import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {RestDatasource} from "../rest.datasource";
import {ChangePasswordRequest} from "../../models/user/change-password-request";
import {LoginRequest} from "../../models/user/login-request";
import {LoginResponse} from "../../models/user/login-response";

@Injectable()
export class ProfileService {



    constructor(private datasource : RestDatasource) {
    }

    changePassword(password:string, newPassword:string): Observable<any> {
        return this.datasource.changePassword(password, newPassword) ;
    }

    changeForgotPassword(login : string, password : string, code2FA : string) : Observable<LoginResponse> {
      const loginRequest : LoginRequest = new LoginRequest();
      loginRequest.login = login;
      loginRequest.code2FA = code2FA;
      const passwordRequest = new ChangePasswordRequest(loginRequest, password);
      return this.datasource.changeForgotPassword(passwordRequest);
    }

  verifyIfLinkChangePasswordIsValid(login : string, code2FA : string) : Observable<any> {
    const loginRequest : LoginRequest = new LoginRequest();
    loginRequest.login = login;
    loginRequest.code2FA = code2FA;
    return this.datasource.verifyIfLinkChangePasswordIsValid(loginRequest);
  }

  updateUserLanguage(langue : string){
      return this.datasource.updateUserLanguage(langue);
  }
}
