import {Injectable} from '@angular/core';
import {BehaviorSubject} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class MessageObservableService {

  private _messageErrorWebssoSubject$ : BehaviorSubject<string> = new BehaviorSubject<string>(null);

  constructor() { }

  sendMessageErrorWebsso(message : string) {
    this._messageErrorWebssoSubject$.next(message)
  }

  onMessageErrorWebsso() {
    return this._messageErrorWebssoSubject$.asObservable();
  }
}
