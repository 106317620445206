import {Component, Input, OnInit} from '@angular/core';
import {Dossier} from "../../../models/dossiers/dossier";
import {BienCaracteris} from "../../../models/dossiers/bien-caracteris";

@Component({
  selector: 'app-resultat-expertise',
  templateUrl: './resultat-expertise.component.html',
  styleUrls: ['./resultat-expertise.component.scss']
})
export class ResultatExpertiseComponent implements OnInit {

  @Input()
  dossier : Dossier;

  noValue : string = 'N/A';

  surface : any[] = [];

  valeurGlobaleEstimee : string;

  prixValeurFuturAchevement : any;

  prixValeurApresTravaux : any;

  prixValeurGlobaleEstimee : any;

  constructor() { }

  ngOnInit(): void {
    if(this.dossier.valeurGlobaleEstimee) {
     this.valeurGlobaleEstimee = this.dossier.valeurGlobaleEstimee.toFixed(2) + '€'
    }
    this.displaySurface(this.dossier?.bien?.bienCaracteris);
    this.prixValeurGlobaleEstimee = this.calculPrixM2(this.dossier.valeurGlobaleEstimee);
    this.prixValeurFuturAchevement = this.calculPrixM2(this.dossier.valeurFuturAchevement);
    this.prixValeurApresTravaux = this.calculPrixM2(this.dossier.valeurApresTravaux);
  }

  displaySurface(biec : BienCaracteris) {

        if(biec) {
          if(biec.sh) {
            this.surface.push({name : 'SH', value : biec.sh}) ;
          }else if(biec.shp) {
            this.surface.push({name : 'SHP', value : biec.shp}) ;
          }
          else if(biec.su) {
            this.surface.push({name : 'SU', value : biec.su}) ;
          }
          else if(biec.sup) {
            this.surface.push({name : 'SUP', value : biec.sup}) ;
          }
          else if(biec.spc) {
            this.surface.push({name : 'SPC', value : biec.spc}) ;
          }
          else if(biec.supTerrain) {
            this.surface.push({name : 'Superifice terrain', value : biec.supTerrain});
          }
          else if(biec.sdhop) {
            this.surface.push({name : 'SDHOP', value : biec.sdhop});
          }
        }
        return this.surface;
  }

  calculPrixM2(value : number) {
    let result = 0;
    let surface = this.surface[0]?.value;
    if(surface && value) {
      result = value / surface;
    }

    return result === 0 ? this.noValue : result.toFixed(2);
  }
}
