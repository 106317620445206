import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MonitoringRoutingModule } from './monitoring-routing.module';
import { MonitoringComponent } from './monitoring.component';
import {SharedModule} from "../../shared/shared.module";
import {NgxSpinnerModule} from "ngx-spinner";
import {ProgressSpinnerModule} from "primeng/progressspinner";
import {ToastModule} from "primeng/toast";
import {TreeModule} from "primeng/tree";


@NgModule({
  declarations: [
    MonitoringComponent
  ],
  imports: [
    CommonModule,
    MonitoringRoutingModule,
    SharedModule,
    NgxSpinnerModule,
    ProgressSpinnerModule,
    ToastModule,
    TreeModule
  ]
})
export class MonitoringModule { }
