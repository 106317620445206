import {Injectable} from '@angular/core';
import {Router} from "@angular/router";

@Injectable({
  providedIn: 'root'
})
export class MessageStorageService {

  static readonly MESSAGE ="message";
  static readonly KEY1 ="key1";
  static readonly KEY2 ="key2";
  static readonly MESSAGE_WEBSSO ="message_websso";

  constructor(private router:Router) { }

  signOut(): void {
    localStorage.clear();
    this.router.navigateByUrl("/auth");
  }


  public saveMessage(message : string, key1? : string, key2 ? : string): void {

    this.removeMessage();

    localStorage.setItem(MessageStorageService.MESSAGE, message);
    localStorage.setItem(MessageStorageService.KEY1, key1);
    localStorage.setItem(MessageStorageService.KEY2, key2);
  }

  public removeMessage() {
    localStorage.removeItem(MessageStorageService.MESSAGE);
    localStorage.removeItem(MessageStorageService.KEY1);
    localStorage.removeItem(MessageStorageService.KEY2);
  }

  public setMessage(message : string) {
    localStorage.removeItem(MessageStorageService.MESSAGE);
    localStorage.setItem(MessageStorageService.MESSAGE, message);
  }

  public getMessage() :string {
    return localStorage.getItem(MessageStorageService.MESSAGE);
  }

  public getKey1() : string {
    return localStorage.getItem(MessageStorageService.KEY1);
  }

  public getKey2() : string {
    return localStorage.getItem(MessageStorageService.KEY2);
  }

  public setMessageWebSSO(message : string) {
    this.removeMessageWebSSO();
    localStorage.setItem(MessageStorageService.MESSAGE_WEBSSO, message);
  }

  public getMessageWebSSO() :string {
    return localStorage.getItem(MessageStorageService.MESSAGE_WEBSSO);
  }
  public removeMessageWebSSO() {
    localStorage.removeItem(MessageStorageService.MESSAGE_WEBSSO);
  }


}
