<h2  id="resultatExp">{{'DOSSIER.RESULTAT_EXPERTISE' | translateI18n}}</h2>
<div class="card">

  <div class="grid">
  <div class="col-12 lg:col-12">
    <fieldset class="grid formgrid">
      <div class="col-4"></div>
      <div class="col-5"></div>
      <div class="col-3"><span class="label"> {{surface.length > 0 ? surface[0].name : ('DOSSIER.SH' | translateI18n)}} : </span> {{surface.length > 0 ? (surface[0].value | number : '1.2-2') : noValue}}</div>
    </fieldset>
  </div>
  </div>

  <p-divider></p-divider>

  <div class="grid">

    <div class="col-12 lg:col-12">
      <h4>{{'DOSSIER.VALEUR_LOCATIVE' | translateI18n}}</h4>
      <fieldset class="grid formgrid">
        <div class="col"><span class="label">{{'DOSSIER.VALEUR' | translateI18n}} : </span> {{dossier.valeurLocative != null ? (dossier.valeurLocative | currency : 'EUR') : noValue}}</div>
        <div class="col"><span class="label">{{'DOSSIER.REGIME_FISCAL' | translateI18n}} : </span> {{dossier.bien?.bienCaracteris?.paramRegimeFiscalLocatif ?
          dossier.bien?.bienCaracteris?.paramRegimeFiscalLocatif?.libelleCourt : '--'}}</div>
      </fieldset>
    </div>

    <div class="col-12 lg:col-12">
      <h4>{{'DOSSIER.VALEUR_VENALE' | translateI18n}} </h4>
      <fieldset class="grid formgrid">
        <div class="col"><span class="label"> {{'DOSSIER.VALEUR' | translateI18n}} : </span> {{dossier.valeurGlobaleEstimee != null ? (dossier.valeurGlobaleEstimee | currency : 'EUR') : noValue }}</div>
        <div class="col"><span class="label">{{'DOSSIER.REGIME_FISCAL' | translateI18n}} : </span> {{dossier.bien?.bienCaracteris?.paramRegimeFiscalVenal ?
          dossier.bien?.bienCaracteris?.paramRegimeFiscalVenal?.libelleCourt : '--'}}</div>
        <div class="col"><span class="label">{{'DOSSIER.TAUX_FISCAL' | translateI18n}} : </span> {{dossier.bien?.bienCaracteris?.tauxFiscaliteVenal ? dossier.bien?.bienCaracteris?.tauxFiscaliteVenal?.libelle + '%' : noValue}}</div>
        <div class="col"><span class="label">{{'DOSSIER.PRIXM2' | translateI18n}} : </span>
          {{prixValeurGlobaleEstimee === noValue ? prixValeurGlobaleEstimee : (prixValeurGlobaleEstimee | currency : 'EUR') +'/m²' }}
        </div>
      </fieldset>
    </div>

    <div class="col-12 lg:col-12">
      <h4>{{'DOSSIER.VALEUR_APRES_TRAVAUX' | translateI18n}} : </h4>
      <fieldset class="grid formgrid">
        <div class="col"><span class="label">{{'DOSSIER.VALEUR' | translateI18n}} : </span> {{dossier.valeurApresTravaux != null ? (dossier.valeurApresTravaux | currency : 'EUR') : noValue}}</div>
        <div class="col"><span class="label">{{'DOSSIER.REGIME_FISCAL' | translateI18n}} : </span> {{dossier.bien?.bienCaracteris?.paramRegimeFiscalApresTravaux ?
          dossier.bien?.bienCaracteris?.paramRegimeFiscalApresTravaux?.libelleCourt : '--'}}</div>
        <div class="col"><span class="label">{{'DOSSIER.TAUX_FISCAL' | translateI18n}} : </span> {{dossier.bien?.bienCaracteris?.tauxFiscaliteApresTravaux ? dossier.bien?.bienCaracteris?.tauxFiscaliteApresTravaux?.libelle + '%'  : noValue}}</div>
        <div class="col"><span class="label">{{'DOSSIER.PRIXM2' | translateI18n}} : </span>
          {{prixValeurApresTravaux === noValue ? prixValeurApresTravaux : (prixValeurApresTravaux | currency : 'EUR') +'/m²' }}
        </div>
      </fieldset>
    </div>

    <div class="col-12 lg:col-12">
      <h4>{{'DOSSIER.VALEUR_EN_ETAT_FUTUR_ACHEVEMENT' | translateI18n}} </h4>
      <fieldset class="grid formgrid">
        <div class="col"><span class="label">{{'DOSSIER.VALEUR' | translateI18n}} : </span>
          {{dossier.valeurFuturAchevement != null ? (dossier.valeurFuturAchevement | currency : 'EUR') : noValue}}
        </div>
        <div class="col"><span class="label">{{'DOSSIER.REGIME_FISCAL' | translateI18n}} : </span> {{dossier.bien?.bienCaracteris?.paramRegimeFiscalEtatFuturAchevement ?
          dossier.bien?.bienCaracteris?.paramRegimeFiscalEtatFuturAchevement?.libelleCourt : '--'}}</div>
        <div class="col"><span class="label">{{'DOSSIER.TAUX_FISCAL' | translateI18n}} :
          </span> {{dossier.bien?.bienCaracteris?.tauxFiscaliteApresTravaux ? dossier.bien?.bienCaracteris?.tauxFiscaliteApresTravaux?.libelle + '%' : noValue}}
        </div>
        <div class="col"><span class="label">{{'DOSSIER.PRIXM2' | translateI18n}} :
          </span> {{prixValeurFuturAchevement === noValue ? prixValeurFuturAchevement : (prixValeurFuturAchevement | currency : 'EUR') +'/m²' }}
        </div>
      </fieldset>
    </div>

    <div class="col-12 lg:col-12">
      <h4>{{'DOSSIER.VALEUR_EN_VENTE_FORCEE' | translateI18n}} </h4>
      <fieldset class="grid formgrid">
        <div class="col"><span class="label">{{'DOSSIER.VALEUR' | translateI18n}} : </span> {{dossier.valeurJuridique != null ? (dossier.valeurJuridique | currency : 'EUR') : noValue}}</div>
      </fieldset>
    </div>
  </div>


  <p-divider></p-divider>

    <div class="grid">

      <div class="col-12 lg:col-12">
        <p><span class="label">{{'DOSSIER.LIQUIDITE_BIEN' | translateI18n}} : </span>
          {{dossier.bien?.paramLiquidite ? dossier.bien?.paramLiquidite?.libelle : '--'}}</p>
      </div>

      <div class="col-12 lg:col-12">
        <p><span class="label">{{'DOSSIER.DELAI_COMMERCIALISATION' | translateI18n}} : </span>
          {{dossier?.delaiCommercialisation ? dossier?.delaiCommercialisation?.libelle : '--'}}</p>
      </div>
    </div>


</div>
