<form [formGroup]="form" >
  <div class="card-large" id="section1">
    <h1 id="mission">I - {{'RAPPORT_EXPERTISE.MISSION' | translateI18n}}</h1>

    <div class="grid">

      <div class="col-12">
        <section>
          <div class="field input-inline">
            {{'RAPPORT_EXPERTISE.TEXTE.LA_SOCIETE' | translateI18n}} {{rapport?.libelleEntiteJuridiqueNegLie}},
            <span *ngIf="rapport.codeEntiteJuridiqueNegLie !== 'AT'">{{'RAPPORT_EXPERTISE.TEXTE.MEMBRE_AFSEI' | translateI18n}}</span>
            {{'RAPPORT_EXPERTISE.TEXTE.ADHERENT_MISSIONNEE' | translateI18n : (rapport?.dateDemande | dateFormatPipe) : (rapport?.agenceNomTiers) : (rapport?.nomCompletDemandeur)}}{{rapport?.displayDateConvention ? ',' : '.'}}
            <span *ngIf="rapport?.displayDateConvention">{{'RAPPORT_EXPERTISE.TEXTE.CONVENTION' | translateI18n : (rapport?.dateContratConvention ? ( rapport?.dateContratConvention | date : 'dd/MM/yyyy') : ' --')}}</span>
          </div>
        </section>

      </div>


      <div class="col-12">

        <section>
          <h2> {{'RAPPORT_EXPERTISE.OBJ_MISSION' | translateI18n}}</h2>
          <div class="field input-inline">
          {{'RAPPORT_EXPERTISE.TEXTE.OBJET_MISSION_APPRECIATION' | translateI18n}}
          <p-dropdown  [options]="paramTypeValeur"  formControlName="typeValeur" optionLabel="libelle" name="typeValeur"  id="typeValeur"
                       [ngClass]="{'ng-dirty ng-invalid': (submittedRequiredSynthese || submittedRequired)&& f.typeValeur.errors}"
                       emptyMessage="{{'RAPPORT_EXPERTISE.MESSAGE.AUCUN_RESULTAT' | translateI18n}}"
                       placeholder="{{'PLACEHOLDER.CHOOSE' | translateI18n}}" class="obligatoire"
                       (onChange)="typeValeurChange($event)"></p-dropdown>
          {{'RAPPORT_EXPERTISE.TEXTE.OBJET_MISSION_SUPPOSE' | translateI18n}}
          <p-dropdown  [options]="paramOccupation"  formControlName="typeOccupation" optionLabel="label" name="typeOccupation"  id="typeOccupation"
                       [ngClass]="{'ng-dirty ng-invalid': submittedRequired && f.typeOccupation.errors}"
                       emptyMessage="{{'RAPPORT_EXPERTISE.MESSAGE.AUCUN_RESULTAT' | translateI18n}}"
                       placeholder="{{'PLACEHOLDER.CHOOSE' | translateI18n}}" class="obligatoire" (onChange)="typeOccupationChange($event)"></p-dropdown>
          {{'RAPPORT_EXPERTISE.TEXTE.OBJET_MISSION_CONTEXTE' | translateI18n : rapport?.missionContexteLabel}}
          </div>

        </section>

      </div>

      <div class="field col-12 field-line">

        <div class="mb-2">
          <h2 class="m-0 obligatoire">{{'RAPPORT_EXPERTISE.PIECES_COMMUNIQUE' | translateI18n}}</h2>
          <small>{{'RAPPORT_EXPERTISE.MESSAGE.MAX_CARACTERE' | translateI18n : '400'}}
            <span [ngClass]="{'is-alert':  f.piecesCommuniques.hasError('hasError')}">({{nbPiecesCommuniquesText}} / 400)</span>
          </small>
        </div>

        <div *ngIf="submitted && f.piecesCommuniques.hasError('hasError')">
          <p-messages  [(value)]="msgError"></p-messages>
        </div>

        <p-editor formControlName="piecesCommuniques" [style]="{'height':'120px'}" name="piecesCommuniques"
                  id="piecesCommuniques" class="w-full"
                  [ngClass]="{'editor-error':  f.piecesCommuniques.hasError('hasError')
                  || (submittedRequired && (f.piecesCommuniques?.errors?.required ||  f.piecesCommuniques.hasError('hasEmptyCaracterError')))  }"
                  (onTextChange)="piecesCommuniquesTextChange($event)">
          <p-header>
            <app-p-editor-header></app-p-editor-header>
          </p-header>
        </p-editor>

        <small *ngIf="submittedRequired && (f.piecesCommuniques?.errors?.required ||  f.piecesCommuniques.hasError('hasEmptyCaracterError'))" class="p-error block">{{'MESSAGE.ERROR.CHAMPS_OBLIGATOIRE' | translateI18n}}</small>
      </div>

    </div>


  </div>
</form>
