<form *ngIf="role" [formGroup]="form" (ngSubmit)="recherche()">

  <div  *ngIf="messageERROR" >
    <p-messages [(value)]="messageERROR" [enableService]="false"></p-messages>
  </div>

  <div class="modale-content grid p-fluid">



    <div class="field col-12 field-line" [hidden]="hiddenTypeVue">
      <div class="p-dropdown-group">
        <h2 for="typeVue">{{'DOSSIER.ADVANCED_SEARCH.LABEL.TYPE_PROFIL' | translateI18n}}</h2>
        <p-dropdown [options]="typeDeVues" formControlName="typeVue" optionLabel="name" id="typeVue"
                    placeholder="{{'PLACEHOLDER.CHOOSE' | translateI18n}}" class="w-full" (onChange)="typeProfil($event)"></p-dropdown>
      </div>
    </div>


    <div class="field col-12 md:col-6" *ngIf="role.includes('AGENT') || ((role.includes('ADMINISTRATEUR') || role.includes('ADMINISTRATEUR_TECHNIQUE'))  && form.value?.typeVue?.profil)">
      <input id="numeroDossier" name="numeroDossier" formControlName="numeroDossier" pInputText type="text" class="inputfield w-full"
             placeholder="{{'FORM_QUICK_SEARCH.NUM_DOSSIER' | translateI18n}}" [minLength]="5">
    </div>

    <div class="field col-12 md:col-6" *ngIf="role.includes('AGENT') || ((role.includes('ADMINISTRATEUR') || role.includes('ADMINISTRATEUR_TECHNIQUE'))  && form.value?.typeVue?.profil)">
      <input id="numeroContrat" name="numeroContrat" formControlName="numeroContrat" pInputText type="text" class="inputfield w-full"
             placeholder="{{'FORM_QUICK_SEARCH.NUM_CONTRAT' | translateI18n}}">
    </div>

    <div class="field col align-items-center flex rechercheAvancee" *ngIf="((role.includes('ADMINISTRATEUR') || role.includes('ADMINISTRATEUR_TECHNIQUE'))  && form.value?.typeVue?.profil)">
      <a (click)="showAdvanceSearch()" style="cursor : pointer;">{{'BUTTON_LABEL.ADVANCED_SEARCH' | translateI18n}}</a>
    </div>


    <ng-template [ngIf]="show">
      <ng-container *ngIf="typeDeVue?.includes('AGENT')">
        <div class="field col-12 field-line -mt-4">
          <app-demandeur [statuts]="statuts" [user]="user" formGroupName="contenu"></app-demandeur>
        </div>
      </ng-container>

      <ng-container *ngIf="typeDeVue?.includes('ASSISTANTE') || typeDeVue?.includes('ANIMATEUR_RESEAU')">
        <div class="field col-12 field-line" >
          <app-gestionnaire [statuts]="statuts" formGroupName="contenu"></app-gestionnaire>
        </div>
      </ng-container>

      <ng-container *ngIf="typeDeVue?.includes('EXPERT_EXTERNE') || typeDeVue?.includes('EXPERT_INTERNE')">
        <div class="field col-12 field-line" >
          <app-expert [statuts]="statuts" formGroupName="contenu"></app-expert>
        </div>
      </ng-container>
    </ng-template>


  </div>

  <div class="p-dialog-footer">

    <button pButton type="button" label="{{'BUTTON_LABEL.CLOSE' | translateI18n}}"  (click)="close()" class="uppercase p-button-secondary p-button-outlined mb-2" icon="pi pi-times"
            iconPos="right"></button>

    <button pButton type="submit" label="{{'BUTTON_LABEL.RECHERCHER' | translateI18n}}" class="uppercase mb-2" icon="pi pi-search"
            iconPos="right"></button>

  </div>

</form>
