import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {CoreModule} from "../../core/core.module";
import {SharedModule} from "../../shared/shared.module";
import {MessagesModule} from "primeng/messages";
import {MessageModule} from "primeng/message";
import {ToastModule} from "primeng/toast";
import {TableModule} from 'primeng/table';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {AvisValeurRoutingModule} from "./avis-valeur-routing.module";
import {TooltipModule} from "primeng/tooltip";
import {DropdownModule} from "primeng/dropdown";
import {InputTextModule} from "primeng/inputtext";
import {EditorModule} from "primeng/editor";
import {NgxSpinnerModule} from "ngx-spinner";
import {ProgressSpinnerModule} from "primeng/progressspinner";
import {AvisValeurComponent} from "./avis-valeur.component";
import {RippleModule} from "primeng/ripple";


@NgModule({
  declarations: [
    AvisValeurComponent,
  ],
    imports: [
        CommonModule,
        CoreModule,
        AvisValeurRoutingModule,
        SharedModule,
        MessagesModule,
        MessageModule,
        TableModule,
        ToastModule,
        FormsModule,
        TooltipModule,
        DropdownModule,
        InputTextModule,
        ReactiveFormsModule,
        EditorModule,
        NgxSpinnerModule,
        ProgressSpinnerModule,
        RippleModule
    ]
})
export class AvisValeurModule { }
