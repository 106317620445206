import {ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {FormGroup, FormGroupDirective} from "@angular/forms";

@Component({
  selector: 'app-expert',
  templateUrl: './expert.component.html',
  styleUrls: ['./expert.component.scss']
})
export class ExpertComponent implements OnInit {

  @Input()
  formGroupName: string;
  form: FormGroup;

  @Input()
  statuts: any[];


  constructor(private rootFormGroup: FormGroupDirective, private cdref: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.form = this.rootFormGroup.control.get(this.formGroupName) as FormGroup;
  }

  ngAfterContentChecked() {
    this.cdref.detectChanges();
  }
}
