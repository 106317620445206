import {AfterContentChecked, ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {AbstractControl, FormBuilder, FormGroup, FormGroupDirective} from "@angular/forms";
import {AvisValeurService} from "../../../service/avis-valeur.service";
import {DialogService, DynamicDialogRef} from "primeng/dynamicdialog";
import {Message} from "primeng/api";
import {TranslateService} from "../../../../shared/translate/translate.service";

@Component({
  selector: 'app-elements-appreciation',
  templateUrl: './elements-appreciation.component.html',
  styleUrls: ['./elements-appreciation.component.scss']
})
export class ElementsAppreciationComponent implements OnInit, AfterContentChecked {

  @Input()
  formGroupName: string;

  @Input()
  submittedRequired : boolean;

  form: FormGroup;

  nbElementDefavorables : number = 0;
  nbElementFavorable : number =0;
  msgError : Message[];

  constructor(private avisValeurService : AvisValeurService,
              private fb : FormBuilder,
              public dialogService: DialogService,
              public ref: DynamicDialogRef,
              private rootFormGroup: FormGroupDirective,
              private translate : TranslateService,
              private cdref: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.form = this.rootFormGroup.control.get(this.formGroupName) as FormGroup;
    this.nbElementFavorable = this.form?.value?.favorable ? this.form?.value?.favorable?.replace(/<[^>]*>/g, '')?.length : 0;
    this.nbElementDefavorables =  this.form?.value?.defavorable ? this.form?.value?.defavorable?.replace(/<[^>]*>/g, '')?.length : 0;
    this.msgError =[ {severity:'error', summary:  this.translate.getElement("AVIS_VALEUR.MESSAGE.ERROR_CARACTERE_COMMENTAIRE", '150')}];
  }

  elementFavorableTextChange(event: any) {
    this.nbElementFavorable = event?.htmlValue ? event?.htmlValue?.replace(/<[^>]*>/g, '')?.trim()?.length : 0;
  }

  elementdefavorablesTextChange(event: any) {
    this.nbElementDefavorables = event?.htmlValue ? event?.htmlValue?.replace(/<[^>]*>/g, '')?.trim()?.length : 0;
  }

  ngAfterContentChecked(): void {
    this.cdref.detectChanges();
  }

  get f(): { [key: string]: AbstractControl } {
    return this.form.controls;
  }

}
