import {Component, Input, OnInit} from '@angular/core';
import {FormGroup, FormGroupDirective} from "@angular/forms";

@Component({
  selector: 'app-adresse-bien',
  templateUrl: './adresse-bien.component.html',
  styleUrls: ['./adresse-bien.component.scss']
})
export class AdresseBienComponent implements OnInit {

  @Input()
  formGroupName: string;
  adresseBien: FormGroup;

  constructor(private rootFormGroup: FormGroupDirective) { }

  ngOnInit(): void {
    this.adresseBien = this.rootFormGroup.control.get(this.formGroupName) as FormGroup;
  }

}
