<h2 >{{'DOSSIER.ADVANCED_SEARCH.LABEL.ADRESSE' | translateI18n}}</h2>
<div class="grid formgrid" [formGroup]="adresseBien">

    <div class="field col-12 md:col-6">
      <div class="p-input-icon-right w-full">
        <input type="number" pInputText id="numero" formControlName="numero" name="numero" class="inputfield w-full"
        placeholder="{{'DOSSIER.ADRESSE_FORM.NUM_ADR' | translateI18n}}" />
      </div>
    </div>

    <div class="field col-12 md:col-6">
      <div class="p-input-icon-right w-full">
        <input type="text" pInputText id="nomVoie" formControlName="nomVoie" name="nomVoie" class="inputfield w-full"
               placeholder="{{'DOSSIER.ADRESSE_FORM.STREET_NAME' | translateI18n}}" required />
      </div>
    </div>

  <div class="col-12 field">
    <div class="p-input-icon-right w-full">
      <input type="text" pInputText id="complement" formControlName="complement" name="complement" class="inputfield w-full"
             placeholder="{{'DOSSIER.ADRESSE_FORM.COMP_ADR' | translateI18n}}" />
    </div>
  </div>


  <div class="field col-12 md:col-6">
      <div class="p-input-icon-right w-full">
        <input type="number" pInputText id="codePostal" formControlName="codePostal" name="codePostal" class="inputfield w-full"
             placeholder="{{'DOSSIER.ADRESSE_FORM.POSTAL_CODE' | translateI18n}}"  required />
      </div>
    </div>


    <div class="field col-12 md:col-6">
      <div class="p-input-icon-right w-full">
        <input type="text" pInputText id="ville" formControlName="ville" name="ville" class="inputfield w-full"
        placeholder="{{'DOSSIER.ADRESSE_FORM.CITY' | translateI18n}}"/>
      </div>
    </div>



</div>
