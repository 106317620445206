import {AfterViewChecked, ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {RapportExpertise} from "../../../models/rapport-expertise/rapport-expertise";
import {AbstractControl, FormBuilder, FormControl, FormGroup, FormGroupDirective, Validators} from "@angular/forms";
import {DialogService, DynamicDialogRef} from "primeng/dynamicdialog";
import {ConfirmationService, Message, MessageService} from "primeng/api";
import {TranslateService} from "../../../../shared/translate/translate.service";
import {DatePipe} from "@angular/common";
import {RapportExpertiseObservableService} from "../../../../shared/observable/rapport-expertise-observable.service";
import {RapportExpertiseService} from "../../../service/rapport-expertise.service";
import {BienCadastre} from "../../../models/bien/bien-cadastre";
import {ConfigSizeInput} from "../../../../shared/providers/config-size-input";
import {UtilsObservableService} from "../../../../shared/observable/utils-observable.service";
import {DocumentService} from "../../../service/document.service";
import {DomSanitizer} from "@angular/platform-browser";
import {AppSettings, Settings} from "../../../../app.settings";
import {UtilsFonction} from "../../../../shared/providers/utils-fonction";
import {Document} from "../../../models/document/document.model";
import {NgxSpinnerService} from "ngx-spinner";

@Component({
  selector: 'app-designation-cadastre',
  templateUrl: './designation-cadastre.component.html',
  styleUrls: ['./designation-cadastre.component.scss']
})
export class DesignationCadastreComponent implements OnInit, AfterViewChecked {

  @Input()
  rapport : RapportExpertise;
  @Input()
  formGroupName: string;
  @Input()
  submitted : boolean;
  @Input()
  submittedRequired : boolean = false;

  form: FormGroup;

  bienCadastreForm : FormGroup;

  paramTypeRegimejRapport : any[];

  nbNumLotTantiemes: number = 0;
  nbObservations : number = 0;
  nbDesignationcadastre : number = 0;

  msgError : Message[];

  messageBienCadastre : Message[];
  messageSuppressionBiencadastre : Message[];

  bienCadastreDialog: boolean;
  submittedBienCadastre : boolean;

  bienCadastres : BienCadastre[];

  bienCadastre: BienCadastre;
  configSizeInput : ConfigSizeInput;

  public settings: Settings;

  documentCadastre : Document;

  progressSpinner : boolean = true;

  message : Message[];

  constructor(
    private fb : FormBuilder,
    public dialogService: DialogService,
    public ref: DynamicDialogRef,
    private rootFormGroup: FormGroupDirective,
    private cdref: ChangeDetectorRef,
    private messageService: MessageService,
    private translate : TranslateService,
    private datePipe: DatePipe,
    private rapportExpertiseObservableService : RapportExpertiseObservableService,
    private rapportExpertiseService : RapportExpertiseService,
    private confirmation: ConfirmationService,
    private utilsObservableService : UtilsObservableService,
    private documentService : DocumentService,
    private sanitizer: DomSanitizer,
    public appSettings : AppSettings,
    private spinnerService: NgxSpinnerService
    ) {
    this.configSizeInput = new ConfigSizeInput();
    this.settings = this.appSettings.settings;
  }

  ngOnInit(): void {
    this.form = this.rootFormGroup.control.get(this.formGroupName) as FormGroup;


    this.nbDesignationcadastre = this.form?.value?.designationcadastre?.length;

    this.getAllParamTypeRegimejRapport();

    this.initBienCadastreForm(null);
    this.getAllBienCadastreByBienId();
    this.getImagePlanCadastral();

    this.initChampstexte();



  }

  /**
   * recupère tous les biens cadastres
   */
  async getAllBienCadastreByBienId() {
    this.bienCadastres = await this.rapportExpertiseService.getAllBienCadastreByBienId(this.rapport.idBien);
  }

  /**
   * recupère la liste des types regimes du rapport
   */
  getAllParamTypeRegimejRapport(){
    this.rapportExpertiseService.getAllParamTypeRegimejRapport().subscribe(
      data => {
        if(data) {
          this.paramTypeRegimejRapport = data;
        }
      }
    )
  }

  numLotTantiemesTextChange(event: any) {
    this.nbNumLotTantiemes = event?.htmlValue ? event?.htmlValue?.replace(/<[^>]*>/g, '')?.trim()?.length : 0;
  }

  get f(): { [key: string]: AbstractControl } {
    return this.form.controls;
  }

  ngAfterContentChecked() {
    this.cdref.detectChanges();
  }

  observationsTextChange(event: any) {
    this.nbObservations = event?.htmlValue ? event?.htmlValue?.replace(/<[^>]*>/g, '')?.trim()?.length : 0;
  }

  detectChangeComment(event : any) {
      this.nbDesignationcadastre =  String(event)?.length;
  }

  editBienCadastre(biec: BienCadastre) {
    this.messageBienCadastre = null;
    this.bienCadastre = {...biec};
    this.initBienCadastreForm(this.bienCadastre);
    this.bienCadastreDialog = true;
  }


  deleteBienCadatre(biec: BienCadastre) {
    if(biec) {
      this.confirmation.confirm({
        header: this.translate.getElement("RAPPORT_EXPERTISE.MESSAGE.ETES_VOUS_SUR"),
        message : this.translate.getElement("RAPPORT_EXPERTISE.MESSAGE.CONFIRMER"),
        icon: 'pi pi-exclamation-triangle',
        accept: () => {

          this.rapportExpertiseService.deleteBienCadastre(biec.idBienCadastre, this.rapport?.idDossier).then( data => {

            if(data) {
              this.getAllBienCadastreByBienId();
              this.messageSuppressionBiencadastre =[ {severity:'success', summary:  this.translate.getElement("RAPPORT_EXPERTISE.MESSAGE.SUCESS_SUPPRESSION")}];
              return;
            }

            this.messageSuppressionBiencadastre =[ {severity:'error', summary:  this.translate.getElement("RAPPORT_EXPERTISE.MESSAGE.ERROR_SUPPRESSION")}];

          }).catch( error => {
            this.messageSuppressionBiencadastre =[ {severity:'error', summary:  this.translate.getElement("RAPPORT_EXPERTISE.MESSAGE.ERROR_SUPPRESSION")}];
          })
        }
      })

    }

  }

  /**
   * Réinitialisation du formulaire bien cadastre
   * @param bieCadastre
   */
  initBienCadastreForm(bieCadastre : BienCadastre) {
    this.bienCadastreForm = this.fb.group({
      commune : new FormControl(bieCadastre?.commune ? bieCadastre?.commune : null,
        [Validators.maxLength(30), Validators.required, Validators.pattern(this.configSizeInput.regexPattern2)]),
      lieudit : new FormControl(bieCadastre?.lieudit ? bieCadastre?.lieudit :null,
        [Validators.maxLength(30), Validators.pattern(this.configSizeInput.regexPattern2)]),
      section : new FormControl(bieCadastre?.section ? bieCadastre?.section :null,
        [Validators.maxLength(10), Validators.required, Validators.pattern(this.configSizeInput.regexPattern2)]),
      numeroParcelle : new FormControl(bieCadastre?.numeroParcelle ? bieCadastre?.numeroParcelle :null,
        [Validators.maxLength(5), Validators.required, Validators.pattern(this.configSizeInput.regexPattern2)]),
      contenance : new FormControl(bieCadastre?.contenance ? bieCadastre?.contenance :null,
        [Validators.maxLength(20), Validators.required, Validators.pattern(this.configSizeInput.regexPattern2)]),
    });
  }

  /**
   * Ajouter une nouvelle reference cadastrale dans le tableau bien cadastre
   */
  openNew() {
    this.messageBienCadastre = null;
    this.initBienCadastreForm(null);
    this.bienCadastre = null;
    this.submittedBienCadastre = false;
    this.bienCadastreDialog = true;
  }

  /**
   * Enregistre la réference cadastrale dans bien cadastre
   */
  saveReferenceCadastre() {
    this.submittedBienCadastre = true;

    if(this.bienCadastreForm.invalid) {
      this.messageBienCadastre =[ {severity:'error', summary:  this.translate.getElement("RAPPORT_EXPERTISE.MESSAGE.ERROR_FORM")}];
      return;
    }

    const biec : BienCadastre = new BienCadastre();

    if(this.bienCadastre) {
      biec.idBienCadastre = this.bienCadastre?.idBienCadastre;
    }

    const value = this.bienCadastreForm.value;
    biec.commune = value.commune;
    biec.lieudit = value.lieudit;
    biec.section = value.section;
    biec.numeroParcelle = value.numeroParcelle;
    biec.contenance = value.contenance;
    biec.idBien = this.rapport?.idBien;
    biec.idDossier = this.rapport?.idDossier;

    this.rapportExpertiseService.saveBieCadastre(biec).subscribe( data => {
      if(data) {
        this.hideDialog()
        this.getAllBienCadastreByBienId();
      }else {
        this.messageBienCadastre =[ {severity:'error', summary:  this.translate.getElement("RAPPORT_EXPERTISE.MESSAGE.ERROR_MESSAGE_MODAL")}];
        this.submittedBienCadastre = false;
      }
    }, error => {
      this.messageBienCadastre =[ {severity:'error', summary:  this.translate.getElement("RAPPORT_EXPERTISE.MESSAGE.ERROR_MESSAGE_MODAL")}];
      this.submittedBienCadastre = false;
    })

  }

  get fBieC(): { [key: string]: AbstractControl } {
    return this.bienCadastreForm.controls;
  }


  /**
   * Recupère le plan cadastral et converti en image affichable
   */
 getImagePlanCadastral() {
    this.documentService.findDocumentByIdbienAndCategorie(this.rapport.idBien, 5).subscribe( doc => {

      if (doc != null && doc.length > 0) {
        this.documentCadastre = doc.shift();

        const reader = new FileReader();

        reader.addEventListener("load", () => {
          this.documentCadastre.lienImage = this.getSantizeUrl(reader.result as string);
        }, false);
        reader.readAsDataURL(UtilsFonction.b64toBlob(this.documentCadastre.contenu, this.documentCadastre.type) );

        this.progressSpinner = false;
      }else {
        this.documentCadastre = null;
        this.progressSpinner = false;
      }
    });

  }

  getSantizeUrl(url : string) {
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }

  addDocument(documents: Document[]): void {
    this.getImagePlanCadastral();
  }


  ngAfterViewChecked() {
    this.cdref.detectChanges();
  }

  /**
   * Supprime l'image pkan cadastrale
   * @param doc
   */
  deleteImage(doc: Document) {
    this.confirmation.confirm({
      message: this.translate.getElement("DOCUMENT.SUPPRESSION.CONFIRMATION"),
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        if(doc?.guid) {
          this.spinnerService.show();
          this.documentService.deleteDossierRapportDocument(this.rapport?.idBien, doc?.guid, this.rapport?.idDossier).subscribe(data => {
            if (data) {
              this.messageService.add({key: 'uploadPhotoCadastre', severity: 'success', summary: this.translate.getElement("DOCUMENT.SUPPRESSION.SUCCESS")});
              this.getImagePlanCadastral();
            } else {
              this.messageService.add({key: 'uploadPhotoCadastre', severity: 'error', summary: this.translate.getElement("DOCUMENT.SUPPRESSION.ERROR")});
            }
            this.spinnerService.hide();
          }, error => {
            this.messageService.add({key: 'uploadPhotoCadastre', severity: 'error', summary: this.translate.getElement("DOCUMENT.SUPPRESSION.ERROR")});
            this.spinnerService.hide();
          })
        }
      }
    })
  }

  hideDialog() {
    this.bienCadastreDialog = false;
    this.submittedBienCadastre = false;
  }

  initChampstexte() {
    this.nbNumLotTantiemes = this.form?.value?.numLotTantiemes ? this.form?.value?.numLotTantiemes?.replace(/<[^>]*>/g, '')?.length : 0;
    this.msgError =[ {key : 'numLotTantiemes', severity:'error', summary:  this.translate.getElement("RAPPORT_EXPERTISE.MESSAGE.ERROR_CARACTERE_COMMENTAIRE", '3000')}];

    this.nbObservations = this.form?.value?.observations ? this.form?.value?.observations?.replace(/<[^>]*>/g, '')?.length : 0;
    this.msgError =[ {key : 'observations', severity:'error', summary:  this.translate.getElement("RAPPORT_EXPERTISE.MESSAGE.ERROR_CARACTERE_COMMENTAIRE", '3000')}];

  }


}
