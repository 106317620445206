import {Component, OnInit} from '@angular/core';
import {DomSanitizer, SafeUrl} from '@angular/platform-browser';
import { runtimeEnvironment } from 'src/environments/runtime-environment';

@Component({
  selector: 'app-aide',
  templateUrl: './aide.component.html',
  styleUrls: ['./aide.component.scss']
})
export class AideComponent implements OnInit {

  aidePdf: SafeUrl;
  sanitizer: DomSanitizer;

  constructor(sanitizer:DomSanitizer
  ) {
    this.sanitizer = sanitizer;
  }

  ngOnInit(): void {

  }

  pdfURL() {
    return this.sanitizer.bypassSecurityTrustResourceUrl("../../../assets/help/" + runtimeEnvironment.aideFilename);
  }


}
