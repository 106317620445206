import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {Demande} from "../../../models/dossiers/demande";
import {AbstractControl, FormBuilder, FormGroup, FormGroupDirective} from "@angular/forms";
import {DialogService, DynamicDialogRef} from "primeng/dynamicdialog";
import {Civilite} from "../../../models/param/civilite";
import {Adresse} from "../../../models/adresse";
import {AdresseComponent} from "../../../../shared/adresse/adresse.component";
import {Client} from "../../../models/dossiers/creation-demande";
import {User} from "../../../models/user/user.model";
import {ConfigSizeInput} from "../../../../shared/providers/config-size-input";
import {ProfilesUser} from "../../../models/user/profiles-user";

@Component({
  selector: 'app-contact-dossier',
  templateUrl: './contact-dossier.component.html',
  styleUrls: ['./contact-dossier.component.scss'],
  changeDetection : ChangeDetectionStrategy.OnPush,
})
export class ContactDossierComponent implements OnInit {


  @Input()
  formGroupName: string;

  @Input()
  demande : Demande;

  @Input()
  submitted : boolean;

  @Input()
  civilites: Civilite[];

  contactForm: FormGroup;

  @Input()
  client : Client;

  @Input()
  user : User;

  @Input()
  qualites : any[] = [];

  idQualiteContact : number;

  configSizeInput : ConfigSizeInput;

  constructor(
    private fb : FormBuilder,
    public dialogService: DialogService,
    public ref: DynamicDialogRef,
    private rootFormGroup: FormGroupDirective,
    private cdref: ChangeDetectorRef,
  ) {
    this.configSizeInput = new ConfigSizeInput();
  }

  ngOnInit(): void {
    this.contactForm = this.rootFormGroup.control.get(this.formGroupName) as FormGroup;

    this.idQualiteContact = this.demande.qualiteContact.id;

    if(this.qualites) {
      this.qualites = this.qualites.filter(q => q.libelle !== 'Client');
    }


  }

  /**
   * MAJ choix mode de contact(identique au client ou autre)
   */
  choiceContactType() {
      const type = this.contactForm.value.contactType;
      const customerContactType = this.contactForm.value.customerContactType;
      if(this.contactForm.value.contactType === 'autre') {

        if((this.demande.contactPhysique && customerContactType === 'company') ||
         (!this.demande.contactPhysique && customerContactType === 'human')) {

         this.contactForm.reset();

        }else {
         this.currentValue();
        }

        this.contactForm.get('contactType').setValue(type);
        this.contactForm.get('customerContactType').setValue(customerContactType);
      }
  }

  /**
   * MAJ choix type de contact (physique ou entreprise)
   */
  choiceContactMode() {

    if(this.contactForm.value.contactType !== null) {

      const type = this.contactForm.value.contactType;
      let customerContactType = this.demande?.contactPhysique ? 'human' : 'company'

      if((this.demande?.contactIdentiqueClient && this.contactForm.value.contactType === 'autre' )
        || (!this.demande?.contactIdentiqueClient && this.contactForm.value.contactType === 'idClient')) {

        this.contactForm.reset();
        if(type === 'autre') {
          customerContactType = 'human';
        }

      }
      else {
       this.currentValue();
      }

      this.contactForm.get('contactType').setValue(type);
      this.contactForm.get('customerContactType').setValue(customerContactType);

    }

  }

  /**
   *
   */
  currentValue() {
    this.setValueContact(
      this.demande.civiliteContact, this.demande.nomContact, this.demande.prenomContact,
      this.demande.raisonSocialeContact, this.demande.telephoneContact, this.demande.portableContact,
      this.demande.mail1Contact, this.demande.mail2Contact, this.demande.adresseContact
    );
  }

  /**
   *
   * @param adresse
   */
  addAdress(adresse? : Adresse) {
    this.ref = this.dialogService.open(AdresseComponent, {
      header: "Adresse",
      width: '50vw',
      baseZIndex: 10000,
      data : {contry : 'allContry', adresse : adresse},
      dismissableMask: true,
      styleClass : 'p-dynamic-dialog'
    });
    this.ref.onClose.subscribe((adress : any) =>{
      if (adress) {

        this.contactForm.get('adresse.adresseFormate').setValue(adress.adresseFormate);
        this.contactForm.get('adresse.numero').setValue(adress.numero);
        this.contactForm.get('adresse.lieuDit').setValue(adress.lieuDit);
        this.contactForm.get('adresse.nomVoie').setValue(adress.nomVoie);
        this.contactForm.get('adresse.codePostal').setValue(adress.codePostal);
        this.contactForm.get('adresse.ville').setValue(adress.ville);
        this.contactForm.get('adresse.pays').setValue(adress.pays);
        this.contactForm.get('adresse.boitePostale').setValue(adress.boitePostale);
        this.contactForm.get('adresse.complement').setValue(adress.complement);
        this.contactForm.get('adresse.lng').setValue(adress.lng);
        this.contactForm.get('adresse.lat').setValue(adress.lat);
        this.contactForm.get('adresse.adresseChange').setValue(adress.adresseChange);
      }
    });
  }

  /**
   *
   * @param civilite
   * @param nomContact
   * @param prenomContact
   * @param nomMoral
   * @param phone1
   * @param phone2
   * @param email1
   * @param email2
   * @param adresse
   */
  setValueContact(
     civilite : Civilite, nomContact : string, prenomContact : string, nomMoral : string,
    phone1 : string, phone2 : string,email1 : string, email2 : string, adresse : Adresse,
  ) {

    const qualite = this.qualites.find(q => q.id === this.idQualiteContact);

    this.contactForm.get('qualite').setValue(qualite);
    this.contactForm.get('gender').setValue(civilite);
    this.contactForm.get('nomContact').setValue(nomContact);
    this.contactForm.get('prenomContact').setValue(prenomContact);
    this.contactForm.get('nomMoral').setValue(nomMoral);
    this.contactForm.get('phone1').setValue(phone1);
    this.contactForm.get('phone2').setValue(phone2);
    this.contactForm.get('email1').setValue(email1);
    this.contactForm.get('email2').setValue(email2);
    this.contactForm.get('adresse').patchValue(adresse);
    this.contactForm.get('adresse.adresseFormate').setValue(adresse?.adresseFormate);
  }

  ngAfterContentChecked() {
    this.cdref.detectChanges();
  }

  get f(): { [key: string]: AbstractControl } {
    return this.contactForm.controls;
  }

  isProfilAuthorize() : boolean {
    return this.user?.profiles && (this.user?.profiles?.includes(ProfilesUser.ADMINISTRATEUR)||this.user?.profiles?.includes(ProfilesUser.ADMINISTRATEUR_TECHNIQUE)
      || this.user?.profiles?.includes(ProfilesUser.ASSISTANTE)
        || this.user?.profiles?.includes(ProfilesUser.ANIMATEUR_RESEAU));
  }

}
