import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import { runtimeEnvironment } from 'src/environments/runtime-environment';

@Injectable()
export class SsoAuthInterceptor implements HttpInterceptor {

  constructor() {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    if(request.url.indexOf(runtimeEnvironment.discoveryKeys) > 0){
      let newReq = request.clone({
        url: runtimeEnvironment.discovery
      });
      return next.handle(newReq);
    }

    if(request.url.indexOf(runtimeEnvironment.oauth2Token) > 0){
      let newReq = request.clone({
        url: runtimeEnvironment.oauth2
      });
      return next.handle(newReq);
    }
    return next.handle(request);
  }

}
