<form #form="ngForm" (ngSubmit)="modifierDate()">

    <p-messages></p-messages>

    <div class="field col-12">
      <div class="p-input-icon-right w-full">
        <p-calendar [(ngModel)]="payload.date" name="dateDossier" id="dateDossier"
                    [showIcon]="true" dateFormat="dd/mm/yy" inputId="icon" class="inputfield w-full"
                    [firstDayOfWeek]="1"></p-calendar>
      </div>
    </div>

    <div class="field col-12">

        <label for="commentaire">{{'DOSSIER.POPUP.DATE.COMMENTAIRE' | translateI18n}}</label>
      <div class="mb-2">
        <small>{{'DOSSIER.DEMANDE.CREATION.MAX_CARACTERE' | translateI18n : '100'}}
          <span [ngClass]="{'is-alert':  commentaireCounter > 400}">({{commentaireCounter}} / 400)</span>
        </small>
      </div>

      <div class="p-input-icon-right w-full">
        <p-editor [(ngModel)]="payload.commentaire" [style]="{'height':'320px'}" name="commentaire"  id="commentaire"
                  [ngClass]="{'editor-error': commentaireCounter > 400 }"
                  (onTextChange)="commentaireTextChange($event)">
          <p-header>
            <app-p-editor-header></app-p-editor-header>
          </p-header>
        </p-editor>
      </div>
    </div>

    <div class="p-dialog-footer">

      <button pButton type="button" label="{{'DOSSIER.POPUP.DATE.ANNULER' | translateI18n}}"
              class="w-100rem uppercase p-button-secondary p-button-outlined mb-2" icon="pi pi-times"
              iconPos="right" (click)="fermer()"></button>

      <button pButton type="submit" label="{{'DOSSIER.POPUP.DATE.MODIFIER' | translateI18n}}" class="w-100rem uppercase mb-2" icon="pi pi-check"
        iconPos="right"></button>


    </div>


</form>

<ngx-spinner  type="ball-scale-multiple">
  <p-progressSpinner></p-progressSpinner>
</ngx-spinner>
