<main class="layout-wrapper">
  <app-navigation></app-navigation>
  <div *ngIf="avisValeur">
    <app-header
    titre="{{avisValeur?.numeroDossier}} / {{avisValeur?.isClientPhysique ? avisValeur?.nomClient  : avisValeur?.nomMoralClient}}"
    sstitre="{{avisValeur?.statutDossier | statutDossier}} {{avisValeur?.numFacture && avisValeur?.statutDossier === factureeStatut ? ' / ' + avisValeur?.numFacture : ''}}"
    [state]="avisValeur?.statutDossier" [signataire]="avisValeur?.nomCompletSignataire" [etatEnvoi]="avisValeur?.etatEnvoi"
    sstitre2="{{avisValeur?.typeLivrable?.code | typeLivrable}}">
    </app-header>
    <app-navigation-contextuelle [AnchorItem]="anchorItem" [ActionsItem]="actionsItem"></app-navigation-contextuelle>
    <div id="main-layout" class="layout-main have-secondary-nav">
      <div class="page is-centered">

        <form class="page-form" [formGroup]="form">
          <!-- Card -->
          <p-messages *ngIf="message" [(value)]="message" [enableService]="false" [escape]="false"></p-messages>


          <!--Après génération de l'avis, message de succès-->
          <div *ngIf="messageSuccessGenerationAvis && avisValeur.typeLivrable?.code === 'TVAVISDOSSI'">
            <p-messages   severity="success" [(value)]="messageSuccessGenerationAvis" [enableService]="false" [escape]="false" [closable]="true">
              <ng-template pTemplate>

                <span class="p-message-icon pi pi-check"></span>
                <span class="p-message-summary">
                  {{messageSuccessGenerationAvis[0]?.summary}}
                  <a  *ngIf="generateAvisValeur" style="cursor: pointer; text-decoration: underline" (click)="downloadRappotDocument()">{{"AVIS_VALEUR.MESSAGE.TELECHARGER" | translateI18n}}</a>
                </span>

                <button type="button" pRipple class=" flex p-element p-message-close p-link" (click)="closableAvisSucessMessage()">
                  <i class="p-message-close-icon pi pi-times"></i><span class="p-ink"></span>
                </button>

              </ng-template>
            </p-messages>
          </div>

          <div class="card-large" id="section0" id="generalites">

            <header>
              <div class="entete" *ngIf="avisValeur?.codeEntiteJuridiqueNegLie === 'AT'">
                <div class="pb-4"><img src="../../assets/images/logo-ate.jpg" style="width: 150px;"></div>
              </div>

              <div class="entete" *ngIf="avisValeur?.codeEntiteJuridiqueNegLie === 'BNPPRE'">
                <img src="../../assets/images/logo-BNPRE.svg">L'immobilier<br>d'un monde<br>qui change
                <div class="label"> <img src="../../assets/images/label-expertise-fr.svg" alt=""> </div>
              </div>
              <div class="titre">{{'AVIS_VALEUR.TITLE' | translateI18n}}<span class="id-dossier">{{'AVIS_VALEUR.NUMERO_DOSSIER' | translateI18n}}{{avisValeur?.numeroDossier}}</span></div>
            </header>

            <!-- ligne -->
            <div class="grid">
              <!-- Colonne -->
              <div class="col-12 md:col-6">
                <section>
                  <h2>{{'AVIS_VALEUR.DEMANDEUR' | translateI18n}}</h2>
                  <p class="locked"><b>{{avisValeur?.entiteClient}}</b></p>
                  <p *ngIf="avisValeur?.entiteClient === 'MRC'" class="locked">
                    <span class="label">{{'DOSSIER.DEMANDE.CREATION.PRETEUR' | translateI18n}} : </span>
                   <b>{{avisValeur?.paramPreteur ? avisValeur?.paramPreteur?.libelle : ' --'}}</b>
                  </p>
                  <p class="locked" *ngIf="avisValeur?.adresseTiers?.numero || avisValeur?.adresseTiers?.nomVoie || avisValeur?.adresseTiers?.codePostal
                   || avisValeur?.adresseTiers?.ville || avisValeur?.adresseTiers?.pays">
                    <b>{{avisValeur?.adresseTiers?.numero}} {{avisValeur?.adresseTiers?.nomVoie}} <br>
                      <span *ngIf="avisValeur?.adresseTiers?.complement">{{avisValeur?.adresseTiers?.complement}} <br></span>
                    {{avisValeur?.adresseTiers?.codePostal}} {{avisValeur?.adresseTiers?.ville}}
                    <span *ngIf="avisValeur?.adresseTiers?.pays">, {{avisValeur?.adresseTiers?.pays?.libelle}}</span></b></p>
                  <p class="locked">
                    <span class="label">{{'AVIS_VALEUR.LABEL.TELEPHONE' | translateI18n}} :</span>
                    <a href="tel:{{avisValeur?.telAgent}}">{{avisValeur?.telAgent ? avisValeur?.telAgent : '--'}}</a>
                  </p>
                  <p class="locked">
                    <span class="label">{{'AVIS_VALEUR.LABEL.PORTABLE' | translateI18n}} : </span >
                    <a href="tel:{{avisValeur?.portableAgent}}">{{avisValeur?.portableAgent ? avisValeur?.portableAgent : '--'}}</a>
                  </p>

                  <p class="locked">
                    <span class="label">{{'AVIS_VALEUR.LABEL.EMAIL' | translateI18n}} :</span>
                    <a href="mailto:{{avisValeur?.emailAgent}}">{{avisValeur?.emailAgent ? avisValeur?.emailAgent : '--'}}</a>
                  </p>
                </section>
              </div>

              <div class="col-12 md:col-6">
                <section>
                  <h2>{{'AVIS_VALEUR.AFFAIRE' | translateI18n}}</h2>
                  <p class="locked"><b><span *ngIf="avisValeur?.isClientPhysique">{{avisValeur?.abreviationCiviliteClient}}</span>
                    {{avisValeur?.nomCompletClient}}</b></p>
                  <p *ngIf="!avisValeur?.isClientPhysique"><b>{{avisValeur?.nomRepresentant}}</b></p>
                  <p class="locked">
                    <span class="label">{{'AVIS_VALEUR.LABEL.NUMERO_CONTRAT' | translateI18n}} :
                    </span><b>{{avisValeur?.numeroContrat ? avisValeur?.numeroContrat : '--'}}</b>
                  </p>
                </section>
              </div>


              <div class="col-12 md:col-6">
                <section>
                  <h2>{{'AVIS_VALEUR.EXPERT' | translateI18n}}</h2>
                  <p class="locked">
                    <b><span *ngIf="avisValeur?.expertAbreviationCivilite">{{avisValeur?.expertAbreviationCivilite}}</span>
                      {{avisValeur?.nomCompletExpert}}
                    </b>
                  </p>
                  <p class="locked">
                    <span class="label">{{'AVIS_VALEUR.LABEL.TELEPHONE' | translateI18n}} :
                    </span><a href="tel:{{avisValeur?.telephoneExpert}}">{{avisValeur?.telephoneExpert ? avisValeur?.telephoneExpert : '--'}}</a>
                  </p>
                  <p class="locked">
                    <span class="label">{{'AVIS_VALEUR.LABEL.PORTABLE' | translateI18n}} :
                    </span><a href="tel:{{avisValeur?.portableExpert}}">{{avisValeur?.portableExpert ? avisValeur?.portableExpert : '--'}}</a>
                  </p>
                  <p class="locked">
                    <span class="label">{{'AVIS_VALEUR.LABEL.DATE_VALEUR' | translateI18n}} : </span><b>{{currentDateTime}}</b>
                  </p>
                </section>
              </div>



              <div class="col-12 md:col-6">

                <section>
                  <h2>{{'AVIS_VALEUR.BIEN_EXPERTISE' | translateI18n}}</h2>
                  <p class="locked">
                    <b>
                      {{avisValeur?.bienAdresse?.numero}} {{avisValeur?.bienAdresse?.nomVoie}} <br>
                      <span *ngIf="avisValeur?.bienAdresse?.complement">{{avisValeur?.bienAdresse?.complement}} <br></span>
                      {{avisValeur?.bienAdresse?.codePostal}} {{avisValeur?.bienAdresse?.ville}}, {{avisValeur?.bienAdresse.pays?.libelle}}
                    </b>
                  </p>
                  <p>
                </section>
              </div>

            </div>

          </div>

          <app-mission formGroupName="mission" [paramTypeValeur]="paramTypeValeur"
                       [paramOccupation]="paramOccupation" [avisValeur]="avisValeur"
                       [submitted]="submitted" [submittedRequired]="submittedRequired"></app-mission>

          <app-designation formGroupName="designation" [submitted]="submitted" [submittedRequired]="submittedRequired"></app-designation>

          <app-situation-geographique formGroupName="situationGeo" [submitted]="submitted" [submittedRequired]="submittedRequired"></app-situation-geographique>

          <app-description formGroupName="description" [avisValeur]="avisValeur" [submitted]="submitted" [submittedRequired]="submittedRequired"></app-description>

          <app-situation-locative formGroupName="situationLocative" [submitted]="submitted" [submittedRequired]="submittedRequired" [avisValeur]="avisValeur"></app-situation-locative>

          <app-elements-appreciation formGroupName="elementApreciation" [submittedRequired]="submittedRequired"></app-elements-appreciation>

          <app-evaluation formGroupName="evaluation" [submitted]="submitted" [submittedRequired]="submittedRequired" [avisValeur]="avisValeur"></app-evaluation>



          <div class="card-large" id="section2">
            <h1 id="annexe">{{'AVIS_VALEUR.ANNEXES' | translateI18n}}</h1>

            <app-liste-document id="document" [idBien]="avisValeur?.idBien" [idDossier]="avisValeur.idDossier"
                                [expertID]="avisValeur.expert"
                                [statutDossier]="avisValeur.statutDossier" [verouillerExpert]="false"></app-liste-document>

          </div>

        </form>
      </div>
    </div>
  </div>
</main>

<ngx-spinner  type="ball-scale-multiple">
  <p-progressSpinner></p-progressSpinner>
</ngx-spinner>
