import {ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {AbstractControl, FormBuilder, FormGroup, FormGroupDirective, Validators} from "@angular/forms";
import {AvisValeurService} from "../../../service/avis-valeur.service";
import {DialogService, DynamicDialogRef} from "primeng/dynamicdialog";
import {Message} from "primeng/api";
import {TranslateService} from "../../../../shared/translate/translate.service";
import {AvisValeurObservableService} from "../../../../shared/observable/avis-valeur-observable.service";
import {AvisValeur} from "../../../models/avis-valeurs/avis-valeur";
import {Validation} from "../../../../shared/providers/validation";

@Component({
  selector: 'app-description',
  templateUrl: './description.component.html',
  styleUrls: ['./description.component.scss']
})
export class DescriptionComponent implements OnInit {

  @Input()
  formGroupName: string;

  @Input()
  avisValeur : AvisValeur

  @Input()
  submitted : boolean;

  @Input()
  submittedRequired : boolean;


  form: FormGroup;

  nbTerrainText : number = 0;
  nbConstructionText : number =0;
  nbDistributionText : number =0;
  nbAmenagementText : number =0;
  nbEtatGeneralText : number = 0;
  nbObservationoText : number =0;

  typeSurfaces : any[];
  surface : any[] = [];

  msgError200 : Message[];
  msgError300 : Message[];
  msgError400 : Message[];
  errorSurface : Message[];
  errorSurfaceValue : boolean;

  constructor(
    private avisValeurService : AvisValeurService,
    private fb : FormBuilder,
    public dialogService: DialogService,
    public ref: DynamicDialogRef,
    private rootFormGroup: FormGroupDirective,
    private translate : TranslateService,
    private cdref: ChangeDetectorRef,
    private avisValeurObservableService : AvisValeurObservableService
  ) { }

  ngOnInit(): void {
    this.form = this.rootFormGroup.control.get(this.formGroupName) as FormGroup;
    this.getAllParamSurface();
    this.nbTerrainText = this.form?.value?.terrain ? this.form?.value?.terrain?.replace(/<[^>]*>/g, '')?.length : 0;
    this.nbObservationoText = this.form?.value?.observation ? this.form?.value?.observation?.replace(/<[^>]*>/g, '')?.length : 0;
    this.nbAmenagementText = this.form?.value?.amenagement ? this.form?.value?.amenagement?.replace(/<[^>]*>/g, '')?.length : 0;
    this.nbConstructionText = this.form?.value?.construction ? this.form?.value?.construction?.replace(/<[^>]*>/g, '')?.length : 0;
    this.nbDistributionText = this.form?.value?.distribution ? this.form?.value?.distribution?.replace(/<[^>]*>/g, '')?.length : 0;
    this.nbEtatGeneralText = this.form?.value?.etatgeneral ? this.form?.value?.etatgeneral?.replace(/<[^>]*>/g, '')?.length : 0;
    this.typeSurface(this.form?.value.typeSurfaces);
    this.msgError200 =[ {severity:'error', summary:  this.translate.getElement("AVIS_VALEUR.MESSAGE.ERROR_CARACTERE_COMMENTAIRE", '200')}];
    this.msgError300 =[ {severity:'error', summary:  this.translate.getElement("AVIS_VALEUR.MESSAGE.ERROR_CARACTERE_COMMENTAIRE", '300')}];
    this.msgError400 =[ {severity:'error', summary:  this.translate.getElement("AVIS_VALEUR.MESSAGE.ERROR_CARACTERE_COMMENTAIRE", '400')}];
  }

  getAllParamSurface()  {
    this.avisValeurService.getAllParamSurface().subscribe(
      data => {
        this.typeSurfaces = data;
      },error => {

      }
    )
  }

  setValeurSurface(surface : number) {
    if(surface === 0) {
      this.form.get('surface').setValue(null);
    }else {
      this.form.get('surface').setValue(surface);
    }
  }

  typeSurface(event : any) {
    let idSurface = event?.idSurface;

    this.surface = [];

    switch (idSurface) {
      case 0 :
        this.surface.push({name : 'SH', value : this.avisValeur.sh});
        this.setValeurSurface(this.avisValeur.sh);
        break;
      case 1 :
        this.surface.push({name : 'SU', value : this.avisValeur.su});
        this.setValeurSurface(this.avisValeur.su);
        break;
      case 2 :
        this.surface.push({name : 'SUP', value : this.avisValeur.sup});
        this.setValeurSurface(this.avisValeur.sup);
        return;
      case 3 :
         this.surface.push({name : 'SDHOP', value : this.avisValeur.sdhop});
         this.setValeurSurface(this.avisValeur.sdhop);
        break;
      default :
        break;
    }

  }

  get f(): { [key: string]: AbstractControl } {
    return this.form.controls;
  }

  terrainTextChange(event: any) {
    this.nbTerrainText =  event?.htmlValue ? event?.htmlValue?.replace(/<[^>]*>/g, '')?.trim()?.length : 0;
  }

  constructionTextChange(event: any) {
    this.nbConstructionText = event?.htmlValue ? event?.htmlValue?.replace(/<[^>]*>/g, '')?.trim()?.length : 0;
  }

  distributionTextChange(event: any) {
    this.nbDistributionText = event?.htmlValue ? event?.htmlValue?.replace(/<[^>]*>/g, '')?.trim()?.length : 0;
  }

  amenagementTextChange(event: any) {
    this.nbAmenagementText = event?.htmlValue ? event?.htmlValue?.replace(/<[^>]*>/g, '')?.trim()?.length : 0;
  }

  etatGeneralTextChange(event: any) {
    this.nbEtatGeneralText =  event?.htmlValue ? event?.htmlValue?.replace(/<[^>]*>/g, '')?.trim()?.length : 0;
  }

  observationTextChange(event: any) {
    this.nbObservationoText = event?.htmlValue ? event?.htmlValue?.replace(/<[^>]*>/g, '')?.trim()?.length : 0;
  }

  obseravationRequired() : boolean {

    if((!this.form?.value?.terrain || this.form?.value?.terrain?.replace(/<[^>]*>/g, '')?.trim()?.length === 0)
      && (!this.form?.value?.construction || this.form?.value?.construction?.replace(/<[^>]*>/g, '')?.trim()?.length === 0)
      && (!this.form?.value?.distribution || this.form?.value?.distribution?.replace(/<[^>]*>/g, '')?.trim()?.length === 0)
      && (!this.form?.value?.amenagement || this.form?.value?.amenagement?.replace(/<[^>]*>/g, '')?.trim()?.length === 0)
      && (!this.form?.value?.etatgeneral || this.form?.value?.etatgeneral?.replace(/<[^>]*>/g, '')?.trim()?.length === 0)
  ) {
      this.form.controls['observation'].setValidators([
        Validators.required, Validation.patternValidatorMaxLengthCaracter(200, {hasError: true}),
        Validation.patternValidatorEmptyCaracter({hasEmptyCaracterError: true})
      ]);
      this.form.controls['observation'].updateValueAndValidity();
      return true;
    }
    this.form.controls['observation'].setValidators([Validation.patternValidatorMaxLengthCaracter(200, {hasError: true})]);
    this.form.controls['observation'].updateValueAndValidity();
    this.submitted = false;
    return false;
  }

  getSurfaceValue(event : any) {
    this.errorSurface = null;
    this.errorSurfaceValue = event.value < 1;
    this.avisValeurObservableService.sendSurface(event.value);
  }
}
