import {ChangeDetectorRef, Component, ElementRef, Input, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {Adresse} from "../models/adresse";

@Component({
  selector: 'app-carte-google-maps',
  templateUrl: './carte-google-maps.component.html',
  styleUrls: ['./carte-google-maps.component.scss']
})
export class CarteGoogleMapsComponent implements OnInit {

  mapOptions: google.maps.MapOptions
  defaultMinZoom = 3;
  defaultMapTypeId = google.maps.MapTypeId.ROADMAP;
  defaultZoomControl = true;
  defaultScaleControl = false;
  defaultStreetViewControl = true;
  disableDoubleClickZoom = true;

  map : google.maps.Map;

  markers : google.maps.Marker[] = [];

  labelOrigin = new google.maps.Point(12, 30);

  @Input()
  adresse : Adresse;

  @Input()
  height : string = '100%';

  coordinates :  google.maps.LatLng;

  zoom : number;

  events : any;


  @ViewChild('gmap', { static: true }) gmap: ElementRef;

  transitLayer : google.maps.TransitLayer
  isTransit : boolean = false

  pngUrl = 'assets/images/markers/marker.png';
  pngUrlGreen  : string = 'assets/images/markers/marker_green.png';

  overlays: any[];
  labelColorGreen = "var(--bnpColor)"
  labelColorBlack = "black"


  latDefault : number = 48.870502;
  lngDefault : number = 2.304897;



  constructor( private cdref: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.initMap();
  }

  ngAfterContentChecked() {
    this.cdref.detectChanges();
  }


  initMap() {

    if(this.adresse?.lng && this.adresse?.lat) {
      this.coordinates = new google.maps.LatLng(this.adresse?.lat, this.adresse?.lng);
      this.zoom = 15;
    }else {
      this.coordinates = new google.maps.LatLng(this.latDefault, this.lngDefault);
      this.zoom = 6;
    }

    this.mapOptions = {
      center:  this.coordinates,
      zoom: this.zoom,
      panControl : true,
      streetViewControl : true,
      minZoom: this.defaultMinZoom,
      mapTypeId: this.defaultMapTypeId,
      zoomControl: this.defaultZoomControl,
      zoomControlOptions: {
        position: google.maps.ControlPosition.RIGHT_BOTTOM
      },
      mapTypeControl: true,
      scaleControl: this.defaultScaleControl,
      streetViewControlOptions: {
        position: google.maps.ControlPosition.RIGHT_BOTTOM
      },
      disableDoubleClickZoom: this.disableDoubleClickZoom,
      // FTEA-579: Avoids zooming with "ctrl" and removes new "full screen" control
      gestureHandling: 'greedy',
      fullscreenControl: true
    };
  }

  setMap(event ? : any, zoom? : any) {

    this.events = event;
    if(this.adresse?.lng && this.adresse?.lat ) {

      this.map = this.events.map;

      if(zoom) {
        this.map.setCenter(this.coordinates)
        this.map.setZoom(zoom);
      }

      let bounds = new google.maps.LatLngBounds();
      let marker = new google.maps.Marker({
        position: {lat: this.adresse?.lat, lng: this.adresse?.lng},
        icon: {
          url: this.pngUrl,
          //origin: new google.maps.Point(0, 0),
          labelOrigin: this.labelOrigin,
          anchor: new google.maps.Point(18, 18),
        },
        title: this.adresse?.adresseFormate,

        map: this.map
      });

      this.overlays = [marker];

      this.addListener(marker);
      bounds.extend(marker.get('position'));
    }
  }

  updateMarkerColorOnClick(marker : google.maps.Marker) {
    let imageIcon;
    let labelColor
    if(marker.get('icon').url === this.pngUrl || marker.get('icon') === this.pngUrlGreen) {
      imageIcon = this.pngUrlGreen
      labelColor = this.labelColorGreen
    }
    else {
      imageIcon = this.pngUrl
      labelColor = this.labelColorBlack
    }
    this.updateMarkerAndLabelColor(marker, labelColor, imageIcon)
  }

  updateMarkerAndLabelColor(marker : google.maps.Marker, labelColor : string, markerUrl : string) {
    let customIcon = {
      url: markerUrl,
      labelOrigin: this.labelOrigin,
      anchor: new google.maps.Point(18, 18),
    }

    marker.setIcon(customIcon)
    let label = marker.getLabel()
    if(label && label.color) label.color = labelColor
    marker.setLabel(label)
  }


  addListener(marker : google.maps.Marker) {

    google.maps.event.addListener(marker, 'click', (event : any) => {
      this.updateMarkerColorOnClick(marker)
    });

    google.maps.event.addListener(marker, 'mouseover', () => {
      this.updateMarkerAndLabelColor(marker, this.labelColorGreen, this.pngUrlGreen)
    });

    google.maps.event.addListener(marker, 'mouseout', () => {
      this.updateMarkerAndLabelColor(marker, this.labelColorBlack , this.pngUrl)
    });

    return marker
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(!changes.adresse.firstChange) {
      let adresse = changes?.adresse?.currentValue;
      this.coordinates = new google.maps.LatLng(adresse.lat, adresse.lng);
      this.setMap(this.events, 16);
    }
  }

}
