<main class="layout-wrapper">
  <app-navigation></app-navigation>
  <app-header titre="Accès refusé"></app-header>

  <div id="main-layout" class="layout-main">
    <div class="page is-centered">

      <p-messages [(value)]="message" [closable]="false"></p-messages>

      <div class="is-centered-img">
        <img src="/assets/images/illustration-permission-denied-404.svg" />
      </div>

    </div>
  </div>
</main>
