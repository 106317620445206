export const enJson = {

  "HEADER" : {
    "SSTITRE_PROFIL_OR_DASHBORD" : "Last connection"
  },

  "NAVIGATION" :{
    "ACCUEIL" : "Home",
    "AFFECTATION" : "Assignment",
    "VALIDATION" : "Validation",
    "DOSSIER" : "FOLDER",
    "DEMANDE" : {
      "CREATION" : "Create request",
      "ITEMDEMANDE" : {
        "RAPPORT" : "Expert report",
        "AVIS" :  "Avis de valeur"
      },
      "SUIVI" : "Follow request"
    },
    "RESEARCH" : "Research",
    "PROFIL" : "Profile",
    "DECONNEXION" : "Disconnect"
  },

  "DOSSIER" : {
    "AFFECTATION" : {
      "HEADER": {
        "TITLE": "Awaiting Assignment",
        "SSTITLE" :"folders"
      },
      "MESSAGE" : {
        "SELECTDOSSIER" : "Selected folder",
        "DESELECTDOSSIER" : "Folder removed from selection",
        "AFFECTE" : "Your folders have been assigned to",
        "NOTAFFECT" : "Error during assignment"
      }
    }
  },


  "FORM_CHANGE_PASSWORD" : {
    "TITLE" : "Change your password",
    "LABEL" : {
      "CURRENT_PASSWORD" : "Your current password",
      "NEW_PASSWORD" : "New Password",
      "CONFIRM_PASSWORD" : "Repeat password",
      "BUTTON_SUBMIT" : "Submit"
    },
    "POLITIQUEPASSWORD": {
      "N_CHARACTER" : "Must be at least 8 characters",
      "LOWERCASE" : "Must contain at least 1 in Capital Case",
      "UPPERCASE" : "Must contain at least 1 Letter in Small Case",
      "NUMBER" : "Must contain at least 1 number",
      "SPECIAL_CHARACTER" : "Must contain at least 1 Special Character"
    },

    "MESSAGE" : {
      "ERROR" : {
        "NEW_PASSWORD": "Password is required",
        "POLITIQUE_PASSWORD": "The new password entered does not respect the Group password policy",
        "NEWPASSWORD_NOT_MATCH_CURRENTPASSWORD": "New password and confirm password do not match",
        "CURRENT_PASSWORD" : "The old password entered is not correct",
        "ERROR_SERVER" : "An error has occurred"

      },
      "SUCCESS" : {
        "CHANGE" : "Password changed!"
      }
    }
  },

  "LANGUE_APP" : {
    "LABEL" : "Change the language"
  }

}





