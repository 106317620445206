import {AuthConfig} from "angular-oauth2-oidc";

const { version: appVersion } = require('package.json');

const authConfig : AuthConfig = {
  skipIssuerCheck: true,
  // responseType: 'id_token',
  responseType: 'code',
  requestAccessToken: false,
  scope: 'openid groups',
  showDebugInformation: true,
  strictDiscoveryDocumentValidation : false,
};

export const environment = {
  production: false,
  version: appVersion,
  isInterne : true,
  enableAdminTech:true,
  ssoConfig : authConfig,
  discoveryKeys : '/discovery/keys',
  oauth2Token : '/adfs/oauth2/token',
  aideFilename : 'BNPPRE_Valuation_VALEA_PremiereConnexion.pdf'
};
