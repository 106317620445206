
<div>

  <div class="tableaux tableaux-documents" id="listeDocumentNonLus">
    <p-messages></p-messages>
    <p-table [value]="listeDocumentNonLus" [paginator]="totalRecords > settings.pagination?.rowsDocument" [rows]="settings.pagination?.rowsDocument" dataKey="id"
             currentPageReportTemplate="{{'DOCUMENT.NONLUS.NOMBRE_RESULTATS' | translateI18n}}"
             [showCurrentPageReport]="true" [rowsPerPageOptions]="settings.pagination?.rowsPerPageOptionsDocument" paginatorPosition="top">

      <ng-template pTemplate="header">
        <tr>
          <th pSortableColumn="auditDateCreation">{{'DOCUMENT.NONLUS.ENTETE.DATE' | translateI18n}}
          </th>
          <th pSortableColumn="numeroContrat">{{'DOCUMENT.NONLUS.ENTETE.NUMERO_CONTRAT' | translateI18n}}
          </th>
          <th pSortableColumn="numeroDossier" class="">{{'DOCUMENT.NONLUS.ENTETE.NUMERO_DOSSIER' | translateI18n}}
          </th>
          <th pSortableColumn="nomClient" class="">{{'DOCUMENT.NONLUS.ENTETE.NOM_CLIENT' | translateI18n}}
          </th>
          <th pSortableColumn="nomDocument" class="">{{'DOCUMENT.NONLUS.ENTETE.NOM' | translateI18n}}
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-document>
        <tr>
          <td class="width-date">{{document.auditDateCreation | date : 'dd/MM/yyyy'}}</td>
          <td>{{document.numeroContrat}}</td>
          <td>{{document.numeroDossier}}</td>
          <td>{{document.nomClient}}</td>
          <td class="file"><a (click)="downloadDocument(document.guid,document.idLecture,document.nom)">{{document.nom}}</a></td>
        </tr>
      </ng-template>
    </p-table>
  </div>

  <div class="p-dialog-footer">

  </div>

</div>

<ngx-spinner type="ball-scale-multiple"><p-progressSpinner></p-progressSpinner></ngx-spinner>
