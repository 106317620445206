<form [formGroup]="form" >
  <div class="card-large" id="section1">
    <h1 id="mission">I - {{'AVIS_VALEUR.MISSION' | translateI18n}}</h1>

    <div class="grid">

      <div class="col-12">
        <section>
          {{'AVIS_VALEUR.TEXTE.LA_SOCIETE' | translateI18n}} {{avisValeur?.libelleEntiteJuridiqueNegLie}},
          <span *ngIf="avisValeur.codeEntiteJuridiqueNegLie !== 'AT'">{{'AVIS_VALEUR.TEXTE.MEMBRE_AFSEI' | translateI18n}}</span>
          {{'AVIS_VALEUR.TEXTE.ADHERENT_MISSIONNEE' | translateI18n : (avisValeur?.dateDemande | dateFormatPipe)}}
          <span *ngIf="avisValeur?.isPhysiqueTiers">{{avisValeur?.abreviationCiviliteTiers}} </span>{{avisValeur?.nomCompletTiers}},
          {{'AVIS_VALEUR.TEXTE.REPRESENTEE_PAR' | translateI18n : (avisValeur?.civiliteDemandeur?.abreviation != null ? avisValeur?.civiliteDemandeur?.abreviation : '') : avisValeur?.nomCompletDemandeur}}{{avisValeur?.displayDateConvention ? ',' : '.'}}
          <span *ngIf="avisValeur?.displayDateConvention">{{'RAPPORT_EXPERTISE.TEXTE.CONVENTION' | translateI18n : (avisValeur?.dateContratConvention ? ( avisValeur?.dateContratConvention | date : 'dd/MM/yyyy') : ' --')}}</span>
        </section>
      </div>

      <div class="col-12">

        <section>
          <h2> {{'AVIS_VALEUR.OBJ_MISSION' | translateI18n}}</h2>
          <div class="field input-inline">

            <!--<label for="typeValeur" class="label"></label>-->
            {{'AVIS_VALEUR.MISSION_TEXTE_1' | translateI18n}}
            <p-dropdown  [options]="paramTypeValeur"  formControlName="typeValeur" optionLabel="libelle" name="typeValeur"  id="typeValeur"
                         [ngClass]="{'ng-dirty ng-invalid': submittedRequired && f.typeValeur.errors}"
                         emptyMessage="{{'DOSSIER.MESSAGE.AUCUN_RESULTAT' | translateI18n}}"
                         placeholder="{{'PLACEHOLDER.CHOOSE' | translateI18n}}" class="obligatoire" (onChange)="typeValeurChange($event)"></p-dropdown>
            {{'AVIS_VALEUR.MISSION_TEXTE_2' | translateI18n}}
            <p-dropdown  [options]="paramOccupation"  formControlName="typeOccupation" optionLabel="label" name="typeOccupation"  id="typeOccupation"
                         [ngClass]="{'ng-dirty ng-invalid': submittedRequired && f.typeOccupation.errors}"
                         emptyMessage="{{'DOSSIER.MESSAGE.AUCUN_RESULTAT' | translateI18n}}"
                         placeholder="{{'PLACEHOLDER.CHOOSE' | translateI18n}}" class="obligatoire"></p-dropdown>
            {{'AVIS_VALEUR.MISSION_TEXTE_3' | translateI18n}}
            <span>{{avisValeur?.missionContexteLabel}}.</span>

          </div>


        </section>

      </div>

      <div class="field col-12 field-line">

        <div class="mb-2">
          <h2 class="m-0 obligatoire">{{'AVIS_VALEUR.PIECES_COMMUNIQUE' | translateI18n}}</h2>
          <small>{{'AVIS_VALEUR.MESSAGE.MAX_CARACTERE' | translateI18n : '400'}}
            <span [ngClass]="{'is-alert':  f.piecesCommuniques.hasError('hasError')}">({{nbPiecesCommuniquesText}} / 400)</span>
          </small>
        </div>

        <div *ngIf="f.piecesCommuniques.hasError('hasError')">
          <p-messages  [(value)]="msgError"></p-messages>
        </div>

        <p-editor formControlName="piecesCommuniques" [style]="{'height':'120px'}" name="piecesCommuniques"
                  id="piecesCommuniques" class="w-full"
                  [ngClass]="{'editor-error':  (submittedRequired && f.piecesCommuniques.errors) || f.piecesCommuniques.hasError('hasError') }"
                  (onTextChange)="piecesCommuniquesTextChange($event)">
          <p-header>
            <app-p-editor-header></app-p-editor-header>
          </p-header>
        </p-editor>
      </div>


      <div class="field col-12 field-line">

        <p class="text-justify">
          {{'AVIS_VALEUR.TEXTE.DISCLAIMER1' | translateI18n}}<br>
          {{'AVIS_VALEUR.TEXTE.DISCLAIMER2' | translateI18n}}<br>
          {{'AVIS_VALEUR.TEXTE.DISCLAIMER3' | translateI18n}}
        </p>

      </div>




    </div>


  </div>
</form>
