<form [formGroup]="form" >

  <div class="card-large" id="section1">
    <h1 id="description">IV - {{'RAPPORT_EXPERTISE.DESCRIPTION' | translateI18n}}</h1>

    <div class="grid">

      <div class="col-12">
        <section>
          <app-photos-description [idBien]="rapport.idBien"
                                categorie="{{'RAPPORT_EXPERTISE.LABEL.PHOTO_EXTERIEUR' | translateI18n}}"
                                [idCategorie]="3"
                                [idDossier]="rapport.idDossier"
                                [titre]="titrePhotoExterieur"
                                [keyMessage]="'uploadPhotoExt'"></app-photos-description>

        </section>
      </div>


      <div class="col-12">
        <h2>{{'RAPPORT_EXPERTISE.TERRAIN' | translateI18n}}</h2>
        <div class="grid">
          <div class="col">
              <div class="field input-ghost">
                <label for="supTerrain" class="label">{{'RAPPORT_EXPERTISE.LABEL.SUPERFECIE' | translateI18n}} :</label>
                <p-inputNumber formControlName="supTerrain"
                               id="supTerrain" placeholder="Renseignez" suffix=" m²"
                               [size]=20 [min]="0" [maxlength]="18"
                               (onInput)="changeSupTerrain($event)"
                               [minFractionDigits]="2" [maxFractionDigits]="5"></p-inputNumber>
              </div>

              <div class="field input-ghost">
                <label for="topolographie" class="label">{{'RAPPORT_EXPERTISE.LABEL.TOPOGRAPHIE' | translateI18n}} :</label>
                <p-dropdown  [options]="topographies"  formControlName="topolographie" optionLabel="libelle" name="topolographie"  id="topolographie"
                             emptyMessage="{{'DOSSIER.MESSAGE.AUCUN_RESULTAT' | translateI18n}}"
                             placeholder="{{'PLACEHOLDER.CHOOSE' | translateI18n}}" ></p-dropdown>
              </div>

          </div>

          <div class="col">

              <div class="field input-ghost">
                <label for="configuration" class="label">{{'RAPPORT_EXPERTISE.LABEL.CONFIGURATION' | translateI18n}} :</label>
                <p-dropdown  [options]="configurations"  formControlName="configuration" optionLabel="libelle" name="configuration"  id="configuration"
                             emptyMessage="{{'DOSSIER.MESSAGE.AUCUN_RESULTAT' | translateI18n}}"
                             placeholder="{{'PLACEHOLDER.CHOOSE' | translateI18n}}"></p-dropdown>
              </div>

              <div class="field input-ghost">
                <label for="entretien" class="label">{{'RAPPORT_EXPERTISE.LABEL.ENTRETIEN' | translateI18n}} :</label>
                <p-dropdown  [options]="entretiens"  formControlName="entretien" optionLabel="libelle" name="entretien"  id="entretien"
                             emptyMessage="{{'DOSSIER.MESSAGE.AUCUN_RESULTAT' | translateI18n}}"
                             placeholder="{{'PLACEHOLDER.CHOOSE' | translateI18n}}" ></p-dropdown>
              </div>
          </div>
        </div>
      </div>


      <div class="field col-12 field-line">


        <div class="mb-2">
          <small>
            {{'RAPPORT_EXPERTISE.MESSAGE.MAX_CARACTERE' | translateI18n : '3000'}}
            <span [ngClass]="{'is-alert':  f.rapportDescription.hasError('hasError')}">({{nbDescriptionText}} / 3000)</span>
          </small>
        </div>

        <div *ngIf="submitted && f.rapportDescription.hasError('hasError')">
          <p-messages  [(value)]="msgError3000" key="rapportDescription"></p-messages>
        </div>
        <p-editor formControlName="rapportDescription" [style]="{'height':'320px'}" name="rapportDescription"  id="rapportDescription" class="w-full"
                  [ngClass]="{'editor-error':  f.rapportDescription.hasError('hasError')  }"
                  (onTextChange)="descriptionTextChange($event)">
          <p-header>
            <app-p-editor-header></app-p-editor-header>
          </p-header>
        </p-editor>
      </div>





      <div class="col-12">
        <h2>{{'RAPPORT_EXPERTISE.CONSTRUCTION' | translateI18n}}</h2>

        <app-table-construction [rapport]="rapport"></app-table-construction>

        <div class="field col-12 field-line">

          <div class="mb-2">
            <small>{{'RAPPORT_EXPERTISE.MESSAGE.MAX_CARACTERE' | translateI18n : '6000'}}
              <span [ngClass]="{'is-alert':  f.construction.hasError('hasError')}">({{nbcaractConstruction}} / 6000)</span>
            </small>
          </div>
          <div *ngIf="submitted && f.construction.hasError('hasError')">
            <p-messages  [(value)]="msgError6000"></p-messages>
          </div>

          <p-editor formControlName="construction" [style]="{'height':'380px'}" name="construction" class="w-full"
                    [ngClass]="{'editor-error': (submitted && f.construction.errors) || f.construction.hasError('hasError')}"
                    (onTextChange)="caractConstructionTextChange($event)">
            <p-header>
              <app-p-editor-header></app-p-editor-header>
            </p-header>
          </p-editor>
        </div>
      </div>




      <div class="field col-12 field-line">

        <h2>{{'RAPPORT_EXPERTISE.DISTRIBUTION' | translateI18n}}</h2>

        <div class="mb-2">
          <small>{{'RAPPORT_EXPERTISE.MESSAGE.MAX_CARACTERE' | translateI18n : '6000'}}
            <span [ngClass]="{'is-alert':  f.distribution.hasError('hasError')}">({{nbcaractDistrubution}} / 6000)</span>
          </small>
        </div>
        <div *ngIf="submitted && f.distribution.hasError('hasError')">
          <p-messages  [(value)]="msgError6000" key="distribution"></p-messages>
        </div>

        <p-editor formControlName="distribution" [style]="{'height':'380px'}" name="distribution" class="w-full"
                  [ngClass]="{'editor-error': (submitted && f.distribution.errors) || f.distribution.hasError('hasError')}"
                  (onTextChange)="caractDistributionTextChange($event)">
          <p-header>
            <app-p-editor-header></app-p-editor-header>
          </p-header>
        </p-editor>

      </div>



    <div class="col-12">

      <h2>{{'RAPPORT_EXPERTISE.AMENAGEMENT_INTERRIEUR' | translateI18n}}</h2>
      <app-table-amenagements-interieurs [rapport]="rapport"></app-table-amenagements-interieurs>

      <div class="field field-line mt-4">

        <div class="mb-2">
          <small>{{'RAPPORT_EXPERTISE.MESSAGE.MAX_CARACTERE' | translateI18n : '6000'}}
            <span [ngClass]="{'is-alert':  f.amenagementsInterieurs.hasError('hasError')}">({{nbcaractAmenagementsIinterieurs}} / 6000)</span>
          </small>
        </div>
        <div *ngIf="submitted && f.amenagementsInterieurs.hasError('hasError')">
          <p-messages  [(value)]="msgError6000" key="amenagementInterieur"></p-messages>
        </div>

        <p-editor formControlName="amenagementsInterieurs" [style]="{'height':'380px'}" name="amenagementsInterieurs" class="w-full"
                  [ngClass]="{'editor-error': (submitted && f.amenagementsInterieurs.errors) || f.amenagementsInterieurs.hasError('hasError')}"
                  (onTextChange)="caractAmenagementsIinterieursTextChange($event)">
          <p-header>
            <app-p-editor-header></app-p-editor-header>
          </p-header>
        </p-editor>



     </div>
   </div>


      <div class="col-12">
        <h2>{{'RAPPORT_EXPERTISE.EQUIPEMENT' | translateI18n}}</h2>
       <div class="mb-2">
         <small>{{'RAPPORT_EXPERTISE.MESSAGE.MAX_CARACTERE' | translateI18n : '6000'}}
           <span [ngClass]="{'is-alert':  f.equipement.hasError('hasError')}">({{nbcaractEquipement}} / 6000)</span>
         </small>
       </div>
       <div *ngIf="submitted && f.equipement.hasError('hasError')">
         <p-messages  [(value)]="msgError6000" key="equipement"></p-messages>
       </div>

       <p-editor formControlName="equipement" [style]="{'height':'380px'}" name="construction" class="w-full"
                 [ngClass]="{'editor-error': (submitted && f.equipement.errors) || f.equipement.hasError('hasError')}"
                 (onTextChange)="caractEquipementTextChange($event)">
         <p-header>
           <app-p-editor-header></app-p-editor-header>
         </p-header>
       </p-editor>

      </div>


      <div class="col-12">
        <h2>{{'RAPPORT_EXPERTISE.ETAT_GENERAL' | translateI18n}}</h2>
        <div class="mb-2">
          <small>{{'RAPPORT_EXPERTISE.MESSAGE.MAX_CARACTERE' | translateI18n : '3000'}}
            <span [ngClass]="{'is-alert':  f.etatGeneral.hasError('hasError')}">({{nbcaractEtatGeneral}} / 3000)</span>
          </small>
        </div>
        <div *ngIf="submitted && f.etatGeneral.hasError('hasError')">
          <p-messages  [(value)]="msgError3000" key="etatGeneral"></p-messages>
        </div>

        <p-editor formControlName="etatGeneral" [style]="{'height':'320px'}" name="etatGeneral" class="w-full"
                  [ngClass]="{'editor-error': (submitted && f.etatGeneral.errors) || f.etatGeneral.hasError('hasError')}"
                  (onTextChange)="caractEtatGeneralTextChange($event)">
          <p-header>
            <app-p-editor-header></app-p-editor-header>
          </p-header>
        </p-editor>

      </div>

      <div class="col-12">
        <section>
          <app-photos-description [idBien]="rapport.idBien"
                                  categorie="{{'RAPPORT_EXPERTISE.LABEL.PHOTO_INTERIEUR' | translateI18n}}"
                                [idCategorie]="4"
                                [idDossier]="rapport.idDossier"
                                [titre]="titrePhotoInterieur"
                                [keyMessage]="'uploadPhotoInt'"></app-photos-description>
        </section>
      </div>



      <h2>{{'RAPPORT_EXPERTISE.SURFACES' | translateI18n}}</h2>

      <div class="col-12">
        <div class="field input-ghost field-line">
          <label class="label obligatoire">{{'RAPPORT_EXPERTISE.LABEL.RESERVES' | translateI18n}} :</label>

          <small *ngIf="submittedRequired && f.reserve?.errors?.required" class="p-error block mr-4">
            {{'MESSAGE.ERROR.CHAMPS_OBLIGATOIRE' | translateI18n}}
          </small>

          <div class="mt-4">
            <div *ngFor="let reverve of reserves; let i=index" class="field-checkbox mr-4">
              <p-radioButton [id]="reverve.idParamReserve" formControlName="reserve"
                             [ngClass]="{'ng-dirty ng-invalid': submittedRequired && f.reserve?.errors?.required}"
                             [value]="reverve.idParamReserve" [label]="reverve.libelle"></p-radioButton>
            </div>
          </div>


        </div>
      </div>

      <div class="col-12 md:col-6 field input-ghost">
        <label for="typeSurface" class="label">{{'RAPPORT_EXPERTISE.LABEL.TYPE_SURFACE' | translateI18n}} :</label>

        <p-dropdown  [options]="typeSurfacesRapport"  formControlName="typeSurface" optionLabel="libelle" name="typeSurface"  id="typeSurface"
                     emptyMessage="{{'DOSSIER.MESSAGE.AUCUN_RESULTAT' | translateI18n}}"
                     class="obligatoire"
                     [ngClass]="{'ng-dirty ng-invalid': submittedRequiredSynthese && f.typeSurface?.errors?.required}"
                     placeholder="{{'PLACEHOLDER.CHOOSE' | translateI18n}}" (onChange)="typeSurface($event.value)"></p-dropdown>
        <small *ngIf="submittedRequiredSynthese && f.typeSurface?.errors?.required" class="p-error block mr-4">
          {{'MESSAGE.ERROR.CHAMPS_OBLIGATOIRE' | translateI18n}}
        </small>
      </div>

      <div class="col-12 md:col-6">

        <div class="field input-ghost locked" *ngIf="form?.value?.typeSurface?.idTypeSurface === 7">
          <span class="label">{{'RAPPORT_EXPERTISE.LABEL.SURFACE' | translateI18n}} : </span>
          <b> {{!form?.value?.valeurSurface ? '-- m²': (form?.value?.valeurSurface | decimal) + ' m²' }}</b>
        </div>

        <div class="field input-ghost" *ngIf="form?.value?.typeSurface?.idTypeSurface !== 7">

          <label for="valeurSurface" class="label">{{'RAPPORT_EXPERTISE.LABEL.SURFACE' | translateI18n}} <span >({{surface[0]?.name ? surface[0]?.name : ' N/A '}})</span> : </label>

          <p-inputNumber formControlName="valeurSurface"
                         id="valeurSurface"
                         placeholder="{{'RAPPORT_EXPERTISE.LABEL.RENSEIGNER' | translateI18n}}"
                         [ngClass]="{'ng-invalid ng-dirty': submittedRequiredSynthese && f.valeurSurface?.errors}"
                         class="obligatoire"
                         suffix=" m²"
                         [size]=20
                         [min]="0"
                         [maxlength]="18"
                         [minFractionDigits]="2"
                         [maxFractionDigits]="5"
                         (onInput)="changeValueSurface($event)">
          </p-inputNumber>

          <small *ngIf="submittedRequiredSynthese && f.valeurSurface?.errors?.min" class="p-error block mr-4">
          {{'RAPPORT_EXPERTISE.MESSAGE.ERROR_SURFACE' | translateI18n}}
          </small>

        </div>

      </div>

      <div class="col-12" *ngIf="form?.value?.typeSurface?.idTypeSurface !== 7">
        <app-table-surfaces
          [typeSurfaceRapport]="typeSurfacesRapport"
          [idBien]="rapport?.idBien" [idDossier]="rapport?.idDossier"></app-table-surfaces>
      </div>

      <div class="col-12" *ngIf="form?.value?.typeSurface?.idTypeSurface !== 7">
        <app-table-annexes-bien [idBien]="rapport?.idBien" [idDossier]="rapport?.idDossier"></app-table-annexes-bien>
      </div>

      <div class="field col-12 field-line mt-3">
        <div class="p-radiobutton-group inline-group">
          <label class="label">{{'RAPPORT_EXPERTISE.LABEL.DETAIL_SURFACE' | translateI18n}} :</label>
          <p-radioButton  [value]="true" formControlName="existsDetailSurface" label="OUI" (onClick)="choiseDetailSurface()"></p-radioButton>
          <p-radioButton [value]="false" formControlName="existsDetailSurface" label="NON" (onClick)="choiseDetailSurface()"></p-radioButton>
        </div>
      </div>


      <div class="col-12" *ngIf="form?.value?.existsDetailSurface">
        <div class="mb-2" [ngClass]="{'obligatoire':requiredDetailSurface}">
          <small>{{'RAPPORT_EXPERTISE.MESSAGE.MAX_CARACTERE' | translateI18n : '3000'}}
            <span [ngClass]="{'is-alert':  f.detailSurface.hasError('hasError')}">({{nbcaractDetailSurface}} / 3000)</span>
          </small>
        </div>
        <div *ngIf="(submitted || submittedRequired) && f.detailSurface.hasError('hasError')">
          <p-messages  [(value)]="msgError3000" key="detailSurface"></p-messages>
        </div>

        <p-editor formControlName="detailSurface" [style]="{'height':'320px'}" name="detailSurface" class="w-full"
                  [ngClass]="{'editor-error': (submittedRequired && requiredDetailSurface && f.detailSurface?.errors?.required)
                                              || f.detailSurface.hasError('hasError')}"
                  (onTextChange)="caractDetailSurfaceTextChange($event)">
          <p-header>
            <app-p-editor-header></app-p-editor-header>
          </p-header>
        </p-editor>

      </div>


      </div>


  </div>

</form>
