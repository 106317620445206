import {ChangeDetectorRef, Component, Input, OnDestroy, OnInit} from '@angular/core';
import {AvisValeur} from "../../../models/avis-valeurs/avis-valeur";
import {AbstractControl, FormBuilder, FormGroup, FormGroupDirective, Validators} from "@angular/forms";
import {Message, MessageService} from "primeng/api";
import {DialogService, DynamicDialogRef} from "primeng/dynamicdialog";
import {TranslateService} from "../../../../shared/translate/translate.service";
import {AvisValeurService} from "../../../service/avis-valeur.service";
import {AvisValeurObservableService} from "../../../../shared/observable/avis-valeur-observable.service";
import {Subscription} from "rxjs";

@Component({
  selector: 'app-evaluation',
  templateUrl: './evaluation.component.html',
  styleUrls: ['./evaluation.component.scss']
})
export class EvaluationComponent implements OnInit, OnDestroy {

  @Input()
  formGroupName: string;

  @Input()
  submitted : boolean;

  @Input()
  submittedRequired : boolean;

  @Input()
  avisValeur : AvisValeur;

  form: FormGroup;

  msgError : Message[];

  methodeValorisation : any[];
  regimeFiscalVenal : any[];
  regimeFiscalLocatif : any[];
  delaiCom : any[]
  liquidites : any[]


  nbDescmethode = 0;
  typeValeur : any;

  etablissements : any[];

  currentDate = new Date();
  surface : number;
  abbatValueError : boolean = false;

  private subscriptionSurface : Subscription;
  private subscriptionTypeValeur : Subscription;

  constructor(
    private fb : FormBuilder,
    public dialogService: DialogService,
    public ref: DynamicDialogRef,
    private rootFormGroup: FormGroupDirective,
    private cdref: ChangeDetectorRef,
    private message: MessageService,
    private translate : TranslateService,
    private avisValeurService : AvisValeurService,
    private avisValeurObservableService : AvisValeurObservableService
  ) {}

  ngOnInit(): void {
    this.form = this.rootFormGroup.control.get(this.formGroupName) as FormGroup;
    this.msgError =[ {severity:'error',
      summary:  this.translate.getElement("AVIS_VALEUR.MESSAGE.ERROR_CARACTERE_COMMENTAIRE", '1000')}];
    this.getAllMethodeValorisation();
    this.nbDescmethode = this.form?.value?.descmethode ? this.form?.value?.descmethode?.replace(/<[^>]*>/g, '')?.length : 0;
    this.getAllRegimeFiscalVenal();
    this.getAllRegimeFiscalLocatif();
    this.getAllDelaiCommercialisation();
    this.getAllLiquidite();
    this.getAllVilleImpl();
    this.avisValeurObservableService.getTypeValeur().subscribe( data => {
      if(data) {
        this.getTypeValeur(data);
        this.requiredValueIfTypeValeurContainsValeurVenal(data);
        this.requiredValueValeur(data);
      }
    });

    this.calculPrixrM2IfSurfaceChanged();
  }

  ngOnDestroy() {
    this.subscriptionTypeValeur.unsubscribe();
    this.subscriptionSurface.unsubscribe();
  }

  getAllRegimeFiscalLocatif()  {
    this.subscriptionTypeValeur = this.avisValeurService.getAllRegimeFiscalLocatif().subscribe(
      data => {
        if(data) {
          this.regimeFiscalLocatif = data;
        }
      },error => {
      }
    )
  }



  getAllRegimeFiscalVenal()  {
    this.avisValeurService.getAllRegimeFiscalVenal().subscribe(
      data => {
        if(data) {
          this.regimeFiscalVenal = data;
        }
      },error => {
      }
    )
  }

  getAllMethodeValorisation()  {
    this.avisValeurService.getAllMethodeValorisation().subscribe(
      data => {
        if(data) {
          this.methodeValorisation = data;
        }
      },error => {
      }
    )
  }

  getAllDelaiCommercialisation()  {
    this.avisValeurService.getAllDelaiCommercialisation().subscribe(
      data => {
        if(data) {
          this.delaiCom = data;
          //let delaiCommercialisation = this.delaiCom.find(rfv => rfv.idDelaiCommercialisation === Number(this.avisValeur?.delaiCommercialisation));
          //this.form.get('delaiCommercialisation').setValue(delaiCommercialisation);
        }
      },error => {
      }
    )
  }

  getAllLiquidite()  {
    this.avisValeurService.getAllLiquidite().subscribe(
      data => {
        if(data) {
          this.liquidites = data;
        }
      },error => {
      }
    )
  }

  getAllVilleImpl()  {
    this.avisValeurService.getAllVilleImpl().subscribe(
      data => {
        if(data) {
          this.etablissements = data;
        }
      },error => {
      }
    )
  }


  getTypeValeur(valeur : any)  {
    this.typeValeur = {idTypeValeur : valeur.idTypeValeur, libelle : valeur.libelle, };
    if(this.typeValeur) {
      switch (this.typeValeur?.idTypeValeur) {
        case 1 :
          this.typeValeur['label1'] = 'une valeur vénale de';
          return this.typeValeur;
        case 2 :
          this.typeValeur['label1'] = 'une valeur locative de';
          return this.typeValeur;
        case 3 :
          this.typeValeur['label1'] = 'une valeur vénale de';
          this.typeValeur['label2'] = 'une valeur après travaux de';
          return this.typeValeur;
        case 4 :
          this.typeValeur['label1'] = 'une valeur en état futur d\'achèvement';
          return this.typeValeur;
        case 5 :
          this.typeValeur['label1'] = 'une valeur vénale de';
          this.typeValeur['label2'] = 'une valeur locative de';
          return this.typeValeur;
        default :
          return null;

      }
    }
  }

  caractMethodeCalculTextChange(event: any) {
    this.nbDescmethode = event?.htmlValue ? event?.htmlValue?.replace(/<[^>]*>/g, '')?.trim()?.length : 0;
  }

  /**
   * si on change la valeur(valeur vénale, locative, après traveaux, futur achevement), ça recalcul son prix m2
   * @param event
   * @param typeVal
   */
  calculPrixM2IfValueChanged(event : any, typeVal : number) {
    let value = event.value;
    switch (typeVal) {
      case 1 :
        this.form.get('prixM2ValeurGlobaleEstime').setValue(this.calculPrixrM2(this.surface, value));
        return;
      case 2 :
        this.form.get('prixM2ValeurLocative').setValue(this.calculPrixrM2(this.surface, value));
        return this.typeValeur;
      case 3 :
        this.form.get('prixM2ValeurApresTravaux').setValue(this.calculPrixrM2(this.surface, value));
        return;
      case 4 :
        this.form.get('prixM2ValeurFuturAchevement').setValue(this.calculPrixrM2(this.surface, value));
        return;
      default :
        return null;
    }
  }

  calculPrixrM2IfSurfaceChanged() {
    this.subscriptionSurface = this.avisValeurObservableService.getSurface().subscribe(surface => {
      this.setValuePrixM2(this.typeValeur, surface);
    });
  }

  /**
   * calcul du prix m2
   * @param surf :  surface
   * @param valeur :  valeur
   */
  calculPrixrM2(surf : number, valeur : number) : number {
    if(surf && surf > 0 && valeur >= 0) {
      this.surface = surf;
      let prixm2 = valeur / this.surface;
      //prixm2 = Math.round(prixm2 * 100) / 100;
      return prixm2;
    }
    return  null;
  }

  /**
   * recalcul le prix
   * @param typeValeur
   * @param surface
   */
  setValuePrixM2(typeValeur : any, surface : number) {
    if(typeValeur) {
      switch (typeValeur?.idTypeValeur) {
        case 1 :
          this.form.get('prixM2ValeurGlobaleEstime').setValue(this.calculPrixrM2(surface, this.form?.value?.valeurGlobaleEstime));
          break;
        case 2 :
          this.form.get('prixM2ValeurLocative').setValue(this.calculPrixrM2(surface, this.form?.value?.valeurLocative));
          break;
        case 3 :
          this.form.get('prixM2ValeurGlobaleEstime').setValue(this.calculPrixrM2(surface, this.form?.value?.valeurGlobaleEstime));
          this.form.get('prixM2ValeurApresTravaux').setValue(this.calculPrixrM2(surface, this.form?.value?.valeurApresTravaux));
          break;
        case 4 :
          this.form.get('prixM2ValeurFuturAchevement').setValue(this.calculPrixrM2(surface, this.form?.value?.valeurFuturAchevement));
          break;
        case 5 :
          this.form.get('prixM2ValeurGlobaleEstime').setValue(this.calculPrixrM2(surface, this.form?.value?.valeurGlobaleEstime));
          this.form.get('prixM2ValeurLocative').setValue(this.calculPrixrM2(surface, this.form?.value?.valeurLocative));
          break;
        default :
          break;
      }
    }
  }

  requiredValueValeur(typevaleur : any) {
      switch (typevaleur?.idTypeValeur) {
        case 1 :
            this.requiredValeurVenal();
          break;
        case 2 :
          this.requiredValeurLocative();
          break;
        case 3 :
          this.requiredValeurApresTravauxAndValeurVenal();
          return;
        case 4 :
          this.requiredValeurFuturAchevement();
          break;
        case 5 :
          this.requiredValeurVenalAndLocative();
          break;
        default :
          break;
      }
  }

  requiredValeurVenal() {
    this.requiredValue(true, 'valeurGlobaleEstime');
    this.requiredValue(true, 'regimeFiscalVenal');
    this.requiredValue(false, 'valeurLocative');
    this.requiredValue(false, 'regimeFiscalLocatif');

    this.requiredValue(false, 'valeurApresTravaux');
    this.requiredValue(false, 'regimefiscalApresTraveaux');

    this.requiredValue(false, 'valeurFuturAchevement');
    this.requiredValue(false, 'regimefiscalFuturAchevement');
  }

  requiredValeurLocative() {
    this.requiredValue(true, 'valeurLocative');
    this.requiredValue(true, 'regimeFiscalLocatif');

    this.requiredValue(false, 'valeurGlobaleEstime');
    this.requiredValue(false, 'regimeFiscalVenal');

    this.requiredValue(false, 'valeurApresTravaux');
    this.requiredValue(false, 'regimefiscalApresTraveaux');

    this.requiredValue(false, 'valeurFuturAchevement');
    this.requiredValue(false, 'regimefiscalFuturAchevement');
  }

  requiredValeurApresTravauxAndValeurVenal() {
    this.requiredValue(true, 'valeurGlobaleEstime');
    this.requiredValue(true, 'regimeFiscalVenal');
    this.requiredValue(true, 'valeurApresTravaux');
    this.requiredValue(true, 'regimefiscalApresTraveaux');

    this.requiredValue(false, 'valeurLocative');
    this.requiredValue(false, 'regimeFiscalLocatif');

    this.requiredValue(false, 'valeurFuturAchevement');
    this.requiredValue(false, 'regimefiscalFuturAchevement');
  }

  requiredValeurVenalAndLocative() {
    this.requiredValue(true, 'valeurGlobaleEstime');
    this.requiredValue(true, 'regimeFiscalVenal');
    this.requiredValue(true, 'valeurLocative');
    this.requiredValue(true, 'regimeFiscalLocatif');

    this.requiredValue(false, 'valeurApresTravaux');
    this.requiredValue(false, 'regimefiscalApresTraveaux');

    this.requiredValue(false, 'valeurFuturAchevement');
    this.requiredValue(false, 'regimefiscalFuturAchevement');
  }

  requiredValeurFuturAchevement() {
    this.requiredValue(true, 'valeurFuturAchevement');
    this.requiredValue(true, 'regimefiscalFuturAchevement');

    this.requiredValue(false, 'valeurGlobaleEstime');
    this.requiredValue(false, 'regimeFiscalVenal');
    this.requiredValue(false, 'valeurLocative');
    this.requiredValue(false, 'regimeFiscalLocatif');
    this.requiredValue(false, 'valeurApresTravaux');
    this.requiredValue(false, 'regimefiscalApresTraveaux');

  }


  requiredValue(required : boolean, value : string) {
    if(required) {
      this.form.controls[value].setValidators(Validators.required);
    }else {
      this.form.controls[value].setErrors(null);
    }
  }

  requiredValueIfTypeValeurContainsValeurVenal(typevaleur : any) {
    if(typevaleur.idTypeValeur === 1 || typevaleur?.idTypeValeur === 3 || typevaleur?.idTypeValeur === 5) {
      this.form.controls['liquidite'].setValidators(Validators.required);
      this.form.controls['delaiCommercialisation'].setValidators(Validators.required);
      if(this.avisValeur?.typeTiers === 'TTBANQUE') {
        this.form.controls['decote'].setValidators(Validators.required);
      }
    }
    else {
      this.form.controls['liquidite'].setErrors(null);
      this.form.controls['delaiCommercialisation'].setErrors(null);
      this.form.controls['decote'].setErrors(null);
    }
  }

  get f(): { [key: string]: AbstractControl } {
    return this.form.controls;
  }
  ngAfterContentChecked() {
    this.cdref.detectChanges();
  }


  onChangeAbbatValue(event: any) {
    this.abbatValueError = event.value < 0 || event.value > 100;
  }
}
