import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {RestDatasource} from "./rest.datasource";
import {Dossier} from "../models/dossiers/dossier";
import {ItemCriteriaResarchDossier} from "../models/dossiers/item-criteria-resarch-dossier";
import {CreationDemande} from "../models/dossiers/creation-demande";
import {ChangementRapide} from '../models/dossiers/changement-rapide';
import {Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class DossierService {

  constructor(private http: HttpClient, private datasource : RestDatasource) {}

  affectationDossier(idDossiers : number[], idUserExpert : number, idUserAssistant : number) {
    return this.datasource.affectationDossier(idDossiers, idUserExpert, idUserAssistant);
  }

  getListDossierAaffecterBis(paginationObject : any) {
    return this.datasource.getListDossierAaffecterBis(paginationObject);
  }

  getStatsDossier() {
    return this.datasource.getStatsDossier();
  }

  getAllDepartement() {
    return this.datasource.getAllDepartement();
  }

  getAllPays(bien : boolean) {
    return this.datasource.getAllPays(bien);
  }

  updateClientAndAdresseBienAffectation(dossier : Dossier) {
    return this.datasource.updateClientAndAdresseBienAffectation(dossier);
  }

  getTypeLivrableUser(idDossier : number) {
    return this.datasource.getTypeLivrableUser(idDossier);
  }

  getDemandeByCriteria(items : ItemCriteriaResarchDossier) {
    return this.datasource.getDemandeByCriteria(items);
  }

  findAllCivilite() {
   return  this.datasource.findAllCivilite();
  }

  createDemande(creationdemande : CreationDemande, files: File[]) {
    return this.datasource.createDemande(creationdemande, files);
  }

  getDosierById(idDossier : number) {
    return this.datasource.getDosierById(idDossier);
  }

  repasserAAffecter(dossier : Dossier) {
    return this.datasource.repasserAAffecter(dossier);
  }

  duplicationDossier(idDossier : number, codeLivrable : string, idAgence : number) {
    return this.datasource.duplicationDossier(idDossier, codeLivrable, idAgence);
  }

  annulationDossier(dossier : Dossier) {
    return this.datasource.annulationDossier(dossier);
  }

  reactivationDossier(dossier : Dossier) {
    return this.datasource.reactivationDossier(dossier);
  }

  relancerDossier(idDossier: number) {
    return this.datasource.relancerDossier(idDossier);
  }

  getQualiteList() {
    return this.datasource.getAllQualiteContact();
  }

  getAllParamPourcentageNegociation(){
    return this.datasource.getAllParamPourcentageNegociation();
  }

  updateFicheDossier(dossier : Dossier) {
    return this.datasource.updateFicheDossier(dossier);
  }

  modifierHonoraires(payload: ChangementRapide) {
    return this.datasource.modifierHonorairesDossier(payload);
  }

  modifierDateContact(payload: ChangementRapide) {
    return this.datasource.modifierDateContactDossier(payload);
  }

  modifierDateVisite(payload: ChangementRapide) {
    return this.datasource.modifierDateVisiteDossier(payload);
  }

  rejectLivrable(dossier : Dossier) {
    return this.datasource.rejectLivrable(dossier);
  }

  verouillerDossier(dossier : Dossier) {
    return this.datasource.verouillerDossier(dossier);
  }

  debloquerDossierVerrouiller(dossier : Dossier) {
    return this.datasource.debloquerDossierVerrouiller(dossier);
  }

  getAllUtilisateurSignataires() {
    return this.datasource.getAllUtilisateurSignataires();
  }

  validerLivrable(dossier : Dossier) {
    return this.datasource.validerLivrable(dossier);
  }

  envoyerProjet(dossier: Dossier, guidDocument : string) {
    return this.datasource.envoyerProjet(dossier, guidDocument);
  }

  getListAgentByAgence(idDossier : number) {
    return this.datasource.getListAgentByAgence(idDossier);
  }

  checkIfAgentCreateDossierIsInactif(idDossier: number) {
    return this.datasource.checkIfAgentCreateDossierIsInactif(idDossier);
  }
}
