import {Demande} from "./demande";
import {Tiers} from "../tiers/tiers";
import {Adresse} from "../adresse";
import {Bien, Client} from "./creation-demande";
import {Mission} from "./mission";
import {InfoExpertise} from "./info-expertise";
import {FraisFournisseur} from "./frais-fournisseur";
import {ReferentielValeur} from "../referentiel-valeur";
import {DetailFacture} from "../facture/detail-facture";
import {DossierAvisSansVisite} from "./dossier-avis-sans-visite";
import {Signataire} from "../user/signataire";

export class Dossier {
  idDossier : number;
  numeroDossier : string;
  statut: string;
  idCreateurDemande : number;
  nomCreateurAudit : string;

  isExpertise : boolean;

  valeurVenale: number;

  tiers : Tiers;
  demande : Demande;
  client : Client;
  bien : Bien;
  mission : Mission;
  adresse : Adresse;
  adresseContact : Adresse;

  dateVisite : Date;
  numeroContrat : string;
  commentviRapportDossier : string;
  commentcontactRapportDossier : string;
  dateContactDossier : Date;

  commentaire : string;

  dateAffectation : Date;
  dateAnnulation : Date;
  dateEstimation : Date;
  infoExpertise : InfoExpertise;

  valeurLocative : number;
  valeurApresTravaux : number;
  valeurFuturAchevement : number;
  valeurJuridique : number;
  delaiCommercialisation : any;
  valeurGlobaleEstimee : number;
  pourcentagePilote1 : number;
  pourcentagePilote2 : number;
  pourcentagePilote3 : number;

  motifAnnulation : ReferentielValeur;

  fraisFournisseurs : FraisFournisseur[] = [];
  paramOccupationLabel : any;
  piecesTransmises : string;

  paramTypeRegimej : any;

  dossierAvisSansVisite : DossierAvisSansVisite;

  ir : string;

  detailFacture : DetailFacture[];

  etatEnvoi : string;
  validationVerrouilleur : number;
  dateValidationVerrouillage : Date;
  nomCompletVerouilleur : string;
  dossierIsLoked : boolean;
  signataire : Signataire;

  synthese : boolean;

  constructor(fields?: Partial<Dossier>) {
    if (fields) {
      Object.assign(this, fields);
    }
  }



}
