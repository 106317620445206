import {ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {AbstractControl, FormBuilder, FormGroup, FormGroupDirective} from "@angular/forms";
import {AvisValeurService} from "../../../service/avis-valeur.service";
import {DialogService, DynamicDialogRef} from "primeng/dynamicdialog";
import {TranslateService} from "../../../../shared/translate/translate.service";
import {Message} from "primeng/api";

@Component({
  selector: 'app-situation-geographique',
  templateUrl: './situation-geographique.component.html',
  styleUrls: ['./situation-geographique.component.scss']
})
export class SituationGeographiqueComponent implements OnInit {

  @Input()
  formGroupName: string;

  @Input()
  submitted : boolean;

  @Input()
  submittedRequired : boolean;

  form: FormGroup;

  nbcaractSituationGeo : number = 0;

  msgError : Message[];

  constructor(
    private avisValeurService : AvisValeurService,
    private fb : FormBuilder,
    public dialogService: DialogService,
    public ref: DynamicDialogRef,
    private rootFormGroup: FormGroupDirective,
    private translate : TranslateService,
    private cdref: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    this.form = this.rootFormGroup.control.get(this.formGroupName) as FormGroup;
    this.nbcaractSituationGeo = this.form?.value?.situation ? this.form?.value?.situation?.replace(/<[^>]*>/g, '')?.length : 0;
    this.msgError =[ {severity:'error', summary:  this.translate.getElement("AVIS_VALEUR.MESSAGE.ERROR_CARACTERE_COMMENTAIRE", '2000')}];
  }

  get f(): { [key: string]: AbstractControl } {
    return this.form.controls;
  }

  caractSituationGeoTextChange(event: any) {
    let value = event.textValue;
    this.nbcaractSituationGeo = event?.htmlValue ? event?.htmlValue?.replace(/<[^>]*>/g, '')?.trim()?.length : 0;
  }

}
