export enum ConfigConstants {
  TYPE_FOURNISSEUR_EXPERT_RESEAU = "TFEXPEXT",
  TYPE_FRAIS_EXPERTISE = "EXPERTIS",
  TYPE_FRAIS_DEPLACEMENT = "DEPLCMNT",
  TYPE_FRAIS = "TFINTERC",
  TYPE_FOURNISSEUR_DEVISE = "EUR",
  DATE_CONTACT = "CONTACT",
  DATE_VISITE = "VISITE",
  ETAT_ENVOI_INIT = "ETATLIVRABLEINIT",
  ETAT_ENVOI_SYNTHESE = "ETATLIVRABLESYNTHESE",
  ETAT_ENVOI_A_VALIDER = "ETATLIVRABLEAVALIDER",
  ETAT_ENVOI_PROJET = "ETATLIVRABLEPRJ",

  //Type generation document
  RAPPORT_EXPERTISE = "RAPPORT_EXPERTISE",
  AVIS_VALEUR = "AVIS_VALEUR",
  SYNTHESE = "SYNTHESE"
}
