<form [formGroup]="form" >
  <div class="card-large" id="section1">
    <h1 id="designation">II - {{'RAPPORT_EXPERTISE.DESIGNATION' | translateI18n}}</h1>

    <div class="grid">

      <section >

        <div class="field input-ghost locked">
          <span class="label">{{ 'RAPPORT_EXPERTISE.LABEL.TYPE_BIEN' | translateI18n}}  : </span> <b>{{rapport?.typlogieBien | caseWord}}</b>
        </div>
        <div class="field input-ghost locked">
          <span class="label"> {{ 'RAPPORT_EXPERTISE.LABEL.ADRESSE_BIEN' | translateI18n}} : </span>
          <b>
            {{rapport?.bienAdresse?.numero}} {{rapport?.bienAdresse?.nomVoie}},
            <span *ngIf="rapport?.bienAdresse?.complement">{{rapport?.bienAdresse?.complement}},</span>
            {{rapport?.bienAdresse?.codePostal}} {{rapport?.bienAdresse?.ville}},
            {{rapport?.bienAdresse?.pays?.libelle}}
          </b>
        </div>

        <div class="field input-ghost">
          <label for="typeRegimejRapport" class="label">{{ 'RAPPORT_EXPERTISE.LABEL.SITUATION_JURIDIQUE' | translateI18n}} :</label>
          <p-dropdown [options]="paramTypeRegimejRapport" formControlName="typeRegimejRapport"
                      optionLabel="libelle" name="typeRegimejRapport"  id="typeRegimejRapport"
                      emptyMessage="{{'DOSSIER.MESSAGE.AUCUN_RESULTAT' | translateI18n}}"
                      placeholder="{{'PLACEHOLDER.CHOOSE' | translateI18n}}">
          </p-dropdown>
        </div>
      </section>

      <div class="field col-12 field-line">

        <div class="mb-2">
          <h2 class="m-0 ">{{'RAPPORT_EXPERTISE.NUM_LOT_TANTIMES' | translateI18n}}</h2>
          <small>{{'RAPPORT_EXPERTISE.MESSAGE.MAX_CARACTERE' | translateI18n : '3000'}}
            <span [ngClass]="{'is-alert':  f.numLotTantiemes.hasError('hasError')}">({{nbNumLotTantiemes}} / 3000)</span>
          </small>
        </div>

        <div *ngIf="submitted && f.numLotTantiemes.hasError('hasError')">
          <p-messages  [(value)]="msgError" key="numLotTantiemes"></p-messages>
        </div>

        <p-editor formControlName="numLotTantiemes" [style]="{'height':'320px'}" name="numLotTantiemes"
                  id="numLotTantiemes" class="w-full"
                  [ngClass]="{'editor-error': f.numLotTantiemes.hasError('hasError')}"
                  (onTextChange)="numLotTantiemesTextChange($event)">
          <p-header>
            <app-p-editor-header></app-p-editor-header>
          </p-header>
        </p-editor>
      </div>

       <div class="col-12 cadastre-text-adrea" *ngIf="rapport?.designationcadastre">
         <div class="mb-2">
           <h2>{{'RAPPORT_EXPERTISE.CADASTRE' | translateI18n}}</h2>
           <small>{{'RAPPORT_EXPERTISE.MESSAGE.MAX_CARACTERE' | translateI18n : '3 000'}}
             <span>({{nbDesignationcadastre}} / 3000)</span>
           </small>
         </div>
         <textarea [rows]="15" [cols]="2" style="width: 100%;" pInputTextarea formControlName="designationcadastre" [autoResize]="true"
                     (ngModelChange)="detectChangeComment($event)" maxlength="3000"></textarea>
        </div>

      <div class="col-12" *ngIf="!rapport?.designationcadastre">
        <h2>{{'RAPPORT_EXPERTISE.CADASTRE' | translateI18n}}</h2>
        <p-messages  *ngIf="messageSuppressionBiencadastre" [(value)]="messageSuppressionBiencadastre"></p-messages>
        <div class="mb-3">
          <button pButton pRipple label="{{'RAPPORT_EXPERTISE.LABEL.ADD_NEW_LINE' | translateI18n}}" icon="pi pi-plus"
                  class="p-button-sm p-button-rounded" (click)="openNew()"></button>
        </div>
        <p-table [value]="bienCadastres" [rows]="settings.pagination?.rowsDocument"  [showCurrentPageReport]="true"
                 [paginator]="bienCadastres?.length > settings.pagination?.rowsDocument" [rowsPerPageOptions]="settings.pagination?.rowsPerPageOptionsDocument"
                 [globalFilterFields]="['commune','lieudit','section','numeroParcelle', 'contenance ']"
                 dataKey="idBienCadastre" paginatorPosition="top"
                 currentPageReportTemplate="Affichage {first} à {last} sur {totalRecords}" >
          <ng-template pTemplate="header">
            <tr>
              <th></th>
              <th pSortableColumn="commune" style="min-width:15rem">{{'RAPPORT_EXPERTISE.LABEL.COMMUNE' | translateI18n}} <p-sortIcon field="commune"></p-sortIcon></th>
              <th pSortableColumn="lieudit">{{'RAPPORT_EXPERTISE.LABEL.LIEU_DIT' | translateI18n}} <p-sortIcon field="lieudit"></p-sortIcon></th>
              <th pSortableColumn="section" style="min-width:10rem">{{'RAPPORT_EXPERTISE.LABEL.SECTION' | translateI18n}} <p-sortIcon field="section"></p-sortIcon></th>
              <th pSortableColumn="numeroParcelle">{{'RAPPORT_EXPERTISE.LABEL.NUMERO_PARCELLE' | translateI18n}}<p-sortIcon field="numeroParcelle"></p-sortIcon></th>
              <th pSortableColumn="contenance" style="min-width:10rem">{{'RAPPORT_EXPERTISE.LABEL.CONTENANCE' | translateI18n}} <p-sortIcon field="contenance"></p-sortIcon></th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-biec>
            <tr>
              <td class="text-left">
                <button type="button" pButton pRipple icon="inc-icon inc-icon-trash is-small"
                        pTooltip="{{'BUTTON_LABEL.DELETE' | translateI18n}}" tooltipPosition="top"
                        class="p-button-rounded mr-2" (click)="deleteBienCadatre(biec)"></button>
                <button type="button" pButton pRipple icon="inc-icon inc-icon-edit is-small"
                        pTooltip="{{'BUTTON_LABEL.MODIFY' | translateI18n}}" tooltipPosition="top"
                        class="p-button-rounded p-button-success" (click)="editBienCadastre(biec)"></button>
              </td>
              <td>{{biec.commune}}</td>
              <td>{{biec.lieudit}}</td>
              <td>{{biec.section}}</td>
              <td>{{biec.numeroParcelle}}</td>
              <td>{{biec.contenance}}</td>
            </tr>
          </ng-template>

        </p-table>
      </div>

      <div class="field col-12">
        <h2 class="m-0">{{'RAPPORT_EXPERTISE.PLAN_CADASTRAL' | translateI18n}}</h2>
        <p-messages [(value)]="message" [escape]="false" key="uploadPhotoCadastre"></p-messages>
        <div class="grid gallery justify-content-center mt-2">

          <p-progressSpinner *ngIf="progressSpinner"  [style]="{width: '100px', height: '100px'}"
                             styleClass="custom-spinner justify-content-center flex mt-6" strokeWidth="3" fill="var(--surface-ground)"
                             animationDuration=".5s"></p-progressSpinner>
          <div *ngIf="!progressSpinner" class="field col-12" style="width: 90%;">


            <figure *ngIf="documentCadastre" >
              <img [src]="documentCadastre?.lienImage" alt="">
              <figcaption class="flex">
                <div class="cta flex">
                  <app-upload-photo [displayLegende]="false"
                        [document]="documentCadastre"
                        [addNewPhoto]="false"
                        [parent]="this"
                        class="update"
                        [keyMessage]="'uploadPhotoCadastre'"
                        [idObjetPrincipal]="rapport?.idBien"
                        [idDossier]="rapport.idDossier"
                        [idCategorie]="5" [multiple]="false">
                  </app-upload-photo>

                  <button type="button" pButton pRipple icon="pi pi-trash"
                          pTooltip="{{'RAPPORT_EXPERTISE.LABEL.SUPPRIMER' | translateI18n}}"
                          tooltipPosition="top" class="p-button-rounded p-button-sm w-full m-0"
                          (click)="deleteImage(documentCadastre)"></button>
                </div>
              </figcaption>
            </figure>


            <app-upload-photo
              [displayLegende]="false" label="{{'RAPPORT_EXPERTISE.LABEL.INSERER_PLAN_CADASTRAL' | translateI18n}}"
              [document]="documentCadastre" [addNewPhoto]="true"
              [parent]="this"
              [idDossier]="rapport?.idDossier"
              [idObjetPrincipal]="rapport?.idBien"
              [idCategorie]="5"
              [multiple]="false"></app-upload-photo>
          </div>

        </div>
      </div>


      <div class="field col-12 field-line">

        <div class="mb-2">
          <h2 class="m-0 ">{{'RAPPORT_EXPERTISE.OBSERVATIONS' | translateI18n}}</h2>
          <small>{{'RAPPORT_EXPERTISE.MESSAGE.MAX_CARACTERE' | translateI18n : '3000'}}
            <span [ngClass]="{'is-alert':  f.observations.hasError('hasError')}">({{nbObservations}} / 3000)</span>
          </small>
        </div>

        <div *ngIf="submitted && f.observations.hasError('hasError')">
          <p-messages  [(value)]="msgError" key="observations"></p-messages>
        </div>

        <p-editor formControlName="observations" [style]="{'height':'320px'}" name="observations"
                  id="observations" class="w-full"
                  [ngClass]="{'editor-error':f.observations.hasError('hasError') }"
                  (onTextChange)="observationsTextChange($event)">
          <p-header>
            <app-p-editor-header></app-p-editor-header>
          </p-header>
        </p-editor>
      </div>

    </div>



  </div>
</form>




<p-dialog [(visible)]="bienCadastreDialog" [style]="{width: '450px'}" header="{{'RAPPORT_EXPERTISE.LABEL.REFERENCE_CADASTRALE' | translateI18n}}" [modal]="true" styleClass="p-fluid">

    <ng-template pTemplate="content">
      <p-messages  *ngIf="messageBienCadastre" [(value)]="messageBienCadastre"></p-messages>
      <form [formGroup]="bienCadastreForm" class="page-form">
      <div class="field">
        <label for="commune" [ngClass]="{'p-error': submittedBienCadastre  && fBieC.commune.invalid}" class="obligatoire">
          {{'RAPPORT_EXPERTISE.LABEL.COMMUNE' | translateI18n}}
        </label>
        <input type="text" class="inputfield" pInputText id="commune" formControlName="commune"
               [ngClass]="{'ng-dirty': submittedBienCadastre  && fBieC.commune.invalid}" [maxLength]="30" required/>

        <div *ngIf="submittedBienCadastre" class="pt-2">
          <small *ngIf="fBieC.commune?.errors?.pattern" class="p-error block">
            {{'MESSAGE.ERROR.REGEX_PATTERN' | translateI18n}}</small>
          <small class="p-error" *ngIf="fBieC.commune?.errors?.required">{{'MESSAGE.ERROR.CHAMPS_OBLIGATOIRE' | translateI18n}}</small>
        </div>
      </div>
      <div class="field">
        <label for="lieudit" [ngClass]="{'p-error': submittedBienCadastre  && fBieC.lieudit?.errors?.pattern}">
          {{'RAPPORT_EXPERTISE.LABEL.LIEU_DIT' | translateI18n}}</label>
        <input type="text" id="lieudit" pInputText pInputTextarea formControlName="lieudit" [maxLength]="30" />
        <small *ngIf="fBieC.lieudit?.errors?.pattern" class="p-error block">
          {{'MESSAGE.ERROR.REGEX_PATTERN' | translateI18n}}</small>
      </div>

      <div class="field">
        <label for="section" [ngClass]="{'p-error': submittedBienCadastre  && fBieC.section.invalid}" class="obligatoire">
          {{'RAPPORT_EXPERTISE.LABEL.SECTION' | translateI18n}}</label>
        <input type="text" pInputText id="section" formControlName="section"
               [ngClass]="{'ng-dirty': submittedBienCadastre  && fBieC.section?.invalid}" [maxLength]="10" required/>
        <div *ngIf="submittedBienCadastre" class="pt-2">
          <small *ngIf="fBieC.section?.errors?.pattern" class="p-error block">
            {{'MESSAGE.ERROR.REGEX_PATTERN' | translateI18n}}</small>
          <small class="p-error" *ngIf="fBieC.section?.errors?.required">{{'MESSAGE.ERROR.CHAMPS_OBLIGATOIRE' | translateI18n}}</small>
        </div>
      </div>

      <div class="field">
        <label for="numeroParcelle" [ngClass]="{'p-error': submittedBienCadastre  && fBieC.numeroParcelle.invalid}" class="obligatoire">
          {{'RAPPORT_EXPERTISE.LABEL.NUMERO_PARCELLE' | translateI18n}}</label>
        <input type="text" pInputText id="numeroParcelle" formControlName="numeroParcelle"
               [ngClass]="{'ng-dirty': submittedBienCadastre  && fBieC.numeroParcelle?.invalid}" [maxLength]="5"  required/>
        <div *ngIf="submittedBienCadastre" class="pt-2">
          <small *ngIf="fBieC.numeroParcelle?.errors?.pattern" class="p-error block">
            {{'MESSAGE.ERROR.REGEX_PATTERN' | translateI18n}}</small>
          <small class="p-error" *ngIf="fBieC.numeroParcelle?.errors?.required">{{'MESSAGE.ERROR.CHAMPS_OBLIGATOIRE' | translateI18n}}</small>
        </div>
      </div>

      <div class="field">
        <label for="contenance" [ngClass]="{'p-error': submittedBienCadastre  && fBieC.contenance.invalid}" class="obligatoire">
          {{'RAPPORT_EXPERTISE.LABEL.CONTENANCE' | translateI18n}}</label>
        <input type="text" pInputText id="contenance" formControlName="contenance" [maxLength]="20"
               [ngClass]="{'ng-dirty': submittedBienCadastre  && fBieC.contenance?.invalid}" required/>
        <div *ngIf="submittedBienCadastre" class="pt-2">
          <small *ngIf="fBieC.contenance?.errors?.pattern" class="p-error block">
            {{'MESSAGE.ERROR.REGEX_PATTERN' | translateI18n}}</small>
          <small class="p-error" *ngIf="fBieC.contenance?.errors?.required">{{'MESSAGE.ERROR.CHAMPS_OBLIGATOIRE' | translateI18n}}</small>
        </div>
      </div>
      </form>
    </ng-template>

    <ng-template pTemplate="footer">
      <button pButton type="button" pRipple label="{{'BUTTON_LABEL.ANNULER' | translateI18n}}"
              icon="pi pi-times" class="uppercase p-button-secondary p-button-outlined mb-2"
              (click)="hideDialog()"></button>
      <button pButton type="submit" pRipple label="{{'BUTTON_LABEL.ENREGISTRER' | translateI18n}}"
              icon="pi pi-save" class="ppercase mb-2" (click)="saveReferenceCadastre()"></button>
    </ng-template>

</p-dialog>



