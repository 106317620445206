import {ElementRef, EventEmitter, Injectable, NgZone, Output} from '@angular/core';
import {Subject} from "rxjs";

/**
 * @Author : mohamed II BAYO
 */

@Injectable({
  providedIn: 'root'
})
export class GoogleMapServiceService {

  public placeSubject: Subject<google.maps.places.PlaceResult> = new Subject<google.maps.places.PlaceResult>();

  public placeObservable = this.placeSubject.asObservable();
  establishmentAddress: Object;

  @Output() setAddress: EventEmitter<any> = new EventEmitter();

  formattedAddress: string;
  formattedEstablishmentAddress: string;

  phone: string;
  address: Object;


  constructor(public zone: NgZone) { }

  getPlaceAutocomplete(addressText : ElementRef) {

    if(typeof google !== 'undefined') {
      const autocomplete = new google.maps.places.Autocomplete(addressText.nativeElement,
        {
          //componentRestrictions: { country: ['FR', 'NC', 'YT', 'GF', 'GP', 'MQ', 'RE', 'PM', 'MF', 'PF', 'BL', 'TF', 'WF'] },
          //types: [adressType]  // 'establishment' / 'address' / 'geocode'
        });
      google.maps.event.addListener(autocomplete, 'place_changed', () => {
        const place = autocomplete.getPlace();
        this.placeSubject.next(place);
      });
    }
  }

  invokeEvent(place: Object) {
    this.setAddress.emit(place);
  }

  getAddress(place: object) {
    this.address = place['formatted_address'];
    this.phone = this.getPhone(place);
    this.formattedAddress = place['formatted_address'];
    this.zone.run(() => this.formattedAddress = place['formatted_address']);
  }

  getEstablishmentAddress(place: object) {
    this.establishmentAddress = place['formatted_address'];
    this.phone = this.getPhone(place);
    this.formattedEstablishmentAddress = place['formatted_address'];
    this.zone.run(() => {
      this.formattedEstablishmentAddress = place['formatted_address'];
      this.phone = place['formatted_phone_number'];
    });
  }

  getAddrComponent(place : any, componentTemplate: any) {
    let result;

    for (let i = 0; i < place.address_components.length; i++) {
      const addressType = place.address_components[i].types[0];
      if (componentTemplate[addressType]) {
        result = place.address_components[i][componentTemplate[addressType]];
        return result;
      }
    }
    return;
  }

  getStreetNumber(place: any) {
    const COMPONENT_TEMPLATE = { street_number: 'short_name' };
    return this.getAddrComponent(place, COMPONENT_TEMPLATE);
  }

  getStreet(place: any) {
    const COMPONENT_TEMPLATE = { route: 'long_name' };
    return this.getAddrComponent(place, COMPONENT_TEMPLATE);
  }

  getCity(place: any) {
    const COMPONENT_TEMPLATE = { locality: 'long_name' };
    return this.getAddrComponent(place, COMPONENT_TEMPLATE);
  }

  getState(place: any) {
    const COMPONENT_TEMPLATE = { administrative_area_level_1: 'short_name' };
    return this.getAddrComponent(place, COMPONENT_TEMPLATE);
  }

  getDistrict(place: any) {
    const COMPONENT_TEMPLATE = { administrative_area_level_2: 'short_name' };
    return this.getAddrComponent(place, COMPONENT_TEMPLATE);
  }

  getCountryShort(place: any) {
    const COMPONENT_TEMPLATE = { country: 'short_name' };
    return this.getAddrComponent(place, COMPONENT_TEMPLATE);
  }

  getCountry(place: any) {
    const COMPONENT_TEMPLATE = { country: 'long_name' };
    return this.getAddrComponent(place, COMPONENT_TEMPLATE);
  }

  getPostCode(place: any) {
    const COMPONENT_TEMPLATE = { postal_code: 'long_name' };
    return this.getAddrComponent(place, COMPONENT_TEMPLATE);
  }

  getPhone(place: any) {
    const COMPONENT_TEMPLATE = { formatted_phone_number: 'formatted_phone_number' };
    return this.getAddrComponent(place, COMPONENT_TEMPLATE);
  }

  getCodeLat(place : any) {
    return place.geometry.location.lat()
  }

  getCodeLng(place : any) {
    return place.geometry.location.lng()
  }

}

