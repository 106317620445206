import {Injectable} from '@angular/core';
import {ItemCriteriaResarchDossier} from "../../models/dossiers/item-criteria-resarch-dossier";

@Injectable({
  providedIn: 'root'
})
export class LocalStorageRechercheDossierDemandeurService {

  static readonly ITEM_CRITERE_RECHERCHE_DOSSIER = "itemCritereRechercheDossier";

  constructor() { }

  public setCritereRechercheDemande(item : ItemCriteriaResarchDossier): void {
    this.removeDataCritereRechercheDemande();
    localStorage.setItem(LocalStorageRechercheDossierDemandeurService.ITEM_CRITERE_RECHERCHE_DOSSIER, JSON.stringify(item));
  }

  public removeDataCritereRechercheDemande() {
    localStorage.removeItem(LocalStorageRechercheDossierDemandeurService.ITEM_CRITERE_RECHERCHE_DOSSIER);
  }

  public getResponseRechercheDossierDemandeur() {
    return localStorage.getItem(LocalStorageRechercheDossierDemandeurService.ITEM_CRITERE_RECHERCHE_DOSSIER);
  }
}
