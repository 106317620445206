import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {LocalStorageService} from "../local-storage/local-storage.service";
import {UserService} from "../user/user.service";

@Injectable({
  providedIn: 'root'
})
export class RoleGuard implements CanActivate {

  constructor(private localStorageUser : LocalStorageService, private router : Router, private userService : UserService) {
  }

  async canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot) {

    const roles = await this.userService.getUserConnectedProfilesById();

    if(roles) {
      const found = roles?.some(r => route.data.roles.includes(r));

      if (found) {
        return true;
      }
    }

    this.router.navigateByUrl("/role-denied");
    return false;

  }

}
