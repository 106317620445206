<form  class="page-form">
  <p-messages [value]="messageReject" *ngIf="messageReject"></p-messages>
  <h2>{{'DOSSIER.GESTIONNAIRE.MESSAGE.REJETER.CHOIX_TYPE_REJET' | translateI18n}}</h2>
  <div class="field-radiobutton" *ngIf="dossier.synthese">
    <p-radioButton name="ETATLIVRABLEINIT" value="ETATLIVRABLEINIT" [(ngModel)]="valueRejectLivrable"
                   [disabled]="codeLivrable === 'TVAVISDOSSI'"    inputId="ETATLIVRABLEINIT"></p-radioButton>
    <label class="label">{{returnTexteByTypeLivrable()}}</label>
  </div>
  <div class="field-radiobutton">
    <p-radioButton name="ETATLIVRABLESYNTHESE" value="ETATLIVRABLESYNTHESE" [(ngModel)]="valueRejectLivrable" inputId="ETATLIVRABLESYNTHESE"></p-radioButton>
    <label class="label">{{'DOSSIER.' + codeLivrable | translateI18n}}</label>
  </div>
</form>

<div class="mt-6">
  <span><i class="pi pi-exclamation-triangle" style="font-size: 1.5rem"></i></span>
  {{'DOSSIER.GESTIONNAIRE.MESSAGE.ACTION_CONFIRMATION' | translateI18n}}
</div>

<div class="p-dialog-footer">
  <button pButton type="button" pRipple label="{{'DOSSIER.CONFIRMATION_DIALOG.REJET_BUTTON' | translateI18n}}" icon="pi pi-times"
          class="uppercase p-button-secondary p-button-outlined mb-2" (click)="hideDialog()"></button>
  <button pButton type="submit" pRipple label="{{'DOSSIER.CONFIRMATION_DIALOG.ACCEPT_BUTTON' | translateI18n}}"
          icon="pi pi-check" class="ppercase mb-2"  (click)="rejectLivrableRapport()"></button>
</div>


