import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {AbstractControl, FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {MenuItem, Message} from "primeng/api";
import {User} from "../../../core/models/user/user.model";
import {UserService} from "../../../core/service/user/user.service";
import {DialogService, DynamicDialogRef} from "primeng/dynamicdialog";
import {Bien, Client, Contact, CreationDemande} from "../../../core/models/dossiers/creation-demande";
import {Adresse} from "../../../core/models/adresse";
import {DossierService} from "../../../core/service/dossier.service";
import {Civilite} from "../../../core/models/param/civilite";
import {CreationDemandeObservableService} from "../../../shared/observable/creation-demande-observable.service";
import {Subscription} from "rxjs";
import {TranslateService} from "../../../shared/translate/translate.service";
import {Tiers} from "../../../core/models/tiers/tiers";
import {ConfigSizeInput} from "../../../shared/providers/config-size-input";
import {ReferentielValeur} from "../../../core/models/referentiel-valeur";
import {UploadDocumentComponent} from 'src/app/core/upload-document/upload-document.component';
import {NgxSpinnerService} from "ngx-spinner";
import {TypeLivrable} from "../../../core/models/dossiers/type-livrable";

@Component({
  selector: 'app-creation-demande',
  templateUrl: './creation-demande.component.html',
  styleUrls: ['./creation-demande.component.scss']
})
export class CreationDemandeComponent implements OnInit {

  @ViewChild('document', {static : true}) documentElement : ElementRef;
  @ViewChild('commentaire', {static : true}) commentaireElement : ElementRef;
  @ViewChild('demandeur', {static : true}) demandeurElement : ElementRef;

  selectedPreneur : any;
  ssTitre : string;
  form : FormGroup;
  anchorItem: MenuItem[] = [];
  user : User;
  preteur : any[];
  submitted = false;
  errorMessageFormInvalid: Message[];
  civilites : Civilite[];
  subscription : Subscription;
  contact : Contact;
  codeTypeLivrable : string;
  files : File[] = [];
  stock : any;
  defaultTypeClient : string;
  configSizeInput : ConfigSizeInput;

  nbCaractCommentaire: number = 0;

  typesLivrables : ReferentielValeur[];

  typeSelected: string;

  actions: MenuItem[];

  constructor(
    private activatedRoute : ActivatedRoute,
    private userService : UserService,
    private fb : FormBuilder,
    public dialogService: DialogService,
    public ref: DynamicDialogRef,
    private router : Router,
    private dossierService : DossierService,
    private creationDemandeObservable : CreationDemandeObservableService,
    private translate : TranslateService,
    private dossierServcie : DossierService,
    private spinnerService: NgxSpinnerService,
  ) {
    this.typeSelected = 'ball-fussion';
    this.configSizeInput = new ConfigSizeInput();
    this.getUserConnected();
  }
  ngOnInit(): void {
    this.getTypeLivrable();

    this.getcivilites();

    this.navigationContextuelle();


  }

  async getUserConnected() {
    let profiles = await this.userService.getUserConnectedProfilesById();
    let agence = await this.userService.getAgenceUserConnected();
    this.user = await this.userService.getUserConnectedByToken();
    if(this.user) {
      this.user.profiles = profiles;
      this.user.agence =  new Tiers(agence);
      this.initialisationForm();
      this.navActionsItem();
      if(this.user?.agence && this.user?.agence?.entiteClient === 'MRC') {
        this.getAllPreteur();
      }
    }
  }


  get f(): { [key: string]: AbstractControl } {
    return this.form.controls;
  }

  /**
   * Envoi de la demande
   */
  submitDemande() {

    this.spinnerService.show();

   this.submitted = true;

    this.errorMessageFormInvalid = null;

    const creationDemande : CreationDemande = new CreationDemande();

    creationDemande.preteur = this.form?.value?.preteur?.itemvalue;
    creationDemande.commentaire = this.form?.value?.commentaire;
    creationDemande.codeTypeLivrable =  this.codeTypeLivrable;

    const client = this.form.value.client;
    this.identiqueClientAndContact(this.form?.value?.contact, client);
    const contact = this.form?.value?.contact;
    const bien = this.form?.value.bien;

   if(this.form.invalid || this.validationClientForm(client)
     || this.validationContactForm(contact, client) || this.validationBienForm(bien)
   ) {
      this.errorMessageFormInvalid = [ {severity:'error',
      summary: this.translate.getElement('MESSAGE.ERROR.MS_CHAMPS_OBLIGATOIRE') }];
      window.scrollTo(0,0);
      this.spinnerService.hide();
      return;
  }

  //Traitement données client
  const adressClient : Adresse = new Adresse(client.adresse);

  creationDemande.client =  new Client(client.gender?.codeCivilite, client.nomClient, client.prenomClient, client.nomMoral,
    client.nomRepresentant, client.cpRepresentant, client.phone1, client.phone2,
    client.email1, client.email2, client.numContrat, client.fraisExp?.value, adressClient);

    //Traitement données contacts
   if(contact.contactType === 'idClient') {
     creationDemande.contact = new Contact(contact.contactType, contact.qualite,  contact.gender?.codeCivilite,
       contact.nomContact, contact.prenomContact, contact.nomMoral, contact.phone1,
       contact.phone2, contact.email1, contact.email2, contact.adresse);
   } else if(contact.contactType === 'autre') {
     const adresseContactAutre : Adresse = new Adresse(contact.adresse);
     creationDemande.contact = new Contact(contact.contactType, contact.qualite?.id, contact.gender?.codeCivilite,
       contact.nomContact, contact.prenomContact, contact.nomMoral,
       contact.phone1, contact.phone2, contact.email1, contact.email2, adresseContactAutre);
   }


   //Traitement Données du bien
   if(bien.customerAdress === 'idClient') {
     creationDemande.bien = new Bien(bien.typeBien?.paramReferentielValeurByCode.code, bien.customerAdress, bien.adresse, bien.etage?.code);
   } else if(bien.customerAdress === 'autre') {
     const adresseBienAutre : Adresse = new Adresse(bien.adresse);
     creationDemande.bien = new Bien(bien.typeBien.paramReferentielValeurByCode.code, bien.customerAdress, adresseBienAutre, bien.etage?.code);
   }

    this.files = [];
    this.subscription = this.creationDemandeObservable.onFilesDocuments().subscribe(data => {
      if(data) {
        data.map( doc => this.files.push(doc.file));
      }
    });

    this.errorMessageFormInvalid = null;

    this.dossierService.createDemande(creationDemande, this.files).subscribe(data => {
      if(data) {
        if(data['documentsUploades']) {
          // Cas erreur document
          this.errorMessageFormInvalid = [];
          let noms: string = "";

          for (let i = 0; i < data['documentsTropGros'].length; i += 1) {
            noms = noms.concat(data['documentsTropGros'][i] + "<br/>");
          }
          if (noms.length != 0) {
            this.errorMessageFormInvalid.push({
              severity: 'warn',
              summary: this.translate.getElement("DOCUMENT.UPLOAD.MESSAGE.WARNING.TAILLE_MAX_DEPASSEE", noms, UploadDocumentComponent.uploadMaxSize.toString())
            });
            noms = "";
          }

          for (let i = 0; i < data['documentsNonAcceptes'].length; i += 1) {
            noms = noms.concat(data['documentsNonAcceptes'][i] + "<br/>");
          }
          if (noms.length != 0) {
            this.errorMessageFormInvalid.push({
              severity: 'warn',
              summary: this.translate.getElement("DOCUMENT.UPLOAD.MESSAGE.WARNING.EXTENSIONS_NON_AUTORISEES", noms)
            });
            noms = "";
          }

          for (let i = 0; i < data['documentsErreur'].length; i += 1) {
            noms = noms.concat(data['documentsErreur'][i] + "<br/>");
          }
          if (noms.length != 0) {
            this.errorMessageFormInvalid.push({
              severity: 'error',
              summary: this.translate.getElement("DOCUMENT.UPLOAD.MESSAGE.ERROR.INCONNUE", noms)
            });
          }
          window.scrollTo(0,0);
          this.spinnerService.hide();
          this.submitted = false;
        } else {
          this.form.reset();
          // Cas demande créé
          this.creationDemandeObservable.sendSucessSubjectMessage(
            this.translate.getElement('DOSSIER.DEMANDE.CREATION.MESSAGE.SUCCESS', data['dossier'].numeroDossier,
              "<a class='messageLink' href='/dossier/demande/fiche/" + data['dossier'].idDossier + "'>(Cliquez ici pour l'ouvrir)</a>"  )
          );
          this.creationDemandeObservable.clearClientAdressFormat();
          this.creationDemandeObservable.clearFilesDocuments();
          this.spinnerService.hide();
          this.submitted = false;
          this.router.navigateByUrl("/dashboard");
        }

      }

    }, error => {
      this.errorMessageFormInvalid = [ {severity:'error', summary: this.translate.getElement('DOSSIER.DEMANDE.CREATION.MESSAGE.ERROR_BACKEND') }];
      window.scrollTo(0,0);
      this.spinnerService.hide();
      this.submitted = false;
    })

  }

   identiqueClientAndContact(contact : any, client? : any) {
      if(contact.contactType === 'idClient') {
        this.form.get('contact.gender').setValue(client.gender);
        this.form.get('contact.nomContact').setValue(client.nomClient);
        this.form.get('contact.prenomContact').setValue(client.prenomClient);
        this.form.get('contact.adresse').setValue(client.adresse);
        this.form.get('contact.phone1').setValue(client.phone1);
        this.form.get('contact.phone2').setValue(client.phone2);
        this.form.get('contact.email1').setValue(client.email1);
        this.form.get('contact.email2').setValue(client.email2);
        this.form.get('contact.qualite').setValue(1);
      }
  }

  async getTypeLivrable() {
    this.typesLivrables = await this.dossierServcie.getTypeLivrableUser(0);
    if(this.typesLivrables.length === 0) {
      this.router.navigateByUrl("/role-denied");
    }
    this.activatedRoute.queryParams.subscribe(params => {
      if (params) {
        this.codeTypeLivrable = params['type'];

        let livrable = this.typesLivrables.find(tl => tl.code === this.codeTypeLivrable);
        if(!livrable) {
          this.router.navigateByUrl("/role-denied");
        }
        if (params['type'] === TypeLivrable.TVRAPPORTXX) {
          this.ssTitre = 'Rapport d\'expertise';
        } else if (params['type'] === TypeLivrable.TVAVISDOSSI) {
          this.ssTitre = 'Avis de valeur';
        } else {
          this.router.navigateByUrl("/role-denied");
        }
      }

    });

  }

  initialisationForm() {
    this.form = this.fb.group({
      preteur : [null, this.user?.agence != null && this.user?.agence?.entiteClient === 'MRC' ? Validators.required : Validators.nullValidator],
      client: this.initFormClient(),
      contact : this.initFormContact(),
      bien : this.initFormBien(),
      commentaire : [null]
    });
  }

  /**
   * Initialisation form adresse
   * @param adresse
   */
  initAdresse() : FormGroup  {
    return new FormGroup({
      adresseFormate: new FormControl(null),
      numero: new FormControl(null, [Validators.maxLength(this.configSizeInput.NUMERO_ADRESSE), Validators.pattern(this.configSizeInput.regexPattern2)]),
      lieuDit: new FormControl(null, [Validators.maxLength(this.configSizeInput.LIEU_DIT), Validators.pattern(this.configSizeInput.regexPattern2)]),
      nomVoie: new FormControl(null, [Validators.required, Validators.maxLength(this.configSizeInput.NOM_VOIE), Validators.pattern(this.configSizeInput.regexPattern2)]),
      codePostal: new FormControl(null, [Validators.required, Validators.maxLength(this.configSizeInput.CODE_POSTALE), Validators.pattern(this.configSizeInput.regexPattern2)]),
      ville: new FormControl(null,  [Validators.required, Validators.maxLength(this.configSizeInput.VILLE), Validators.pattern(this.configSizeInput.regexPattern2)]),
      pays: new FormControl(null, Validators.required),
      boitePostale: new FormControl(null, [Validators.maxLength(this.configSizeInput.BOITE_POSTALE), Validators.pattern(this.configSizeInput.regexPattern2)]),
      complement: new FormControl(null, [Validators.maxLength(this.configSizeInput.COMPLEMENT_ADRESSE), Validators.pattern(this.configSizeInput.regexPattern2)]),
      lat : new FormControl(null),
      lng : new FormControl(null),
      adresseChange : new FormControl(null)
    })
  }

  /**
   * initialisation formulaire client
   */
  initFormClient() : FormGroup {
    const agence = this.user?.agence;
    return  new FormGroup({
        customerType :  new FormControl('human', Validators.required),
        gender :  new FormControl(null),
        nomClient: new FormControl(null, [Validators.maxLength(this.configSizeInput.NOM_CLIENT), Validators.pattern(this.configSizeInput.regexPattern)]),
        prenomClient:  new FormControl(null,  [Validators.maxLength(this.configSizeInput.PRENOM_CLIENT), Validators.pattern(this.configSizeInput.regexPattern)]),
        nomMoral :  new FormControl(null,  [Validators.maxLength(this.configSizeInput.NOM_MOARAL_CLIENT), Validators.pattern(this.configSizeInput.regexPattern)]),
        nomRepresentant :  new FormControl(null,  [Validators.maxLength(this.configSizeInput.REPRESENTANT_CLIENT), Validators.pattern(this.configSizeInput.regexPattern)]),
        phone1 :  new FormControl(null, [Validators.maxLength(this.configSizeInput.TELEPHONE_CLIENT), Validators.pattern(this.configSizeInput.regexPattern)]),
        phone2 :  new FormControl(null, [Validators.maxLength(this.configSizeInput.TELEPHONE_CLIENT), Validators.pattern(this.configSizeInput.regexPattern)]),
        email1 :  new FormControl(null, [Validators.email, Validators.maxLength(this.configSizeInput.MAIL_CLIENT), Validators.pattern(this.configSizeInput.regexPattern)]),
        email2 :  new FormControl(null, [Validators.email, Validators.maxLength(this.configSizeInput.MAIL_CLIENT), Validators.pattern(this.configSizeInput.regexPattern)]),
        adresse : this.initAdresse(),
        numContrat :  new FormControl(null,
          [agence != null && agence?.entiteClient !== 'BNPPARIBAS' ? Validators.required : Validators.nullValidator,
            Validators.minLength(7), Validators.maxLength(this.configSizeInput.NUMERO_CONTRAT), Validators.pattern(this.configSizeInput.regexPattern)]
        ),
        fraisExp :  new FormControl(null, [agence != null && (agence?.entiteClient === 'UCB' || agence?.entiteClient === 'UCBE'
          || agence?.entiteClient === 'Service Expertise UCB Suisse')
          ? Validators.required : Validators.nullValidator]),
    })
  }

  /**
   * initialisation formulaire contact
   */
  initFormContact() : FormGroup {
    return  new FormGroup({
      contactType : new FormControl('idClient'),
      customerContactType : new FormControl(null),
      qualite : new FormControl(null),
      gender : new FormControl(null),
      nomContact : new FormControl(null, [Validators.maxLength(this.configSizeInput.NOM_CONTACT), Validators.pattern(this.configSizeInput.regexPattern)]),
      prenomContact: new FormControl(null, [Validators.maxLength(this.configSizeInput.PRENOM_CONTACT), Validators.pattern(this.configSizeInput.regexPattern)]),
      nomMoral : new FormControl(null, [Validators.maxLength(this.configSizeInput.RAISON_SOCIALE_CONTACT), Validators.pattern(this.configSizeInput.regexPattern)]),
      phone1 : new FormControl(null, [Validators.maxLength(this.configSizeInput.TELEPHONE_CONTACT), Validators.pattern(this.configSizeInput.regexPattern)]),
      phone2 : new FormControl(null, [Validators.maxLength(this.configSizeInput.TELEPHONE_CONTACT), Validators.pattern(this.configSizeInput.regexPattern)]),
      email1 : new FormControl(null, [Validators.email, Validators.maxLength(this.configSizeInput.MAIL_CONTACT), Validators.pattern(this.configSizeInput.regexPattern)]),
      email2 : new FormControl(null, [Validators.email, Validators.maxLength(this.configSizeInput.MAIL_CONTACT), Validators.pattern(this.configSizeInput.regexPattern)]),
      adresse : this.initAdresse()
    })
  }

  /**
   * initialisation formulaire bien
   */
  initFormBien() {
    return  new FormGroup({
      typeBien :new FormControl(null, Validators.required),
      customerAdress : new FormControl('idClient', Validators.required),
      adresse : this.initAdresse(),
      etage : new FormControl(null),
    })
  }

  /**
   * La liste des preteurs
   */
  getAllPreteur() {
    this.userService.getAllPreteur().subscribe(data => {
      this.preteur = data.filter(p => p.libelle !== '' && p.libelle !== null).sort((a, b) => (a.libelle > b.libelle) ? 1 : -1);
    });
  }

  /**
   * La liste des civilité
   */
  getcivilites() {
    this.dossierService.findAllCivilite().subscribe( data => {
      this.civilites = data;
    })
  }

  /**
   * Les elements de la navigation pour la redirection à chaque section
   */
  navigationContextuelle() {
    this.anchorItem.push({
      label: this.translate.getElement('DOSSIER.DEMANDE.CREATION.SECTION'),
      items: [
        {
          label: this.translate.getElement('DOSSIER.DEMANDE.CREATION.DEMANDEUR'),
          command : event => {
            this.scroolElement('demandeur', event);
            this.addIsactiveNavigationContextuelleMenuItem(event);
          }
        },
        {
          label: this.translate.getElement('DOSSIER.DEMANDE.CREATION.AFFAIRE'),
          command :(event) => {
            this.scroolElement('client', event);
            this.addIsactiveNavigationContextuelleMenuItem(event);
          }
        },
        {
          label: this.translate.getElement('DOSSIER.DEMANDE.CREATION.CONTACT'),
          command : event => {
            this.scroolElement('contact', event);
            this.addIsactiveNavigationContextuelleMenuItem(event);
          }
        },
        {label: this.translate.getElement('DOSSIER.DEMANDE.CREATION.BIEN'),
          command : event => {
            this.scroolElement('bien', event);
            this.addIsactiveNavigationContextuelleMenuItem(event)
          }
        },
        {
          label: this.translate.getElement('DOSSIER.DEMANDE.CREATION.DOCUMENT'),
          command : event => {
            this.scroolElement('documents', event);
            this.addIsactiveNavigationContextuelleMenuItem(event);
          }
        },
        {
          label: this.translate.getElement('DOSSIER.DEMANDE.CREATION.COMMENTAIRE'),
          command : event => {
            this.scroolElement('commentaire', event);
            this.addIsactiveNavigationContextuelleMenuItem(event);
          }
        }
      ]
    })
  }

  scroolElement(idElement : string, event : any) {
    const yOffset = -15;
    const y =  document.getElementById(idElement).getBoundingClientRect().top + window.pageYOffset + yOffset;
    window.scrollTo({top: y, behavior: 'smooth'});
    event.originalEvent.target.parentNode.classList.add("is-active");
  }

  addIsactiveNavigationContextuelleMenuItem(event : any) {
    if(this.stock && this.stock.item.label !== event.item.label) {
      this.stock.originalEvent.target.parentNode.classList.remove("is-active")
      event.originalEvent.target.parentNode.classList.add("is-active");
      this.stock = event;
    }else {
      event.originalEvent.target.parentNode.classList.add("is-active");
      this.stock = event;
    }
  }

  validationClientForm(client : any) {
    return (client.customerType === null || client.adresse.adresseFormate === null ||  client.adresse?.adresseFormate.length === 0
      || (client.customerType === 'human'
        && (client.gender === null || client.nomClient === null || client.nomClient.length === 0
          || client.prenomClient === null || client.prenomClient.length === 0))
      || (client.customerType === 'company'
        && (client.nomMoral === null || client.nomMoral.length === 0 || client.nomRepresentant === null || client.nomRepresentant?.length === 0 ))
    )
  }

  validationContactForm(contact : any, client : any) {
    return contact.contactType === 'autre' &&
      (contact.adresse.adresseFormate === null || contact.adresse.adresseFormate.length === 0
        && (contact.customerContactType === 'human' && (contact.gender === null || client.gender.length === 0
          || contact.nomContact === null || client.nomContact.length === 0
          || contact.prenomContact === null || client.prenomContact.length === 0))
        && (contact.customerContactType === 'company' && contact.nomMoral === null || client.nomMoral.length === 0));

  }

  validationBienForm(bien : any) {
    return bien.customerAdress === 'autre' && (bien.adresse.adresseFormate === null || bien.adresse.adresseFormate.length === 0);
  }


  detectChangeComment(event : any) {
    if(event) {
      this.nbCaractCommentaire =  String(event).length;
    }
  }

  /**
   * construction des actions utilisateurs sur le dossier
   * @param dossier
   */
  navActionsItem() {
    this.actions = [{
      label: this.translate.getElement('DOSSIER.GESTIONNAIRE.ACTION.ACTION_TITLE'),
      items : [{
          label: this.translate.getElement('DOSSIER.DEMANDE.CREATION.ENVOYER'),
          icon: 'pi pi-save',
          styleClass: 'primary-link',
          command: event => {this.submitDemande();}
      }]
    }];
  }

}
