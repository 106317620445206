import {Component, OnInit} from '@angular/core';
import {LocalStorageService} from "../service/local-storage/local-storage.service";
import {AuthService} from "../service/auth/auth.service";
import {Router} from "@angular/router";
import {OAuthService} from "angular-oauth2-oidc";
import { runtimeEnvironment } from 'src/environments/runtime-environment';

@Component({
  selector: 'app-deconnexion',
  templateUrl: './deconnexion.component.html',
  styleUrls: ['./deconnexion.component.scss']
})
export class DeconnexionComponent implements OnInit {

  constructor(
    private userTokenStorageService : LocalStorageService,
    private authService : AuthService, private router:Router,
    private  oauthService : OAuthService
  ) { }

  ngOnInit(): void {
    this.deconnexion();
  }

  deconnexion() {
    this.authService.logout().subscribe(obs => {
      
    });
    this.userTokenStorageService.signOut();
    const adfsExpirationtoken = this.oauthService.getAccessTokenExpiration();
    const currentTimeMillis = new Date().getTime();
    
    if (runtimeEnvironment.isInterne && adfsExpirationtoken != null && adfsExpirationtoken < currentTimeMillis) {
      this.authService.logoutAdfs();
    }
    
    this.router.navigateByUrl("/auth");
  }

}
