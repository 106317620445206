import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, Subject} from "rxjs";
import {Document} from "../../core/models/document/document.model";
import {Adresse} from "../../core/models/adresse";

@Injectable({
  providedIn: 'root'
})
export class CreationDemandeObservableService {

  private subjectFiles = new Subject<File[]>();

  private _subjectFilesDocuments$ : BehaviorSubject<Document[]> = new BehaviorSubject<Document[]>(null);

  private _adresseFormatSubject$ : BehaviorSubject<Adresse> = new BehaviorSubject<Adresse>(null);

  private _sucesSubjectMessage :  BehaviorSubject<string> = new BehaviorSubject<string>('');

  files : File[];

  constructor() { }

  sendFiles(files: File[]) {
    this.subjectFiles.next(files);
  }

  /*clearFiles() {
    this.subject.next();
  }*/

  onFiles(): Observable<File[]> {
    return this.subjectFiles.asObservable();
  }


  sendFilesDocuments(files: Document[]) {
    this._subjectFilesDocuments$.next(files);
  }

  onFilesDocuments() {
    return this._subjectFilesDocuments$;
  }

  clearFilesDocuments() {
    this._subjectFilesDocuments$.next(null);
  }

  sendClientAdressFormat(adress : Adresse) {
    this._adresseFormatSubject$.next(adress)
  }

  onClientAdressFormat() {
    return this._adresseFormatSubject$.asObservable();
  }

  clearClientAdressFormat() {
    this._adresseFormatSubject$.next(null);
  }


  sendSucessSubjectMessage(messsage : string) {
    this._sucesSubjectMessage.next(messsage);
  }

  onSucessSubjectMessage() {
    return this._sucesSubjectMessage.asObservable();
  }

  clearSucessSubjectMessage() {
    this._sucesSubjectMessage.next(null);
  }


}
