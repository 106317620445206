<p-toast></p-toast>
<main class="layout-wrapper">
  <app-navigation></app-navigation>
  <app-header titre="{{'MONITORING.HEADER.TITLE' | translateI18n}}"
              state="progress"></app-header>
  <div class="layout-main have-table">
    <!-- file-explorer.component.html -->
<!--    <a (click)="listFiles('')" style="background-color:yellow">Racine</a>
    <BR>
    <BR>
    <div *ngFor="let file of files">
      <div *ngIf="file[1]=='R'">
        <a (click)="listFiles(file[0])" style="background-color:yellow">{{ file[0]}}</a> {{ file[1]}}
      </div>
      <div *ngIf="file[1]=='F'">
        <a (click)="downloadFile(file[0])" style="background-color:green">{{ file[0]}}</a> {{ file[1]}}
      </div>
    </div>-->
    <p-tree
      [value]="files"
      class="w-full md:w-30rem"
      selectionMode="single"
      [(selection)]="selectedFile"
      (onNodeSelect)="nodeSelect($event)"
    ></p-tree>

  </div>

</main>



<ngx-spinner  type="ball-scale-multiple">
  <p-progressSpinner></p-progressSpinner>
</ngx-spinner>
