import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {ForgottenPasswordComponent} from "./forgotten-password/forgotten-password.component";
import {LoginComponent} from "./login/login.component";
import {ChangePasswordPageComponent} from "../../core/change-password-page/change-password-page.component";

const routes: Routes = [
  {
    path: '',
    component : LoginComponent,
    children : [
      {
        path: 'mot-de-passe-oublie',
        component : ForgottenPasswordComponent
      },
      { path: "reinit-password", component:  ChangePasswordPageComponent }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class UserRoutingModule { }
