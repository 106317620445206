import {Injectable} from '@angular/core';
import {BehaviorSubject} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class FicheDossierObsevableService {

  private _clientInfo$ : BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor() { }

  sendClientInfo(client : any) {
    this._clientInfo$.next(client)
  }

  onClientInfo() {
    return this._clientInfo$.asObservable();
  }

}
