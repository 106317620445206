import {ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {RapportExpertise} from "../../../models/rapport-expertise/rapport-expertise";
import {AbstractControl, FormBuilder, FormGroup, FormGroupDirective, Validators} from "@angular/forms";
import {DialogService, DynamicDialogRef} from "primeng/dynamicdialog";
import {Message, MessageService} from "primeng/api";
import {TranslateService} from "../../../../shared/translate/translate.service";
import {DatePipe} from "@angular/common";
import {RapportExpertiseObservableService} from "../../../../shared/observable/rapport-expertise-observable.service";
import {RapportExpertiseService} from "../../../service/rapport-expertise.service";
import {DocumentService} from "../../../service/document.service";
import {ReferentielValeur} from "../../../models/referentiel-valeur";
import {AppSettings, Settings} from "../../../../app.settings";
import {Validation} from "../../../../shared/providers/validation";

@Component({
  selector: 'app-description-rapport',
  templateUrl: './description-rapport.component.html',
  styleUrls: ['./description-rapport.component.scss']
})
export class DescriptionRapportComponent implements OnInit {

  @Input()
  formGroupName: string;

  @Input()
  submitted : boolean;

  @Input()
  submittedRequired : boolean = false;

  @Input()
  submittedRequiredSynthese : boolean = false;

  @Input()
  rapport : RapportExpertise;

  form: FormGroup;

  nbDescriptionText : number;

  msgError3000 : Message[];
  msgError6000 : Message[];

  topographies : any[];
  configurations : any[];
  entretiens : ReferentielValeur[];

  public settings: Settings;

  nbcaractConstruction : number = 0;
  nbcaractDistrubution : number = 0;
  nbcaractEquipement : number = 0;
  nbcaractAmenagementsIinterieurs  : number = 0;
  nbcaractEtatGeneral : number = 0;
  nbcaractDetailSurface: number = 0;

  reserves : any[];
  typeSurfacesRapport: any[];

  titrePhotoInterieur : string;
  titrePhotoExterieur : string;

  surface : any[] = [];

  requiredDetailSurface : boolean;

  constructor(
    private fb : FormBuilder,
    public dialogService: DialogService,
    public ref: DynamicDialogRef,
    private rootFormGroup: FormGroupDirective,
    private documentService : DocumentService,
    private cdref: ChangeDetectorRef,
    private messageService : MessageService,
    private translate : TranslateService,
    private datePipe: DatePipe,
    private rapportExpertiseObservableService : RapportExpertiseObservableService,
    private rapportExpertiseService : RapportExpertiseService,
    public appSettings : AppSettings,
  ) {
    this.settings = this.appSettings.settings;
  }

  ngOnInit(): void {
    this.form = this.rootFormGroup.control.get(this.formGroupName) as FormGroup;

    this.reinitMessageFieldTypeText();

    this.getAllTopographie();
    this.getAllConfiguration();
    this.getAllEntretiens();
    this.getListReserves();
    this.getAllTypeSurfaceRapport();

    this.typeSurface(this.form?.value?.typeSurface);

    this.titrePhotoInterieur = this.translate.getElement('RAPPORT_EXPERTISE.PHOTO_INTERIEUR');
    this.titrePhotoExterieur = this.translate.getElement('RAPPORT_EXPERTISE.PHOTO_EXTERIEUR');

    this.choiseDetailSurface();

  }



  getAllTopographie() {
    this.rapportExpertiseService.getAllTopographie().subscribe( data => {
      this.topographies = data;
    })
  }

  getAllConfiguration()  {
    this.rapportExpertiseService.getAllConfiguration().subscribe( data => {
      this.configurations = data;
    })
  }

  getAllEntretiens() {
    this.rapportExpertiseService.getAllEntretiens().subscribe( data => {
      this.entretiens = data;
    })
  }

  getListReserves() {
    this.rapportExpertiseService.getListReserves().subscribe( data => {
      this.reserves = data;
    })
  }

  getAllTypeSurfaceRapport() {
    this.rapportExpertiseService.getAllTypeSurfaceRapport().subscribe( data => {
      this.typeSurfacesRapport = data;
    })
  }

  get f(): { [key: string]: AbstractControl } {
    return this.form.controls;
  }

  descriptionTextChange(event: any) {
    this.nbDescriptionText = event?.htmlValue ? event?.htmlValue?.replace(/<[^>]*>/g, '')?.trim()?.length : 0;
  }


  caractConstructionTextChange(event: any) {
    this.nbcaractConstruction = event?.htmlValue ? event?.htmlValue?.replace(/<[^>]*>/g, '')?.trim()?.length : 0;
  }

  caractDistributionTextChange(event: any) {
    this.nbcaractDistrubution = event?.htmlValue ? event?.htmlValue?.replace(/<[^>]*>/g, '')?.trim()?.length : 0;
  }

  caractEquipementTextChange(event: any) {
    this.nbcaractEquipement = event?.htmlValue ? event?.htmlValue?.replace(/<[^>]*>/g, '')?.trim()?.length : 0;
  }

  caractAmenagementsIinterieursTextChange(event: any) {
    this.nbcaractAmenagementsIinterieurs = event?.htmlValue ? event?.htmlValue?.replace(/<[^>]*>/g, '')?.trim()?.length : 0;
  }

  caractEtatGeneralTextChange(event: any) {
    this.nbcaractEtatGeneral = event?.htmlValue ? event?.htmlValue?.replace(/<[^>]*>/g, '')?.trim()?.length : 0;
  }

  setValeurSurface(surface : number) {
    if(surface === 0) {
      this.form.get('valeurSurface').setValue(null);
    }else {
      this.form.get('valeurSurface').setValue(surface);
    }
  }

  typeSurface(event : any) {

    this.rapportExpertiseObservableService.sendTypeSurface(event);
    let idTypeSurface = event?.idTypeSurface;
    this.surface = [];
    switch (idTypeSurface) {
      case 1 :
        this.surface.push({name : 'SH', value : this.rapport.sh});
        this.setValeurSurface(this.rapport.sh);
        return;
      case 2 :
        this.surface.push({name : 'SHP', value : this.rapport.shp});
        this.setValeurSurface(this.rapport.shp);
        return;
      case 3 :
        this.surface.push({name : 'SU', value : this.rapport.su});
        this.setValeurSurface(this.rapport.su);
        return ;
      case 4 :
        this.surface.push({name : 'SUP', value : this.rapport.sup});
        this.setValeurSurface(this.rapport.sup);
        return;
      case 5 :
        this.surface.push({name : 'SDHOP', value : this.rapport.sdhop});
        this.setValeurSurface(this.rapport.sdhop);
        return;
      case 6 :
        this.surface.push({name : 'SPC', value : this.rapport.spc});
        this.setValeurSurface(this.rapport.spc);
        return;
      case 7 :
        this.surface.push({name : '', value : this.form?.value?.supTerrain});
        return  this.form.get('valeurSurface').setValue(this.form?.value?.supTerrain);
      default :
        return  this.form.get('valeurSurface').setValue(null);
    }

  }


  transformValue(value : number) {
    return value.toFixed(2)
  }

  reinitMessageFieldTypeText() {

    this.nbDescriptionText = this.form?.value?.rapportDescription ? this.form?.value?.rapportDescription?.replace(/<[^>]*>/g, '')?.length : 0;
    this.msgError3000 =[ {key : 'rapportDescription', severity:'error', summary:  this.translate.getElement("RAPPORT_EXPERTISE.MESSAGE.ERROR_CARACTERE_COMMENTAIRE", '3000')}];

    this.nbcaractConstruction = this.form?.value?.construction ? this.form?.value?.construction?.replace(/<[^>]*>/g, '')?.length : 0;
    this.msgError6000 =[ {severity:'error', summary:  this.translate.getElement("RAPPORT_EXPERTISE.MESSAGE.ERROR_CARACTERE_COMMENTAIRE", '6000')}];

    this.nbcaractDistrubution =  this.form?.value?.distribution ? this.form?.value?.distribution?.replace(/<[^>]*>/g, '')?.length : 0;
    this.msgError6000 =[ {key : 'distribution', severity:'error', summary:  this.translate.getElement("RAPPORT_EXPERTISE.MESSAGE.ERROR_CARACTERE_COMMENTAIRE", '6000')}];

    this.nbcaractEquipement =  this.form?.value?.equipement ? this.form?.value?.equipement?.replace(/<[^>]*>/g, '')?.length : 0;
    this.msgError6000 =[ {key : 'equipement', severity:'error', summary:  this.translate.getElement("RAPPORT_EXPERTISE.MESSAGE.ERROR_CARACTERE_COMMENTAIRE", '6000')}];

    this.nbcaractAmenagementsIinterieurs =  this.form?.value?.amenagementsInterieurs ? this.form?.value?.amenagementsInterieurs?.replace(/<[^>]*>/g, '')?.length : 0;
    this.msgError6000 =[ {key : 'amenagementInterieur', severity:'error', summary:  this.translate.getElement("RAPPORT_EXPERTISE.MESSAGE.ERROR_CARACTERE_COMMENTAIRE", '6000')}];

    this.nbcaractEtatGeneral = this.form?.value?.etatGeneral ? this.form?.value?.etatGeneral?.replace(/<[^>]*>/g, '')?.length : 0;
    this.msgError3000 =[ {key : 'etatGeneral', severity:'error', summary:  this.translate.getElement("RAPPORT_EXPERTISE.MESSAGE.ERROR_CARACTERE_COMMENTAIRE", '3000')}];

    this.nbcaractDetailSurface = this.form?.value?.detailSurface ? this.form?.value?.detailSurface?.replace(/<[^>]*>/g, '')?.length : 0;
    this.msgError3000 =[ {key : 'detailSurface', severity:'error', summary:  this.translate.getElement("RAPPORT_EXPERTISE.MESSAGE.ERROR_CARACTERE_COMMENTAIRE", '3000')}];


  }

  changeSupTerrain(event: any) {
    if(this.form?.value?.typeSurface?.idTypeSurface === 7) {
      this.form.get('valeurSurface').setValue(event.value);
    }
  }

  changeValueSurface(event: any) {
    this.rapportExpertiseObservableService.sendSurface(event.value);
  }

  caractDetailSurfaceTextChange(event: any) {
    this.nbcaractDetailSurface = event?.htmlValue ? event?.htmlValue?.replace(/<[^>]*>/g, '')?.trim()?.length : 0;
  }

  ngAfterContentChecked() {
    this.cdref.detectChanges();
  }

  choiseDetailSurface() {
    let detailSurface = this.rapport?.detailSurface;
    if(this.form?.value?.existsDetailSurface) {
      this.form.controls['detailSurface'].setValidators([Validators.required,
        Validation.patternValidatorMaxLengthCaracter(3000, {hasError: true}),
        Validation.patternValidatorEmptyCaracter({hasEmptyCaracterError: true})]);
      this.form.get('detailSurface').setValue(detailSurface);
      this.form.controls['detailSurface'].updateValueAndValidity();
      this.requiredDetailSurface = true;
    }else {
      this.form.controls['detailSurface'].setValidators(null);
      this.form.get('detailSurface').setValue(null);
      this.form.controls['detailSurface'].updateValueAndValidity();
      this.requiredDetailSurface = false;
    }
  }

}
