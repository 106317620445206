import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {CoreModule} from "../../core/core.module";
import {DashbordRoutingModule} from './dashbord-routing.module';
import {DashboardComponent} from "./dashboard/dashboard.component";
import {DashboardCardComponent} from "./dashboard-card/dashboard-card.component";
import {SharedModule} from "../../shared/shared.module";
import {MessagesModule} from "primeng/messages";
import {MessageModule} from "primeng/message";
import {ToastModule} from "primeng/toast";
import {DocumentsNonLusComponent} from './documents-non-lus/documents-non-lus.component';
import {TableModule} from 'primeng/table';
import {NgxSpinnerModule} from "ngx-spinner";
import {ProgressSpinnerModule} from "primeng/progressspinner";


@NgModule({
  declarations: [
    DashboardComponent,
    DashboardCardComponent,
    DocumentsNonLusComponent
  ],
  imports: [
    CommonModule,
    CoreModule,
    DashbordRoutingModule,
    SharedModule,
    MessagesModule,
    MessageModule,
    TableModule,
    ToastModule,
    NgxSpinnerModule,
    ProgressSpinnerModule
  ]
})
export class DashbordModule { }
