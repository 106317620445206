import {ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {RapportExpertise} from "../../../models/rapport-expertise/rapport-expertise";
import {AbstractControl, FormBuilder, FormGroup, FormGroupDirective} from "@angular/forms";
import {DialogService, DynamicDialogRef} from "primeng/dynamicdialog";
import {Message, MessageService} from "primeng/api";
import {TranslateService} from "../../../../shared/translate/translate.service";
import {DatePipe} from "@angular/common";
import {RapportExpertiseObservableService} from "../../../../shared/observable/rapport-expertise-observable.service";
import {RapportExpertiseService} from "../../../service/rapport-expertise.service";
import {ReferentielValeur} from "../../../models/referentiel-valeur";

@Component({
  selector: 'app-environnement-urbanisme',
  templateUrl: './environnement-urbanisme.component.html',
  styleUrls: ['./environnement-urbanisme.component.scss']
})
export class EnvironnementUrbanismeComponent implements OnInit {

  @Input()
  formGroupName: string;

  @Input()
  submitted : boolean;

  @Input()
  submittedRequired : boolean = false;

  @Input()
  rapport : RapportExpertise;

  form: FormGroup;

  dpes : ReferentielValeur[];

  nbDpeCommentaire : number = 0;
  nbUrbanisme : number = 0;
  nbRisquesEnvironnement : number = 0;

  message : Message[];

  constructor(
    private fb : FormBuilder,
    public dialogService: DialogService,
    public ref: DynamicDialogRef,
    private rootFormGroup: FormGroupDirective,
    private cdref: ChangeDetectorRef,
    private messageService: MessageService,
    private translate : TranslateService,
    private datePipe: DatePipe,
    private rapportExpertiseObservableService : RapportExpertiseObservableService,
    private rapportExpertiseService : RapportExpertiseService,
  ) { }

  ngOnInit(): void {
    this.form = this.rootFormGroup.control.get(this.formGroupName) as FormGroup;
    this. getAllDPE();

   this.initChampstexte();
  }

  /**
   * recupère les dpe(Diagnostique de Performance Énergétiques)
   */
  getAllDPE() {
    this.rapportExpertiseService.getAllDPE().subscribe( data => {
      this.dpes = data;
    })
  }

  get f(): { [key: string]: AbstractControl } {
    return this.form.controls;
  }

  /**
   *
   */
  initChampstexte() {
    this.nbDpeCommentaire = this.form?.value?.dpeCommentaire ? this.form?.value?.dpeCommentaire?.replace(/<[^>]*>/g, '')?.length : 0;
    this.message =[ {key : 'dpeCommentaire', severity:'error', summary:  this.translate.getElement("RAPPORT_EXPERTISE.MESSAGE.ERROR_CARACTERE_COMMENTAIRE", '1000')}];

    this.nbUrbanisme = this.form?.value?.urbanisme ? this.form?.value?.urbanisme?.replace(/<[^>]*>/g, '')?.length : 0;
    this.message =[ {key : 'urbanisme', severity:'error', summary:  this.translate.getElement("RAPPORT_EXPERTISE.MESSAGE.ERROR_CARACTERE_COMMENTAIRE", '3500')}];

    this.nbRisquesEnvironnement =  this.form?.value?.risquesEnvironnement ? this.form?.value?.risquesEnvironnement?.replace(/<[^>]*>/g, '')?.length : 0;
    this.message =[ {key : 'risquesEnvironnement', severity:'error', summary:  this.translate.getElement("RAPPORT_EXPERTISE.MESSAGE.ERROR_CARACTERE_COMMENTAIRE", '1000')}];
  }

  numDpeCommentaireTextChange(event: any) {
    this.nbDpeCommentaire = event?.htmlValue ? event?.htmlValue?.replace(/<[^>]*>/g, '')?.trim()?.length : 0;
  }

  numUrbanismeTextChange(event: any) {
    this.nbUrbanisme =  event?.htmlValue ? event?.htmlValue?.replace(/<[^>]*>/g, '')?.trim()?.length : 0;
  }

  numRisquesEnvironnementTextChange(event: any) {
    this.nbRisquesEnvironnement  =  event?.htmlValue ? event?.htmlValue?.replace(/<[^>]*>/g, '')?.trim()?.length : 0;
  }
}
