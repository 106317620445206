import {Component, Input, OnInit} from '@angular/core';
import {AbstractControl, FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {Validation} from "../providers/validation";
import {ActivatedRoute, Router} from "@angular/router";
import {LocalStorageService} from "../../core/service/local-storage/local-storage.service";
import {ProfileService} from "../../core/service/user/profile.service";
import {LoginResponse} from "../../core/models/user/login-response";
import {TranslateService} from "../translate/translate.service";
import {Message, MessageService} from "primeng/api";

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {

  form = new FormGroup({
    currentPassword: new FormControl(''),
    password: new FormControl(''),
    confirmPassword: new FormControl('')
  });
  submitted = false;

  @Input() askCurrentPassword : boolean;

  errorMessage : string;
  infoMessage : string;

  confirmPasswordMessage : string = "";

  currentNavigation : any;

  message: Message[];


  login : string;
  code : string;
  type : string;
  maxPasswordLength :any = 15;
  nbrOfLastOldPassword :any = 24;

  constructor(private router:Router, private fb : FormBuilder, private profileService : ProfileService,
              private route: ActivatedRoute, private messageService: MessageService,
              private tokenStorageService : LocalStorageService,
              public translate : TranslateService
  ) {
    this.currentNavigation = this.router.getCurrentNavigation();
  }
  ngOnInit(): void {
    this.confirmPasswordMessage = this.translate.getElement('FORM_CHANGE_PASSWORD.MESSAGE.ERROR.CONNFIRM_PASSWORD');
    if(this.askCurrentPassword === false) {
      this.code = this.route.snapshot.paramMap.get('otp');
      if(this.currentNavigation.extractedUrl.queryParams?.type === 'passwordExpired') {
        this.message = [{severity : "info", summary : this.translate.getElement("AUTH.MESSAGE.ERROR.PASSWORD_EXPIRED")}]
      }
      else {
        this.verifyIfLinkChangePasswordIsValid();
      }
    }

    this.initForm();

  }

  get f(): { [key: string]: AbstractControl } {
    return this.form.controls;
  }

  onSubmit(): void {
    this.submitted = true;
    this.errorMessage ="";

    if (this.form.invalid) {
      return;
    }
    else{
      if(this.form.value.password) {
          if(Validation.validationPassword(this.form.value.password)) {
            if (this.askCurrentPassword) {
              this.changePasswordAskCurrentPassword();
            } else {
              this.changePasswordIfUserIsNotAuthenticated();
            }
            this.onReset();
          }else {
            this.errorMessage = this.translate.getElement('FORM_CHANGE_PASSWORD.MESSAGE.ERROR.POLITIQUE_PASSWORD')
          }
      }
    }

  }


  changePasswordAskCurrentPassword() {
    this.messageService.clear();
    this.profileService.changePassword(this.form.value.currentPassword, this.form.value.password).subscribe(
      data => {
        if(data === true) {
          // OK
          this.message =  [ {severity:'success', summary: this.translate.getElement('FORM_CHANGE_PASSWORD.MESSAGE.SUCCESS.CHANGE') }];
        } else {
          this.message = [ {severity:'error', summary: this.translate.getElement('FORM_CHANGE_PASSWORD.MESSAGE.ERROR.ERROR_SERVER') }];
        }
      }, error => {
        if (error.status == 406) {
          // Unacceptable : Le nouveau mot de passe n'est pas correct
          //"Le nouveau mot de passe saisi ne respecte pas la politique de mot de passe Groupe";
          this.message = [ {severity:'error', summary: this.translate.getElement('FORM_CHANGE_PASSWORD.MESSAGE.ERROR.POLITIQUE_PASSWORD') }];
        } else if (error.status == 403) {
          // Forbidden
          this.message = [ {severity:'error', summary: this.translate.getElement('FORM_CHANGE_PASSWORD.MESSAGE.ERROR.CURRENT_PASSWORD') }];
        } else {
          this.message = [ {severity:'error', summary: this.translate.getElement('FORM_CHANGE_PASSWORD.MESSAGE.ERROR.ERROR_SERVER') }];
        }
      }
    )
  }

  changePasswordIfUserIsNotAuthenticated () {
    this.profileService.changeForgotPassword(this.login, this.form.value.password,  this.code).subscribe(
      (data : LoginResponse)=> {
        if(data && data.token) {
          this.tokenStorageService.clearAllStorage();
          this.tokenStorageService.setSession(data, new Date().getTime());
          this.router.navigateByUrl("/dashboard");
        }
      }, error => {
        if (error.status == 406) {
          // Unacceptable : Le nouveau mot de passe n'est pas correct
          this.message = [ {severity:'error', summary: this.translate.getElement('FORM_CHANGE_PASSWORD.MESSAGE.ERROR.POLITIQUE_PASSWORD') }];
        }else {
          this.message = [ {severity:'error', summary: this.translate.getElement('FORM_CHANGE_PASSWORD.MESSAGE.ERROR.ERROR_SERVER') }];
        }
      }
    )
  }

  verifyIfLinkChangePasswordIsValid() {
    this.profileService.verifyIfLinkChangePasswordIsValid(this.login, this.code).subscribe(
      response => {
        if(!response) {
          this.router.navigateByUrl("/error-link-change-password-expired");
        }
      },error=> {
        this.router.navigateByUrl("/auth");
      }
    );
  }

   initForm() {
     this.form = this.fb.group(
      {
        currentPassword: ['', [this.askCurrentPassword ? Validators.required : Validators.nullValidator]],
        password: [
          '',
          Validators.compose([
            Validators.required,
            // check whether the entered password has a number
            Validation.patternValidator(/\d/, {
              hasNumber: true
            }),
            // check whether the entered password has upper case letter
            Validation.patternValidator(/[A-Z]/, {
              hasCapitalCase: true
            }),
            // check whether the entered password has a lower case letter
            Validation.patternValidator(/[a-z]/, {
              hasSmallCase: true
            }),
            // check whether the entered password has a special character
            Validation.patternValidator(
              /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/,
              {
                hasSpecialCharacters: true
              }
            ),
            Validators.minLength(this.maxPasswordLength)
          ])
        ],
        confirmPassword: ['', [Validators.required]]
      },
      {
        validators: [Validation.confirmedValidator('password', 'confirmPassword')]
      }
    );
  }


  onReset(): void {
    this.submitted = false;
    this.form.reset();
  }

}
