import {ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {AbstractControl, FormBuilder, FormGroup, FormGroupDirective} from "@angular/forms";
import {Message, MessageService} from "primeng/api";
import {ConfigSizeInput} from "../../../../shared/providers/config-size-input";
import {DialogService, DynamicDialogRef} from "primeng/dynamicdialog";
import {TranslateService} from "../../../../shared/translate/translate.service";
import {DatePipe} from "@angular/common";
import {RapportExpertise} from "../../../models/rapport-expertise/rapport-expertise";
import {RapportExpertiseService} from "../../../service/rapport-expertise.service";
import {RapportExpertiseObservableService} from "../../../../shared/observable/rapport-expertise-observable.service";

@Component({
  selector: 'app-mission-rapport',
  templateUrl: './mission-rapport.component.html',
  styleUrls: ['./mission-rapport.component.scss']
})
export class MissionRapportComponent implements OnInit {

  @Input()
  formGroupName: string;

  paramTypeValeur : any[];
  paramOccupation : any[];

  @Input()
  submitted : boolean;

  @Input()
  submittedRequired : boolean = false;

  @Input()
  submittedRequiredSynthese : boolean = false;

  @Input()
  rapport : RapportExpertise;

  form: FormGroup;
  nbPiecesCommuniquesText: number = 0;

  msgError : Message[];

  nbPiecesCommuniquesTextError : boolean;

  missionSection : string;

  configSizeInput : ConfigSizeInput;

  constructor(
    private fb : FormBuilder,
    public dialogService: DialogService,
    public ref: DynamicDialogRef,
    private rootFormGroup: FormGroupDirective,
    private cdref: ChangeDetectorRef,
    private message: MessageService,
    private translate : TranslateService,
    private datePipe: DatePipe,
    private rapportExpertiseObservableService : RapportExpertiseObservableService,
    private rapportExpertiseService : RapportExpertiseService,
  ) { }

  ngOnInit(): void {

    this.form = this.rootFormGroup.control.get(this.formGroupName) as FormGroup;

    if(this.form.value?.piecesCommuniques?.length > 0) {
      this.nbPiecesCommuniquesText =this.form?.value?.piecesCommuniques ? this.form?.value?.piecesCommuniques?.replace(/<.*?>/g, '')?.length : 0;
    }

    this.msgError =[ {severity:'error', summary:  this.translate.getElement("RAPPORT_EXPERTISE.MESSAGE.ERROR_CARACTERE_COMMENTAIRE", '400')}];

    this.getAllParamTypeValeurRapport();
    this.getAllParamOccupation();
  }

  piecesCommuniquesTextChange(event: any) {
    this.nbPiecesCommuniquesText = event?.htmlValue ? event?.htmlValue?.replace(/<[^>]*>/g, '')?.trim()?.length : 0;
  }

  typeValeurChange(event : any) {
    this.rapport.paramTypeValeur = event.value;
    this.rapportExpertiseObservableService.sendTypeValeur(event.value);
  }

  get f(): { [key: string]: AbstractControl } {
    return this.form.controls;
  }

  ngAfterContentChecked() {
    this.cdref.detectChanges();
  }

  getAllParamTypeValeurRapport()  {
    this.rapportExpertiseService.getAllParamTypeValeur().subscribe(
      data => {
        this.paramTypeValeur = data;
      },error => {

      }
    )
  }

  getAllParamOccupation()  {
    this.rapportExpertiseService.getAllParamOccupation().subscribe(
      data => {
        this.paramOccupation = data;
      },error => {

      }
    )
  }



  typeOccupationChange(event: any) {
    this.rapportExpertiseObservableService.sendTypeOccupation(event.value);
  }
}
