<form [formGroup]="form" >
  <div class="card-large" id="section1">
    <h1 id="situationLocative">V - {{'AVIS_VALEUR.SITUATION_LOCATIVE' | translateI18n}}</h1>

    <div class="grid">


      <div class="col-12 ">
        <div class="field field-line input-ghost">
          <label for="cdtOccup" class="label obligatoire">{{'AVIS_VALEUR.LABEL.CDT_OCCUPATION' | translateI18n}} :</label>
          <input type="text" pInputText id="cdtOccup" class="inputfield w-7" formControlName="cdtOccup"
                 [ngClass]="{'ng-dirty ng-invalid': (submittedRequired && f.cdtOccup.errors) || f.cdtOccup.hasError('hasError')}"
                 placeholder="{{'AVIS_VALEUR.LABEL.RENSEIGNER' | translateI18n}}" [maxLength]="configSizeInput.OCCUPATION"/>

          <small *ngIf="f.cdtOccup?.errors?.pattern" class="p-error block">
            {{'MESSAGE.ERROR.REGEX_PATTERN' | translateI18n}}</small>

          <small *ngIf="f.cdtOccup?.hasError('hasError')" class="p-error block">
            {{'AVIS_VALEUR.MESSAGE.ERROR_CARACTERE_INPUT' | translateI18n : '80'}}</small>
        </div>
      </div>


      <div class="col">
        <section>

          <div class="field field-line input-ghost">
            <label for="priseEffetBail" class="label">{{'AVIS_VALEUR.LABEL.PRISE_EFFET_BAIL' | translateI18n}} :</label>
            <input type="text" pInputText id="priseEffetBail" class="inputfield" formControlName="priseEffetBail"
                   [ngClass]="{'ng-dirty ng-invalid': f.priseEffetBail.hasError('hasError')} "
                   placeholder="{{'AVIS_VALEUR.LABEL.RENSEIGNER' | translateI18n}}" [maxLength]="configSizeInput.PRISE_EFFET_BAIL"/>

            <small *ngIf="f.priseEffetBail?.errors?.pattern" class="p-error block">
              {{'MESSAGE.ERROR.REGEX_PATTERN' | translateI18n}}</small>

            <small *ngIf="f.priseEffetBail?.hasError('hasError')" class="p-error block">
              {{'AVIS_VALEUR.MESSAGE.ERROR_CARACTERE_INPUT' | translateI18n : '16'}}</small>
          </div>

        </section>
      </div>


      <div class="col">
        <section>

          <div class="field field-line input-ghost">
            <label for="dureeBail" class="label">{{'AVIS_VALEUR.LABEL.DUREE_BAIL' | translateI18n}} :</label>
            <input type="text" pInputText id="dureeBail" class="inputfield" formControlName="dureeBail"
                   [ngClass]="{'ng-dirty ng-invalid': f.dureeBail.hasError('hasError')} "
                   placeholder="{{'AVIS_VALEUR.LABEL.RENSEIGNER' | translateI18n}}" [maxLength]="configSizeInput.DURRE_BAIL"/>

            <small *ngIf="f.dureeBail?.errors?.pattern" class="p-error block">
              {{'MESSAGE.ERROR.REGEX_PATTERN' | translateI18n}}</small>

            <small *ngIf="f.dureeBail?.hasError('hasError')" class="p-error block">
              {{'AVIS_VALEUR.MESSAGE.ERROR_CARACTERE_INPUT' | translateI18n : '16'}}</small>
          </div>

        </section>
      </div>



      <div class="col-12">

        <section>
          <div class="field input-ghost">
            <label class="label">{{'AVIS_VALEUR.LABEL.LOYER' | translateI18n}} :</label>
            <span class="field input-inline">
              <p-inputNumber  formControlName="loyer" mode="currency" inputId="currency-loyer"
                             placeholder="{{'AVIS_VALEUR.LABEL.RENSEIGNER' | translateI18n}}"
                             (onInput)="calculLoyerM2IfLoyerValueChanged($event)"
                             currency="EUR" locale="fr-FR"
                              [size]=20 [min]="0" [maxlength]="18"></p-inputNumber>
            </span>

            <span class="field input-inline">
              <p-dropdown [options]="regimeFiscalLocatif"
                          formControlName="regimechargeloyer" optionLabel="libelleCourt"
                          id="regimechargeloyer" name="regimechargeloyer"
                          emptyMessage="{{'AVIS_VALEUR.MESSAGE.AUCUN_RESULTAT' | translateI18n}}"
                          placeholder="{{'PLACEHOLDER.CHOOSE' | translateI18n}}"
                          [ngClass]="{'obligatoire': requiredValue(), 'ng-dirty ng-invalid ' : submittedRequired && f?.periodiciteloyer?.errors}">
              </p-dropdown>
            </span>

            <span class="field input-inline">   /   </span>

            <span class="field input-inline">
              <p-dropdown [options]="periodicitesLoyer"
                          formControlName="periodiciteloyer" optionLabel="libelle"
                          id="periodiciteloyer" name="periodiciteloyer"
                          emptyMessage="{{'AVIS_VALEUR.MESSAGE.AUCUN_RESULTAT' | translateI18n}}"
                          placeholder="{{'PLACEHOLDER.CHOOSE' | translateI18n}}"
                          [ngClass]="{'obligatoire': requiredValue(), 'ng-dirty ng-invalid ' :  submittedRequired && f?.periodiciteloyer?.errors}">
              </p-dropdown>

            </span>
            <span class="field input-inline"> {{'AVIS_VALEUR.LABEL.SOIT' | translateI18n}}</span>

            <span class="field">

              <p-inputNumber *ngIf="form?.value?.loyerm2" formControlName="loyerm2"
                             inputId="loyerm2"  mode="currency"
                             currency="EUR" locale="fr-FR"
                             [size]=15
                             [readonly]="true"
                             suffix="/m²{{form?.value?.periodiciteloyer != null ? '/'+form?.value?.periodiciteloyer.libelle : ''}}"
                             [disabled]="true">
              </p-inputNumber>

              <span *ngIf="!form?.value?.loyerm2" class="ml-2 w-1 text-center">{{'-- €/m²' + (form?.value?.periodiciteloyer != null ? ('/'+form?.value?.periodiciteloyer.libelle) : '')}}</span>
            </span>

          </div>


        </section>

      </div>


    </div>

  </div>
</form>
