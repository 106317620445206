import {ReferentielValeur} from "../referentiel-valeur";
import {StatutDossier} from "../enum/statut-dossier";
import {Adresse} from "../adresse";
import {BienCadastre} from "../bien/bien-cadastre";

export class RapportExpertise {

  idDossier : number;
  statutDossier : StatutDossier;
  numeroDossier : string;
  numeroContrat : string;
  etatEnvoi : string;
  situation : any;
  entretienInterne : any;
  entretienExterne : any;
  delaiPrecision : string;
  valeurJuridique : number;

  libelleEntiteJuridiqueNegLie : string;
  codeEntiteJuridiqueNegLie : string;
  nomTierNeg : string;
  negTierId : number;
  negTypeTiers : string;

  agenceNomTiers : string;
  dateVisite : Date;

  natureVisite : any;

  numFacture : string;

  idBien : number;
  typlogieBien : string;

  //dos_rapport
  nomExpert : string;
  prenomExpert : string;
  accompagnateur : string;
  piecesTransmises : string;
  paramTypeValeur : any;
  rapportOccupation : any;
  paramTypeRegimejRapport : any;
  numLotTantiemes : string;
  observations : string;
  population : number;
  localisation : string;
  secteur : ReferentielValeur;
  desserte : string;
  description : string;
  paramTopographie : any;
  entretien : ReferentielValeur;
  paramConfiguration : any;
  construction : string;
  distribution : string;
  equipement : string;
  etatGeneral : string;
  amenagementsInterieurs : string;
  paramReserve : any;
  idParamReserve : number;
  typeSurfaceRapport : any;
  detailSurface : string;
  existeDetailSurface : boolean;
  dpeCommentaire : string;
  dpe : ReferentielValeur;
  urbanisme : string;
  risquesEnvironnement : string;
  conditionOccupation : string;
  positionnement : string;
  forces : string;
  faiblesses : string;
  opportunites : string;
  menaces : string;
  estimation : string;
  analysemarche : string;
  analysemarcheLocatif : string;
  methodeValorisationRapport : any;
  dateValeur : Date;


  //Client
  nomCompletClient : string;
  nomRepresentant : string;
  clientphysique : boolean;
  abreviationCiviliteClient : string;

  designationcadastre : string;

  bienCadastre : BienCadastre[];

  typeLivrable : string;

  typeTiers : string;

  valeurLocative : number;
  valeurApresTravaux : number;
  valeurFuturAchevement : number;
  valeurGlobaleEstimee : number;

  typeBiens : ReferentielValeur;

  missionContexteLabel : string;
  missionContexteCode : string;
  bienAdresse : Adresse;
  //mission : Mission;
  dateDemande : Date;
  telAgent : string;
  portableAgent : string;
  emailAgent : string;
  paramLiquidite : any;
  delaiCommercialisation : any;


  prixM2ValeurVenal : number;
  prixM2ValeurLocative : number;
  prixM2ValeurApresTravaux : number;
  prixM2ValeurFuturAchevement : number;

  //Demandeur
  nomCompletDemandeur : string;

  //Tiers
  nomCompletTiers : string;

  supTerrain : number;
  sdhop : number;
  sup : number;
  su : number;
  sh : number;
  spc : number;
  shp : number;
  valueSurface : number;

  paramVille : any;

  //Bien caracteris
  regimeFiscalVenal : any;
  regimeFiscalLocatif : any;
  regimeFiscalApresTravaux : any;
  regimeFiscalEtatFuturAchevement : any;
  decote : number;
  tauxFiscaliteVenal : any;
  tauxFiscaliteApresTravaux : any;
  tauxFiscaliteEtatFuturAchevement : any;

  nomCompletSignataire : string;

  idExpert : number;

  dateContratConvention : Date;
  displayVenteForcee : Boolean;
  displayDateConvention : Boolean;
  synthese : Boolean;

  constructor(fields?: Partial<RapportExpertise>) {
    if (fields) {
      Object.assign(this, fields);
    }
  }
}
