<main class="layout-wrapper">
  <app-navigation></app-navigation>
  <app-header *ngIf="dossier" titre="{{dossier.numeroDossier}} / {{dossier.client?.nom ? dossier.client.nom  : dossier.client?.nomMoral}}"
              sstitre="{{dossier.statut | statutDossier}} {{dossier.detailFacture.length > 0 && dossier.statut === 'FACTUREE' ? ' / ' + dossier.detailFacture[0]?.facture?.numero : ''}}"
              [state]="dossier.statut" [signataire]="dossier?.signataire?.nomCompletSignataire"
              [etatEnvoi]="dossier.etatEnvoi"
              sstitre2="{{dossier.infoExpertise?.typeLivrable?.code | typeLivrable}}">
  </app-header>
  <app-navigation-contextuelle *ngIf="dossier" [AnchorItem]="anchorItem" [ActionsItem]="actionsItem" [SecondActionsItem]="livrableItem"></app-navigation-contextuelle>

  <div class="layout-main have-secondary-nav" id="main-layout" *ngIf="dossier" >
    <div class="page is-centered">

      <p-messages *ngIf="messageVerroullage" [(value)]="messageVerroullage" [enableService]="false" [escape]="false" [closable]="false"></p-messages>

      <p-messages *ngIf="message" [(value)]="message" [enableService]="false" [escape]="false"></p-messages>

      <div  *ngIf="errorMessageFormInvalid" class="mr-4">
        <p-messages [(value)]="errorMessageFormInvalid" [enableService]="false"></p-messages>
      </div>

      <div class="steps">
        <app-steps-nav [stepsList]="this.stepsList" ></app-steps-nav>
      </div>

      <h2  id="demandeur">{{'DOSSIER.DEMANDE.CREATION.DEMANDEUR' | translateI18n}}</h2>
      <div class="card">
        <div class="grid">
          <div class="col">
            <p class="locked"><b>{{dossier?.demande?.prenomDemandeur}} {{dossier?.demande?.nomDemandeur}}</b></p>
            <p class="locked"  *ngIf="dossier?.tiers?.adresse?.numero || dossier?.tiers?.adresse?.nomVoie || dossier?.tiers?.adresse?.codePostal
             || dossier?.tiers?.adresse?.ville">{{dossier?.tiers?.adresse?.numero}} {{dossier?.tiers?.adresse?.nomVoie}}<br>
              {{dossier?.tiers?.adresse?.codePostal}} {{dossier?.tiers?.adresse?.ville}}</p>
            <p class="locked"><span class="label">{{'DOSSIER.DEMANDE.CREATION.ENTITE' | translateI18n}} : </span>
              {{dossier.tiers.entiteClient}}<br>
            </p>
            <p class="locked" *ngIf="dossier.tiers.entiteClient === 'MRC'">
              <span class="label">{{'DOSSIER.DEMANDE.CREATION.PRETEUR' | translateI18n}} : </span>
              {{dossier.demande?.paramPreteur?.libelle}}</p>
            <p class="locked"><span class="label">{{'DOSSIER.DEMANDE.CREATION.RATTACHEMENT' | translateI18n}} : </span>
              {{dossier.tiers?.raisonSociale ? dossier.tiers?.raisonSociale : ' -- '}}</p>

          </div>

          <div class="col">
            <p class="locked"><a href="mailto:{{dossier.demande?.mailDemandeur}}">{{dossier.demande?.mailDemandeur ? dossier.demande?.mailDemandeur : ' -- '}}</a></p>
            <p class="locked" *ngIf="dossier.tiers?.mail"><a href="mailto:{{dossier.tiers?.mail}}">{{dossier.tiers?.mail ? dossier.tiers?.mail : ' -- '}}</a></p>
            <p class="locked"><span class="label">{{'DOSSIER.DEMANDE.CREATION.TELEPHONE' | translateI18n}} :</span>
              <a >{{dossier?.demande?.telephone ? dossier?.demande?.telephone : ' -- '}}</a></p>
          </div>

        </div>

      </div>


      <div *ngIf="form">
      <form [formGroup]="form" >

        <app-client-dossier formGroupName="client" [civilites]="civilites" [client]="dossier?.client" [fraisExp]="fraisExp"
                            [entiteClient]="dossier?.tiers?.entiteClient"
                            [submitted]="submitted" [user]="userConnecte"></app-client-dossier>


       <app-contact-dossier formGroupName="contact"
           [civilites]="civilites" [demande]="dossier.demande" [submitted]="submitted"
           [qualites]="qualitesContact" [user]="userConnecte"></app-contact-dossier>

        <app-bien-dossier formGroupName="bien" [bien]="dossier.bien" [idDossier]="dossier?.idDossier" [typeBiens]="typeBiens" [etages]="etages" [user]="userConnecte" [submitted]="submitted" ></app-bien-dossier>


        <app-expert-dossier *ngIf="dossier.infoExpertise?.expert" [expert]="dossier.infoExpertise" ></app-expert-dossier>

         <app-honoraires formGroupName="honoraireHT"  [pourcentageNeg]="purcentageNeg" [submitted]="submitted" [dossier]="dossier" [userConnecte]="userConnecte" [expertInterneUser]="expertInterneUser"></app-honoraires>

       <app-resultat-expertise [dossier]="dossier" ></app-resultat-expertise>

        <app-liste-document id="document"
              [idBien]="dossier.bien?.idBien" [idDossier]="dossier.idDossier" [statutDossier]="dossier.statut"
              [tierID]="dossier?.tiers?.id" [expertID]="dossier?.infoExpertise?.expert"
              [verouillerUpload]="verouillerUploadDocument" [verouillerExpert]="true">
        </app-liste-document>

        <div *ngIf="!isProfilExterne()">
         <h2 id="commentaire">{{'DOSSIER.OBSERVATION' | translateI18n}}</h2>
         <fieldset class="grid formgrid" *ngIf="dossier.statut !=='ANNULE' && isProfilAuthorize()">

           <div class="field col-12 field-line">
             <textarea [rows]="15" [cols]="114" pInputTextarea formControlName="commentaire" [autoResize]="false" maxlength="4000" class="w-full"></textarea>
             <div>
               {{'DOSSIER.DEMANDE.CREATION.MAX_CARACTERE' | translateI18n : '4 000'}}
             </div>

           </div>

         </fieldset>

          <div class="card" *ngIf="dossier.statut ==='ANNULE' || !isProfilAuthorize()">
            <div class="grid">
              <div class="col">
                <p>{{dossier.mission?.commentaire}}</p>
              </div>
            </div>
          </div>
        </div>


      </form>
      </div>

    </div>



  </div>

</main>

<ngx-spinner type="ball-scale-multiple"><p-progressSpinner></p-progressSpinner></ngx-spinner>
