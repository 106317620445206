export class ChangementRapide {

  idUtilisateurConnecte: number;
  idDossier: number;
  honoraires: number;
  fraisExpert: number;
  date: Date;
  commentaire: string;

}
