import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import {LocalStorageService} from "../local-storage/local-storage.service";
import {Router} from "@angular/router";
import {OAuthService} from "angular-oauth2-oidc";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(private tokenStorgeService : LocalStorageService, private router : Router, private authService : OAuthService ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let retUrl = this.router?.parseUrl(this.router?.url).queryParams["retUrl"];

    if (retUrl != null && retUrl != "/deconnexion") {
      this.tokenStorgeService.setCurrentURL(retUrl);
    }else {
      let urlSplit : string[] = this.router?.url?.split("=")
      if(!urlSplit[0].startsWith("/auth") && !urlSplit[0].startsWith("/deconnexion") && urlSplit[0] !== "/" && urlSplit[0] != "/help-navigation") {
        this.tokenStorgeService.setCurrentURL(this.router.url);
      }
    }

    let authToken = null;
    if(request.url.includes('/api/auth/interne')) {//intercepte token adfs
       authToken = this.authService.getIdToken();
    }else {//intercepte token applicatif valea
       authToken = this.tokenStorgeService.getToken();
    }
    const authRequest = request.clone({ headers: request.headers.set('Authorization', 'Bearer ' + authToken) });
    return next.handle(authRequest);
  }
}
