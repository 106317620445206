import {ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {AbstractControl, FormBuilder, FormGroup, FormGroupDirective} from "@angular/forms";
import {Client} from "../../../models/dossiers/creation-demande";
import {Adresse} from "../../../models/adresse";
import {AdresseComponent} from "../../../../shared/adresse/adresse.component";
import {DialogService, DynamicDialogRef} from "primeng/dynamicdialog";
import {Civilite} from "../../../models/param/civilite";
import {User} from "../../../models/user/user.model";
import {ConfigSizeInput} from "../../../../shared/providers/config-size-input";
import {FicheDossierObsevableService} from "../../../../shared/observable/fiche-dossier-obsevable.service";
import {ProfilesUser} from "../../../models/user/profiles-user";

@Component({
  selector: 'app-client-dossier',
  templateUrl: './client-dossier.component.html',
  styleUrls: ['./client-dossier.component.scss']
})
export class ClientDossierComponent implements OnInit {

  @Input()
  formGroupName: string;

  @Input()
  client : Client;

  @Input()
  entiteClient : string;

  @Input()
  submitted : boolean;

  @Input()
  civilites: Civilite[];

  @Input()
  user : User;

  @Input()
  fraisExp : any []

  clientForm: FormGroup;

  configSizeInput : ConfigSizeInput;

  constructor(
    private fb : FormBuilder,
    public dialogService: DialogService,
    public ref: DynamicDialogRef,
    private rootFormGroup: FormGroupDirective,
    private cdref: ChangeDetectorRef,
    private ficheDossierObservable : FicheDossierObsevableService
  ) {
    this.configSizeInput = new ConfigSizeInput();
  }

  ngOnInit(): void {

    this.clientForm = this.rootFormGroup.control.get(this.formGroupName) as FormGroup;

    this.ficheDossierObservable.sendClientInfo(this.clientForm.value.adresse);

  }


  choiceTypeClient() {
    if(this.clientForm.value.customerType !== null) {
      const type = this.clientForm.value.customerType;
/* isClientPhysique == false  cad company*/
/* isClientPhysique == true  cad humain*/

      /*ça pour comparer les donnés qui vient du bd donné avec celle du formulaire
      pour connaitre est ce que on garder les champs rempli du formulaire d'aprés les donnés de bd ou non */
      if((this.client?.isClientPhysique && this.clientForm.value.customerType === 'company')
        || (!this.client?.isClientPhysique && this.clientForm.value.customerType === 'human')) {
        this.clientForm.reset();
      }
      else {
        this.clientForm.get('gender').setValue(this.client.civilite);
        this.clientForm.get('nomClient').setValue(this.client.nom);
        this.clientForm.get('prenomClient').setValue(this.client.prenom);
        this.clientForm.get('nomMoral').setValue(this.client.nomMoral);
        this.clientForm.get('nomRepresentant').setValue(this.client.nomRepresentant);
        this.clientForm.get('phone1').setValue(this.client.telephone1);
        this.clientForm.get('phone2').setValue(this.client.telephone2);
        this.clientForm.get('email1').setValue(this.client.mail1);
        this.clientForm.get('email2').setValue(this.client.mail2);
        this.clientForm.get('numContrat').setValue(this.client.numeroContrat);
        this.clientForm.get('fraisExp').setValue(this.client.ir);
        this.clientForm.get('adresse.adresseFormate').setValue(this.client.adresse?.adresseFormate);
      }
      this.clientForm.get('customerType').setValue(type);
    }
  }

  addAdress(adresse? : any) {

    this.ref = this.dialogService.open(AdresseComponent, {
      header: "Adresse",
      width: '50vw',
      baseZIndex: 10000,
      data : {contry : 'allContry', adresse : adresse},
      dismissableMask: true,
      styleClass : 'p-dynamic-dialog'
    });
    this.ref.onClose.subscribe((adress : any) =>{

      if (adress) {

        this.clientForm.get('adresse.adresseFormate').setValue(adress.adresseFormate);
        this.clientForm.get('adresse.numero').setValue(adress.numero);
        this.clientForm.get('adresse.lieuDit').setValue(adress.lieuDit);
        this.clientForm.get('adresse.nomVoie').setValue(adress.nomVoie);
        this.clientForm.get('adresse.codePostal').setValue(adress.codePostal);
        this.clientForm.get('adresse.ville').setValue(adress.ville);
        this.clientForm.get('adresse.pays').setValue(adress.pays);
        this.clientForm.get('adresse.boitePostale').setValue(adress.boitePostale);
        this.clientForm.get('adresse.complement').setValue(adress.complement);
        this.clientForm.get('adresse.lng').setValue(adress.lng);
        this.clientForm.get('adresse.lat').setValue(adress.lat);
        this.clientForm.get('adresse.adresseChange').setValue(adress.adresseChange);
        this.ficheDossierObservable.sendClientInfo(adress);
      }
    });
  }

  ngAfterContentChecked() {
    this.cdref.detectChanges();
  }

  get f(): { [key: string]: AbstractControl } {
    return this.clientForm.controls;
  }

  isProfilAuthorize() : boolean {
    return this.user?.profiles && (this.user?.profiles?.includes(ProfilesUser.ADMINISTRATEUR)||this.user?.profiles?.includes(ProfilesUser.ADMINISTRATEUR_TECHNIQUE)
      || this.user?.profiles?.includes(ProfilesUser.ASSISTANTE)
      || this.user?.profiles?.includes(ProfilesUser.ANIMATEUR_RESEAU));
  }


}
