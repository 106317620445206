<div class="mb-3">
  <button pButton pRipple label="{{'RAPPORT_EXPERTISE.ACTIONS.AJOUTER_NOUVELLE_LIGNE' | translateI18n}}"
          icon="pi pi-plus" class="p-button-sm p-button-rounded" (click)="openNew()"></button>
</div>
<p-messages  *ngIf="messageBieAnnexe" [(value)]="messageBieAnnexe" key="tableAnnexe"></p-messages>
<p-table [value]="bienAnnexes" [rows]="settings.pagination?.rowsDocument"  [showCurrentPageReport]="true"
         [paginator]="bienAnnexes?.length > settings.pagination?.rowsDocument" [rowsPerPageOptions]="settings.pagination?.rowsPerPageOptionsDocument"
         [globalFilterFields]="['annexe.libelle','nombre']"
         dataKey="id" paginatorPosition="top"
         currentPageReportTemplate="Affichage {first} à {last} sur {totalRecords}" >
  <ng-template pTemplate="header">
    <tr>
      <th></th>
      <th pSortableColumn="annexe.libelle" style="min-width:25rem">{{'RAPPORT_EXPERTISE.LABEL.NATURE' | translateI18n}}<p-sortIcon field="annexe.libelle"></p-sortIcon></th>
      <th pSortableColumn="nombre" style="min-width:25rem">{{'RAPPORT_EXPERTISE.LABEL.NOMBRE' | translateI18n}}<p-sortIcon field="nombre"></p-sortIcon></th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-biea>
    <tr>
      <td class="text-left">
        <button type="button" pButton pRipple icon="inc-icon inc-icon-trash is-small"
                pTooltip="{{'BUTTON_LABEL.DELETE' | translateI18n}}" tooltipPosition="top"
                class="p-button-rounded " (click)="deleteBieAnnexe(biea)"></button>
        <button type="button" pButton pRipple icon="inc-icon inc-icon-edit is-small"
                pTooltip="{{'BUTTON_LABEL.MODIFY' | translateI18n}}" tooltipPosition="top"
                class="p-button-rounded p-button-success mr-2" (click)="editBieAnnexe(biea)"></button>
      </td>
      <td>{{biea?.annexe?.libelle}}</td>
      <td>{{biea?.nombre}}</td>

    </tr>
  </ng-template>

</p-table>



<p-dialog  [(visible)]="dialog" [style]="{width: '500px'}" header="{{'RAPPORT_EXPERTISE.LABEL.AJOUTER_ELEMENT' | translateI18n}}" [modal]="true" styleClass="p-fluid">

  <ng-template pTemplate="content">
    <p-messages  *ngIf="messageBieAnnexeDialog" [(value)]="messageBieAnnexeDialog" key="dialogAnnexe"></p-messages>
    <form [formGroup]="annexeForm" class="page-form" >

      <div class="field">
        <label for="nature" [ngClass]="{'p-error': submitted
        && formBieAnnexe.nature.invalid}" class="obligatoire">
          {{'RAPPORT_EXPERTISE.LABEL.NATURE' | translateI18n}}
        </label>
        <div class="p-dropdown-group">
        <p-dropdown [options]="annexes" formControlName="nature"
                    optionLabel="libelle" name="nature"  id="nature"
                    [ngClass]="{'ng-dirty ng-invalid':  submitted  && formBieAnnexe.nature.invalid}"
                    emptyMessage="{{'RAPPORT_EXPERTISE.MESSAGE.AUCUN_RESULTAT' | translateI18n}}"
                    placeholder="{{'PLACEHOLDER.CHOOSE' | translateI18n}}" class="w-full">
        </p-dropdown>

        <small class="p-error" *ngIf="submitted  && formBieAnnexe.nature?.errors?.required">{{'MESSAGE.ERROR.CHAMPS_OBLIGATOIRE' | translateI18n}}</small>
        </div>
      </div>

      <div class="field">
        <label for="nombre" [ngClass]="{'p-error': submitted  && formBieAnnexe.nombre.invalid}" class="obligatoire">
          {{'RAPPORT_EXPERTISE.LABEL.NOMBRE' | translateI18n}}
        </label>
        <input type="text" class="inputfield" pInputText id="nombre" formControlName="nombre"
               [ngClass]="{'ng-dirty': submitted  && (formBieAnnexe.nombre?.errors || formBieAnnexe.nombre.invalid)}" [maxLength]="25" required/>

        <div *ngIf="submitted" class="pt-2">
          <small class="p-error" *ngIf="formBieAnnexe.nombre?.errors?.required">{{'MESSAGE.ERROR.CHAMPS_OBLIGATOIRE' | translateI18n}}</small>
        </div>
      </div>


    </form>
  </ng-template>

  <ng-template pTemplate="footer" class="mt-5">
    <button pButton type="button" pRipple label="{{'BUTTON_LABEL.ANNULER' | translateI18n}}" icon="pi pi-times" class="uppercase p-button-secondary p-button-outlined mb-2"
            (click)="hideDialog()"></button>
    <button pButton type="submit" pRipple label="{{'BUTTON_LABEL.ENREGISTRER' | translateI18n}}" icon="pi pi-save" class="ppercase mb-2" (click)="saveBienAnnexe()"></button>
  </ng-template>

</p-dialog>


