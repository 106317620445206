import {ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {BienAmenagementInterieur} from "../../../../models/bien/bien-amenagement-interieur";
import {AbstractControl, FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {DialogService, DynamicDialogRef} from "primeng/dynamicdialog";
import {ConfirmationService, Message, MessageService} from "primeng/api";
import {TranslateService} from "../../../../../shared/translate/translate.service";
import {RapportExpertiseService} from "../../../../service/rapport-expertise.service";
import {AppSettings, Settings} from "../../../../../app.settings";
import {RapportExpertise} from "../../../../models/rapport-expertise/rapport-expertise";
import {ReferentielValeur} from "../../../../models/referentiel-valeur";

@Component({
  selector: 'app-table-amenagements-interieurs',
  templateUrl: './table-amenagements-interieurs.component.html',
  styleUrls: ['./table-amenagements-interieurs.component.scss']
})
export class TableAmenagementsInterieursComponent implements OnInit {

  bienAmenagementsInterieur : BienAmenagementInterieur[];
  bieAmenagement : BienAmenagementInterieur;

  bienAmenagementDialog: boolean;
  submittedAmenagement : boolean;

  bienAmenagementForm : FormGroup;
  messageAmenagement : Message[];

  message : Message[];

  nbCaractDescriptionAmenagement : number = 0;

  amenagementsInterieur : ReferentielValeur[];

  public settings: Settings;

  @Input()
  rapport : RapportExpertise;


  constructor(
    private fb : FormBuilder,
    public dialogService: DialogService,
    public ref: DynamicDialogRef,
    private cdref: ChangeDetectorRef,
    private messageService : MessageService,
    private rapportExpertiseService : RapportExpertiseService,
    private confirmation: ConfirmationService,
    public appSettings : AppSettings,
    private translate : TranslateService
  ) {
    this.settings = this.appSettings.settings;
  }

  ngOnInit(): void {
    this.getAllAmenagementInterieurByBienId();
    this. getAllAmenagementInterieur();
  }

  initBienAmenagementForm(bieai? : BienAmenagementInterieur) {
    this.bienAmenagementForm = this.fb.group({
      amenagementInterieur : new FormControl(bieai?.amenagementInterieur ? bieai?.amenagementInterieur : null, Validators.required),
      descriptionAmenagement : new FormControl(bieai?.description ? bieai?.description :null, Validators.maxLength(75)),
    });
  }

  get formBieAmenagement(): { [key: string]: AbstractControl } {
    return this.bienAmenagementForm.controls;
  }

  async getAllAmenagementInterieurByBienId() {
    this.bienAmenagementsInterieur= await this.rapportExpertiseService.getAllAmenagementInterieurByBienId(this.rapport.idBien);
  }

  getAllAmenagementInterieur() {
    this.rapportExpertiseService.getAllAmenagementInterieur().subscribe( data => {
      this.amenagementsInterieur = data;
    })
  }

  hideDialogAmenagement() {
    this.bienAmenagementDialog = false;
    this.submittedAmenagement = false;
  }

  openNewAmenagement() {
    this.messageAmenagement = null;
    this.initBienAmenagementForm();
    this.bieAmenagement = null;
    this.submittedAmenagement = false;
    this.bienAmenagementDialog = true;
  }

  saveBienAmenagement() {
    this.submittedAmenagement = true;

    this.messageAmenagement = null;

    if(this.bienAmenagementForm.invalid) {
      this.messageAmenagement =[ {severity:'error', summary:  this.translate.getElement("RAPPORT_EXPERTISE.MESSAGE.ERROR_FORM")}];
      return;
    }

    const bieai : BienAmenagementInterieur = new BienAmenagementInterieur();

    if(this.bieAmenagement) {
      bieai.id = this.bieAmenagement?.id;
    }

    const value = this.bienAmenagementForm.value;
    bieai.idBien = this.rapport?.idBien;
    bieai.idDossier = this.rapport?.idDossier;
    bieai.amenagementInterieur = value.amenagementInterieur;
    bieai.description = value.descriptionAmenagement;


    this.rapportExpertiseService.saveBieAmenagementInterieur(bieai).subscribe( data => {
      if(data) {
        this.hideDialogAmenagement()
        this.getAllAmenagementInterieurByBienId();
      }else {
        this.messageAmenagement =[ {key : 'dialog', severity:'error', summary:  this.translate.getElement("RAPPORT_EXPERTISE.MESSAGE.ERROR_MESSAGE_MODAL")}];
        this.submittedAmenagement = false;
      }
    }, error => {
      this.messageAmenagement =[ {key : 'dialog', severity:'error', summary:  this.translate.getElement("RAPPORT_EXPERTISE.MESSAGE.ERROR_MESSAGE_MODAL")}];
      this.submittedAmenagement = false;
    })
  }

  editBienAmenagementInterieur(bieai : BienAmenagementInterieur) {
    this.messageAmenagement = null;
    this.bieAmenagement = {...bieai};
    this.initBienAmenagementForm(this.bieAmenagement);
    this.nbCaractDescriptionAmenagement = bieai?.description?.length;
    this.bienAmenagementDialog = true;
  }

  deleteBienAmenagementInterieur(bieai : BienAmenagementInterieur) {
    if(bieai) {
      this.confirmation.confirm({
        header: this.translate.getElement("RAPPORT_EXPERTISE.MESSAGE.ETES_VOUS_SUR"),
        message : this.translate.getElement("RAPPORT_EXPERTISE.MESSAGE.CONFIRMER"),
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          this.rapportExpertiseService.deleteBienAmenagementInterieur(bieai.id, this.rapport?.idDossier).then( data => {
            if(data) {
              this.getAllAmenagementInterieurByBienId();
              this.message =[ {key : 'table', severity:'success', summary:  this.translate.getElement("RAPPORT_EXPERTISE.MESSAGE.SUCESS_SUPPRESSION")}];
              return;
            }
            this.message =[ {key : 'table', severity:'error', summary:  this.translate.getElement("RAPPORT_EXPERTISE.MESSAGE.ERROR_SUPPRESSION")}];
          }).catch( error => {
            this.message =[ {key : 'table', severity:'error', summary:  this.translate.getElement("RAPPORT_EXPERTISE.MESSAGE.ERROR_SUPPRESSION")}];
          })
        }
      })

    }
  }

  detectChangeDescripionAmenegament(event : any) {
    this.nbCaractDescriptionAmenagement =  String(event).length;
  }

}
