export class BienReference {
  id : number;
  typeBien : any;
  adresse : string;
  datetransac : Date;
  annexe  : string;
  surface  : string;
  prixvente : number;
  prixm2 : number;
  observations : number;
  numerobienref : string;
  idDossier : number;
}
