import {Injectable} from '@angular/core';
import {BehaviorSubject} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class AvisValeurObservableService {

  private _surfaceSubject$ : BehaviorSubject<number> = new BehaviorSubject<number>(null);
  private _loyerM2Subject$ : BehaviorSubject<number> = new BehaviorSubject<number>(null);

  constructor() { }

  sendSurface(surface : number) {
    this._surfaceSubject$.next(surface);
  }

  getSurface() {
    return this._surfaceSubject$.asObservable();
  }

  sendTypeValeur(typeValeur : any) {
    this._loyerM2Subject$.next(typeValeur)
  }

  getTypeValeur() {
    return this._loyerM2Subject$.asObservable();
  }
}
