import {Component, Input, OnInit} from '@angular/core';
import {FormGroup, FormGroupDirective} from "@angular/forms";

@Component({
  selector: 'app-input-client',
  templateUrl: './input-client.component.html',
  styleUrls: ['./input-client.component.scss']
})
export class InputClientComponent implements OnInit {

  @Input()
  formGroupName: string;
  form: FormGroup;

  constructor(private rootFormGroup: FormGroupDirective) { }

  ngOnInit(): void {
    this.form = this.rootFormGroup.control.get(this.formGroupName) as FormGroup;
  }

}
