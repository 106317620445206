import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {DossierRoutingModule} from './dossier-routing.module';
import {CoreModule} from "../../core/core.module";
import {FolderListToAssignComponent} from "./affectation/folder-list-to-assign/folder-list-to-assign.component";
import {SharedModule} from "../../shared/shared.module";
import {TableModule} from "primeng/table";
import {AutoCompleteModule} from "primeng/autocomplete";
import {MessagesModule} from "primeng/messages";
import {ToastModule} from "primeng/toast";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {RadioButtonModule} from "primeng/radiobutton";
import {DropdownModule} from "primeng/dropdown";
import {ButtonModule} from "primeng/button";
import {RippleModule} from "primeng/ripple";
import {InputTextModule} from "primeng/inputtext";
import {PaginatorModule} from "primeng/paginator";
import {MessageModule} from "primeng/message";
import {RechercheDossierGestionnaireComponent} from './recherche-gestionnaire/recherche-gestionnaire.component';
import {DialogModule} from "primeng/dialog";
import {DynamicDialogModule} from "primeng/dynamicdialog";
import {RechercheDossierExpertComponent} from './recherche-expert/recherche-expert.component';
import {RechercheDemandeComponent} from './recherche-demande/recherche-demande.component';
import {TooltipModule} from "primeng/tooltip";
import {ToolbarModule} from "primeng/toolbar";
import {CreationDemandeComponent} from "./creation-demande/creation-demande.component";
import {TabViewModule} from "primeng/tabview";
import {EditorModule} from "primeng/editor";
import {InputTextareaModule} from "primeng/inputtextarea";
import {FicheDemandeComponent} from './fiche-demande/fiche-demande.component';
import {GMapModule} from "primeng/gmap";
import {SplitButtonModule} from "primeng/splitbutton";
import {MenuModule} from "primeng/menu";
import {InplaceModule} from "primeng/inplace";
import {FicheDossierComponent} from "./fiche-dossier/fiche-dossier.component";
import {NgxSpinnerModule} from "ngx-spinner";
import {ConfirmDialogModule} from "primeng/confirmdialog";
import {ProgressSpinnerModule} from "primeng/progressspinner";

@NgModule({
  declarations: [
    FolderListToAssignComponent,
    RechercheDossierGestionnaireComponent,
    RechercheDossierExpertComponent,
    RechercheDemandeComponent,
    CreationDemandeComponent,
    FicheDemandeComponent,
    FicheDossierComponent
  ],
    imports: [
        CommonModule,
        DossierRoutingModule,
        SharedModule,
        CoreModule,
        TableModule,
        AutoCompleteModule,
        ToastModule,
        MessagesModule,
        FormsModule,
        ReactiveFormsModule,
        RadioButtonModule,
        DropdownModule,
        RippleModule,
        ButtonModule,
        InputTextModule,
        PaginatorModule,
        MessageModule,
        DialogModule,
        DynamicDialogModule,
        TooltipModule,
        ToolbarModule,
        TabViewModule,
        EditorModule,
        InputTextareaModule,
        GMapModule,
        SplitButtonModule,
        MenuModule,
        InplaceModule,
        NgxSpinnerModule,
        ConfirmDialogModule,
        ProgressSpinnerModule
    ]
})
export class DossierModule { }
