import {Adresse} from "../adresse";
import {BienCaracteris} from "./bien-caracteris";
import {ReferentielValeur} from "../referentiel-valeur";

export class Client {

  idClient : number;
  codeCivilite : string;
  nom : string;
  prenom : string;
  nomMoral : string;
  nomRepresentant : string;
  cpRepresentant : string
  telephone1 : string;
  telephone2 : string;
  mail1 : string;
  mail2 : string;
  adresse : Adresse;
  numeroContrat : string;
  ir : string;
  nomComplet : string;

  isClientPhysique : boolean;
  abreviationCivilite : string;

  civilite : any;

  constructor(
    codeCivilite : string, nom : string, prenom : string, nomMoral : string,
    nomRepresentant : string, cpRepresentant : string, telephone1 : string, telephone2 : string,
    mail1 : string, mail2 : string, numeroContrat : string, ir : string, adress : Adresse
  ) {
    this.codeCivilite = codeCivilite;
    this.nom = nom;
    this.prenom = prenom;
    this.nomMoral = nomMoral;
    this.nomRepresentant = nomRepresentant;
    this.telephone1 = telephone1;
    this.telephone2 = telephone2;
    this.mail1 = mail1;
    this.mail2 = mail2;

    this.numeroContrat = numeroContrat;
    this.ir = ir;

    this.adresse = adress;
  }

}

export class Contact {
  identiqueClient : boolean;
  idQualiteContact : number;
  codeCivilite : string;
  nom : string;
  prenom : string;
  raisonSociale : string;
  telephone1 : string;
  telephone2 : string;
  mail1 : string;
  mail2 : string;
  adresse : Adresse;

  constructor(
    identiqueClient : string, idQualiteContact : number, codeCivilite : string, nom : string, prenom : string, raisonSociale : string,
    telephone1 : string, telephone2 : string, mail1 : string, mail2 : string, adress : Adresse
  ) {
    this.codeCivilite = codeCivilite;
    this.nom = nom;
    this.prenom = prenom;
    this.raisonSociale = raisonSociale;
    this.telephone1 = telephone1;
    this.telephone2 = telephone2;
    this.mail1 = mail1;
    this.mail2 = mail2;

    this.idQualiteContact = idQualiteContact;

    if(identiqueClient === 'idClient') {
      this.identiqueClient = true;
    } else  if(identiqueClient === 'autre') {
      this.identiqueClient = false;
    }


    if(adress) {
      this.adresse = adress;
    }

  }

}

export class Bien {
  codeTypeBien  : string;
  identiqueClient  : boolean;
  enConflit: boolean;
  adresse : Adresse;
  codeEtage : string;

  bienCaracteris : BienCaracteris;
  paramLiquidite : any;
  etage : string;
  typeBien : string;
  idBien : number;
  refTypeBien : ReferentielValeur;

  typeBiens : ReferentielValeur;

  constructor(codeTypeBien : string, identiqueClient : string, adresse : Adresse, codeEtage : string ) {
   this.codeTypeBien = codeTypeBien;

    if(identiqueClient === 'idClient') {
      this.identiqueClient = true;
    } else  if(identiqueClient === 'autre') {
      this.identiqueClient = false;
    }

   this.adresse = adresse;
   this.codeEtage = codeEtage;
  }
}


export class CreationDemande {
  codeTypeLivrable : string;
  preteur : number;
  client : Client;
  contact : Contact;
  bien : Bien;
  commentaire : string;
}
