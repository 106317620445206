import {Component, OnInit} from '@angular/core';
import {UsefulFunctionService} from "../../../core/service/usefulFunction.service";
import {LocalStorageService} from "../../../core/service/local-storage/local-storage.service";
import {User} from "../../../core/models/user/user.model";
import {TranslateService} from "../../../shared/translate/translate.service";
import {ProfileService} from "../../../core/service/user/profile.service";
import {Router} from "@angular/router";
import {UserService} from "../../../core/service/user/user.service";
import {ProfilesUser} from "../../../core/models/user/profiles-user";

@Component({
  selector: 'app-profil',
  templateUrl: './profil.component.html',
  styleUrls: ['./profil.component.scss']
})
export class ProfilComponent implements OnInit {

  user : User;
  profils : ProfilesUser[];

  langues: any[];

  langue: any;

  constructor(
    private localStorageService : LocalStorageService,
    public usefulFunctionService : UsefulFunctionService,
    private translateService : TranslateService,
    private profileService : ProfileService,
    private router : Router,
    private userService : UserService
  ) {
    this.langue = this.languageLabel(this.translateService.getLanguage());
    this.localStorageService.setCurrentURL(this.router.url);
  }

  ngOnInit(): void {

    this.getUserConnected();

    this.langues = [
      {name: 'Français', code: 'FR'},
      {name: 'English', code: 'EN'}
    ];

  }

  async getUserConnected() {
    this.user = await this.userService.getUserConnectedByToken();
    this.profils = await this.userService.getUserConnectedProfilesById();
  }

  getLanguage() {
    const code = this.langue.code;
    this.profileService.updateUserLanguage(code.toUpperCase()).subscribe(data => {
      this.translateService.deleteLocalStorageLanguage();
      this.translateService.setLanguage(code);
      window.location.reload();
    }, error => {

    })

  }

  languageLabel(lang : string) {
    let langue = lang.toLowerCase();
     if(this.translateService.getLanguage().toLowerCase() === 'fr'.toLowerCase()) {
       switch (langue) {
         case 'fr'.toLowerCase() :
           return 'Français';
         case 'en'.toLowerCase() :
           return 'Anglais';
         default :
           return '';
       }
     }

    else if(this.translateService.getLanguage().toLowerCase() === 'en'.toLowerCase()) {
      switch (langue) {
        case 'fr'.toLowerCase() :
          return 'French';
        case 'en'.toLowerCase() :
          return 'English';
        default :
          return '';
      }
    }

    return '';

  }
}
