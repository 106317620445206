<form [formGroup]="bienForm">

  <h2  id="bien">{{'DOSSIER.DEMANDE.CREATION.BIEN_EXPERTISER' | translateI18n}}</h2>

  <div class="grid grid-card">

    <!-- colonne -->
    <div class="col-12" [ngClass]="{ 'lg:col-6': (displayMapGoogle && !documentCarte) || documentCarte}">

      <!-- contenu -->
      <div class="card grid">
        <div class="field col-12" *ngIf="f.typeBien.status !== 'DISABLED'" [ngClass]="{ 'lg:col-6': !displayMapGoogle}">
          <label for="typeBien" class="obligatoire">{{'DOSSIER.DEMANDE.CREATION.TYPE_BIEN' | translateI18n}}</label>
          <div class="p-input-icon-right w-full">
            <p-dropdown [options]="typeBiens" placeholder="{{'PLACEHOLDER.CHOOSE' | translateI18n}}" id="typeBien" formControlName="typeBien" name="typeBien"
                        optionLabel="paramReferentielValeurByCode.libelle"
                        [ngClass]="{'ng-dirty ng-invalid': submitted && bienForm.value.typeBien === null}"></p-dropdown>
          </div>


          <small *ngIf="submitted  && bienForm.value.typeBien === null" class="p-error block">{{'MESSAGE.ERROR.CHAMPS_OBLIGATOIRE' | translateI18n}}</small>

        </div>

        <div class="field col-12" *ngIf="f.typeBien.status === 'DISABLED'">
          <p>
            <span class="label"> {{'DOSSIER.DEMANDE.CREATION.TYPE_BIEN' | translateI18n}} : </span>
            {{bienForm.value?.typeBien?.paramReferentielValeurByCode?.libelle}}</p>
        </div>

        <div class="field col-12" *ngIf="f.etage.status !== 'DISABLED'" [ngClass]="{ 'lg:col-6': !displayMapGoogle}">
          <label for="etage">{{'DOSSIER.DEMANDE.CREATION.ETAGE' | translateI18n}}</label>
          <div class="p-input-icon-right w-full">
            <p-dropdown [options]="etages" placeholder="{{'PLACEHOLDER.CHOOSE' | translateI18n}}." id="etage" formControlName="etage" name="etage"
                        optionLabel="libelle"></p-dropdown>
          </div>

        </div>

        <div class="field col-12" *ngIf="f.etage.status === 'DISABLED'">
          <p>
            <span class="label"> {{'DOSSIER.DEMANDE.CREATION.ETAGE' | translateI18n}} : </span>
            {{bienForm?.value?.etage?.libelle}}</p>
        </div>

        <div class="col-12 field field-line">
          <!-- .p-radiobutton-group regroupement .inline-group pour afficher les radiobutton en ligne -->
          <label [ngClass]="{ 'p-error': submitted && bienForm?.get('adresse.adresseFormate')?.value === null}">
            {{'DOSSIER.DEMANDE.CREATION.ADRESSE' | translateI18n}}</label>
          <div class="p-radiobutton-group inline-group" >
            <p-radioButton  value="idClient" formControlName="customerAdress"
                            [ngClass]="{'read-only-form' : bienForm?.controls?.customerAdress?.status === 'DISABLED'}"
                            label="Adresse du client" (click)="choiceCustomerAdress()">
            </p-radioButton>
            <p-radioButton value="autre" formControlName="customerAdress"
                           [ngClass]="{'read-only-form' : bienForm?.controls?.customerAdress?.status === 'DISABLED'}"
                           label="Autre" (click)="choiceCustomerAdress()"></p-radioButton>
          </div>

        </div>

        <div class="field col-12" formGroupName="adresse">

          <div class="flex flex-row">
            <div *ngIf="adresseComplet" style="background-color: #ffffff;display: flex;align-items: center;" >
              <span class="p-3">{{adresseComplet}}</span>
            </div>

            <button type="button"  pButton pRipple [disabled] ="!isProfilAuthorize()"
                    *ngIf="!disabledAdresseButton && bienForm.value.customerAdress === 'autre'
                    && bienForm.get('adresse.adresseFormate').value === null && bienForm.controls.adresse.status !== 'DISABLED'"
                    label="{{'BUTTON_LABEL.ADD' | translateI18n}}" (click)="addAdress()"></button>

            <button type="button"  pButton pRipple [disabled] ="!isProfilAuthorize()"
                    *ngIf="!disabledAdresseButton && bienForm?.value?.customerAdress === 'autre'
                    && bienForm?.get('adresse.adresseFormate')?.value !== null && bienForm?.controls?.adresse?.status !== 'DISABLED'"
                    label="{{'BUTTON_LABEL.MODIFY' | translateI18n}}" (click)="addAdress(bienForm?.value?.adresse)"></button>
          </div>

          <div *ngIf="submitted" class="pt-2">
            <small *ngIf="bienForm.get('adresse.adresseFormate').value === null" class="p-error block">{{'MESSAGE.ERROR.CHAMPS_OBLIGATOIRE' | translateI18n}}</small>
          </div>
        </div>

      </div>
      <!-- FIN contenu -->

    </div>

    <!-- colonne -->
    <div class="col-12 lg:col-6" *ngIf="documentCarte || (displayMapGoogle && !documentCarte)">

      <div class="card-map" style="height:100%;"  >
        <app-carte-google-maps *ngIf="displayMapGoogle && !documentCarte" [adresse]="addr"></app-carte-google-maps>

        <img  *ngIf="documentCarte" [src]="documentCarte?.lienImage" alt="" width="100%" height="100%" >
      </div>



    </div>

  </div>

</form>
