<form  [formGroup]="form" >

  <h2 id="contact">{{titre}}</h2>
  <fieldset class="grid formgrid">


    <!--col-12 pour une colonne sur toute la largeur -->
    <div class="field col-12 field-line">

      <!-- .p-radiobutton-group regroupement .inline-group pour afficher les radiobutton en ligne -->
      <div class="p-radiobutton-group inline-group">
        <p-radioButton  value="idClient" formControlName="contactType"
                        [ngClass]="submitted && form.get('contactType').value === null ? 'radioErrorColor' : null"
                        label="{{'DOSSIER.DEMANDE.CREATION.ID_CLIENT' | translateI18n}}" (onClick)="choiceModeContact()">
        </p-radioButton>
        <p-radioButton value="autre" formControlName="contactType"
                       [ngClass]="submitted && form.get('contactType').value === null ? 'radioErrorColor' : null"
                       label="{{'DOSSIER.DEMANDE.CREATION.OTHER' | translateI18n}}" (onClick)="choiceModeContact()"></p-radioButton>
      </div>
      <div *ngIf="submitted && form.get('contactType').value === null" class="pt-2">
        <small  class="p-error block">{{'MESSAGE.ERROR.CHAMPS_OBLIGATOIRE' | translateI18n}}</small>
      </div>
    </div>


    <!-- col-12 pour une colonne sur toute la largeur -->
    <div class="field col field-line mt-3" *ngIf="form.value.contactType === 'autre'">

      <!-- .p-radiobutton-group regroupement .inline-group pour afficher les radiobutton en ligne -->
      <div class="p-radiobutton-group inline-group">
        <p-radioButton  value="human" formControlName="customerContactType"
                        [ngClass]="submitted && form.get('customerContactType').value === null ? 'radioErrorColor':null"
                        label="{{'DOSSIER.DEMANDE.CREATION.PHYSICAL_PERSON' | translateI18n}}" (onClick)="choiceContactType()">
        </p-radioButton>
        <p-radioButton value="company" formControlName="customerContactType"
                       [ngClass]="submitted && form.get('customerContactType').value === null ? 'radioErrorColor':null"
                       label="{{'DOSSIER.DEMANDE.CREATION.CORPORATION' | translateI18n}}" (onClick)="choiceContactType()"></p-radioButton>
      </div>
      <div *ngIf="submitted && form.get('customerContactType').value === null" class="pt-2">
        <small  class="p-error block">{{'MESSAGE.ERROR.CHAMPS_OBLIGATOIRE' | translateI18n}}</small>
      </div>
    </div>

    <div class="col-12 grid field-line mt-3" *ngIf="form.value.contactType === 'autre' && (form.value.customerContactType === 'human' || form.value.customerContactType === 'company') ">

      <div class="col-12 grid field-line" >
        <div class="field col">
          <label for="qualite" class="obligatoire">
            {{'DOSSIER.DEMANDE.CREATION.QUALITE' | translateI18n}}</label>
          <div class="p-input-icon-right w-full">
            <p-dropdown [options]="qualite" placeholder="{{'PLACEHOLDER.CHOOSE' | translateI18n}}" id="qualite" formControlName="qualite" name="qualite"
                        optionLabel="libelle" [ngClass]="{'ng-dirty ng-invalid': submitted && form.get('qualite').value === null}"></p-dropdown>
          </div>

          <div *ngIf="submitted && form.get('qualite').value === null " class="pt-2">
            <small class="p-error block">{{'MESSAGE.ERROR.CHAMPS_OBLIGATOIRE' | translateI18n}}</small>
          </div>
        </div>

        <div class="field col" *ngIf="form.value.customerContactType === 'human'">
          <label for="gender"  class="obligatoire">
            {{'DOSSIER.DEMANDE.CREATION.CIVILITY' | translateI18n}}</label>
          <div class="p-input-icon-right w-full">
            <p-dropdown [options]="civilites" placeholder="{{'PLACEHOLDER.CHOOSE' | translateI18n}}" id="gender" formControlName="gender" name="gender"
                        optionLabel="abreviation" [ngClass]="{'ng-dirty ng-invalid': submitted && form.get('gender').value === null}"
            ></p-dropdown>
          </div>

          <div *ngIf="submitted && form.get('gender').value === null " class="pt-2">
            <small  class="p-error block">{{'MESSAGE.ERROR.CHAMPS_OBLIGATOIRE' | translateI18n}}</small>
          </div>
        </div>
      </div>

      <div class="col-12 grid field-line" *ngIf="form.value.customerContactType === 'human'">
        <div class="field col">
          <label for="nomContact" class="obligatoire">
            {{'DOSSIER.DEMANDE.CREATION.NOM' | translateI18n}}</label>
          <div class="p-input-icon-right w-full">
            <i class="pi pi-user"></i>
            <input type="text" pInputText formControlName="nomContact" id="nomContact" class="inputfield w-full"
                   [ngClass]="{'ng-dirty ng-invalid': submitted && (form.value.nomContact === null || form.value.nomContact.length === 0)}"
            [maxLength]="configSizeInput.NOM_CONTACT"/>
          </div>

          <div *ngIf="submitted" class="pt-2">
            <small *ngIf="f.nomContact?.errors?.pattern" class="p-error block">{{'MESSAGE.ERROR.REGEX_PATTERN' | translateI18n}}</small>
            <small *ngIf="form.value.nomContact === null || form.value.nomContact.length === 0" id="form-title" class="p-error block">
              {{'MESSAGE.ERROR.CHAMPS_OBLIGATOIRE' | translateI18n}}</small>
          </div>
        </div>

        <div class="field col">
          <label for="firstname" class="obligatoire">{{'DOSSIER.DEMANDE.CREATION.PRENOM' | translateI18n}}</label>
          <div class="p-input-icon-right w-full">
            <i class="pi pi-user"></i>
            <input type="text" pInputText formControlName="prenomContact" id="firstname" class="inputfield w-full"
                   [ngClass]="{'ng-dirty ng-invalid': submitted && (form.value.prenomContact === null || form.value.prenomContact.length === 0)}"
                   [maxLength]="configSizeInput.PRENOM_CONTACT"/>
          </div>

          <div *ngIf="submitted" class="pt-2">
            <small *ngIf="f.prenomContact?.errors?.pattern" class="p-error block">{{'MESSAGE.ERROR.REGEX_PATTERN' | translateI18n}}</small>
            <small *ngIf="form.value.prenomContact === null || form.value.prenomContact.length === 0" class="p-error block">
              {{'MESSAGE.ERROR.CHAMPS_OBLIGATOIRE' | translateI18n}}</small>
          </div>

        </div>
      </div>

      <div class="col-12 grid field-line" *ngIf="form.value.customerContactType === 'company'">
        <div class="field col">
          <label for="nommoral" class="obligatoire">{{'DOSSIER.DEMANDE.CREATION.NOM_MORAL' | translateI18n}}</label>
          <div class="p-input-icon-right w-full">
            <i class="pi pi-user"></i>
            <input type="text" pInputText formControlName="nomMoral" id="nommoral" class="inputfield w-full"
                   [ngClass]="{'ng-dirty ng-invalid': submitted && (form.value.nomMoral === null || form.value.nomMoral.length === 0)}"
                   [maxLength]="configSizeInput.RAISON_SOCIALE_CONTACT"/>
          </div>

          <div *ngIf="submitted" class="pt-2">
            <small *ngIf="f.nomMoral?.errors?.pattern" class="p-error block">{{'MESSAGE.ERROR.REGEX_PATTERN' | translateI18n}}</small>
            <small *ngIf="form.value.nomMoral === null || form.value.nomMoral.length === 0" class="p-error block">
              {{'MESSAGE.ERROR.CHAMPS_OBLIGATOIRE' | translateI18n}}</small>
          </div>
        </div>

      </div>

      <!-- Ligne formulaire -->
      <div class="col-12 grid field-line" *ngIf="form.value.customerContactType === 'human' || form.value.customerContactType === 'company'">
        <div class="field col">
          <label for="phone1">
            {{'DOSSIER.DEMANDE.CREATION.PHONE' | translateI18n : '1'}}
            <span> {{'DOSSIER.DEMANDE.CREATION.OPTIONAL' | translateI18n}}</span></label>
          <div class="p-input-icon-right w-full">
            <i class="pi pi-phone"></i>
            <input type="text" pInputText formControlName="phone1" id="phone1" class="inputfield w-full"
                   [ngClass]="{ 'is-invalid': submitted && f.phone1?.errors?.pattern }"
                   [maxLength]="configSizeInput.TELEPHONE_CONTACT"/>
          </div>

          <div *ngIf="submitted" class="pt-2">
            <small *ngIf="f.phone1?.errors?.pattern" class="p-error block">{{'MESSAGE.ERROR.REGEX_PATTERN' | translateI18n}}</small>
          </div>

        </div>

        <div class="field col">
          <label for="phone2">{{'DOSSIER.DEMANDE.CREATION.PHONE' | translateI18n : '2'}}
            <span> {{'DOSSIER.DEMANDE.CREATION.OPTIONAL' | translateI18n}}</span></label>
          <div class="p-input-icon-right w-full">
            <i class="pi pi-phone"></i>
            <input type="text" pInputText formControlName="phone2" id="phone2" class="inputfield w-full"
                   [ngClass]="{ 'is-invalid': submitted && f.phone2?.errors?.pattern }"
                   [maxLength]="configSizeInput.TELEPHONE_CONTACT"/>
          </div>
          <small *ngIf="submitted && f.phone2?.errors?.pattern" class="p-error block">{{'MESSAGE.ERROR.REGEX_PATTERN' | translateI18n}}</small>
        </div>
      </div>

      <div class="col-12 grid field-line">

        <div class="field col">
          <label for="email1">{{'DOSSIER.DEMANDE.CREATION.EMAIL' | translateI18n : '1'}}
            <span> {{'DOSSIER.DEMANDE.CREATION.OPTIONAL' | translateI18n}}</span></label>
          <div class="p-input-icon-right w-full">
            <i class="pi pi-envelope"></i>
            <input type="email" pInputText formControlName="email1" id="email1" class="inputfield w-full"
                   [ngClass]="{ 'is-invalid': submitted && f.email1.errors?.email}"
                   [maxLength]="configSizeInput.MAIL_CONTACT"/>
          </div>

          <div *ngIf="submitted" class="pt-2">
            <small *ngIf="f.email1.errors?.email"  class="p-error block">{{'MESSAGE.ERROR.EMAIL_INVALID' | translateI18n : '1'}}</small>
          </div>


        </div>

        <div class="field col">
          <label for="email2">{{'DOSSIER.DEMANDE.CREATION.EMAIL' | translateI18n : '2'}}
            <span> {{'DOSSIER.DEMANDE.CREATION.OPTIONAL' | translateI18n}}</span></label>
          <div class="p-input-icon-right w-full">
            <i class="pi pi-envelope"></i>
            <input type="text" pInputText formControlName="email2" id="email2" class="inputfield w-full"
                   [maxLength]="configSizeInput.MAIL_CONTACT"/>
          </div>
          <div *ngIf="f.email2.touched || submitted" class="pt-2">
            <small *ngIf="f.email2.errors?.email"  class="p-error block">{{'MESSAGE.ERROR.EMAIL_INVALID' | translateI18n : '2'}}</small>
            <small *ngIf="f.phone2?.errors?.pattern" class="p-error block">{{'MESSAGE.ERROR.REGEX_PATTERN' | translateI18n}}</small>
          </div>
        </div>
      </div>

      <div class="col field field-line" formGroupName="adresse">
        <label  class="obligatoire">
          {{'DOSSIER.DEMANDE.CREATION.ADRESSE' | translateI18n}}</label>
        <div class="flex flex-row" >
          <div style="background-color: #efefef;display: flex;align-items: center;" *ngIf="form.get('adresse.adresseFormate').value !== null">
            <span class="p-3">{{form.get('adresse.adresseFormate').value}}</span>
          </div>

          <button type="button"  pButton pRipple  *ngIf="form.get('adresse.adresseFormate').value === null"
                  label="{{'BUTTON_LABEL.ADD' | translateI18n}}"
                  (click)="addAdress()"></button>

          <button type="button"  pButton pRipple  *ngIf="form.get('adresse.adresseFormate').value !== null"
                  label="{{'BUTTON_LABEL.MODIFY' | translateI18n}}"
                  (click)="addAdress()"></button>

        </div>

        <div *ngIf="submitted" class="pt-2">
          <small *ngIf="form.get('adresse.adresseFormate').value === null" class="p-error block">{{'MESSAGE.ERROR.CHAMPS_OBLIGATOIRE' | translateI18n}}</small>
        </div>

      </div>

    </div>

  </fieldset>

</form>
