import {Component, OnInit} from '@angular/core';
import {AbstractControl, FormBuilder, FormGroup, Validators} from "@angular/forms";
import {Message, MessageService} from "primeng/api";
import {AuthService} from "../../../core/service/auth/auth.service";
import {TranslateService} from "../../../shared/translate/translate.service";

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgotten-password.component.html',
  styleUrls: ['./forgotten-password.component.scss']
})
export class ForgottenPasswordComponent implements OnInit {


  submitted = false;

  forgottenPasswordForm: FormGroup;

  errorMessage: Message[];
  infoMessage : Message[];

  message : string[];

  constructor(
    private authService : AuthService, private messageService: MessageService,
    private fb: FormBuilder, private translate : TranslateService
  ) {}

  ngOnInit(): void {
    this.forgottenPasswordForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]]
    });

  }

  get f(): { [key: string]: AbstractControl } {
    return this.forgottenPasswordForm.controls;
  }

  submit( ){

    this.message = [];
    this.errorMessage = null;

    if (this.forgottenPasswordForm.invalid) {
      this.errorMessage = [ {severity:'error', summary: this.translate.getElement("FORGGOTTEN_PASWORD.FORM.MESSAGE.ERROR.IVALID_FORMAT_EMAIL")}];
      return;
    }

    this.submitted = true;

    this.messageService.clear();

    let msg = this.translate.getElement("FORGGOTTEN_PASWORD.FORM.MESSAGE.SUCCESS.SUCCESS_SEND_EMAIL");
    this.message = msg.split('<br/>');

    this.authService.forgotPassWord(this.forgottenPasswordForm.value.email).subscribe();

    this.forgottenPasswordForm.reset();
  }

}
