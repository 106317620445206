import {Injectable} from '@angular/core';
import {RestDatasource} from "../rest.datasource";
import {HttpClient} from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private datasource : RestDatasource, private http : HttpClient) { }

  getAllUserByProfiles(profiles : any[]) {
    return this.datasource.getAllUserByProfiles(profiles);
  }

  getAllUsersByDepartementCode(code : string) {
    return this.datasource.getAllUsersByDepartementCode(code);
  }

  getAllPreteur() {
    return this.datasource.getAllPreteur();
  }

  getAllTiersExpertise() {
    return this.datasource. getAllTiersExpertise();
  }

  getUserConnectedProfilesById() {
      return this.datasource.getUserConnectedProfilesById();
  }

  getUserConnectedByToken() {
    return this.datasource.getUserConnectedByToken();
  }
  getAgenceUserConnected() {
    return this.datasource.getAgenceUserConnected();
  }

}
