import {Pipe, PipeTransform} from '@angular/core';

@Pipe({ name: 'decimal' })

export class DecimalPipe implements PipeTransform {
  transform(value: number): string {
    if (!value)
      return null;

    const decimalPart = value % 1;
    const integerPart = Math.floor(value);
    const decimalString = decimalPart.toFixed(2).split('.')[1];
    const integerString = integerPart.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
    return `${integerString},${decimalString}`;
  }
}
