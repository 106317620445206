import {AfterContentChecked, ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {AvisValeurService} from "../../../service/avis-valeur.service";
import {AbstractControl, FormBuilder, FormGroup, FormGroupDirective, Validators} from "@angular/forms";
import {DialogService, DynamicDialogRef} from "primeng/dynamicdialog";
import {TranslateService} from "../../../../shared/translate/translate.service";
import {Message} from "primeng/api";
import {ConfigSizeInput} from "../../../../shared/providers/config-size-input";
import {Validation} from "../../../../shared/providers/validation";

@Component({
  selector: 'app-designation',
  templateUrl: './designation.component.html',
  styleUrls: ['./designation.component.scss']
})
export class DesignationComponent implements OnInit, AfterContentChecked {

  paramTypeRegimes : any[];
  paramTypeBiens : any[];

  @Input()
  formGroupName: string;

  form: FormGroup;

  commentaireRegimej : boolean;

  nbCaractNumeroLots = 0;

  nbRefCadastrales = 0;

  nbCommentTypeRegimej = 0;

  @Input()
  submitted : boolean;

  @Input()
  submittedRequired : boolean;

  msgError : Message[];
  msgError96 : Message[];

  configSizeInput : ConfigSizeInput;

  constructor(private avisValeurService : AvisValeurService,
              private fb : FormBuilder,
              public dialogService: DialogService,
              public ref: DynamicDialogRef,
              private rootFormGroup: FormGroupDirective,
              private translate : TranslateService,
              private cdref: ChangeDetectorRef) {
    this.configSizeInput = new ConfigSizeInput();
  }

  ngOnInit(): void {
    this.form = this.rootFormGroup.control.get(this.formGroupName) as FormGroup;
    this.getAllParamTypeRegimej();
    this.getAllTypeBiens();
    this.msgError =[ {severity:'error', summary:  this.translate.getElement("AVIS_VALEUR.MESSAGE.ERROR_CARACTERE_COMMENTAIRE", '100')}];
    this.commentaireRegimej = this.form.value?.regimeJuridique?.idTypeRegimej == 4;
    this.msgError96 =[ {severity:'error', summary:  this.translate.getElement("AVIS_VALEUR.MESSAGE.ERROR_CARACTERE_COMMENTAIRE", '96')}];
    this.nbCommentTypeRegimej = this.form?.value?.commentTypeRegimej ? this.form?.value?.commentTypeRegimej?.replace(/<[^>]*>/g, '')?.length : 0;
    this.nbCaractNumeroLots = this.form?.value?.numeroLots ? this.form?.value?.numeroLots?.replace(/<[^>]*>/g, '')?.length : 0;
    this.nbRefCadastrales = this.form?.value?.refCadastrales ? this.form?.value?.refCadastrales?.replace(/<[^>]*>/g, '')?.length : 0;
  }

  getAllParamTypeRegimej()  {
    this.avisValeurService.getAllParamTypeRegimej().subscribe(
      data => {
        this.paramTypeRegimes = data;
      },error => {

      }
    )
  }

  getAllTypeBiens()  {
    this.avisValeurService.getAllTypeBiens().subscribe(
      data => {
        this.paramTypeBiens = data;
      },error => {

      }
    )
  }

  ngAfterContentChecked(): void {
    this.cdref.detectChanges();
  }

  changeSelectionRegimej(event: any) {
    let regimej = event?.value;

    if(regimej?.idTypeRegimej === 4) {
      this.form.controls['commentTypeRegimej'].setValidators([
        Validators.required,
        Validation.patternValidatorEmptyCaracter({hasEmptyCaracterError: true}),
        Validation.patternValidatorMaxLengthCaracter(96, {hasError: true})
      ]);
    }else {
      this.form.controls['commentTypeRegimej'].setErrors(null);
    }

    this.commentaireRegimej = regimej?.idTypeRegimej == 4;
  }

  get f(): { [key: string]: AbstractControl } {
    return this.form.controls;
  }

  caractNumeroLotsTextChange(event: any) {
    this.nbCaractNumeroLots = event?.htmlValue ? event?.htmlValue?.replace(/<[^>]*>/g, '')?.trim()?.length : 0;
  }

  refCadastralesTextChange(event: any) {
    this.nbRefCadastrales = event?.htmlValue ? event?.htmlValue?.replace(/<[^>]*>/g, '')?.trim()?.length : 0;
  }

  caractCommentTypeRegimejTextChange(event: any) {
    this.nbCommentTypeRegimej = event?.htmlValue ? event?.htmlValue?.replace(/<[^>]*>/g, '')?.trim()?.length : 0;
  }
}
