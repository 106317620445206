<form [formGroup]="form" >
  <div class="card-large" id="section1">
    <h1 id="description">IV - {{'AVIS_VALEUR.DESCRIPTION' | translateI18n}}</h1>


    <div class="grid">

      <div class="field col-12 field-line">
        <div class="mb-2">
          <h3 class="m-0">{{'AVIS_VALEUR.LABEL.TERRAIN' | translateI18n}} :</h3>
          <small>{{'AVIS_VALEUR.MESSAGE.MAX_CARACTERE' | translateI18n : '200'}}
            <span [ngClass]="{'is-alert':  f.terrain.hasError('hasError')}">({{nbTerrainText}} / 200)</span>
            </small>
        </div>

        <div *ngIf="submitted && f.terrain.hasError('hasError')">
          <p-messages  [(value)]="msgError200"></p-messages>
        </div>
        <p-editor formControlName="terrain" [style]="{'height':'100px'}" name="terrain"  id="terrain" class="w-full"
                (onTextChange)="terrainTextChange($event)" [ngClass]="{'editor-error': f.terrain.hasError('hasError')}">
          <p-header>
            <app-p-editor-header></app-p-editor-header>
          </p-header>
        </p-editor>

      </div>

      <div class="field col-12">
          <h3 class="m-0">{{'AVIS_VALEUR.LABEL.CONSTRUCTION' | translateI18n}} :</h3>
      </div>

      <div class="col">
        <section>

          <div class="field field-line input-ghost">
            <label for="dateConstruction" class="label">{{'AVIS_VALEUR.LABEL.DATE_CONSTRUCTION' | translateI18n}} :</label>
            <input type="text" pInputText id="dateConstruction" class="inputfield" formControlName="dateConstruction"
                   [ngClass]="{'ng-dirty ng-invalid': f.dateConstruction.hasError('hasError')} "
                   placeholder="{{'AVIS_VALEUR.LABEL.RENSEIGNER' | translateI18n}}"/>

            <small *ngIf="f.dateConstruction?.errors?.pattern" class="p-error block">
              {{'MESSAGE.ERROR.REGEX_PATTERN' | translateI18n}}</small>

            <small *ngIf="f.dateConstruction.hasError('hasError')" class="p-error block">
              {{'AVIS_VALEUR.MESSAGE.ERROR_CARACTERE_INPUT' | translateI18n : '32'}}</small>
          </div>

          <div class="field field-line input-ghost">
            <label for="typeSurface" class="label">{{'AVIS_VALEUR.LABEL.TYPE_SURFACE' | translateI18n}} :</label>
            <p-dropdown  [options]="typeSurfaces"  formControlName="typeSurfaces" optionLabel="label" name="typeSurface"  id="typeSurface"
                         emptyMessage="{{'DOSSIER.MESSAGE.AUCUN_RESULTAT' | translateI18n}}"
                         class="obligatoire"
                         [ngClass]="{'ng-dirty ng-invalid': submittedRequired && f.typeSurfaces.errors}"
                         placeholder="{{'PLACEHOLDER.CHOOSE' | translateI18n}}" (onChange)="typeSurface($event.value)"></p-dropdown>
          </div>
        </section>
      </div>


      <div class="col">
        <section>

          <div class="field field-line input-ghost">
            <label for="nbNiveaux" class="label">{{'AVIS_VALEUR.LABEL.NOMBRE_NIVEAU' | translateI18n}} :</label>
            <input type="text" pInputText id="nbNiveaux" class="inputfield" formControlName="nbNiveau"
                   [ngClass]="{'ng-dirty ng-invalid': f.nbNiveau.hasError('hasError')} "
                   placeholder="{{'AVIS_VALEUR.LABEL.RENSEIGNER' | translateI18n}}"/>

            <small *ngIf="submitted &&  f.nbNiveau?.errors?.pattern" class="p-error block">
              {{'MESSAGE.ERROR.REGEX_PATTERN' | translateI18n}}</small>

            <small *ngIf="submitted &&  f.nbNiveau?.hasError('hasError')" class="p-error block">
              {{'AVIS_VALEUR.MESSAGE.ERROR_CARACTERE_INPUT' | translateI18n : '20'}}</small>
          </div>


          <div class="field field-line input-ghost">
            <label for="surface" class="label obligatoire">{{'AVIS_VALEUR.LABEL.SURFACE' | translateI18n}} ({{surface[0]?.name}}):</label>


            <p-inputNumber formControlName="surface"
                           id="surface"
                           placeholder="{{'AVIS_VALEUR.LABEL.RENSEIGNER' | translateI18n}}"
                           [ngClass]="{'ng-invalid ng-dirty': submittedRequired && f.surface.errors}"
                           (onInput)="getSurfaceValue($event)"
                           suffix=" m²"
                           [size]=20
                           [min]="0"
                           [maxlength]="18"
                           [minFractionDigits]="2"
                           [maxFractionDigits]="5"></p-inputNumber>

            <small *ngIf="errorSurfaceValue" class="p-error block">{{'AVIS_VALEUR.MESSAGE.ERROR_SURFACE' | translateI18n}}</small>
          </div>

        </section>
      </div>





      <div class="field col-12 field-line">

        <div class="mb-2">
         <!-- <h3 class="m-0">{{'AVIS_VALEUR.LABEL.CONSTRUCTION' | translateI18n}} :</h3>-->
          <small>{{'AVIS_VALEUR.MESSAGE.MAX_CARACTERE' | translateI18n : '200'}}
            <span [ngClass]="{'is-alert':  f.construction.hasError('hasError')}">({{nbConstructionText}} / 200)</span>
          </small>
        </div>

        <div *ngIf="submitted && f.construction.hasError('hasError')">
          <p-messages  [(value)]="msgError200"></p-messages>
        </div>

        <p-editor formControlName="construction" [style]="{'height':'100px'}" name="construction"  id="construction" class="w-full"
                  [ngClass]="{'editor-error': f.construction.hasError('hasError')  }"
                  (onTextChange)="constructionTextChange($event)">
          <p-header>
            <app-p-editor-header></app-p-editor-header>
          </p-header>
        </p-editor>
      </div>

      <div class="field col-12 field-line">

        <div class="mb-2">
          <h3 class="m-0">{{'AVIS_VALEUR.LABEL.DISTRIBUTION' | translateI18n}} :</h3>
          <small>{{'AVIS_VALEUR.MESSAGE.MAX_CARACTERE' | translateI18n : '300'}}
            <span [ngClass]="{'is-alert':  f.distribution.hasError('hasError')}">({{nbDistributionText}} / 300)</span>
          </small>
        </div>

        <div *ngIf="submitted && f.distribution.hasError('hasError')">
          <p-messages  [(value)]="msgError300"></p-messages>
        </div>
        <p-editor formControlName="distribution" [style]="{'height':'100px'}" name="distribution"  id="distribution" class="w-full"
                  [ngClass]="{'editor-error': f.distribution.hasError('hasError')  }"
                  (onTextChange)="distributionTextChange($event)">
          <p-header>
            <app-p-editor-header></app-p-editor-header>
          </p-header>
        </p-editor>

      </div>

      <div class="field col-12 field-line">

        <div class="mb-2">
          <h3 class="m-0">{{'AVIS_VALEUR.LABEL.AMENAGEMENT_INTERRIEUR' | translateI18n}} :</h3>
          <small>{{'AVIS_VALEUR.MESSAGE.MAX_CARACTERE' | translateI18n : '400'}}
            <span [ngClass]="{'is-alert':  f.amenagement.hasError('hasError')}">({{nbAmenagementText}} / 400)</span>
          </small>
        </div>

        <div *ngIf="submitted && f.amenagement.hasError('hasError')">
          <p-messages  [(value)]="msgError400"></p-messages>
        </div>
        <p-editor formControlName="amenagement" [style]="{'height':'100px'}" name="amenagement"  id="amenagement" class="w-full"
                  [ngClass]="{'editor-error':  f.amenagement.hasError('hasError')  }"
                  (onTextChange)="amenagementTextChange($event)">
          <p-header>
            <app-p-editor-header></app-p-editor-header>
          </p-header>
        </p-editor>

      </div>

      <div class="field col-12 field-line">


        <div class="mb-2">
          <h3 class="m-0">{{'AVIS_VALEUR.LABEL.ETAT_GENERAL' | translateI18n}} :</h3>
          <small>
            {{'AVIS_VALEUR.MESSAGE.MAX_CARACTERE' | translateI18n : '200'}}
            <span [ngClass]="{'is-alert':  f.etatgeneral.hasError('hasError')}">({{nbEtatGeneralText}} / 200)</span>
          </small>
        </div>

        <div *ngIf="submitted && f.etatgeneral.hasError('hasError')">
          <p-messages  [(value)]="msgError200"></p-messages>
        </div>
        <p-editor formControlName="etatgeneral" [style]="{'height':'100px'}" name="etatgeneral"  id="etatgeneral" class="w-full"
                  [ngClass]="{'editor-error':  f.etatgeneral.hasError('hasError')  }"
                  (onTextChange)="etatGeneralTextChange($event)">
          <p-header>
            <app-p-editor-header></app-p-editor-header>
          </p-header>
        </p-editor>
      </div>

      <div class="field col-12 field-line">

        <div class="mb-2">
          <h3 class="m-0" [ngClass]="{'obligatoire': obseravationRequired()}" >
            {{'AVIS_VALEUR.LABEL.OBSERVATION' | translateI18n}} :</h3>
          <small>{{'AVIS_VALEUR.MESSAGE.MAX_CARACTERE' | translateI18n : '200'}}
            <span [ngClass]="{'is-alert': f.observation.hasError('hasError')}">({{nbObservationoText}} / 200)</span>
          </small>
        </div>
        <div *ngIf="f.observation.hasError('hasError')">
          <p-messages  [(value)]="msgError200"></p-messages>
        </div>
        <p-editor formControlName="observation" [style]="{'height':'100px'}" name="observation"  id="observation" class="w-full"
                  [ngClass]="{'editor-error': (submittedRequired && (f.observation?.errors?.required || f.observation?.hasError('hasEmptyCaracterError')))
                  || f.observation?.hasError('hasError')}"
                  (onTextChange)="observationTextChange($event)">
          <p-header>
            <app-p-editor-header></app-p-editor-header>
          </p-header>
        </p-editor>

      </div>

    </div>

  </div>
</form>


