import {Component, Input, OnInit} from '@angular/core';
import {GoogleMapServiceService} from "../../core/service/googleMaps/google-map-service.service";
import {DossierService} from "../../core/service/dossier.service";
import {LocalStorageService} from "../../core/service/local-storage/local-storage.service";
import {TranslateService} from "../translate/translate.service";
import {Dossier} from "../../core/models/dossiers/dossier";
import {Adresse, Pays} from "../../core/models/adresse";
import {Subscription} from "rxjs";
import {Message} from "primeng/api";
import {AbstractControl, FormBuilder, FormControl, FormGroup, FormGroupDirective, Validators} from "@angular/forms";
import {Validation} from "../providers/validation";
import {DynamicDialogConfig, DynamicDialogRef} from "primeng/dynamicdialog";
import {ConfigSizeInput} from "../providers/config-size-input";

@Component({
  selector: 'app-adresse',
  templateUrl: './adresse.component.html',
  styleUrls: ['./adresse.component.scss']
})
export class AdresseComponent implements OnInit {

  display: boolean = false;

  @Input()
  dossier : Dossier;

  @Input()
  formGroupName: string;

  form: FormGroup;

  formattedAddress : string;

  pays : Pays[];

  adresseFormate : Adresse;

  @Input()
  submitted : boolean = false;

  protected placeSubscription: Subscription;

  errorMessage : Message[]

  messageGoogle: Message[];

  adresse : Adresse;

  frErrorCP : boolean = false;

  configSizeInput : ConfigSizeInput;

  displayMapGoogle : boolean = true;

  constructor(
    private googleMapService : GoogleMapServiceService,
    private dossierService : DossierService,
    private userLocalStorage : LocalStorageService,
    public translate: TranslateService,
    private rootFormGroup: FormGroupDirective,
    public config: DynamicDialogConfig,
    private fb : FormBuilder,
    public ref: DynamicDialogRef
  ) {
    this.getAllPays();
    this.configSizeInput = new ConfigSizeInput();

    if(typeof google === 'undefined') {
      this.displayMapGoogle = false;
      this.messageGoogle = [ {severity:'warn',
        summary: this.translate.getElement('DOSSIER.ADRESSE_FORM.INDISPO_GOOGLE') }]
    }
  }

  ngOnInit(): void {

    if(this.dossier) {
      this.adresseFormate = this.dossier.adresse;
      this.form = this.rootFormGroup.control.get(this.formGroupName) as FormGroup;
    }
    else{

      const adresse = this.config.data.adresse;

      this.form = new FormGroup({
        adresseFormate : new FormControl(!adresse || adresse.adresseFormate === null ? null : adresse.adresseFormate,
          Validators.maxLength(this.configSizeInput.ADRESSE_FORMATE)),
        numero : new FormControl(!adresse || adresse.numero === null ? null : adresse.numero,
          [Validators.maxLength(this.configSizeInput.NUMERO_ADRESSE), Validators.pattern(this.configSizeInput.regexPattern2)]),
        lieuDit : new FormControl(!adresse || adresse.lieuDit === null ? null : adresse.lieuDit,
          [Validators.maxLength(this.configSizeInput.LIEU_DIT), Validators.pattern(this.configSizeInput.regexPattern2)]),
        nomVoie : new FormControl(!adresse || adresse.nomVoie === null ? null : adresse.nomVoie,
          [Validators.required, Validators.maxLength(this.configSizeInput.NOM_VOIE), Validators.pattern(this.configSizeInput.regexPattern2)]),
        codePostal : new FormControl(!adresse || adresse.codePostal === null ? null : adresse.codePostal,
          [Validators.required, Validators.maxLength(this.configSizeInput.CODE_POSTALE), Validators.pattern(this.configSizeInput.regexPattern2)]),
        ville : new FormControl(!adresse || adresse.ville === null ? null : adresse.ville,
          [Validators.required, Validators.maxLength(this.configSizeInput.VILLE), Validators.pattern(this.configSizeInput.regexPattern2)]),
        pays : new FormControl(this.pays && adresse && adresse.pays !== null ? this.pays.find(p => p.id === adresse?.pays?.id) : null,
          Validators.required),
        boitePostale: new FormControl(!adresse || adresse.boitePostale === null ? null : adresse.boitePostale,
          [Validators.maxLength(this.configSizeInput.BOITE_POSTALE), Validators.pattern(this.configSizeInput.regexPattern2)]),
        complement : new FormControl(!adresse || adresse.complement === null ? null : adresse.complement,
          [Validators.maxLength(this.configSizeInput.COMPLEMENT_ADRESSE), Validators.pattern(this.configSizeInput.regexPattern2)]),
        lat : new FormControl(adresse ? adresse.lat : null),
        lng : new FormControl(adresse ? adresse.lng : null),
        adresseChange : new FormControl(adresse ? adresse.adresseChange : null)
      });

      if(adresse) {
        this.adresseFormate = adresse;
      }

    }
  }

  get f(): { [key: string]: AbstractControl } {
    return this.form.controls;
  }

  /**
   * Recupère la liste de pays en fonction du critère (adresse du bien ou autres)
   */
   getAllPays() {
    let isbienAdress = this.config.data?.contry !== 'allContry';

    this.dossierService.getAllPays(isbienAdress).subscribe(data => {
      this.pays = data.sort((a, b) => a.libelle.toLowerCase().localeCompare(b.libelle.toLowerCase()));
      if(data && this.config.data?.adresse?.pays?.id) {
        this.form.get('pays').setValue(this.pays.find(p => p.id === this.config.data?.adresse?.pays?.id))
      }else {
        this.form.get('pays').setValue(this.pays.find(p => p.codeIso3.toLowerCase() === 'FRA'.toLowerCase()))
      }
    });

  }

  /**
   * Remplir automatiquement les champs adresses(numero, code postal, pays, departement)
   */
  onAddressChange(): void {
    this.placeSubscription = this.googleMapService.placeObservable.subscribe(
      (place) => {

        this.form.get('adresseFormate').setValue(place.formatted_address);
        this.form.get('numero').setValue(this.googleMapService.getStreetNumber(place));
        this.form.get('nomVoie').setValue( this.googleMapService.getStreet(place));
        this.form.get('ville').setValue( this.googleMapService.getCity(place));
        this.form.get('codePostal').setValue( this.googleMapService.getPostCode(place));

        this.form.get('pays').setValue(this.pays.find(dept => dept.libelle === this.googleMapService.getCountry(place)));

        this.validationCPIfContryFR(this.form.get('pays').value.codeIso2);

        this.form.get('lat').setValue(this.googleMapService.getCodeLat(place));
        this.form.get('lng').setValue(this.googleMapService.getCodeLng(place));
      }
    );
  }

  /**
   * Verification pour la validation code postal français
   * @param codeIso2
   */
  validationCPIfContryFR(codeIso2:  string) {
    this.frErrorCP = codeIso2 === 'FR' && !Validation.regexPostalCodeFR(this.form.get('codePostal').value);
  }

  verificationPostalCode() {
    this.validationCPIfContryFR(this.form.get('pays').value?.codeIso2);
  }

  close(message? : any) {
    this.ref.close(message);
  }

  /**
   * Utilisation popup
   * Ajout et envoi de l'adresse au composant parent
   */
  /*addAdresse() {
    this.submitted = true;

    if(this.form.invalid || !this.form.value?.codePostal
      || !this.form.value?.nomVoie || !this.form.value?.ville || !this.form.value?.pays) {
      this.errorMessage = [ {severity:'error', summary: this.translate.getElement('MESSAGE.ERROR.MS_CHAMPS_OBLIGATOIRE') }];
      return;
    }

    if(this.form.value.pays.codeIso2 === 'FR' && !Validation.regexPostalCodeFR(this.form.value.codePostal)) {
      this.errorMessage = [{
        severity:'error',
        summary: this.translate.getElement('DOSSIER.MESSAGE.ERROR.CODE_POSTAL_FR')
      }];
      return;
    }

    let adresse = this.form.value;
    let adress = this.config.data.adresse;
    if(adresse && adress && (adresse?.numero !== adress?.numero || adresse?.nomVoie !== adress?.nomVoie
      || adresse?.codePostal !== adress?.codePostal || adresse?.ville !== adress?.ville
      || adresse?.pays?.id !== adress?.pays?.id)
    ) {

      if(!this.form.value.adresseFormate) {

        let adresseForm = this.addresseFormat(adresse);

        this.form.get('adresseFormate').setValue(adresseForm);
        this.form.get('lat').setValue(null);
        this.form.get('lng').setValue(null);
      }
      adress.adresseChange = true;
    }
    this.form.get('adresseChange').setValue(true);

    if (this.form.value?.numero === undefined) {
      this.form.value.numero = null;
    }
      this.form.get('adresseChange').setValue(true);
    }else if(!adress){
      if(!this.form.value.adresseFormate) {

        let adresseForm = this.addresseFormat(adresse);

        this.form.get('adresseFormate').setValue(adresseForm);
        this.form.get('lat').setValue(null);
        this.form.get('lng').setValue(null);
      }
      this.form.get('adresseChange').setValue(true);
    }

    this.close(this.form.value);
  }*/





  addAdresse() {
    this.submitted = true;

    const adresse = this.form.value;
    const existingAddress = this.config.data.adresse;

    if (!existingAddress || AdresseComponent.isAddressChanged(adresse, existingAddress)) {
      this.updateAddress(adresse);
    }
    if (this.isFormInvalid()) {
      this.errorMessage = [{ severity: 'error', summary: this.translate.getElement('MESSAGE.ERROR.MS_CHAMPS_OBLIGATOIRE') }];
      return;
    }

    if (this.form.value.pays.codeIso2 === 'FR' && !Validation.regexPostalCodeFR(this.form.value.codePostal)) {
      this.errorMessage = [{ severity: 'error', summary: this.translate.getElement('DOSSIER.MESSAGE.ERROR.CODE_POSTAL_FR') }];
      return;
    }

    this.close(this.form.value);
  }

  private isFormInvalid(): boolean {
    const formValues = this.form.value;
    return this.form.invalid || !formValues?.codePostal || !formValues?.nomVoie || !formValues?.ville || !formValues?.pays;
  }

  private static isAddressChanged(newAddress: any, existingAddress: any): boolean {
    return (
      newAddress.numero !== existingAddress?.numero ||
      newAddress.nomVoie !== existingAddress?.nomVoie ||
      newAddress.codePostal !== existingAddress?.codePostal ||
      newAddress.ville !== existingAddress?.ville ||
      newAddress.pays?.id !== existingAddress?.pays?.id
    );
  }

  private updateAddress(adresse: any): void {
    if (!this.form.value.adresseFormate) {
      const adresseForm = this.addresseFormat(adresse);

      this.form.get('adresseFormate').setValue(adresseForm);
      this.form.get('lat').setValue(null);
      this.form.get('lng').setValue(null);
    }

    this.form.get('adresseChange').setValue(true);
  }


  changeValueInput(event? : any) {
    this.adresseFormate = this.form?.value;
  }

  addresseFormat(adresse : Adresse) {
    let adresseForm = '';
    if(adresse?.numero) {
      adresseForm = adresse?.numero + ' '
    }
    if(adresse?.nomVoie) {
      adresseForm = adresseForm  + adresse?.nomVoie + ', ';
    }
    if(adresse?.codePostal) {
      adresseForm = adresseForm + ' ' + adresse?.codePostal + ' ';
    }
    if(adresse?.ville) {
      adresseForm = adresseForm + ' ' + adresse?.ville;
    }
    if(adresse?.pays) {
      adresseForm =', ' +adresseForm + adresse?.pays?.libelle;
    }

    return adresseForm;
  }

}
