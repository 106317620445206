import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {HttpClient, HttpHeaders, HttpParams} from "@angular/common/http";
import {LocalStorageService} from "./local-storage/local-storage.service";
import {LoginResponse} from "../models/user/login-response";
import {LoginRequest} from "../models/user/login-request";
import {ChangePasswordRequest} from "../models/user/change-password-request";
import {Dossier} from "../models/dossiers/dossier";
import {ResponsePagination} from "../models/dossiers/response-pagination";
import {User} from "../models/user/user.model";
import {UploadRequest} from "../models/document/upload-request";
import {Document} from "../models/document/document.model";
import {Departement, Pays} from "../models/adresse";
import {ReferentielValeur} from "../models/referentiel-valeur";
import {ItemCriteriaResarchDossier} from "../models/dossiers/item-criteria-resarch-dossier";
import {ParamTypeBien} from "../models/bien/param-type-bien";
import {Civilite} from "../models/param/civilite";
import {CreationDemande} from "../models/dossiers/creation-demande";
import {Tiers} from "../models/tiers/tiers";
import {ChangementRapide} from "../models/dossiers/changement-rapide";
import {ParamQualiteContact} from "../models/param/param-qualite-contact";
import {OAuthService} from "angular-oauth2-oidc";
import {ProfilesUser} from "../models/user/profiles-user";
import {CategorieDocument} from "../models/document/categorie-document";
import {ModificationDocument} from "../models/document/modification-document";
import {Signataire} from "../models/user/signataire";
import { runtimeEnvironment } from "src/environments/runtime-environment";

@Injectable()
export class RestDatasource {

    httpOptions : any ;

    baseUrl = runtimeEnvironment.backEndPoint;

    constructor(
      private http: HttpClient,
      private tokenStorageService: LocalStorageService,
      private authService : OAuthService
    ) {}

    private getOptionsBlob(params?: any) {
      if(this.tokenStorageService.getToken() !== null) {
        return  {
          headers: new HttpHeaders({
            Authorization: 'Bearer ' + this.tokenStorageService.getToken(),
          }),
          responseType: 'blob' as 'json',
          params: params
        };
      }
      return null ;
    }

    logout() {
      return this.http.get(this.baseUrl + "api/auth/logout");
    }

    ask2fa(login:string, password:string): Observable<any> {
        return this.http.post<any>(this.baseUrl + "api/auth/ask2fa", {
            login:login, password:password
        });
    }

    authenticate(login:string, password:string, code2fa?:string): Observable<LoginResponse> {
        return this.http.post<any>(this.baseUrl + "api/auth/signin", {
            login:login, password:password, code2FA:code2fa
        });
    }

    authenticateWebsso(): Observable<LoginResponse> {
      return this.http.get<LoginResponse>(this.baseUrl + "api/auth/interne");
    }

    reinitPassword(login:string, newPassword:string, code2fa:string): Observable<any> {
        return this.http.post<any>(this.baseUrl + "api/auth/reinit-password", {
            login:{login:login, code2FA:code2fa}, newPassword:newPassword
        });
    }

    changePassword(password:string, newPassword:string): Observable<any> {
        return this.http.post<any>(this.baseUrl + "password/change", {
            login:{password:password}, newPassword:newPassword
        });
    }
    getMessage() {
        return this.http.get<any>(this.baseUrl + "v1/message");
    }

    forgotPassWord( loginRequest : LoginRequest): Observable<any> {
      let param = new HttpParams().append('isIntranet', runtimeEnvironment.isInterne);
      return this.http.post<any>(this.baseUrl + "api/auth/forgot-password", loginRequest, {params : param});
    }

    changeForgotPassword(passwordRequest : ChangePasswordRequest) : Observable<LoginResponse> {
      return this.http.post<LoginResponse>(this.baseUrl + "forgot/password/change", passwordRequest);
    }

    verifyIfLinkChangePasswordIsValid(loginRequest : LoginRequest) : Observable<any> {
      return this.http.post<any>(this.baseUrl + "forgot/password/link-validity", loginRequest);
    }

    getListDossierAaffecterBis(paginationObject : any) : Observable<ResponsePagination> {
      return this.http.post<ResponsePagination>(this.baseUrl + "api/dossier/list/statut/aaffecter", paginationObject);
    }

    getAllUserByProfiles(profiles : any[]) : Observable<any[]> {
      return this.http.post<any[]>(this.baseUrl + "api/user/filter/profiles", profiles);
    }

    uploadDocument(files: File[], uploadRequest: UploadRequest) : Observable<any> {
        const formData: FormData = new FormData();
        let idDossier = null;
        for(let i = 0; i < files.length; i++) {
          formData.append('file'+(i+1), files[i], files[i].name);
        }
        formData.append('objetPrincipal', uploadRequest.objetPrincipal);
        formData.append('idObjetPrincipal', uploadRequest.idObjetPrincipal.toString());
        if( uploadRequest.idDossier) {
          idDossier = uploadRequest.idDossier.toString();
        }
        formData.append('idDossier', idDossier);

        return this.http.post<any>(this.baseUrl + "document/upload", formData);
    }

    getDocumentsDossier(idBien: number, allPictures : boolean) : Observable<Document[]> {
        return this.http.get<Document[]>(this.baseUrl + "documents/dossier/"+idBien + "/" + allPictures);
    }

    getDocumentsNonLus() : Observable<Document[]> {
      return this.http.get<Document[]>(this.baseUrl + "documents/nonlus/utilisateur");
    }

    downloadDocument(uidDocument: string, idDocumentLecture: number) : Observable<any> {
      return this.http.get(this.baseUrl + "document/"+uidDocument, this.getOptionsBlob(idDocumentLecture == null ? null : {nonlus:idDocumentLecture}));
    }

    deleteResource(uidResource: string, idDossier : number, url: string) : Observable<any> {
      let param = new HttpParams().append('idDossier', idDossier);
      return this.http.delete<any>(this.baseUrl+url+"/"+uidResource, {params : param});
    }

    affectationDossier(idDossiers : number[], idUserExpert : number, idUserAssistant : number){
        const url = "api/dossier/affectation/"  + idUserExpert + "/" +idUserAssistant;
        return this.http.post<any>(this.baseUrl + url, idDossiers);
    }

    getParamMessageInformationExpertiseFr() : Observable<Document[]> {
      return this.http.get<Document[]>(this.baseUrl + "param-message-information-expertiseFR");
    }

    getStatsDossier() : Observable<any> {
      return this.http.get<any>(this.baseUrl + "api/dossier/statistique/" );
    }

    getAllDepartement() : Observable<Departement[]> {
      return this.http.get<Departement[]>(this.baseUrl + "api/dossier/all/departements");
    }

    getAllPays(bien : boolean) : Observable<Pays[]> {
      return this.http.get<Pays[]>(this.baseUrl + "api/dossier/all/pays/" + bien);
    }

    updateClientAndAdresseBienAffectation(dossier : Dossier): Observable<Dossier> {
      return this.http.post<Dossier>(this.baseUrl + "api/dossier/update/affectation", dossier);
    }

    getTypeLivrableUser(idDossier : number) : Promise<ReferentielValeur[]> {
      let param = new HttpParams().append('idDossier', idDossier);
      let url = "api/dossier/user/typeLivrable"
      return this.http.get<ReferentielValeur[]>(this.baseUrl + url, {params : param}).toPromise();
    }

    getAllUsersByDepartementCode(code : string) : Observable<User[]> {
      let url = "api/user/expert/departement/" + code
      return this.http.get<User[]>(this.baseUrl + url);
    }

    getDemandeByCriteria(items : ItemCriteriaResarchDossier) : Observable<ResponsePagination> {
      let url = "api/dossier/recherche-avancee";
      return this.http.post<ResponsePagination>(this.baseUrl + url, items);
    }

    getAllPreteur() : Observable<any[]> {
      return this.http.get<any[]>(this.baseUrl + "api/user/preteur");
    }

    getAllParamTypeBien() : Observable<ParamTypeBien[]> {
      return this.http.get<ParamTypeBien[]>(this.baseUrl + "api/bien/types-bien");
    }

    getAllEtageBienImmeubleByAdressPrincipal() : Observable<ReferentielValeur[]> {
      return this.http.get<ReferentielValeur[]>(this.baseUrl + "api/bien/etages");
    }


    findAllCivilite() : Observable<Civilite[]> {
      return this.http.get<Civilite[]>(this.baseUrl + "api/dossier/civilite");
    }



    createDemande(creationdemande : CreationDemande, files: File[]) : Observable<Dossier> {
      const formData: FormData = new FormData();
        for(let i = 0; i < files.length; i++) {
          formData.append('file'+(i+1), files[i], files[i].name);
        }
        formData.append('creationDemande', JSON.stringify(creationdemande));
      return this.http.post<Dossier>(this.baseUrl + "api/dossier/demande/creation", formData);
    }


    updateUserLanguage(langue : string) : Observable<any>{
      return this.http.get<any>(this.baseUrl +"api/user/update-language/" + langue);
    }

    getDosierById(idDossier : number) : Observable<Dossier> {
      return this.http.get<Dossier>(this.baseUrl +"api/dossier/" + idDossier);
    }

    getAllTiersExpertise() : Observable<Tiers[]> {
      return this.http.get<Tiers[]>(this.baseUrl +"api/users/all/tiers");
    }

    repasserAAffecter(dossier : Dossier) : Observable<Dossier> {
      return this.http.put<Dossier>(this.baseUrl +"api/dossier/repasserAAffecter", dossier);
    }

    duplicationDossier(idDossier : number, codeLivrable : string, idAgence : number) : Observable<Dossier> {
      let param = new HttpParams().append('codeLivrable', codeLivrable).append('idAgence', idAgence);
      return this.http.get<Dossier>(this.baseUrl + "api/dossier/duplicationDossier/" + idDossier, {params : param})
    }

    annulationDossier(dossier : Dossier) : Observable<Dossier> {
      return this.http.put<Dossier>(this.baseUrl + "api/dossier/annulationDossier", dossier)
    }

    reactivationDossier(dossier : Dossier) : Observable<Dossier> {
      return this.http.put<Dossier>(this.baseUrl + "api/dossier/reactivationDossier", dossier)
    }

    relancerDossier(idDossier: number) : Observable<boolean> {
      return this.http.get<boolean>(this.baseUrl + "api/dossier/relance/" + idDossier);
    }

    getAllParamPourcentageNegociation() : Observable<any[]> {
      return this.http.get<any[]>(this.baseUrl + "api/dossier/pourcentage/negociation");
    }

    getAllQualiteContact() : Observable<ParamQualiteContact[]> {
      return this.http.get<ParamQualiteContact[]>(this.baseUrl + "api/dossier/all/qualite_contact");
    }

    updateFicheDossier(dossier : Dossier) : Observable<Dossier> {
      return this.http.post<Dossier>(this.baseUrl + "api/dossier/update/fiche", dossier);
    }

    modifierHonorairesDossier(payload : ChangementRapide) : Observable<Dossier> {
      return this.http.post<Dossier>(this.baseUrl + "api/dossier/update/honoraires", payload);
    }

    modifierDateContactDossier(payload : ChangementRapide) : Observable<Dossier> {
      return this.http.post<Dossier>(this.baseUrl + "api/dossier/update/dateContact", payload);
    }

    modifierDateVisiteDossier(payload : ChangementRapide) : Observable<Dossier> {
      return this.http.post<Dossier>(this.baseUrl + "api/dossier/update/dateVisite", payload);
    }

    modifierDocument(document : ModificationDocument) : Observable<Document> {
      return this.http.post<Document>(this.baseUrl + "document/modifier", document);
    }

    getUserConnectedProfilesById() : Promise<ProfilesUser[]> {
      if(this.tokenStorageService.getToken()) {
         return this.http.get<ProfilesUser[]>(this.baseUrl + "api/user-connected/profiles").toPromise();
      }
      return null;
    }

    getUserConnectedByToken() : Promise<User> {
      return this.http.get<User>(this.baseUrl + "api/user/connected").toPromise();
    }

    getAgenceUserConnected() : Promise<User> {
      return this.http.get<User>(this.baseUrl + "api/user/connected/agence").toPromise();
    }

    getCategoriesDocument() : Observable<CategorieDocument[]> {
      return this.http.get<CategorieDocument[]>(this.baseUrl + "document/categories");
    }


  findDocumentByIdbienAndCategorie(idBien: number, idCat : number) : Observable<Document[]> {
    let param = new HttpParams().append('idBien', idBien).append("idCat", idCat);
    return this.http.get<Document[]>(this.baseUrl + "documents/categorie", {params : param});
  }

  uploadPhoto(files: File[], uploadRequest: UploadRequest) : Observable<any> {
    const formData: FormData = new FormData();

    for(let i = 0; i < files.length; i++) {
      formData.append('files'+(i+1), files[i]);
    }

    formData.append('uploadRequest', JSON.stringify(uploadRequest));
    return this.http.post<any>(runtimeEnvironment.backEndPoint + "photo/upload", formData);
  }

  deleteDossierRapportDocument(idBien: number, uidDocument : string, idDossier : number) : Observable<any> {
    let param = new HttpParams()
      .append('idBien', idBien)
      .append("uidDocument", uidDocument)
      .append("idDossier", idDossier);
    return this.http.delete<any>(this.baseUrl + "dossier/rapport/document", {params : param});
  }


  rejectLivrable(dossier : Dossier) : Observable<Dossier> {
    return this.http.put<Dossier>(this.baseUrl +"api/dossier/rejectLivrable", dossier);
  }

  verouillerDossier(dossier : Dossier) : Observable<Dossier> {
    return this.http.put<Dossier>(this.baseUrl +"api/dossier/verouiller-dossier", dossier);
  }

  debloquerDossierVerrouiller(dossier : Dossier) : Observable<Dossier> {
    return this.http.put<Dossier>(this.baseUrl +"api/dossier/debloquer-dossier-verrouiller", dossier);
  }


  getAllUtilisateurSignataires() : Observable<Signataire[]> {
    return this.http.get<Signataire[]>(this.baseUrl +"api/dossier/all-user-signataire");
  }

  validerLivrable(dossier : Dossier) : Observable<Dossier> {
    return this.http.put<Dossier>(this.baseUrl +"api/dossier/valider-livrable-dossier", dossier);
  }

  envoyerProjet(dossier : Dossier, guidDocument : string) : Observable<Dossier> {
    let param = new HttpParams()
      .append('guidDocument', guidDocument);
    return this.http.get<Dossier>(this.baseUrl +"api/dossier/envoyer-projet/" + dossier.idDossier, {params: param});
  }

  downloadDocumentByType(idBien : number, type : String) : Observable<any> {
    return this.http.get(this.baseUrl + "document/" + type + "/" + idBien, {
      responseType: 'arraybuffer',
      observe : 'response'
    });
  }

  getListAgentByAgence(idDossier: number) : Observable<User[]> {
    return this.http.get<User[]>(this.baseUrl + "api/dossier/list-agent/" + idDossier);
  }

  checkIfAgentCreateDossierIsInactif(idDossier: number) : Promise<boolean> {
    return this.http.get<boolean>(this.baseUrl + "api/dossier/check-if-agent-create-dossier-is-inactif/" + idDossier).toPromise();
  }

  listFiles(path: string): Observable<any> {
    return this.http.get<string[]>(`${this.baseUrl}api/files/list`, { params: { path } });
  }
  downloadFile(path: string): Observable<Blob> {
    return this.http.get(`${this.baseUrl}api/files/download`, { params: { path }, responseType: 'blob' });
  }
}
