import {Injectable} from '@angular/core';
import {ReferentielValeur} from '../../models/referentiel-valeur';
import {LoginResponse} from "../../models/user/login-response";
import {User} from "../../models/user/user.model";

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {

  static readonly TOKEN_KEY="valea_token";
  static readonly TOKEN_EXPIRATION_DATE = "expiration_date";
  static readonly USER_KEY ="user";
  static readonly USER_LOGIN_DATE ="user_login_date";
  static readonly USER_TYPES_LIVRABLES = "user_types_livrables";

  static readonly APPLY_BUTTON_WEBSSO = 'apply_button_websso';
  static readonly LANGUE = 'langue';

  constructor() { }

  signOut(): void {
   /* localStorage.removeItem(LocalStorageService.TOKEN_KEY);
    localStorage.removeItem(LocalStorageService.TOKEN_EXPIRATION_DATE);
    localStorage.removeItem(LocalStorageService.USER_KEY);
    localStorage.removeItem(LocalStorageService.USER_LOGIN_DATE);
    localStorage.removeItem(LocalStorageService.USER_TYPES_LIVRABLES);
    localStorage.removeItem("itemCritereRechercheDossier")*/
    this.clearAllStorage()
    //localStorage.clear();
  }

  /**
   *Met à jour les information de la session
   * @param data
   * @param dateLoginUser
   */
  public setSession(data : LoginResponse, dateLoginUser : number): void {

    LocalStorageService.removeData();
    localStorage.setItem('langue', data.langue);
    localStorage.setItem(LocalStorageService.TOKEN_EXPIRATION_DATE, JSON.stringify(data.expirationDate.valueOf()));
    localStorage.setItem(LocalStorageService.TOKEN_KEY, data.token);
    localStorage.setItem(LocalStorageService.USER_LOGIN_DATE,  JSON.stringify(dateLoginUser));
  }

  private static removeData() {
    localStorage.removeItem(LocalStorageService.LANGUE);
    localStorage.removeItem(LocalStorageService.TOKEN_KEY);
    localStorage.removeItem(LocalStorageService.TOKEN_EXPIRATION_DATE);
    localStorage.removeItem(LocalStorageService.USER_LOGIN_DATE);
    localStorage.removeItem(LocalStorageService.USER_TYPES_LIVRABLES);
  }

  public setTypesLivrables(typesLivrables: ReferentielValeur[]) {
    localStorage.setItem(LocalStorageService.USER_TYPES_LIVRABLES, JSON.stringify(typesLivrables));
  }

  public getTypesLivrables() : ReferentielValeur[] {
    const types = localStorage.getItem(LocalStorageService.USER_TYPES_LIVRABLES);
    if (types) {
      return JSON.parse(types);
    }
    return null;
  }

  public getToken(): string | null {
    return localStorage.getItem(LocalStorageService.TOKEN_KEY);
  }

  public getLoginDate() : number {
    return JSON.parse(localStorage.getItem(LocalStorageService.USER_LOGIN_DATE));
  }

  public getUser(): User {
    const user = localStorage.getItem(LocalStorageService.USER_KEY);
    if (user) {
      return JSON.parse(user);
    }
    return null;
  }

  getExpiration(): number {
    const expiration = localStorage.getItem(LocalStorageService.TOKEN_EXPIRATION_DATE);
    return JSON.parse(expiration);
  }

  setApplyButtonWeSSO(applyWebsso : any)  {
    localStorage.setItem(LocalStorageService.APPLY_BUTTON_WEBSSO, applyWebsso);
  }

  getApplyButtonWeSSO() : string | null {
    return localStorage.getItem(LocalStorageService.APPLY_BUTTON_WEBSSO);
  }

  removeApplyButtonWebSSO() {
    localStorage.removeItem(LocalStorageService.APPLY_BUTTON_WEBSSO);
  }

  setCurrentURL(url : string) {
    localStorage.removeItem("VALEA")
    localStorage.setItem('VALEA', url)
  }

  getCurrentURL() : string {
    return localStorage.getItem("VALEA");
  }

  clearAllStorage() {
    let valea = localStorage.getItem("VALEA");
    localStorage.clear();
    this.setCurrentURL(valea);
  }

}
