<form  class="page-form" #form="ngForm" (ngSubmit)="dupliquer()" >

  <p-messages [value]="messageTypeLivrable" *ngIf="messageTypeLivrable" [closable]="false"></p-messages>

  <div *ngIf="display">
    <div *ngIf="codeLivrables.length > 0 ">
      <p-messages [value]="messageReject" *ngIf="messageReject"></p-messages>
      <h2 class="p-2">{{'DOSSIER.CHOIX_LIVRABLE' | translateI18n}}</h2>

      <div *ngFor="let codeLivrable of codeLivrables" class="field-checkbox">
        <p-radioButton [inputId]="codeLivrable.code" name="category" [value]="codeLivrable.code" [(ngModel)]="valueTypeLivrable" ></p-radioButton>
        <label [for]="codeLivrable.code">{{codeLivrable.name}}</label>
      </div>


      <div *ngIf="!agentActif" class="mt-6">
        <p class="obligatoireAgent">
          <span><i class="pi pi-exclamation-triangle" style="font-size: 1.5rem"></i></span>
          L'agent {{dossier.nomCreateurAudit}} qui a créé le dossier est inactif dans l'agence.
          Merci de sélectionner un autre agent actif dans la liste ci-dessous.
        </p>

        <p-messages [value]="messageAucunAgentActif" *ngIf="messageAucunAgentActif" [closable]="false"></p-messages>

        <p-autoComplete *ngIf="!selectBoxAgentActifVisible" [(ngModel)]="agent" [showEmptyMessage]="true" name="agent"
                        [suggestions]="filteredAgent"
                        (completeMethod)="searchAgentUser($event)"
                        emptyMessage="Aucun resultat"
                        [minLength]="1"
                        field="nomComplet"
                        [required]="!agentActif"
                        [dropdown]="true"
                        class="inputfield w-full" [size]="55">
          <ng-template let-expert pTemplate="item">
            <span>{{ expert.nomComplet}}</span>
          </ng-template>
        </p-autoComplete>
      </div>

      <div class="mt-6">
        <span><i class="pi pi-exclamation-triangle" style="font-size: 1.5rem"></i></span>
        {{'DOSSIER.GESTIONNAIRE.MESSAGE.ACTION_CONFIRMATION' | translateI18n}}
      </div>

    </div>

    <div class="p-dialog-footer">

      <button pButton type="button" pRipple label="{{(codeLivrables.length > 0  ? 'DOSSIER.CONFIRMATION_DIALOG.REJET_BUTTON' : 'BUTTON_LABEL.CLOSE' ) | translateI18n }}" icon="pi pi-times"
              class="uppercase p-button-secondary p-button-outlined mb-2" (click)="hideDialog()"></button>
      <button *ngIf="codeLivrables.length > 0 "  pButton type="submit" pRipple label="{{'DOSSIER.CONFIRMATION_DIALOG.ACCEPT_BUTTON' | translateI18n}}"
              icon="pi pi-check" class="ppercase mb-2" ></button>
    </div>
  </div>

</form>


