<p-paginator *ngIf="totalRecords > settings.pagination.rows" [rows]="settings.pagination.rows"
            #paginator
             [first]="settings.pagination.first"
             [totalRecords]="totalRecords"
             [showPageLinks]="true"
             [pageLinkSize]="settings.pagination.pageLinkSize"
             class="ml-auto"
             [showCurrentPageReport]="true"
             [currentPageReportTemplate]="label"
             (onPageChange)="nextPage($event)"
             [rowsPerPageOptions]="settings.pagination.rowsPerPageOptions"></p-paginator>
