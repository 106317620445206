import {Component, OnInit} from '@angular/core';
import {ProfilesUser} from "../../models/user/profiles-user";
import {DynamicDialogConfig, DynamicDialogRef} from "primeng/dynamicdialog";
import {UserService} from "../../service/user/user.service";
import {DossierService} from "../../service/dossier.service";
import {Departement} from "../../models/adresse";
import {User} from "../../models/user/user.model";
import {TranslateService} from "../../../shared/translate/translate.service";
import {Message} from "primeng/api";
import {NgxSpinnerService} from "ngx-spinner";

@Component({
  selector: 'app-affectation-recherche-avancee-expert',
  templateUrl: './affectation-recherche-avancee-expert.component.html',
  styleUrls: ['./affectation-recherche-avancee-expert.component.scss']
})
export class AffectationRechercheAvanceeExpertComponent implements OnInit {

  filteredAssistante: any[];
  filteredItemsAssistante: any[];
  selectedAssistantes: any;
  assistantUser : any[];

  filteredExpert: any[];
  filteredItems: any[];
  selectedExpert: any;

  experUser : User[] = [];

  userProfile : any[] = [ProfilesUser.EXPERT_EXTERNE, ProfilesUser.EXPERT_INTERNE];
  assistantProfile : any[] = [ProfilesUser.ASSISTANTE];

  selectAll: boolean = false;

  departements : Departement[];

  departement : Departement;
  selectedDepartement : Departement;
  filteredDepartement : any;

  displayDeptInter  : boolean = true;
  rechAdvLink : boolean = false;

  users : User[];

  ecran  : string;

  assistanteConnected : any;
  message : Message[];

  constructor(
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private userService : UserService,
    private dossierService : DossierService,
    public translate: TranslateService,
    private spinnerService: NgxSpinnerService
  ) { }

  ngOnInit(): void {

    this.ecran = this.config.data;

    if(this.ecran === 'EN_ATTENTE_AFFECTATION') {
      this.displayDeptInter = false;
    }
    this.userService.getAllUserByProfiles(this.userProfile).subscribe(expertUser => {
      this.experUser = expertUser;
    });

    this.userService.getAllUserByProfiles(this.assistantProfile).subscribe(assistant => {
      this.assistantUser = assistant;
      this.checkAssistante();
    });

    this.getAllPaysAndDepartement();
  }

  /**
   * auto completion utilisateur expert
   * @param event
   */
  searchExpertUser(event : any) {
    let filtered : User[] = [];
    let query = event.query;
    if(this.experUser.length > 0) {
      this.experUser.forEach(expert => {
        if (expert.nom.toLowerCase().indexOf(query.toLowerCase()) === 0 ||
          expert.prenom.toLowerCase().indexOf(query.toLowerCase()) === 0) {
          expert['nomComplet'] = expert.prenom + ' ' + expert.nom;
          filtered.push(expert);
        }
      })
      this.filteredExpert = filtered;
    }
  }

  async checkAssistante() {
    let user = await this.userService.getUserConnectedByToken();
    this.assistantUser.forEach((assistante => {
      assistante['nomComplet'] = assistante.nom + ' ' + assistante.prenom;
      if (assistante.id === user.id) {
       this.selectedAssistantes = assistante;
      }
    }));

  }

  getAllPaysAndDepartement() {
    this.dossierService.getAllDepartement().subscribe(data => {
      data.forEach(dept => {
        dept['numeroLibelle'] = dept.numero + ' - ' + dept.code.libelle
      })
      this.departements = data.sort((a, b) => (a.numero > b.numero) ? 1 : -1);
    });
  }

  searchDepartement(event : any) {
    let filtered : any[] = [];
    let query = event.query;
    if(this.departements.length > 0) {
      this.departements.forEach(dept => {
        if (dept.numero.indexOf(query.toLowerCase()) === 0 ||
          dept.code.libelle.toLowerCase().indexOf(query.toLowerCase()) === 0) {
          filtered.push(dept);
        }
      })
      this.filteredDepartement = filtered;
    }
  }

  affectation() {
    if(this.selectedExpert && this.selectedAssistantes) {

      if(this.selectedExpert.id === undefined) {
        this.message = [{severity:'warn',  summary: this.translate.getElement('DOSSIER.AFFECTATION.MESSAGE.ERROR.SELECT_EXPERT')}];
      }else if(this.selectedAssistantes.id === undefined) {
        this.message = [{severity:'warn',  summary: this.translate.getElement('DOSSIER.AFFECTATION.MESSAGE.ERROR.SELECT_ASSISTANTE')}];
      }else {
        const data = {expert : this.selectedExpert, assistante : this.selectedAssistantes};
        this.close(data);
      }
    }
    else {
      if(!this.selectedExpert) {
        this.message = [{severity:'warn', summary: this.translate.getElement('DOSSIER.AFFECTATION.MESSAGE.ERROR.SELECT_EXPERT_1')}];
        return;
      }
      else if (!this.selectedAssistantes) {
        this.message = [{severity:'warn', summary: this.translate.getElement('DOSSIER.AFFECTATION.MESSAGE.ERROR.SELECT_ASSISTANTE_1')}];
        return;
      }
    }
  }

  close(data? : any) {
    this.ref.close(data);
  }

  selectedDepartementIntervention(departement : Departement) {
    let ref = departement.code;
    this.userService.getAllUsersByDepartementCode(ref.code).subscribe(
      data => {
        this.users = data.sort((a, b) => (a.nom > b.nom) ? 1 : -1);
      }, error => {

      }
    )
  }

  choiceExpert(user : User) {
    let filtered : any[] = [];

    this.experUser.forEach(expert => {
      if (expert.nom.toLowerCase().indexOf(user.nom.toLowerCase()) === 0 &&
        expert.prenom.toLowerCase().indexOf(user.prenom.toLowerCase()) === 0) {
        expert['nomComplet'] = expert.prenom + ' ' + expert.nom;
        filtered.push(expert);
      }
    })

    this.filteredExpert = filtered;
    this.selectedExpert = this.filteredExpert[0];

    if(this.ecran === 'EN_ATTENTE_AFFECTATION') {
      this.displayDeptInter = false;
      this.close(this.selectedExpert);
    }else {
      this.displayDeptInter = true;
      this.rechAdvLink = false;
    }

  }

  showDeptIntervention() {
    this.displayDeptInter = false;
    this.rechAdvLink = true;
  }

}
