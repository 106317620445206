<div class="login-page">
</div>
<main class="layout-wrapper h-full" id="main-login">

  <div class="flex justify-content-center m-4" id="logo-bnp-login">
    <img src="../../../assets/images/logo-bnp-valuation.svg" width="200px" />
  </div>

  <div class="layout-main h-full no-nav no-header">

    <div class="grid justify-content-center align-content-center w-full">

      <div class="col-10 is-login-card">

        <div class="flex justify-content-center pb-6">
          <img src="../../../assets/images/logo-valea.svg" width="140px" />
        </div>

        <div  *ngIf="infoMessage">
          <p-messages [(value)]="infoMessage" [enableService]="false"></p-messages>
        </div>

        <div  *ngIf="errorMessageCode">
          <p-messages [(value)]="errorMessageCode" [enableService]="false" [escape]="false"></p-messages>
        </div>

        <div *ngIf="messagesTentitivesCode">
          <p-messages severity="error" [escape]="false" [enableService]="false" [closable]="true">
            <ng-template pTemplate>
              <div class="ml-5">
                <h3> <i class="pi pi-info-circle" style="font-size: 1.5rem"></i>
                  <span class="ml-4">{{'AUTH.MESSAGE.INFO.TITLE_CODE_SEND' | translateI18n}}</span></h3>
                <p class="pb-3">{{messagesTentitivesCode}}</p>
              </div>
            </ng-template>
          </p-messages>
        </div>

        <div *ngIf="messages">
          <p-messages severity="info" [escape]="false" [enableService]="false" [closable]="true">
            <ng-template pTemplate>
              <div class="ml-4 mr-4">
                <h3> <i class="pi pi-info-circle" style="font-size: 1.5rem;"></i>
                  <span class="ml-4">{{'AUTH.MESSAGE.INFO.TITLE_CODE_SEND' | translateI18n}}</span></h3>
                <p>{{messages[0]}}</p>
                <p>{{messages[1]}}</p>
                <p class="pb-3">{{messages[2]}}</p>
              </div>
            </ng-template>
          </p-messages>
        </div>

        <div class="form-container card" *ngIf="showCodeInput">
          <form #form="ngForm" (ngSubmit)="authenticate(form)">

            <div class="form-title text-center">Entrez le code de sécurité</div>
            <div class="field">
              <div class="flex justify-content-center pt-3 pb-3">
                <input type="text" pInputText id="pin1" name="pin1" [(ngModel)]="chiffre1" (keyup)="onDigitInput($event)" (paste)="onPaste($event)" class="inputfield pin-code" maxlength="1" size="1" required />
                <input type="text" pInputText id="pin2" name="pin2" [(ngModel)]="chiffre2" (keyup)="onDigitInput($event)" (paste)="onPaste($event)" class="inputfield pin-code" maxlength="1" size="1" required />
                <input type="text" pInputText id="pin3" name="pin3" [(ngModel)]="chiffre3" (keyup)="onDigitInput($event)" (paste)="onPaste($event)" class="inputfield pin-code" maxlength="1" size="1" required />
                <input type="text" pInputText id="pin4" name="pin4" [(ngModel)]="chiffre4" (keyup)="onDigitInput($event)" (paste)="onPaste($event)" class="inputfield pin-code" maxlength="1" size="1" required />
                <input type="text" pInputText id="pin5" name="pin5" [(ngModel)]="chiffre5" (keyup)="onDigitInput($event)" (paste)="onPaste($event)" class="inputfield pin-code" maxlength="1" size="1" required />
                <input type="text" pInputText id="pin6" name="pin6" [(ngModel)]="chiffre6" (keyup)="onDigitInput($event)" (paste)="onPaste($event)" class="inputfield pin-code" maxlength="1" size="1" required />
                <input type="text" pInputText id="pin7" name="pin7" [(ngModel)]="chiffre7" (keyup)="onDigitInput($event)" (paste)="onPaste($event)" class="inputfield pin-code" maxlength="1" size="1" required />
                <input type="text" pInputText id="pin8" name="pin8" [(ngModel)]="chiffre8" (keyup)="onDigitInput($event)" (paste)="onPaste($event)" class="inputfield pin-code" maxlength="1" size="1" required />
                <button pButton type="button" label="" icon="pi pi-times" iconPos="left" class="p-button-info"
                        (click)="clearCodeInput()" pTooltip="Effacer"></button>

              </div>


            </div>
            <div>
              <button pButton type="submit" [disabled]="form.invalid" label="Valider" class="w-full uppercase"  icon="pi pi-check" iconPos="right"></button>
            </div>
          </form>
        </div>

      </div>
    </div>
  </div>

</main>
