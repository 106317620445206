<form [formGroup]="form">

  <div class="card-large" id="section1">
    <h1 id="situationGeo">III - {{'AVIS_VALEUR.SITUATION_GEO' | translateI18n}}</h1>

    <div class="grid">
      <div class="field col-12 field-line">

        <div class="mb-2">
          <h3 class="m-0 obligatoire">{{'AVIS_VALEUR.LOC_ENV_DES' | translateI18n}} :</h3>
          <small>{{'AVIS_VALEUR.MESSAGE.MAX_CARACTERE' | translateI18n : '2000'}}
            <span [ngClass]="{'is-alert': f.situation.hasError('hasError')}">({{nbcaractSituationGeo}} / 2000)</span>
          </small>
        </div>
        <div *ngIf="f.situation.hasError('hasError')">
          <p-messages  [(value)]="msgError"></p-messages>
        </div>

        <p-editor formControlName="situation" [style]="{'height':'320px'}" name="situation" class="w-full"
                  [ngClass]="{'editor-error': (submittedRequired && f.situation.errors) || f.situation.hasError('hasError')}"
                  (onTextChange)="caractSituationGeoTextChange($event)">
          <p-header>
            <app-p-editor-header></app-p-editor-header>
          </p-header>
        </p-editor>
      </div>
    </div>
  </div>


</form>

