<p-inplace [closable]="true" class="inline-block">
  <ng-template pTemplate="display">
    {{doc?.commentaire ? doc?.commentaire : "Ajouter un commentaire"}}
  </ng-template>
  <ng-template pTemplate="content">
    <form #form="ngForm" class="flex">
      <input type="text" pInputText id="commentaire"
             placeholder="Ajouter un commentaire"
             name="commentaire"
             [(ngModel)]="doc.commentaire"
              [maxLength]="30"/>
      <button pButton pRipple type="button" icon="pi pi-check" class="p-button-text p-button p-component p-button-icon-only ml-2"
              (click)="editCommentaire(doc)"></button>
    </form>

  </ng-template>
</p-inplace>
