import {Component, Input, OnInit} from '@angular/core';
import {InfoExpertise} from "../../../models/dossiers/info-expertise";

@Component({
  selector: 'app-expert-dossier',
  templateUrl: './expert-dossier.component.html',
  styleUrls: ['./expert-dossier.component.scss']
})
export class ExpertDossierComponent implements OnInit {

  expertAdresseIconVisible : boolean;
  expertNameIconVisible : boolean;
  expertEmailIconVisible : boolean;
  telIconVisible : boolean;

  @Input()
  expert : InfoExpertise;

  constructor() { }

  ngOnInit(): void {
  }

}
