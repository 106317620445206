<ng-template [ngIf]="typeField === 'text'">
  <p-columnFilter type="text" [field]="field"
                  display="menu"
                  [matchMode]="matchModeOptions"
                  [showOperator]="false"
                  [showAddButton]="false"
                  [showMatchModes]="false">
  </p-columnFilter>
</ng-template>

<ng-template [ngIf]="typeField === 'numeric'">
  <p-columnFilter type="numeric" [field]="field"
                  display="menu" currency="EUR" [maxFractionDigits]="0"
                  [matchMode]="matchModeOptions"
                  [showOperator]="false"
                  [showAddButton]="false"
                  [showMatchModes]="false">
  </p-columnFilter>
</ng-template>

<ng-template [ngIf]=" typeField === 'date'">

  <p-columnFilter type="date" [field]="field"
                  display="menu"
                  [matchModeOptions]="matchDateModeOptions"
                  [showMatchModes]="true"
                  [showOperator]="false"
                  [showAddButton]="false">
  </p-columnFilter>
</ng-template>

<ng-template [ngIf]="typeField === 'status'">

  <p-columnFilter [field]="field" [matchMode]="statutsMatchMode"  display="menu"  [showOperator]="false" [showAddButton]="false"
                  [showApplyButton]="false" [showClearButton]="false" [showMatchModes]="false" >

    <ng-template pTemplate="filter" let-value let-filter="filterCallback">
      <p-multiSelect
        [ngModel]="value"
        [options]="statuts"
        placeholder="selectionnez"
        (onChange)="filter($event.value)"
        [showToggleAll]="false"
        [showHeader]="false"
        optionLabel="name"
        optionValue="value">
      </p-multiSelect>
    </ng-template>
  </p-columnFilter>

</ng-template>

<ng-template [ngIf]="typeField === 'typeLivrable'">

  <p-columnFilter [field]="field" [matchMode]="statutsMatchMode"  display="menu"  [showOperator]="false" [showAddButton]="false"
                  [showApplyButton]="false" [showClearButton]="false" [showMatchModes]="false" >

    <ng-template pTemplate="filter" let-value let-filter="filterCallback">
      <p-multiSelect
        [ngModel]="value"
        [options]="livrables"
        placeholder="selectionnez"
        (onChange)="filter($event.value)"
        [showToggleAll]="false"
        [showHeader]="false"
        optionLabel="name"
        optionValue="value">
      </p-multiSelect>
    </ng-template>
  </p-columnFilter>

</ng-template>
