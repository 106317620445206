<h2>{{'DOSSIER.ADVANCED_SEARCH.LABEL.AFFAIRE' | translateI18n}}</h2>

<div class="grid formgrid" [formGroup]="form">
  <div class="field col-12 md:col-6">
    <div class="p-input-icon-right w-full">
      <input type="text" pInputText id="nomClient" formControlName="nomClient" class="inputfield w-full"
      placeholder="{{'DOSSIER.ADVANCED_SEARCH.LABEL.NOM_RAISON_SOCIALE' | translateI18n}}"/>
    </div>
  </div>

  <div class="field col-12 md:col-6">
    <div class="p-input-icon-right w-full">
      <input type="text" pInputText id="prenomClient" formControlName="prenomClient" class="inputfield w-full"
             placeholder="{{'DOSSIER.ADVANCED_SEARCH.LABEL.PRENOM' | translateI18n}}" />
    </div>
  </div>
</div>
