
<span class="ql-formats">
  <button class="ql-bold" aria-label="Bold"></button>
  <button class="ql-italic" aria-label="Italic"></button>
  <button class="ql-underline" aria-label="Underline"></button>
</span>

<span class="ql-formats">
    <button class="ql-list" value="ordered"></button>
    <button class="ql-list" value="bullet"></button>
    <select title="Text Alignment" class="ql-align" >
      <option selected>Gauche</option>
      <option value="center" label="Center"></option>
      <option value="right" label="Right"></option>
      <option value="justify" label="Justify"></option>
    </select>
</span>

