import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'userProfil'
})
export class UserProfilPipe implements PipeTransform {

  transform(value : string[]): any[] {

    let tt : any [] = [];
    if(value && value.length > 0) {

      value.forEach(val => {
        switch (val) {
          case  'ADMINISTRATEUR' :
            tt.push('Administrateur')
            break;
          case  'EXPERT_INTERNE' :
            tt.push('Expert interne')
            break;
          case  'EXPERT_EXTERNE' :
            tt.push('Expert externe')
            break;
          case  'ASSISTANTE' :
            tt.push('Assistante')
            break;
          case  'ANIMATEUR_RESEAU' :
            tt.push('Animateur reseau')
            break;
          case  'AGENT' :
            tt.push('Agent')
            break;
        }
      })
    }


    return tt;
  }

}
