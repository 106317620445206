<div class="mb-3">
  <button pButton pRipple label="{{'RAPPORT_EXPERTISE.ACTIONS.AJOUTER_NOUVELLE_LIGNE' | translateI18n}}"
          icon="pi pi-plus" class="p-button-sm p-button-rounded" (click)="openNew()"></button>
</div>
<p-messages  *ngIf="message" [(value)]="message" key="tableSurface"></p-messages>
<p-table [value]="bienReferencesLoc" [rows]="settings.pagination?.rowsDocument"  [showCurrentPageReport]="true"
         [paginator]="bienReferencesLoc?.length > settings.pagination?.rowsDocument" [rowsPerPageOptions]="settings.pagination?.rowsPerPageOptionsDocument"
         [globalFilterFields]="['adresse', 'typeBien.libelle', 'loyer', 'surface', 'ratio', 'annexe', 'datetransac', 'observations']"
         dataKey="id" paginatorPosition="top"
         scrollDirection="both"
         [scrollable]="true"
         currentPageReportTemplate="Affichage {first} à {last} sur {totalRecords}" >
  <ng-template pTemplate="header">
    <tr>
      <th pFrozenColumn class="width-9"></th>
      <th pFrozenColumn pSortableColumn="adresse" class="width-15 frozen-shadow">{{'RAPPORT_EXPERTISE.LABEL.ADRESSE' | translateI18n}}  <p-sortIcon field="adresse"></p-sortIcon></th>
      <th class="is-large" pSortableColumn="typeBien.libelle">{{'RAPPORT_EXPERTISE.LABEL.TYPE_BIEN' | translateI18n}}   <p-sortIcon field="typeBien.libelle"></p-sortIcon></th>
      <th class="is-large" pSortableColumn="loyer">{{'RAPPORT_EXPERTISE.LABEL.LOYER' | translateI18n}} (€)<p-sortIcon field="loyer"></p-sortIcon></th>
      <th class="is-large" pSortableColumn="surface">{{'RAPPORT_EXPERTISE.LABEL.SURFACE' | translateI18n}} (m²)<p-sortIcon field="surface"></p-sortIcon></th>
      <th class="is-large" pSortableColumn="ratio" >{{'RAPPORT_EXPERTISE.LABEL.RATIO' | translateI18n}} <p-sortIcon field="ratio"></p-sortIcon></th>
      <th class="is-large" pSortableColumn="annexe">{{'RAPPORT_EXPERTISE.LABEL.ANNEXE' | translateI18n}} <p-sortIcon field="annexe"></p-sortIcon></th>
      <th class="is-large" pSortableColumn="datetransac">{{'RAPPORT_EXPERTISE.LABEL.DATE_TRANSACTION' | translateI18n}}<p-sortIcon field="datetransac"></p-sortIcon></th>
      <th class="is-large" pSortableColumn="observations">{{'RAPPORT_EXPERTISE.LABEL.OBSERVATIONS' | translateI18n}} <p-sortIcon field="observations"></p-sortIcon></th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-bier>
    <tr>
      <td pFrozenColumn class="width-9">
        <button type="button" pButton pRipple icon="inc-icon inc-icon-trash is-small"
                pTooltip="{{'BUTTON_LABEL.DELETE' | translateI18n}}" tooltipPosition="top"
                class="p-button-rounded " (click)="delete(bier)"></button>
        <button type="button" pButton pRipple icon="inc-icon inc-icon-edit is-small"
                pTooltip="{{'BUTTON_LABEL.MODIFY' | translateI18n}}" tooltipPosition="top"
                class="p-button-rounded p-button-success mr-2" (click)="edit(bier)"></button>
      </td>
      <td pFrozenColumn class="width-15 frozen-shadow">{{bier.adresse}}</td>
      <td class="is-large">{{bier.typeBien?.libelle}}</td>
      <td class="is-large">{{bier.loyer | decimal}}/{{bier.periodiciteLoyer?.libelle}}</td>
      <td class="is-large">{{bier.surface | decimal}}</td>
      <td class="is-large">{{bier.ratio | decimal}}/{{bier.periodiciteLoyer?.libelle}}</td>
      <td class="is-large">{{bier.annexe}}</td>
      <td class="is-large">{{bier.datetransac | date : 'dd/MM/yyyy'}}</td>
      <td class="is-large">{{bier.observations }}</td>
    </tr>
  </ng-template>

</p-table>






<p-dialog [(visible)]="dialog" [style]="{width: '600px'}" [baseZIndex]="10000" header="{{'RAPPORT_EXPERTISE.LABEL.AJOUTER_ELEMENT' | translateI18n}}" [modal]="true" styleClass="p-fluid p-dynamic-dialog">

  <ng-template pTemplate="content" style="overflow: visible !important;">
    <p-messages  *ngIf="messageDialog" [(value)]="messageDialog"></p-messages>
    <form [formGroup]="refLocativeForm" class="page-form">
     <div class="grid">
      <div class="col-12 field">
        <label for="adresse" class="obligatoire label" [ngClass]="{'p-error': submitted && formRefLocative.adresse.errors?.pattern}">
          {{'RAPPORT_EXPERTISE.LABEL.ADRESSE' | translateI18n}}
        </label>
        <input type="text" class="inputfield" pInputText id="adresse" formControlName="adresse"
               [ngClass]="{'ng-dirty': submitted  && (formRefLocative.adresse?.errors?.pattern ||  formRefLocative.adresse?.errors?.required)}"
               [maxLength]="64" required/>

        <small *ngIf="formRefLocative.adresse?.errors?.pattern" class="p-error block">
          {{'MESSAGE.ERROR.REGEX_PATTERN' | translateI18n}}</small>
        <small *ngIf="submitted && formRefLocative.adresse?.errors?.required" class="p-error block">
          {{'MESSAGE.ERROR.REGEX_PATTERN' | translateI18n}}</small>

      </div>


      <div class="col-12 field">
        <label for="typeBien" [ngClass]="{'p-error': submitted  && formRefLocative.typeBien.errors?.required}" class="obligatoire label">
          {{'RAPPORT_EXPERTISE.LABEL.TYPE_BIEN' | translateI18n}}
        </label>
        <p-dropdown [options]="typeBiens" formControlName="typeBien"
                    optionLabel="libelle" name="typeBien"  id="typeBien"
                    [ngClass]="{'ng-dirty ng-invalid':  submitted  && formRefLocative.typeBien.errors?.required}"
                    emptyMessage="{{'DOSSIER.MESSAGE.AUCUN_RESULTAT' | translateI18n}}"
                    placeholder="{{'PLACEHOLDER.CHOOSE' | translateI18n}}" (onChange)="typeBienChange($event)">
        </p-dropdown>
        <small class="p-error" *ngIf="submitted && formRefLocative.typeBien?.errors?.required">{{'MESSAGE.ERROR.CHAMPS_OBLIGATOIRE' | translateI18n}}</small>
      </div>


      <div class="col-12 md:col-6 field">
        <label class="obligatoire label" [ngClass]="{'p-error': submitted && formRefLocative.loyer.errors}">
          {{'RAPPORT_EXPERTISE.LABEL.LOYER' | translateI18n}}</label>

        <p-inputNumber formControlName="loyer"
                       inputId="loyer"
                       mode="currency" currency="EUR" locale="fr-FR"
                       [ngClass]="{'ng-invalid ng-dirty': submitted && formRefLocative.loyer.errors}"
                       [size]=20
                       [min]="0"
                       [maxlength]="18"
                       placeholder="{{'RAPPORT_EXPERTISE.LABEL.RENSEIGNER' | translateI18n}}">
        </p-inputNumber>
        <small class="p-error" *ngIf="submitted && formRefLocative.loyer?.errors?.required">{{'MESSAGE.ERROR.CHAMPS_OBLIGATOIRE' | translateI18n}}</small>
      </div>

      <div class="col-12 md:col-6 field">
        <label for="periodiciteLoyer" [ngClass]="{'p-error': submitted  && formRefLocative.periodiciteLoyer.errors?.required}" class="obligatoire label">
          {{'RAPPORT_EXPERTISE.LABEL.PERIODICITE' | translateI18n}}
        </label>
        <p-dropdown [options]="periodicites" formControlName="periodiciteLoyer"
                    optionLabel="libelle" name="periodiciteLoyer"  id="periodiciteLoyer"
                    [ngClass]="{'ng-dirty ng-invalid':  submitted  && formRefLocative.periodiciteLoyer.errors?.required}"
                    emptyMessage="{{'DOSSIER.MESSAGE.AUCUN_RESULTAT' | translateI18n}}"
                    placeholder="{{'PLACEHOLDER.CHOOSE' | translateI18n}}" (onChange)="periodiciteLoyerChange($event)">
        </p-dropdown>
        <small class="p-error" *ngIf="submitted && formRefLocative.periodiciteLoyer?.errors?.required">{{'MESSAGE.ERROR.CHAMPS_OBLIGATOIRE' | translateI18n}}</small>
      </div>

      <div class="col-12 field">
        <label for="surface" class="label">
          {{'RAPPORT_EXPERTISE.LABEL.SURFACE' | translateI18n}} (m²)</label>
        <p-inputNumber formControlName="surface"
                       id="surface"
                       suffix=" m²"
                       [size]=20
                       [min]="0"
                       [maxlength]="18"
                       [minFractionDigits]="2"
                       [maxFractionDigits]="5"
                       placeholder="{{'RAPPORT_EXPERTISE.LABEL.RENSEIGNER' | translateI18n}}">
        </p-inputNumber>
      </div>

      <div class="col-12 md:col-6 field">
        <label for="ratio" class="label">
          {{'RAPPORT_EXPERTISE.LABEL.RATIO' | translateI18n}}</label>
        <p-inputNumber formControlName="ratio"
                       id="ratio"
                       suffix=" €/m²"
                       [size]=20
                       [min]="0"
                       [maxlength]="18"
                       [minFractionDigits]="2"
                       [maxFractionDigits]="5"
                       placeholder="{{'RAPPORT_EXPERTISE.LABEL.RENSEIGNER' | translateI18n}}">
        </p-inputNumber>
      </div>

      <div class="col-12 md:col-6 field">
        <label class="label">{{'RAPPORT_EXPERTISE.LABEL.PERIODICITE' | translateI18n}}</label>
        <p-dropdown [options]="periodicites" formControlName="periodiciteLoyerRatio"
                    optionLabel="libelle" name="periodiciteLoyer" placeholder="{{'PLACEHOLDER.CHOOSE' | translateI18n}}" >
        </p-dropdown>
      </div>


      <div class="col-12 field">
        <label for="annexe" class="label">{{'RAPPORT_EXPERTISE.LABEL.ANNEXE' | translateI18n}}</label>
        <input type="text" class="inputfield" pInputText id="annexe" formControlName="annexe" [maxLength]="64"/>
        <small *ngIf="formRefLocative.annexe?.errors?.pattern" class="p-error block">
          {{'MESSAGE.ERROR.REGEX_PATTERN' | translateI18n}}</small>
      </div>

      <div class="col-12 field">
        <label for="annexe" class="label obligatoire">{{'RAPPORT_EXPERTISE.LABEL.DATE_TRANSACTION' | translateI18n}}</label>
        <div class="p-input-icon-right w-full">
          <p-calendar  formControlName="datetransac" name="datetransac" id="datetransac"
                       [ngClass]="{'ng-dirty ng-invalid': submitted && formRefLocative.datetransac?.errors}"
                      [showIcon]="true" dateFormat="dd/mm/yy" inputId="icon" class="inputfield w-full"
                      [firstDayOfWeek]="1"></p-calendar>
        </div>
        <small *ngIf="submitted && formRefLocative.datetransac?.errors" class="p-error block">{{'MESSAGE.ERROR.CHAMPS_OBLIGATOIRE' | translateI18n}}</small>
      </div>

      <div class="col-12 field label">
        <label for="observations">{{'RAPPORT_EXPERTISE.LABEL.OBSERVATIONS' | translateI18n}}
        </label>
        <input type="text" class="inputfield" pInputText id="observations" formControlName="observations" [maxLength]="64"/>
        <small *ngIf="formRefLocative.observations?.errors?.pattern" class="p-error block">
          {{'MESSAGE.ERROR.REGEX_PATTERN' | translateI18n}}</small>
      </div>

     </div>
    </form>
  </ng-template>

  <ng-template pTemplate="footer">
    <button pButton type="button" pRipple label="{{'BUTTON_LABEL.ANNULER' | translateI18n}}" icon="pi pi-times" class="uppercase p-button-secondary p-button-outlined mb-2"
            (click)="hideDialog()"></button>
    <button pButton type="submit" pRipple label="{{'BUTTON_LABEL.ENREGISTRER' | translateI18n}}" icon="pi pi-save" class="ppercase mb-2" (click)="save()"></button>
  </ng-template>

</p-dialog>

