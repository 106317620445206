<p-toast></p-toast>
<main class="layout-wrapper">
  <app-navigation></app-navigation>
  <app-header titre="{{'DOSSIER.AFFECTATION.HEADER.TITLE' | translateI18n}}" sstitre="{{totalRecords}} dossiers" state="progress"></app-header>
  <div class="layout-main have-table">

    <div  *ngIf="messageAffect" class="mr-4">
      <p-messages [(value)]="messageAffect" [enableService]="false"></p-messages>
    </div>

    <div  *ngIf="errorMessage" class="mr-4">
      <p-messages [(value)]="errorMessage" [enableService]="false"></p-messages>
    </div>

    <!--TODO : Ticket 815 (a voir avec le metier)-->
    <!--<div class="cta-container m-3 flex justify-content-between flex-wrap">

      <app-quick-serch  [showContrat]="true" [showRechercheAvancee] = "true" rechercheAvanceeData="rechercheDossier"
                        lienRechercheRapide="dossier/recherche/gestionnaire"></app-quick-serch>


      <div *ngIf="!listFolderEmpty" class="flex align-items-center">
        <app-pagination [totalRecords]="response?.totalElements" (eventNextPage)="nextPage($event)"
                        label="{{'DOSSIER.PAGINATOR.MESSAGE.AFFICHAGE' | translateI18n}} {first}
                         {{'DOSSIER.PAGINATOR.MESSAGE.A' | translateI18n}} {last} {{'DOSSIER.PAGINATOR.MESSAGE.SUR' | translateI18n}} {totalRecords}"></app-pagination>

        <button type="button" pButton pRipple icon="pi pi-file-excel" (click)="exportExcel()"
                class="p-button-sm p-button-success m-auto ml-0 mr-0"
                pTooltip="XLS" tooltipPosition="top"></button>
      </div>

    </div>-->

    <div *ngIf="!listFolderEmpty">
      <div class="cta-container m-3 flex justify-content-between">
        <app-pagination [totalRecords]="response?.totalElements" (eventNextPage)="nextPage($event)"
                        label="{{'DOSSIER.PAGINATOR.MESSAGE.AFFICHAGE' | translateI18n}} {first}
                           {{'DOSSIER.PAGINATOR.MESSAGE.A' | translateI18n}} {last} {{'DOSSIER.PAGINATOR.MESSAGE.SUR' | translateI18n}} {totalRecords}"></app-pagination>

        <button type="button" pButton pRipple icon="pi pi-file-excel" (click)="exportExcel()"
                class="p-button-sm p-button-success m-auto ml-0 mr-0"
                pTooltip="XLS" tooltipPosition="top"></button>

      </div>
    </div>


    <div class="tableaux" id="tableaux" >

      <p-table *ngIf="!listFolderEmpty" [value]="dossiers" dataKey="numeroDossier"
               (selectAllChange)="selectAllChange($event)"
               [selectAll]="selectAll"
               [selection]="selectedDossier"  [scrollable]="true" scrollHeight="{{tableHeight}}px"  [loading]="loading"
               (onRowSelect)="onRowSelect($event)" (onRowUnselect)="onRowUnselect($event)"
               [rowSelectable]="isRowSelectable"
               [lazy]="true" (onLazyLoad)="loadDossier($event)"
               [globalFilterFields]="['numeroDossier','tiers.nomCompletTiers', 'demande.nomCompletDemandeur', 'demande.observation',
               'client.nomComplet', 'adresse.codePostal', 'adresse.ville', 'mission.dateMissionPrevisionnelle', 'mission.typeLivrable.code']">

        <ng-template pTemplate="header">
          <tr>
            <th class="with-check">
              <p-tableHeaderCheckbox [disabled]="!isAllDossierEnConflit"></p-tableHeaderCheckbox>
            </th>
            <th class="min-width-16">
            <th pSortableColumn="numero" class="table-id width-6">{{'DOSSIER.NUM_DOSSIER' | translateI18n}}<p-sortIcon field="numero"></p-sortIcon>
            <app-filter-text typeField="text" field="numero"></app-filter-text></th>

            <th pSortableColumn="tiers" class="is-large">{{'DOSSIER.TIERS' | translateI18n}}<p-sortIcon field="tiers"></p-sortIcon>
              <app-filter-text typeField="text" field="tiers"></app-filter-text></th>

            <th pSortableColumn="demandeur" class="is-large">{{'DOSSIER.DEMANDEUR' | translateI18n}}<p-sortIcon field="demandeur"></p-sortIcon>
              <app-filter-text typeField="text" field="demandeur"></app-filter-text></th>

            <th pSortableColumn="observation" class="is-large">{{'DOSSIER.OBSERVATION_DEMANDEUR' | translateI18n}} <p-sortIcon field="observation"></p-sortIcon>
              <app-filter-text typeField="text" field="observation"></app-filter-text></th>

            <th pSortableColumn="client" class="is-large">{{'DOSSIER.AFFAIRE' | translateI18n}}<p-sortIcon field="client"></p-sortIcon>
              <app-filter-text typeField="text" field="client"></app-filter-text></th>

            <th pSortableColumn="codePostal" class="width-5">{{'DOSSIER.CP' | translateI18n}}<p-sortIcon field="codePostal"></p-sortIcon>
              <app-filter-text typeField="text" field="codePostal"></app-filter-text></th>

            <th pSortableColumn="ville" class="width-10">{{'DOSSIER.ADRESSE_FORM.CITY' | translateI18n}}<p-sortIcon field="ville"></p-sortIcon>
              <app-filter-text typeField="text" field="ville"></app-filter-text></th>

            <th pSortableColumn="mission.dateMissionPrevisionnelle" class="width-date">{{'DOSSIER.ECHEANCE' | translateI18n}}<p-sortIcon field="mission.dateMissionPrevisionnelle"></p-sortIcon>
              <app-filter-text typeField="date" field="mission.dateMissionPrevisionnelle"></app-filter-text></th>

            <th pSortableColumn="typeLivrable" class="width-10">{{'DOSSIER.TYPE_LIVRABLE' | translateI18n}}<p-sortIcon field="typeLivrable"></p-sortIcon>
              <app-filter-text typeField="typeLivrable" field="typeLivrable"></app-filter-text></th>

          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-dossier>
          <tr [pSelectableRow]="dossier" [ngClass]="{'en_conflit' : dossier.bien.enConflit}" [pTooltip]="dossier.bien.enConflit ? infoBulleConflit : ''" tooltipPosition="top">
            <td class="with-check"><p-tableCheckbox [value]="dossier" [disabled]="dossier.bien.enConflit"></p-tableCheckbox></td>
            <td class="affectationButton min-width-16">
              <button pButton pRipple label="Editer" [disabled]="dossier.bien.enConflit" (click)="showEdit(dossier)" icon="pi pi-pencil" iconPos="right" class="p-button-sm p-button-rounded" ></button>
              <button pButton pRipple label="Ouvrir" [routerLink]="['/dossier/fiche', dossier.idDossier]"   icon="pi pi-eye" iconPos="right" class="p-button-sm p-button-rounded" ></button>
            </td>
            <td class="table-id width-6">{{dossier.numeroDossier}}</td>
            <td class="is-large">{{dossier.tiers?.nomCompletTiers}}</td>
            <td class="is-large">{{dossier.demande?.nomCompletDemandeur}}</td>
            <td class="is-large" [pTooltip]="dossier.demande?.observation" tooltipPosition="bottom">
              {{dossier.demande?.observation ? dossier.demande?.observation.substring(0,15) + "..." : dossier.demande?.observation}}</td>
            <td class="is-large">{{dossier.client?.nomComplet }}</td>
            <td class="width-5">{{dossier.adresse?.codePostal}}</td>
            <td class="width-10">{{dossier.adresse?.ville}}</td>
            <td class="width-date">{{dossier.mission?.dateMissionPrevisionnelle | date : 'dd/MM/yyyy'}}</td>
            <td class="width-11">{{dossier.mission?.typeLivrable?.code | typeLivrable}}</td>
          </tr>
        </ng-template>
          <ng-template pTemplate="footer" class="tfooter">
            <div class="pt-3 tfooter" [hidden]="isAffecter" *ngIf="dossiers.length > 0">
              <form novalidate #form="ngForm" (ngSubmit)="valider()">
                <div class="formgroup-inline">
                  <div class="icon-form">
                    <i class="pi pi-reply" style="transform: scaleX(-1) rotate(180deg);"></i>
                  </div>

                  <div class="field">
                    <p-autoComplete [(ngModel)]="selectedExpert" [showEmptyMessage]="true" name="selectedExpert"
                                    [suggestions]="filteredExpert"
                                    (completeMethod)="searchExpertUser($event)"
                                    emptyMessage="{{'DOSSIER.MESSAGE.AUCUN_RESULTAT' | translateI18n}}"
                                    [minLength]="1"
                                    appendTo="body"
                                    field="nomComplet"
                                    placeholder="{{'PLACEHOLDER.EXPERT' | translateI18n}}"
                                    class="inputfield w-full" [size]="30" [disabled]="isAffecter">
                      <ng-template let-expert pTemplate="item">
                        <span>{{ expert.nomComplet}}</span>
                      </ng-template>
                    </p-autoComplete>
                  </div>

                  <div class="field">
                    <p-dropdown [(ngModel)]="selectedAssistantes" name="selectedAssistantes"
                                    [options]="assistantUser"
                                    placeholder="{{'PLACEHOLDER.ASSISTANTE' | translateI18n}}"
                                    emptyMessage="{{'DOSSIER.MESSAGE.AUCUN_RESULTAT' | translateI18n}}"
                                    optionLabel="nomComplet"
                                    appendTo="body"
                                    class="inputfield w-full" [disabled]="isAffecter">

                    </p-dropdown>
                  </div>

                  <button pButton type="submit" label="{{'BUTTON_LABEL.AFFECTER' | translateI18n}}" class="p-button-sm uppercase" icon="pi pi-check"
                          iconPos="right" [disabled]="!selectedExpert || !selectedAssistantes"></button>

                </div>

                <a class="button-rech-avance" (click)="showAffectationRechercheAvanceeExpert()" style="cursor: pointer">{{'BUTTON_LABEL.ADVANCED_SEARCH' | translateI18n}}</a>

              </form>

            </div>
          </ng-template>
      </p-table>
    </div>

    <app-other-folder-image [listFolderEmpty]="listFolderEmpty"></app-other-folder-image>

  </div>

</main>

<ngx-spinner type="ball-scale-multiple"><p-progressSpinner></p-progressSpinner></ngx-spinner>

