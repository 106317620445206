import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'iconDesserte'
})
export class IconDessertePipe implements PipeTransform {

  transform(value: string, ...args: unknown[]): unknown {
    switch (value) {
      case 'DESSERTEAER' :
        return 'icon-avion.svg';
      case 'DESSERTEMET' :
        return 'icon-metro.svg';
      case 'DESSERTERER' :
        return 'icon-RER.svg';
      case 'DESSERTEBUS' :
        return 'icon-bus.svg';
      case 'DESSERTETRAI' :
        return 'icon-tgv.svg';
      case 'DESSERTETRAM' :
        return 'icon-tram.svg';
      default :
        return;
    }
  }

}
