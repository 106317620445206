import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {AvisValeur} from "../models/avis-valeurs/avis-valeur";
import { runtimeEnvironment } from 'src/environments/runtime-environment';

@Injectable({
  providedIn: 'root'
})
export class AvisValeurService {

  baseUrl = runtimeEnvironment.backEndPoint + "api/avis-valeur/";

  constructor(private http: HttpClient) {}

  /**
   * Recupère un dossier par son id
   * @param idDossier
   */
  getDosierById(idDossier : number) : Observable<AvisValeur> {
    return this.http.get<AvisValeur>(this.baseUrl +"edition/dossier/" + idDossier);
  }

  /**
   * Récupère tous les type de valeur
   */
  getAllParamTypeValeur() : Observable<any[]> {
    return this.http.get<any[]>(this.baseUrl +"param/all-param-type-valeur");
  }

  /**
   *
   */
  getAllParamOccupation() : Observable<any[]> {
    return this.http.get<any[]>(this.baseUrl +"param/all-param-occupation");
  }

  /**
   *
   */
  getAllParamTypeRegimej() : Observable<any[]> {
    return this.http.get<any[]>(this.baseUrl +"param/all-param-type-regime");
  }

  /**
   *
   */
  getAllTypeBiens() : Observable<any[]> {
    return this.http.get<any[]>(this.baseUrl +"param/all-ref-type-biens");
  }

  /**
   *
   */
  getAllParamSurface() : Observable<any[]> {
    return this.http.get<any[]>(this.baseUrl +"param/all-param-surface");
  }

  /**
   *
   * @param avisValeur
   */
  updateAvisValeur(avisValeur : AvisValeur) : Observable<AvisValeur> {
    return this.http.post<AvisValeur>(this.baseUrl +"edition/update", avisValeur);
  }

  /**
   * @param idDossier
   */
  sendForValidation(idDossier : number) : Observable<Boolean> {
    return this.http.get<Boolean>(this.baseUrl +"send-for-validation/" + idDossier);
  }

  generationAvisDeValeur(idDossier : number) : Observable<AvisValeur> {
    return this.http.get<AvisValeur>(this.baseUrl + "generer/" +  idDossier);
  }


  /**
   * Récupère la liste des regimes fiscaux locatif
   */
  getAllRegimeFiscalLocatif() : Observable<any[]> {
    return this.http.get<any[]>(this.baseUrl +"param/all-regime-fiscal-locatif");
  }

  //

  getAllRegimeFiscalVenal() : Observable<any[]> {
    return this.http.get<any[]>(this.baseUrl +"param/all-regime-fiscal-venal");
  }

  /**
   *  Récupère la liste des periodes loyers
   */
  getPeriodiciteLoyer() : Observable<any[]> {
    return this.http.get<any[]>(this.baseUrl +"param/all-periodicite-loyer");
  }

  getAllMethodeValorisation() : Observable<any[]> {
    return this.http.get<any[]>(this.baseUrl +"param/all-methode-valorisation");
  }

  /**
   *
   */
  getAllDelaiCommercialisation() : Observable<any[]> {
    return this.http.get<any[]>(this.baseUrl +"param/all-delai-commercialisation");
  }

  /**
   *
   */
  getAllLiquidite() : Observable<any[]> {
    return this.http.get<any[]>(this.baseUrl +"param/all-liquidite");
  }

  /**
   *
   */
  getAllVilleImpl() : Observable<any[]> {
    return this.http.get<any[]>(this.baseUrl +"param/all-villes-impl");
  }
}
