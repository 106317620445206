import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {DatePipe} from "@angular/common";
import {ConfirmationService, Message, MessageService} from "primeng/api";
import {DocumentService} from "../service/document.service";
import {Document} from "../models/document/document.model";
import {TranslateService} from "src/app/shared/translate/translate.service";
import {CreationDemandeObservableService} from "../../shared/observable/creation-demande-observable.service";
import {Subscription} from "rxjs";
import {Byte} from "@angular/compiler/src/util";
import {LocalStorageService} from "../service/local-storage/local-storage.service";
import {User} from "../models/user/user.model";
import {AppSettings, Settings} from "../../app.settings";
import {ProfilesUser} from "../models/user/profiles-user";
import {UtilsFonction} from "../../shared/providers/utils-fonction";
import {StatutDossier} from "../models/enum/statut-dossier";
import {UserService} from "../service/user/user.service";
import {UtilsObservableService} from "../../shared/observable/utils-observable.service";
import {CategorieDocument} from "../models/document/categorie-document";
import {DomSanitizer} from "@angular/platform-browser";
import {ModificationDocument} from "../models/document/modification-document";
import {NgxSpinnerService} from "ngx-spinner";
import {Router} from "@angular/router";

@Component({
  selector: 'app-liste-document',
  templateUrl: './liste-document.component.html',
  styleUrls: ['./liste-document.component.scss']
})
export class ListeDocumentComponent implements OnInit {

  @Input() idBien: number;
  @Input() idDossier: number;
  @Input() statutDossier: string;
  @Input() tierID : number;
  @Input() expertID : number;
  @Input() verouillerUpload : boolean;
  @Input() verouillerExpert : boolean;

  @Input()
  allPictures : boolean = true;

  @Output() newItemEvent = new EventEmitter<Document[]>();

  listeDocuments: Document[] = [];

  test : any[];

  subscription : Subscription;

  filesNameDuplicate : string[] = [];

  files : Document[] = [];

  user : User;

  categoriesDocument : CategorieDocument[];

  public settings: Settings;
  totalRecords : number = 0;
  message : Message[];

  allowedToDelete : boolean;

  profiles : ProfilesUser[];

  constructor(
              private datePipe: DatePipe,
              private messageService: MessageService,
              private confirmation: ConfirmationService,
              private service: DocumentService,
              private translate : TranslateService,
              private fileObservable : CreationDemandeObservableService,
              private userStoragre : LocalStorageService,
              public appSettings : AppSettings,
              private userService : UserService,
              private utilsObservableService : UtilsObservableService,
              private sanitizer: DomSanitizer,
              private spinnerService: NgxSpinnerService,
              private router : Router
  ) {
    this.settings = this.appSettings.settings;

  }

  ngOnInit(): void {
    this.getUserConnected();
    this.getProfilUserConnected();
    this.getCategoriesDocument();
    this.chargerTableau();
  }

  async getUserConnected() {
    this.user = await this.userService.getUserConnectedByToken();
  }

  async getProfilUserConnected() {
    this.profiles = await this.userService.getUserConnectedProfilesById();
  }

  getCategoriesDocument() {
    this.categoriesDocument = [];
    this.service.getCategoriesDocument().subscribe(data => {
      this.categoriesDocument = data;
    })
  }

  chargerTableau() {
    if(this.idBien) {
      this.service.getDocumentsDossier(this.idBien, this.allPictures).subscribe(data => {
        if(data) {
          this.listeDocuments = data;
          this.listeDocuments.forEach(d => this.creerLienImage(d));
          this.totalRecords = this.listeDocuments.length;
          this.utilsObservableService.sendDocuments(this.listeDocuments);
        }

      });
    }else {
      this.sendDocumentFileCreation();
    }
  }

  creerLienImage(document: Document) {
    if (document.contenu) {
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        document.lienImage = this.getSantizeUrl(reader.result as string);
      }, false);
      reader.readAsDataURL(this.b64toBlob(document.contenu, document.type) );
    }
  }

  b64toBlob(contenu: string, type: string) {

    let byteString = atob(contenu);
    let ab = new ArrayBuffer(byteString.length);
    let ia = new Uint8Array(ab);

    for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: type });
  }

  getSantizeUrl(url : string) {
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }

  confirmerSuppression(document: Document) {

    this.confirmation.confirm({
      message: this.translate.getElement("DOCUMENT.SUPPRESSION.CONFIRMATION"),
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.deleteDocument(document.guid, document.nom);
      }
    })
  }

  downloadDocument(uidDocument: string, filename: string) {
    this.messageService.clear();
    this.service.downloadDocument(uidDocument, null).subscribe(data => {
      if (data != null) {
        this.openFile(data, filename);
      }
    }, error => {
      this.message = [ {severity:'error', summary :  this.translate.getElement("DOCUMENT.TELECHARGEMENT.ERROR")}];
    })
  }

  openFile(data: any, filename: string) {
    let dataType = data.type;
    let binaryData = [];
    binaryData.push(data);
    let downloadLink = document.createElement('a');
    downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, {type: dataType}));
    if (filename)
        downloadLink.setAttribute('download', filename);
    document.body.appendChild(downloadLink);
    downloadLink.click();
    downloadLink.parentNode.removeChild(downloadLink);
  }

  deleteDocument(uidDocument: string, fileName? : string) {
    this.spinnerService.show();
    this.messageService.clear();
    if(uidDocument) {
      this.service.deleteDocument(uidDocument, this.idDossier).subscribe(data => {
        if (data) {
          this.message = [{severity: 'success', summary: this.translate.getElement("DOCUMENT.SUPPRESSION.SUCCESS")}];
          this.chargerTableau();
          location.reload();
        } else {
          this.message = [{severity: 'error', summary: this.translate.getElement("DOCUMENT.SUPPRESSION.ERROR")}];
        }
        this.spinnerService.hide();
      }, error => {
        this.message = [{severity: 'error', summary: this.translate.getElement("DOCUMENT.SUPPRESSION.ERROR")}];
        this.spinnerService.hide();
      })
    }
    else if(fileName) {
      this.removeFileFromListDocumentArray(fileName, this.listeDocuments);
      this.spinnerService.hide();
    }
  }

  getDateDocument(document:Document): string {
    return this.datePipe.transform(document.auditDateCreation, "dd/MM/yyyy");
  }

  addDocument(documents: Document[]): void {
    this.chargerTableau();
  }


  sendDocumentFileCreation() {
    this.subscription = this.fileObservable.onFiles().subscribe(data  => {
      if (data) {
        this.filesNameDuplicate = [];
        data.map(file => {

          const document : Document = new Document();
          document.nom = file.name;
          document.taille = this.formatBytesToKoct(file.size);
          document.auditDateCreation = new Date;
          document.origine = this.profiles ?  this.profiles.join() : null;
          document.file = file;

          this.fileNameExist(document.nom) ?  this.filesNameDuplicate.push(document.nom) : this.listeDocuments.push(document);

        });

        this.listeDocuments = [...this.listeDocuments]

        this.fileObservable.sendFilesDocuments(this.listeDocuments);

        if(this.filesNameDuplicate.length > 0) {
          this.message = [{
            severity: 'warn',
            summary: this.translate.getElement("DOCUMENT.UPLOAD.MESSAGE.ERROR.DOCUMENTS_EXISTE", this.filesNameDuplicate.join())
          }];

        }
      }
    });
  }

  formatBytesToKoct(bytes : Byte, decimals = 2) {
    if (bytes === 0) return '0 ko';
    return parseFloat((bytes / Math.pow(1024, Math.floor(Math.log(bytes) / Math.log(1024)))).toFixed(decimals < 0 ? 0 : decimals)) + ' ko';
  }

  /**
   * supprime les fichiers en mode creation
   * @param nom
   * @param documents
   */
   removeFileFromListDocumentArray(nom : string, documents : Document[]) {
     documents.forEach((value,index)=>{
      if(value.nom === nom) documents.splice(index,1);
    });
    this.listeDocuments = [...this.listeDocuments];
    this.fileObservable.sendFilesDocuments(this.listeDocuments);
  }

  /**
   *
   * @param nameFile
   */
  fileNameExist (nameFile : string)  {
      return  this.listeDocuments.some(function(el) {
        return el.nom === nameFile;
      });
  };

  isAuthorized(createurId : number) : boolean {

      if(this.profiles) {
        if(this.profiles.includes(ProfilesUser.ASSISTANTE) ||
          this.profiles.includes(ProfilesUser.ANIMATEUR_RESEAU) ||
          this.profiles.includes(ProfilesUser.ADMINISTRATEUR)||
          this.profiles.includes(ProfilesUser.ADMINISTRATEUR_TECHNIQUE)
        ) {
          if(this.verouillerUpload) return false;
          return (this.statutDossier === StatutDossier.A_AFFECTER || this.statutDossier === StatutDossier.ENCOURS || this.statutDossier === StatutDossier.D_ATTENTE_V1);
        }
        if(this.user && createurId) {
          if(createurId === this.user.id) {
            if(this.profiles.includes(ProfilesUser.AGENT)){
              if(!this.statutDossier && !this.verouillerUpload) {
                return true;
              }
              return (this.statutDossier === StatutDossier.A_AFFECTER || this.statutDossier === StatutDossier.ENCOURS);
            }else if(this.profiles.includes(ProfilesUser.EXPERT_EXTERNE) || this.profiles.includes(ProfilesUser.EXPERT_INTERNE)) {
              if (this.verouillerExpert || this.verouillerUpload) {
                return false;
              }
              return this.statutDossier === StatutDossier.ENCOURS;
            }
          }
        }
        //Pour autorisé la suppression du document à la création de la demande
        else if(this.profiles.includes(ProfilesUser.AGENT) && !this.statutDossier && !this.verouillerUpload) {
            return true;
        }
      }
      return false;
  }

  estImage(type: string) {
    return type != null && type.startsWith("image");
  }

  onRowEditInit(document: Document) {
    if (document.categorie) {
      document.categorie = this.categoriesDocument.find(cat => cat.id===document.categorie.id);
    }
  }

  onRowEditSave(document: Document) {
    var payload: ModificationDocument = new ModificationDocument();
    payload.idDocument = document.id;
    payload.commentaire = document.commentaire;
    payload.idDossier =  this.idDossier;
    this.service.modifierDocument(payload).subscribe(data => {
      document = data;
      this.message = [{severity: 'success', summary: this.translate.getElement("DOCUMENT.MODIFICATION.SUCCESS")}];
    }, error => {
      this.message = [{severity: 'error', summary: this.translate.getElement("DOCUMENT.MODIFICATION.ERROR")}];
    });
  }

  getLibelleCategorie(document: Document) : string {
    return document.categorie ? document.categorie.libelle : "--";
  }

}
