<div class="p-input-icon-left w-full">
  <i class="pi pi-map-marker"  style="font-size: 1.25em;"></i>
  <input type="text"
         [disabled]="!displayMapGoogle"
         pInputText id="autocompleteInput"
         [(ngModel)]="adresseFormate"
         class="inputfield w-full"
         #addresstext
  />
</div>

