import {Injectable} from '@angular/core';
import {RestDatasource} from "./rest.datasource";

@Injectable({
  providedIn: 'root'
})
export class BienService {

  constructor(private datasource : RestDatasource) { }

  getAllParamTypeBien()  {
    return this.datasource.getAllParamTypeBien();
  }

  getAllEtageBienImmeubleByAdressPrincipal() {
    return this.datasource.getAllEtageBienImmeubleByAdressPrincipal();
  }
}
