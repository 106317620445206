import {Component, OnInit} from '@angular/core';
import {MonitoringService} from "./monitoring.service";
import {MessageService, TreeNode} from "primeng/api";

@Component({
  selector: 'app-monitoring',
  templateUrl: './monitoring.component.html',
  styleUrls: ['./monitoring.component.scss']
})
export class MonitoringComponent implements OnInit {

  files!: TreeNode[];
  selectedFile!: TreeNode;

  constructor(private monitoringService: MonitoringService, private messageService: MessageService) {
  }

  ngOnInit(): void {
    this.listFiles('');
  }

  listFiles(path: string): void {
    this.monitoringService.listFiles(path).subscribe(
      (data: any) => this.files = data,
      (error: any) => console.error(error)
    );
  }



  nodeSelect(event: any) {
        this.downloadFile(this.buildPath(event.node))
  }



  downloadFile(path: string): void {
    this.monitoringService.downloadFile(path).subscribe(
      (data: Blob | MediaSource) => {
        const url = window.URL.createObjectURL(data);
        const a = document.createElement('a');
        a.href = url;
        a.download = path.substring(path.lastIndexOf('/') + 1);
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
        // this.messageService.add({severity: 'info', summary: 'Fichier télécharger', detail: path});

      },
      (error: any) => {
        console.error(error)
        this.messageService.add({severity: 'error', summary: 'Fichier non télécharger', detail: path});

      }
    );
  }

  buildPath(node: any) {
    let path = node.label;
    let parentKey = this.getParentKey(node.key);
    while (parentKey) {
      const parentNode = this.findNodeByKey(this.files, parentKey);
      if (parentNode) {
        path = parentNode.label + '/' + path;
        parentKey = this.getParentKey(parentNode.key);
      } else {
        parentKey = null;
      }
    }
    return path;
  }

  getParentKey(key: any): string | null {
    const parts = key.split('-');
    if (parts.length > 1) {
      parts.pop();
      return parts.join('-')
    }
    return null;

  }

  findNodeByKey(nodes: any, key: any): any | null {
    for (const node of nodes) {
      if (node.key === key)
        return node;

      if (node.children && node.children.length > 0) {
        const foundNode = this.findNodeByKey(node.children, key);
        if (foundNode) {
          return foundNode;
        }
      }
    }
  }
}
