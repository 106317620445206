import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import { runtimeEnvironment } from 'src/environments/runtime-environment';
import {IconStatutDossierPipe} from "../pipes/icon-statut-dossier.pipe";
import {UtilsFonction} from "../providers/utils-fonction";
import {StatutDossier} from "../../core/models/enum/statut-dossier";
import {ConfigConstants} from "../../core/models/enum/config-constants";

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnChanges {

  @Input() idFonctionnel : string;
  @Input() titre: string;
  @Input() sstitre : string ;
  @Input() state: string;
  @Input() stateClass: string;
  @Input() id: string;
  @Input() signataire : string;
  @Input() etatEnvoi : string;

  @Input() sstitre2 : string ;

  display: boolean;
  content : string;

  displayFile: boolean;

  sstitrespan : string ='';

  envClass : string;
  livrableClass : string;



  constructor(private iconStatutPipe : IconStatutDossierPipe) { }

  ngOnInit(): void {

    if(this.id) {
      this.displayFile = true;
    }

    if (!this.titre) {
      this.titre = 'Titre de la page';
    }

    switch (runtimeEnvironment.name) {
      case "prod_internet":
      case "prod_intranet":
        this.envClass = "prod";
        break;
      default:
        this.envClass = runtimeEnvironment.name;
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.state) {
      let icon =  this.iconStatutPipe.transform(this.state, this.etatEnvoi);
      this.sstitrespan = '<span class="inc-icon ' +  icon +' is-small"></span>';
      this.stateClass = "progress";
      this.livrableClass =  this.stateClass
      if(this.state === StatutDossier.D_ATTENTE_V1 && this.etatEnvoi === ConfigConstants.ETAT_ENVOI_PROJET) {
        this.stateClass = "icon-statut-" + this.state + "-etatpeProjet";
      }
    }

    this.display = true;
    if(this.sstitre) {
      this.content = this.sstitrespan + this.sstitre;
    }

    if(this.signataire) {
      this.sstitre2 = this.sstitre2 + ' / ' + this.signataire;
    }



    if(this.idFonctionnel) {
      this.titre = '<span class="focus">' + this.idFonctionnel + '</span>' + ' ' + this.titre;
    }
  }

}
