import {ChangeDetectorRef, Component, Input, OnDestroy, OnInit} from '@angular/core';
import {AbstractControl, FormBuilder, FormGroup, FormGroupDirective, Validators} from "@angular/forms";
import {DialogService, DynamicDialogRef} from "primeng/dynamicdialog";
import {MessageService} from "primeng/api";
import {TranslateService} from "../../../../shared/translate/translate.service";
import {DatePipe} from "@angular/common";
import {DateFormatPipePipe} from "../../../../shared/pipes/date-format-pipe.pipe";
import {RapportExpertise} from "../../../models/rapport-expertise/rapport-expertise";
import {RapportExpertiseService} from "../../../service/rapport-expertise.service";
import {ConfigSizeInput} from "../../../../shared/providers/config-size-input";
import {RapportExpertiseObservableService} from "../../../../shared/observable/rapport-expertise-observable.service";
import {Subscription} from "rxjs";
import {ConfigConstants} from 'src/app/core/models/enum/config-constants';

@Component({
  selector: 'app-synthese-mission',
  templateUrl: './synthese-mission.component.html',
  styleUrls: ['./synthese-mission.component.scss']
})
export class SyntheseMissionComponent implements OnInit, OnDestroy {

  @Input()
  formGroupName: string;

  @Input()
  submitted : boolean;

  @Input()
  submittedRequiredSynthese : boolean;

  @Input()
  rapport : RapportExpertise;

  form: FormGroup;

  // Permet de l'appeler depuis le HTML
  ConfigConstants = ConfigConstants;

  naturesVisites : any[];
  situationsBien : any[];
  etatsEntretien : any[];
  delaiCom : any[];
  liquidites : any[];
  configSizeInput : ConfigSizeInput;

  typeValeur : any;

  typeSurface : any;

  surface : number;

  delaiCommercialisation : any;

  displayEtatEntretienInterne : boolean;

  valeurVenal : any;
  valeurApresTravaux : any;
  valeurLocative : any;
  valeurFuturAchevement : any;
  valeurVenteForcee : number;

  fullNameExpert : string;

  private subscriptionSurface : Subscription;
  private subscriptionTypeSurface: Subscription;
  private subscriptionValeurVenale : Subscription;
  private subscriptionValeurVenteForcee : Subscription;
  private subscriptionValeurFutureAchevement : Subscription;
  private subscriptionValeurApresTraveaux : Subscription;
  private subscriptionValeurLocative : Subscription;
  private subscriptionTypeValeur : Subscription;

  constructor(
    private fb : FormBuilder,
    public dialogService: DialogService,
    public ref: DynamicDialogRef,
    private rootFormGroup: FormGroupDirective,
    private cdref: ChangeDetectorRef,
    private message: MessageService,
    private translate : TranslateService,
    private datePipe: DatePipe,
    private rapportExpertiseService : RapportExpertiseService,
    private dateFormatPipePipe: DateFormatPipePipe,
    private rapportExpertiseObservableService : RapportExpertiseObservableService,
  ) {
    this.configSizeInput = new ConfigSizeInput();
  }

  ngOnInit(): void {
    this.form = this.rootFormGroup.control.get(this.formGroupName) as FormGroup;

    this.getAllNatureVisite();
    this.getAllEtatEntretiens();
    this.getAllSituationBiens();
    this.getAllLiquidite();
    this.getAllDelaiCommercialisation();

    this.subscriptionTypeValeur = this.rapportExpertiseObservableService.getTypeValeur().subscribe( data => {
      if(data) {
        this.initTypeValeur();
        this.getTypeValeur(data);
        this.requiredValueIfTypeValeurContainsValeurVenal(data);
      }
    });

    this.subscriptionTypeSurface = this.rapportExpertiseObservableService.getTypeSurface().subscribe( data => {
      if(data) {
        this.typeSurface = data;
      }
    });

    this.subscriptionSurface = this.rapportExpertiseObservableService.getSurface().subscribe( data => {
      if(data) {
        this.surface = data;
      }else {
        this.surface = this.rapport?.valueSurface;
      }
    });

    if(this.form?.value?.typeVisite) {
      this.displayEtatEntretienInterne = this.form?.value?.typeVisite?.idNatureVisite !== 2;
    }


  }

  ngOnDestroy() {
    this.subscriptionTypeValeur?.unsubscribe();
    this.subscriptionSurface?.unsubscribe();
    this.subscriptionTypeSurface?.unsubscribe();
    this.subscriptionValeurVenale?.unsubscribe();
    this.subscriptionValeurVenteForcee?.unsubscribe();
    this.subscriptionValeurFutureAchevement?.unsubscribe();
    this.subscriptionValeurApresTraveaux?.unsubscribe();
    this.subscriptionValeurLocative?.unsubscribe();
  }

  initTypeValeur() {

    this.subscriptionValeurVenale = this.rapportExpertiseObservableService.getValeurVenale().subscribe( data => {
      if(data) {
        this.valeurVenal = data;
      }
    });

    this.subscriptionValeurLocative = this.rapportExpertiseObservableService.getValeurLocative().subscribe( data => {
      if(data) {
        this.valeurLocative = data;
      }
    });

    this.subscriptionValeurApresTraveaux =  this.rapportExpertiseObservableService.getValeurApresTraveaux().subscribe( data => {
      if(data) {
        this.valeurApresTravaux = data;
      }
    });

    this.subscriptionValeurFutureAchevement =  this.rapportExpertiseObservableService.getValeurFutureAchevement().subscribe( data => {
      if(data) {
        this.valeurFuturAchevement = data;
      }
    });

    this.subscriptionValeurVenteForcee = this.rapportExpertiseObservableService.getValeurVenteForcee().subscribe( data => {
        this.valeurVenteForcee = data;
    });
  }

  async getAllNatureVisite() {
    this.naturesVisites = await this.rapportExpertiseService.getAllNatureVisite();
  }

  async getAllSituationBiens() {
    this.situationsBien = await this.rapportExpertiseService.getAllSituationBiens();
  }

  async getAllEtatEntretiens() {
    this.etatsEntretien = await this.rapportExpertiseService.getAllEtatEntretiens();
  }

  getAllDelaiCommercialisation()  {
    this.rapportExpertiseService.getAllDelaiCommercialisation().subscribe(
      data => {
        if(data) {
          this.delaiCom = data;
        }
      },error => {
      }
    )
  }

  getAllLiquidite()  {
    this.rapportExpertiseService.getAllLiquidite().subscribe(
      data => {
        if(data) {
          this.liquidites = data;
        }
      },error => {
      }
    )
  }

  getTypeValeur(valeur : any)  {
    this.typeValeur = {idTypeValeur : valeur.idTypeValeur, libelle : valeur.libelle, };
    if(valeur) {
      switch (this.typeValeur?.idTypeValeur) {
        case 1 :
          this.typeValeur['label1'] = 'Valeur vénale';
          return this.typeValeur;
        case 2 :
          this.typeValeur['label1'] = 'Valeur locative';
          return this.typeValeur;
        case 3 :
          this.typeValeur['label1'] = 'Valeur vénale';
          this.typeValeur['label2'] = 'Valeur après travaux';
          return this.typeValeur;
        case 4 :
          this.typeValeur['label1'] = 'une valeur en état futur d\'achèvement';
          return this.typeValeur;
        case 5 :
          this.typeValeur['label1'] = 'Valeur vénale';
          this.typeValeur['label2'] = 'Valeur locative';
          return this.typeValeur;
        default :
          return null;

      }
    }
  }

  changeTypeVisite(value : any) {
    this.displayEtatEntretienInterne = value && value?.idNatureVisite !== 2;
    if(this.displayEtatEntretienInterne) {
      this.form.controls['entretienInterne'].setValidators(Validators.required);
    }else {
      this.form.controls['entretienInterne'].setValidators(null);
    }
    this.form.controls['entretienInterne'].updateValueAndValidity();
  }

  changeLiquidite(event: any) {
    this.rapportExpertiseObservableService.sendLiquidite(event.value);
  }

  changeDelaiCommercialisation(event: any) {
    this.rapportExpertiseObservableService.sendDelaiCommercialissation(event.value);
  }

  get f(): { [key: string]: AbstractControl } {
    return this.form.controls;
  }

  requiredValueIfTypeValeurContainsValeurVenal(typevaleur : any) {
    if(
      (typevaleur.idTypeValeur === 1 || typevaleur?.idTypeValeur === 3 || typevaleur?.idTypeValeur === 5)
      && (!this.rapport?.etatEnvoi || this.rapport?.etatEnvoi === ConfigConstants.ETAT_ENVOI_INIT)
    ) {
      this.form.controls['liquidite'].setValidators(Validators.required);
      this.form.controls['delaiCommercialisation'].setValidators(Validators.required);
      this.form.controls['liquidite'].updateValueAndValidity();
      this.form.controls['delaiCommercialisation'].updateValueAndValidity();
    }
    else {
      this.form.controls['liquidite'].setValidators(null);
      this.form.controls['delaiCommercialisation'].setValidators(null);
      this.form.controls['liquidite'].updateValueAndValidity();
      this.form.controls['delaiCommercialisation'].updateValueAndValidity();
    }
  }

  nomExpertChange() {

    if(this.form?.value.nomExpert) {
      this.fullNameExpert =  (this.form?.value.nomExpert).toUpperCase() + " ";
    }

    if(this.form?.value.prenomExpert) {
      this.fullNameExpert = this.fullNameExpert +  this.form?.value.prenomExpert;
    }

    this.rapportExpertiseObservableService.sendFullNameExpert(this.fullNameExpert);
  }
}
