<main class="layout-wrapper">
  <app-navigation ></app-navigation>
  <app-header titre="{{user?.prenom}} {{user?.nom}}"
              sstitre="{{'HEADER.SSTITRE_PROFIL_OR_DASHBORD' | translateI18n}} : {{user?.dateDernierConnexion | date:'d MMMM y HH:mm'}}">
  </app-header>
  <div class="layout-main">

    <div class="mr-2">

      <p-message *ngIf="messageParamInfoExpFR != null" [severity]="'custom'" [text]="messageParamInfoExpFR" class="p-message"></p-message>

      <p-messages severity="info" *ngIf="nbDoc > 0" >
        <ng-template pTemplate>
          <!--TODO : renseigner l'url dans le routerlink-->
          <div class="ml-2"> {{'DASHBORD.MESSAGE.INFO.INFO_DOC_TELECHARGER' | translateI18n:nbDoc.toString()}}
            <a (click)="showDocumentsNonLus()" style="cursor: pointer" class="voir-doc"> {{'DASHBORD.MESSAGE.INFO.LABEL_LINK_NB_DOC' | translateI18n}}</a>
          </div>
        </ng-template>
      </p-messages>

      <div  *ngIf="successCreationDemande"  class="col-12" >
        <p-messages [(value)]="successCreationDemande" [escape]="false" [enableService]="false"></p-messages>
      </div>

    </div>

    <div class="page" *ngIf="role && !role.includes('ADMINISTRATEUR') && !role.includes('ADMINISTRATEUR_TECHNIQUE')">
      <app-quick-serch [showContrat]="role.includes('ASSISTANTE') || role.includes('ANIMATEUR_RESEAU') || role.includes('AGENT')"
                        [lienRechercheRapide]="lienRechercheRapide"></app-quick-serch>
    </div>

    <div class="grid py-5" *ngIf="role">

      <div *ngIf="role && (role.includes('ADMINISTRATEUR') || role.includes('ADMINISTRATEUR_TECHNIQUE') || role.includes('ASSISTANTE') || role.includes('ANIMATEUR_RESEAU'))"
           class="col-12 sm:col-6 md:col-6 lg:col-4 xl:col-3"
           routerLink="/dossier/list-folder-to-assign" style="cursor: pointer">
        <app-dashboard-card type="affectation" icon="A_AFFECTER" titre="{{'DASHBORD.AFFECTATION' | translateI18n}}" [compteur]="statsDossier?.affecter"></app-dashboard-card>
      </div>

      <div *ngIf="role && (role.includes('ADMINISTRATEUR') || role.includes('ADMINISTRATEUR_TECHNIQUE') || role.includes('ASSISTANTE') || role.includes('ANIMATEUR_RESEAU'))"
           class="col-12 sm:col-6 md:col-6 lg:col-4 xl:col-3"
           routerLink="/dossier/recherche/gestionnaire" [queryParams]="{typeRecherche: 'dashboardCard', etatEnvoi : 'ETATLIVRABLEPRJ'}" style="cursor: pointer">
        <app-dashboard-card type="affectation" titre="{{'DASHBORD.PROJET_ENVOYE' | translateI18n}}" icon="D_ATTENTE_V1" etatEnvoi="ETATLIVRABLEPRJ" [compteur]="statsDossier?.projetEnvoyes"></app-dashboard-card>
      </div>

      <div *ngIf="role && (role.includes('ADMINISTRATEUR') || role.includes('ADMINISTRATEUR_TECHNIQUE') || role.includes('ASSISTANTE') || role.includes('ANIMATEUR_RESEAU'))"
           class="col-12 sm:col-6 md:col-6 lg:col-4 xl:col-3"
           routerLink="/dossier/recherche/gestionnaire" [queryParams]="{typeRecherche: 'dashboardCard'}" style="cursor: pointer">
        <app-dashboard-card type="affectation" titre="{{'DASHBORD.VALIDATION' | translateI18n}}" icon="D_ATTENTE_V1" [compteur]="statsDossier?.validation"></app-dashboard-card>
      </div>

      <div *ngIf="role && (role.includes('EXPERT_EXTERNE') || role.includes('EXPERT_INTERNE') || role.includes('ADMINISTRATEUR') || role.includes('ADMINISTRATEUR_TECHNIQUE') )"
           class="col-12 sm:col-6 md:col-6 lg:col-4 xl:col-3" style="cursor: pointer"
           routerLink="/dossier/recherche/expert" [queryParams]="{typeRecherche:'dashboardCard'}">
        <app-dashboard-card type="follow" icon="ENCOURS" titre="{{'DASHBORD.DOSSIER_EN_COURS' | translateI18n}}"
                            [compteur]="statsDossier?.dossierEncoursExpert"></app-dashboard-card>
      </div>

      <div  style="cursor: pointer"
            class="col-12 sm:col-6 md:col-6 lg:col-4 xl:col-3" *ngIf="role && !role.includes('AGENT')">
        <app-dashboard-card (click)="showAdvanceSearch()" type="view" icon="RESEARCH" titre="{{'DASHBORD.RECHERCHE_DOSSIER' | translateI18n}}"></app-dashboard-card>
      </div>

      <div *ngIf="role && (role.includes('AGENT') && creerDemande)"
           class="col-12 sm:col-6 md:col-6 lg:col-4 xl:col-3" >
        <app-dashboard-card type="create" icon="ADD" titre="{{'DASHBORD.CREER_DEMANDE' | translateI18n}}" [liens]="lienCreerDemande"></app-dashboard-card>
      </div>

      <div  class="col-12 sm:col-6 md:col-6 lg:col-4 xl:col-3" *ngIf="role && (role.includes('AGENT') || role.includes('ADMINISTRATEUR') || role.includes('ADMINISTRATEUR_TECHNIQUE'))" style="cursor: pointer;">
        <app-dashboard-card type="follow" icon="DEMANDEENCOURS" titre="{{'DASHBORD.DEMANDE_EN_COURS' | translateI18n}}"
                            [compteur]="statsDossier?.demandeEncoursAgent"
                            [routerLink]="['/dossier/demande/suivi']" [queryParams]="{typeRecherche:'dashboardCard'}"></app-dashboard-card>
      </div>

      <div class="col-12 sm:col-6 md:col-6 lg:col-4 xl:col-3">
        <app-dashboard-card type="profil" titre="{{'DASHBORD.PROFIL' | translateI18n}}" icon="PROFIL"
                            sstitre="{{'DASHBORD.SSTITREPROFIL' | translateI18n}}" routerLink="/profil" style="cursor: pointer">
        </app-dashboard-card>
      </div>
      <div *ngIf="role && (role.includes('ADMINISTRATEUR_TECHNIQUE') &&  isInterne && enableAdminTech )"
           class="col-12 sm:col-6 md:col-6 lg:col-4 xl:col-3"
           style="cursor: pointer"
      >
        <app-dashboard-card type="adminTech" icon="PROFIL" titre="{{'DASHBORD.MONITORING' | translateI18n}}" sstitre="{{'DASHBORD.SSMONITORING'|translateI18n}}" routerLink="/monitoring"></app-dashboard-card>
      </div>

    </div>


  </div>

</main>
