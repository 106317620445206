export class BienSurface {
  id : number;
  batEtage : string;
  utilisation : string;
  surface : number;
  coefPonderation : number;
  surfacePondere : number;
  idBien : number;
  idDossier : number;
}
