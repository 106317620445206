import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-other-folder-image',
  templateUrl: './other-folder-image.component.html',
  styleUrls: ['./other-folder-image.component.scss']
})
export class OtherFolderImageComponent implements OnInit {

  @Input()
  listFolderEmpty : boolean;

  @Input()
  displayTitle : boolean = true;

  constructor() { }

  ngOnInit(): void {
  }

}
