<div class="login-page">
</div>
<main class="layout-wrapper h-full" id="main-login">

    <div class="flex justify-content-center m-4" id="logo-bnp-login">
      <img src="../../../assets/images/logo-bnp-valuation.svg" width="200px" />
    </div>

    <div class="layout-main h-full no-nav no-header">

    <div class="grid justify-content-center align-content-center w-full">

      <div class="col-10 is-login-card">

        <div class="flex justify-content-center pb-6">
          <img src="../../../assets/images/logo-valea.svg" width="140px" />
        </div>

        <div class="grid">

          <div class="col-12 md:col-12" *ngIf="env.isInterne && !showCodeInput">
            <div  *ngIf="errorMessageWebsso" >
              <p-messages [(value)]="errorMessageWebsso" [enableService]="false"></p-messages>
            </div>
            <div class="form-container card ">
              <form>
                <div class="form-title text-center">{{'AUTH.FORM.LOGIN.LABEL.SSO_TITLE' | translateI18n}}</div>

                <button pButton type="button" label="{{'AUTH.FORM.LOGIN.LABEL.SSO_BUTTON' | translateI18n}}" class="w-full uppercase" icon="pi pi-check"
                        iconPos="right" (click)="connectionBySSO()"></button>

              </form>
            </div>
          </div>

          <div class="col-12 md:col-12">
            <div  *ngIf="errorMessage" >
              <p-messages [(value)]="errorMessage" [enableService]="false"></p-messages>
            </div>
            <div class="form-container card" *ngIf="!showCodeInput">
              <form novalidate #form="ngForm" (ngSubmit)="ask2fa(form)">

                <div class="field">
                    <label for="login">{{'AUTH.FORM.LOGIN.LABEL.ID' | translateI18n}}</label>
                    <div class="p-input-icon-right w-full">
                      <i class="pi pi-user"></i>
                      <input type="text" pInputText id="login" name="login" [(ngModel)]="login" class="inputfield w-full" required />
                    </div>
                  </div>

                <div class="field">
                  <label for="password">{{'AUTH.FORM.LOGIN.LABEL.PASSWORD' | translateI18n}}</label>
                  <div class="p-input-icon-right w-full">
                    <i class="pi pi-lock"></i>
                    <input type="password" pInputText id="password" name="password" [(ngModel)]="password" class="inputfield w-full" required />
                  </div>

                  <div class="pt-1">
                    <small id="username1-help" >
                      <a [routerLink]="['/auth/mot-de-passe-oublie']">{{'AUTH.FORM.LOGIN.LINK.FORGOT_PASSWORD' | translateI18n}}</a>
                    </small>
                  </div>
                </div>

                <button pButton type="submit" [disabled]="form.invalid" label="{{'AUTH.FORM.LOGIN.LABEL.BUTTON_SUBMIT' | translateI18n}}" class="w-full uppercase"  icon="pi pi-check" iconPos="right"></button>

              </form>
            </div>
        </div>
      </div>
      </div>
    </div>
    </div>

</main>
