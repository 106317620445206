<form [formGroup]="form">

  <h2  id="honoraire">{{'DOSSIER.HT' | translateI18n}}</h2>

  <div class="card">
    <!-- Fieldsets pour structurer des groupes de champs -->
    <fieldset class="grid">

      <!-- Structurer le formulaire ligne par ligne pour préserver l'accessiblité et la tabulation champs à champs -->
      <!-- Ligne formulaire HONORAIRES -->
      <div class="col-12 grid field-line" *ngIf="userConnecte && !userConnecte?.profiles?.includes('EXPERT_EXTERNE')">

        <div class="field col-fixed">
          <label [ngClass]="{'locked' : form.controls.honoraireClient.status === 'DISABLED'}">{{'DOSSIER.HONORAIRE_CLIENT' | translateI18n}}</label>
          <div>
            <p-inputNumber formControlName="honoraireClient" inputId="honoraireClient"  mode="currency" currency="EUR" locale="fr-FR"
                           [size]=10  styleClass="p-inputtext-sm"
                           [ngClass]="{'read-only-form' : form.controls.honoraireClient.status === 'DISABLED' }"
                           (onInput)="onChangeHonoClient($event, 'honoraireClient')">
            </p-inputNumber>
          </div>
        </div>

        <div class="field col-fixed ml-4">
          <label [ngClass]="{'locked' : form.controls.fraisRefacturable.status === 'DISABLED'}">{{'DOSSIER.FRAIS_REFACTURABLE_CLIENT' | translateI18n}}</label>
          <div>
            <p-inputNumber formControlName="fraisRefacturable" inputId="fraisRefacturable"  mode="currency" currency="EUR" locale="fr-FR"
                           [size]=10  styleClass="p-inputtext-sm"
                           [ngClass]="{'read-only-form' : form.controls.fraisRefacturable.status === 'DISABLED' }"
                           (onInput)="onChangeHonoClient($event, 'fraisRefacturable')">
            </p-inputNumber>
          </div>
        </div>

        <div class="field col text-right m-0 col-resultat">

          <div class="data-result">
            <div class="pointille"></div>
            <div class="resultat">
              {{'DOSSIER.TOTAL_REFACTURABLE_CLIENT' | translateI18n}}
              <span>{{totalRefacturable | currency : 'EUR'}}</span>
            </div>
          </div>
        </div>

      </div>

      <!-- Ligne formulaire NEGOCIATION-->
      <div class="col-12 grid field-line" *ngIf="userConnecte && !userConnecte?.profiles?.includes('EXPERT_EXTERNE')">

        <div class="field col-fixed m-0"  >
          <label [ngClass]="{'locked' : form.controls.negociation.status === 'DISABLED'}"> {{'DOSSIER.POURCENT_NEGOCIATION' | translateI18n}}
            <span *ngIf="pourcentNegociationMouse"><i class="pi pi-lock ml-2"></i></span></label>
          <div class="" *ngIf="form.controls.negociation.status !== 'DISABLED'"  >
            <p-dropdown [options]="pourcentageNeg" placeholder="{{'PLACEHOLDER.CHOOSE' | translateI18n}}" id="negociation"
                        formControlName="negociation" name="negociation" optionLabel="libelle" (onChange)="onChangeNegociation($event)"
                        styleClass="p-inputtext-sm" ></p-dropdown>
          </div>

          <div class="read-only-form" *ngIf="form.controls.negociation.status === 'DISABLED'">
            {{form.value?.negociation?.libelle}}
          </div>
        </div>


        <div class="field col text-right m-0 col-resultat">
          <div class="data-result">
            <div class="pointille"></div>
            <div class="resultat">{{'DOSSIER.MONTANT_NEGOCIATION' | translateI18n}} :
              <span>{{montantNegociation | currency : 'EUR'}}</span>
            </div>
          </div>
        </div>

      </div>


      <!-- Ligne formulaire PILOTAGE -->
      <div class="col-12 grid field-line" *ngIf="userConnecte && !userConnecte?.profiles?.includes('EXPERT_EXTERNE')">

        <div class="field col-fixed m-0">
          <label  class="col-fixed" [ngClass]="{'locked' : form.controls.pilotage.status === 'DISABLED'}" >{{'DOSSIER.POURCENT_PILOTAGE' | translateI18n}}</label>
          <div class="">
            <p-inputNumber formControlName="pilotage" inputId="pilotage"  (onInput)="onChangePilotage($event)"
                           [ngClass]="{'read-only-form' : form.controls.pilotage.status === 'DISABLED', 'ng-dirty ng-invalid' : pilotageError}"
                           [size]=10 [min]="0" [maxlength]="4" styleClass="p-inputtext-sm">
            </p-inputNumber>
          </div>
          <small *ngIf="pilotageError" class="p-error block">{{'DOSSIER.MESSAGE.ERROR.POURCENTAGE_PILOTAGE' | translateI18n}}</small>
        </div>

        <div class="field col text-right m-0 col-resultat">

          <div class="data-result">
            <div class="pointille"></div>
            <div class="resultat">{{'DOSSIER.MONTANT_PILOTAGE' | translateI18n}} :
              <span>{{mtPilotage | currency : 'EUR'}}</span>
            </div>
          </div>
        </div>

      </div>

      <div class="field col field-line" *ngIf="form.controls.expertInterne.status !== 'DISABLED' && expertInterneModif" >
        <label  class="col-fixed">{{'DOSSIER.PILOTE' | translateI18n}}</label>
        <div>
          <p-autoComplete formControlName="expertInterne" [showEmptyMessage]="true" name="expert"
                          [suggestions]="filteredExpert"
                          (completeMethod)="searchExpertUser($event)"
                          emptyMessage=" {{'DOSSIER.MESSAGE.AUCUN_RESULTAT' | translateI18n}}"
                          [minLength]="1"
                          field="nomComplet"
                          class="inputfield w-full" [size]="30">
            <ng-template let-expert pTemplate="item">
              <span>{{ expert.nomComplet}}</span>
            </ng-template>
          </p-autoComplete>

        </div>


      </div>

      <!-- Ligne formulaire Honoraire Expert-->
      <div class="col-12 grid field-line" *ngIf="dossier.infoExpertise?.expert">

        <div class="field col-fixed m-0">
          <label [ngClass]="{'locked' : form.controls.honoExpertMontantHT.status === 'DISABLED'}">{{'DOSSIER.HONORAIRES_EXPERT' | translateI18n}}</label>
          <div class="">
            <p-inputNumber formControlName="honoExpertMontantHT" inputId="minmaxfraction" mode="currency" currency="EUR" locale="fr-FR"
                           [ngClass]="{'read-only-form' : form.controls.honoExpertMontantHT.status === 'DISABLED' }"
                           (onInput)="changeHonoraireExpert($event)" [size]=10  styleClass="p-inputtext-sm">
            </p-inputNumber>
          </div>
        </div>

        <div class="field col-fixed ml-4">
          <label [ngClass]="{'locked' : form.controls.fraisExpertMontantHt.status === 'DISABLED'}">{{'DOSSIER.FRAIS_EXPERT' | translateI18n}}</label>
          <div class="">
            <p-inputNumber formControlName="fraisExpertMontantHt" inputId="fraisExpertMontantHt" mode="currency" currency="EUR" locale="fr-FR"
                           [ngClass]="{'read-only-form' : form.controls.fraisExpertMontantHt.status === 'DISABLED' }"
                           (onInput)="changeFraixExpert($event)"  [size]=10  styleClass="p-inputtext-sm">
            </p-inputNumber>
          </div>
        </div>

        <div class="field col text-right m-0 col-resultat">

          <div class="data-result">
            <div class="pointille"></div>
            <div class="resultat">{{'DOSSIER.TOTAL_EXPERT' | translateI18n}} :
              <span>{{totalExpert | currency : 'EUR'}}</span>
            </div>
          </div>
        </div>

      </div>

      <div class="field col-12" *ngIf="dossier?.infoExpertise?.expert">
        <p (mouseover)="calculKilometrage=true" (mouseout)="calculKilometrage=false">
          <a [href]="kiloMettrage" target="_blank">{{'DOSSIER.CALCUL_KILOMETRAGE' | translateI18n}}</a>
        </p>
      </div>

    </fieldset>


  </div>
</form>
