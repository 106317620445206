import {SafeUrl} from "@angular/platform-browser";
import {CategorieDocument} from "./categorie-document";

export class Document {
    id: number;
    guid: string;
    nom: string;
    commentaire: string;
    taille: string;
    auditDateCreation: Date;
    auditCreateur : number;
    origine: string;
    numeroDossier: string;
    numeroContrat: string;
    nomClient: string;
    idLecture: number; // ID de l'objet DocumentLecture (table dos_document_lecture)
    file? : File;
    categorie: CategorieDocument;
    type: string;
    contenu: any;
    lienImage: SafeUrl;
}
