<form [formGroup]="form" >
  <div class="card-large" id="section1">
    <h1 id="elementAppreciation">VI - {{'AVIS_VALEUR.ELEMENTS_APPRECIATION' | translateI18n}}</h1>


    <div class="grid">

        <div class="field col-12 field-line">

          <div class="mb-2">
            <h3 class="m-0 obligatoire" >{{'AVIS_VALEUR.LABEL.ELEMENT_FAVORABLE' | translateI18n}} :</h3>
            <small>{{'AVIS_VALEUR.MESSAGE.MAX_CARACTERE' | translateI18n : '150'}}
              <span [ngClass]="{'is-alert':  f.favorable.hasError('hasError')}">({{nbElementFavorable}} / 150)</span>
            </small>
          </div>
          <div *ngIf="f.favorable.hasError('hasError')">
            <p-messages  [(value)]="msgError"></p-messages>
          </div>
          <p-editor formControlName="favorable" [style]="{'height':'100px'}" name="favorable"  id="favorable" class="w-full"
                    [ngClass]="{'editor-error': (submittedRequired && f.favorable.errors) || f.favorable.hasError('hasError')}"
                    (onTextChange)="elementFavorableTextChange($event)">
            <p-header>
              <app-p-editor-header></app-p-editor-header>
            </p-header>
          </p-editor>

        </div>

        <div class="field col-12 field-line">
          <div class="mb-2">
            <h3 class="m-0 obligatoire" >{{'AVIS_VALEUR.LABEL.ELEMENT_DEFAVORABLE' | translateI18n}} :</h3>
            <small>{{'AVIS_VALEUR.MESSAGE.MAX_CARACTERE' | translateI18n : '150'}}
              <span [ngClass]="{'is-alert':  f.defavorable.hasError('hasError')}">({{nbElementDefavorables}} / 150)</span>
            </small>
          </div>
          <div *ngIf="f.defavorable.hasError('hasError')">
            <p-messages  [(value)]="msgError"></p-messages>
          </div>
          <p-editor formControlName="defavorable" [style]="{'height':'100px'}" name="defavorable"  id="defavorable" class="w-full"
                    [ngClass]="{'editor-error': (submittedRequired && f.defavorable.errors) || f.defavorable.hasError('hasError')}"
                    (onTextChange)="elementdefavorablesTextChange($event)">
            <p-header>
              <app-p-editor-header></app-p-editor-header>
            </p-header>
          </p-editor>

        </div>

    </div>

  </div>
</form>

