<main class="layout-wrapper">

  <app-navigation></app-navigation>

  <app-header [titre]="titleHeader"
              sstitre="{{demandeEncoursAgent}} {{'DOSSIER.DEMANDE.RECHERCHE.HEADER.SSTITRE' | translateI18n}}"
              state="progress"></app-header>

  <div class="layout-main have-table">

    <p-messages key="upload" [escape]=false></p-messages>
    <div  *ngIf="errorMessage" class="mr-4">
      <p-messages [(value)]="errorMessage" [enableService]="false" [escape]="false"></p-messages>
    </div>

    <div class="cta-container m-3 flex justify-content-between flex-wrap">


        <app-quick-serch  [showContrat]="true" [showRechercheAvancee] = "true" rechercheAvanceeData="rechercheDossier" lienRechercheRapide="dossier/demande/suivi"></app-quick-serch>


        <div *ngIf="!listFolderEmpty" class="flex align-items-center">
          <div>
            <app-pagination [totalRecords]="response?.totalElements" (eventNextPage)="nextPage($event)"
            label="{{'DOSSIER.PAGINATOR.MESSAGE.AFFICHAGE' | translateI18n}} {first}
                           {{'DOSSIER.PAGINATOR.MESSAGE.A' | translateI18n}} {last} {{'DOSSIER.PAGINATOR.MESSAGE.SUR' | translateI18n}} {totalRecords}"></app-pagination>
          </div>

          <div>
            <button type="button" pButton pRipple icon="pi pi-file-excel" (click)="exportExcel()"
                    class="p-button-sm p-button-success m-auto ml-0 mr-0"
                    pTooltip="XLS" tooltipPosition="top"></button>
          </div>
        </div>
      </div>



      <div class="tableaux" id="tableaux" >

        <p-table *ngIf="!listFolderEmpty"  [lazy]="true" (onLazyLoad)="lazyLoad($event)" [value]="dossiers" dataKey="numeroDossier" [scrollable]="true" scrollHeight="{{tableHeight}}px" scrollDirection="both" [loading]="loading">

          <ng-template pTemplate="header">
            <tr id="header">
              <th class="width-17" pFrozenColumn>
              </th>
              <th pSortableColumn="numeroContrat" class="table-id width-13 frozen-shadow" pFrozenColumn>{{'DOSSIER.NUM_CONTRAT' | translateI18n}}<p-sortIcon field="numeroContrat" ></p-sortIcon>
              <app-filter-text typeField="text" field="numeroContrat"></app-filter-text>
              </th>

              <th pSortableColumn="numero" class="is-large" >{{'DOSSIER.NUM_DOSSIER' | translateI18n}}<p-sortIcon field="numero"></p-sortIcon>
                <app-filter-text typeField="text" field="numero"></app-filter-text></th>

              <th pSortableColumn="statutActuel" class="is-large" >{{'DOSSIER.STATUT' | translateI18n}}<p-sortIcon field="statutActuel"></p-sortIcon>
                <app-filter-text typeField="status" field="statutActuel"></app-filter-text></th>

              <th pSortableColumn="tiers" class="is-large">{{'DOSSIER.TIERS' | translateI18n}}<p-sortIcon field="tiers"></p-sortIcon>
                <app-filter-text typeField="text" field="tiers"></app-filter-text></th>

              <th pSortableColumn="client" class="is-large">{{'DOSSIER.AFFAIRE' | translateI18n}}<p-sortIcon field="client"></p-sortIcon>
                <app-filter-text typeField="text" field="client"></app-filter-text></th>

              <th pSortableColumn="codePostal" class="width-6">{{'DOSSIER.CP' | translateI18n}}<p-sortIcon field="codePostal"></p-sortIcon>
                <app-filter-text typeField="text" field="codePostal"></app-filter-text></th>

              <th pSortableColumn="ville" class="width-13">{{'DOSSIER.ADRESSE_FORM.CITY' | translateI18n}}<p-sortIcon field="ville"></p-sortIcon>
                <app-filter-text typeField="text" field="ville"></app-filter-text></th>

              <th pSortableColumn="dateContact" class="width-date">{{'DOSSIER.CONTACT' | translateI18n}}<p-sortIcon field="dateContact"></p-sortIcon>
                <!--<p-columnFilter type="date" field="dateContactDossier" display="menu"></p-columnFilter>-->
                <app-filter-text typeField="date" field="dateContact"></app-filter-text>
              </th>

              <th pSortableColumn="dateVisite" class="width-date">{{'DOSSIER.VISITE' | translateI18n}}<p-sortIcon field="dateVisite"></p-sortIcon>
                <app-filter-text typeField="date" field="dateVisite"></app-filter-text></th>

              <th pSortableColumn="typeLivrable" class="width-13">{{'DOSSIER.TYPE_LIVRABLE' | translateI18n}}<p-sortIcon field="typeLivrable"></p-sortIcon>
                <app-filter-text typeField="typeLivrable" field="typeLivrable"></app-filter-text></th>

            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-dossier>
            <tr [pSelectableRow]="dossier">

              <td pFrozenColumn class="width-17">
                <app-upload-document objet='DOSSIER' [idObjet]="dossier.bien.idBien" [statut]="dossier.statut" [parent]="this" [verouillerExpert]="true"
                                     label="DOCUMENT.UPLOAD.BOUTON.LABEL_COURT" [idTiers]="dossier?.tiers?.id" [idDossier]="dossier?.idDossier"></app-upload-document>
                <button pButton pRipple label="{{'BUTTON_LABEL.OUVRIR' | translateI18n}}" icon="inc-icon inc-icon-eye is-small" iconPos="right"
                        [routerLink]="['/dossier/demande/fiche', dossier.idDossier]"
                        class="p-button-sm p-button-rounded"></button>
              </td>
              <td class="table-id width-13 frozen-shadow" pFrozenColumn>{{dossier.numeroContrat}}</td>
              <td class="is-large">{{dossier.numeroDossier}}</td>
              <td class="is-large">
                <p class="icon-statut-{{dossier.statut}}">
                  <span class="inc-icon {{dossier.statut | iconStatutDossier}} is-small ml-2"></span>
                  <span class="mr-2">{{dossier.statut | statutDossier}}</span>
                </p>
              </td>
              <td class="is-large">{{dossier.tiers?.nomCompletTiers}}</td>
              <td class="is-large">{{dossier.client?.nomComplet}}</td>
              <td class="width-6">{{dossier?.adresse?.codePostal}}</td>
              <td class="width-13">{{dossier?.adresse?.ville}}</td>
              <td class="width-date" [pTooltip]="dossier.commentcontactRapportDossier"
                  [ngClass]="dossier.commentcontactRapportDossier != null && dossier.commentcontactRapportDossier != ''? 'commentToolip' : ''">
                {{dossier.dateContactDossier | date : 'dd/MM/yyyy'}}</td>
              <td class="width-date" [pTooltip]="dossier.commentviRapportDossier"
                  [ngClass]="dossier.commentviRapportDossier != null && dossier.commentviRapportDossier? 'commentToolip' : ''">
                {{dossier.dateVisite | date : 'dd/MM/yyyy'}}</td>
              <td class="width-13">{{dossier.infoExpertise?.typeLivrable?.code | typeLivrable}}</td>
            </tr>
          </ng-template>

        </p-table>

      </div>

    <app-other-folder-image [listFolderEmpty]="listFolderEmpty"></app-other-folder-image>

  </div>

</main>

<ngx-spinner type="ball-scale-multiple"><p-progressSpinner></p-progressSpinner></ngx-spinner>
