import {ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {AbstractControl, FormBuilder, FormGroup, FormGroupDirective} from "@angular/forms";
import {DialogService, DynamicDialogRef} from "primeng/dynamicdialog";
import {Message, MessageService} from "primeng/api";
import {TranslateService} from "../../../../shared/translate/translate.service";
import {AvisValeur} from "../../../models/avis-valeurs/avis-valeur";
import {DatePipe} from "@angular/common";
import {AvisValeurObservableService} from "../../../../shared/observable/avis-valeur-observable.service";
import {ConfigSizeInput} from "../../../../shared/providers/config-size-input";

@Component({
  selector: 'app-mission',
  templateUrl: './mission.component.html',
  styleUrls: ['./mission.component.scss']
})
export class MissionComponent implements OnInit {

  @Input()
  formGroupName: string;

  @Input()
  paramTypeValeur : any[];

  @Input()
  paramOccupation : any[];

  @Input()
  submitted : boolean;

  @Input()
  submittedRequired : boolean;

  @Input()
  avisValeur : AvisValeur;

  form: FormGroup;
  nbPiecesCommuniquesText: number = 0;

  msgError : Message[];

  nbPiecesCommuniquesTextError : boolean;

  missionSection : string;

  configSizeInput : ConfigSizeInput;

  constructor(
      private fb : FormBuilder,
      public dialogService: DialogService,
      public ref: DynamicDialogRef,
      private rootFormGroup: FormGroupDirective,
      private cdref: ChangeDetectorRef,
      private message: MessageService,
      private translate : TranslateService,
      private datePipe: DatePipe,
      private avisValeurObservableService : AvisValeurObservableService,
  ) {
    this.configSizeInput = new ConfigSizeInput();
  }

  ngOnInit(): void {

    this.form = this.rootFormGroup.control.get(this.formGroupName) as FormGroup;
    if(this.form.value?.piecesCommuniques?.length > 0) {
      this.nbPiecesCommuniquesText = this.form?.value?.piecesCommuniques ? this.form?.value?.piecesCommuniques?.replace(/<.*?>/g, '')?.length : 0;
    }

    this.msgError =[ {severity:'error',
      summary:  this.translate.getElement("AVIS_VALEUR.MESSAGE.ERROR_CARACTERE_COMMENTAIRE", '400')}];
    this.getInfosUtils();

    this.avisValeurObservableService.sendTypeValeur(this.form?.value?.typeValeur);
  }

  piecesCommuniquesTextChange(event: any) {
    let value = event.textValue;
    this.nbPiecesCommuniquesText = value.length;
    this.nbPiecesCommuniquesTextError = value.length > 400;
  }

  get f(): { [key: string]: AbstractControl } {
    return this.form.controls;
  }

  getInfosUtils() {
    let civilite = '';
    if(this.avisValeur?.isPhysiqueTiers) {
      civilite = this.avisValeur?.abreviationCiviliteTiers + " ";
    }
    let agenceTiers = civilite + this.avisValeur?.nomCompletTiers;

    let missionMention = '';
    if(this.avisValeur?.codeEntiteJuridiqueNegLie !== 'AT') {
      missionMention = this.translate.getElement('AVIS_VALEUR.MISSION_MENTION');
    }

    this.missionSection = this.translate.getElement("AVIS_VALEUR.MISSION_SECTION",
      this.avisValeur.libelleEntiteJuridiqueNegLie, missionMention, this.datePipe.transform( this.avisValeur?.dateDemande, "dd/MM/yyyy"),
      agenceTiers, this.avisValeur?.civiliteDemandeur?.abreviation, this.avisValeur?.nomCompletDemandeur)
  }

  typeValeurChange(event : any) {
    this.avisValeurObservableService.sendTypeValeur(event.value);
  }

  ngAfterContentChecked() {
    this.cdref.detectChanges();
  }

}
