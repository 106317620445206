<form  [formGroup]="form">


  <div *ngIf="user && !user?.agentSuperviseur">
    <h2 >{{'DOSSIER.ADVANCED_SEARCH.LABEL.ELARGIR_RECHERCHE' | translateI18n}} {{agence}} </h2>
    <div class="grid formgrid" [formGroup]="form">

      <div class="field col-12" formGroupName="agence">
        <div class="p-radiobutton-group inline-group">
          <p-radioButton name="elargir" [value]="true" formControlName="elargir"
                         label="{{'DOSSIER.ADVANCED_SEARCH.LABEL.OUI' | translateI18n}}">
          </p-radioButton>

          <p-radioButton name="elargir" [value]="false" formControlName="elargir"
                         label="{{'DOSSIER.ADVANCED_SEARCH.LABEL.NON' | translateI18n}}" checked>
          </p-radioButton>
        </div>
      </div>
    </div>
  </div>


  <app-input-client formGroupName="client"></app-input-client>

  <app-type-document formGroupName="typeDoc"></app-type-document>

  <app-statuts-dropdown formGroupName="statut" [statuts]="statuts"></app-statuts-dropdown>

  <app-periode-calender formGroupName="periode"></app-periode-calender>

  <app-adresse-bien formGroupName="adresseBien"></app-adresse-bien>

</form>
