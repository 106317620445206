import {Injectable} from '@angular/core';
import {BehaviorSubject} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class RapportExpertiseObservableService  {

  private _surfaceSubject$ : BehaviorSubject<number> = new BehaviorSubject<number>(null);
  private _typeValeurSubject$ : BehaviorSubject<number> = new BehaviorSubject<number>(null);
  private _typeSurfaceSubject$ : BehaviorSubject<any> = new BehaviorSubject<any>(null);
  private _typeOccupationSubject$ : BehaviorSubject<any> = new BehaviorSubject<any>(null);
  private _liquiditeValeurVenal$ : BehaviorSubject<any> = new BehaviorSubject<any>(null);
  private _delaiCommercialisationSubject$ : BehaviorSubject<any> = new BehaviorSubject<any>(null);
  private _valeurVenteForceeSubject$ : BehaviorSubject<number> = new BehaviorSubject<number>(null);

  private _valeurVenaleSubject$ : BehaviorSubject<any> = new BehaviorSubject<any>(null);
  private _valeurLocativeeSubject$ : BehaviorSubject<any> = new BehaviorSubject<any>(null);
  private _valeurApresTraveauxSubject$ : BehaviorSubject<any> = new BehaviorSubject<any>(null);
  private _valeurFutureAchevementSubject$ : BehaviorSubject<any> = new BehaviorSubject<any>(null);

  private _valeurFullNameExpertSubject$ : BehaviorSubject<any> = new BehaviorSubject<any>(null);

  private _methodeValorisation$ : BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor() { }

  sendSurface(surface : number) {
    this._surfaceSubject$.next(surface);
  }

  getSurface() {
    return this._surfaceSubject$.asObservable();
  }

  sendTypeValeur(typeValeur : any) {
    this._typeValeurSubject$.next(typeValeur)
  }

  getTypeValeur() {
    return this._typeValeurSubject$.asObservable();
  }

  sendTypeSurface(typeSurface : any) {
    this._typeSurfaceSubject$.next(typeSurface)
  }

  getTypeSurface() {
    return this._typeSurfaceSubject$.asObservable();
  }

  sendTypeOccupation(typeSurface : any) {
    this._typeOccupationSubject$.next(typeSurface)
  }

  getTypeOccupation() {
    return this._typeOccupationSubject$.asObservable();
  }


  sendLiquidite(liquidite : any) {
    this._liquiditeValeurVenal$.next(liquidite)
  }

  getLiquidite() {
    return this._liquiditeValeurVenal$.asObservable();
  }

  sendDelaiCommercialissation(liquidite : any) {
    this._delaiCommercialisationSubject$.next(liquidite)
  }

  getDelaiCommercialissation() {
    return this._delaiCommercialisationSubject$.asObservable();
  }

  sendValeurVenteForcee(vvf : number) {
    this._valeurVenteForceeSubject$.next(vvf)
  }

  getValeurVenteForcee() {
    return this._valeurVenteForceeSubject$.asObservable();
  }

  //Valeur venale
  sendValeurVenale(vv : any) {
    this._valeurVenaleSubject$.next(vv)
  }

  getValeurVenale() {
    return this._valeurVenaleSubject$.asObservable();
  }

  //Valeur locative
  sendValeurLocative(vl : any) {
    this._valeurLocativeeSubject$.next(vl)
  }

  getValeurLocative() {
    return this._valeurLocativeeSubject$.asObservable();
  }

  //Valeur après traveaux
  sendValeurApresTraveaux(vap : any) {
    this._valeurApresTraveauxSubject$.next(vap)
  }

  getValeurApresTraveaux() {
    return this._valeurApresTraveauxSubject$.asObservable();
  }

  //Valeur future achevement
  sendValeurFutureAchevement(vfa : any) {
    this._valeurFutureAchevementSubject$.next(vfa)
  }

  getValeurFutureAchevement() {
    return this._valeurFutureAchevementSubject$.asObservable();
  }


  sendFullNameExpert(fullName : string) {
    this._valeurFullNameExpertSubject$.next(fullName)
  }

  getFullNameExpert() {
    return this._valeurFullNameExpertSubject$.asObservable();
  }


  sendMethodeValorisation(methode : any) {
    this._methodeValorisation$.next(methode)
  }

  getMethodeValorisation() {
    return this._methodeValorisation$.asObservable();
  }


}
