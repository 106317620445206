<p-toast></p-toast>
<main class="layout-wrapper">
  <app-navigation></app-navigation>
  <app-header [titre]="titleHeader" sstitre="{{totalRecords}} dossiers" state="progress"></app-header>
  <div class="layout-main have-table">

    <div  *ngIf="messageAffect" class="mr-4">
      <p-messages [(value)]="messageAffect" [enableService]="false"></p-messages>
    </div>

    <div class="cta-container m-3 flex justify-content-between flex-wrap">
      <app-quick-serch  [showContrat]="true" [showRechercheAvancee] = "true" rechercheAvanceeData="rechercheDossier" lienRechercheRapide="dossier/recherche/expert"></app-quick-serch>

      <div *ngIf="!listFolderEmpty" class="flex align-items-center">

        <div>
          <app-pagination [totalRecords]="response?.totalElements" (eventNextPage)="nextPage($event)"
                          label="{{'DOSSIER.PAGINATOR.MESSAGE.AFFICHAGE' | translateI18n}} {first}
                           {{'DOSSIER.PAGINATOR.MESSAGE.A' | translateI18n}} {last} {{'DOSSIER.PAGINATOR.MESSAGE.SUR' | translateI18n}} {totalRecords}"></app-pagination>
        </div>

        <div>
          <button type="button" pButton pRipple icon="pi pi-file-excel" (click)="exportExcel()"
                  class="p-button-sm p-button-success m-auto ml-0 mr-0"
                  pTooltip="XLS" tooltipPosition="top"></button>
        </div>
      </div>

    </div>

    <div class="tableaux" id="tableaux" >

      <p-table *ngIf="!listFolderEmpty" [lazy]="true" (onLazyLoad)="lazyLoad($event)" [value]="dossiers" dataKey="idDossier"  [scrollable]="true" scrollHeight="{{tableHeight}}px" scrollDirection="both" [loading]="loading">

        <ng-template pTemplate="header">
          <tr>
            <th class="width-17" pFrozenColumn></th>
            <th pFrozenColumn pSortableColumn="numero" class="table-id width-11 frozen-shadow">{{'DOSSIER.NUM_DOSSIER' | translateI18n}}<p-sortIcon field="numero"></p-sortIcon>
              <app-filter-text typeField="text" field="numero"></app-filter-text></th>

            <th pSortableColumn="statutActuel" class="is-large">{{'DOSSIER.STATUT' | translateI18n}}<p-sortIcon field="statutActuel"></p-sortIcon>
              <app-filter-text typeField="status" field="statutActuel"></app-filter-text></th>

            <th pSortableColumn="typeLivrable" class="width-13">{{'DOSSIER.TYPE_LIVRABLE' | translateI18n}}<p-sortIcon field="typeLivrable"></p-sortIcon>
              <app-filter-text typeField="typeLivrable" field="typeLivrable"></app-filter-text></th>

            <th pSortableColumn="tiers" class="is-large">{{'DOSSIER.TIERS' | translateI18n}}<p-sortIcon field="tiers"></p-sortIcon>
              <app-filter-text typeField="text" field="tiers"></app-filter-text></th>

            <th pSortableColumn="demandeur" class="is-large">{{'DOSSIER.DEMANDEUR' | translateI18n}}<p-sortIcon field="demandeur"></p-sortIcon>
              <app-filter-text typeField="text" field="demandeur"></app-filter-text></th>

            <th pSortableColumn="client" class="is-large">{{'DOSSIER.AFFAIRE' | translateI18n}}<p-sortIcon field="client"></p-sortIcon>
              <app-filter-text typeField="text" field="client"></app-filter-text></th>

            <th pSortableColumn="expert" class="is-large">{{'DOSSIER.EXPERT' | translateI18n}}<p-sortIcon field="expert"></p-sortIcon>
              <app-filter-text typeField="text" field="expert"></app-filter-text></th>

            <th pSortableColumn="nomVoie" class="is-large">{{'DOSSIER.ADRESSE' | translateI18n}}<p-sortIcon field="nomVoie"></p-sortIcon>
              <app-filter-text typeField="text" field="nomVoie"></app-filter-text></th>

            <th pSortableColumn="codePostal" class="width-6">{{'DOSSIER.CP' | translateI18n}}<p-sortIcon field="codePostal"></p-sortIcon>
              <app-filter-text typeField="text" field="codePostal"></app-filter-text></th>

            <th pSortableColumn="ville" class="width-13">{{'DOSSIER.ADRESSE_FORM.CITY' | translateI18n}}<p-sortIcon field="ville"></p-sortIcon>
              <app-filter-text typeField="text" field="ville"></app-filter-text></th>

            <th pSortableColumn="dateContact" class="width-date">{{'DOSSIER.CONTACT' | translateI18n}}<p-sortIcon field="dateContact"></p-sortIcon>
              <app-filter-text typeField="date" field="dateContact"></app-filter-text></th>

            <th pSortableColumn="dateVisite" class="width-date">{{'DOSSIER.VISITE' | translateI18n}}<p-sortIcon field="dateVisite"></p-sortIcon>
              <app-filter-text typeField="date" field="dateVisite"></app-filter-text></th>

            <th pSortableColumn="mission.dateMissionPrevisionnelle" class="width-date">{{'DOSSIER.ECHEANCE' | translateI18n}}<p-sortIcon field="mission.dateMissionPrevisionnelle"></p-sortIcon>
              <app-filter-text typeField="date" field="mission.dateMissionPrevisionnelle"></app-filter-text></th>

          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-dossier>
          <tr [pSelectableRow]="dossier" [ngClass]="{'en_conflit' : dossier.bien.enConflit}" [pTooltip]="dossier.bien.enConflit ? infoBulleConflit : ''" tooltipPosition="top">
            <td pFrozenColumn class="affectationButton width-17">
              <button type="button" pButton pRipple icon="pi pi-chevron-down" label="{{'BUTTON_LABEL.ACTION' | translateI18n}}" iconPos="right"
                      class="p-button-sm p-button-rounded" (click)="menu.toggle($event)" [disabled]="dossier.bien?.enConflit || dossier.statut !== 'ENCOURS'"></button>
              <p-menu #menu [popup]="true" [model]="getActions(dossier)"  appendTo="body" styleClass="sub-menu"></p-menu>
              <button pButton pRipple label="{{'BUTTON_LABEL.OUVRIR' | translateI18n}}" [routerLink]="['//dossier/fiche', dossier.idDossier]"  icon="pi pi-eye" iconPos="right"
                      class="p-button-info p-button-sm p-button-rounded" ></button>
            </td>
            <td pFrozenColumn class="table-id width-11 frozen-shadow">{{dossier.numeroDossier}}</td>
            <td class="is-large">
              <p class="icon-statut-{{dossier.statut}}">
                <span class="inc-icon {{dossier.statut | iconStatutDossier}} is-small ml-2"></span>
                <span class="mr-2">{{dossier.statut | statutDossier}}</span>
              </p>
            </td>
            <td class="width-13">{{dossier.infoExpertise?.typeLivrable?.code | typeLivrable}}</td>
            <td class="is-large">{{dossier.tiers?.nomCompletTiers}}</td>
            <td class="is-large">{{dossier.demande?.nomCompletDemandeur}}</td>
            <td class="is-large">{{dossier.client?.nomComplet}}</td>
            <td class="is-large">{{dossier.infoExpertise.nomCompletExpert}}</td>
            <td class="is-large">
              <p style="overflow-wrap : anywhere">
                {{dossier?.adresse?.nomVoie}}
              </p>
            </td>
            <td class="width-6">{{dossier?.adresse?.codePostal}}</td>
            <td class="width-13">{{dossier?.adresse?.ville}}</td>
            <td class="width-date"
                [ngClass]="{'p-error': dossier.commentcontactRapportDossier != null && dossier.commentcontactRapportDossier != ''}">
              <span [pTooltip]="dossier.commentcontactRapportDossier" [escape]="false">
                {{dossier.commentcontactRapportDossier != null && dossier.dateContactDossier == null ? '--/--/----': (dossier.dateContactDossier | date : 'dd/MM/yyyy')}}
              </span>

            </td>
            <td class="width-date"
                [ngClass]="{'p-error': dossier.commentviRapportDossier != null && dossier.commentviRapportDossier}">
              <span [pTooltip]="dossier.commentviRapportDossier" [escape]="false">
                 {{dossier.commentviRapportDossier != null && dossier.dateVisite == null ? '--/--/----': (dossier.dateVisite | date : 'dd/MM/yyyy')}}
              </span>
             </td>
            <td class="width-date">{{dossier.mission?.dateMissionPrevisionnelle | date : 'dd/MM/yyyy'}}</td>
          </tr>
        </ng-template>
        <ng-template pTemplate="footer" class="tfooter">

        </ng-template>
      </p-table>
    </div>

    <app-other-folder-image [listFolderEmpty]="listFolderEmpty"></app-other-folder-image>

  </div>

</main>
