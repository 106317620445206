import {ChangeDetectorRef, Component, Input, OnDestroy, OnInit} from '@angular/core';
import {RapportExpertise} from "../../../models/rapport-expertise/rapport-expertise";
import {AbstractControl, FormBuilder, FormGroup, FormGroupDirective} from "@angular/forms";
import {DialogService, DynamicDialogRef} from "primeng/dynamicdialog";
import {Message, MessageService} from "primeng/api";
import {RapportExpertiseObservableService} from "../../../../shared/observable/rapport-expertise-observable.service";
import {RapportExpertiseService} from "../../../service/rapport-expertise.service";
import {DatePipe} from "@angular/common";
import {TranslateService} from "../../../../shared/translate/translate.service";
import {Subscription} from "rxjs";

@Component({
  selector: 'app-estrimation-rapport',
  templateUrl: './estrimation-rapport.component.html',
  styleUrls: ['./estrimation-rapport.component.scss']
})
export class EstrimationRapportComponent implements OnInit, OnDestroy {

  @Input()
  formGroupName: string;

  @Input()
  submitted : boolean;

  @Input()
  submittedRequired : boolean;

  @Input()
  rapport : RapportExpertise;

  form: FormGroup;

  nbForces : number = 0;
  nbFaiblesses : number = 0;
  nbOpportinute : number = 0;
  nbMenaces : number = 0;
  nbEstimation : number = 0;

  msgErrorMaxcaract : Message[];

  typeSurface : any;

  surface : number;

  methodes : any[];

  private subscriptionSurface : Subscription;
  private subscriptionTypeSurface: Subscription;

  constructor(
    private fb : FormBuilder,
    public dialogService: DialogService,
    public ref: DynamicDialogRef,
    private rootFormGroup: FormGroupDirective,
    private cdref: ChangeDetectorRef,
    private message: MessageService,
    private translate : TranslateService,
    private datePipe: DatePipe,
    private rapportExpertiseObservableService : RapportExpertiseObservableService,
    private rapportExpertiseService : RapportExpertiseService,
  ) { }

  ngOnInit(): void {
    this.form = this.rootFormGroup.control.get(this.formGroupName) as FormGroup;

    this.getAllMethodeValorsationRapport();

    this.subscriptionTypeSurface  = this.rapportExpertiseObservableService.getTypeSurface().subscribe( data => {
      this.typeSurface = data;
    });

    this.subscriptionSurface = this.rapportExpertiseObservableService.getSurface().subscribe( data => {
      this.surface = data;
    })


    this.nbForces = this.form?.value?.forces ? this.form?.value?.forces?.replace(/<[^>]*>/g, '')?.length : 0;
    this.msgErrorMaxcaract = [ {key :'forces', severity:'error', summary:  this.translate.getElement("RAPPORT_EXPERTISE.MESSAGE.ERROR_CARACTERE_COMMENTAIRE", '500')}];

    this.nbFaiblesses = this.form?.value?.faiblesses ? this.form?.value?.faiblesses?.replace(/<[^>]*>/g, '')?.length : 0;
    this.msgErrorMaxcaract = [ {key :'faiblesses', severity:'error', summary:  this.translate.getElement("RAPPORT_EXPERTISE.MESSAGE.ERROR_CARACTERE_COMMENTAIRE", '500')}];

    this.nbOpportinute = this.form?.value?.opportunites ? this.form?.value?.opportunites?.replace(/<[^>]*>/g, '')?.length : 0;
    this.msgErrorMaxcaract = [ {key :'opportunites', severity:'error', summary:  this.translate.getElement("RAPPORT_EXPERTISE.MESSAGE.ERROR_CARACTERE_COMMENTAIRE", '500')}];

    this.nbMenaces = this.form?.value?.menaces ? this.form?.value?.menaces?.replace(/<[^>]*>/g, '')?.length : 0;
    this.msgErrorMaxcaract = [ {key :'menaces', severity:'error', summary:  this.translate.getElement("RAPPORT_EXPERTISE.MESSAGE.ERROR_CARACTERE_COMMENTAIRE", '500')}];


    this.nbEstimation = this.form?.value?.estimation ? this.form?.value?.estimation?.replace(/<[^>]*>/g, '')?.length : 0;
    this.msgErrorMaxcaract = [ {key :'estimation', severity:'error', summary:  this.translate.getElement("RAPPORT_EXPERTISE.MESSAGE.ERROR_CARACTERE_COMMENTAIRE", '500')}];

  }

  ngOnDestroy() {
    this.subscriptionSurface.unsubscribe();
    this.subscriptionTypeSurface.unsubscribe();
  }

  numForcesTextChange(event: any) {
    this.nbForces = event?.htmlValue ? event?.htmlValue?.replace(/<[^>]*>/g, '')?.trim()?.length : 0;
  }

  numFaiblessesTextChange(event: any) {
    this.nbFaiblesses = event?.htmlValue ? event?.htmlValue?.replace(/<[^>]*>/g, '')?.trim()?.length : 0;
  }

  numOpportinutesTextChange(event: any) {
    this.nbOpportinute = event?.htmlValue ? event?.htmlValue?.replace(/<[^>]*>/g, '')?.trim()?.length : 0;
  }

  numMenacesTextChange(event: any) {
    this.nbMenaces = event?.htmlValue ? event?.htmlValue?.replace(/<[^>]*>/g, '')?.trim()?.length : 0;
  }

  getAllMethodeValorsationRapport() {
    this.rapportExpertiseService.getAllMethodeValorsationRapport().subscribe( data => {
      this.methodes = data;
    })
  }


  get f(): { [key: string]: AbstractControl } {
    return this.form.controls;
  }

  numEstimationTextChange(event: any) {
    this.nbEstimation = event?.htmlValue ? event?.htmlValue?.replace(/<[^>]*>/g, '')?.trim()?.length : 0;
  }

  methodeValorisation(event: any) {
    this.rapport.methodeValorisationRapport = event.value;
    this.rapportExpertiseObservableService.sendMethodeValorisation(event.value);
  }
}
