import {ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {FormGroup, FormGroupDirective} from "@angular/forms";
import {User} from "../../../models/user/user.model";

@Component({
  selector: 'app-demandeur',
  templateUrl: './demandeur.component.html',
  styleUrls: ['./demandeur.component.scss']
})
export class DemandeurComponent implements OnInit {

  @Input()
  formGroupName: string;
  form: FormGroup;

  @Input()
  statuts: any[];

  @Input()
  agence : string;

  @Input()
  user : User;

  constructor(private rootFormGroup: FormGroupDirective, private cdref: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.form = this.rootFormGroup.control.get(this.formGroupName) as FormGroup;
  }

  ngAfterContentChecked() {
    this.cdref.detectChanges();
  }

}
