import {Pipe, PipeTransform} from '@angular/core';
import {TranslateService} from "../translate/translate.service";

@Pipe({
  name: 'translateI18n'
})
export class TranslatePipe implements PipeTransform {

  constructor(private translate: TranslateService) {}

  transform(key: string, ...args: string[]): string {
    return this.translate.get(this.translate.data, key, null, args) || key;
  }

}
