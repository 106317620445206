import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {RestDatasource} from "./rest.datasource";
import {UploadRequest} from "../models/document/upload-request";
import {Document} from "../models/document/document.model";
import {CategorieDocument} from "../models/document/categorie-document";
import {ModificationDocument} from "../models/document/modification-document";

@Injectable()
export class DocumentService {



    constructor(private datasource : RestDatasource) {
    }

    postFile(upload: File[], objet: string, idObjet: number, idDossier : number): Observable<any> {
      const request: UploadRequest = new UploadRequest();
      request.objetPrincipal = objet;
      request.idObjetPrincipal = idObjet;
      request.idDossier = idDossier;
      return this.datasource.uploadDocument(upload, request);
    }

    getDocumentsDossier(idBien: number, allPictures : boolean): Observable<Document[]> {
      return this.datasource.getDocumentsDossier(idBien, allPictures);
    }

    getDocumentsNonLus(): Observable<Document[]> {
      return this.datasource.getDocumentsNonLus();
    }

    deleteDocument(uidDocument: string, idDossier : number): Observable<any> {
      return this.datasource.deleteResource(uidDocument, idDossier, "document");
    }

    downloadDocument(uidDocument: string, idDocumentLecture: number): Observable<Blob> {
      return this.datasource.downloadDocument(uidDocument, idDocumentLecture);
    }

    getCategoriesDocument(): Observable<CategorieDocument[]> {
      return this.datasource.getCategoriesDocument();
    }

    modifierDocument(document: ModificationDocument): Observable<Document> {
      return this.datasource.modifierDocument(document);
    }

    findDocumentByIdbienAndCategorie(idBien: number, idCat : number) {
      return this.datasource.findDocumentByIdbienAndCategorie(idBien, idCat);
    }

    uploadPhoto(files: File[], uploadRequest: UploadRequest) {
      return this.datasource.uploadPhoto(files, uploadRequest);
    }

    deleteDossierRapportDocument(idBien: number, uidDocument : string, idDossier : number) {
        return this.datasource.deleteDossierRapportDocument(idBien, uidDocument, idDossier);
    }

    downloadDocumentByType(idBien : number, type : String) {
        return this.datasource.downloadDocumentByType(idBien, type);
    }


}
