import {Component, Input, OnInit} from '@angular/core';
import {RapportExpertise} from "../../../models/rapport-expertise/rapport-expertise";
import {AbstractControl, FormBuilder, FormGroup, FormGroupDirective} from "@angular/forms";
import {Message} from "primeng/api";
import {TranslateService} from "../../../../shared/translate/translate.service";
import {Subscription} from "rxjs";
import {RapportExpertiseObservableService} from "../../../../shared/observable/rapport-expertise-observable.service";

@Component({
  selector: 'app-etude-marche',
  templateUrl: './etude-marche.component.html',
  styleUrls: ['./etude-marche.component.scss']
})
export class EtudeMarcheComponent implements OnInit {

  @Input()
  formGroupName: string;

  @Input()
  submitted : boolean;

  @Input()
  submittedRequired : boolean;

  @Input()
  rapport : RapportExpertise;

  form: FormGroup;

  nbAnalyseMarche : number = 0;
  nbAnalysemarcheLocatif : number = 0;
  nbPositionnement : number = 0;

  msgErrorMaxcaract : Message[];

  displayMarcheLocatif : boolean;

  constructor(
    private fb : FormBuilder,
    private rootFormGroup: FormGroupDirective,
    private translate : TranslateService,
    private rapportExpertiseObservableService : RapportExpertiseObservableService,
  ) { }

  ngOnInit(): void {
    this.form = this.rootFormGroup.control.get(this.formGroupName) as FormGroup;
    this.initCompteurChampText();
    this.displayMarcheLocatif = this.rapport.paramTypeValeur.idTypeValeur === 2 || this.rapport.paramTypeValeur.idTypeValeur === 5 ;
  }

  get f(): { [key: string]: AbstractControl } {
    return this.form.controls;
  }

  initCompteurChampText() {
    this.nbAnalyseMarche = this.form?.value?.analysemarche ? this.form?.value?.analysemarche?.replace(/<[^>]*>/g, '')?.length : 0;

    this.msgErrorMaxcaract = [ {key :'analysemarche', severity:'error', summary:  this.translate.getElement("AVIS_VALEUR.MESSAGE.ERROR_CARACTERE_COMMENTAIRE", '4000')}];

    this.nbAnalysemarcheLocatif = this.form?.value?.analysemarcheLocatif ? this.form?.value?.analysemarcheLocatif?.replace(/<[^>]*>/g, '')?.length : 0;

    this.msgErrorMaxcaract = [ {key :'analysemarcheLocatif', severity:'error', summary:  this.translate.getElement("AVIS_VALEUR.MESSAGE.ERROR_CARACTERE_COMMENTAIRE", '4000')}];


    this.nbPositionnement = this.form?.value?.positionnement ? this.form?.value?.positionnement?.replace(/<[^>]*>/g, '')?.length : 0;
    this.msgErrorMaxcaract = [ {key :'positionnement', severity:'error', summary:  this.translate.getElement("AVIS_VALEUR.MESSAGE.ERROR_CARACTERE_COMMENTAIRE", '2000')}];

  }

  numanalysemarcheTextChange(event: any) {
    this.nbAnalyseMarche = event?.htmlValue ? event?.htmlValue?.replace(/<[^>]*>/g, '')?.trim()?.length : 0;
  }

  numanalysemarcheLocatifTextChange(event: any) {
    this.nbAnalysemarcheLocatif = event?.htmlValue ? event?.htmlValue?.replace(/<[^>]*>/g, '')?.trim()?.length : 0;
  }


  numPositionnementTextChange(event: any) {
    this.nbPositionnement = event?.htmlValue ? event?.htmlValue?.replace(/<[^>]*>/g, '')?.trim()?.length : 0;
  }
}
