import {Injectable} from '@angular/core';
import {BehaviorSubject} from "rxjs";
import {Document} from "../../core/models/document/document.model";

@Injectable({
  providedIn: 'root'
})
export class UtilsObservableService {

  private _documentsSubject$ : BehaviorSubject<Document[]> = new BehaviorSubject<Document[]>(null);

  constructor() { }

  sendDocuments(docs : Document[]) {
    this._documentsSubject$.next(docs)
  }

  getDocuments() {
    return this._documentsSubject$.asObservable();
  }
}
