import {Adresse} from "../adresse";
import {Pagination} from "../rechercher-tableau/pagination";

export class ItemCriteriaResarchDossier {
  typeVue : string[];
  nomClient : string;
  prenomClient : string;
  codeTypeLivrable : string;
  periodeDateDebut : Date;
  periodeDateFin : Date;
  adresseBien : Adresse;
  statutDossier : string[];
  etatEnvoi : string;
  pagination : Pagination;
  numeroDossier : string;
  numeroContrat : string;
  expert : number;
  tiers : string;
  demandeEncoursExpert : boolean = false;
  expandAgencySearch : boolean;

  constructor(fields?: Partial<ItemCriteriaResarchDossier>) {
    if (fields) {
      Object.assign(this, fields);
    }
  }
}
