import {Component, OnInit} from '@angular/core';
import {Signataire} from "../../models/user/signataire";
import {DossierService} from "../../service/dossier.service";
import {DomSanitizer} from "@angular/platform-browser";
import {DynamicDialogConfig, DynamicDialogRef} from "primeng/dynamicdialog";
import {User} from "../../models/user/user.model";
import {Dossier} from "../../models/dossiers/dossier";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {Message} from "primeng/api";
import {TranslateService} from "../../../shared/translate/translate.service";

@Component({
  selector: 'app-validation-livrable',
  templateUrl: './validation-livrable.component.html',
  styleUrls: ['./validation-livrable.component.scss']
})
export class ValidationLivrableComponent implements OnInit {

  userSignataire : Signataire;

  userSignataires : Signataire[];

  signataire : Signataire;
  userConnected : User;
  dossier : Dossier;
  errorMessage: Message[];

  form :  FormGroup;

  constructor(
    private dossierService : DossierService,
    private sanitizer: DomSanitizer,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private fb: FormBuilder,
    private translate : TranslateService
  ) { }

  ngOnInit(): void {
    this.form = this.fb.group({
      signataire: [null, Validators.required]
    });
    this.userConnected = this.config.data.userConnected;
    this.dossier = this.config.data.dossier;
    this.getAllSignataires();
  }


  getAllSignataires() {
     this.dossierService.getAllUtilisateurSignataires().subscribe( data => {
       this.userSignataires = data;
       this.userSignataire = this.userSignataires.find(us => us.id === this.userConnected.id && us.actif);
       if(this.userSignataire) {
         this.form.get('signataire').setValue(this.userSignataire);
       }
     })
  }

  validerLivrable() {

    if (!this.userSignataire) {
      this.errorMessage = [ {severity:'error', summary: this.translate.getElement("DOSSIER.GESTIONNAIRE.MESSAGE.VALIDER_LIVRABLE.ERROR_CHOIX_SIGNATAIRE")}];
      return;
    }
    this.close(this.userSignataire);
  }

  close(signataire? : any) {
    this.ref.close(signataire);
  }
}
