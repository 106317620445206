<div class="login-page">
</div>
<main class="layout-wrapper h-full" id="main-login">

  <div class="flex justify-content-center m-4" id="logo-bnp-login">
    <img src="../../../assets/images/logo-bnp-valuation.svg" width="200px" />
  </div>

  <div class="layout-main h-full no-nav no-header">

    <div class="grid justify-content-center align-content-center w-full">

      <div class="col-10 is-login-card">

        <div class="flex justify-content-center pb-6">
          <img src="../../../assets/images/logo-valea.svg" width="140px" />
        </div>

        <div class="grid">


          <div class="col-12 md:col-12">
            <div>
              <p-messages severity="info" [escape]="false" [enableService]="false" [closable]="true">
                <ng-template pTemplate>
                  <div class="ml-5">
                    <h3> <i class="pi pi-info-circle" style="font-size: 1.5rem"></i>
                      <span class="ml-4">{{'AUTH.MESSAGE.INFO.TITRE_LIEN_EXPIRE' | translateI18n}}</span></h3>
                    <p class="pb-3">{{message}}</p>
                  </div>
                </ng-template>
              </p-messages>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>

</main>


<!--<div class="layout-main">
  <div class="mr-5 ml-5">
    <p-messages [(value)]="msgs1" [enableService]="false"></p-messages>
  </div>
</div>-->
