<form [formGroup]="form" >

  <div class="card-large" id="section1">
    <h1 id="estimation-rappot">VIII - {{'RAPPORT_EXPERTISE.ESTIMATION' | translateI18n}}</h1>

    <h2>{{'RAPPORT_EXPERTISE.ELEMENTS_APPRECIATION' | translateI18n}}</h2>
    <div class="grid">

      <div class="col-12 md:col-6">
        <div class="field input-ghost">
          <label class="label">{{'RAPPORT_EXPERTISE.LABEL.FORCES' | translateI18n}}</label>
          <div class="mb-2">
            <small>{{'RAPPORT_EXPERTISE.MESSAGE.MAX_CARACTERE' | translateI18n : '500'}}
              <span [ngClass]="{'is-alert':  f.forces.hasError('hasError')}">({{nbForces}} / 500)</span>
            </small>
          </div>

          <div *ngIf="f.forces.hasError('hasError')">
            <p-messages  [(value)]="msgErrorMaxcaract" key="forces"></p-messages>
          </div>

          <p-editor formControlName="forces" [style]="{'height':'140px'}" name="forces"
                    id="forces" class="w-full"
                    [ngClass]="{'editor-error': f.forces.hasError('hasError')}"
                    (onTextChange)="numForcesTextChange($event)">
            <p-header>
              <app-p-editor-header></app-p-editor-header>
            </p-header>
          </p-editor>
        </div>
      </div>

      <div class="col-12 md:col-6">
        <div class="field input-ghost">
          <label class="label">{{'RAPPORT_EXPERTISE.LABEL.FAIBLESSES' | translateI18n}}</label>
          <div class="mb-2">
            <small>{{'RAPPORT_EXPERTISE.MESSAGE.MAX_CARACTERE' | translateI18n : '500'}}
              <span [ngClass]="{'is-alert':  f.faiblesses.hasError('hasError')}">({{nbFaiblesses}} / 500)</span>
            </small>
          </div>

          <div *ngIf="f.faiblesses.hasError('hasError')">
            <p-messages  [(value)]="msgErrorMaxcaract" key="faiblesses"></p-messages>
          </div>

          <p-editor formControlName="faiblesses" [style]="{'height':'140px'}" name="forces"
                    id="faiblesses" class="w-full"
                    [ngClass]="{'editor-error': f.faiblesses.hasError('hasError')}"
                    (onTextChange)="numFaiblessesTextChange($event)">
            <p-header>
              <app-p-editor-header></app-p-editor-header>
            </p-header>
          </p-editor>
        </div>
      </div>

      <div class="col-12 md:col-6">
        <div class="field input-ghost">
          <label class="label">{{'RAPPORT_EXPERTISE.LABEL.OPPORTUNITES' | translateI18n}}</label>
          <div class="mb-2">
            <small>{{'RAPPORT_EXPERTISE.MESSAGE.MAX_CARACTERE' | translateI18n : '500'}}
              <span [ngClass]="{'is-alert':  f.opportunites.hasError('hasError')}">({{nbOpportinute}} / 500)</span>
            </small>
          </div>

          <div *ngIf="f.opportunites.hasError('hasError')">
            <p-messages  [(value)]="msgErrorMaxcaract" key="opportunites"></p-messages>
          </div>

          <p-editor formControlName="opportunites" [style]="{'height':'140px'}" name="opportunites"
                    id="opportunites" class="w-full"
                    [ngClass]="{'editor-error': f.opportunites.hasError('hasError')}"
                    (onTextChange)="numOpportinutesTextChange($event)">
            <p-header>
              <app-p-editor-header></app-p-editor-header>
            </p-header>
          </p-editor>
        </div>
      </div>

      <div class="col-12 md:col-6">
        <div class="field input-ghost">
          <label class="label">{{'RAPPORT_EXPERTISE.LABEL.MENACES' | translateI18n}}</label>
          <div class="mb-2">
            <small>{{'RAPPORT_EXPERTISE.MESSAGE.MAX_CARACTERE' | translateI18n : '500'}}
              <span [ngClass]="{'is-alert':  f.menaces.hasError('hasError')}">({{nbMenaces}} / 500)</span>
            </small>
          </div>

          <div *ngIf="f.menaces.hasError('hasError')">
            <p-messages  [(value)]="msgErrorMaxcaract" key="menaces"></p-messages>
          </div>

          <p-editor formControlName="menaces" [style]="{'height':'140px'}" name="menaces"
                    id="menaces" class="w-full"
                    [ngClass]="{'editor-error': f.menaces.hasError('hasError')}"
                    (onTextChange)="numMenacesTextChange($event)">
            <p-header>
              <app-p-editor-header></app-p-editor-header>
            </p-header>
          </p-editor>
        </div>
      </div>

      <div class="col-12">
        <h2>{{'RAPPORT_EXPERTISE.EVALUATION' | translateI18n}}</h2>

        <section>
          <div class="field field-line">
            <p class="text-justify">{{'RAPPORT_EXPERTISE.TEXTE.EVALUATION' | translateI18n : typeSurface?.libelle : surface?.toString()}}
            </p>
          </div>
        </section>

        <section>
          <div class="field input-ghost">
            <label class="label">{{'RAPPORT_EXPERTISE.METHODE' | translateI18n}} : </label>
              <p-dropdown  [options]="methodes"  formControlName="methode" optionLabel="label" name="methode"  id="methode"
                           emptyMessage="{{'DOSSIER.MESSAGE.AUCUN_RESULTAT' | translateI18n}}"
                           class="obligatoire"
                           [ngClass]="{'ng-dirty ng-invalid': submittedRequired && f.methode?.errors?.required}"
                           placeholder="{{'PLACEHOLDER.CHOOSE' | translateI18n}}" (onChange)="methodeValorisation($event)">
              </p-dropdown>

            <small *ngIf="submittedRequired && f.methode?.errors?.required" class="p-error block">{{'MESSAGE.ERROR.CHAMPS_OBLIGATOIRE' | translateI18n}}</small>
          </div>
        </section>



        <section>
          <div class="field field-line">
            <label class="label obligatoire">{{'RAPPORT_EXPERTISE.LABEL.COMMENTAIRE_DETAIL_CALCUL' | translateI18n}}</label>
            <div class="mb-2">
              <small>{{'RAPPORT_EXPERTISE.MESSAGE.MAX_CARACTERE' | translateI18n : '3000'}}
                <span [ngClass]="{'is-alert':  f.estimation.hasError('hasError')}">({{nbEstimation}} / 3000)</span>
              </small>
            </div>

            <div *ngIf="f.estimation.hasError('hasError')">
              <p-messages  [(value)]="msgErrorMaxcaract" key="estimation"></p-messages>
            </div>

            <p-editor formControlName="estimation" [style]="{'height':'320px'}" name="estimation"
                      id="estimation" class="w-full"
                      [ngClass]="{'editor-error': f.estimation.hasError('hasError')
                      || (submittedRequired && ( f.estimation?.errors?.required || f.estimation.hasError('hasEmptyCaracterError') ) )}"
                      (onTextChange)="numEstimationTextChange($event)">
              <p-header>
                <app-p-editor-header></app-p-editor-header>
              </p-header>
            </p-editor>

            <small *ngIf="submittedRequired && ( f.estimation?.errors?.required || f.estimation.hasError('hasEmptyCaracterError') )"
                   class="p-error block">{{'MESSAGE.ERROR.CHAMPS_OBLIGATOIRE' | translateI18n}}</small>
          </div>
        </section>
      </div>

    </div>
  </div>

</form>

