import {ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {FormGroup, FormGroupDirective} from "@angular/forms";
import {ProfilesUser} from "../../../models/user/profiles-user";
import {UserService} from "../../../service/user/user.service";
import {User} from "../../../models/user/user.model";
import {Tiers} from "../../../models/tiers/tiers";

@Component({
  selector: 'app-gestionnaire',
  templateUrl: './gestionnaire.component.html',
  styleUrls: ['./gestionnaire.component.scss']
})
export class GestionnaireComponent implements OnInit {

  @Input()
  formGroupName: string;
  form: FormGroup;

  @Input()
  statuts: any[];


  userProfile : any[] = [ProfilesUser.EXPERT_EXTERNE, ProfilesUser.EXPERT_INTERNE];

  expertUser : User;

  tier : Tiers;

  experUsers : User[];
  filteredExpert: any[];
  filteredItems: any[];

  tiers : Tiers[];
  filteredtiers: any[];
  filteredItemsTiers : any[];
  selectedTiers: User;

  constructor(private rootFormGroup: FormGroupDirective, private cdref: ChangeDetectorRef, private userService : UserService) { }

  ngOnInit(): void {
    this.form = this.rootFormGroup.control.get(this.formGroupName) as FormGroup;
    this.userService.getAllUserByProfiles(this.userProfile).subscribe(expertUser => {
      this.experUsers = expertUser;
    });

    this.userService.getAllTiersExpertise().subscribe(tiers => {
      this.tiers = tiers;
    })
  }

  ngAfterContentChecked() {
    this.cdref.detectChanges();
  }

  /**
   * auto completion utilisateur expert
   * @param event
   */
  searchExpertUser(event : any) {

    let filtered : any[] = [];
    let query = event.query;
    if(this.experUsers.length > 0) {
      this.experUsers.forEach(expert => {
        if (expert.nom.toLowerCase().indexOf(query.toLowerCase()) === 0 ||
          expert.prenom.toLowerCase().indexOf(query.toLowerCase()) === 0) {
          expert['nomComplet'] = expert.prenom + ' ' + expert.nom;
          filtered.push(expert);
        }
      })
      this.filteredExpert = filtered;
    }
  }

  searchTiers(event : any) {

    let filtered : any[] = [];
    let query = event.query;
    if(this.tiers.length > 0) {
      this.tiers.forEach(tiers => {
        if ((tiers.nom !== null && tiers.nom.toLowerCase().indexOf(query.toLowerCase())) === 0 ||
          (tiers.prenom !== null && tiers.prenom.toLowerCase().indexOf(query.toLowerCase()) === 0) ) {
          tiers['nomComplet'] = tiers.prenom + ' ' + tiers.nom;
          filtered.push(tiers);
        }
        else if(tiers.raisonSociale !== null &&  tiers.raisonSociale.toLowerCase().indexOf(query.toLowerCase()) === 0) {
          tiers['nomComplet'] = tiers.raisonSociale;
          filtered.push(tiers);
        }
      })
      this.filteredtiers = filtered;
    }
  }

}
