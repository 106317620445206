export const frJson = {

  AUTH : {
    FORM : {
      LOGIN : {
        LABEL: {
          ID: "Identifiant",
          PASSWORD: "Mot de passe",
          BUTTON_SUBMIT: "Connectez-vous",
          SSO_BUTTON : "Connectez-vous par SSO",
          SSO_TITLE : "Connexion par SSO"
        },

        LINK: {
          FORGOT_PASSWORD: "Mot de passe oublié"
        }
      },
      CODE : {
        FORM : {
          TITLE : "Entrez le code de sécurité"
        }
      }
    },
    MESSAGE : {
      ERROR : {
        AUTH_FAILED : "Authentication échouée",
        //CODE_WRONG : "Code d'authentification non reconnu. Veuillez vous reconnecter.",
        CODE_EXPIRED : "Code expiré : Nouveau code renvoyé sur votre boite mail",
        PASSWORD_EXPIRED : "Mot de passe expiré",
        CODE_INCORRECT : "Le code saisi est incorrect",
        ID_OR_PASSWORD_INCOORECT : "Vos identifiants sont incorrects",
        PRECONDITION_FAILED : "Vous n'êtes pas autorisés à vous connecter. Veuillez contacter votre administrateur.",
        PRECONDITION_FAILLED_LOGIN : "Vos identifiants sont incorrects. Merci de réessayer. \n" +
          "En cas de besoin, n'hésitez pas à contacter votre administrateur de l'application.",
        CODE_WRONG : "Vous n'êtes pas autorisés à vous connecter. Veuillez contacter votre administrateur ou réessayer plus tard."
      },

      INFO : {
        TITLE_CODE_SEND :"Code d'authentification",
        CODE_SEND : "Vous devriez bientôt recevoir un e-mail avec un code d’authentification.<br/>" +
          "Veuillez saisir ce code pour terminer l'authentification.<br/>" +
          "Si vous ne retrouvez pas cet e-mail, vérifiez vos courriers indésirables et votre corbeille.",
        LIEN_EXPIRE : "Le lien demandé a expiré",
        TITRE_LIEN_EXPIRE : 'Lien expiré'
      }
    }
  },

  HEADER : {
    SSTITRE_PROFIL_OR_DASHBORD : "Dernière connexion"
  },

  NAVIGATION :{
    ACCUEIL : "Accueil",
    AFFECTATION : "Affectations",
    VALIDATION : "Validations",
    PROJET_ENVOYE : "Projets envoyés",
    DOSSIER : "Dossiers en cours",
    DEMANDE : {
      CREATION : "Créer une demande",
      ITEMDEMANDE : {
        RAPPORT : "Rapport d'expertise",
        AVIS :  "Avis de valeur"
      },
      SUIVI : "Demandes ouvertes"
    },
    RESEARCH : "Recherche avancée",
    PROFIL : "Profil",
    MONITORING : "Admin technique",
    DECONNEXION : "Déconnexion",
    AIDE : "Aide"
  },

  DASHBORD : {
    AFFECTATION : "Affectations",
    VALIDATION : "Validations",
    RECHERCHE_DOSSIER : "Recherche avancée",
    DOSSIER_EN_COURS : "Dossiers en cours",
    DOSSIER_ANNEE_EN_COURS : "Dossier année en cours",
    CREER_DEMANDE : "Créer une demande",
    DEMANDE_EN_COURS : "Demandes ouvertes",
    PROFIL : "Profil",
    SSTITREPROFIL : "Gestion de mon mot de passe",
    PROJET_ENVOYE : "Projets envoyés",
    LIEN_CREER_DEMANDE :{
     RAPPORT : "Rapport d'expertise",
      AVIS : "Avis de valeur"
    },
    MESSAGE : {
      INFO : {
        INFO_DOC_TELECHARGER: "Vous avez {0} nouveaux documents à télécharger",
        LABEL_LINK_NB_DOC : "voir"
      }
    },
    MONITORING : "Admin technique",
    SSMONITORING : "ADMINISTRATEUR TECHNIQUE"
  },
  MONITORING : {
    HEADER: {
      TITLE: "Administrateur technique",
    }
  },

  FORM_QUICK_SEARCH : {
    TITLE : "Recherche rapide",
    NUM_DOSSIER : "Numéro de dossier",
    NUM_CONTRAT : "Vos références",
    CLIENT : "Nom / Raison sociale",
    AFFAIRE : "Affaire",
  },


  FORGGOTTEN_PASWORD : {
    FORM : {
      TITLe : "Mot de passe oublié",
      LABEL : {
        EMAIL : "Veuillez renseigner votre email",
        BLUTTON_SUBMIT : "Envoyer"
      },
      MESSAGE : {
        ERROR : {
          IVALID_FORMAT_EMAIL : "Veuillez saisir une adresse mail valide",
          ERROR_SEND_EMAIL : "Erreur lors de la réinitialisation du mot de passe. Veuillez contacter votre administrateur",
          ERROR_MAIL_NON_RECONNU : "Votre compte n'est pas reconnu"
        },
        SUCCESS : {
            TITLE : "E-mail de réinitialisation du mot de passe envoyé",
            SUCCESS_SEND_EMAIL : "Vous devriez bientôt recevoir un e-mail vous permettant de réinitialiser votre mot de passe. <br/>" +
              "Si vous ne retrouvez pas cet e-mail, vérifiez vos courriers indésirables et votre corbeille."
        }
      }
    }

  },

  FORM_CHANGE_PASSWORD : {
    TITLE : "Changer votre mot de passe",
    TITLE_PASSWORD_EXPIRED : "Mot de passe expiré : Veuillez modifier votre mot  de passe",
    LABEL : {
      CURRENT_PASSWORD : "Votre mot de passe actuel",
      NEW_PASSWORD : "Nouveau mot de passe",
      CONFIRM_PASSWORD : "Répéter mot de passe",
      BUTTON_SUBMIT : "Valider"
    },
    POLITIQUEPASSWORD: {
      N_CHARACTER : "Au moins {0} caractères",
      LOWERCASE : "Au moins 1 lettre majuscule",
      UPPERCASE : "Au moins 1 lettre miniscule",
      NUMBER : "Au moins 1 chiffre",
      SPECIAL_CHARACTER : "Au moins 1 caractère spécial",
      DIFFERENT_N_LAST_PASSWORD : "Différent des {0} derniers mot de passe"
    },

    MESSAGE : {
      ERROR : {
        NEW_PASSWORD: "Le mot de passe est obligatoire",
        POLITIQUE_PASSWORD: "Le nouveau mot de passe saisi ne respecte pas la politique de mot de passe du Groupe",
        NEWPASSWORD_NOT_MATCH_CURRENTPASSWORD: "Le nouveau mot de passe et le mot de passe de confirmation ne correspondent pas",
        CURRENT_PASSWORD : "L'ancien mot de passe rentré n'est pas correct",
        ERROR_SERVER : "Une erreur s'est produite",
        CONNFIRM_PASSWORD: "La confirmation du mot de passe est obligatoire"
      },

      SUCCESS : {
        CHANGE : "Le mot de passe  a été mis à jour avec succès."
      }
    }
  },

  BUTTON_LABEL : {
    MAJ : "Mettre à jour",
    CANCEL : "Effacer",
    ADD : "Ajouter",
    MODIFY : "Modifier",
    RECHERCHER : "Rechercher",
    CONNECTER: "Connectez-vous",
    VALIDER: "Valider",
    SUBMIT: "Envloyer",
    ADVANCED_SEARCH : "Recherche avancée",
    AFFECTER : "Affecter",
    OUVRIR : "Ouvrir",
    ACTION : "Actions",
    APPLIQUER : "Appliquer",
    SAVE : "Sauvegarder",
    CLOSE : "Fermer",
    ANNULER : "Annuler",
    ENREGISTRER : "Enregistrer",
    DELETE : "Supprimer",
  },

  DATE_FILTER : {
    FORMAT : "dd/mm/yy",
    EQUALS : "Égale",
    DIFFERENT : "Différent",
    BEFORE : "Avant",
    AFTER : "Après"
  },

  //MESSAGE COMMUN
  MESSAGE : {
    SUCESS: {

    },
    ERROR: {
      CHAMPS_OBLIGATOIRE : "Ce champ est obligatoire",
      CHAMP_OBLIGATOIRE : "Champ est obligatoire",
      MS_CHAMPS_OBLIGATOIRE: "Veuillez saisir les champs obligatoires",
      EMAIL_INVALID : "L'adresse mail {0} est invalide",
      REGEX_PATTERN : "L'entrée contient des caractères non valides"
    }
  },

  PLACEHOLDER :{
    CHOOSE :"Choisir...",
    RENSEIGNEZ: "Renseignez",
    ASSISTANTE : "Assistante",
    EXPERT : "Expert",
    COMMENTAIRE: "Ajouter un commentaire",
    NA: "N/A"
  },

  DOSSIER : {
      TVAVISDOSSI: "Avis de valeur",
      TVRAPPORTXX : "Rapport d'expertise",
      NUM_CONTRAT : "Vos références",
      NUM_DOSSIER : "N° Dossier",
      STATUT : "Statut",
      TIERS : "Tiers",
      NOM_CLIENT : "Nom",
      PRENOM_CLIENT : "Prénom",
      CP : "CP",
      CONTACT : "Contact",
      VISITE : "Visite",
      TYPE_LIVRABLE : "Type de livrable",
      ECHEANCE : "Echéance",
      ANNULER : 'Annulation',
      AFFAIRE : "Affaire",
      DEMANDEUR : "Demandeur",
      OBSERVATION_DEMANDEUR : "Observation demandeur",
      AUCUN_DOSSIER : "Aucun dossier",
      PILOTE : "Pilote",

      RAISON_SOCIALE : "Raison sociale",

      REQUEST : "Demande",
      ASSIGNMENT : "Affectation",
      SYNTHESE : "Synthèse",
      ENVOI_LIVRABLE : "Envoi du livrable",
      ENVOI_PROJET : "Envoi du projet",
      VALIDATION : "Validation",
      EXPERT : "Expert",
      VALEUR_VENALE : "Valeur vénale",
      LIVRABLE : "Livrable",
      DOSSIER :"Dossier",
      ADMINISTRATOR_GESTIONNAIRE : "Gestionnaire",
      BIEN : "Bien",
      HT : "Honoraires H.T.",
      RESULTAT_EXP : "Résultat expertise",
      DOPCUMENT : "Documents",
      OBSERVATION : "Observations",
      HONORAIRE_CLIENT : "Honoraires client",
      FRAIS_REFACTURABLE_CLIENT : " Frais refacturables au client",
      TOTAL_REFACTURABLE_CLIENT: "Total refacturables  au client",
      POURCENT_NEGOCIATION : "Négociation (%)",
      MONTANT_NEGOCIATION:"Montant négociation ",

      VALEUR : "Valeur",
      PRIXM2 : " Prix/m²",
      REGIME_FISCAL : "Régime fiscal",
      TAUX_FISCAL : "Taux fiscalité",
      VALEUR_EN_VENTE_FORCEE : "Valeur en vente forcée",
      SH : "SH",

      RESULTAT_EXPERTISE : "Résultat de l'expertise",

      VALEUR_EN_ETAT_FUTUR_ACHEVEMENT: "Valeur en état futur d'achèvement",
      VALEUR_APRES_TRAVAUX: "Valeur après travaux ",
      VALEUR_LOCATIVE : "Valeur locative",
      LIQUIDITE_BIEN : "Liquidité du bien",
      DELAI_COMMERCIALISATION : "Délai de commercialisation",

      POURCENT_PILOTAGE : "Pilotage (%)",
      MONTANT_PILOTAGE : " Montant pilotage",
      HONORAIRES_EXPERT : "Honoraires expert",
      FRAIS_EXPERT : "Frais expert",
      TOTAL_EXPERT : "Total expert",
      CALCUL_KILOMETRAGE : "Calcul du kilométrage ",

      ADRESSE : "Adresse",

      DEPARTEMENT_INTERVENTION : "Département d'intervention",

      RECHERCHER : "Recherche",

      NO_MESSAGE_DETECTED : "Aucune modification détectée.",

      CHOIX_LIVRABLE : "Choix du livrable",
      DUPLIQUER_DOSSIER : "Dupliquer le dossier : {0}",

      PAGINATOR : {
        MESSAGE : {
          AFFICHAGE : "Affichage",
          A : "à",
          SUR : "sur"
        }
      },

      STATUTS : {
          A_AFFECTER : "A affecter",
          ENCOURS : "En cours",
          D_ATTENTE_V1 : "En attente validation V1",
          A_FACTURER : "A facturer",
          F_ENCOURS : "Facturation en cours",
          FACTUREE : "Facturé",
          ANNULE : "Annulé",
      },

    MESSAGE : {
        AUCUN_RESULTAT : "Aucun résultat",
        UPDATE_DOSSIER : "Le dossier n°{0} a été mis à jour avec succès",

        ERROR : {
          UPDATE_DOSSIER : "La mise à jour du dossier n'a pas abouti.",
          CODE_POSTAL_FR : "Le code postal en France est une suite de cinq chiffres",
          EXPORT_EXCEL : "Erreur survenue lors de l'export du tableau",
          CARACTERE_NUMERO_DOSSIER : "Veuillez saisir au moins {0} caractères pour le numero de dossier",
          POURCENTAGE_PILOTAGE : "Le pourcentage de pilotage doit etre compris entre 0 et 100",
          GET_DOSSIER_BY_ID : "Erreur de recuperation des données"
        },
        VEROUILLAGE_1 : "Vous êtes en mode contrôle sur la fiche depuis le {0}. Elle est en lecture seule pour les autres utilisateurs durant cette phase.",
        VEROUILLAGE_2 : "La fiche est passée en mode contrôle de dossier par {0} le {1}. Elle est en lecture seule durant cette phase."
    },

      CONFIRMATION_DIALOG : {
          ACCEPT_BUTTON : "Oui",
          REJET_BUTTON : "Non",
          HEADER : "Confirmation",
      },

    AFFECTATION : {
      HEADER: {
        TITLE : "Affectations",
        SSTITLE :"dossiers",
        AFFECTER : "Affecter un expert"
      },
      INFO : {
        CONFLIT : "Conflit d'intérêt détecté"
      },
      MESSAGE : {
        SELECTDOSSIER : "Dossier selectionné",
        DESELECTDOSSIER : "Dossier retiré de la sélection",
        AFFECTS : "Vos dossiers ont bien été affectés à l'expert {0} et l'assistante {1}",
        NOTAFFECTS : "L'affectation des dossiers n'a pas abouti",
        AFFECT : "Le dossier a été affecté à l'expert {0} et l'assistante {1}",
        NOTALLAFFECTS : "L'affectation de {0}/{1} dossiers n'a pas abouti. Veuillez réessayer svp. Si le problème persiste, merci de contacter votre administrateur.",
        MAJ : 'Le dossier {0} a été mis à jour.',
        SELECT_EXPERT : "Les informations de l'expert sont incorrectes",
        SELECT_EXPERT_1 : "Veuillez renseigner un expert ",
        SELECT_ASSISTANTE : "Les informations de l'assistante sont incorrectes",
        SELECT_ASSISTANTE_1 : "Veuillez renseigner une assitante",
        ERROR_SERVER : "Les données n'ont pas été mis à jour",
        CODE_POSTAL_FR : "Le code postal en France est une suite de cinq chiffres",
        SUCCES_REPASSER_AFFECTER : "Le dossier n°{0} a été repassé au statut en attente d'affectation.",
        ERROR_REPASSER_AFFECTER : "La mise à jour du dossier n°{0} n'a pas abouti.",
        RELANCE : {
          SUCCES : "La relance du dossier n°{0} est en succès.",
          ERREUR : "La relance du dossier n°{0} n'a pas abouti."
        }
      },
    },

    ACTION_TABLE_RESULTA_EXPERT: {
        DATE_CONTACT : "Saisir la date de contact",
        DATE_VISITE : "Saisir la date de visite",
        MODIFY_HONORAIRE : "Modifier les honoraires",
        EDITER_LIVRABLE : "Editer le livrable"
    },

    EXPERT_TAB : {
      HEADER: {
        TITLE: "Dossiers en cours",
        SSTITLE: "dossiers"
      },
    },

    GESTIONNAIRE : {
      HEADER: {
        TITLE: "Validations",
        SSTITLE :"dossiers"
      },
      INFO : {
        CONFLIT : "Conflit d'intérêt détecté"
      },
      ACTION : {
        REPASSER_AFFECTER : 'Repasser en "A Affecter"',
        AFFECTER : "Affecter",
        RELANCER : "Relancer",
        DUPLIQUER : "Dupliquer",
        MODIFIER_LIVRABLE : "Modifier Livrable",
        REJETER : "Rejeter le livrable",
        VALIDER : "Valider le livrable",
        CONTROLE : "Contrôler le livrable",
        ENVOYER_PROJET : "Envoyer un projet",
        ANNULER : "Annuler",
        REACTIVER : "Reactiver",
        ACTION_TITLE : "Actions",
        AFFECTER_EXpert : "Affecter à un expert",
        DEBLOQUER_DOSSIER : "Débloquer dossier verrouillé"
      },
      LIVRABLE : {
        LIVRABLE_TITLE : "Livrables",
        RAPPORT : "Rapport (pdf)",
        SYNTHESE : "Synthèse (pdf)",
        AVIS : "Avis de valeur (pdf)",
      },
      MESSAGE : {
        SUCESS_ANNULATION_DOSSIER : "Le dossier n°{0} a été annulé",
        ERROR_ANNULATION_DOSSIER : "L'annulation du dossier n°{0} n'a pas abouti",
        ERROR_VEROUILLER_DOSSIER : "Le verouillage du dossier n°{0} n'a pas abouti",
        ERROR_DEBLOQUER_DOSSIER : "Le déblocage du dossier n°{0} n'a pas abouti",
        ACTION_CONFIRMATION: "Etes-vous sûr de faire cette action ?",
        SUCESS_REACTIVATION_DOSSIER : "Le dossier n°{0} a été réactivé.",
        ERROR_REACTIVATION_DOSSIER : "La réactivation du dossier n°{0} n'a pas abouti.",
        SUCESS_DUPLICATION_DOSSIER : "Le dossier a été dupliqué avec succès. Le nouveau dossier n° {0} est en attente d'affectation. Les informations relatives à ce nouveau dossier ont été ré-initialisées. {1}",
        ERROR_DUPLICATION_DOSSIER_NEGO_NON_TROUVE : "La duplication du dossier n° {0} n'a pas abouti. Merci de vérifier les informations associées à la négociation chapeau.",
        ERROR_DUPLICATION_DOSSIER : "La duplication du dossier n° {0} n'a pas abouti.",
        SUCCCESS_ENVOYER_PROJET : "Le projet du dossier n°{0} a été envoyé avec succès.",
        ERROR_ENVOYER_PROJET : "L'envoi du projet du dossier n°{0} n'a pas abouti.",
        DUPPLIQUER_ERROR : "Le choix du type de livrable est obligatoire",
        CLICK_OUVRIR : "Cliquez ici pour l'ouvrir",
        CHOIX_AGENT : "Le choix d'un nouveau agent est obligatoire",
        TYPE_LIVRABLE_ERROR : "Le dossier {0} ne peut pas être dupliqué car les conditions de selection du livrable ne sont pas remplies",
        AUCUN_AGENT_ACTIF : "Il n'y a pas d'autres agents actifs. \n Merci de contacter votre administrateur afin d'en rajouter un pour pouvoir dupliquer ce dossier",
        REJETER : {
          SUCCESS : "Le dossier n°{0} a été rejeté avec succès.",
          ERROR_SERVER : "Le rejet du dossier n°{0} n'a pas abouti.",
          RAPPORT_ERROR : "Le choix du type de rejet est obligatoire",
          CHOIX_TYPE_REJET : "Choisir le type de rejet",
          RAPPORT_SYNTHESE : "Rapport et Synthèse",
          AVIS_VALEUR_SYNTHESE : "Avis de valeur et Synthèse",
          RAPPORT : "Rapport",
        },
        VALIDER_LIVRABLE : {
          PRENOM : "Prénom",
          NOM : "NOM",
          TITLE_CHOOSE_SIGNATAIRE : "Choix du signataire",
          DIALOG : "L'envoi du livrable est définitif. Confirmez-vous cet envoi ?",
          ERROR_CHOIX_SIGNATAIRE :"Vous devez choisir un signataire",
          SUCCESS : "Le dossier n°{0} a été validé avec succès.",
          ERROR_SERVER : "La validation du  dossier n°{0} n'a pas abouti.",
        }
      },
    },

    ADRESSE_FORM : {
        ADR_FORMAT : "Adresse Google",
        NUM_ADR : "Numéro",
        LIEU_DIT : "Lieu dit",
        STREET_NAME : "Nom de voie",
        POSTAL_CODE : "Code Postal",
        CITY : "Ville",
        COUNTRY : "Pays",
        DEPARTMENT : "Departement",
        COMP_ADR : "Complément d'adresse",
        BOITE_POSTAL : "Boite postale",
        INDISPO_GOOGLE : "La saisie d'adresse Google est indisponible momentanément. Veuillez saisir manuellement l'adresse."
    },

    ADVANCED_SEARCH : {
      LABEL : {
        TYPE_PROFIL : "Type de profil",
        NOM_RAISON_SOCIALE : "Nom / Raison sociale",
        PRENOM : "Prénom",
        TYPE_DOC : "Type de document",
        RAPPORT : "Rapport d'expertise",
        AVIS : "Avis de valeur",
        STATUT : "Statut",
        PERIODE_CREATION : "Période de création",
        ADRESSE : "Adresse du bien",
        DU : "Du",
        AU : "Au",
        TITRE_MODAL : "Recherche avancée",
        PERIODE : 'Période de création',

        ELARGIR_RECHERCHE : "Elargir la recherche au sein de l'agence",
        OUI : "Oui",
        NON : "Non",
        AFFAIRE : "Affaire"

      },
      MESSAGE : {
        ERROR : "Veuillez renseigner au moins un critère"
      }
    },

    DEMANDE : {
      RECHERCHE : {
        HEADER : {
          TITLE : "Demandes ouvertes",
          SSTITRE : "demandes ouvertes"
        },
        POPUP_RESEARCH : "Rechercher",
      },
      CREATION : {
          PHYSICAL_PERSON : "Personne physique",
          CORPORATION : "Personne morale",
          NOM : "Nom",
          PRENOM : "Prénom",
          NOM_MORAL : "Raison sociale",
          REPRESENTANT_LEGALE : "Représentant légale",
          PHONE : "Télephone {0}",
          OPTIONAL : "Facultatif",
          EMAIL : "Email {0}",
          CIVILITY:"Civilité",
          CONTRAT : "Vos références",
          FRAIS_EXPERTISE : "Frais d'expertise",
          ADRESSE : "Adresse",
          ID_CLIENT : "Identique au client",
          OTHER : "Autre",
          QUALITE : "Qualité",
          TYPE_BIEN : "Type de bien",
          ETAGE : "Etage",
          PRETEUR : "Preteur",
          RATTACHEMENT : "Rattachement",
          ENTITE : "Entité",
          DEMANDEUR : "Demandeur",
          PORTABLE : "Portable",
          TELEPHONE : "Tel",
          AFFAIRE : "Affaire",
          CONTACT : "Contact",
          DOCUMENT : "Document",
          BIEN : "Bien(s)",
          COMMENTAIRE : "Commentaires",
          ENVOYER : "Envoyer la demande",
          MAX_CARACTERE : "Maximum {0} caractères.",
          SECTION : "Sections",
          BIEN_EXPERTISER : "Bien(s)",
          QUALITE_CONTACT : "Qualité du contact :",

          MESSAGE : {
              SUCCESS : "La demande {0} a bien été créée {1}.",
              CLiCK : "Cliquez ici pour l'ouvrir",
              ERROR_CONTRAT_INVALID: "Le numéro de contrat peut comporter 7 caractères minimum.",
              ERROR_BACKEND : "Une erreur a eu lieu durant la création de la demande. Réessayez ultérieurement ou contactez votre administrateur."
          }

      }
    },

    POPUP : {
      HONORAIRES : {
        TITRE: "Modifier les honoraires",
        HONORAIRES: "Honoraires expert",
        FRAIS: "Frais expert",
        TOTAL: "Total expert",
        MODIFIER: "Modifier",
        ANNULER: "Fermer",
        MESSAGE_SUCCESS: "Les honoraires et frais du dossier ont été modifiés.",
        MESSAGE_ERROR: "Une erreur s'est produite. Réessayez ultérieurement ou contactez votre administrateur."
      },
      DATE : {
        CONTACT : {
          TITRE: "Date de contact",
          MESSAGE_SUCCESS: "La modification a bien été sauvegardée",
          MESSAGE_ERROR: "Une erreur s'est produite. Réessayez ultérieurement ou contactez votre administrateur.",
          MESSAGE_ERROR_DATE : "Veuillez renseigner une date de contact ou un commentaire"
        },
        VISITE : {
          TITRE: "Date de visite",
          MESSAGE_SUCCESS: "La modification a bien été sauvegardée",
          MESSAGE_ERROR: "Une erreur s'est produite. Réessayez ultérieurement ou contactez votre administrateur.",
          MESSAGE_ERROR_DATE : "Veuillez renseigner une date de visite ou un commentaire"
        },
        COMMENTAIRE: "Commentaire",
        MODIFIER: "Valider",
        ANNULER: "Fermer",
        MESSAGE_ERROR_CARACTERE_COMMENTAIRE: "Le commentaire ne doit pas depasser 400 caractères",
      }
    }
  },

  DOCUMENT : {
    LISTE : {
      HEADER : "Documents",
      NOMBRE_RESULTATS : "Documents {first} à {last} de {totalRecords} résultats",
      ENTETE : {
        NOM : "Nom document",
        CATEGORIE : "Catégorie",
        DATE : "Date"
      }
    },
    UPLOAD : {
      BOUTON : {
        LABEL_LONG : "Ajouter un document",
        LABEL_COURT : "Ajouter"
      },
      MESSAGE : {
        SUCCESS : 'Document(s) suivant(s) rattaché(s) avec succès :<br/>{0}',
        WARNING : {
          TAILLE_MAX_DEPASSEE : "Impossibilité de rattacher le(s) document(s) suivant(s) car trop volumineux. La taille maximale autorisée est {1} Mo :<br/>{0}",
          DOCUMENT_HOMONYME : "Impossibilité de rattacher le(s) document(s) suivant(s) car un document du même nom est déjà rattaché à ce dossier :<br/>{0}",
          DOCUMENT_NOM_INTERDIT : "Impossibilité de rattacher le(s) document(s) suivant(s) car leur(s) nom(s) contien(nen)t des caractères non autorisés :<br/>{0}",
          EXTENSIONS_NON_AUTORISEES : "Impossibilité de rattacher le(s) document(s) suivant(s) car extension(s) non autorisée(s) :<br/>{0}"
        },
        ERROR : {
          UTILISATEUR_NON_AUTORISEE : "Vous n'avez pas le droit d'attacher des documents sur ce dossier.",
          INCONNUE : "Une erreur s'est produite lors du rattachement des documents {0}. Veuillez réessayer ultérieurement.",
          GENERIQUE : "Une erreur s'est produite lors du rattachement des documents. Veuillez réessayer ultérieurement.",
          DOCUMENTS_EXISTE : "Impossibilité de rattacher le(s) document(s) suivant(s) car un document du même nom est déjà rattaché à ce dossier :<br/>{0}",
        }
      },
      AIDE : "Taille maximale autorisée : 10Mo<br/>Extensions autorisées : .docm, .docx, .dot, .dotm, .dotx, .odp, .odp, .ods, .odt, .one, .pot, .potm, .potx, .pps, .ppsm, .ppsx, .ppt, .pptx, .pptm, .xlsb, .xlsm, .xlsx, .pdf, .jpg, .jpeg, .png, .bmp, .gif<br/>Le document ne peut pas avoir le même nom qu'un autre document attaché au dossier.",
    },
    TELECHARGEMENT : {
      ERROR : "Une erreur s'est produite durant le téléchargement du document."
    },
    SUPPRESSION : {
      CONFIRMATION : "Confirmez-vous la suppression du document ?",
      SUCCESS : "Le document a été supprimé.",
      ERROR : "Une erreur s'est produite durant la suppression du document."
    },
    MODIFICATION : {
      SUCCESS : "Le document a été modifié.",
      ERROR : "Une erreur s'est produite durant la modification du document."
    },
    NONLUS : {
      HEADER1 : "nouveau document à télécharger",
      HEADER2 : "nouveaux documents à télécharger",
      NOMBRE_RESULTATS : "Documents {first} à {last} de {totalRecords} résultats",
      ENTETE : {
        DATE : "Date",
        NUMERO_CONTRAT : "Vos références",
        NUMERO_DOSSIER : "N° Dossier",
        NOM_CLIENT : "Nom client",
        NOM : "Nom du fichier"
      }
    }
  },

  AVIS_VALEUR : {
    TITLE : "Avis de valeur sur dossier (sans visite)",
    NUMERO_DOSSIER : "Dossier n°",
    DEMANDEUR : "Demandeur",
    EXPERT : "Expert",
    BIEN_EXPERTISE : "Bien(s)",
    AFFAIRE : "Affaire",
    SECTIONS : "Sections",
    GENERALITE : "Généralités",
    MISSION : "Mission",
    DESIGNATION_SITUATION_JURIDIQUE : "Désignation",
    SITUATION_GEO : "Situation géographique",
    DESCRIPTION : "Description",
    SITUATION_LOCATIVE : " Situation locative",
    ELEMENTS_APPRECIATION : "Éléments d'appréciation",
    EVALUATION : "Évaluation",
    ANNEXES : "Annexes",
    PIECES_COMMUNIQUE : "Liste des pièces communiquées",
    LOC_ENV_DES : "Localisation, environnement, desserte",

    MISSION_TEXTE_1 : "Appréciation de la",
    MISSION_TEXTE_2 : "du bien immobilier sus visé, considéré en l'état et dans sa destination actuelle ; supposé",
    MISSION_TEXTE_3 : ". Cette mission s'inscrit dans un contexte",

    POPUP_CONFIRMATION_ENVOYER : "L'envoi de l'avis de valeur est définitif, vous ne pourrez plus le modifier une fois envoyé pour validation.<br>" +
      "Confirmez-vous cet envoi ?",
    POPUP_CONFIRMATION_ENVOYER_HEADER : "Attention !",
    POPUP_CONFIRMATION_GENERATION : "Etes-vous sûrs de faire cette action ?",
    GENERATION :"Génération",
    OBJ_MISSION : "Objet de la mission",
    MISSION_MENTION : "membre de l'Association Française des Sociétés d'Expertise Immobilière,",
    MISSION_SECTION : "La Société <b>{0}</b>, {1} a été missionnée," +
      " en date du <b>{2}</b> par " +
      "<b>{3}</b>" +
      ", représentée par <b>{4} {5}</b>." ,
    AUCUN_RESULTAT : "Aucun résultat",
    FAIT_A : "Fait à",
    LE :"le",

    LABEL : {
      PORTABLE : "Portable",
      TELEPHONE : "Tel",
      DATE_VALEUR : "Date de valeur",
      NUMERO_CONTRAT: "Vos références",
      ADRESSE : "Adresse",
      CODE_POSTAL : "Code postal",
      VILLE : 'Ville',
      PAYS : "Pays",
      REGIME_JURIDIQUE : "Régime juridique",
      NATURE_BIEN : "Nature des biens",
      NUMERO_LOT : "Numéros de lots",
      PARKING_ANNEXE : "Parkings/Annexes",
      SUPERFICIE_TERRAIN :"Superficie terrain",
      REFERENCE_CADASTRALE : "Références cadastrales",
      TERRAIN : "Terrain",
      DATE_CONSTRUCTION : "Date construction",
      NOMBRE_NIVEAU : "Nombre de niveaux",
      TYPE_SURFACE : "Type surface",
      SURFACE : "Surface",
      CONSTRUCTION : "Construction",
      DISTRIBUTION : "Distribution",
      AMENAGEMENT_INTERRIEUR : "Aménagements intérieurs et éléments d'équipements",
      ETAT_GENERAL : "État général",
      OBSERVATION :"Observations",
      ELEMENT_FAVORABLE : "Eléments favorables",
      ELEMENT_DEFAVORABLE : "Eléments défavorables",
      CDT_OCCUPATION : "Conditions d'occupation",
      DUREE_BAIL : "Durée du bail",
      PRISE_EFFET_BAIL : "Prise d'effet du bail le",
      LOYER : "Loyer",
      SOIT : "soit",
      COMMENT_TYPE_REGIME : "Commentaire type régime juridique",
      EMAIL : "Email",
      METHODOLOGIE : "Méthodologie",
      CONCLUSION : "Conclusion",
      LIQUIDITE_BIEN : "Liquidité du bien",
      DELAI_COMMERCIALISATION : "Délai de commercialisation",
      AB_PREV_VENTE_JUDICIAIRE: "Abattement à prévoir en cas de vente judiciaire",
      PRIXM2 : " Prix moyen au m²",
      RENSEIGNER : "Renseignez"
    },

    TEXTE: {
      MEMBRE_AFSEI: "membre de l'Association Française des Sociétés d'Expertise Immobilière, ",
      LA_SOCIETE: "La Société",
      ADHERENT_MISSIONNEE: "a été missionnée, en date du {0} par ",
      REPRESENTEE_PAR: "représentée par {0} {1}.",
      DISCLAIMER1: "Les informations reprises ci-dessous sont celles figurant dans les pièces transmises ou communiquées verbalement par le client. En l’absence de visite du site, l’avis de valeur qui en découle s’appuie sur ces seuls éléments et ne saurait être assimilé à une expertise en forme de rapport détaillé avec visite du bien immobilier.",
      DISCLAIMER2: "L’ensemble des surfaces ici considérées ne saurait donc être retenu qu’à titre strictement indicatif, sous réserve de confirmation par un Géomètre-Expert.",
      DISCLAIMER3: "Le bien immobilier est estimé en l’état, et supposé conforme aux dispositions d’urbanisme actuellement en vigueur.",
      EVALUATION: "Notre évaluation prend en compte les caractéristiques générales et particulières du bien immobilier ainsi que son positionnement dans le marché immobilier sectoriel tel qu'exposé dans notre étude et sur la base d'une {0} de {1} m².",
      AVIS: "Nous retiendrons pour avis :  ",
      UTILISATION: "Le client ne peut utiliser l'avis de valeur sur dossier, de façon partielle, en isolant telle ou telle partie de son contenu. Le présent avis de valeur sur dossier, en tout ou partie, ne pourra être cité, ni même mentionné dans aucun document ni aucune circulaire et autre déclaration, destinés à être publiés. Il ne pourra pas non plus être publié d'une quelconque manière, sans l'accord écrit de l'expert, quant à la forme et aux circonstances dans lesquelles il pourrait paraître.",
      CONVENTION : "dans le cadre d'une convention en date du {0}."
    },

    MESSAGE : {
      ERROR_FORM : "Veuillez corriger les erreurs",
      MAX_CARACTERE : "Maximum {0} caractères",
      AUCUN_RESULTAT : "Aucun résultat",
      ERROR_CARACTERE_COMMENTAIRE: "Le commentaire ne doit pas depasser {0} caractères",
      ERROR_CARACTERE_INPUT: "{0} caractères au maximum",
      SAVE_SUCCES : "L'avis de valeur n°{0} a été mis à jour avec succès.",
      SAVE_ERROR : "La mise à jour de l'avis de valeur n'a pas abouti.",
      NO_MESSAGE_DETECTED : "Aucune modification détectée.",
      SUCESS_SEND_FOR_VALIDATION : "L'avis de valeur n°{0} a été envoyé pour validation avec succès.",
      ERROR_SEND_FOR_VALIDATION : " L'envoi pour validation de l'avis de valeur n°{0} n'a pas abouti.",
      ERROR_CONDITION_SEND_FOR_VALIDATION : "Vous ne pouvez pas envoyer l'avis de valeur {0} pour validation, car le dossier ne remplit pas les conditions réquises",
      GENERER : "Un nouvel avis de valeur a été généré avec succès pour le dossier n°{0}.",
      GENERER_ERROR : "La génération de l'avis de valeur n'a pas abouti.",
      SURFACE_POSITIVE : "Veuillez saisir une valeur positive pour la surface.",
      SAUVEGARDE : "Voulez-vous vraiment enregistrer les modifications ?",
      ERROR_ABAT : "Le pourcentage d'abattement doit être compris entre 0 et 100",
      CHAMPS_OBLIGATOIRE : "Veuillez renseigner les champs obligatoire.",
      ERROR_SURFACE : "Veuillez saisir une valeur supérieur à 0 pour la surface.",
      ETES_VOUS_SUR : "Etes-vous sûr ?",
      CONFIRMER : "Confirmer pour continuer",
      TELECHARGER : "Télécharger"
    },

    ACTIONS : {
      TITLE : "Actions",
      SAUVEGARDER : "Sauvegarder",
      GENERER : "Générer",
      ENVOYER : "Envoyer",
      OUVRIR_FICHE_DOSSIER : "Ouvrir la fiche dossier"
    }

  },

  RAPPORT_EXPERTISE : {
    TITLE: "Rapport d'expertise",
    NUMERO_DOSSIER: "Dossier n°",
    SECTIONS : "Sections",
    SYNTHESE : "Synthèse",
    SYNTHESE_MISSION: "Synthèse de la mission",
    MISSION : "Mission",
    DESIGNATION : "Désignation",
    SITUATION_GEO : "Situation géographique",
    DESCRIPTION : "Description",
    ENVIRONNEMENT_URBANISME : "Environnement et urbanisme",
    CODITION_OCCUPATION : "Conditions d'occupation",
    ETUDE_MARCHE : "Étude de marché",
    ESTIMATION : "Estimation",
    CONCLUSION :"Conclusion",
    ANNEXES : "Annexes",
    PIECES_COMMUNIQUE : "Liste des pièces communiquées",
    NUM_LOT_TANTIMES : "Numéros de lots et tantièmes",
    OBSERVATIONS : "Observations",
    CADASTRE : "Cadastre",
    PLAN_CADASTRAL : "Plan cadastral",
    LOCALISATION : "Localisation",
    DESSERTE : "Desserte",
    PHOTO_ENVIRONNEMENT : "Photos de l‘environnement",
    PHOTO_EXTERIEUR : " Photos extérieures",
    PHOTO_INTERIEUR : "Photos intérieures",
    TERRAIN : "Terrain",
    CONSTRUCTION : "Construction",
    DISTRIBUTION :"Distribution",
    AMENAGEMENT_INTERRIEUR: "Aménagements intérieurs",
    EQUIPEMENT : "Éléments d'équipements",
    ETAT_GENERAL : "État général",
    SURFACES : "Surfaces",
    DIAGNOSTIQUE_ENV : "Diagnostics environnementaux",
    URBANISME : "Urbanisme",
    PPR : "P.P.R. (Plan de prévention des risques)",
    ANALYSE_MARCHE : "Analyse du marché",
    ANALYSE_MARCHE_LOCATIF : "Analyse du marché locatif",
    POSITIONNEMENT_BIEN_MARCHE: "Positionnement du bien dans son marché",
    ELEMENTS_APPRECIATION : "Éléments d'appréciation",
    EVALUATION : "Évaluation",
    METHODE : "Méthode",

    POPUP_CONFIRMATION_ENVOYER_SYNTHESE : "L'envoi de la synthèse est définitif, elle ne sera plus modifiable une fois envoyée.<br>" +
      "Confirmez-vous cet envoi ?",
    POPUP_CONFIRMATION_ENVOYER_RAPPORT : "L'envoi du rapport d'expertise est définitif, vous ne pourrez plus le modifier une fois envoyé pour validation.<br>" +
      "Confirmez-vous cet envoi ?",
    POPUP_CONFIRMATION_ENVOYER_HEADER : "Attention !",
    POPUP_CONFIRMATION_GENERATION : "Etes-vous sûrs de faire cette action ?",
    GENERATION :"Génération",
    OBJ_MISSION : "Objet de la mission",
    FAIT_A : "Fait à",
    LE :"le",
    LABEL : {
      A_LA_DATE: "À la date du",
      SOIT: "soit",
      AU_M2: "/m²",
      REFERENCE_CADASTRALE : "Références cadastrales",
      TYPE_VALEUR : "Type de valeur",
      AFFAIRE: "Affaire",
      REPRESENTANT_LEGALE : "Représentant légale",
      POUR_COMPTE_DE: "Pour le compte de",
      NUMERO_CONTRAT: "N° de contrat",
      AGENCE: "Agence",
      ADRESSE_BIEN : "Adresse du bien",
      PHOTO_GARDE : "Photo de garde",
      DATE_VALEUR : "Date de valeur",
      RENSEIGNER : "Renseignez",
      TYPE_BIEN : "Type de bien",
      DATE_VISITE : "Date de visite",
      TYPE_VISITE : "Type de visite",
      EFFECTUEE_PAR : "Effectuée par",
      SOUS_CONDUITE : "Sous la conduite",
      SURFACE : "Surface",
      SITUATION_BIEN : "Situation du bien",
      ETAT_ENTRETIEN_EXTERNE : "État entretien externe",
      ETAT_ENTRETIEN_INTERNE : "État entretien interne",
      VALEUR_VENTE_FORCEE : "Valeur en vente forcée",
      LIQUIDITE_BIEN : "Liquidité du bien",
      DELAI_COMMERCIALISATION : "Délai de commercialisation",
      COMMUNE : "Commune",
      LIEU_DIT : "Lieudit",
      SECTION : "Section",
      NUMERO_PARCELLE : "Numéro de parcelle",
      CONTENANCE : "Contenance",
      LOCALISATION : "Localisation",
      SECTEUR : "Secteur",
      POPULATION : "Population",
      DESSERTE : "Desserte",
      DESCRIPTION : "Description",
      SITUATION_JURIDIQUE : "Situation juridique",
      SUPERFECIE : "Superficie",
      TOPOGRAPHIE : "Topographie",
      CONFIGURATION : "Configuration",
      ENTRETIEN : "Entretien",
      AMENAGEMENT_INTERRIEUR : "Aménagement intérieur",
      CONSTRUCTION : "Construction",
      RESERVES : "Réserves",
      TYPE_SURFACE : "Type surface",
      BAT_ETAGE : "Bâtiment/Étage",
      UTILISATION : "Utilisation",
      COEF_PONDERATION : "Coefficient de pondération",
      SURFACE_CONSIDEREE : "Surface considérée",
      NOMBRE : "Nombre",
      NATURE : "Nature",
      DETAIL_SURFACE : "Détail sur les surfaces",
      DPE : "DPE (Diagnostic de Performance Énergétique)",
      AUTRES_DIAGNOSTIQUES : "Autres diagnostics ",
      ADRESSE : "Adresse",
      PRIX_VENTE : "Prix de vente (€)",
      RATIO : "Ratio (€/m²)",
      ANNEXE : "Annexe",
      OBSERVATIONS : "Observations",
      DATE_TRANSACTION : "Date transaction",
      LOYER : "Loyer",
      PERIODICITE : "Périodicité",
      FORCES : "Forces",
      FAIBLESSES : "Faiblesses",
      OPPORTUNITES : "Opportunités",
      MENACES : "Menaces",
      COMMENTAIRE_DETAIL_CALCUL : "Commentaire et détail des calculs",
      PRECISION_DELAI : "Précision sur le délai",
      AB_VENTE_FORCEE: "Abattement en vente forcée",
      TAUX_FISCALITE : "Taux de fiscalité",
      ADD_NEW_LINE : "Ajouter une nouvelle ligne",
      SUPPRIMER : "Supprimer",
      INSERER_PLAN_CADASTRAL: "Insérer le plan cadastral",
      PHOTO_MAPS : "Ajouter la carte manuellement",
      TYPE : "Type",
      PHOTO_EXTERIEUR : "Photo extérieure",
      PHOTO_INTERIEUR: "Photo intérieure",
      PHOTO_ENVIRONNEMENT : "Photo de l'environnement",
      AJOUTER_ELEMENT : "Ajouter un élément",
      ELEMENTS : "Éléments",
      ASRESSE : "Adresse",
      EXPERT: "Expert",
    },

    TEXTE: {
      MEMBRE_AFSEI: "membre de l'Association Française des Sociétés d'Expertise Immobilière, ",
      LA_SOCIETE: "La Société",
      ADHERENT_MISSIONNEE: "adhérent à la Charte de l'Expertise en Évaluation Immobilière, a été missionnée, en date du {0} par {1}, représentée par {2}",
      OBJET_MISSION_APPRECIATION: "Appréciation de la",
      OBJET_MISSION_SUPPOSE: "du bien immobilier sus visé, considéré en l'état et dans sa destination actuelle ; supposé",
      OBJET_MISSION_CONTEXTE: ". Cette mission s'inscrit dans un contexte {0}.",
      EVALUATION: "Notre évaluation prend en compte les caractéristiques générales et particulières du bien immobilier ainsi que son positionnement dans le marché immobilier sectoriel tel qu'exposé dans notre étude et sur la base d'une {0} de {1} m².",
      CONVENTION : "dans le cadre d'une convention en date du {0}."
    },

    MESSAGE : {
      ERROR_FORM : "Veuillez corriger les erreurs",
      MAX_CARACTERE : "Maximum {0} caractères",
      AUCUN_RESULTAT : "Aucun résultat",
      ERROR_CARACTERE_COMMENTAIRE: "Le commentaire ne doit pas depasser {0} caractères",
      ERROR_CARACTERE_INPUT: "{0} caractères au maximum",
      SAVE_SUCCES : "Le rapport d'expertise n°{0} a été mis à jour avec succès.",
      SAVE_ERROR : "La mise à jour du rapport d'expertise n'a pas abouti.",
      NO_MESSAGE_DETECTED : "Aucune modification détectée.",
      SUCESS_RAPPORT_SEND_FOR_VALIDATION : "Le rapport d'expertise n°{0} a été envoyé pour validation avec succès.",
      ERROR_RAPPORT_SEND_FOR_VALIDATION : "L'envoi pour validation du rapport d'expertise  n°{0} n'a pas abouti.",
      ERROR_CONDITION_RAPPORT_SEND_FOR_VALIDATION : "Vous ne pouvez pas envoyer le rapport {0} pour validation, car le dossier ne remplit pas les conditions réquises",
      SUCESS_SYNTHESE_SEND_FOR_VALIDATION : "La synthèse du dossier n°{0} a été envoyée avec succès.",
      ERROR_SYNTHESE_SEND_FOR_VALIDATION :"L'envoi de la synthèse n'a pas abouti.",
      ERROR_CONDITION_SYNTHESE_SEND_FOR_VALIDATION : "Vous ne pouvez pas envoyer la synthèse du rapport {0} pour validation, car le dossier ne remplit pas les conditions réquises",
      GENERER_RAPPORT_SUCCES : "Un nouveau rapport d'expertise a été généré avec succès pour le dossier n°{0}.",
      GENERER_RAPPORT_ERROR : "La génération du rapport d'expertise n'a pas abouti.",
      GENERER_SYNTHESE_SUCCES : "La synthèse du rapport d'expertise a été généré avec succès pour le dossier n°{0}",
      GENERER_SYNTHESE_ERROR : "La génération de la synthèse du rapport d'expertise n'a pas abouti.",
      CHAMPS_OBLIGATOIRE : "Veuillez renseigner les champs obligatoire.",
      SURFACE_POSITIVE : "Veuillez saisir une valeur positive pour la surface.",
      SAUVEGARDE : "Voulez-vous vraiment enregistrer les modifications ?",
      ERROR_MESSAGE_MODAL : "Une erreur a eu lieu durant l'enregistrement.",
      SUCESS_SUPPRESSION : "La suppression a bien été prise en compte.",
      ERROR_SUPPRESSION : "Une erreur s'est produite durant la suppression.",
      ETES_VOUS_SUR : "Etes-vous sûr ?",
      CONFIRMER : "Confirmer pour continuer",
      ERROR_ABAT : "Le pourcentage d'abattement doit être compris entre 0 et 100",
      ERROR_SURFACE : "Veuillez saisir une valeur supérieur à 0 pour la surface.",
      TELECHARGER : "Télécharger"
    },

    ACTIONS : {
      TITLE : "Actions",
      SAUVEGARDER : "Sauvegarder",
      GENERER_SYNTHESE : "Générer la synthèse",
      ENVOYER_SYNTHESE : "Envoyer la synthèse",
      GENERER_RAPPORT : "Générer le rapport",
      ENVOYER_RAPPORT : "Envoyer le rapport",
      AJOUTER_NOUVELLE_LIGNE : "Ajouter une nouvelle ligne",
      OUVRIR_FICHE_DOSSIER : "Ouvrir la fiche dossier"
    }
  },

  ACCES_DENIED : {
    MESSAGE : "Vous ne pouvez pas accéder à cette page"
  },

   PRIMENGCONFIG: {
    CALENDRIER : {
      DAYSNAMESMIN: ['D', 'L', 'M', 'M', 'J', 'V', 'S'],
      MONTHNAMES: ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'],
      MONTHNAMESSHORT : ['Jan', 'Fev', 'Mar', 'Avr', 'Mai', 'Jun', 'Jul', 'Aou', 'Sep', 'Oct', 'Nov', 'Dec']
    }
  },

  LANGUE_APP : {
    LABEL : "Changer la langue"
  }

}


