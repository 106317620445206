import {Component, OnInit} from '@angular/core';
import {TranslateService} from "../../../shared/translate/translate.service";
import {Message} from "primeng/api";

@Component({
  selector: 'app-role-denied',
  templateUrl: './role-denied.component.html',
  styleUrls: ['./role-denied.component.scss']
})
export class RoleDeniedComponent implements OnInit {

  message : Message [];

  constructor(private translate : TranslateService) { }

  ngOnInit(): void {

    this.message= [
      {severity:'warn', summary: this.translate.getElement('ACCES_DENIED.MESSAGE')}
    ];

  }

}
