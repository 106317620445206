<form  [formGroup]="form">

  <h2>{{titre}}</h2>
  <!-- Fieldsets pour structurer des groupes de champs -->
  <fieldset id="bien" class="grid formgrid">

    <!-- col-12 pour une colonne sur toute la largeur -->
    <div class="field col-12 grid field-line">

      <div class="field col" >
        <label for="typeBien" class="obligatoire">
          {{'DOSSIER.DEMANDE.CREATION.TYPE_BIEN' | translateI18n}}</label>
        <div class="p-input-icon-right w-full">
          <p-dropdown [options]="typeBiens" placeholder="{{'PLACEHOLDER.CHOOSE' | translateI18n}}" id="typeBien" formControlName="typeBien" name="typeBien"
                      optionLabel="paramReferentielValeurByCode.libelle"
                      [ngClass]="{'ng-dirty ng-invalid': submitted && form.get('typeBien').value === null}"></p-dropdown>
        </div>

        <div *ngIf="submitted && form.get('typeBien').value === null && f.typeBien.invalid" class="pt-2">
          <small *ngIf="f.typeBien.errors.required" class="p-error block">{{'MESSAGE.ERROR.CHAMPS_OBLIGATOIRE' | translateI18n}}</small>
        </div>
      </div>

      <div class="field col" >
        <label for="etage">{{'DOSSIER.DEMANDE.CREATION.ETAGE' | translateI18n}}
          <span> {{'DOSSIER.DEMANDE.CREATION.OPTIONAL' | translateI18n}}</span></label>
        <div class="p-input-icon-right w-full">
          <p-dropdown [options]="etages" placeholder="{{'PLACEHOLDER.CHOOSE' | translateI18n}}." id="etage" formControlName="etage" name="etage"
                      optionLabel="libelle"></p-dropdown>
        </div>

      </div>
    </div>

    <div class="col-12 field field-line">
      <!-- .p-radiobutton-group regroupement .inline-group pour afficher les radiobutton en ligne -->
      <label class="obligatoire">Adresse</label>
      <div class="p-radiobutton-group inline-group" >
        <p-radioButton  value="idClient" formControlName="customerAdress"
                        [ngClass]="submitted && form.get('customerAdress').value === null ? 'radioErrorColor':null"
                        label="Adresse du client" (click)="choiceCustomerAdress()">
        </p-radioButton>
        <p-radioButton value="autre" formControlName="customerAdress"
                       [ngClass]="submitted && form.get('customerAdress').value === null ? 'radioErrorColor':null"
                       label="Autre" (click)="choiceCustomerAdress()"></p-radioButton>
      </div>

      <div *ngIf="submitted && form.get('customerAdress').value === null" class="pt-2">
        <small  class="p-error block">{{'MESSAGE.ERROR.CHAMPS_OBLIGATOIRE' | translateI18n}}</small>
      </div>
    </div>

    <div class="col field field-line" formGroupName="adresse">

      <div class="flex flex-row">
        <div style="background-color: #efefef;display: flex;align-items: center;" *ngIf="form.get('adresse.adresseFormate').value !== null">
          <span class="p-3">{{form.get('adresse.adresseFormate').value}}</span>
        </div>
          <button type="button"  pButton pRipple  *ngIf=" form.value.customerAdress === 'autre' && form.get('adresse.adresseFormate').value === null"
                  label="{{'BUTTON_LABEL.ADD' | translateI18n}}"
                  (click)="addAdress()"></button>

          <button type="button"  pButton pRipple  *ngIf="form.value.customerAdress === 'autre' && form.get('adresse.adresseFormate').value !== null"
                  label="{{'BUTTON_LABEL.MODIFY' | translateI18n}}"
                  (click)="addAdress()"></button>

      </div>

      <div *ngIf="submitted" class="pt-2">
        <small *ngIf="form.get('adresse.adresseFormate').value === null" class="p-error block">{{'MESSAGE.ERROR.CHAMPS_OBLIGATOIRE' | translateI18n}}</small>
      </div>

    </div>



  </fieldset>


</form>
