import {Component, Input, OnInit} from '@angular/core';
import {Document} from "../../../models/document/document.model";
import {ModificationDocument} from "../../../models/document/modification-document";
import {DocumentService} from "../../../service/document.service";
import {MessageService} from "primeng/api";
import {TranslateService} from "../../../../shared/translate/translate.service";
import {NgxSpinnerService} from "ngx-spinner";

@Component({
  selector: 'app-commentaire-photo',
  templateUrl: './commentaire-photo.component.html',
  styleUrls: ['./commentaire-photo.component.scss']
})
export class CommentairePhotoComponent implements OnInit {

  @Input()
  doc : Document;

  @Input()
  idDossier : number;

  @Input()
  keyMessage : string;

  constructor(
    private documentService : DocumentService,
    private messageService : MessageService,
    private translate : TranslateService,
    private spinnerService: NgxSpinnerService
  ) { }

  ngOnInit(): void {
  }

  editCommentaire(doc : Document) {
    this.messageService.clear();
    if(doc?.commentaire) {
      this.spinnerService.show();
      let payload: ModificationDocument = new ModificationDocument();
      payload.idDocument = doc.id;
      payload.commentaire = doc.commentaire;
      payload.idDossier =  this.idDossier;
      this.documentService.modifierDocument(payload).subscribe(data => {
        doc = data;
        this.messageService.add({key: this.keyMessage, severity: 'success', summary: this.translate.getElement("DOCUMENT.MODIFICATION.SUCCESS")});
        this.spinnerService.hide();
      }, error => {
        this.messageService.add({key: this.keyMessage, severity: 'error', summary: this.translate.getElement("DOCUMENT.MODIFICATION.ERROR")});
        this.spinnerService.hide();
      });
    }
  }

}
