import {ReferentielValeur} from "../referentiel-valeur";
import {Adresse} from "../adresse";
import {ProfilesUser} from "../user/profiles-user";

export class InfoExpertise {
  id : number;
  honoraire : number;
  fraisExpertise : number
  visite : boolean;
  expert : number;
  typePrestation : ReferentielValeur;
  isExpertise : boolean;
  typeLivrable : ReferentielValeur;
  typeTravaux : ReferentielValeur[];
  expertAbreviationCivilite : string;

  //Expert information
  nomExpert: string;
  prenomExpert: string;
  nomCompletExpert: string;
  emailExpert: string;
  telephoneExpert: string;
  portableExpert: string;
  adressExpert: Adresse;
  profilsExpert: ProfilesUser[];


  constructor(fields?: Partial<InfoExpertise>) {
    if (fields) {
      Object.assign(this, fields);
    }
  }
}
