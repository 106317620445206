<form *ngIf="form" [formGroup]="form" (change)="verificationPostalCode()">

  <div  *ngIf="errorMessage && !this.dossier">
    <p-messages [(value)]="errorMessage" [enableService]="false"></p-messages>
  </div>

  <div class="grid formgrid p-fluid">

    <div class="col-12">
      <div class="field-section">{{'DOSSIER.ADRESSE_FORM.ADR_FORMAT' | translateI18n}}</div>
      <div class="field">
        <p-messages [(value)]="messageGoogle" [enableService]="false" [closable]="false"></p-messages>
        <app-google-place-autocomplete
          [displayMapGoogle]="displayMapGoogle"
          [adresseInput]="adresseFormate"
          (input)="onAddressChange()">
        </app-google-place-autocomplete>
      </div>
    </div>

    <div class="col-12">

      <div class="card grid w-full m-auto p-4">

        <div class="field col-12 lg:col-6">
          <label for="numero">{{'DOSSIER.ADRESSE_FORM.NUM_ADR' | translateI18n}}</label>
          <div class="p-input-icon-right w-full">
            <input type="number" pInputText id="numero"  formControlName="numero" name="numero" class="inputfield w-full"
                   [ngClass]="{'ng-dirty ng-invalid': submitted && f.numero?.errors?.pattern}"
                   (input)="changeValueInput($event)" [maxLength]="configSizeInput.NUMERO_ADRESSE"/>
          </div>

          <small *ngIf="submitted && f.numero?.errors?.pattern" class="p-error block">{{'MESSAGE.ERROR.REGEX_PATTERN' | translateI18n}}</small>
        </div>

        <div class="field col-12 lg:col-6">
          <label for="lieuDit">{{'DOSSIER.ADRESSE_FORM.LIEU_DIT' | translateI18n}}</label>
          <div class="p-input-icon-right w-full">
            <input type="text" pInputText id="lieuDit" formControlName="lieuDit" name="lieuDit" class="inputfield w-full"
                   [maxLength]="configSizeInput.LIEU_DIT"/>
          </div>
          <small *ngIf="f.lieuDit?.errors?.pattern" class="p-error block">{{'MESSAGE.ERROR.REGEX_PATTERN' | translateI18n}}</small>
        </div>

        <div class="field col-12">
          <label for="nomVoie" class="obligatoire">
            {{'DOSSIER.ADRESSE_FORM.STREET_NAME' | translateI18n}}</label>
          <div class="p-input-icon-right w-full">
            <input type="text" pInputText id="nomVoie" formControlName="nomVoie" name="nomVoie" class="inputfield w-full"
                   (input)="changeValueInput()"
                   [ngClass]="{'ng-dirty ng-invalid': submitted && f['nomVoie'].errors}" [maxLength]="configSizeInput.NOM_VOIE" required />
          </div>
          <div *ngIf="(f.nomVoie.touched || submitted) && (form.value.nomVoie === null || form.controls.nomVoie.errors)" class="pt-2">
            <small *ngIf="f.nomVoie?.errors?.pattern" class="p-error block">{{'MESSAGE.ERROR.REGEX_PATTERN' | translateI18n}}</small>
            <small *ngIf="f.nomVoie?.errors?.required" class="p-error block">{{'MESSAGE.ERROR.CHAMPS_OBLIGATOIRE' | translateI18n}}</small>
          </div>

        </div>

        <div class="field col-12">
          <label for="complement">{{'DOSSIER.ADRESSE_FORM.COMP_ADR' | translateI18n}}</label>
          <div class="p-input-icon-right w-full">
            <input type="text" pInputText id="complement" formControlName="complement" name="complement" class="inputfield w-full"
                   [maxLength]="configSizeInput.COMPLEMENT_ADRESSE"/>
          </div>
          <small *ngIf="(f.complement.touched || submitted) && f.complement?.errors?.pattern" class="p-error block">{{'MESSAGE.ERROR.REGEX_PATTERN' | translateI18n}}</small>
        </div>

        <div class="field col-12 lg:col-6">
          <label for="codePostal" class="obligatoire">
            {{'DOSSIER.ADRESSE_FORM.POSTAL_CODE' | translateI18n}}</label>
          <div class="p-input-icon-right w-full">
            <input type="text" pInputText id="codePostal" formControlName="codePostal" name="codePostal" class="inputfield w-full"
                   [style.border-color]="(f.codePostal.touched || submitted) && (form.value.codePostal === null || form.controls.codePostal.errors) ?'red':null"
                   [ngClass]="{ 'is-invalid': submitted && f['codePostal'].errors }" (input)="changeValueInput()"
                   [maxLength]="configSizeInput.CODE_POSTALE" required />
          </div>

          <div *ngIf="(f.codePostal.touched || submitted) && (form.value.codePostal === null || form.controls.codePostal.errors)" class="pt-2">
            <small *ngIf="f.codePostal?.errors?.pattern" class="p-error block">{{'MESSAGE.ERROR.REGEX_PATTERN' | translateI18n}}</small>
            <small *ngIf="f.codePostal?.errors?.required" class="p-error block">{{'MESSAGE.ERROR.CHAMPS_OBLIGATOIRE' | translateI18n}}</small>
          </div>

        </div>


        <div class="field  col-12 lg:col-6">
          <label for="ville" class="obligatoire">
            {{'DOSSIER.ADRESSE_FORM.CITY' | translateI18n}}</label>
          <div class="p-input-icon-right w-full">
            <input type="text" pInputText id="ville" formControlName="ville" name="ville" class="inputfield w-full"
                   [style.border-color]="(f.ville.touched || submitted) && (form.value.ville === null || form.controls.ville.errors) ?'red':null"
                   [ngClass]="{ 'is-invalid': submitted && f['ville'].errors }" (input)="changeValueInput()"
                   [maxLength]="configSizeInput.VILLE" required/>
          </div>
          <div *ngIf="(f.ville.touched || submitted) && (form.value.ville === null || form.controls.ville.errors)" class="pt-2">
            <small *ngIf="f.ville?.errors?.pattern" class="p-error block">{{'MESSAGE.ERROR.REGEX_PATTERN' | translateI18n}}</small>
            <small *ngIf="f.ville?.errors?.required" class="p-error block">{{'MESSAGE.ERROR.CHAMPS_OBLIGATOIRE' | translateI18n}}</small>
          </div>
        </div>

        <div class="field  col-12 lg:col-6">
          <label for="nomPays" >
            {{'DOSSIER.ADRESSE_FORM.COUNTRY' | translateI18n}}</label>
          <div class="p-input-icon-right w-full">
            <p-dropdown [options]="pays"  id="nomPays" formControlName="pays" optionLabel="libelle"
                        [ngClass]="(f.pays.touched || submitted) && (form.get('pays').value === null || form.controls.pays.errors)? 'dropdownError':null"
                        (onChange)="validationCPIfContryFR($event.value.codeIso2)" appendTo="body"></p-dropdown>
          </div>
          <div *ngIf="(f.pays.touched || submitted) && (form.get('pays').value === null || form.controls.pays.errors)" class="pt-2">
            <small class="p-error block">{{'MESSAGE.ERROR.CHAMPS_OBLIGATOIRE' | translateI18n}}</small>
          </div>
        </div>


        <div class="field  col-12 lg:col-6">
          <label for="boitePostale">{{'DOSSIER.ADRESSE_FORM.BOITE_POSTAL' | translateI18n}}</label>
          <div class="p-input-icon-right w-full">
            <input type="text" pInputText id="boitePostale" formControlName="boitePostale" name="boitePostale" class="inputfield w-full"  />
          </div>

          <small *ngIf="(f.boitePostale.touched || submitted) && f.boitePostale?.errors?.pattern" class="p-error block">{{'MESSAGE.ERROR.REGEX_PATTERN' | translateI18n}}</small>
        </div>


      </div>

    </div>



  </div>

  <div class="p-dialog-footer" *ngIf="!dossier">
    <button pButton type="button" label="{{'BUTTON_LABEL.CLOSE' | translateI18n}}"
            (click)="close()" class="uppercase p-button-secondary p-button-outlined" icon="pi pi-times" iconPos="right"></button>

    <button pButton type="button"  (click)="addAdresse()" label="{{'BUTTON_LABEL.ADD' | translateI18n}}" class="uppercase" icon="pi pi-plus"
            iconPos="right"></button>
  </div>

</form>
