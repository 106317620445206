import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {DynamicDialogConfig, DynamicDialogRef} from "primeng/dynamicdialog";
import {Dossier} from "../../models/dossiers/dossier";
import {AbstractControl, FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {Subject, Subscription} from "rxjs";
import {GoogleMapServiceService} from "../../service/googleMaps/google-map-service.service";
import {Adresse, Departement, Pays} from "../../models/adresse";
import {DossierService} from "../../service/dossier.service";
import {LocalStorageService} from "../../service/local-storage/local-storage.service";
import {Message} from "primeng/api";
import {TranslateService} from "../../../shared/translate/translate.service";
import {ConfigSizeInput} from "../../../shared/providers/config-size-input";
import {NgxSpinnerService} from "ngx-spinner";
import {UtilsFonction} from "../../../shared/providers/utils-fonction";
import {Validation} from "../../../shared/providers/validation";


@Component({
  selector: 'app-edit-dossier-affectation',
  templateUrl: './edit-dossier-affectation.component.html',
  styleUrls: ['./edit-dossier-affectation.component.scss']
})
export class EditDossierAffectationComponent implements OnInit {

  display: boolean = false;
  dossier : Dossier;

  formattedAddress : string;

  adresse : Adresse;

  departements : Departement[];

  pays : Pays[];

  protected placeSubscription: Subscription;

  errorMessage : Message[];

  submitted : boolean = false;

  @Output() messageSuccesss: EventEmitter<String> = new EventEmitter();

  public messageSuccess: Subject<string> = new Subject<string>();

  public messageSuccessObservable = this.messageSuccess.asObservable();

  form: FormGroup;

  configSizeInput : ConfigSizeInput;

  message : Message[];

  constructor(
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private googleMapService : GoogleMapServiceService,
    private dossierService : DossierService,
    private userLocalStorage : LocalStorageService,
    private translate: TranslateService,
    private fb : FormBuilder,
    private spinnerService: NgxSpinnerService
  ) {
    this.configSizeInput = new ConfigSizeInput();

    const body = document.getElementsByTagName('body')[0];
    body.classList.add('p-dynamic-dialog-scrollable');
  }

  ngOnInit(): void {
    this.dossier = this.config.data;
    this.form = this.fb.group({
      nomMoral : [this.dossier?.client?.nomMoral,
        [!this.dossier?.client?.nom  ? Validators.required : Validators.nullValidator,
          Validators.maxLength(this.configSizeInput.NOM_MOARAL_CLIENT), Validators.pattern(this.configSizeInput.regexPattern) ]],
      nomClient : [this.dossier?.client?.nom, [!this.dossier?.client?.nomMoral  ? Validators.required : Validators.nullValidator,
        Validators.maxLength(this.configSizeInput.NOM_CLIENT), Validators.pattern(this.configSizeInput.regexPattern)]],
      prenomClient : [this.dossier?.client?.prenom, [Validators.maxLength(this.configSizeInput.PRENOM_CLIENT), Validators.pattern(this.configSizeInput.regexPattern),
        Validators.pattern(this.configSizeInput.regexPattern)]],
      adresse: this.initAdresse()
    });

  }

  initAdresse() : FormGroup  {
    return new FormGroup({
      adresseFormate: new FormControl(this.dossier?.adresse?.adresseFormate, Validators.maxLength(this.configSizeInput.ADRESSE_FORMATE)),
      numero: new FormControl(this.dossier?.adresse?.numero, [Validators.maxLength(this.configSizeInput.NUMERO_ADRESSE),
        Validators.pattern(this.configSizeInput.regexPattern2)]),
      lieuDit: new FormControl(this.dossier?.adresse?.lieuDit, [Validators.maxLength(this.configSizeInput.LIEU_DIT), Validators.pattern(this.configSizeInput.regexPattern2)]),
      nomVoie: new FormControl(this.dossier?.adresse?.nomVoie, [
        Validators.required, Validators.maxLength(this.configSizeInput.NOM_VOIE), Validators.pattern(this.configSizeInput.regexPattern2)
      ]),
      codePostal: new FormControl(this.dossier?.adresse?.codePostal, [Validators.required, Validators.maxLength(this.configSizeInput.CODE_POSTALE),
        Validators.pattern(this.configSizeInput.regexPattern2)]),
      ville: new FormControl(this.dossier?.adresse?.ville, [Validators.required, Validators.maxLength(this.configSizeInput.VILLE), Validators.pattern(this.configSizeInput.regexPattern2)]),
      pays: new FormControl(this.dossier?.adresse?.pays, Validators.required),
      boitePostale: new FormControl(this.dossier?.adresse?.boitePostale, [Validators.maxLength(this.configSizeInput.BOITE_POSTALE),
        Validators.pattern(this.configSizeInput.regexPattern2)]),
      complement: new FormControl(this.dossier?.adresse?.complement, [Validators.maxLength(this.configSizeInput.COMPLEMENT_ADRESSE),
        Validators.pattern(this.configSizeInput.regexPattern2)]),
      lat : new FormControl(this.dossier?.adresse?.lat),
      lng : new FormControl(this.dossier?.adresse?.lng),
      adresseChange : new FormControl(this.dossier?.adresse?.adresseChange)
    })
  }

  get f(): { [key: string]: AbstractControl } {
    return this.form.controls;
  }


  /**
   * Mets à jour les éléments modifiés
   * @param form
   */
  updateDossierAffectation() {

    this.spinnerService.show();

    this.submitted = true;

    this.errorMessage = null;

    if(!this.checkifFormIsValid()) {
      this.errorMessage = [
        {severity:'warn', summary: this.translate.getElement('DOSSIER.NO_MESSAGE_DETECTED') }
      ];
      window.scrollTo(0,0);
      this.spinnerService.hide();
      return;
    }

    if(this.form.invalid) {
      this.errorMessage = [ {severity:'error', summary: this.translate.getElement('MESSAGE.ERROR.MS_CHAMPS_OBLIGATOIRE') }];
      this.spinnerService.hide();
      return;
    }

    const data = this.form.value;

    let dossier = this.updateAdress(data.adresse);

    if(dossier?.client?.nom && data?.nomClient.toUpperCase() !== dossier?.client?.nom.toUpperCase()) {
      dossier.client.nom = data.nomClient.toUpperCase()
    } else if(data?.nomMoral?.toUpperCase() !== dossier?.client?.nomMoral?.toUpperCase()) {
      dossier.client.nomMoral = data.nomMoral.toUpperCase()
    }

    if((dossier?.client?.nom && !dossier?.client?.prenom)
      || dossier?.client?.prenom && data?.prenomClient?.toUpperCase() !== dossier?.client?.prenom.toUpperCase()) {
      dossier.client.prenom = data.prenomClient;
    }


    if(dossier.adresse.pays.codeIso2 === 'FR' && !Validation.regexPostalCodeFR(dossier.adresse.codePostal)) {
        this.errorMessage = [{
          severity:'error',
          summary: this.translate.getElement('DOSSIER.MESSAGE.ERROR.CODE_POSTAL_FR')
        }];
        this.spinnerService.hide();
        return;
    }


    this.dossierService.updateClientAndAdresseBienAffectation(dossier).subscribe(
      data => {
        this.close(this.translate.getElement('DOSSIER.AFFECTATION.MESSAGE.MAJ', dossier.numeroDossier));
        //"Le dossier " + dossier.numeroDossier + " a été mis à jour"
        this.submitted = false;
        this.spinnerService.hide();
      },
      error => {
        this.errorMessage = [{
          severity:'error',
          summary: this.translate.getElement('DOSSIER.AFFECTATION.MESSAGE.ERROR_SERVER')
        }];
        this.spinnerService.hide();
      }
    )

  }

  /**
   * MAJ de l'adresse
   * @param adresse
   */
  updateAdress(adresse : Adresse)  {

    if(this.checkIFAdresseIsChanged()) {
      if(adresse.numero) {
        this.dossier.adresse.numero = adresse.numero;
      }

      if( adresse.nomVoie) {
        this.dossier.adresse.nomVoie = adresse.nomVoie;
      }

      if( adresse.ville) {
        this.dossier.adresse.ville = adresse.ville.toUpperCase();
      }

      if( adresse.codePostal) {
        this.dossier.adresse.codePostal = adresse.codePostal;
      }

      if( adresse.pays) {
        this.dossier.adresse.pays = adresse.pays
      }

      if(adresse.adresseFormate || adresse.adresseFormate.trim() === "") {
        this.dossier.adresse.adresseFormate =  UtilsFonction.addresseFormat(adresse);
      }

      if(!adresse.adresseFormate || adresse.adresseFormate.trim() === "")  {
        this.dossier.adresse.lat = null;
        this.dossier.adresse.lng = null;
      }else {
        this.dossier.adresse.lat = adresse.lat;
        this.dossier.adresse.lng = adresse.lng;
      }

      this.dossier.adresse.lieuDit = adresse.lieuDit;
      this.dossier.adresse.complement = adresse.complement;
      this.dossier.adresse.boitePostale = adresse.boitePostale;

      this.dossier.adresse.adresseChange = true;
    }else {
      this.dossier.adresse.adresseChange = false;
    }

    return this.dossier;
  }


  close(message? : string) {
    this.ref.close(message);
  }

  ngOnDestroy() {
    if (this.ref) {
      this.ref.close();
    }
  }

  checkifFormIsValid() : boolean {
    let client = this.form.value;
    return (this.dossier?.client?.nomMoral && this.dossier?.client?.nomMoral !== client.nomMoral) ||
      (this.dossier?.client?.nom && ( this.dossier?.client?.nom !== client.nomClient ||  this.dossier?.client?.prenom !== client.prenomClient))
      || this.checkIFAdresseIsChanged();
  }

  checkIFAdresseIsChanged() {
    let bienAdress = this.form?.value?.adresse;
    let adresse = this.dossier?.adresse;

    return (bienAdress?.numero?.toString() !== adresse?.numero || bienAdress?.codePostal?.toString() !== adresse.codePostal
      || bienAdress?.nomVoie !== adresse.nomVoie || bienAdress?.pays !== adresse.pays ||  bienAdress?.boitePostale !== adresse.boitePostale
      || bienAdress?.complement !==  adresse.complement || bienAdress?.lieuDit !== adresse.lieuDit || bienAdress?.ville !== adresse.ville)
  }

}
