import {StatutDossier} from "../../core/models/enum/statut-dossier";
import {TranslateService} from "../translate/translate.service";
import {Dossier} from "../../core/models/dossiers/dossier";
import {RechercheAvanceeDossierComponent} from "../../core/dialog/recherche-avancee-dossier/recherche-avancee-dossier.component";
import {DialogService, DynamicDialogRef} from "primeng/dynamicdialog";
import {DatePipe} from "@angular/common";
import {HttpResponse} from "@angular/common/http";
import {Adresse} from "../../core/models/adresse";

export class UtilsFonction {

  static uploadMaxSize = 10; // en Mo
  static moEnOctet = 1048576;

  /**
   *
   * @param value
   */
  static getKeyByValueStatutDossier(value: string) {
    const indexOfS = Object.values(StatutDossier).indexOf(value as unknown as StatutDossier);
    return Object.keys(StatutDossier)[indexOfS];
  }

  static rechercheAvancee(dialogService: DialogService, translate : TranslateService) :  DynamicDialogRef {
    return dialogService.open(RechercheAvanceeDossierComponent, {
      header: translate.getElement('DOSSIER.ADVANCED_SEARCH.LABEL.TITRE_MODAL'),
      width: '900px',
      baseZIndex: 6000,
      styleClass : 'p-dynamic-dialog'
    });

  }

  static b64toBlob(contenu: string, type: string) {

    let byteString = atob(contenu);
    let ab = new ArrayBuffer(byteString.length);
    let ia = new Uint8Array(ab);

    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: type });
  }

  static uploadMaxSizeOctet() {
    return UtilsFonction.uploadMaxSize * UtilsFonction.moEnOctet;
  }

  /**
   * construction des étapes de la frise chronologique
   * @param dossier
   * @param translate
   * @param datePipe
   */
  static navSteps(dossier : Dossier, translate : TranslateService, datePipe : DatePipe) {

    let stepsList = [
      {
        label : translate.getElement("DOSSIER.REQUEST"),
        value : dossier.mission.dateDemande ? datePipe.transform(dossier.mission.dateDemande, 'dd/MM/yyyy') : '--',
        class : 'is-active'
      },
      {
        label :  translate.getElement("DOSSIER.ASSIGNMENT"),
        value : dossier.dateAffectation ? datePipe.transform( dossier.dateAffectation, 'dd/MM/yyyy') : '--',
        class : dossier.dateAffectation ? 'is-active' : dossier.mission.dateDemande && !dossier.dateContactDossier ? 'is-current' : ''
      }
    ];
    if(dossier.infoExpertise?.typeLivrable?.code === 'TVRAPPORTXX') {
      stepsList.push(
        {
          label: translate.getElement("DOSSIER.CONTACT"),
          value: dossier.dateContactDossier ? datePipe.transform(dossier.dateContactDossier, 'dd/MM/yyyy') : '--',
          class : dossier.dateContactDossier ? 'is-active' : dossier.dateAffectation && !dossier.dateVisite ? 'is-current' : ''
        },
        {
          label: translate.getElement("DOSSIER.VISITE"),
          value: dossier.dateVisite ? datePipe.transform(dossier.dateVisite, 'dd/MM/yyyy') : '--',
          class : dossier.dateVisite ? 'is-active' : dossier.dateContactDossier && !dossier.dateEstimation ? 'is-current' : ''
        });

      if(dossier.synthese) {
        stepsList.push(
          {
            label: translate.getElement("DOSSIER.SYNTHESE"),
            value: dossier.dateEstimation ? datePipe.transform(dossier.dateEstimation, 'dd/MM/yyyy') : '--',
            class : dossier.dateEstimation ? 'is-active' : dossier.dateVisite && !dossier.mission.dateRemiseEnValeurPilote ? 'is-current' : ''
          }
       );
      }


      stepsList.push(
        {
          label : translate.getElement("DOSSIER.ENVOI_LIVRABLE"),
          value : dossier.mission.dateRemiseEnValeurPilote ? datePipe.transform(dossier.mission.dateRemiseEnValeurPilote, 'dd/MM/yyyy')  : '--',
          class : dossier.mission.dateRemiseEnValeurPilote ? 'is-active' : dossier.dateEstimation &&
          ((dossier?.tiers?.projetLivrable && !dossier.mission.dateEnvoiLivrables) || !dossier.mission.dateEnvoieClient )? 'is-current' : ''
        }
      );
    }

    if(dossier.infoExpertise?.typeLivrable?.code === 'TVAVISDOSSI') {

      if(dossier.synthese) {
        stepsList.push(
          {
            label: translate.getElement("DOSSIER.SYNTHESE"),
            value: dossier.dateEstimation ? datePipe.transform(dossier.dateEstimation, 'dd/MM/yyyy') : '--',
            class : dossier.dateEstimation ? 'is-active' : dossier.dateAffectation && !dossier.mission.dateRemiseEnValeurPilote ? 'is-current' : ''
          }
        );
      }

      stepsList.push(
        {
          label : translate.getElement("DOSSIER.ENVOI_LIVRABLE"),
          value : dossier.mission.dateRemiseEnValeurPilote ? datePipe.transform(dossier.mission.dateRemiseEnValeurPilote, 'dd/MM/yyyy')  : '--',
          class : dossier.mission.dateRemiseEnValeurPilote ? 'is-active' : dossier.dateAffectation &&
          ((dossier?.tiers?.projetLivrable && !dossier.mission.dateEnvoiLivrables) || !dossier.mission.dateEnvoieClient ) ? 'is-current' : ''
        }
      );
    }


    if(dossier?.tiers?.projetLivrable) {
      stepsList.push(
        {
          label : translate.getElement("DOSSIER.ENVOI_PROJET"),
          value : dossier.mission.dateEnvoieClient ? datePipe.transform(dossier.mission.dateEnvoieClient, 'dd/MM/yyyy')  : '--',
          class : dossier.mission.dateEnvoieClient ? 'is-active' : dossier.mission.dateRemiseEnValeurPilote && !dossier.mission.dateEnvoiLivrables ? 'is-current' : ''
        }
      );
    }


    stepsList.push(
      {
        label : translate.getElement("DOSSIER.VALIDATION"),
        value : dossier.mission.dateEnvoiLivrables ? datePipe.transform(dossier.mission.dateEnvoiLivrables, 'dd/MM/yyyy')  : '--',
        class : dossier.mission.dateEnvoiLivrables ? 'is-active' :
          (dossier?.tiers?.projetLivrable && dossier.mission.dateEnvoieClient && dossier.mission.dateMissionPrevisionnelle)
          || (!dossier?.tiers?.projetLivrable && dossier.mission.dateRemiseEnValeurPilote && dossier.mission.dateMissionPrevisionnelle) ? 'is-current' : ''
      },
      {
        label : translate.getElement("DOSSIER.ECHEANCE"),
        value : dossier.mission.dateMissionPrevisionnelle ? datePipe.transform(dossier.mission.dateMissionPrevisionnelle, 'dd/MM/yyyy') : '--',
        class : 'echeance'
      }
    );

    if(dossier.statut === StatutDossier.ANNULE) {
      let foundFirstEmpty = false;
      let firstEmptyValueIndex = -1;

      let annulation = {
        label : translate.getElement("DOSSIER.ANNULER"),
        value : dossier.dateAnnulation ? datePipe.transform(dossier.dateAnnulation, 'dd/MM/yyyy') : '--',
        class : 'is-cancelled'
      }

      for (let i = 0; i < stepsList.length; i++) {
        if(stepsList[i].value === '--' && !foundFirstEmpty) {
          foundFirstEmpty = true;
          firstEmptyValueIndex = i;
          break;
        }
      }

      if(firstEmptyValueIndex !== -1) {

        stepsList[firstEmptyValueIndex]['motifAnnulation'] =dossier.motifAnnulation?.libelle;
        stepsList[firstEmptyValueIndex].value ='Annulé'

        for (let i = firstEmptyValueIndex ; i <stepsList.length; i++) {
          if(stepsList[i].label !== translate.getElement("DOSSIER.ECHEANCE")) {
            stepsList[i].class = 'is-cancelled';
          }
        }



        stepsList.splice(stepsList.length - 1, 0, annulation )
      }
    }

    return stepsList;

  }

   static getFilenameFromResponse(response: HttpResponse<ArrayBuffer>, typeDoc? : string, numberDossier ?: string): string {
    const contentDispositionHeader = response.headers.get('Content-Disposition');
    if (contentDispositionHeader) {
      const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
      const matches = filenameRegex.exec(contentDispositionHeader);
      if (matches != null && matches[1]) {
        return decodeURIComponent(matches[1].trim().replace(/['"]/g, ''));
      }
    }
    return typeDoc + numberDossier + ".pdf";
  }

  static openFile(data: any, filename: string) {
    let dataType = data.type;
    let binaryData = [];
    binaryData.push(data);
    let downloadLink = document.createElement('a');
    downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, {type: dataType}));
    if (filename)
      downloadLink.setAttribute('download', filename);
    document.body.appendChild(downloadLink);
    downloadLink.click();
    downloadLink.parentNode.removeChild(downloadLink);
  }

  static addresseFormat(adresse : Adresse) {
    let adresseForm = '';
    if(adresse?.numero) {
      adresseForm = adresse?.numero + ' '
    }
    if(adresse?.nomVoie) {
      adresseForm = adresseForm  + adresse?.nomVoie + ', ';
    }
    if(adresse?.codePostal) {
      adresseForm = adresseForm + ' ' + adresse?.codePostal + ' ';
    }
    if(adresse?.ville) {
      adresseForm = adresseForm + ' ' + adresse?.ville;
    }
    if(adresse?.pays) {
      adresseForm =', ' +adresseForm + adresse?.pays?.libelle;
    }

    return adresseForm;
  }

  /**
   *  calcul du montant de la négociation
   * @param honoExpertise : honoraire de l'expertise
   * @param fraisNonRefacturable : frais non refacturable( refacturable à false)
   * @param pourcentageNegociation :  pourcentage de la negociation
   */
  static calculMontantNegociation(honoExpertise : number, fraisNonRefacturable : number, pourcentageNegociation : number) : number {
    return ((honoExpertise - fraisNonRefacturable) * pourcentageNegociation) / 100;
  }

  /**
   *  calcul du montat de pilotage (déduire le montant de négociation)
   * @param honoExpertise : honoraire de l'expertise
   * @param fraisNonRefacturable : frais non refacturable( refacturable à false)
   * @param montantNegociation : montant de la négiociation
   * @param pourcentagePilotage : pourcentage de pilotage
   */
  static calculMontantPilotage(honoExpertise : number, fraisNonRefacturable : number, montantNegociation : number,  pourcentagePilotage : number) : number {
    return ((honoExpertise - fraisNonRefacturable - montantNegociation) * pourcentagePilotage) / 100;
  }

  /**
   * calcul les honoraires experts internes (déduire le montant de négociation & pilotage)
   * @param honoExpertise : honoraire de l'expertise
   * @param fraisNonRefacturable : frais non refacturable( refacturable à false)
   * @param montantNegociation :montant de la négociation
   * @param montantPilotage : montat de pilotage
   */
  static calculHonoraireExpertInterne(honoExpertise : number, fraisNonRefacturable : number, montantNegociation : number, montantPilotage : number) : number {
    return honoExpertise - fraisNonRefacturable - montantNegociation - montantPilotage;
  }
}
