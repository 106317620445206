<form (ngSubmit)="rechercheRapide()">

  <div  *ngIf="messageErrorCaractereNumDossier">
    <p-messages [(value)]="messageErrorCaractereNumDossier" [enableService]="false"></p-messages>
  </div>

  <div class="form-title">{{'FORM_QUICK_SEARCH.TITLE' | translateI18n}}</div>

  <div class="formgrid grid">

    <div class="field col-6 sm:col-6 md:col-2 xl:col-2">
      <input id="numeroDossier" name="numeroDossier" [(ngModel)]="numeroDossier" pInputText type="text" class="inputfield w-full"
             placeholder="{{'FORM_QUICK_SEARCH.NUM_DOSSIER' | translateI18n}}" [minLength]="5">
    </div>

    <div class="field col-6 sm:col-6 md:col-2 xl:col-2" *ngIf="showContrat">
      <input id="numeroContrat" name="numeroContrat" [(ngModel)]="numeroContrat" pInputText type="text" class="inputfield w-full" placeholder="{{'FORM_QUICK_SEARCH.NUM_CONTRAT' | translateI18n}}">
    </div>

    <div class="field col-6 sm:col-6 md:col-2 xl:col-2">
      <input id="nomClient" name="nomClient" [(ngModel)]="nomClient" pInputText type="text" class="inputfield w-full" placeholder="{{'FORM_QUICK_SEARCH.CLIENT' | translateI18n}}">
    </div>

    <div class="field col-fixed">
      <button pButton type="submit" [disabled]="!numeroDossier && !numeroContrat && !nomClient" label="{{'BUTTON_LABEL.RECHERCHER' | translateI18n}}" class="uppercase" icon="pi pi-search"
        iconPos="right"></button>
    </div>

    <div class="field col align-items-center flex rechercheAvancee" >
      <a (click)="showAdvanceSearch()">{{'BUTTON_LABEL.ADVANCED_SEARCH' | translateI18n}}</a>
    </div>

  </div>

</form>
