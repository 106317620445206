import {Component, OnInit, Renderer2} from '@angular/core';
import {TranslateService} from "../../shared/translate/translate.service";

@Component({
  selector: 'app-change-password-page',
  templateUrl: './change-password-page.component.html',
  styleUrls: ['./change-password-page.component.scss']
})
export class ChangePasswordPageComponent implements OnInit{

  constructor(private renderer: Renderer2, private translate : TranslateService) {}

  ngOnInit(): void {}

}
