import {NgModule} from '@angular/core';
import {PreloadAllModules, RouterModule, Routes} from '@angular/router';
import {ForgottenPasswordComponent} from "./features/user/forgotten-password/forgotten-password.component";
import {LoginComponent} from "./features/user/login/login.component";
import {DashboardComponent} from "./features/dashbord/dashboard/dashboard.component";
import {AuthGuard} from "./core/service/auth/auth.guard";
import {ProfilComponent} from "./features/user/profil/profil.component";
import {ErroLinkChangementPasswordExpiredComponent} from "./core/erro-link-changement-password-expired/erro-link-changement-password-expired.component";
import {DeconnexionComponent} from "./core/deconnexion/deconnexion.component";
import {FolderListToAssignComponent} from "./features/dossier/affectation/folder-list-to-assign/folder-list-to-assign.component";
import {CreationDemandeComponent} from "./features/dossier/creation-demande/creation-demande.component";
import {ChangePasswordPageComponent} from "./core/change-password-page/change-password-page.component";
import {RechercheDossierGestionnaireComponent} from './features/dossier/recherche-gestionnaire/recherche-gestionnaire.component';
import {RechercheAvanceeDossierComponent} from "./core/dialog/recherche-avancee-dossier/recherche-avancee-dossier.component";
import {RechercheDossierExpertComponent} from './features/dossier/recherche-expert/recherche-expert.component';
import {RechercheDemandeComponent} from "./features/dossier/recherche-demande/recherche-demande.component";
import {FicheDemandeComponent} from "./features/dossier/fiche-demande/fiche-demande.component";
import {FicheDossierComponent} from "./features/dossier/fiche-dossier/fiche-dossier.component";
import {ProfilesUser} from "./core/models/user/profiles-user";
import {AideComponent} from './core/aide/aide.component';
import {RoleGuard} from "./core/service/auth/role.guard";
import {RoleDeniedComponent} from "./core/not-found/role-denied/role-denied.component";
import {HelpNavigationComponent} from "./core/help-navigation/help-navigation.component";
import {CodeAuthentificationComponent} from "./features/user/code-authentification/code-authentification.component";
import {RapportExpertiseComponent} from "./features/rapport-expertise/rapport-expertise.component";
import {AvisValeurComponent} from "./features/avis-valeur/avis-valeur.component";
import {MonitoringComponent} from "./features/monitoring/monitoring.component";

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: '/auth'
  },
  { path: "error-link-change-password-expired", component:  ErroLinkChangementPasswordExpiredComponent },
  {
    path: 'auth',
    children: [
      { path: '', component: LoginComponent},
      { path: 'code', component: CodeAuthentificationComponent},
      { path: 'mot-de-passe-oublie', component: ForgottenPasswordComponent },
      { path: "reinit-password/:otp", component:  ChangePasswordPageComponent },
    ]
    //loadChildren: () => import('./features/user/user.module').then(mod => mod.UserModule)
  },
  {
    path: "dashboard",
    component:  DashboardComponent,
    canActivate : [AuthGuard]
  },
  {
    path: 'profil',
    component: ProfilComponent,
    canActivate : [ AuthGuard, RoleGuard],
    data : {
      roles : [
        ProfilesUser.ASSISTANTE, ProfilesUser.ADMINISTRATEUR,  ProfilesUser.AGENT, ProfilesUser.ADMINISTRATEUR_TECHNIQUE,
        ProfilesUser.EXPERT_INTERNE, ProfilesUser.EXPERT_EXTERNE, ProfilesUser.ANIMATEUR_RESEAU
      ]
    }
  },
  {
    path: 'dossier',
    canActivate : [AuthGuard],
    children: [
      {
        path: 'list-folder-to-assign',
        component: FolderListToAssignComponent,
        canActivate : [RoleGuard],
        data : {
          roles : [ProfilesUser.ASSISTANTE, ProfilesUser.ADMINISTRATEUR, ProfilesUser.ADMINISTRATEUR_TECHNIQUE, ProfilesUser.ANIMATEUR_RESEAU]
        }
      },
      {
        path: 'fiche/:id',
        component: FicheDossierComponent,
        canActivate : [RoleGuard],
        data : {
          roles : [
            ProfilesUser.ASSISTANTE, ProfilesUser.ADMINISTRATEUR, ProfilesUser.ADMINISTRATEUR_TECHNIQUE,
            ProfilesUser.EXPERT_INTERNE, ProfilesUser.EXPERT_EXTERNE, ProfilesUser.ANIMATEUR_RESEAU
          ]
        }
      },
      {
        path: "demande/creation",
        component : CreationDemandeComponent,
        canActivate : [RoleGuard],
        data : {
          roles : [ProfilesUser.AGENT]
        }
      },
      {
        path: "recherche/gestionnaire",
        component : RechercheDossierGestionnaireComponent,
        canActivate :[RoleGuard],
        data : {
          roles : [ProfilesUser.ASSISTANTE, ProfilesUser.ADMINISTRATEUR, ProfilesUser.ADMINISTRATEUR_TECHNIQUE, ProfilesUser.ANIMATEUR_RESEAU]
        }
      },
      {
        path: "recherche/expert",
        component : RechercheDossierExpertComponent,
        canActivate :[RoleGuard],
        data : {
          roles : [ProfilesUser.EXPERT_EXTERNE, ProfilesUser.EXPERT_INTERNE, ProfilesUser.ADMINISTRATEUR, ProfilesUser.ADMINISTRATEUR_TECHNIQUE,]
        }
      },
      {
        path: "recherche/avancee/agent",
        component : RechercheAvanceeDossierComponent,
        canActivate :[RoleGuard],
        data : {
          roles : [ProfilesUser.AGENT, ProfilesUser.ADMINISTRATEUR, ProfilesUser.ADMINISTRATEUR_TECHNIQUE,]
        }
      },
      {
        path: "demande/suivi",
        component : RechercheDemandeComponent,
        canActivate :[RoleGuard],
        data : {
          roles : [ProfilesUser.AGENT, ProfilesUser.ADMINISTRATEUR, ProfilesUser.ADMINISTRATEUR_TECHNIQUE,]
        }
      },
      {
        path : "demande/fiche/:id",
        component: FicheDemandeComponent,
        canActivate :[RoleGuard],
        data : {
          roles : [ProfilesUser.AGENT, ProfilesUser.ADMINISTRATEUR, ProfilesUser.ADMINISTRATEUR_TECHNIQUE,]
        }
      },
    ]
    //loadChildren: () => import('./features/user/user.module').then(mod => mod.UserModule)
  },
  {
    path: 'avis-valeur/edition/:id',
    component : AvisValeurComponent,
    canActivate : [AuthGuard, RoleGuard],
    data : {
      roles : [
        ProfilesUser.ASSISTANTE, ProfilesUser.ADMINISTRATEUR, ProfilesUser.ADMINISTRATEUR_TECHNIQUE,
        ProfilesUser.EXPERT_INTERNE, ProfilesUser.EXPERT_EXTERNE, ProfilesUser.ANIMATEUR_RESEAU
      ]
    }
  },
  {
    path: 'rapport-expertise/edition/:id',
    component : RapportExpertiseComponent,
    canActivate : [AuthGuard, RoleGuard],
    data : {
      roles : [
        ProfilesUser.ASSISTANTE, ProfilesUser.ADMINISTRATEUR, ProfilesUser.ADMINISTRATEUR_TECHNIQUE,
        ProfilesUser.EXPERT_INTERNE, ProfilesUser.EXPERT_EXTERNE, ProfilesUser.ANIMATEUR_RESEAU
      ]
    }
  } ,{
    path: 'monitoring',
    component : MonitoringComponent,
    canActivate : [AuthGuard, RoleGuard],
    data : {
      roles : [
        ProfilesUser.ADMINISTRATEUR_TECHNIQUE
      ]
    }
  },
  {
    path: "error-link-change-password-expired",
    component:  ErroLinkChangementPasswordExpiredComponent
  },
  {
    path : 'deconnexion',
    component : DeconnexionComponent,
    canActivate : [AuthGuard]
  },
  {
    path : 'aide',
    component : AideComponent
  },
  {
    path : 'role-denied',
    component : RoleDeniedComponent
  },
  {
    path : 'help-navigation',
    component : HelpNavigationComponent,
    canActivate : [AuthGuard]
  },
  {
    path: "**",
    redirectTo: "/auth"
  },

];

@NgModule({
  imports: [
    RouterModule.forRoot(routes,
      {
        preloadingStrategy: PreloadAllModules,
       /* relativeLinkResolution: 'legacy',
        initialNavigation: 'enabled', // for one load page, without reload
        onSameUrlNavigation: 'reload',*/
        //skipLocationChange : 'true'
      }
    )
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
