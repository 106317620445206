import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {frJson} from "./ressource/fr";
import {enJson} from "./ressource/en";
import {LocalStorageService} from "../../core/service/local-storage/local-storage.service";


@Injectable({
  providedIn: 'root'
})
export class TranslateService {

  data: any = {};

  constructor(private http: HttpClient) {
  }

  use() {
    return new Promise<{}>((resolve, reject) => {
        const json = this.getLanguage().toLowerCase() === 'en'.toLowerCase() ? enJson : frJson;
        this.data = Object.assign({}, json || {});
        resolve(this.data);
    });
  }

  get( data : any, key : any, defval? : any, remplacements?:string[]) {
      if (typeof defval == 'undefined') defval = null;
      key = key.split('.');
      for (let i = 0; i < key.length; i++) {
        if(typeof data[key[i]] == 'undefined')
          return defval;
        data = data[key[i]];
      }
      for (let j = 0; j < remplacements.length; j++) {
        data = data.replace("{"+j+"}", remplacements[j]);
      }
      return data;
  }

  getElement( key : any, ...remplacements:string[]) {
    let data = this.data;
    key = key.split('.');
    for (let i = 0; i < key.length; i++) {
      if(data[key[i]]) {
        data = data[key[i]];
      }

    }
    for (let j = 0; j < remplacements.length; j++) {
      data = data.replace("{"+j+"}", remplacements[j]);
    }
    return data;

  }

  public setLanguage(lang : string) {
    localStorage.setItem(LocalStorageService.LANGUE, lang);
  }

  public getLanguage() {
    return localStorage.getItem(LocalStorageService.LANGUE);
  }

  public deleteLocalStorageLanguage() {
    localStorage.removeItem('langue');
  }

}
