import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MessageModule} from "primeng/message";
import {ToastModule} from "primeng/toast";
import {ButtonModule} from "primeng/button";
import {InputTextModule} from "primeng/inputtext";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {LoginComponent} from "./login/login.component";
import {ForgottenPasswordComponent} from "./forgotten-password/forgotten-password.component";
import {UserRoutingModule} from "./user-routing.module";
import {SharedModule} from "../../shared/shared.module";
import {ProfilComponent} from "./profil/profil.component";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {RouterModule} from "@angular/router";
import {DropdownModule} from "primeng/dropdown";
import {MessagesModule} from "primeng/messages";
import {RippleModule} from "primeng/ripple";
import {ToggleButtonModule} from "primeng/togglebutton";
import {TooltipModule} from "primeng/tooltip";
import {ChipModule} from "primeng/chip";
import {CodeAuthentificationComponent} from './code-authentification/code-authentification.component';


@NgModule({
  declarations: [
    LoginComponent,
    ForgottenPasswordComponent,
    ProfilComponent,
    CodeAuthentificationComponent
  ],
    imports: [
        CommonModule,
        MessageModule,
        SharedModule,
        ToastModule,
        ButtonModule,
        InputTextModule,
        BrowserAnimationsModule,
        FormsModule,
        UserRoutingModule,
        RouterModule,
        DropdownModule,
        MessagesModule,
        ReactiveFormsModule,
        RippleModule,
        ToggleButtonModule,
        TooltipModule,
        ChipModule
    ]
})
export class UserModule { }
