<div class="mb-3">
  <button pButton pRipple label="{{'RAPPORT_EXPERTISE.ACTIONS.AJOUTER_NOUVELLE_LIGNE' | translateI18n}}"
          icon="pi pi-plus" class="p-button-sm p-button-rounded" (click)="openNewAmenagement()"></button>
</div>

<p-messages  *ngIf="message" [(value)]="message" key="table"></p-messages>

<p-table [value]="bienAmenagementsInterieur" [rows]="settings?.pagination?.rowsDocument"  [showCurrentPageReport]="true"
         [paginator]="bienAmenagementsInterieur?.length > settings?.pagination?.rowsDocument"
         [rowsPerPageOptions]="settings.pagination?.rowsPerPageOptionsDocument"
         [globalFilterFields]="['amenagementInterieur?.libelle','description']"
         dataKey="id" paginatorPosition="top"
         currentPageReportTemplate="Affichage {first} à {last} sur {totalRecords}" >
  <ng-template pTemplate="header">
    <tr>
      <th></th>
      <th pSortableColumn="commune" style="min-width:15rem">{{'RAPPORT_EXPERTISE.LABEL.ELEMENTS' | translateI18n}} <p-sortIcon field="amenagementInterieur?.libelle"></p-sortIcon></th>
      <th pSortableColumn="section" style="min-width:10rem">{{'RAPPORT_EXPERTISE.LABEL.DESCRIPTION' | translateI18n}} <p-sortIcon field="description"></p-sortIcon></th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-bieai>
    <tr>
      <td class="text-left">
        <button type="button" pButton pRipple icon="inc-icon inc-icon-trash is-small"
                pTooltip="{{'BUTTON_LABEL.DELETE' | translateI18n}}" tooltipPosition="top"
                class="p-button-rounded mr-2" (click)="deleteBienAmenagementInterieur(bieai)"></button>
        <button type="button" pButton pRipple icon="inc-icon inc-icon-edit is-small"
                pTooltip="{{'BUTTON_LABEL.MODIFY' | translateI18n}}" tooltipPosition="top"
                class="p-button-rounded p-button-success mr-2" (click)="editBienAmenagementInterieur(bieai)"></button>
      </td>
      <td>{{bieai.amenagementInterieur?.libelle}}</td>
      <td>{{bieai.description}}</td>
    </tr>
  </ng-template>

</p-table>





<p-dialog [(visible)]="bienAmenagementDialog" [style]="{width: '500px'}" header="{{'RAPPORT_EXPERTISE.LABEL.AJOUTER_ELEMENT' | translateI18n}}" [modal]="true" styleClass="p-fluid">

  <ng-template pTemplate="content">
    <p-messages  *ngIf="messageAmenagement" [(value)]="messageAmenagement" key="dialog"></p-messages>
    <form [formGroup]="bienAmenagementForm" class="page-form">
      <div class="field">
        <label for="amenagementInterieur" [ngClass]="{'p-error': submittedAmenagement
        && formBieAmenagement.amenagementInterieur.invalid}" class="obligatoire">
          {{'RAPPORT_EXPERTISE.LABEL.AMENAGEMENT_INTERRIEUR' | translateI18n}}
        </label>
        <p-dropdown [options]="amenagementsInterieur" formControlName="amenagementInterieur"
                    optionLabel="libelle" name="amenagementInterieur"  id="amenagementInterieur"
                    [ngClass]="{'ng-dirty ng-invalid':  submittedAmenagement  && formBieAmenagement.amenagementInterieur.invalid}"
                    emptyMessage="{{'DOSSIER.MESSAGE.AUCUN_RESULTAT' | translateI18n}}"
                    placeholder="{{'PLACEHOLDER.CHOOSE' | translateI18n}}" >
        </p-dropdown>

        <small class="p-error" *ngIf="submittedAmenagement  && formBieAmenagement.amenagementInterieur?.errors?.required">
          {{'MESSAGE.ERROR.CHAMPS_OBLIGATOIRE' | translateI18n}}</small>
      </div>

      <div class="field">
        <label for="descriptionAmenagement" [ngClass]="{'p-error': submittedAmenagement  && formBieAmenagement.descriptionAmenagement?.errors}">
          {{'RAPPORT_EXPERTISE.LABEL.DESCRIPTION' | translateI18n}}</label>
        <textarea [rows]="5" id="descriptionAmenagement" [cols]="2" style="width: 100%;" pInputTextarea
                  formControlName="descriptionAmenagement" [autoResize]="true"
                  (ngModelChange)="detectChangeDescripionAmenegament($event)" [maxLength]="75"></textarea>

        <small>{{'RAPPORT_EXPERTISE.MESSAGE.MAX_CARACTERE' | translateI18n : '75'}}
          <span>({{nbCaractDescriptionAmenagement}} / 75)</span>
        </small>
      </div>

    </form>
  </ng-template>

  <ng-template pTemplate="footer">
    <button pButton type="button" pRipple label="{{'BUTTON_LABEL.ANNULER' | translateI18n}}" icon="pi pi-times" class="uppercase p-button-secondary p-button-outlined mb-2"
            (click)="hideDialogAmenagement()"></button>
    <button pButton type="submit" pRipple label="{{'BUTTON_LABEL.ENREGISTRER' | translateI18n}}" icon="pi pi-save" class="ppercase mb-2" (click)="saveBienAmenagement()"></button>
  </ng-template>

</p-dialog>

