
<div  *ngIf="errorMessage">
  <p-messages [(value)]="errorMessage" [enableService]="false"></p-messages>
</div>

<form  [formGroup]="form" (ngSubmit)="updateDossierAffectation()" >

   <div class="modale-content">
     <div class="grid">

       <div class="col-12 mt-2">
         <div class="field-section">{{'DOSSIER.AFFAIRE' | translateI18n}}</div>
       </div>

       <div *ngIf="dossier?.client?.nomMoral && !dossier.client.isClientPhysique" class="field col-12">
         <label for="nomMoral" [style.color]="(f.nomMoral.touched || submitted) && f.nomMoral.errors ?'red':null">
           {{ 'DOSSIER.RAISON_SOCIALE'| translateI18n}}</label>
         <div class="p-input-icon-right w-full">
           <input type="text" pInputText id="nomMoral" formControlName="nomMoral" name="nomMoral" class="inputfield w-full"
                  [style.border-color]="(f.nomMoral.touched || submitted) && form.controls.nomMoral.errors ?'red':null"
                  [ngClass]="{ 'is-invalid': submitted && f['nomMoral'].errors }" [maxLength]="configSizeInput.NOM_MOARAL_CLIENT"/>
         </div>

         <div *ngIf="(f.nomMoral.touched || submitted)" class="pt-2">
           <small *ngIf="f.nomMoral?.errors?.pattern" class="p-error block">
             {{'MESSAGE.ERROR.REGEX_PATTERN' | translateI18n}}</small>
           <small *ngIf="f.nomMoral?.errors?.required" class="p-error block">{{'MESSAGE.ERROR.CHAMPS_OBLIGATOIRE' | translateI18n}}</small>
         </div>
       </div>

       <div class="col-12">
         <div *ngIf="dossier.client.isClientPhysique" class="grid">

           <div class="field col-12 lg:col-6">
             <label for="nomClient" [style.color]="(f.nomClient.touched || submitted) && f.nomClient.errors ?'red':null">
               {{'DOSSIER.NOM_CLIENT' | translateI18n}}</label>
             <div class="p-input-icon-right w-full">
               <input type="text" pInputText id="nomClient" formControlName="nomClient" name="nomClient" class="inputfield w-full"
                      [style.border-color]="(f.nomClient.touched || submitted) && form.controls.nomClient.errors ?'red':null"
                      [ngClass]="{ 'is-invalid': submitted && f['nomClient'].errors }" [maxLength]="configSizeInput.NOM_CLIENT"/>
             </div>


             <div *ngIf="(f.nomClient.touched || submitted)" class="pt-2">
               <small *ngIf="f.nomClient?.errors?.pattern" class="p-error block">
                 {{'MESSAGE.ERROR.REGEX_PATTERN' | translateI18n}}</small>
               <small *ngIf="f.nomClient?.errors?.required" class="p-error block">{{'MESSAGE.ERROR.CHAMPS_OBLIGATOIRE' | translateI18n}}</small>
             </div>
           </div>

           <div class="field col-12  lg:col-6">
             <label for="prenomClient">
               {{'DOSSIER.PRENOM_CLIENT' | translateI18n}}</label>
             <div class="p-input-icon-right w-full">
               <input type="text" pInputText id="prenomClient"
                      formControlName="prenomClient" name="prenomClient"
                      class="inputfield w-full" [maxLength]="configSizeInput.PRENOM_CLIENT"/>
             </div>
             <small *ngIf="(f.nomClient.touched || submitted) &&  f.prenomClient?.errors?.pattern" class="p-error block">
               {{'MESSAGE.ERROR.REGEX_PATTERN' | translateI18n}}</small>
           </div>


         </div>

       </div>

     </div>


    <!-- Adresse/auto completion google places-->
     <app-adresse formGroupName="adresse" [dossier]="dossier" [submitted]="submitted" ></app-adresse>

   </div>

  <div class="p-dialog-footer">
    <button pButton type="button" label="{{'BUTTON_LABEL.CLOSE' | translateI18n}}"
            (click)="close()" class="uppercase p-button-secondary p-button-outlined mb-2" icon="pi pi-times" iconPos="right"></button>

    <button pButton type="submit" label="{{'BUTTON_LABEL.MAJ' | translateI18n}}" class="uppercase mb-2" icon="pi pi-save"
            iconPos="right"></button>
  </div>

</form>


<ngx-spinner type="ball-scale-multiple"><p-progressSpinner></p-progressSpinner></ngx-spinner>
