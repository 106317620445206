<form [formGroup]="form" >
  <div class="card-large" id="section1">

    <h1 id="situationGeo">III - {{'RAPPORT_EXPERTISE.SITUATION_GEO' | translateI18n}}</h1>

    <div class="grid">

      <div class="col-12">
        <h2>{{'RAPPORT_EXPERTISE.LOCALISATION' | translateI18n}}</h2>
        <div class="field input-ghost locked">
          <span class="label"> {{ 'RAPPORT_EXPERTISE.LABEL.COMMUNE' | translateI18n}} : </span>
          <b>{{rapport?.bienAdresse?.ville}}</b>
        </div>

      </div>


      <div class="col">
        <section>
          <div class="field input-ghost">
            <label for="secteur" class="label">{{ 'RAPPORT_EXPERTISE.LABEL.SECTEUR' | translateI18n}} :</label>
            <p-dropdown [options]="secteurs" formControlName="secteur"
                        optionLabel="libelle" name="secteur"  id="secteur"
                        emptyMessage="{{'DOSSIER.MESSAGE.AUCUN_RESULTAT' | translateI18n}}"
                        placeholder="{{'PLACEHOLDER.CHOOSE' | translateI18n}}" >
            </p-dropdown>
          </div>
        </section>
      </div>


      <div class="col">
        <section>
          <div class="field input-ghost">
            <label for="secteur" class="label">{{ 'RAPPORT_EXPERTISE.LABEL.POPULATION' | translateI18n}} :</label>
            <p-inputNumber formControlName="population"  inputId="minmax-buttons" [min]="0" placeholder="{{'RAPPORT_EXPERTISE.LABEL.RENSEIGNER' | translateI18n}}">
            </p-inputNumber>
          </div>
        </section>
      </div>

      <div class="field col-12 field-line">

        <div class="mb-2">
          <label class="m-0 label obligatoire">{{'RAPPORT_EXPERTISE.LABEL.LOCALISATION' | translateI18n}}</label>
          <div>
            <small>{{'RAPPORT_EXPERTISE.MESSAGE.MAX_CARACTERE' | translateI18n : '4000'}}
              <span [ngClass]="{'is-alert':  f.localisation.hasError('hasError')}">({{nbLocalisation}} / 4000)</span>
            </small>
          </div>

        </div>

        <div *ngIf="f.localisation.hasError('hasError')">
          <p-messages  [(value)]="msgError" key="localisation"></p-messages>
        </div>

        <p-editor formControlName="localisation" [style]="{'height':'340px'}" name="localisation"
                  id="localisation" class="w-full"
                  [ngClass]="{'editor-error': f.localisation.hasError('hasError')
                  || (submittedRequired && (f.localisation?.errors?.required ||  f.localisation.hasError('hasEmptyCaracterError')))}"
                  (onTextChange)="numLocalisationTextChange($event)">
          <p-header>
            <app-p-editor-header></app-p-editor-header>
          </p-header>
        </p-editor>

        <small *ngIf="submittedRequired && (f.localisation?.errors?.required ||  f.localisation.hasError('hasEmptyCaracterError'))" class="p-error block">{{'MESSAGE.ERROR.CHAMPS_OBLIGATOIRE' | translateI18n}}</small>
      </div>


      <div class="col-12">
        <h2>{{'RAPPORT_EXPERTISE.DESSERTE' | translateI18n}}</h2>
        <p-messages  *ngIf="messageSuppressionBienDesserte" [(value)]="messageSuppressionBienDesserte"></p-messages>
        <div class="mb-3">
          <button pButton pRipple label="{{'RAPPORT_EXPERTISE.LABEL.ADD_NEW_LINE' | translateI18n}}" icon="pi pi-plus" class="p-button-sm p-button-rounded" (click)="openNew()"></button>
        </div>
        <p-table [value]="bienDessertes" [rows]="settings.pagination?.rowsDocument"  [showCurrentPageReport]="true"
                 [paginator]="bienDessertes?.length > settings.pagination?.rowsDocument" [rowsPerPageOptions]="settings.pagination?.rowsPerPageOptionsDocument"
                 [globalFilterFields]="['desserte.desserte?.libelle','description']"
                 dataKey="id" paginatorPosition="top"
                 currentPageReportTemplate="Affichage {first} à {last} sur {totalRecords}" >

          <ng-template pTemplate="header">
            <tr>
              <th class="width-9"></th>
              <th class="width-5"></th>
              <th class="" pSortableColumn="desserte.desserte?.libelle">
                {{'RAPPORT_EXPERTISE.LABEL.TYPE' | translateI18n}}<p-sortIcon field="desserte.desserte?.libelle"></p-sortIcon></th>
              <th class="" pSortableColumn="description" >
                {{'RAPPORT_EXPERTISE.LABEL.DESCRIPTION' | translateI18n}} <p-sortIcon field="description"></p-sortIcon></th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-bienDesserte>
            <tr>
              <td>
                <button type="button" pButton pRipple icon="inc-icon inc-icon-trash is-small"
                        pTooltip="{{'BUTTON_LABEL.DELETE' | translateI18n}}" tooltipPosition="top"
                        class="p-button-rounded mr-2" (click)="deleteDesserte(bienDesserte)"></button>
                <button type="button" pButton pRipple icon="inc-icon inc-icon-edit is-small"
                        pTooltip="{{'BUTTON_LABEL.MODIFY' | translateI18n}}" tooltipPosition="top"
                        class="p-button-rounded p-button-success" (click)="editDesserte(bienDesserte)"></button>
              </td>
              <td class="" id="thumbnailDesserte"><img src="../../assets/images/{{bienDesserte.desserte?.code | iconDesserte}}" alt="" class="thumbnailDesserte"> </td>
              <td class="">{{bienDesserte.desserte?.libelle}}</td>
              <td class="">{{bienDesserte.description}}</td>
            </tr>
          </ng-template>

        </p-table>
      </div>


      <div class="field col-12 field-line">

        <div class="mb-2">
          <small>{{'RAPPORT_EXPERTISE.MESSAGE.MAX_CARACTERE' | translateI18n : '1000'}}
            <span [ngClass]="{'is-alert':  f.desserte.hasError('hasError')}">({{nbDesserte}} / 1000)</span>
          </small>
        </div>

        <div *ngIf="submitted && f.desserte.hasError('hasError')">
          <p-messages  [(value)]="msgError" key="desserte"></p-messages>
        </div>

        <p-editor formControlName="desserte" [style]="{'height':'130px'}" name="desserte"
                  id="desserte" class="w-full"
                  [ngClass]="{'editor-error': f.desserte.hasError('hasError')}"
                  (onTextChange)="numDesserteTextChange($event)">
          <p-header>
            <app-p-editor-header></app-p-editor-header>
          </p-header>
        </p-editor>
      </div>

      <div class="col-12" >

        <div class="card-map" *ngIf="displayMapGoogle && !documentCarte && rapport?.bienAdresse?.lat && rapport?.bienAdresse?.lng; else imageCarte" >
          <app-carte-google-maps [adresse]="rapport?.bienAdresse" [height]="'550px'"></app-carte-google-maps>
        </div>

        <ng-template #imageCarte>
          <div *ngIf="!documentCarte">
            <app-upload-photo
              [displayLegende]="false"
              label="{{'RAPPORT_EXPERTISE.LABEL.PHOTO_MAPS' | translateI18n}}"
              [document]="documentCarte"
              [addNewPhoto]="true"
              [parent]="this"
              [idDossier]="rapport?.idDossier"
              [idObjetPrincipal]="rapport?.idBien"
              [idCategorie]="12"
              [multiple]="false"></app-upload-photo>
          </div>


          <div *ngIf="documentCarte">
            <figure >
              <img [src]="documentCarte?.lienImage" alt="">
              <figcaption class="flex">
                <div class="cta flex">
                  <app-upload-photo
                    [displayLegende]="false"
                    [document]="documentCarte"
                    [addNewPhoto]="false"
                    [parent]="this"
                    class="update"
                    [keyMessage]="'uploadPhotoCarte'"
                    [idObjetPrincipal]="rapport?.idBien"
                    [idDossier]="rapport.idDossier"
                    [idCategorie]="12" [multiple]="false"></app-upload-photo>

                  <button type="button" pButton pRipple icon="pi pi-trash"
                          pTooltip="{{'RAPPORT_EXPERTISE.LABEL.SUPPRIMER' | translateI18n}}"
                          tooltipPosition="top" class="p-button-rounded p-button-sm w-full m-0"
                          (click)="deleteImage(documentCarte)"></button>
                </div>
              </figcaption>
            </figure>
          </div>
        </ng-template>


      </div>


      <div class="col-12">
        <section>
          <h2>{{'RAPPORT_EXPERTISE.PHOTO_ENVIRONNEMENT' | translateI18n}} ({{documentEnv?.length > 0 ? documentEnv?.length : 0 }})</h2>
          <p-messages [(value)]="message" [escape]="false" key="uploadPhotoEnv"></p-messages>

          <p-progressSpinner *ngIf="progressSpinner"  [style]="{width: '100px', height: '100px'}"
                             styleClass="custom-spinner justify-content-center flex mt-6" strokeWidth="3" fill="var(--surface-ground)"
                             animationDuration=".5s"></p-progressSpinner>

          <div class="grid gallery" *ngIf="!progressSpinner">
            <div class="col-12  md:col-6 lg:col-4" *ngFor="let doc of documentEnv">
              <figure >
                <img [src]="doc.lienImage" alt="">
                <figcaption class="flex">

                  <div class="field input-ghost commentairePhoto">
                    <app-commentaire-photo [doc]="doc" [keyMessage]="'uploadPhotoEnv'" [idDossier]="rapport?.idDossier"></app-commentaire-photo>
                  </div>

                  <div class="cta flex">
                    <button type="button" pButton pRipple icon="pi pi-trash"
                            pTooltip="Supprimer" tooltipPosition="top" class="test p-button-rounded p-button-sm w-full m-0" (click)="deleteImage(doc)"></button>
                  </div>
                </figcaption>
              </figure>
            </div>

            <div class="col-12  md:col-6 lg:col-4">
              <app-upload-photo [displayLegende]="false"
                                categorie="{{'RAPPORT_EXPERTISE.LABEL.PHOTO_ENVIRONNEMENT' | translateI18n}}"
                                [idObjetPrincipal]="rapport?.idBien" [idCategorie]="2"
                                [addNewPhoto]="true" [parent]="this"
                                [idDossier]="rapport.idDossier"
                                [keyMessage]="'uploadPhotoEnv'"
                                [multiple]="true">
              </app-upload-photo>
            </div>
          </div>

        </section>
      </div>

    </div>

  </div>

</form>



<p-dialog [(visible)]="bienDesserteDialog" [style]="{width: '500px'}" header="{{'RAPPORT_EXPERTISE.LABEL.DESSERTE' | translateI18n}}" [modal]="true" styleClass="p-fluid">

  <ng-template pTemplate="content">
    <p-messages  *ngIf="messageBienDesserte" [(value)]="messageBienDesserte"></p-messages>
    <form [formGroup]="bienDesserteForm" class="page-form">
      <div class="field">
        <label for="desserteTransport" [ngClass]="{'p-error': submittedBienDesserte  && fBieDess.desserteTransport.invalid}" class="obligatoire">
          {{'RAPPORT_EXPERTISE.LABEL.DESSERTE' | translateI18n}}
        </label>
        <p-dropdown [options]="dessertes" formControlName="desserteTransport"
                    optionLabel="libelle" name="desserteTransport"  id="desserteTransport"
                    [ngClass]="{'ng-dirty ng-invalid':  submittedBienDesserte  && fBieDess.desserteTransport.invalid}"
                    emptyMessage="{{'DOSSIER.MESSAGE.AUCUN_RESULTAT' | translateI18n}}"
                    placeholder="{{'PLACEHOLDER.CHOOSE' | translateI18n}}" >
        </p-dropdown>
      </div>

      <div class="field">
        <label for="description" [ngClass]="{'p-error': submittedBienDesserte  && fBieDess.lieudit?.errors}">
          {{'RAPPORT_EXPERTISE.LABEL.DESCRIPTION' | translateI18n}}</label>
        <textarea [rows]="10" id="description" [cols]="2" style="width: 100%;" pInputTextarea formControlName="description" [autoResize]="true"
                  (ngModelChange)="detectChangeComment($event)" [maxLength]="130"></textarea>

        <small>{{'RAPPORT_EXPERTISE.MESSAGE.MAX_CARACTERE' | translateI18n : '130'}}
          <span>({{nbCaractDescriptionDesserte}} / 130)</span>
        </small>
      </div>

    </form>
  </ng-template>

  <ng-template pTemplate="footer">
    <button pButton type="button" pRipple label="{{'BUTTON_LABEL.ANNULER' | translateI18n}}" icon="pi pi-times" class="uppercase p-button-secondary p-button-outlined mb-2"
            (click)="hideDialog()"></button>
    <button pButton type="submit" pRipple label="{{'BUTTON_LABEL.ENREGISTRER' | translateI18n}}" icon="pi pi-save" class="ppercase mb-2" (click)="saveBienDesserte()"></button>
  </ng-template>

</p-dialog>
