import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {CoreModule} from "../../core/core.module";
import {SharedModule} from "../../shared/shared.module";
import {MessagesModule} from "primeng/messages";
import {MessageModule} from "primeng/message";
import {ToastModule} from "primeng/toast";

import {TableModule} from 'primeng/table';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {TooltipModule} from "primeng/tooltip";
import {DropdownModule} from "primeng/dropdown";
import {InputTextModule} from "primeng/inputtext";
import {EditorModule} from "primeng/editor";
import {NgxSpinnerModule} from "ngx-spinner";
import {RapportExpertiseRoutingModule} from "./rapport-expertise-routing.module";
import {ButtonModule} from "primeng/button";
import {FileUploadModule} from "primeng/fileupload";
import {ProgressSpinnerModule} from "primeng/progressspinner";
import {ConfirmDialogModule} from "primeng/confirmdialog";
import {RapportExpertiseComponent} from "./rapport-expertise.component";
import {MenuModule} from "primeng/menu";
import {RippleModule} from "primeng/ripple";


@NgModule({
  declarations: [
    RapportExpertiseComponent,
  ],
  imports: [
    CommonModule,
    CoreModule,
    RapportExpertiseRoutingModule,
    SharedModule,
    MessagesModule,
    MessageModule,
    TableModule,
    ToastModule,
    FormsModule,
    TooltipModule,
    DropdownModule,
    InputTextModule,
    ReactiveFormsModule,
    EditorModule,
    NgxSpinnerModule,
    ButtonModule,
    FileUploadModule,
    ProgressSpinnerModule,
    ConfirmDialogModule,
    MenuModule,
    RippleModule
  ]
})
export class RapportExpertiseModule { }
