import {Component, Input, OnInit} from '@angular/core';
import {MessageService} from 'primeng/api';
import {FileUpload} from 'primeng/fileupload';
import {LocalStorageService} from "../service/local-storage/local-storage.service";
import {DocumentService} from "../service/document.service";
import {Document} from "../models/document/document.model";
import {UploadResponse} from '../models/document/upload-response';
import {TranslateService} from 'src/app/shared/translate/translate.service';
import {CreationDemandeObservableService} from "../../shared/observable/creation-demande-observable.service";
import {FileValidation} from "../../shared/providers/file-validation";
import {StatutDossier} from "../models/enum/statut-dossier";
import {UserService} from "../service/user/user.service";
import {ProfilesUser} from "../models/user/profiles-user";
import {NgxSpinnerService} from "ngx-spinner";
import {Router} from "@angular/router";

export interface ListeDocument {
  addDocument(documents: Document[]): void;
}

@Component({
  selector: 'app-upload-document',
  templateUrl: './upload-document.component.html',
  styleUrls: ['./upload-document.component.scss']
})
export class UploadDocumentComponent implements OnInit {

  static uploadMaxSize = 10; // en Mo
  static moEnOctet = 1048576;
  static statutAAffecter = "A_AFFECTER";
  static statutEncours = "ENCOURS";
  static statutEnAttenteValidation = "D_ATTENTE_V1";
  static statutAnnule = "ANNULE";

  @Input() objet: string;
  @Input() idObjet: number;
  @Input() parent: ListeDocument;
  @Input() label: string;
  @Input() statut: string;
  @Input() aide: boolean = false;
  @Input() idTiers : number;
  @Input() expertID : number;
  @Input() verouiller : boolean;
  @Input() verouillerExpert : boolean;
  @Input() creationDemande : boolean;
  @Input() idDossier: number;

  uploadAllowed: boolean;

  uploadResponses: Document [] = [];

  aideContent : string;

  constructor(
        private message: MessageService,
        private service: DocumentService,
        private tokenStorgeService : LocalStorageService,
        private translate : TranslateService,
        private fileObservable : CreationDemandeObservableService,
        private userService : UserService,
        private spinnerService: NgxSpinnerService,
        private router : Router
  ) {
  }

  async ngOnInit() {
    this.aideContent = this.translate.getElement('DOCUMENT.UPLOAD.AIDE');
    this.uploadAllowed = await this.isUploadAllowed();
  }

  onUpload(event: any, fileUpload: FileUpload) {
    this.spinnerService.show();
    let upload: File[] = [];
    let filesTooBig: String[] = [];
    let fileExtensionsNotAllowed : string [] = [];
    for (let file of event.files) {
      if(!FileValidation.isExtension(file.name)) {
        fileExtensionsNotAllowed.push(file.name);
      }
      else if (file.size >= this.uploadMaxSizeOctet()) {
        filesTooBig.push(file.name);
      } else {
        upload.push(file);
      }
    }
    fileUpload.clear(); // Permet de choisir et uploader un nouveau fichier
    this.message.clear();

    if(fileExtensionsNotAllowed.length > 0) {
      this.message.add({
        key: 'upload',
        severity: 'warn',
        summary: this.translate.getElement("DOCUMENT.UPLOAD.MESSAGE.WARNING.EXTENSIONS_NON_AUTORISEES", fileExtensionsNotAllowed.join("<br/>"))
      });
    }


    if (filesTooBig.length > 0) {
      this.message.add({key: 'upload', severity: 'warn', summary: this.translate.getElement("DOCUMENT.UPLOAD.MESSAGE.WARNING.TAILLE_MAX_DEPASSEE", filesTooBig.join("<br/>"), UploadDocumentComponent.uploadMaxSize.toString())});
    }
    if (upload.length > 0) {

      if (this.idObjet) {
        try {
          this.service.postFile(upload, this.objet, this.idObjet, this.idDossier).subscribe(data => {
            if(!data) {
              this.message.add({key: 'upload', severity: 'error', summary: this.translate.getElement("DOCUMENT.UPLOAD.MESSAGE.ERROR.GENERIQUE")});
              this.spinnerService.hide();
              return;
            }
            let res: UploadResponse = data;
            let noms: string = "";
            for (let i = 0; i < res.documentsUploades.length; i += 1) {
              noms = noms.concat(res.documentsUploades[i].nom + "<br/>");
            }
            if (noms.length != 0) {
              this.message.add({
                key: 'upload',
                severity: 'success',
                summary: this.translate.getElement("DOCUMENT.UPLOAD.MESSAGE.SUCCESS", noms)
              });
              noms = "";
            }

            for (let i = 0; i < res.documentsTropGros.length; i += 1) {
              noms = noms.concat(res.documentsTropGros[i] + "<br/>");
            }
            if (noms.length != 0) {
              this.message.add({
                key: 'upload',
                severity: 'warn',
                summary: this.translate.getElement("DOCUMENT.UPLOAD.MESSAGE.WARNING.TAILLE_MAX_DEPASSEE", noms, UploadDocumentComponent.uploadMaxSize.toString())
              });
              noms = "";
            }

            for (let i = 0; i < res.documentsHomonymes.length; i += 1) {
              noms = noms.concat(res.documentsHomonymes[i] + "<br/>");
            }
            if (noms.length != 0) {
              this.message.add({
                key: 'upload',
                severity: 'warn',
                summary: this.translate.getElement("DOCUMENT.UPLOAD.MESSAGE.WARNING.DOCUMENT_HOMONYME", noms)
              });
              noms = "";
            }

            for (let i = 0; i < res.documentsNomInterdit.length; i += 1) {
              noms = noms.concat(res.documentsNomInterdit[i] + "<br/>");
            }
            if (noms.length != 0) {
              this.message.add({
                key: 'upload',
                severity: 'warn',
                summary: this.translate.getElement("DOCUMENT.UPLOAD.MESSAGE.WARNING.DOCUMENT_NOM_INTERDIT", noms)
              });
              noms = "";
            }

            for (let i = 0; i < res.documentsNonAcceptes.length; i += 1) {
              noms = noms.concat(res.documentsNonAcceptes[i] + "<br/>");
            }
            if (noms.length != 0) {
              this.message.add({
                key: 'upload',
                severity: 'warn',
                summary: this.translate.getElement("DOCUMENT.UPLOAD.MESSAGE.WARNING.EXTENSIONS_NON_AUTORISEES", noms)
              });
              noms = "";
            }

            for (let i = 0; i < res.documentsErreur.length; i += 1) {
              noms = noms.concat(res.documentsErreur[i] + "<br/>");
            }
            if (noms.length != 0) {
              this.message.add({
                key: 'upload',
                severity: 'error',
                summary: this.translate.getElement("DOCUMENT.UPLOAD.MESSAGE.ERROR.INCONNUE", noms)
              });
            }

            this.parent.addDocument(res.documentsUploades);
            this.spinnerService.hide();
          }, error => {
            if (error.status == 406) {
              this.message.add({
                key: 'upload',
                severity: 'error',
                summary: this.translate.getElement("DOCUMENT.UPLOAD.MESSAGE.ERROR.UTILISATEUR_NON_AUTORISEE")
              });
              this.spinnerService.hide();
            } else {
              this.message.add({
                key: 'upload',
                severity: 'error',
                summary: this.translate.getElement("DOCUMENT.UPLOAD.MESSAGE.ERROR.GENERIQUE")
              });
              this.spinnerService.hide();
            }
            this.spinnerService.hide();
          })
        }catch (error) {
          this.message.add({
            key: 'upload',
            severity: 'error',
            summary: this.translate.getElement("DOCUMENT.UPLOAD.MESSAGE.ERROR.GENERIQUE")
          });
          this.spinnerService.hide();
        }

      }
      else {
        this.fileObservable.sendFiles(upload);
        this.spinnerService.hide();
      }
    }else {
      this.spinnerService.hide();
    }
  }

  private async isUploadAllowed() {

    let profiles = await this.userService.getUserConnectedProfilesById();
    let userConnecte = await this.userService.getUserConnectedByToken();
    let agence = await this.userService.getAgenceUserConnected();
    if (profiles.includes(ProfilesUser.ADMINISTRATEUR) || profiles.includes(ProfilesUser.ADMINISTRATEUR_TECHNIQUE)
      || profiles.includes(ProfilesUser.ANIMATEUR_RESEAU)
      || profiles.includes(ProfilesUser.ASSISTANTE)) {
      if(this.verouiller) {
        return false;
      }
      return UploadDocumentComponent.statutAnnule !== this.statut;
    }
    if (profiles.includes(ProfilesUser.AGENT) ) {
      return (!this.idTiers && this.router.url.includes('/dossier/demande/creation?type') && this.verouiller === false)
        || (agence && agence.id === this.idTiers
        && (UploadDocumentComponent.statutAAffecter === this.statut || UploadDocumentComponent.statutEncours === this.statut));
    }
    if (profiles.includes(ProfilesUser.EXPERT_INTERNE)
      || (profiles.includes(ProfilesUser.EXPERT_EXTERNE) && userConnecte.id === this.expertID)
    ) {
        if (this.verouiller || this.verouillerExpert) {
          return false;
        }
        return this.statut === StatutDossier.ENCOURS;
    }

    return false;
  }


  private uploadMaxSizeOctet() {
    return UploadDocumentComponent.uploadMaxSize * UploadDocumentComponent.moEnOctet;
  }

}
