import {ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {BienAnnexe} from "../../../../models/bien/bien-annexe";
import {AbstractControl, FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {DialogService, DynamicDialogRef} from "primeng/dynamicdialog";
import {ConfirmationService, Message, MessageService} from "primeng/api";
import {RapportExpertiseService} from "../../../../service/rapport-expertise.service";
import {AppSettings, Settings} from "../../../../../app.settings";
import {ConfigSizeInput} from "../../../../../shared/providers/config-size-input";
import {ReferentielValeur} from "../../../../models/referentiel-valeur";
import {Validation} from "../../../../../shared/providers/validation";
import {TranslateService} from "../../../../../shared/translate/translate.service";

@Component({
  selector: 'app-table-annexes-bien',
  templateUrl: './table-annexes-bien.component.html',
  styleUrls: ['./table-annexes-bien.component.scss']
})
export class TableAnnexesBienComponent implements OnInit {

  @Input()
  idBien : number

  @Input()
  idDossier : number

  bienAnnexe : BienAnnexe;
  submitted  : boolean;
  dialog : boolean;

  bienAnnexes : BienAnnexe[];

  public settings: Settings;

  configSizeInput : ConfigSizeInput;

  messageBieAnnexe : Message[];

  messageBieAnnexeDialog : Message[];

  annexeForm : FormGroup;

  annexes : ReferentielValeur[];

  constructor(
    private fb : FormBuilder,
    public dialogService: DialogService,
    public ref: DynamicDialogRef,
    private cdref: ChangeDetectorRef,
    private messageService : MessageService,
    private rapportExpertiseService : RapportExpertiseService,
    private confirmation: ConfirmationService,
    public appSettings : AppSettings,
    private translate : TranslateService
  ) {
    this.settings = this.appSettings.settings;
    this.configSizeInput = new ConfigSizeInput();

  }

  ngOnInit(): void {
    this. getAllBienAnnexeByBienId();
    this.getAllAnnexes();
  }

  initForm(biea? : BienAnnexe) {
    this.annexeForm = this.fb.group({
      nature  : new FormControl(biea?.annexe ? biea?.annexe :null, Validators.required),
      nombre  : new FormControl(biea?.nombre ? biea?.nombre :null, [
        Validators.required,
        Validation.patternValidatorMaxLengthCaracter(25, {hasError: true})])
    });
  }

  async getAllBienAnnexeByBienId() {
    this.bienAnnexes = await this.rapportExpertiseService.getAllBienAnnexesByBienId(this.idBien);
  }

  saveBienAnnexe() {

    this.submitted = true;

    const biea : BienAnnexe = new BienAnnexe();

    if(this.annexeForm.invalid) {
      this.messageBieAnnexeDialog = [{key :'dialogAnnexe', severity:'error', summary: this.translate.getElement('MESSAGE.ERROR.MS_CHAMPS_OBLIGATOIRE')}];
      return;
    }

    if(this.bienAnnexe) {
      biea.id = this.bienAnnexe?.id;
    }

    const value = this.annexeForm.value;
    biea.annexe = value.nature;
    biea.nombre = value.nombre;

    biea.idBien = this.idBien;
    biea.idDossier = this.idDossier;

    this.rapportExpertiseService.saveBieAnnexe(biea).subscribe( data => {
      if(data) {
        this.hideDialog()
        this.getAllBienAnnexeByBienId();
        return;
      }
      this.messageBieAnnexeDialog =[ {key : 'dialogAnnexe', severity:'error', summary:  this.translate.getElement("RAPPORT_EXPERTISE.MESSAGE.ERROR_MESSAGE_MODAL")}];
      this.submitted = false;
    }, error => {
      this.messageBieAnnexeDialog =[ {key : 'dialogAnnexe', severity:'error', summary:  this.translate.getElement("RAPPORT_EXPERTISE.MESSAGE.ERROR_MESSAGE_MODAL")}];
      this.submitted = false;
    })
  }

  getAllAnnexes() {
    this.rapportExpertiseService.getAllAnnexes().subscribe( data => {
      this.annexes = data;
    })
  }

  editBieAnnexe(biea: BienAnnexe) {
    this.messageBieAnnexe = null;
    this.bienAnnexe = {...biea};
    this.initForm(this.bienAnnexe);
    this.dialog = true;
  }

  deleteBieAnnexe(biea: BienAnnexe) {
    if(biea) {
      this.confirmation.confirm({
        header: this.translate.getElement("RAPPORT_EXPERTISE.MESSAGE.ETES_VOUS_SUR"),
        message : this.translate.getElement("RAPPORT_EXPERTISE.MESSAGE.CONFIRMER"),
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          this.rapportExpertiseService.deleteBienAnnexe(biea.id, this.idDossier).then( data => {
            if(data) {
              this.getAllBienAnnexeByBienId();
              this.messageBieAnnexe =[ {key : 'tableAnnexe', severity:'success', summary:  this.translate.getElement("RAPPORT_EXPERTISE.MESSAGE.SUCESS_SUPPRESSION")}];
              return;
            }
            this.messageBieAnnexe =[ {key : 'tableAnnexe', severity:'error', summary:  this.translate.getElement("RAPPORT_EXPERTISE.MESSAGE.ERROR_SUPPRESSION")}];
          }).catch( error => {
            this.messageBieAnnexe =[ {key : 'tableAnnexe', severity:'error', summary:  this.translate.getElement("RAPPORT_EXPERTISE.MESSAGE.ERROR_SUPPRESSION")}];
          })
        }
      })

    }
  }

  openNew() {
    this.messageBieAnnexe = null;
    this.initForm(null);
    this.bienAnnexe = null;
    this.submitted = false;
    this.dialog = true;
  }


  hideDialog() {
    this.dialog = false;
    this.submitted = false;
  }


  get formBieAnnexe(): { [key: string]: AbstractControl } {
    return this.annexeForm.controls;
  }
}
