<p-messages *ngIf="message != null"  [(value)]="message" [enableService]="false"  class="mb-3 block">
</p-messages>
<div class="card">
  <form id="change_password" [formGroup]="form" (ngSubmit)="onSubmit()" >

    <div class="form-title">{{'FORM_CHANGE_PASSWORD.TITLE' | translateI18n}}</div>

    <div class="field" *ngIf="askCurrentPassword">
      <label for="currentPassword">{{'FORM_CHANGE_PASSWORD.LABEL.CURRENT_PASSWORD' | translateI18n}}</label>
      <div class="p-input-icon-right w-full">
        <i class="pi pi-lock"></i>
        <input type="password" pInputText id="currentPassword"
               class="inputfield w-full"
               formControlName="currentPassword" required />
      </div>
    </div>

    <div class="field">
      <label for="password">{{'FORM_CHANGE_PASSWORD.LABEL.NEW_PASSWORD' | translateI18n}}</label>
      <div class="p-input-icon-right w-full">
        <i class="pi pi-lock"></i>
        <input type="password" pInputText id="password" class="inputfield w-full" formControlName="password"
               [ngClass]="{ 'is-invalid': submitted && f['password'].errors }" required />


        <div *ngIf="f.password.touched && f.password.invalid" class="pt-2">
          <small *ngIf="f.password.errors.required" id="form-title" class="p-error block">{{'FORM_CHANGE_PASSWORD.MESSAGE.ERROR.NEW_PASSWORD' | translateI18n}}</small>
        </div>

      </div>

    </div>

    <div class="field">
      <label for="confirmPassword">{{'FORM_CHANGE_PASSWORD.LABEL.CONFIRM_PASSWORD' | translateI18n}}</label>
      <div class="p-input-icon-right w-full">
        <i class="pi pi-lock"></i>
        <input type="password" pInputText id="confirmPassword" class="inputfield w-full"
               formControlName="confirmPassword"
               [ngClass]="{ 'is-invalid': submitted && f['confirmPassword'].errors }" required />
      </div>
      <div *ngIf="f.password.valid && f.confirmPassword.touched && f.confirmPassword.invalid" class="pt-2">
        <small *ngIf="!submitted && f.confirmPassword.errors.required" class="p-error block">{{confirmPasswordMessage}}</small>
        <small *ngIf="f.confirmPassword.errors.confirmedValidator && form.value.password !== form.value.confirmPassword" class="p-error block">
          {{'FORM_CHANGE_PASSWORD.MESSAGE.ERROR.NEWPASSWORD_NOT_MATCH_CURRENTPASSWORD' | translateI18n}}
        </small>
      </div>

      <div *ngIf="submitted && !form.value.confirmPassword" class="invalid-feedback" class="pt-2">
        <small class="p-error block">{{confirmPasswordMessage}}</small>
      </div>
    </div>

    <div>
      <ul class="rulePassword" style="list-style-type:none;">
        <li class="input-filled w-full" [ngStyle]="{color : f.password.hasError('required') || f.password.hasError('minlength') ? 'red' : 'green' }">
          <i class="{{ f.password.hasError('required') || f.password.hasError('minlength') ? 'pi pi-times-circle' : 'pi pi-check-circle' }}"></i>
          {{'FORM_CHANGE_PASSWORD.POLITIQUEPASSWORD.N_CHARACTER' | translateI18n : maxPasswordLength}}
        </li>

        <li  class="input-filled w-full"  [ngStyle]="{color :f.password.hasError('required') ||
                                      f.password.hasError('hasCapitalCase')  ? 'red' : 'green'}">
          <i class="{{f.password.hasError('required') || f.password.hasError('hasCapitalCase') ? 'pi pi-times-circle' : 'pi pi-check-circle' }}"></i>
          {{'FORM_CHANGE_PASSWORD.POLITIQUEPASSWORD.LOWERCASE' | translateI18n}}
        </li>

        <li  class="input-filled w-full"  [ngStyle]="{color : f.password.hasError('required') ||
                                      f.password.hasError('hasSmallCase')  ? 'red' : 'green'}">
          <i class="{{f.password.hasError('required') || f.password.hasError('hasSmallCase') ? 'pi pi-times-circle' : 'pi pi-check-circle' }}"></i>
          {{'FORM_CHANGE_PASSWORD.POLITIQUEPASSWORD.UPPERCASE' | translateI18n}}
        </li>

        <li  class="input-filled w-full"  [ngStyle]="{color : f.password.hasError('required') ||
                                      f.password.hasError('hasNumber')  ? 'red' : 'green'}">
          <i class="{{f.password.hasError('required') || f.password.hasError('hasNumber') ? 'pi pi-times-circle' : 'pi pi-check-circle' }}"></i>
          {{'FORM_CHANGE_PASSWORD.POLITIQUEPASSWORD.NUMBER' | translateI18n}}
        </li>

        <li  class="input-filled w-full"  [ngStyle]="{color : f.password.hasError('required') || f.password.hasError('hasSpecialCharacters')  ? 'red' : 'green'}">
          <i class="{{f.password.hasError('required') || f.password.hasError('hasSpecialCharacters') ? 'pi pi-times-circle' : 'pi pi-check-circle' }}"></i>
          {{'FORM_CHANGE_PASSWORD.POLITIQUEPASSWORD.SPECIAL_CHARACTER' | translateI18n}}
        </li>


      </ul>

      <ul class="pl-0 pb-2 different7LastPassword " style="list-style-type:none;" >
        <li class="input-filled">
          <i class="pi pi-info-circle"></i>
          {{'FORM_CHANGE_PASSWORD.POLITIQUEPASSWORD.DIFFERENT_N_LAST_PASSWORD' | translateI18n: nbrOfLastOldPassword}}
        </li>
      </ul>





    </div>

    <button [disabled]="form.invalid" pButton type="submit" label="{{'FORM_CHANGE_PASSWORD.LABEL.BUTTON_SUBMIT' | translateI18n}}" class="w-full uppercase p-button-label" icon="pi pi-check" iconPos="right"></button>

  </form>
</div>
