<div class="login-page">
</div>

<main class="layout-wrapper h-full" id="main-login">

  <div class="flex justify-content-center m-4" id="logo-bnp-login">
    <img src="../../../assets/images/logo-bnp-valuation.svg" width="200px" />
  </div>

  <div class="layout-main h-full no-nav no-header">
    <div class="grid justify-content-center align-content-center w-full">
      <div class="col-10 is-login-card">

        <div class="flex justify-content-center pb-6">
          <img src="../../../assets/images/logo-valea.svg" width="140px" />
        </div>

        <app-change-password [askCurrentPassword]=false></app-change-password>

      </div>
    </div>
  </div>

</main>
