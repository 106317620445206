<nav class="grid nav-steps">

  <div class="col" *ngFor="let step of stepsList" [ngClass]="step.class">

    <div class="progress-line">
      <div class="bullet"></div>
    </div>

    <div class="text" >
      <h3>{{step.label}}</h3>
      <p>
        {{step.value}}
        <i *ngIf= "step?.motifAnnulation" class="pi pi-question-circle"
           pTooltip="{{step.motifAnnulation}}"
           tooltipPosition="top" [hideDelay]="200"></i>
      </p>
    </div>

  </div>

</nav>
