import {ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {AbstractControl, FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {DialogService, DynamicDialogRef} from "primeng/dynamicdialog";
import {ConfirmationService, Message, MessageService} from "primeng/api";
import {RapportExpertiseService} from "../../../../service/rapport-expertise.service";
import {AppSettings, Settings} from "../../../../../app.settings";
import {BienSurface} from "../../../../models/bien/bien-surface";
import {ConfigSizeInput} from "../../../../../shared/providers/config-size-input";
import {RapportExpertiseObservableService} from "../../../../../shared/observable/rapport-expertise-observable.service";
import {TranslateService} from "../../../../../shared/translate/translate.service";

@Component({
  selector: 'app-table-surfaces',
  templateUrl: './table-surfaces.component.html',
  styleUrls: ['./table-surfaces.component.scss']
})
export class TableSurfacesComponent implements OnInit {


  @Input()
  idBien : number

  @Input()
  idDossier : number

  @Input()
  typeSurfaceRapport : any[];

  typeSurface : any;

  messageBieSurface : Message[];
  messageBieSurfaceDialog : Message[];
  bieSurface : BienSurface;
  submitted  : boolean;
  dialog : boolean;

  public settings: Settings;

  bieSurfaces : BienSurface[];
  bienSurface : BienSurface;
  surfaceForm : FormGroup;

  configSizeInput : ConfigSizeInput;


  total : number = 0;

  totalSurfaceConsideree : number = 0;

  constructor(
    private fb : FormBuilder,
    public dialogService: DialogService,
    public ref: DynamicDialogRef,
    private cdref: ChangeDetectorRef,
    private messageService : MessageService,
    private rapportExpertiseService : RapportExpertiseService,
    private confirmation: ConfirmationService,
    public appSettings : AppSettings,
    private rapportExpertiseObservableService : RapportExpertiseObservableService,
    private translate : TranslateService,
  ) {
    this.settings = this.appSettings.settings;
    this.configSizeInput = new ConfigSizeInput();
  }

  ngOnInit(): void {

    this.getAllBienSurfaceByBienId();

    this.rapportExpertiseObservableService.getTypeSurface().subscribe(data => {
      if(data) {
        this.typeSurface = data;
      }
      this.cdref.detectChanges();
    })
  }

  async getAllBienSurfaceByBienId() {
    this.bieSurfaces = await this.rapportExpertiseService.getAllBienSurfaceByBienId(this.idBien);
    this.total = 0;
    this.totalSurfaceConsideree = 0;
    if(this.bieSurfaces) {
      this.bieSurfaces.forEach( bies => {

        if(bies.surface) {
          this.total = this.total + bies.surface;
        }
        if(bies.coefPonderation && bies.surface) {
          //bies.surfaceConsideree = bies.surface * bies.coefPonderation;
          this.totalSurfaceConsideree = this.totalSurfaceConsideree + bies.surfacePondere;
        }
      })
    }
  }

  initForm(bies? : BienSurface) {
    this.surfaceForm = this.fb.group({
      batEtage : new FormControl(bies?.batEtage ? bies?.batEtage : null,
        [Validators.maxLength(18), Validators.pattern(this.configSizeInput.regexPattern)]),
      utilisation : new FormControl(bies?.utilisation ? bies?.utilisation :null,
        [Validators.maxLength(18), Validators.pattern(this.configSizeInput.regexPattern)]),
      surface : new FormControl(bies?.surface ? bies?.surface :null, Validators.required),
      coefPondereation : new FormControl(bies?.coefPonderation ? bies?.coefPonderation :null, Validators.required),
      surfacePondere  : new FormControl(bies?.surfacePondere ? bies?.surfacePondere :null),
    });
  }

  editBieSurface(bies: BienSurface) {
    this.messageBieSurfaceDialog = null;
    this.bienSurface = {...bies};
    this.initForm(this.bienSurface);
    this.dialog = true;
  }

  deleteBieSurface(bies: BienSurface) {
    if(bies) {
      this.confirmation.confirm({
        header: this.translate.getElement("RAPPORT_EXPERTISE.MESSAGE.ETES_VOUS_SUR"),
        message : this.translate.getElement("RAPPORT_EXPERTISE.MESSAGE.CONFIRMER"),
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          this.rapportExpertiseService.deleteBienSurface(bies.id, this.idDossier).then( data => {
            if(data) {
              this.getAllBienSurfaceByBienId();
              this.messageBieSurface =[ {severity:'success', summary:  this.translate.getElement("RAPPORT_EXPERTISE.MESSAGE.SUCESS_SUPPRESSION")}];
              return;
            }
            this.messageBieSurface =[ {severity:'error', summary:  this.translate.getElement("RAPPORT_EXPERTISE.MESSAGE.ERROR_SUPPRESSION")}];
          }).catch( error => {
            this.messageBieSurface =[ {severity:'error', summary:  this.translate.getElement("RAPPORT_EXPERTISE.MESSAGE.ERROR_SUPPRESSION")}];
          })
        }
      })

    }
  }

  openNew() {
    this.messageBieSurfaceDialog = null;
    this.initForm(null);
    this.bieSurface = null;
    this.submitted = false;
    this.dialog = true;
  }

  get formBieSurface(): { [key: string]: AbstractControl } {
    return this.surfaceForm.controls;
  }

  hideDialog() {
    this.dialog = false;
    this.submitted = false;
  }

  saveBienSurface() {
    this.submitted = true;

    if(this.surfaceForm.invalid) {
      this.messageBieSurfaceDialog = [{key :'dialogSurface', severity:'error', summary: this.translate.getElement('RAPPORT_EXPERTISE.MESSAGE.ERROR_FORM')}];
      return;
    }

    const bies : BienSurface = new BienSurface();

    if(this.bienSurface) {
      bies.id = this.bienSurface?.id;
    }

    const value = this.surfaceForm.value;
    bies.batEtage = value.batEtage;
    bies.utilisation = value.utilisation;
    bies.surface = value.surface;
    bies.surfacePondere = value.surfacePondere;
    bies.coefPonderation = value.coefPondereation;
    bies.idBien = this.idBien;
    bies.idDossier = this.idDossier;

    this.rapportExpertiseService.saveBieSurface(bies).subscribe( data => {
      if(data) {
        this.hideDialog()
        this.getAllBienSurfaceByBienId();
      }else {
        this.messageBieSurfaceDialog =[ {severity:'error', summary:  this.translate.getElement("RAPPORT_EXPERTISE.MESSAGE.ERROR_MESSAGE_MODAL")}];
      }
      this.submitted = false;
    }, error => {
      this.messageBieSurfaceDialog =[ {severity:'error', summary:  this.translate.getElement("RAPPORT_EXPERTISE.MESSAGE.ERROR_MESSAGE_MODAL")}];
      this.submitted = false;
    })
  }

  coefPonderationChange(event: any) {
    let surfaceConsidere = this.surfaceForm?.value?.surface * event.value;
    this.surfaceForm.get('surfacePondere').setValue(surfaceConsidere);
  }

  surfaceChange(event: any) {
    let surfaceConsidere = this.surfaceForm?.value?.coefPondereation * event.value;
    this.surfaceForm.get('surfacePondere').setValue(surfaceConsidere);
  }
}
