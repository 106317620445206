<form  [formGroup]="form">


  <app-type-document formGroupName="typeDoc"></app-type-document>

  <app-statuts-dropdown formGroupName="statut" [statuts]="statuts"></app-statuts-dropdown>

  <app-adresse-bien formGroupName="adresseBien"></app-adresse-bien>

  <app-input-client formGroupName="client"></app-input-client>

  <app-periode-calender formGroupName="periode"></app-periode-calender>


</form>

