import {Component, OnInit} from '@angular/core';
import {DomSanitizer, SafeUrl} from "@angular/platform-browser";
import {UserService} from "../service/user/user.service";
import {ProfilesUser} from "../models/user/profiles-user";

@Component({
  selector: 'app-help-navigation',
  templateUrl: './help-navigation.component.html',
  styleUrls: ['./help-navigation.component.scss']
})
export class HelpNavigationComponent implements OnInit {

  aidePdf: SafeUrl;
  sanitizer: DomSanitizer;

  profiles : ProfilesUser[];


  constructor(sanitizer:DomSanitizer, private userService : UserService) {
    this.sanitizer = sanitizer;
  }

  ngOnInit(): void {
   this.getProfilUserConnected();
  }

  async getProfilUserConnected() {
    await this.userService.getUserConnectedProfilesById()
      .then((data) => this.profiles = data);
  }

  pdfURL() {
    if(this.profiles) {
      if(this.profiles.includes(ProfilesUser.ADMINISTRATEUR) || this.profiles.includes(ProfilesUser.ADMINISTRATEUR_TECHNIQUE)) {
        return this.sanitizer.bypassSecurityTrustResourceUrl("../../../assets/help/BNPPRE_Valuation_VALEA_PremiersPas-Admin.pdf");
      }
      else if(this.profiles.includes(ProfilesUser.AGENT)) {
        return this.sanitizer.bypassSecurityTrustResourceUrl("../../../assets/help/BNPPRE_Valuation_VALEA_PremiersPas-Agent.pdf");
      }else  if(this.profiles.includes(ProfilesUser.EXPERT_EXTERNE) || this.profiles.includes(ProfilesUser.EXPERT_INTERNE)) {
        return this.sanitizer.bypassSecurityTrustResourceUrl("../../../assets/help/BNPPRE_Valuation_VALEA_PremiersPas-Expert.pdf");
      }
      else  if(this.profiles.includes(ProfilesUser.ASSISTANTE) || this.profiles.includes(ProfilesUser.ANIMATEUR_RESEAU)) {
        return this.sanitizer.bypassSecurityTrustResourceUrl("../../../assets/help/BNPPRE_Valuation_VALEA_PremiersPas-Gestionnaire.pdf");
      }
    }

    return null;
  }
}
