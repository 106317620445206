<form [formGroup]="form" >

  <div class="card-large" id="section1">
    <h1 id="conclusion">IX - {{'RAPPORT_EXPERTISE.CONCLUSION' | translateI18n}}</h1>

    <div class="grid">
      <div class="col-12">
        <section>
          <div class="field input-inline">
            <label class="label">{{'RAPPORT_EXPERTISE.LABEL.DATE_VALEUR' | translateI18n}} : </label>
            <p-calendar formControlName="dateValeur" [showIcon]="true" inputId="icon"
                        dateFormat="dd/mm/yy" inputId="icon" class="inputfield w-full"  [firstDayOfWeek]="1"></p-calendar>
          </div>
        </section>




      <section   *ngIf="typeValeur">

        <ul>
          <li class="field input-ghost" *ngIf="typeValeur?.idTypeValeur === 1 || typeValeur?.idTypeValeur === 3 || typeValeur?.idTypeValeur === 5">

            <div>
              <label class="label">{{typeValeur?.label1}}</label>
              <span class="field input-inline">
                  <p-inputNumber formControlName="valeurVenal"
                                 inputId="valeurVenal"  mode="currency"
                                 currency="EUR" locale="fr-FR"
                                 placeholder="{{'RAPPORT_EXPERTISE.LABEL.RENSEIGNER' | translateI18n}}"
                                 [size]=20 [min]="0" [maxlength]="18"
                                 [ngClass]="{'ng-dirty ng-invalid': submittedRequiredSynthese && f.valeurVenal?.errors}"
                                 (onInput)="calculPrixM2IfValueChanged($event, 1)"
                                 class="obligatoire">
                  </p-inputNumber>
              </span>
              <span class="field input-inline">
                  <p-dropdown
                    [options]="regimeFiscalVenal"  formControlName="regimeFiscalVenal"
                    optionLabel="libelleCourt" name="regimeFiscalVenal"  id="regimeFiscalVenal"
                    [ngClass]="{'ng-dirty ng-invalid': (submittedRequiredSynthese || submittedRequired) && f.regimeFiscalVenal?.errors}"
                    emptyMessage="{{'DOSSIER.MESSAGE.AUCUN_RESULTAT' | translateI18n}}"
                    placeholder="{{'PLACEHOLDER.CHOOSE' | translateI18n}}" class="obligatoire"
                    (onChange)="changeRegimeFiscalVenal($event)">
                  </p-dropdown>

              </span>

              <span class="field input-ghost w-1 text-center">
                  {{'RAPPORT_EXPERTISE.LABEL.SOIT' | translateI18n}}
                   {{this.form?.value?.prixM2ValeurVenale ? (this.form?.value?.prixM2ValeurVenale |  currency : 'EUR' : 'symbol' : '1.2-2') : '  -- €'}}{{'RAPPORT_EXPERTISE.LABEL.AU_M2' | translateI18n}}
              </span>

              <small *ngIf="submittedRequiredSynthese && (f.valeurVenal?.errors || f.regimeFiscalVenal?.errors)"
                     class="p-error block mr-4">{{'MESSAGE.ERROR.CHAMPS_OBLIGATOIRE' | translateI18n}}
              </small>

            </div>

            <div class="mt-3">

              <label class="label">{{'RAPPORT_EXPERTISE.LABEL.TAUX_FISCALITE' | translateI18n}} (%)</label>
              <span class="field input-inline">
                  <p-dropdown
                    [options]="tauxFiscalite"  formControlName="tauxFiscaliteVenal"
                    optionLabel="libelle" name="tauxFiscaliteVenal"  id="tauxFiscaliteVenal"
                    [ngClass]="{'ng-dirty ng-invalid': submittedRequiredSynthese && f.tauxFiscaliteVenal?.errors}"
                    emptyMessage="{{'DOSSIER.MESSAGE.AUCUN_RESULTAT' | translateI18n}}"
                    placeholder="{{'PLACEHOLDER.CHOOSE' | translateI18n}}" class="obligatoire"
                    (onChange)="changeTauxFiscalite($event)">
                  </p-dropdown>
              </span>

              <span class="field input-inline">
                 <span> {{'RAPPORT_EXPERTISE.LABEL.SOIT' | translateI18n}} {{vvfIncluse ? (vvfIncluse | currency: 'EUR' : 'symbol' : '1.2-2' ): ' -- '}}</span>
              </span>

              <span class="field input-ghost">
                <span *ngIf="regimeFv" class="ml-2 w-1 text-center">{{regimeFv?.libelleInclus}}</span>
              </span>

              <small *ngIf="submittedRequiredSynthese && f.tauxFiscaliteVenal?.errors" class="p-error block mr-4">
                {{'MESSAGE.ERROR.CHAMPS_OBLIGATOIRE' | translateI18n}}
              </small>

            </div>

          </li>

          <li class="field input-ghost"  *ngIf="typeValeur?.idTypeValeur === 2 || typeValeur?.idTypeValeur === 5">

            <label  *ngIf="typeValeur?.idTypeValeur === 2" class="label">{{typeValeur?.label1}} : </label>
            <label  *ngIf="typeValeur?.idTypeValeur === 5" class="label">{{typeValeur?.label2}} : </label>

            <span class="field input-inline">
                  <p-inputNumber formControlName="valeurLocative"
                                 inputId="valeurLocative"  mode="currency"
                                 currency="EUR" locale="fr-FR"
                                 [size]=20 [min]="0" [maxlength]="18"
                                 [ngClass]="{'ng-dirty ng-invalid': submittedRequiredSynthese && f.valeurLocative?.errors}"
                                 placeholder="{{'RAPPORT_EXPERTISE.LABEL.RENSEIGNER' | translateI18n}}"
                                 (onInput)="calculPrixM2IfValueChanged($event, 2)"
                                 class="obligatoire">
                  </p-inputNumber>
            </span>

            <span class="field input-inline">
                  <p-dropdown  [options]="regimeFiscalLocatif"
                               formControlName="regimeFiscalLocatif"
                               optionLabel="libelleCourt" name="regimeFiscalLocatif"
                               id="regimeFiscalLocatif"
                               [ngClass]="{'ng-dirty ng-invalid': submittedRequiredSynthese && f.regimeFiscalLocatif?.errors}"
                               emptyMessage="{{'DOSSIER.MESSAGE.AUCUN_RESULTAT' | translateI18n}}"
                               placeholder="{{'PLACEHOLDER.CHOOSE' | translateI18n}}" class="obligatoire"
                        (onChange)="changeRegimeLocatif($event)">
                  </p-dropdown>
            </span>
            <span class="field input-ghost  w-1 text-center">
              {{'RAPPORT_EXPERTISE.LABEL.SOIT' | translateI18n}} {{this.form?.value?.prixM2ValeurLocative ? (this.form?.value?.prixM2ValeurLocative |  currency : 'EUR' : 'symbol' : '1.2-2') : '  -- €'}}{{'RAPPORT_EXPERTISE.LABEL.AU_M2' | translateI18n}}
            </span>

            <small *ngIf="submittedRequiredSynthese && (f.valeurLocative?.errors || f.regimeFiscalLocatif?.errors)" class="p-error block mr-4">
              {{'MESSAGE.ERROR.CHAMPS_OBLIGATOIRE' | translateI18n}}
            </small>
          </li>

          <li class="field input-ghost" *ngIf="typeValeur?.idTypeValeur === 3">

            <div>
              <label class="label">{{typeValeur?.label2}} : </label>
              <span class="field input-inline">
                    <p-inputNumber formControlName="valeurApresTravaux"
                                   inputId="valeurApresTravaux"
                                   mode="currency" currency="EUR" locale="fr-FR"
                                   [size]=20 [min]="0" [maxlength]="18"
                                   [ngClass]="{'ng-dirty ng-invalid': submittedRequiredSynthese && f.valeurApresTravaux?.errors}"
                                   placeholder="{{'AVIS_VALEUR.LABEL.RENSEIGNER' | translateI18n}}"
                                   (onInput)="calculPrixM2IfValueChanged($event, 3)"
                                   class="obligatoire">
                    </p-inputNumber>
              </span>
              <span class="field input-inline">
                    <p-dropdown  [options]="regimeFiscalVenal"
                                 formControlName="regimefiscalApresTraveaux"
                                 optionLabel="libelleCourt" name="regimefiscalApresTraveaux"
                                 id="regimefiscalApresTraveaux"
                                 [ngClass]="{'ng-dirty ng-invalid': submittedRequiredSynthese && f.regimefiscalApresTraveaux?.errors}"
                                 emptyMessage="{{'DOSSIER.MESSAGE.AUCUN_RESULTAT' | translateI18n}}"
                                 placeholder="{{'PLACEHOLDER.CHOOSE' | translateI18n}}" class="obligatoire"
                                 (onChange)="changeRegimeFiscalApresTravaux($event)" >
                    </p-dropdown>
              </span>
              <span class="field input-ghost ml-2 w-1 text-center">
                {{'RAPPORT_EXPERTISE.LABEL.SOIT' | translateI18n}} {{this.form?.value?.prixM2ValeurApresTravaux ?
                (this.form?.value?.prixM2ValeurApresTravaux |  currency : 'EUR' : 'symbol' : '1.2-2') : '  -- €'}}{{'RAPPORT_EXPERTISE.LABEL.AU_M2' | translateI18n}}
              </span>

              <small *ngIf="submittedRequiredSynthese && (f.valeurApresTravaux?.errors || f.regimefiscalApresTraveaux?.errors)"
                     class="p-error block mr-4">{{'MESSAGE.ERROR.CHAMPS_OBLIGATOIRE' | translateI18n}}
              </small>
            </div>


            <div class="mt-3">

              <label class="label">{{'RAPPORT_EXPERTISE.LABEL.TAUX_FISCALITE' | translateI18n}} (%)</label>
              <span class="field input-inline">
                  <p-dropdown
                    [options]="tauxFiscalite"  formControlName="tauxFiscaliteApresTravaux"
                    optionLabel="libelle" name="tauxFiscaliteApresTravaux"  id="tauxFiscaliteApresTravaux"
                    [ngClass]="{'ng-dirty ng-invalid': submittedRequiredSynthese && f.tauxFiscaliteApresTravaux?.errors}"
                    emptyMessage="{{'DOSSIER.MESSAGE.AUCUN_RESULTAT' | translateI18n}}"
                    placeholder="{{'PLACEHOLDER.CHOOSE' | translateI18n}}" class="obligatoire"
                    (onChange)="changeTauxFiscaliteApresTravaux($event)">
                  </p-dropdown>
              </span>

              <span class="field input-inline">
                 <span>   {{'RAPPORT_EXPERTISE.LABEL.SOIT' | translateI18n}} {{vapIncluse ? (vapIncluse | currency: 'EUR' : 'symbol' : '1.2-2' ): ' -- '}}</span>
              </span>

              <span class="field input-ghost">
                <span *ngIf="regimeFvap" class="ml-2 w-1 text-center">{{regimeFvap?.libelleInclus}}</span>
              </span>

              <small *ngIf="submittedRequiredSynthese && f.tauxFiscaliteApresTravaux?.errors" class="p-error block mr-4">
                {{'MESSAGE.ERROR.CHAMPS_OBLIGATOIRE' | translateI18n}}
              </small>
            </div>

          </li>



          <!-- Valeur etat futur achevement-->
          <li *ngIf="typeValeur?.idTypeValeur === 4">
            <div>
              <label class="label">{{typeValeur?.label1}} : </label>
              <span class="field input-inline">
                  <p-inputNumber formControlName="valeurFuturAchevement"
                                 inputId="valeurFuturAchevement"
                                 mode="currency" currency="EUR" locale="fr-FR"
                                 placeholder="{{'RAPPORT_EXPERTISE.LABEL.RENSEIGNER' | translateI18n}}"
                                 [size]=20 [min]="0" [maxlength]="18"
                                 [ngClass]="{'ng-dirty ng-invalid': submittedRequiredSynthese && f.valeurFuturAchevement.errors}"
                                 (onInput)="calculPrixM2IfValueChanged($event,4)"
                                 class="obligatoire">
                  </p-inputNumber>
            </span>
              <span class="field input-inline">
                  <p-dropdown  [options]="regimeFiscalVenal"
                               formControlName="regimefiscalFuturAchevement"
                               optionLabel="libelleCourt" name="regimefiscalFuturAchevement"
                               id="regimefiscalFuturAchevement"
                               [ngClass]="{'ng-dirty ng-invalid': submittedRequiredSynthese  && f.regimefiscalFuturAchevement.errors}"
                               emptyMessage="{{'DOSSIER.MESSAGE.AUCUN_RESULTAT' | translateI18n}}"
                               placeholder="{{'PLACEHOLDER.CHOOSE' | translateI18n}}" class="obligatoire"
                               (onChange)="changeRegimeFiscalFuturAchevement($event)">
                  </p-dropdown>
            </span>
            <span class="field input-ghost w-1 text-center">
              {{'RAPPORT_EXPERTISE.LABEL.SOIT' | translateI18n}} {{this.form?.value?.prixM2ValeurFuturAchevement ?
              (this.form?.value?.prixM2ValeurFuturAchevement |  currency : 'EUR' : 'symbol' : '1.2-2') : '  -- €'}} {{'RAPPORT_EXPERTISE.LABEL.AU_M2' | translateI18n}}
            </span>

              <small *ngIf="submittedRequiredSynthese  && (f.valeurFuturAchevement?.errors || f.regimefiscalFuturAchevement?.errors)"
                     class="p-error block mr-4">{{'MESSAGE.ERROR.CHAMPS_OBLIGATOIRE' | translateI18n}}
              </small>
            </div>


            <div class="mt-3">

              <label class="label">{{'RAPPORT_EXPERTISE.LABEL.TAUX_FISCALITE' | translateI18n}} (%)</label>
              <span class="field input-inline">
                  <p-dropdown
                    [options]="tauxFiscalite"  formControlName="tauxFiscaliteEtatFuturAchevement"
                    optionLabel="libelle" name="tauxFiscaliteEtatFuturAchevement"  id="tauxFiscaliteEtatFuturAchevement"
                    [ngClass]="{'ng-dirty ng-invalid':submittedRequiredSynthese && f.tauxFiscaliteEtatFuturAchevement?.errors}"
                    emptyMessage="{{'DOSSIER.MESSAGE.AUCUN_RESULTAT' | translateI18n}}"
                    placeholder="{{'PLACEHOLDER.CHOOSE' | translateI18n}}" class="obligatoire"
                    (onChange)="changeTauxFiscaliteFuturAchevement($event)">
                  </p-dropdown>
              </span>

              <span class="field input-inline">
                 <span>   {{'RAPPORT_EXPERTISE.LABEL.SOIT' | translateI18n}} {{vfafIncluse ? (vfafIncluse | currency : 'EUR' : 'symbol' : '1.2-2' ): ' -- '}}</span>
              </span>

              <span class="field input-ghost">
                <span *ngIf="regimeFFa" class="ml-2 w-1 text-center">{{regimeFFa?.libelleInclus}}</span>
              </span>

              <small *ngIf="submittedRequiredSynthese  && f.tauxFiscaliteEtatFuturAchevement?.errors" class="p-error block mr-4">
                {{'MESSAGE.ERROR.CHAMPS_OBLIGATOIRE' | translateI18n}}
              </small>
            </div>
          </li>


        </ul>

      </section>



        <section *ngIf="typeValeur?.idTypeValeur === 1 || typeValeur?.idTypeValeur === 3 || typeValeur?.idTypeValeur === 5">

          <section *ngIf="rapport?.typeTiers === 'TTBANQUE' && rapport?.displayVenteForcee">
            <div class="field input-ghost" >
              <label for="decote" class="label">{{'RAPPORT_EXPERTISE.LABEL.AB_VENTE_FORCEE' | translateI18n}} :</label>
              <p-inputNumber formControlName="decote"
                             id="decote"
                             class="obligatoire"
                             placeholder="{{'RAPPORT_EXPERTISE.LABEL.RENSEIGNER' | translateI18n}}"
                             [ngClass]="{'ng-dirty ng-invalid errorAbatt': (submittedRequiredSynthese && f.decote.errors) ||  abbatValueError}"
                             [useGrouping]="false"
                             [size]="13"
                             suffix="%" [min]="0" [maxlength]="4"
                              (onInput)="abattementChange($event)">
              </p-inputNumber>
              <small *ngIf="abbatValueError" class="p-error block">{{'RAPPORT_EXPERTISE.MESSAGE.ERROR_ABAT' | translateI18n}}</small>
              <small *ngIf="submittedRequiredSynthese && f.decote?.errors?.required" class="p-error block mr-4">
                {{'MESSAGE.ERROR.CHAMPS_OBLIGATOIRE' | translateI18n}}
              </small>
            </div>

            <div class="locked">
              <label  class="label">{{'RAPPORT_EXPERTISE.LABEL.VALEUR_VENTE_FORCEE' | translateI18n}} : </label>
              <b> {{valeurVenteForcee && valeurVenteForcee > 0 ? (valeurVenteForcee | currency: 'EUR' : 'symbol' : '1.2-2' ): ' -- '}}</b>
            </div>

          </section>



          <div class="locked">
            <label  class="label">{{'RAPPORT_EXPERTISE.LABEL.LIQUIDITE_BIEN' | translateI18n}} : </label>
            <b> {{liquidite?.libelle ? liquidite?.libelle  : ' -- '}}</b>
          </div>

          <div class="locked">
            <label class="label">{{'RAPPORT_EXPERTISE.LABEL.DELAI_COMMERCIALISATION' | translateI18n}} : </label>
            <b> {{delaiCommercialisation?.libelle ? delaiCommercialisation?.libelle  : ' -- '}}</b>
          </div>


            <div class="field field-line mt-3" *ngIf="delaiCommercialisation">
              <label  class="label " [ngClass]="{'obligatoire':  delaiPrecisionRequired}">{{'RAPPORT_EXPERTISE.LABEL.PRECISION_DELAI' | translateI18n}} :</label>
              <div class="mb-2">
                <small>{{'RAPPORT_EXPERTISE.MESSAGE.MAX_CARACTERE' | translateI18n : '500'}}
                  <span [ngClass]="{'is-alert':  f.delaiPrecision.hasError('hasError')}">({{nbDelaiPrecision}} / 500)</span>
                </small>
              </div>

              <div *ngIf="f.delaiPrecision.hasError('hasError')">
                <p-messages  [(value)]="msgErrorMaxcaract" key="delaiPrecision"></p-messages>
              </div>

              <p-editor formControlName="delaiPrecision" [style]="{'height':'120px'}" name="delaiPrecision"
                        id="delaiPrecision" class="w-full"
                        [ngClass]="{'editor-error': f.delaiPrecision.hasError('hasError')
                        || (submittedRequired  && (f.delaiPrecision?.errors?.required || f.delaiPrecision.hasError('hasEmptyCaracterError') ) )}"
                        (onTextChange)="numDelaiPrecisionTextChange($event)" #quill>
                <p-header>
                  <app-p-editor-header></app-p-editor-header>
                </p-header>
              </p-editor>

              <small *ngIf="submittedRequired  && (f.delaiPrecision?.errors?.required || f.delaiPrecision.hasError('hasEmptyCaracterError') )"
                     class="p-error block">{{'MESSAGE.ERROR.CHAMPS_OBLIGATOIRE' | translateI18n}}</small>
            </div>
        </section>

        <section>
          <div class="field input-ghost">
            <p>
              {{'RAPPORT_EXPERTISE.FAIT_A' | translateI18n}}
              <span class="field input-inline">
                   <p-dropdown  [options]="etablissements"  formControlName="etablissement" optionLabel="libelle" name="etablissement"
                                id="etablissement" [ngClass]="{'ng-dirty ng-invalid': submittedRequired && f.etablissement.errors}"
                                emptyMessage="{{'AVIS_VALEUR.AUCUN_RESULTAT' | translateI18n}}"
                                placeholder="{{'PLACEHOLDER.CHOOSE' | translateI18n}}" class="obligatoire"></p-dropdown>
             </span>
              {{'RAPPORT_EXPERTISE.LE' | translateI18n}} <span class="field input-inline"> {{currentDate | dateFormatPipe}}</span>
            </p>
            <small *ngIf="submittedRequired && f.etablissement?.errors?.required" class="p-error block mr-4">
              {{'MESSAGE.ERROR.CHAMPS_OBLIGATOIRE' | translateI18n}}
            </small>
          </div>

          <div class="grid mt-5 ">
            <div class="col-6 lg:col-3">
              <div class="field input-ghost">
                <p class="font-semibold mb-0">{{fullNameExpert ? fullNameExpert : ''}}</p>
                <p>{{'RAPPORT_EXPERTISE.LABEL.EXPERT' | translateI18n}}</p>
              </div>
            </div>
          </div>

        </section>



      </div>
    </div>

  </div>

</form>
