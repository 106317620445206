<form #form="ngForm" (ngSubmit)="modifierHonoraires()">

  <p-messages></p-messages>

      <div class="field col-12">
        <label for="honoraires">{{'DOSSIER.POPUP.HONORAIRES.HONORAIRES' | translateI18n}}</label>
        <div class="p-input-icon-right w-full">
          <p-inputNumber class=" w-full"  [(ngModel)]="payload.honoraires" name="honoraires" mode="currency" currency="EUR" locale="fr-FR" (onInput)="calculTotal()" [disabled]="disabled">
          </p-inputNumber>
        </div>
      </div>

      <div class="field col-12">
        <label for="frais">{{'DOSSIER.POPUP.HONORAIRES.FRAIS' | translateI18n}}</label>
        <div class="p-input-icon-right w-full">
          <p-inputNumber class=" w-full" [(ngModel)]="payload.fraisExpert" name="frais" mode="currency" currency="EUR" locale="fr-FR" (onInput)="calculTotal()" [disabled]="disabled">
          </p-inputNumber>
        </div>
      </div>

      <div class="field col-12">
        <label for="total">{{'DOSSIER.POPUP.HONORAIRES.TOTAL' | translateI18n}}</label>
        <span class="p-input-icon-right w-full">{{(payload.honoraires+payload.fraisExpert) | currency:'EUR':'symbol':'1.2-2'}}</span>
      </div>

      <div class="p-dialog-footer">

        <button pButton type="button" label="{{'DOSSIER.POPUP.HONORAIRES.ANNULER' | translateI18n}}"
                class="w-100rem uppercase p-button-secondary p-button-outlined mb-2" icon="pi pi-times"
                iconPos="right" (click)="fermer()"></button>

        <button pButton type="submit" label="{{'DOSSIER.POPUP.HONORAIRES.MODIFIER' | translateI18n}}" class="w-100rem uppercase mb-2" icon="pi pi-check"
          iconPos="right" [disabled]="disabled"></button>


      </div>


  </form>
