import {ReferentielValeur} from "../referentiel-valeur";
import {PourcentageNegociation} from "../param/pourcentage-negociation";

export class Mission {
  id : number;
  numero : string;
  statut : string;
  dateEnvoieClient : Date;
  dateRemiseEnValeurPilote : Date;
  commentaire : string;
  dateValeur : Date;
  typeLivrable : ReferentielValeur;
  typeTravaux : ReferentielValeur[];
  typeBien : string;
  dateDemande : Date;
  contexte : ReferentielValeur;
  dateMissionPrevisionnelle : Date;
  dateEnvoiLivrables : Date;
  isExpertise : boolean;
  paramPourcentageNegociation : PourcentageNegociation;
  contexteLabel : string;
  pilote1 : number;

  constructor(fields?: Partial<Mission>) {
    if (fields) {
      Object.assign(this, fields);
    }
  }
}
