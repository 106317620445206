import {Injectable} from "@angular/core";
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from "@angular/router";
import {AuthService} from "./auth.service";
import {LocalStorageService} from "../local-storage/local-storage.service";

@Injectable()
export class AuthGuard implements CanActivate{

    constructor(
      private router: Router, private authService: AuthService,
      private  tokenStorageService : LocalStorageService
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot ): boolean {

        if(this.authService.isSignIn()) {
          return true;
        }
        this.router.navigate(["auth"],{ queryParams: { retUrl: state.url} });
        return false;

    }
}
