import {Component, Renderer2} from '@angular/core';
import {TranslateService} from "./shared/translate/translate.service";
import {PrimeNGConfig} from "primeng/api";
import {Router} from "@angular/router";
import {AuthService} from "./core/service/auth/auth.service";
import {LocalStorageService} from "./core/service/local-storage/local-storage.service";
import {OAuthService} from "angular-oauth2-oidc";
import { runtimeEnvironment } from 'src/environments/runtime-environment';

@Component({
  selector: 'app-root',
  template: '<router-outlet></router-outlet>'
})
export class AppComponent {

  previousUrl: any;

  constructor(
    public translate: TranslateService, private primengConfig: PrimeNGConfig,
    private auth:AuthService, private tokenStorageService : LocalStorageService,
    private router:Router,private  oauthService : OAuthService,  private renderer: Renderer2
  ) {

    if(runtimeEnvironment.isInterne && tokenStorageService.getApplyButtonWeSSO() === 'WEBSSO_BUTTON') {
      this.tokenStorageService.setApplyButtonWeSSO(null);
      this.auth.configureSSOConnexion();
    }

    this.primengConfig.setTranslation({
      dayNamesMin: this.translate.getElement('PRIMENGCONFIG.CALENDRIER.DAYSNAMESMIN'),
      monthNames: this.translate.getElement('PRIMENGCONFIG.CALENDRIER.MONTHNAMES'),
      monthNamesShort : this.translate.getElement('PRIMENGCONFIG.CALENDRIER.MONTHNAMESSHORT')
    });

  }

  /*backGroundImageLogin() {
    this.previousUrl = null
    const body = document.getElementsByTagName('body')[0];
    this.router.events.subscribe((event) => {

      if (event instanceof NavigationStart) {

        if (this.previousUrl) {
          this.renderer.removeClass(body, 'login-page');
        }
        let currentUrlSlug = event.url.slice(1);

        if (currentUrlSlug.split('?')[0] === "" || currentUrlSlug.split('?')[0] === 'auth'
          || currentUrlSlug === 'auth/mot-de-passe-oublie' || currentUrlSlug === 'auth/reinit-password') {
          this.renderer.addClass(document.body, 'login-page');
        }
        this.previousUrl = currentUrlSlug;
      }
    });
  }*/

  /*@HostListener('window:scroll', ['$event'])
  onWindowScroll(e : any) {
    if (window.pageYOffset > 50) {
      let element = document.getElementById('header-primary');
      element?.classList.add('fixed');
    } else {
      let element = document.getElementById('header-primary');
      element?.classList.remove('fixed');
    }
  }*/


}
