<p-confirmDialog header="{{'DOSSIER.CONFIRMATION_DIALOG.HEADER' | translateI18n}}"
                 acceptLabel="{{'DOSSIER.CONFIRMATION_DIALOG.ACCEPT_BUTTON' | translateI18n}}"
                 rejectButtonStyleClass = 'p-button-secondary p-button-outlined'
                 rejectLabel="{{'DOSSIER.CONFIRMATION_DIALOG.REJET_BUTTON' | translateI18n}}"></p-confirmDialog>
<h2>{{'DOCUMENT.LISTE.HEADER' | translateI18n}}</h2>
<div class="tableaux tableaux-documents mb-3" id="listeDocument" >
  <p-messages [(value)]="message" [escape]="false" key="upload"></p-messages>
  <p-table [value]="listeDocuments" [paginator]="totalRecords > settings.pagination?.rowsDocument" [rows]="settings.pagination?.rowsDocument" dataKey="id" *ngIf="listeDocuments.length > 0"
      currentPageReportTemplate="{{'DOCUMENT.LISTE.NOMBRE_RESULTATS' | translateI18n}}" editMode="row"
      [showCurrentPageReport]="true" [rowsPerPageOptions]="settings.pagination?.rowsPerPageOptionsDocument" paginatorPosition="top">
    <ng-template pTemplate="header">
      <tr>
        <th></th>
        <th pSortableColumn="nom" class="">{{'DOCUMENT.LISTE.ENTETE.NOM' | translateI18n}}
        </th>
        <th pColumn="categorie" class="" *ngIf="allPictures">{{'DOCUMENT.LISTE.ENTETE.CATEGORIE' | translateI18n}}
        </th>
        <th pSortableColumn="auditDateCreation">{{'DOCUMENT.LISTE.ENTETE.DATE' | translateI18n}}
        </th>
        <th pcolumn="suppression"></th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-document let-editing="editing" let-ri="rowIndex">
      <tr [pEditableRow]="document">
        <td class="thumbnail">
          <img *ngIf="!document.lienImage" src="./assets/images/dummy-image.png" alt="">
          <img *ngIf="document.lienImage" [src]="document.lienImage" alt="">
        </td>
        <td class="file">
          <p-cellEditor>
            <ng-template pTemplate="input">
              <input type="text" pInputText [(ngModel)]="document.commentaire" [maxLength]="30" placeholder="{{'PLACEHOLDER.COMMENTAIRE' | translateI18n}}">
            </ng-template>
            <ng-template pTemplate="output">
              <a (click)="downloadDocument(document.guid,document.nom)">{{document.nom}}</a>
              <p>{{document.commentaire}}</p>
            </ng-template>
          </p-cellEditor>
        </td>
        <td class="" *ngIf="allPictures">{{getLibelleCategorie(document)}}</td>
        <td class="width-date">{{document.auditDateCreation | date : 'dd/MM/yyyy HH:mm'}}</td>
        <td class="">
          <button *ngIf="isAuthorized(document.auditCreateur) && !editing && estImage(document.type)" pButton pRipple type="button" pInitEditableRow icon="inc-icon inc-icon-edit is-small"
                  (click)="onRowEditInit(document)" class="p-button-rounded p-button-success mr-2"></button>
          <button *ngIf="editing" pButton pRipple type="button" pSaveEditableRow icon="pi pi-check"
                  (click)="onRowEditSave(document)" class="p-button-rounded p-button-text p-button-succes mr-2"></button>
          <button *ngIf="!estImage(document.type)" [disabled]="true" pButton pRipple  type="button" icon="pi"
                  class="p-button-rounded p-button-text p-button-secondary mr-2"></button>
          <button  *ngIf="isAuthorized(document.auditCreateur)"  pButton pRipple type="button" icon="inc-icon inc-icon-trash is-small"
                   class="p-button-rounded" (click)="confirmerSuppression(document)"></button>
          <button pButton pRipple  type="button" icon="pi pi-info-circle" class="p-button-rounded p-button-text p-button-secondary"
                  pTooltip="{{document.origine}}"></button>
        </td>
      </tr>
    </ng-template>
  </p-table>
</div>
<app-upload-document  *ngIf="statutDossier !=='ANNULE' " [idDossier]="idDossier"
                      [objet]="'DOSSIER'" [idObjet]="idBien" [statut]="statutDossier"
                      [idTiers]="tierID" [expertID]="expertID"
                      [verouiller]="verouillerUpload" [verouillerExpert]="verouillerExpert"
                      [parent]="this" label="DOCUMENT.UPLOAD.BOUTON.LABEL_LONG" [aide]=true>

</app-upload-document>

<ngx-spinner type="ball-scale-multiple"><p-progressSpinner></p-progressSpinner></ngx-spinner>



