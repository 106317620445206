import {Component, Input, OnInit} from '@angular/core';
import {FileUpload} from "primeng/fileupload";
import {UploadRequest} from "../models/document/upload-request";
import {RapportExpertiseService} from "../service/rapport-expertise.service";
import {MessageService} from "primeng/api";
import {DocumentService} from "../service/document.service";
import {DomSanitizer} from "@angular/platform-browser";
import {TranslateService} from "../../shared/translate/translate.service";
import {FileValidation} from "../../shared/providers/file-validation";
import {Document} from "../models/document/document.model";
import {UtilsFonction} from "../../shared/providers/utils-fonction";
import {UploadResponse} from "../models/document/upload-response";
import {NgxSpinnerService} from "ngx-spinner";
import {ListeDocument} from "../upload-document/upload-document.component";

export interface DocumentPhoto {
  addDocument(documents: Document[]): void;
}

@Component({
  selector: 'app-upload-photo',
  templateUrl: './upload-photo.component.html',
  styleUrls: ['./upload-photo.component.scss']
})
export class UploadPhotoComponent implements OnInit {

  @Input()
  imageSrc : any;

  @Input()
  categorie : string;

  @Input()
  legende : string;

  @Input()
  displayLegende : boolean;

  @Input()
  label : string;

  @Input()
  idObjetPrincipal : number;

  @Input()
  idCategorie : number;

  @Input()
  multiple : boolean = false;

  @Input()
  document : Document;

  @Input()
  addNewPhoto : boolean;

  @Input() parent: DocumentPhoto;

  @Input()
  keyMessage : string = 'uploadPhoto'

  @Input()
  choseLabel : string;

  @Input()
  chooseIcon : string = "pi-file-edit";

  @Input()
  idDossier : number;

  @Input()
  styleClassModifyPicture : string = 'p-button p-button-sm p-button-rounded p-button-success mr-0';

  constructor(
    private rapportExpertiseService : RapportExpertiseService,
    private messageService: MessageService,
    private documentService : DocumentService,
    private sanitizer: DomSanitizer,
    private translate : TranslateService,
    private spinnerService: NgxSpinnerService

  ) { }

  ngOnInit(): void {
  }

  onUpload(event: any, fileUpload: FileUpload) {

    this.spinnerService.show();

    let upload: File[] = [];
    let filesTooBig: String[] = [];
    let fileExtensionsNotAllowed : string [] = [];

    for (let file of event.files) {
      if(!FileValidation.isExtensionImage(file.name)) {
        fileExtensionsNotAllowed.push(file.name);
      }
      else if (file.size >= UtilsFonction.uploadMaxSizeOctet()) {
        filesTooBig.push(file.name);
      } else {
        upload.push(file);
      }
    }
    fileUpload.clear(); // Permet de choisir et uploader un nouveau fichier
    this.messageService.clear();

    if(fileExtensionsNotAllowed.length > 0) {
      this.messageService.add({key: this.keyMessage, severity: 'warn', summary: this.translate.getElement("DOCUMENT.UPLOAD.MESSAGE.WARNING.EXTENSIONS_NON_AUTORISEES",
          fileExtensionsNotAllowed.join("<br/>"))});
    }

    if (filesTooBig.length > 0) {
      this.messageService.add({key: this.keyMessage, severity: 'warn', summary: this.translate.getElement("DOCUMENT.UPLOAD.MESSAGE.WARNING.TAILLE_MAX_DEPASSEE", filesTooBig.join("<br/>"),
          UtilsFonction.uploadMaxSize.toString())});
    }

    if (upload.length > 0) {
      const request: UploadRequest = new UploadRequest();
      request.objetPrincipal = 'DOSSIER';
      request.idObjetPrincipal = this.idObjetPrincipal;
      request.idCategorie = this.idCategorie;
      request.idDossier = this.idDossier;
      this.documentService.uploadPhoto(upload, request).subscribe( data => {
        if(!data) {
          this.messageService.add({key: this.keyMessage, severity: 'error', summary: this.translate.getElement("DOCUMENT.UPLOAD.MESSAGE.ERROR.GENERIQUE")});
          this.spinnerService.hide();
          return;
        }
        let res: UploadResponse = data;
        let noms: string = "";
        for (let i = 0; i < res.documentsUploades.length; i += 1) {
          noms = noms.concat(res.documentsUploades[i].nom + "<br/>");
        }
        if (noms.length != 0) {
          this.messageService.add({key: this.keyMessage, severity: 'success', summary: this.translate.getElement("DOCUMENT.UPLOAD.MESSAGE.SUCCESS", noms)});
          noms = "";
        }

        for (let i = 0; i < res.documentsTropGros.length; i += 1) {
          noms = noms.concat(res.documentsTropGros[i] + "<br/>");
        }
        if (noms.length != 0) {
          this.messageService.add({key: this.keyMessage, severity: 'warn', summary: this.translate.getElement("DOCUMENT.UPLOAD.MESSAGE.WARNING.TAILLE_MAX_DEPASSEE", noms,
              UtilsFonction.uploadMaxSize.toString())});
          noms = "";
        }

        for (let i = 0; i < res.documentsHomonymes.length; i += 1) {
          noms = noms.concat(res.documentsHomonymes[i] + "<br/>");
        }
        if (noms.length != 0) {
          this.messageService.add({key: this.keyMessage, severity: 'warn', summary: this.translate.getElement("DOCUMENT.UPLOAD.MESSAGE.WARNING.DOCUMENT_HOMONYME", noms)});
          noms = "";
        }

        for (let i = 0; i < res.documentsNomInterdit.length; i += 1) {
          noms = noms.concat(res.documentsNomInterdit[i] + "<br/>");
        }
        if (noms.length != 0) {
          this.messageService.add({key: this.keyMessage, severity: 'warn', summary: this.translate.getElement("DOCUMENT.UPLOAD.MESSAGE.WARNING.DOCUMENT_NOM_INTERDIT", noms)});
          noms = "";
        }

        for (let i = 0; i < res.documentsNonAcceptes.length; i += 1) {
          noms = noms.concat(res.documentsNonAcceptes[i] + "<br/>");
        }
        if (noms.length != 0) {
          this.messageService.add({key: this.keyMessage, severity: 'warn', summary: this.translate.getElement("DOCUMENT.UPLOAD.MESSAGE.WARNING.EXTENSIONS_NON_AUTORISEES", noms)});
          noms = "";
        }

        for (let i = 0; i < res.documentsErreur.length; i += 1) {
          noms = noms.concat(res.documentsErreur[i] + "<br/>");
        }
        if (noms.length != 0) {
          this.messageService.add({key: this.keyMessage, severity: 'error', summary: this.translate.getElement("DOCUMENT.UPLOAD.MESSAGE.ERROR.INCONNUE", noms)});
        }

        this.parent.addDocument(res.documentsUploades);
        this.spinnerService.hide();
      }, error => {
        if (error.status == 406) {
          this.messageService.add({key: this.keyMessage, severity: 'error', summary: this.translate.getElement("DOCUMENT.UPLOAD.MESSAGE.ERROR.UTILISATEUR_NON_AUTORISEE")});
        } else {
          this.messageService.add({key: this.keyMessage, severity: 'error', summary: this.translate.getElement("DOCUMENT.UPLOAD.MESSAGE.ERROR.GENERIQUE")});
        }
        this.spinnerService.hide();
      })
    }

  }

}
