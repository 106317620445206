import {ReferentielValeur} from "../referentiel-valeur";
import {StatutDossier} from "../enum/statut-dossier";
import {Adresse} from "../adresse";

export class AvisValeur {
  idDossier : number;
  etatEnvoi : string;
  valeurLocative : number;
  valeurApresTravaux : number;
  valeurFuturAchevement : number;
  valeurGlobaleEstimee : number;

  idBien : number;
  typeBiens : ReferentielValeur;

  //dossierAvisSansVisite
  piecesTransmises : string;
  paramOccupation : any;
  paramTypeRegimej : any;
  situationGeo : string;
  commentTypeRegimej : string;
  terrain : string;
  construction : string;
  distribution : string;
  amenagement : string;
  observation : string;
  favorable : string;
  defavorable : string;
  etatGeneral : string;
  paramSurface : any;
  occupation : string;
  descmethode : string;
  methodeValorisation : any;
  paramVille : any;



  //client : Client;
  libelleCiviliteClient : string;
  nomClient : string;
  prenomClient : string;
  nomMoralClient : string;
  nomRepresentant : string;
  isClientPhysique : string;
  abreviationCiviliteClient : string;
  nomCompletClient : string;
  numeroContrat : string;

  //demande : Demande;
  paramPreteur : any;
  nomCompletDemandeur : string;
  civiliteDemandeur : any;
  preteur : string;

  //info Expertise
  expertAbreviationCivilite : string;
  nomCompletExpert : string;
  emailExpert : string;
  telephoneExpert : string;
  portableExpert : string;
  typeLivrable : ReferentielValeur;
  expert : number;


  //tiers
  commentaire : string;
  mailTiers : string;
  nomTiers : string;
  prenomTiers : string;
  codeCiviliteTiers : string;
  raisonSocialeTiers : string;
  adresseTiers : Adresse;
  isPhysiqueTiers : boolean;
  entiteClient : string;
  abreviationCiviliteTiers : string;
  nomCompletTiers : string;
  typeTiers : string;

 // bienCaracteris
  sh : number;
  supTerrain : number;
  sdhop : number;
  sup : number;
  loyer : number;
  decote : number;
  su : number;
  dateConstruction : string;
  niveau : string;
  parking : string;
  dureebail : string;
  priseeffetbail : string;
  regimechargeloyer : number;
  periodiciteloyer : number;
  loyerm2 : number;
  numlot : string;
  sectioncadastre : string;
  numcadastre : string;
  designationcadastre : string;
  paramTypeValeur : any;
  paramRegimeFiscalLocatif : any;
  paramRegimeFiscalVenal : any;
  paramRegimeFiscalApresTravaux : any;
  paramRegimeFiscalEtatFuturAchevement : any;
  spc : number;


  statutDossier : StatutDossier;
  numeroDossier : string;
  numFacture : string;
  missionContexteLabel : string;
  bienAdresse : Adresse;
  //mission : Mission;
  dateDemande : Date;
  libelleEntiteJuridiqueNegLie : string;
  codeEntiteJuridiqueNegLie : string;
  telAgent : string;
  portableAgent : string;
  emailAgent : string;
  paramLiquidite : any;
  delaiCommercialisation : any;


  prixM2ValeurVenal : number;
  prixM2ValeurLocative : number;
  prixM2ValeurApresTravaux : number;
  prixM2ValeurFuturAchevement : number;

  surface : number;

  nomCompletSignataire : string;

  dateContratConvention : Date;
  displayDateConvention : Boolean;

  constructor(fields?: Partial<AvisValeur>) {
    if (fields) {
      Object.assign(this, fields);
    }
  }
}
