<main class="layout-wrapper">
  <app-navigation></app-navigation>
  <div *ngIf="rapport">
    <app-header
      titre="{{rapport?.numeroDossier}} / {{rapport?.nomCompletClient}}"
      sstitre="{{rapport?.statutDossier | statutDossier}} {{rapport?.numFacture && rapport?.statutDossier === factureeStatut ? ' / ' + rapport?.numFacture : ''}}"
      [state]="rapport?.statutDossier" [signataire]="rapport?.nomCompletSignataire" [etatEnvoi]="rapport?.etatEnvoi"
      sstitre2="{{rapport?.typeLivrable | typeLivrable}}">
    </app-header>
    <app-navigation-contextuelle  [AnchorItem]="anchorItem" [ActionsItem]="actionsItem"></app-navigation-contextuelle>
    <div id="main-layout" class="layout-main have-secondary-nav">
      <div class="page is-centered">

        <form class="page-form" [formGroup]="form">

          <p-messages *ngIf="messages" [(value)]="messages" [enableService]="false" [escape]="false"></p-messages>

          <!--Après génération du rapport, message de succès-->
          <div *ngIf="messageSuccessGenerationRapport && rapport.typeLivrable === 'TVRAPPORTXX'">
            <p-messages   severity="success" [(value)]="messageSuccessGenerationRapport" [enableService]="false" [escape]="false" [closable]="true">
              <ng-template pTemplate>

                  <span class="p-message-icon pi pi-check"></span>
                  <span class="p-message-summary">{{messageSuccessGenerationRapport[0]?.summary}}
                    <a *ngIf="generateRapport" style="cursor: pointer; text-decoration: underline" (click)="downloadRappotDocument()">{{"RAPPORT_EXPERTISE.MESSAGE.TELECHARGER" | translateI18n}}</a>
                  </span>

                  <button type="button" pRipple class=" flex p-element p-message-close p-link" (click)="closableRapportSucessMessage()">
                    <i class="p-message-close-icon pi pi-times"></i><span class="p-ink"></span>
                  </button>

              </ng-template>
            </p-messages>
          </div>


          <div class="card-large" id="section0">

            <header class="mb-7">
              <!--logo-ate.jpg-->
              <div class="entete" *ngIf="rapport?.codeEntiteJuridiqueNegLie === 'AT'">
                <div class="pb-4"><img src="../../assets/images/logo-ate.jpg" style="width: 150px;"></div>
              </div>

              <!--log BNP-->
              <div class="entete" *ngIf="rapport?.codeEntiteJuridiqueNegLie === 'BNPPRE'">
                <img src="../../assets/images/logo-BNPRE.svg">L'immobilier<br>d'un monde<br>qui change
                <div class="label"> <img src="../../assets/images/label-expertise-fr.svg" alt=""> </div>
              </div>
            </header>

            <p-messages [(value)]="messagePhotoGarde" [escape]="false" key="uploadPhotoGarde"></p-messages>
            <!-- ligne -->
            <div class="grid">
              <!-- Colonne -->
              <div class="col-12 md:col-6 flex flex-column" id="generalite">
                <header >
                  <div class="titre mb-0">{{'RAPPORT_EXPERTISE.TITLE' | translateI18n}}</div>
                  <div class="id-dossier mt-3">{{'RAPPORT_EXPERTISE.NUMERO_DOSSIER' | translateI18n}}{{rapport?.numeroDossier}}</div>
                </header>

                <section class="mt-3">
                  <div class="field input-ghost">
                    <p class="locked">
                      <span class="label">{{'RAPPORT_EXPERTISE.LABEL.TYPE_VALEUR' | translateI18n}} : </span>
                      <b>{{typeValeur?.libelle ? typeValeur?.libelle : '--'}}</b>
                    </p>
                  </div>
                  <div class="field input-ghost">
                   <b class="locked">{{'RAPPORT_EXPERTISE.LABEL.A_LA_DATE' | translateI18n}} {{rapport?.dateVisite ? (rapport?.dateVisite | dateFormatPipe) : ' -- '}}</b>
                  </div>
                </section>

                <section class="mt-auto">
                  <div class="field input-ghost locked">
                    <span class="label">{{ 'RAPPORT_EXPERTISE.LABEL.POUR_COMPTE_DE' | translateI18n}} : </span> <b>{{rapport?.nomTierNeg ? rapport?.nomTierNeg : ' --'}}</b>
                  </div>
                  <div class="field input-ghost locked" *ngIf="rapport?.agenceNomTiers">
                    <span class="label locked">{{ 'RAPPORT_EXPERTISE.LABEL.AGENCE' | translateI18n}} : </span> <b>{{rapport?.agenceNomTiers ? rapport?.agenceNomTiers : ' --'}}</b>
                  </div>
                  <div class="field input-ghost locked">
                    <span class="label">{{ 'RAPPORT_EXPERTISE.LABEL.AFFAIRE' | translateI18n}} : </span>
                    <b>{{rapport.clientphysique && rapport.abreviationCiviliteClient ? rapport.abreviationCiviliteClient : ''}}
                      {{rapport?.nomCompletClient ? rapport?.nomCompletClient : ' --'}}</b>
                  </div>
                  <div class="field input-ghost locked" *ngIf="!rapport.clientphysique">
                    <span class="label">{{ 'RAPPORT_EXPERTISE.LABEL.REPRESENTANT_LEGALE' | translateI18n}} : </span>
                    <b>{{rapport?.nomRepresentant ? rapport?.nomRepresentant : ' --'}}</b>
                  </div>
                  <div class="field input-ghost locked">
                    <span class="label">{{ 'RAPPORT_EXPERTISE.LABEL.NUMERO_CONTRAT' | translateI18n}} : </span><b>{{rapport?.numeroContrat ? rapport?.numeroContrat : ' --'}}</b>
                  </div>
                </section>
              </div>

              <div class="col-12 md:col-6">

                <p-progressSpinner *ngIf="progressSpinner"  [style]="{width: '100px', height: '100px'}"
                                   styleClass="custom-spinner justify-content-center flex mt-6" strokeWidth="3" fill="var(--surface-ground)"
                                   animationDuration=".5s"></p-progressSpinner>

                <figure class="w-full ratio-image" *ngIf="!progressSpinner && !documentGarde?.lienImage"
                        [ngClass]="{'photoGarde': submittedRequiredSynthese && documentGarde.lienImage == null}">
                  <div class="upload-cta">

                    <app-upload-photo [displayLegende]="false"
                                      [categorie]="'Photo de garde'"
                                      [idObjetPrincipal]="rapport?.idBien" [idCategorie]="1"
                                      [addNewPhoto]="true" [parent]="this"
                                      [idDossier]="rapport.idDossier"
                                      [keyMessage]="'uploadPhotoGarde'"
                                      [multiple]="false">
                    </app-upload-photo>
                    <small *ngIf="submittedRequiredSynthese" class="p-error block">{{'MESSAGE.ERROR.CHAMPS_OBLIGATOIRE' | translateI18n}}</small>
                  </div>
                </figure>

                <figure *ngIf="!progressSpinner && documentGarde?.lienImage" class="w-full ratio-image">
                  <div class="img-container">
                    <img [src]="documentGarde.lienImage" alt=""  class="w-full">
                  </div>
                  <figcaption class="justify-content-end flex">
                    <app-upload-photo [displayLegende]="false"
                                      [document]="documentGarde" [addNewPhoto]="false"
                                      [parent]="this"
                                      [idDossier]="rapport?.idDossier"
                                      [keyMessage]="'uploadPhotoGarde'"
                                      [idObjetPrincipal]="rapport?.idBien"
                                      choseLabel="Changer de photo"
                                      chooseIcon="pi pi-plus"
                                      [styleClassModifyPicture]="'p-button-sm p-button-secondary mr-0'"
                                      [idCategorie]="1" [multiple]="false">
                    </app-upload-photo>
                  </figcaption>
                </figure>

              </div>

            </div>

            <app-synthese-mission formGroupName="synthese" [rapport]="rapport" [submitted]="submitted" [submittedRequiredSynthese]="submittedRequiredSynthese"></app-synthese-mission>

          </div>

         <app-mission-rapport formGroupName="mission" [rapport]="rapport" [submitted]="submitted" [submittedRequired]="submittedRequired" [submittedRequiredSynthese]="submittedRequiredSynthese"></app-mission-rapport>

         <app-designation-cadastre formGroupName="designation" [rapport]="rapport" [submitted]="submitted" [submittedRequired]="submittedRequired"></app-designation-cadastre>

         <app-situation-geographique-rapport formGroupName="situationGeo" [rapport]="rapport" [submitted]="submitted" [submittedRequired]="submittedRequired"></app-situation-geographique-rapport>

          <app-description-rapport formGroupName="description" [rapport]="rapport" [submitted]="submitted" [submittedRequired]="submittedRequired" [submittedRequiredSynthese]="submittedRequiredSynthese"></app-description-rapport>

          <app-environnement-urbanisme formGroupName="envUrbanisme" [rapport]="rapport" [submitted]="submitted" [submittedRequired]="submittedRequired"></app-environnement-urbanisme>

          <app-conditon-occupation formGroupName="cdtOccupation" [rapport]="rapport" [submitted]="submitted" [submittedRequired]="submittedRequired"></app-conditon-occupation>

          <app-etude-marche  formGroupName="etudeMarche" [rapport]="rapport" [submitted]="submitted" [submittedRequired]="submittedRequired"></app-etude-marche>

          <app-estrimation-rapport  formGroupName="estimation" [rapport]="rapport" [submitted]="submitted" [submittedRequired]="submittedRequired"></app-estrimation-rapport>

          <app-conclusion-rapport  formGroupName="conclusion" [rapport]="rapport" [submitted]="submitted" [submittedRequired]="submittedRequired" [submittedRequiredSynthese]="submittedRequiredSynthese"></app-conclusion-rapport>
        </form>

          <div class="card-large" id="section2">
            <h1 id="annexe">{{'RAPPORT_EXPERTISE.ANNEXES' | translateI18n}}</h1>
            <app-liste-document id="document" [idBien]="rapport?.idBien" [idDossier]="rapport.idDossier" [verouillerExpert]="false"
            [statutDossier]="rapport.statutDossier" [allPictures]="false" [expertID]="rapport?.idExpert"></app-liste-document>
          </div>


      </div>
    </div>
  </div>
</main>

<ngx-spinner  type="ball-scale-multiple">
  <p-progressSpinner></p-progressSpinner>
</ngx-spinner>
