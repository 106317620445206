import {Component, Input, OnInit} from '@angular/core';
import {BienService} from "../../../service/bien.service";
import {User} from "../../../models/user/user.model";
import {AbstractControl, FormGroup, FormGroupDirective} from "@angular/forms";
import {AdresseComponent} from "../../../../shared/adresse/adresse.component";
import {DialogService, DynamicDialogRef} from "primeng/dynamicdialog";
import {ReferentielValeur} from "../../../models/referentiel-valeur";
import {ParamTypeBien} from "../../../models/bien/param-type-bien";
import {CreationDemandeObservableService} from "../../../../shared/observable/creation-demande-observable.service";
import {Subscription} from "rxjs";
import {Adresse} from "../../../models/adresse";

@Component({
  selector: 'app-bien',
  templateUrl: './bien.component.html',
  styleUrls: ['./bien.component.scss']
})
export class BienComponent implements OnInit {

  @Input()
  formGroupName: string;

  @Input()
  user : User;

  form: FormGroup;

  @Input()
  submitted : boolean;

  @Input()
  titre : string;


   typeBiens :  ParamTypeBien[];

  etages : ReferentielValeur[] = [];

  subscription : Subscription;

  constructor(
    private bienService : BienService,
    private rootFormGroup: FormGroupDirective,
    public dialogService: DialogService,
    public ref: DynamicDialogRef,
    private creationDemandeObservable : CreationDemandeObservableService
  ) { }

  ngOnInit(): void {

    this.getAllEtageBienImmeubleByAdressPrincipal();
    this.getAllParamTypeBien();

    this.form = this.rootFormGroup.control.get(this.formGroupName) as FormGroup;


      this.creationDemandeObservable.onClientAdressFormat().subscribe(adress => {
        if(this.form.value.customerAdress === 'idClient' && adress) {
         this.setAdressForm(adress)
        }
      })


  }

  get f(): { [key: string]: AbstractControl } {
    return this.form.controls;
  }

  getAllParamTypeBien() {
    this.bienService.getAllParamTypeBien().subscribe(data => {
      this.typeBiens = data.sort((a, b) => ( a.paramReferentielValeurByCode.libelle > b.paramReferentielValeurByCode.libelle) ? 1 : -1);
    })
  }

  getAllEtageBienImmeubleByAdressPrincipal() {
    this.bienService.getAllEtageBienImmeubleByAdressPrincipal().subscribe(data => {
      const def : ReferentielValeur = new ReferentielValeur();
      def.libelle = 'Choisir'
      this.etages.push(def);
      if(data) {
        data.forEach(dt => this.etages.push(dt));
      }
    })
  }

  addAdress() {

    this.ref = this.dialogService.open(AdresseComponent, {
      header: "Adresse",
      width: '50vw',
      baseZIndex: 10000,
      data : {adresse : this.form.value.adresse},
      dismissableMask: true,
      styleClass : 'p-dynamic-dialog'
    });


    this.ref.onClose.subscribe((adress : any) =>{
      if (adress) {

        const adresseFormate = adress.numero + ' ' + adress.nomVoie + ', ' +
          adress.codePostal + ' ' + adress.ville + ', ' + adress.pays?.libelle;

        this.form.get('adresse.adresseFormate').setValue(adress.adresseFormate);
        this.form.get('adresse.numero').setValue(adress.numero);
        this.form.get('adresse.lieuDit').setValue(adress.lieuDit);
        this.form.get('adresse.nomVoie').setValue(adress.nomVoie);
        this.form.get('adresse.codePostal').setValue(adress.codePostal);
        this.form.get('adresse.ville').setValue(adress.ville);
        this.form.get('adresse.pays').setValue(adress.pays);
        this.form.get('adresse.boitePostale').setValue(adress.boitePostale);
        this.form.get('adresse.complement').setValue(adress.complement);
        this.form.get('adresse.lng').setValue(adress.lng);
        this.form.get('adresse.lat').setValue(adress.lat);
        this.form.get('adresse.adresseChange').setValue(adress.adresseChange);
      }
    });
  }

  setAdressForm(adress : Adresse) {
    this.form.get('adresse.adresseFormate').setValue(adress.adresseFormate);
    this.form.get('adresse.numero').setValue(adress.numero);
    this.form.get('adresse.lieuDit').setValue(adress.lieuDit);
    this.form.get('adresse.nomVoie').setValue(adress.nomVoie);
    this.form.get('adresse.codePostal').setValue(adress.codePostal);
    this.form.get('adresse.ville').setValue(adress.ville);
    this.form.get('adresse.pays').setValue(adress.pays);
    this.form.get('adresse.boitePostale').setValue(adress.boitePostale);
    this.form.get('adresse.complement').setValue(adress.complement);
    this.form.get('adresse.lng').setValue(adress.lng);
    this.form.get('adresse.lat').setValue(adress.lat);
    this.form.get('adresse.adresseChange').setValue(adress.adresseChange);
  }

  choiceCustomerAdress() {
    if(this.form.value.customerAdress === 'idClient') {
      this.creationDemandeObservable.onClientAdressFormat().subscribe(adress => {
        if(adress) {
          this.setAdressForm(adress);
        }
      });
    }else if(this.form.value.customerAdress === 'autre') {
     // this.form.get('adresse').setValue(null);
      this.form.get('adresse.adresseFormate').setValue(null);
      this.form.get('adresse.numero').setValue(null);
      this.form.get('adresse.lieuDit').setValue(null);
      this.form.get('adresse.nomVoie').setValue(null);
      this.form.get('adresse.codePostal').setValue(null);
      this.form.get('adresse.ville').setValue(null);
      this.form.get('adresse.pays').setValue(null);
      this.form.get('adresse.boitePostale').setValue(null);
      this.form.get('adresse.complement').setValue(null);
      this.form.get('adresse.lng').setValue(null);
      this.form.get('adresse.lat').setValue(null);
      this.form.get('adresse.adresseChange').setValue(null);
    }

  }
}
