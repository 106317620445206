import {ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {User} from "../../../models/user/user.model";
import {AbstractControl, FormBuilder, FormGroup, FormGroupDirective} from "@angular/forms";
import {DialogService, DynamicDialogRef} from "primeng/dynamicdialog";
import {AdresseComponent} from "../../../../shared/adresse/adresse.component";
import {Civilite} from "../../../models/param/civilite";
import {DossierService} from "../../../service/dossier.service";
import {ParamQualiteContact} from "../../../models/param/param-qualite-contact";
import {ConfigSizeInput} from "../../../../shared/providers/config-size-input";

interface Gender {
  name: string;
  value: string
}

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {

  @Input()
  formGroupName: string;

  @Input()
  user : User;

  form: FormGroup;

  preteur : any[];

  qualite : ParamQualiteContact[]

  @Input()
  submitted : boolean;

  @Input()
  civilites: Civilite[];

  selectedGender: Gender;

  selectedPreneur : any;

  @Input()
  titre : string;

  configSizeInput : ConfigSizeInput;

  constructor(
    private fb : FormBuilder,
    public dialogService: DialogService,
    public ref: DynamicDialogRef,
    private rootFormGroup: FormGroupDirective,
    private cdref: ChangeDetectorRef,
    private dossierService : DossierService
  ) {
    this.configSizeInput = new ConfigSizeInput();
  }

  ngOnInit(): void {

    this.getListQualiteContact();

    this.form = this.rootFormGroup.control.get(this.formGroupName) as FormGroup;
  }

  get f(): { [key: string]: AbstractControl } {
    return this.form.controls;
  }

  getListQualiteContact() {
    this.dossierService.getQualiteList().subscribe(data => {
      this.qualite = data.filter(q => q.libelle !== 'Client');
    })
  }

  addAdress() {

    this.ref = this.dialogService.open(AdresseComponent, {
      header: "Adresse",
      width: '50vw',
      baseZIndex: 10000,
      data : {contry : 'allContry', adresse : this.form.value.adresse},
      dismissableMask: true,
      styleClass : 'p-dynamic-dialog'
    });

    this.ref.onClose.subscribe((adress : any) =>{
      if (adress) {
        const adresseFormate = adress.numero + ' ' + adress.nomVoie + ', ' +
          adress.codePostal + ' ' + adress.ville + ', ' + adress.pays?.libelle;

        this.form.get('adresse.adresseFormate').setValue(adress.adresseFormate);
        this.form.get('adresse.numero').setValue(adress.numero);
        this.form.get('adresse.lieuDit').setValue(adress.lieuDit);
        this.form.get('adresse.nomVoie').setValue(adress.nomVoie);
        this.form.get('adresse.codePostal').setValue(adress.codePostal);
        this.form.get('adresse.ville').setValue(adress.ville);
        this.form.get('adresse.pays').setValue(adress.pays);
        this.form.get('adresse.boitePostale').setValue(adress.boitePostale);
        this.form.get('adresse.complement').setValue(adress.complement);
        this.form.get('adresse.lng').setValue(adress.lng);
        this.form.get('adresse.lat').setValue(adress.lat);
        this.form.get('adresse.adresseChange').setValue(adress.adresseChange);
      }
    });
  }

  ngAfterContentChecked() {
    this.cdref.detectChanges();
  }

  choiceModeContact() {

    if(this.form.value.contactType !== null) {
      const type = this.form.value.contactType;
      this.form.reset();
      this.form.get('contactType').setValue(type);
      if(this.form.value.contactType === 'autre') {
        this.form.get('customerContactType').setValue('human');
      }
    }
  }

  /**
   * MAJ choix type de contact (physique ou entreprise)
   */
  choiceContactType() {

      if(this.form.value?.contactType != null && this.form.value.customerContactType !== null && this.form.value?.contactType === 'autre' ) {
        const contactType = this.form.value.contactType;
        const customerContactType = this.form.value.customerContactType;
        this.form.reset();
        this.form.get('contactType').setValue(contactType);
        this.form.get('customerContactType').setValue(customerContactType);
      }

  }

}
