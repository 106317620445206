import {ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {DossierService} from "../../core/service/dossier.service";
import {StatutDossier} from "../../core/models/enum/statut-dossier";
import {UserService} from "../../core/service/user/user.service";
import {TranslateService} from "../../shared/translate/translate.service";
import {AbstractControl, FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {ActivatedRoute, Router, RoutesRecognized} from "@angular/router";
import {DatePipe} from "@angular/common";
import {NgxSpinnerService} from "ngx-spinner";
import {ConfirmationService, MenuItem, Message, MessageService} from "primeng/api";
import {MessageStorageService} from "../../core/service/local-storage/message-storage.service";
import {RapportExpertiseService} from "../../core/service/rapport-expertise.service";
import {RapportExpertise} from "../../core/models/rapport-expertise/rapport-expertise";
import {Validation} from "../../shared/providers/validation";
import {UtilsObservableService} from "../../shared/observable/utils-observable.service";
import {DocumentService} from "../../core/service/document.service";
import {DomSanitizer} from "@angular/platform-browser";
import {Document} from "../../core/models/document/document.model";
import {UtilsFonction} from "../../shared/providers/utils-fonction";
import {RapportExpertiseObservableService} from "../../shared/observable/rapport-expertise-observable.service";
import {ConfigSizeInput} from "../../shared/providers/config-size-input";
import {filter, firstValueFrom, Observable, pairwise, Subscription, take} from "rxjs";
import {ProfilesUser} from "../../core/models/user/profiles-user";
import {ConfigConstants} from 'src/app/core/models/enum/config-constants';
import {HttpResponse} from "@angular/common/http";
import {saveAs} from "file-saver";

@Component({
  selector: 'app-rapport-expertise',
  templateUrl: './rapport-expertise.component.html',
  styleUrls: ['./rapport-expertise.component.scss']
})
export class RapportExpertiseComponent implements OnInit, OnDestroy {

  rapport : RapportExpertise;
  stock : any;
  anchorItem: MenuItem[] = [];
  actionsItem: MenuItem[] = [];
  factureeStatut = StatutDossier.FACTUREE;

  form : FormGroup;

  currentDateTime : Date;

  changeDetectedMission : boolean;
  changeDetectedSynthese : boolean;
  changeDetectedDesignation : boolean;
  changeDetectedSituationGeo : boolean;
  changeDetectedDescription : boolean;
  changeDetectedEnvUrbanisme : boolean;
  changeDetectedCdtOccupation : boolean;
  changeDetectedEtudeMarche : boolean;
  changeDetectedEstimation : boolean;
  changeDetectedConclusion : boolean;

  messages: Message[];

  messageSuccessGenerationRapport : Message[];

  messagePhotoGarde: Message[];

  progressSpinner : boolean = true;
  isImage : boolean;

  documentGarde : Document = new Document();

  //Validation des actions sans prise en compte des champs obligatoire(sauvegarde du rapport)
  submitted : boolean;

  //Validation des actions avec prise en compte des champs obligatoire
  submittedRequired : boolean;
  submittedRequiredSynthese : boolean;
  generateRapport : boolean;

  typeValeur : any;

  dateValeur : Date;

  configSizeInput : ConfigSizeInput;

  static CODE_CONTEXTE_APPRECIATION = 'CODAPPRGAR';
  static CODE_CONTEXTE_CONTENTIEUX = 'CODECONTEN';
  static PREVIOUS_PAGE_URL_RAPPORT = 'previousPageUrlRapport';

  existDosSynthese : boolean = false;
  existDocRapport : boolean = false;
  guidDocSynthese : string;

  profiles : ProfilesUser[];

  previousUrl : string;

  private subscriptionTypeValeur : Subscription;

  constructor(
      private dossierService : DossierService, private userService : UserService,
      private translate : TranslateService,  private fb : FormBuilder,
      private route: ActivatedRoute, public datepipe: DatePipe,
      private cdref: ChangeDetectorRef,
      private spinnerService: NgxSpinnerService, private confirmation: ConfirmationService,
      private messageStorage : MessageStorageService,  private messageService: MessageService,
      private router : Router, private rapportExpertiseService : RapportExpertiseService,
      private utilsObservableService : UtilsObservableService, private documentService : DocumentService,
      private rapportExpertiseObservableService : RapportExpertiseObservableService,
      private sanitizer: DomSanitizer
  ) {
    this.configSizeInput = new ConfigSizeInput();
    this.getUserConnected();

    this.router.events
      .pipe(filter((e: any) => e instanceof RoutesRecognized),
        pairwise()
      ).subscribe((e: any) => {
      this.previousUrl = e[0].urlAfterRedirects;
      localStorage.setItem(RapportExpertiseComponent.PREVIOUS_PAGE_URL_RAPPORT, this.previousUrl );
    });
  }

  ngOnInit(): void {
    this.currentDateTime = new Date();
    this.route.params.subscribe(params => {
      if(params) {
        this.getRapportByIdDossier(params['id']);
      }
    });

    if(this.messageStorage.getMessage()) {
      this.messageSuccessGenerationRapport = [
        {severity:'success', summary:this.translate.getElement(this.messageStorage.getMessage(), this.messageStorage.getKey1()), closable : true }
      ];
      if(this.messageStorage.getKey2() === ConfigConstants.RAPPORT_EXPERTISE) {
        this.generateRapport = true;
      }
      this.messageStorage.removeMessage();
    }

    this.subscriptionTypeValeur = this.rapportExpertiseObservableService.getTypeValeur().subscribe( data => {
      this.typeValeur = data;
    })
  }

  ngOnDestroy() {
    this.subscriptionTypeValeur.unsubscribe();
  }

  async getUserConnected() {
    this.profiles = await this.userService.getUserConnectedProfilesById();
  }

  /**
   * Recupère un dossier de type rapport d'expertise
   * @param idDossier
   */
  async  getRapportByIdDossier(idDossier : number) {
    await this.rapportExpertiseService.getRapportByIdDossier(idDossier).then(
      data => {
        if(data) {
          this.rapport = data;
          if(this.rapport.dateValeur) {
            this.rapport.dateValeur = new Date( this.rapport.dateValeur);
            this.dateValeur = this.rapport.dateValeur;
          }
          else if(this.rapport.dateVisite) {
            this.rapport.dateVisite = new Date( this.rapport.dateVisite);
            this.dateValeur = this.rapport.dateVisite;
          }
          this.navigationContextuelle();
          this.docsContainsFilename(this.rapport?.idBien);
          setTimeout(() => {
            this.navActionsItem();
          }, 3000);

          this.initForm();
          this.getPhotoGarde();
        }else {
          this.router.navigateByUrl("/role-denied");
        }
      }, error => {
        if(error.status === 401) {
          this.router.navigateByUrl("/role-denied");
        }
      }
    )
  }

  get f(): { [key: string]: AbstractControl } {
    return this.form.controls;
  }

  /**
   * Sauvegarde les modifications effectuées sur le rapport
   * @private
   */
  private prepareSauvegarder(): Observable<any>{

    this.spinnerService.show();

    this.submitted = true;

    this.messages = null;

    if(!this.hasErroTextFields()) {

      const rapport : RapportExpertise = new RapportExpertise(this.rapport);

      this.validationMissionForm(rapport);
      this.validationSyntheseForm(rapport);
      this.validationDesignation(rapport);
      this.validationSituationGeoForm(rapport);
      this.validationDescriptionForm(rapport);
      this.validationEnvUrbanisme(rapport);
      this.validationCdtOccupationForm(rapport);
      this.validationEtudeMarche(rapport);
      this.validationEstimation(rapport);
      this.validationConclusionForm(rapport);

      if(!this.submittedRequiredSynthese && this.detectionModification()) {
        this.messages = [
          {severity:'warn', summary: this.translate.getElement("RAPPORT_EXPERTISE.MESSAGE.NO_MESSAGE_DETECTED") }
        ];
        this.spinnerService.hide();
        return null;
      }

      return this.rapportExpertiseService.updateAndSaveRapport(rapport);
    }
    return null;

  }

  private sauvegarder() {
    let obs = this.prepareSauvegarder();
    if (obs != null) {
      obs.subscribe({
        next: (data) => {
          if(data) {
            this.messageStorage.saveMessage('RAPPORT_EXPERTISE.MESSAGE.SAVE_SUCCES', data.numeroDossier);
            window.location.reload();
            this.spinnerService.hide();
          }else {
            this.messages =[ {severity:'error', summary : this.translate.getElement('RAPPORT_EXPERTISE.MESSAGE.SAVE_ERROR')}];
          }
          this.spinnerService.hide();
          window.scrollTo(0,0);
        },
        error: () => {
          this.messages =[ {severity:'error', summary : this.translate.getElement('RAPPORT_EXPERTISE.MESSAGE.SAVE_ERROR')}];
          this.spinnerService.hide();
          window.scrollTo(0,0);
        }
      });
    }

  }

  sendSyntheseForValidation() {
    this.rapportExpertiseService.sendSyntheseForValidation(this.rapport?.idDossier, this.guidDocSynthese).subscribe(data => {
      if (data) {
        this.messageStorage.saveMessage('RAPPORT_EXPERTISE.MESSAGE.SUCESS_SYNTHESE_SEND_FOR_VALIDATION', this.rapport.numeroDossier);
        window.location.reload();
        window.scrollTo(0,0);
        this.submittedRequired = false;
      } else {
        this.messages =[ {severity:'error', summary : this.translate.getElement('RAPPORT_EXPERTISE.MESSAGE.ERROR_SYNTHESE_SEND_FOR_VALIDATION', this.rapport.numeroDossier)}];
        window.scrollTo(0,0);
        this.spinnerService.hide();
      }
    }, error => {
      window.scrollTo(0,0);
      if(error.status === 412) {
        this.messages =[ {severity:'error', summary : this.translate.getElement('RAPPORT_EXPERTISE.MESSAGE.ERROR_CONDITION_SYNTHESE_SEND_FOR_VALIDATION', this.rapport.numeroDossier)}];
      }else {
        this.messages =[ {severity:'error', summary : this.translate.getElement('RAPPORT_EXPERTISE.MESSAGE.ERROR_SYNTHESE_SEND_FOR_VALIDATION', this.rapport.numeroDossier)}];
      }
      this.spinnerService.hide();
    })
  }

  /**
   * Envoi de la synthèse pour validation
   */
  submitSyntheseForValidation() {
    this.submittedRequiredSynthese = true;
    let obs = this.prepareSauvegarder();
    if(obs != null) {
      obs.subscribe({
        next: (data) => {
          if(data) {
            let obsSynthese = this.genererSynthese();
            if(obsSynthese) {
              obsSynthese.subscribe({
                next : (data) => {
                  if(data) {
                    this.sendSyntheseForValidation();
                  }
                },
                error : () => {
                  window.scrollTo(0,0);
                  this.messages =[ {severity:'error', summary : this.translate.getElement('RAPPORT_EXPERTISE.MESSAGE.GENERER_SYNTHESE_ERROR', this.rapport.numeroDossier)}];
                  this.spinnerService.hide();
                }
              })
            }else {
              window.scrollTo(0,0);
              this.messages =[ {severity:'error', summary : this.translate.getElement('RAPPORT_EXPERTISE.MESSAGE.GENERER_SYNTHESE_ERROR', this.rapport.numeroDossier)}];
              this.spinnerService.hide();
            }
          }
          else {
            this.messages =[ {severity:'error', summary : this.translate.getElement('RAPPORT_EXPERTISE.MESSAGE.SAVE_ERROR')}];
            this.spinnerService.hide();
            window.scrollTo(0,0);
          }
        },
        error: () => {
          this.messages =[ {severity:'error', summary : this.translate.getElement('RAPPORT_EXPERTISE.MESSAGE.SAVE_ERROR')}];
          this.spinnerService.hide();
          window.scrollTo(0,0);
        }
      });
    }else {
      this.sendSyntheseForValidation();
    }

  }

  /**
   * Envoi du rapport pour validation
   */
  sendRapportForValidation() {
    this.rapportExpertiseService.sendRapportForValidation(this.rapport.idDossier).subscribe(data => {
      if (data) {
        let url = localStorage.getItem(RapportExpertiseComponent.PREVIOUS_PAGE_URL_RAPPORT);
        this.submittedRequired = false;
        if(url) {
          localStorage.removeItem(RapportExpertiseComponent.PREVIOUS_PAGE_URL_RAPPORT)
          this.router.navigateByUrl(url)
        }else {
          this.router.navigateByUrl("/dashboard")
        }
        this.spinnerService.hide();
      } else {
        this.messages =[ {severity:'error', summary : this.translate.getElement('RAPPORT_EXPERTISE.MESSAGE.ERROR_RAPPORT_SEND_FOR_VALIDATION', this.rapport.numeroDossier)}];
        window.scrollTo(0,0);
        this.spinnerService.hide();
      }
    }, error => {
      window.scrollTo(0,0);
      if(error.status === 412) {
        this.messages =[ {severity:'error', summary : this.translate.getElement('RAPPORT_EXPERTISE.MESSAGE.ERROR_CONDITION_RAPPORT_SEND_FOR_VALIDATION', this.rapport.numeroDossier)}];
      }else {
        this.messages =[ {severity:'error', summary : this.translate.getElement('RAPPORT_EXPERTISE.MESSAGE.ERROR_RAPPORT_SEND_FOR_VALIDATION', this.rapport.numeroDossier)}];
      }

      this.spinnerService.hide();
    })
  }


  /**
   * valider l'envoi du rapport pour validation
   */
  submitSendRapportForValidation() {
    this.submittedRequiredSynthese = true;
    this.submittedRequired = true;
    let obs = this.prepareSauvegarder();
    if(obs != null) {
      obs.subscribe({
        next: (data) => {
          if(data) {
            let obsRapport= this.genererRapport();
            if(obsRapport) {
              obsRapport.subscribe({
                next : (data) => {
                  if(data) {
                    this.sendRapportForValidation();
                  }
                },
                error : () => {
                  window.scrollTo(0,0);
                  this.messages =[ {severity:'error', summary : this.translate.getElement('RAPPORT_EXPERTISE.MESSAGE.GENERER_RAPPORT_ERROR', this.rapport.idDossier.toString())}];
                  this.spinnerService.hide();
                }
              })
            }else {
              window.scrollTo(0,0);
              this.messages =[ {severity:'error', summary : this.translate.getElement('RAPPORT_EXPERTISE.MESSAGE.GENERER_RAPPORT_ERROR', this.rapport.idDossier.toString())}];
              this.spinnerService.hide();
            }
          }
          else {
            this.messages =[ {severity:'error', summary : this.translate.getElement('RAPPORT_EXPERTISE.MESSAGE.SAVE_ERROR')}];
            this.spinnerService.hide();
            window.scrollTo(0,0);
          }
        },
        error: () => {
          this.messages =[ {severity:'error', summary : this.translate.getElement('RAPPORT_EXPERTISE.MESSAGE.SAVE_ERROR')}];
          this.spinnerService.hide();
          window.scrollTo(0,0);
        }
      });
    }else {
      this.sendRapportForValidation();
    }
  }


  /**
   * Detection des modifications sur le rapport
   */
  detectionModification() {
    return !this.changeDetectedSynthese && !this.changeDetectedMission && !this.changeDetectedDesignation
      && !this.changeDetectedSituationGeo && !this.changeDetectedDescription
      && !this.changeDetectedEnvUrbanisme && !this.changeDetectedCdtOccupation
      && !this.changeDetectedEtudeMarche && !this.changeDetectedEstimation && !this.changeDetectedConclusion;
  }

  /**
   * Vérifie que les champs avec mise en forme ne sont pas en erreur
   */
  hasErroTextFields() {
    if(
      this.f?.mission['controls']?.piecesCommuniques?.errors?.hasError
      ||  this.f?.designation['controls']?.numLotTantiemes?.errors?.hasError
      ||  this.f?.designation['controls']?.observations?.errors?.hasError
      ||  this.f?.situationGeo['controls']?.localisation?.errors?.hasError
      ||  this.f?.situationGeo['controls']?.desserte?.errors?.hasError
      ||  this.f?.description['controls']?.rapportDescription?.errors?.hasError
      ||  this.f?.description['controls']?.construction?.errors?.hasError
      ||  this.f?.description['controls']?.amenagementsInterieurs?.errors?.hasError
      ||  this.f?.description['controls']?.equipement?.errors?.hasError
      ||  this.f?.description['controls']?.etatGeneral?.errors?.hasError
      ||  this.f?.description['controls']?.detailSurface?.errors?.hasError
      ||  this.f?.envUrbanisme['controls']?.dpeCommentaire?.errors?.hasError
      ||  this.f?.envUrbanisme['controls']?.urbanisme?.errors?.hasError
      ||  this.f?.envUrbanisme['controls']?.risquesEnvironnement?.errors?.hasError
      ||  this.f?.cdtOccupation['controls']?.conditionOccupation?.errors?.hasError
      ||  this.f?.etudeMarche['controls']?.analysemarche?.errors?.hasError
      ||  this.f?.etudeMarche['controls']?.analysemarcheLocatif?.errors?.hasError
      ||  this.f?.etudeMarche['controls']?.positionnement?.errors?.hasError
      ||  this.f?.estimation['controls']?.forces?.errors?.hasError
      ||  this.f?.estimation['controls']?.menaces?.errors?.hasError
      ||  this.f?.estimation['controls']?.faiblesses?.errors?.hasError
      ||  this.f?.estimation['controls']?.opportunites?.errors?.hasError
      ||  this.f?.estimation['controls']?.estimation?.errors?.hasError
      ||  this.f?.conclusion['controls']?.delaiPrecision?.errors?.hasError
      ||  (this.form.value?.conclusion?.decote < 0 || this.form.value?.conclusion?.decote > 100)
    ) {
      this.messages = [
        {severity:'error', summary: this.translate.getElement("RAPPORT_EXPERTISE.MESSAGE.ERROR_FORM") }
      ];
      window.scrollTo(0,0);
      this.spinnerService.hide();
      return true;
    }
    return false;
  }

  /**
   * initialisation du formulaire
   */
  initForm() {
    const rapport : RapportExpertise = this.rapport;

    this.form = this.fb.group({
      synthese : this.initFormSyntheseMission(rapport),
      mission : this.initFormMission(rapport),
      designation : this.initFormDesignation(rapport),
      situationGeo : this.initsituationGeo(rapport),
      description : this.initDescription(rapport),
      envUrbanisme : this.initEnvUrbanisme(rapport),
      cdtOccupation : this.initCdtOccupation(rapport),
      etudeMarche : this.initEtudeMarche(rapport),
      estimation : this.initEstimation(rapport),
      conclusion : this.initConclusion(rapport),
    });
  }

  /**
   * initialisation formulaire section description
   * @param rapport
   */
  initDescription(rapport : RapportExpertise) : FormGroup {

    this.rapportExpertiseObservableService.sendSurface(rapport?.valueSurface);
    this.rapportExpertiseObservableService.sendTypeSurface(rapport?.typeSurfaceRapport);
    return  new FormGroup({
      supTerrain : new FormControl(rapport?.supTerrain),
      rapportDescription : new FormControl(rapport?.description, Validation.patternValidatorMaxLengthCaracter(3000, {hasError: true})),
      topolographie : new FormControl(rapport?.paramTopographie),
      configuration : new FormControl(rapport?.paramConfiguration),
      entretien : new FormControl(rapport?.entretien),
      construction : new FormControl(rapport?.construction, Validation.patternValidatorMaxLengthCaracter(6000, {hasError: true})),
      distribution : new FormControl(rapport?.distribution, Validation.patternValidatorMaxLengthCaracter(6000, {hasError: true})),
      amenagementsInterieurs : new FormControl(rapport?.amenagementsInterieurs, Validation.patternValidatorMaxLengthCaracter(6000, {hasError: true})),
      equipement : new FormControl(rapport?.equipement, Validation.patternValidatorMaxLengthCaracter(6000, {hasError: true})),
      etatGeneral : new FormControl(rapport?.etatGeneral, Validation.patternValidatorMaxLengthCaracter(3000, {hasError: true})),
      reserve : new FormControl(rapport?.idParamReserve, Validators.required),
      typeSurface : new FormControl(rapport?.typeSurfaceRapport, Validators.required),
      valeurSurface  : new FormControl(rapport?.typeSurfaceRapport ? rapport?.valueSurface : null, [Validators.required, Validators.min(1)]),
      existsDetailSurface : new FormControl(rapport?.existeDetailSurface ? rapport?.existeDetailSurface : false),
      detailSurface : rapport?.existeDetailSurface ? new FormControl(rapport?.detailSurface, [
        Validators.required, Validation.patternValidatorMaxLengthCaracter(3000, {hasError: true}),
        Validation.patternValidatorEmptyCaracter({hasEmptyCaracterError: true})
      ] ) : new FormControl(rapport?.detailSurface, [Validation.patternValidatorMaxLengthCaracter(3000, {hasError: true})] ),

    });
  }

  /**
   * initialisation formulaire section environnement et urbanisme
   * @param rapport
   */
  initEnvUrbanisme(rapport : RapportExpertise) : FormGroup {
    return  new FormGroup({
      dpe :  new FormControl(rapport?.dpe, Validators.required),
      dpeCommentaire : new FormControl(rapport?.dpeCommentaire, Validation.patternValidatorMaxLengthCaracter(1000, {hasError: true})),
      urbanisme : new FormControl(rapport?.urbanisme, [Validators.required,
        Validation.patternValidatorMaxLengthCaracter(3500, {hasError: true}),
        Validation.patternValidatorEmptyCaracter({hasEmptyCaracterError: true})
      ]),
      risquesEnvironnement : new FormControl(rapport?.risquesEnvironnement, Validation.patternValidatorMaxLengthCaracter(1000, {hasError: true})),
    });
  }

  /**
   * initialisation formulaire section condition d'occupation
   * @param rapport
   */
  initCdtOccupation(rapport : RapportExpertise) : FormGroup {
    return  new FormGroup({
      conditionOccupation : new FormControl(rapport?.conditionOccupation, [
        Validators.required, Validation.patternValidatorMaxLengthCaracter(3500, {hasError: true}),
        Validation.patternValidatorEmptyCaracter({hasEmptyCaracterError: true})
      ]),
    });
  }

  /**
   * initialisation formulaire section etude du marché
   * @param rapport
   */
  initEtudeMarche(rapport : RapportExpertise) : FormGroup {
    return  new FormGroup({
      analysemarche : new FormControl(rapport?.analysemarche, [
        Validators.required, Validation.patternValidatorMaxLengthCaracter(4000, {hasError: true}),
        Validation.patternValidatorEmptyCaracter({hasEmptyCaracterError: true})
      ]),
      analysemarcheLocatif :  new FormControl(rapport?.analysemarcheLocatif, Validation.patternValidatorMaxLengthCaracter(4000, {hasError: true})),
      positionnement : new FormControl(rapport?.positionnement, [
        Validators.required, Validation.patternValidatorMaxLengthCaracter(2000, {hasError: true}),
        Validation.patternValidatorEmptyCaracter({hasEmptyCaracterError: true})
      ]),
    });
  }

  /**
   * initialisation formulaire section estimation
   * @param rapport
   */
  initEstimation(rapport : RapportExpertise) : FormGroup {
    this.rapportExpertiseObservableService.sendMethodeValorisation(rapport?.methodeValorisationRapport);
    return  new FormGroup({
      forces : new FormControl(rapport?.forces, Validation.patternValidatorMaxLengthCaracter(500, {hasError: true})),
      faiblesses :  new FormControl(rapport?.faiblesses, Validation.patternValidatorMaxLengthCaracter(500, {hasError: true})),
      opportunites : new FormControl(rapport?.opportunites, Validation.patternValidatorMaxLengthCaracter(500, {hasError: true})),
      menaces : new FormControl(rapport?.menaces, Validation.patternValidatorMaxLengthCaracter(500, {hasError: true})),
      methode : new FormControl(rapport?.methodeValorisationRapport, Validators.required),
      estimation : new FormControl(rapport?.estimation, [
        Validators.required, Validation.patternValidatorMaxLengthCaracter(3000, {hasError: true}),
        Validation.patternValidatorEmptyCaracter({hasEmptyCaracterError: true})
      ]),
    });
  }


  /**
   * initialisation formulaire section conclusion
   * @param rapport
   */
  initConclusion(rapport : RapportExpertise) : FormGroup {
    let requiredDelaiPrecision  : boolean = false;
    if(rapport?.paramTypeValeur?.idTypeValeur === 1 || rapport?.paramTypeValeur?.idTypeValeur === 3 || rapport?.paramTypeValeur?.idTypeValeur === 5) {
      if(rapport.delaiCommercialisation?.idDelaiCommercialisation === 1) {
        requiredDelaiPrecision = true;
      }
    }
    return  new FormGroup({

      dateValeur : new FormControl(this.dateValeur),
      valeurVenal : this.rapportIsVenale() ? new FormControl(rapport?.valeurGlobaleEstimee, Validators.required) : new FormControl(rapport?.valeurGlobaleEstimee),
      valeurLocative : this.rapportIsLocative() ? new FormControl(rapport?.valeurLocative, Validators.required) : new FormControl(rapport?.valeurLocative),
      valeurApresTravaux : this.rapportIsApresTravaux() ? new FormControl(rapport?.valeurApresTravaux, Validators.required) : new FormControl(rapport?.valeurApresTravaux),
      valeurFuturAchevement : this.rapportIsFuturAchevement() ? new FormControl(rapport?.valeurFuturAchevement, Validators.required) : new FormControl(rapport?.valeurFuturAchevement),

      regimeFiscalVenal : this.rapportIsVenale() ? new FormControl(rapport?.regimeFiscalVenal, Validators.required) : new FormControl(rapport?.regimeFiscalVenal),
      regimeFiscalLocatif : this.rapportIsLocative() ? new FormControl(rapport?.regimeFiscalLocatif, Validators.required) : new FormControl(rapport?.regimeFiscalLocatif),
      regimefiscalApresTraveaux : this.rapportIsApresTravaux() ? new FormControl(rapport?.regimeFiscalApresTravaux, Validators.required) : new FormControl(rapport?.regimeFiscalApresTravaux),
      regimefiscalFuturAchevement : this.rapportIsFuturAchevement() ? new FormControl(rapport?.regimeFiscalEtatFuturAchevement, Validators.required) : new FormControl(rapport?.regimeFiscalEtatFuturAchevement),

      //Valeur non enregistré en base
      prixM2ValeurVenale : new FormControl(null),
      prixM2ValeurLocative : new FormControl(null),
      prixM2ValeurApresTravaux : new FormControl(null),
      prixM2ValeurFuturAchevement : new FormControl(null),

      etablissement : new FormControl(rapport?.paramVille, Validators.required),
      delaiPrecision : rapport?.delaiCommercialisation && requiredDelaiPrecision ? new FormControl(rapport?.delaiPrecision, [
            Validators.required,
            Validation.patternValidatorMaxLengthCaracter(500, {hasError: true}),
            Validation.patternValidatorEmptyCaracter({hasEmptyCaracterError: true})
          ]) : new FormControl(rapport?.delaiPrecision, [Validation.patternValidatorMaxLengthCaracter(500, {hasError: true})]),

      decote : this.rapportIsVenale() && this.rapport?.typeTiers === 'TTBANQUE' ? new FormControl(rapport?.decote, Validators.required) : new FormControl(rapport?.decote),

      valeurJuridique : new FormControl(rapport?.valeurJuridique),

      tauxFiscaliteVenal : this.rapportIsVenale() ? new FormControl(rapport?.tauxFiscaliteVenal, Validators.required) : new FormControl(rapport?.tauxFiscaliteVenal),
      tauxFiscaliteApresTravaux : this.rapportIsApresTravaux() ? new FormControl(rapport?.tauxFiscaliteApresTravaux, Validators.required) : new FormControl(rapport?.tauxFiscaliteApresTravaux),
      tauxFiscaliteEtatFuturAchevement : this.rapportIsFuturAchevement() ? new FormControl(rapport?.tauxFiscaliteEtatFuturAchevement, Validators.required) : new FormControl(rapport?.tauxFiscaliteEtatFuturAchevement),
    });
  }

  /**
   * initialisation formulaire section synthese
   * @param rapport
   */
  initFormSyntheseMission(rapport : RapportExpertise) : FormGroup {
    this.rapportExpertiseObservableService.sendLiquidite(rapport?.paramLiquidite);
    this.rapportExpertiseObservableService.sendDelaiCommercialissation(rapport?.delaiCommercialisation);
    return  new FormGroup({
      typeVisite : this.isSyntheseSendingForValidation() ? new FormControl(rapport?.natureVisite) : new FormControl(rapport?.natureVisite, Validators.required),
      prenomExpert : new FormControl(rapport?.prenomExpert, Validators.pattern(this.configSizeInput.regexPattern)),
      nomExpert : this.isSyntheseSendingForValidation() ? new FormControl(rapport?.nomExpert) : new FormControl(rapport?.nomExpert, [Validators.required, Validators.pattern(this.configSizeInput.regexPattern)]),
      accompagnateur : new FormControl(rapport?.accompagnateur),
      situationBien : this.isSyntheseSendingForValidation() ?  new FormControl(rapport?.situation) : new FormControl(rapport?.situation, Validators.required),
      entretienInterne : (rapport?.natureVisite?.idNatureVisite === 1 || rapport?.natureVisite?.idNatureVisite === 3) && !this.isSyntheseSendingForValidation() ?
        new FormControl(rapport?.entretienInterne, Validators.required) : new FormControl(rapport?.entretienInterne),
      entretienExterne  : rapport?.natureVisite?.idNatureVisite === 2 && this.isSyntheseSendingForValidation() ?
        new FormControl(rapport?.entretienExterne)  : this.isSyntheseSendingForValidation() ?
          new FormControl(rapport?.entretienExterne) : new FormControl(rapport?.entretienExterne, Validators.required),
      liquidite : this.rapportIsVenale() && (!rapport?.etatEnvoi || rapport?.etatEnvoi === ConfigConstants.ETAT_ENVOI_INIT) ?
        new FormControl(rapport?.paramLiquidite, Validators.required) : new FormControl( rapport?.paramLiquidite),
      delaiCommercialisation : this.rapportIsVenale() && (!rapport?.etatEnvoi || rapport?.etatEnvoi === ConfigConstants.ETAT_ENVOI_INIT) ?
        new FormControl(rapport?.delaiCommercialisation, Validators.required) : new FormControl(rapport?.delaiCommercialisation)
    });
  }

  /**
   * initialisation formulaire section Mission
   * @param rapport
   */
  initFormMission(rapport : RapportExpertise) : FormGroup {

    this.rapportExpertiseObservableService.sendTypeValeur(rapport?.paramTypeValeur);

    return  new FormGroup({
      typeValeur : new FormControl(rapport?.paramTypeValeur, Validators.required),
      typeOccupation : new FormControl(rapport?.rapportOccupation, Validators.required),
      typeBien : new FormControl(rapport?.typeBiens, Validators.required),
      piecesCommuniques : new FormControl(rapport?.piecesTransmises, [
        Validators.required, Validation.patternValidatorMaxLengthCaracter(400, {hasError: true}),
        Validation.patternValidatorEmptyCaracter({hasEmptyCaracterError: true})
      ]),
    });
  }

  /**
   * initialisation formulaire section Mission
   * @param rapport
   */
  initFormDesignation(rapport : RapportExpertise) : FormGroup {
    return  new FormGroup({
      typeRegimejRapport : new FormControl(rapport?.paramTypeRegimejRapport),
      numLotTantiemes : new FormControl(rapport?.numLotTantiemes, Validation.patternValidatorMaxLengthCaracter(3000, {hasError: true})),
      observations : new FormControl(rapport?.observations, Validation.patternValidatorMaxLengthCaracter(3000, {hasError: true})),
      designationcadastre : new FormControl(rapport?.designationcadastre, Validators.maxLength(3000)),
    });
  }

  /**
   * initialisation formulaire section situation géographique
   * @param rapport
   */
  initsituationGeo(rapport : RapportExpertise) : FormGroup {
    return  new FormGroup({
      population : new FormControl(rapport?.population),
      localisation : new FormControl(rapport?.localisation, [
        Validators.required,
        Validation.patternValidatorMaxLengthCaracter(4000, {hasError: true}),
        Validation.patternValidatorEmptyCaracter({hasEmptyCaracterError: true})
      ]),
      secteur : new FormControl(rapport?.secteur),
      desserte : new FormControl(rapport?.desserte, Validation.patternValidatorMaxLengthCaracter(1000, {hasError: true})),
    });
  }


  /**
   * Detection des modification sur la section synthese et mise à jour des informations
   * @param rapport
   */
  validationSyntheseForm(rapport : RapportExpertise) {
    let synthese = this.form.value.synthese;
    this.changeDetectedSynthese = false;
    if(rapport?.natureVisite !== synthese.typeVisite
      || rapport?.prenomExpert !== synthese.prenomExpert
      || rapport?.nomExpert !== synthese.nomExpert
      || rapport?.accompagnateur !== synthese.accompagnateur
      || rapport?.situation !== synthese.situationBien
      || rapport?.entretienExterne !== synthese.entretienExterne
      || rapport?.entretienInterne !== synthese.entretienInterne
      || rapport?.paramLiquidite !== synthese.liquidite
      || rapport?.delaiCommercialisation !== synthese.delaiCommercialisation
      || this.form?.value?.mission?.typeValeur != this.rapport?.paramTypeValeur
    ) {
      rapport.natureVisite = synthese.typeVisite;
      rapport.prenomExpert = synthese.prenomExpert;
      rapport.nomExpert = synthese.nomExpert;
      rapport.accompagnateur = synthese.accompagnateur;
      rapport.situation = synthese.situationBien;
      rapport.entretienExterne = synthese.entretienExterne;
      rapport.entretienInterne = synthese.entretienInterne;


      if(this.form?.value?.mission?.typeValeur?.idTypeValeur === 1
        || this.form?.value?.mission?.typeValeur?.idTypeValeur === 3
        || this.form?.value?.mission?.typeValeur?.idTypeValeur === 5) {
        rapport.paramLiquidite = synthese.liquidite;
        rapport.delaiCommercialisation = synthese.delaiCommercialisation;
      }else {
        rapport.paramLiquidite = null;
        rapport.delaiCommercialisation = null;
      }
      this.changeDetectedSynthese = true;
    }
  }

  /**
   * Detection des modification sur la section condition d'occupation et mise à jour des informations
   * @param rapport
   */
  validationCdtOccupationForm(rapport : RapportExpertise) {
    let cdtOccupation = this.form.value.cdtOccupation;
    this.changeDetectedCdtOccupation = false;
    if(rapport?.conditionOccupation !== cdtOccupation.conditionOccupation) {

      if(Validation.validatoionEmptyCaracterFielTextWithShaping(cdtOccupation.conditionOccupation)) {
        rapport.conditionOccupation = null;
      }else {
        rapport.conditionOccupation =  cdtOccupation.conditionOccupation;
      }

      this.changeDetectedCdtOccupation = true;
    }
  }

  /**
   * Detection des modifications sur la section mission et mise à jour des informations
   * @param rapport
   */
  validationMissionForm(rapport : RapportExpertise) {
    let missionForm = this.form.value.mission;
    this.changeDetectedMission = false;
    if(rapport?.paramTypeValeur !== missionForm.typeValeur
        || rapport?.piecesTransmises !== missionForm.piecesCommuniques
        || rapport?.rapportOccupation !== missionForm.typeOccupation) {
      rapport.paramTypeValeur = missionForm.typeValeur;
      rapport.rapportOccupation = missionForm.typeOccupation;

      if(Validation.validatoionEmptyCaracterFielTextWithShaping(missionForm.piecesCommuniques)) {
        rapport.piecesTransmises = null;
      }else {
        rapport.piecesTransmises =  missionForm.piecesCommuniques;
      }

      this.changeDetectedMission = true;
    }
  }

  /**
   *Detection des modification sur la section etude de marché et mise à jour des informations
   * @param rapport
   */
  validationEtudeMarche(rapport : RapportExpertise) {
    let etudeMarche = this.form.value.etudeMarche;
    this.changeDetectedEtudeMarche = false;
    if(rapport?.analysemarche !== etudeMarche.analysemarche
      || rapport?.analysemarcheLocatif !== etudeMarche.analysemarcheLocatif
      || rapport?.positionnement !== etudeMarche.positionnement
    ) {

      if(Validation.validatoionEmptyCaracterFielTextWithShaping(etudeMarche.analysemarche)) {
        rapport.analysemarche = null;
      }else {
        rapport.analysemarche =  etudeMarche.analysemarche;
      }

      if(Validation.validatoionEmptyCaracterFielTextWithShaping(etudeMarche.analysemarcheLocatif)) {
        rapport.analysemarcheLocatif = null;
      }else {
        rapport.analysemarcheLocatif =  etudeMarche.analysemarcheLocatif;
      }

      if(Validation.validatoionEmptyCaracterFielTextWithShaping(etudeMarche.positionnement)) {
        rapport.positionnement = null;
      }else {
        rapport.positionnement =  etudeMarche.positionnement;
      }


      this.changeDetectedEtudeMarche = true;
    }
  }


  /**
   *Detection des modification sur la section estimation et mise à jour des informations
   * @param rapport
   */
  validationEstimation(rapport : RapportExpertise) {
    let estimation = this.form.value.estimation;
    this.changeDetectedEstimation = false;
    if(rapport?.forces !== estimation.forces
      || rapport?.faiblesses !== estimation.faiblesses
      || rapport?.opportunites !== estimation.opportunites
      || rapport?.menaces !== estimation.menaces
      || rapport?.methodeValorisationRapport !== estimation.methode
      || rapport?.estimation !== estimation.estimation
    ) {
      rapport.forces = estimation.forces;
      rapport.faiblesses = estimation.faiblesses;
      rapport.opportunites = estimation.opportunites;
      rapport.menaces = estimation.menaces;

      rapport.methodeValorisationRapport = estimation.methode;

      if(Validation.validatoionEmptyCaracterFielTextWithShaping(estimation.estimation)) {
        rapport.estimation = null;
      }else {
        rapport.estimation =  estimation.estimation;
      }


      this.changeDetectedEstimation = true;
    }
  }

  /**
   * Detection des modifications sur la section designation et mise à jour des informations
   * @param rapport
   */
  validationDesignation(rapport : RapportExpertise) {
    let designation = this.form.value.designation;
    this.changeDetectedDesignation = false;
    if(rapport?.paramTypeRegimejRapport !== designation.typeRegimejRapport
      || rapport?.numLotTantiemes !== designation.numLotTantiemes
      || rapport?.designationcadastre !== designation.designationcadastre
      || rapport?.observations !== designation.observations
    ) {
      rapport.paramTypeRegimejRapport = designation.typeRegimejRapport;
      rapport.designationcadastre = designation.designationcadastre;

      if(Validation.validatoionEmptyCaracterFielTextWithShaping(designation.numLotTantiemes)) {
        rapport.numLotTantiemes = null;
      }else {
        rapport.numLotTantiemes =  designation.numLotTantiemes;
      }

      if(Validation.validatoionEmptyCaracterFielTextWithShaping(designation.observations)) {
        rapport.observations = null;
      }else {
        rapport.observations =  designation.observations;
      }
      this.changeDetectedDesignation = true;
    }
  }

  /**
   * Detection des modifications sur la section environnement et urbanisme et mise à jour des informations
   * @param rapport
   */
  validationEnvUrbanisme(rapport : RapportExpertise) {
    let envUrbanisme = this.form.value.envUrbanisme;
    this.changeDetectedEnvUrbanisme = false;
    if(rapport?.dpe !== envUrbanisme.dpe
      || rapport?.dpeCommentaire !== envUrbanisme.dpeCommentaire
      || rapport?.urbanisme !== envUrbanisme.urbanisme
      || rapport?.risquesEnvironnement !== envUrbanisme.risquesEnvironnement
    ) {
      rapport.dpe = envUrbanisme.dpe;

      if(Validation.validatoionEmptyCaracterFielTextWithShaping(envUrbanisme.dpeCommentaire)) {
        rapport.dpeCommentaire = null;
      }else {
        rapport.dpeCommentaire =  envUrbanisme.dpeCommentaire;
      }

      if(Validation.validatoionEmptyCaracterFielTextWithShaping(envUrbanisme.urbanisme)) {
        rapport.urbanisme = null;
      }else {
        rapport.urbanisme =  envUrbanisme.urbanisme;
      }

      if(Validation.validatoionEmptyCaracterFielTextWithShaping(envUrbanisme.risquesEnvironnement)) {
        rapport.risquesEnvironnement = null;
      }else {
        rapport.risquesEnvironnement =  envUrbanisme.risquesEnvironnement;
      }

      this.changeDetectedEnvUrbanisme = true;
    }
  }

  /**
   * Detection des modifications sur la section situation géographique et mise à jour des informations
   * @param rapport
   */
  validationSituationGeoForm(rapport : RapportExpertise) {
    let situationGeo = this.form.value.situationGeo;
    this.changeDetectedSituationGeo = false;
    if(
      rapport?.secteur !== situationGeo.secteur
      || rapport?.population !== situationGeo.population
      || rapport?.localisation !== situationGeo.localisation
      || rapport?.desserte !== situationGeo.desserte
    ) {
      rapport.secteur = situationGeo.secteur;
      rapport.population = situationGeo.population;
      rapport.localisation = situationGeo.localisation;

      if(Validation.validatoionEmptyCaracterFielTextWithShaping(situationGeo.localisation)) {
        rapport.localisation = null;
      }else {
        rapport.localisation =  situationGeo.localisation;
      }

      if(Validation.validatoionEmptyCaracterFielTextWithShaping(situationGeo.desserte)) {
        rapport.desserte = null;
      }else {
        rapport.desserte =  situationGeo.desserte;
      }
      this.changeDetectedSituationGeo = true;
    }
  }


  /**
   * Detection des modifications sur la section description et mise à jour des informations
   * @param rapport
   */
  validationDescriptionForm(rapport : RapportExpertise) {
    let description = this.form.value.description;
    this.changeDetectedDescription = false;
    if(
      rapport?.supTerrain !== description.supTerrain
      || rapport?.paramTopographie !== description.topolographie
      || rapport?.entretien !== description.entretien
      || rapport?.paramConfiguration !== description.configuration
      || rapport?.description !== description.rapportDescription
      || rapport.construction !== description.construction
      || rapport.distribution !== description.distribution
      || rapport.equipement !==  description.equipement
      || rapport?.amenagementsInterieurs !== description.amenagementsInterieurs
      || rapport?.etatGeneral !== description.etatGeneral
      || rapport?.idParamReserve !== description.reserve
      || rapport?.typeSurfaceRapport !== description.typeSurface
      || rapport?.valueSurface !== description.valeurSurface
      || rapport?.existeDetailSurface !== description.existsDetailSurface
      || rapport?.detailSurface !== description.detailSurface
    ) {
      this.setTypeSurface(description?.typeSurface, description.valeurSurface, rapport);
      rapport.supTerrain = description.supTerrain;
      rapport.paramTopographie = description.topolographie;
      rapport.entretien = description.entretien;
      rapport.paramConfiguration = description.configuration;
      rapport.idParamReserve = description.reserve;
      rapport.typeSurfaceRapport = description.typeSurface;
      rapport.valueSurface = description.valeurSurface;
      rapport.existeDetailSurface = description.existsDetailSurface;

      if(Validation.validatoionEmptyCaracterFielTextWithShaping(description.rapportDescription)) {
        rapport.description = null;
      }else {
        rapport.description =  description.rapportDescription;
      }

      if(Validation.validatoionEmptyCaracterFielTextWithShaping(description.construction)) {
        rapport.construction = null;
      }else {
        rapport.construction =  description.construction;
      }
      ///
      if(Validation.validatoionEmptyCaracterFielTextWithShaping(description.distribution)) {
        rapport.distribution = null;
      }else {
        rapport.distribution =  description.distribution;
      }

      if(Validation.validatoionEmptyCaracterFielTextWithShaping(description.amenagementsInterieurs)) {
        rapport.amenagementsInterieurs = null;
      }else {
        rapport.amenagementsInterieurs =  description.amenagementsInterieurs;
      }


      if(Validation.validatoionEmptyCaracterFielTextWithShaping(description.etatGeneral)) {
        rapport.etatGeneral = null;
      }else {
        rapport.etatGeneral =  description.etatGeneral;
      }


      if(Validation.validatoionEmptyCaracterFielTextWithShaping(description.equipement)) {
        rapport.equipement = null;
      }else {
        rapport.equipement =  description.equipement;
      }

      if(Validation.validatoionEmptyCaracterFielTextWithShaping(description.detailSurface)) {
        rapport.detailSurface = null;
      }else {
        rapport.detailSurface =  description.detailSurface;
      }


      this.changeDetectedDescription = true;
    }
  }

  /**
   * Detection des modifications sur la section conclusion et mise à jour des informations
   * @param rapport
   */
  validationConclusionForm(rapport : RapportExpertise) {

    let conclusion = this.form.value.conclusion;
    this.changeDetectedConclusion = false;

    if(conclusion.dateValeur && rapport.dateValeur != conclusion.dateValeur) {
      rapport.dateValeur =new Date(Date.UTC(conclusion.dateValeur.getFullYear(),
        conclusion.dateValeur.getMonth(), conclusion.dateValeur.getDate()));
    }

    if(rapport.valeurGlobaleEstimee != conclusion.valeurVenal
      || rapport.valeurLocative != conclusion.valeurLocative
      || rapport.valeurApresTravaux != conclusion.valeurApresTravaux
      || rapport.valeurFuturAchevement != conclusion.valeurFuturAchevement
      || rapport.regimeFiscalVenal != conclusion.regimeFiscalVenal
      || rapport.regimeFiscalLocatif != conclusion.regimeFiscalLocatif
      || rapport.regimeFiscalApresTravaux != conclusion.regimefiscalApresTraveaux
      || rapport.regimeFiscalEtatFuturAchevement != conclusion.regimefiscalFuturAchevement
      || rapport.paramVille != conclusion.etablissement
      || rapport.delaiPrecision != conclusion.delaiPrecision
      || rapport.decote != conclusion.decote
      || rapport.tauxFiscaliteVenal != conclusion.tauxFiscaliteVenal
      || rapport.tauxFiscaliteApresTravaux != conclusion.tauxFiscaliteApresTravaux
      || rapport.tauxFiscaliteEtatFuturAchevement != conclusion.tauxFiscaliteEtatFuturAchevement
      || rapport.valeurJuridique != conclusion.valeurJuridique
      || this.form?.value?.mission?.typeValeur != this.rapport?.paramTypeValeur
    ) {

      this.setValeurTypeValeur(this.form?.value?.mission?.typeValeur, rapport, conclusion);

      rapport.paramVille = conclusion.etablissement;
      rapport.valeurJuridique = conclusion.valeurJuridique;

      if(Validation.validatoionEmptyCaracterFielTextWithShaping(conclusion.delaiPrecision)) {
        rapport.delaiPrecision = null;
      }else {
        rapport.delaiPrecision =  conclusion.delaiPrecision;
      }

      this.changeDetectedConclusion = true;
    }

  }

  setValeurTypeValeur(typeValeur : any, rapport : RapportExpertise, conclusion : any) {

    switch (typeValeur?.idTypeValeur) {
      case 1 :

        rapport.valeurLocative = null;
        rapport.valeurApresTravaux = null;
        rapport.valeurFuturAchevement = null;
        rapport.valeurGlobaleEstimee = conclusion.valeurVenal;

        rapport.regimeFiscalLocatif = null;
        rapport.regimeFiscalApresTravaux = null;
        rapport.regimeFiscalEtatFuturAchevement = null;
        rapport.regimeFiscalVenal = conclusion.regimeFiscalVenal;


        rapport.tauxFiscaliteApresTravaux = null;
        rapport.tauxFiscaliteEtatFuturAchevement = null;
        rapport.tauxFiscaliteVenal = conclusion.tauxFiscaliteVenal;

        rapport.decote = conclusion.decote;

        return rapport;
      case 2 :

        rapport.valeurApresTravaux = null;
        rapport.valeurFuturAchevement = null;
        rapport.valeurGlobaleEstimee =null;
        rapport.valeurLocative = conclusion.valeurLocative;

        rapport.regimeFiscalApresTravaux = null;
        rapport.regimeFiscalEtatFuturAchevement = null;
        rapport.regimeFiscalVenal = null;
        rapport.regimeFiscalLocatif = conclusion.regimeFiscalLocatif;

        rapport.tauxFiscaliteVenal = null;
        rapport.tauxFiscaliteApresTravaux = null;
        rapport.tauxFiscaliteEtatFuturAchevement = null;

        rapport.decote = null;

        return rapport;
      case 3 :

        rapport.valeurLocative = null;
        rapport.valeurFuturAchevement = null;
        rapport.valeurApresTravaux = conclusion.valeurApresTravaux;
        rapport.valeurGlobaleEstimee = conclusion.valeurVenal;

        rapport.regimeFiscalEtatFuturAchevement = null;
        rapport.regimeFiscalLocatif = null;
        rapport.regimeFiscalApresTravaux = conclusion.regimefiscalApresTraveaux;
        rapport.regimeFiscalVenal = conclusion.regimeFiscalVenal;

        rapport.tauxFiscaliteEtatFuturAchevement = null;
        rapport.tauxFiscaliteVenal = conclusion.tauxFiscaliteVenal;
        rapport.tauxFiscaliteApresTravaux = conclusion.tauxFiscaliteApresTravaux;

        rapport.decote = conclusion.decote;

        return rapport;
      case 4 :

        rapport.valeurLocative = null;
        rapport.valeurApresTravaux = null;
        rapport.valeurGlobaleEstimee = null;
        rapport.valeurFuturAchevement = conclusion.valeurFuturAchevement;

        rapport.regimeFiscalVenal = null;
        rapport.regimeFiscalLocatif = null;
        rapport.regimeFiscalApresTravaux = null;
        rapport.regimeFiscalEtatFuturAchevement = conclusion.regimefiscalFuturAchevement;

        rapport.tauxFiscaliteVenal = null;
        rapport.tauxFiscaliteApresTravaux = null;
        rapport.tauxFiscaliteEtatFuturAchevement = conclusion.tauxFiscaliteEtatFuturAchevement;

        rapport.decote = null;

        return rapport;
      case 5 :

        rapport.valeurApresTravaux = null;
        rapport.valeurFuturAchevement = null;
        rapport.valeurLocative = conclusion.valeurLocative;
        rapport.valeurGlobaleEstimee = conclusion.valeurVenal;

        rapport.regimeFiscalApresTravaux = null;
        rapport.regimeFiscalEtatFuturAchevement = null;
        rapport.regimeFiscalVenal = conclusion.regimeFiscalVenal;
        rapport.regimeFiscalLocatif = conclusion.regimeFiscalLocatif;

        rapport.tauxFiscaliteApresTravaux = null;
        rapport.tauxFiscaliteEtatFuturAchevement = null;
        rapport.tauxFiscaliteVenal = conclusion.tauxFiscaliteVenal;

        rapport.decote = conclusion.decote;

        return rapport;
      default :
        return null;

    }
  }

  /**
   * Mise à jour du type de surface selectionné dans le rapport
   * @param typeSurfaces
   * @param surface
   * @param rapport
   */
  setTypeSurface(typeSurfaces : any, surface : number, rapport : RapportExpertise) {
    let idSurface = typeSurfaces?.idTypeSurface;
    switch (idSurface) {
      case 1 :
        rapport.shp = null;
        rapport.su = null;
        rapport.sup = null;
        rapport.sdhop = null;
        rapport.spc = null;
        rapport.sh = surface;
        return rapport;
      case 2 :
        rapport.sh = null;
        rapport.su = null;
        rapport.sup = null;
        rapport.sdhop = null;
        rapport.spc = null;
        rapport.shp = surface;
        return rapport;
      case 3 :
        rapport.sh = null;
        rapport.shp = null;
        rapport.sup = null;
        rapport.sdhop = null;
        rapport.spc = null;
        rapport.su = surface;
        return rapport;
      case 4 :
        rapport.sh = null;
        rapport.shp = null;
        rapport.su = null;
        rapport.sdhop = null;
        rapport.spc = null;
        rapport.sup = surface;
        return rapport;
      case 5 :
        rapport.sh = null;
        rapport.shp = null;
        rapport.su = null;
        rapport.sup = null;
        rapport.spc = null;
        rapport.sdhop = surface;
        return rapport;
      case 6 :
        rapport.sh = null;
        rapport.shp = null;
        rapport.su = null;
        rapport.sup = null;
        rapport.sdhop = null;
        rapport.spc = surface;
        return rapport;
      default :
        return null;
    }

  }

  /**
   * recupère la photo de garde
   */
   getPhotoGarde() {

     this.documentService.findDocumentByIdbienAndCategorie(this.rapport.idBien, 1).subscribe( doc => {

      if (doc != null && doc.length > 0) {
        this.documentGarde = doc.shift();

        const reader = new FileReader();

        reader.addEventListener("load", () => {
          this.documentGarde.lienImage = this.sanitizer.bypassSecurityTrustUrl(reader.result as string);
        }, false);
        reader.readAsDataURL(UtilsFonction.b64toBlob(this.documentGarde.contenu, this.documentGarde.type) );

        this.progressSpinner = false;
      }else {
        this.progressSpinner = false;
      }
    });

  }


  /**
   * Menu de droite action utilisateurs
   */
  async navActionsItem() {

    const isAuthorize : boolean = (this.profiles.includes(ProfilesUser.EXPERT_EXTERNE) || this.profiles.includes(ProfilesUser.EXPERT_INTERNE)) && this.rapport.statutDossier === StatutDossier.ENCOURS;

    this.actionsItem = [{
      label: this.translate.getElement('RAPPORT_EXPERTISE.ACTIONS.TITLE'),
      items : [
        {
          label: this.translate.getElement('RAPPORT_EXPERTISE.ACTIONS.SAUVEGARDER'),
          icon : 'inc-icon inc-icon-save is-small',
          command : event => {
            this.confirmation.confirm({
              message:this.translate.getElement('AVIS_VALEUR.MESSAGE.SAUVEGARDE'),
              header :  this.translate.getElement('AVIS_VALEUR.ACTIONS.SAUVEGARDER')?.toUpperCase(),
              icon: 'pi pi-exclamation-triangle',
              accept: () => {
                this.sauvegarder();
                window.scrollTo(0,0);
              }
            })

          }
        }
      ]
    }];

    if (isAuthorize && this.rapport.synthese && (this.rapport.etatEnvoi == null || this.rapport.etatEnvoi == ConfigConstants.ETAT_ENVOI_INIT)) {

      this.actionsItem[0].items.push({
        label: this.translate.getElement('RAPPORT_EXPERTISE.ACTIONS.GENERER_SYNTHESE'),
        icon: 'inc-icon inc-icon-load is-small',
        command: event => {
          this.submitGenererSynthese();
          window.scrollTo(0, 0);
        }
      });

      this.actionsItem[0].items.push({
        label: this.translate.getElement('RAPPORT_EXPERTISE.ACTIONS.ENVOYER_SYNTHESE'),
        icon: 'inc-icon inc-icon-arrow is-small',
        visible: this.existDosSynthese,
        command: event => {
          this.confirmation.confirm({
            message: this.translate.getElement('RAPPORT_EXPERTISE.POPUP_CONFIRMATION_ENVOYER_SYNTHESE'),
            header: this.translate.getElement('RAPPORT_EXPERTISE.POPUP_CONFIRMATION_ENVOYER_HEADER'),
            icon: 'pi pi-exclamation-triangle',
            accept: () => {
              this.submitSyntheseForValidation();
              window.scrollTo(0, 0);
            }
          });
        }
      });

    }


    this.actionsItem[0].items.push(
      {
        label: this.translate.getElement('RAPPORT_EXPERTISE.ACTIONS.GENERER_RAPPORT'),
        icon: 'inc-icon inc-icon-load is-small',
        command: event => {
          this.confirmation.confirm({
            message:this.translate.getElement('RAPPORT_EXPERTISE.POPUP_CONFIRMATION_GENERATION'),
            header :  this.translate.getElement('RAPPORT_EXPERTISE.ACTIONS.GENERER_RAPPORT')?.toUpperCase(),
            icon: 'pi pi-exclamation-triangle',
            accept: () => {
              this.submitGenererRapport();
              window.scrollTo(0,0);
            }
          })

        }
      }
    );

    //Precondition pour afficher le bouton de l'envoi du livrable(rapport)
    let visible : boolean = isAuthorize && this.existDocRapport &&  (
      (this.rapport?.synthese && this.rapport.etatEnvoi === ConfigConstants.ETAT_ENVOI_SYNTHESE)
      || (!this.rapport?.synthese && this.rapport.etatEnvoi === ConfigConstants.ETAT_ENVOI_INIT)
    );

    this.actionsItem[0].items.push(
      {
        label: this.translate.getElement('RAPPORT_EXPERTISE.ACTIONS.ENVOYER_RAPPORT'),
        icon: 'inc-icon inc-icon-arrow is-small',
        visible : visible,
        command: event => {
          this.confirmation.confirm({
            message: this.translate.getElement('RAPPORT_EXPERTISE.POPUP_CONFIRMATION_ENVOYER_RAPPORT'),
            header: this.translate.getElement('RAPPORT_EXPERTISE.POPUP_CONFIRMATION_ENVOYER_HEADER'),
            icon: 'pi pi-exclamation-triangle',
            accept: () => {
              this.submitSendRapportForValidation();
              window.scrollTo(0,0);
            }
          });
        }
      }
    );

    this.actionsItem[0].items.push({
      label: this.translate.getElement('RAPPORT_EXPERTISE.ACTIONS.OUVRIR_FICHE_DOSSIER'),
      icon: 'pi pi-eye',
      routerLink : ['/dossier/fiche', this.rapport?.idDossier]
    });


  }

  /**
   * Menu de droite pour la redirection vers les sections
   */
  navigationContextuelle() {
    this.anchorItem.push({
      label: this.translate.getElement('RAPPORT_EXPERTISE.SECTIONS'),
      items: [
        {
          label: this.translate.getElement('RAPPORT_EXPERTISE.SYNTHESE'),
          command : event => {
            this.scroolElement('generalite', event);
            this.addIsactiveNavigationContextuelleMenuItem(event);
          }
        },
        {
          label: this.translate.getElement('RAPPORT_EXPERTISE.MISSION'),
          command : event => {
            this.scroolElement('mission', event);
            this.addIsactiveNavigationContextuelleMenuItem(event);
          }
        },
        {
          label: this.translate.getElement('RAPPORT_EXPERTISE.DESIGNATION'),
          command :(event) => {
            this.scroolElement('designation', event);
            this.addIsactiveNavigationContextuelleMenuItem(event);
          }
        },
        {
          label: this.translate.getElement('RAPPORT_EXPERTISE.SITUATION_GEO'),
          command : event => {
            this.scroolElement('situationGeo', event);
            this.addIsactiveNavigationContextuelleMenuItem(event);
          }
        },
        {label: this.translate.getElement('RAPPORT_EXPERTISE.DESCRIPTION'),
          command : event => {
            this.scroolElement('description', event);
            this.addIsactiveNavigationContextuelleMenuItem(event)
          }
        },
        {
          label: this.translate.getElement('RAPPORT_EXPERTISE.ENVIRONNEMENT_URBANISME'),
          command : event => {
            this.scroolElement('envUrbanisme', event);
            this.addIsactiveNavigationContextuelleMenuItem(event);
          }
        },
        {
          label: this.translate.getElement('RAPPORT_EXPERTISE.CODITION_OCCUPATION'),
          command : event => {
            this.scroolElement('cdtOccup', event);
            this.addIsactiveNavigationContextuelleMenuItem(event);
          }
        },
        {
          label: this.translate.getElement('RAPPORT_EXPERTISE.ETUDE_MARCHE'),
          command : event => {
            this.scroolElement('etudeMarche', event);
            this.addIsactiveNavigationContextuelleMenuItem(event);
          }
        },
        {
          label: this.translate.getElement('RAPPORT_EXPERTISE.ESTIMATION'),
          command : event => {
            this.scroolElement('estimation-rappot', event);
            this.addIsactiveNavigationContextuelleMenuItem(event);
          }
        },
        {
          label: this.translate.getElement('RAPPORT_EXPERTISE.CONCLUSION'),
          command : event => {
            this.scroolElement('conclusion', event);
            this.addIsactiveNavigationContextuelleMenuItem(event);
          }
        },
        {
          label: this.translate.getElement('RAPPORT_EXPERTISE.ANNEXES'),
          command : event => {
            this.scroolElement('annexe', event);
            this.addIsactiveNavigationContextuelleMenuItem(event);
          }
        }
      ]
    });

  }

  scroolElement(idElement : string, event : any) {
    const yOffset = -15;
    const y =  document.getElementById(idElement).getBoundingClientRect().top + window.pageYOffset + yOffset;
    window.scrollTo({top: y, behavior: 'smooth'});
    event.originalEvent.target.parentNode.classList.add("is-active");
  }


  addIsactiveNavigationContextuelleMenuItem(event : any) {
    if(this.stock && this.stock.item.label !== event.item.label) {
      this.stock.originalEvent.target.parentNode.classList.remove("is-active")
      event.originalEvent.target.parentNode.classList.add("is-active");
      this.stock = event;
    }else {
      event.originalEvent.target.parentNode.classList.add("is-active");
      this.stock = event;
    }
  }

  ngAfterViewChecked() {
    this.cdref.detectChanges();
  }

  addDocument(documents: Document[]): void {
    this.getPhotoGarde();
  }

  genererSynthese() {
    if (this.form.get("synthese").invalid || this.form.get("conclusion").invalid || this.documentGarde.lienImage == null
      || this.rapport.typeSurfaceRapport == null || this.rapport.valueSurface == null)  {
      this.messages =[ {severity:'error', summary : this.translate.getElement('RAPPORT_EXPERTISE.MESSAGE.CHAMPS_OBLIGATOIRE')}];
      this.spinnerService.hide();
      return null;
    }
    return this.rapportExpertiseService.genererSynthese(this.rapport.idDossier);
  }

  verifyValidationSynthese() {

    let conclusion = this.form.get('conclusion');

    if(
      this.form.get("synthese").invalid
      || conclusion?.get('valeurVenal')?.invalid
      || conclusion?.get('valeurLocative')?.invalid
      || conclusion?.get('valeurApresTravaux')?.invalid
      || conclusion?.get('valeurFuturAchevement')?.invalid

      || conclusion?.get('regimeFiscalVenal')?.invalid
      || conclusion?.get('regimeFiscalLocatif')?.invalid
      || conclusion?.get('regimefiscalApresTraveaux')?.invalid
      || conclusion?.get('regimefiscalFuturAchevement')?.invalid

      || conclusion?.get('tauxFiscaliteVenal')?.invalid
      || conclusion?.get('tauxFiscaliteApresTravaux')?.invalid
      || conclusion?.get('tauxFiscaliteEtatFuturAchevement')?.invalid
      || conclusion?.get('delaiPrecision')?.invalid
      || this.documentGarde.lienImage == null
      || this.rapport.typeSurfaceRapport == null || this.rapport.valueSurface == null
    ) {
      this.messages =[ {severity:'error', summary : this.translate.getElement('RAPPORT_EXPERTISE.MESSAGE.CHAMPS_OBLIGATOIRE')}];
      this.spinnerService.hide();
      return false;
    }

    return true;
  }


  submitGenererSynthese() {
    this.submittedRequiredSynthese = true;
    var obs = this.prepareSauvegarder();
    if (obs != null) {
      obs.subscribe(data => {
        if(this.verifyValidationSynthese()) {
          this.rapportExpertiseService.genererSynthese(this.rapport.idDossier).subscribe(data => {
            window.scrollTo(0,0);
            this.spinnerService.hide();
            if (data) {
              this.messageStorage.saveMessage('RAPPORT_EXPERTISE.MESSAGE.GENERER_SYNTHESE_SUCCES', this.rapport.numeroDossier.toString() );
              window.location.reload();
            } else {
              this.messages =[ {severity:'error', summary : this.translate.getElement('RAPPORT_EXPERTISE.MESSAGE.GENERER_SYNTHESE_ERROR', this.rapport.numeroDossier.toString())}];
            }
          }, error => {
            window.scrollTo(0,0);
            this.messages =[ {severity:'error', summary : this.translate.getElement('RAPPORT_EXPERTISE.MESSAGE.GENERER_SYNTHESE_ERROR', this.rapport.numeroDossier.toString())}];
            this.spinnerService.hide();
          });
        }

      }, error => {
        this.messages =[ {severity:'error', summary : this.translate.getElement('RAPPORT_EXPERTISE.MESSAGE.SAVE_ERROR')}];
        this.spinnerService.hide();
      })
    } else {
      this.spinnerService.hide();
    }
  }

  /**
   * genère le rapport
   */
  genererRapport() {
    if (this.form.invalid || this.documentGarde.lienImage == null) {
      this.messages =[ {severity:'error', summary : this.translate.getElement('RAPPORT_EXPERTISE.MESSAGE.CHAMPS_OBLIGATOIRE')}];
      this.spinnerService.hide();
      return null;
    }
    return this.rapportExpertiseService.genererRapport(this.rapport.idDossier);
  }

   submitGenererRapport() {
    this.submittedRequiredSynthese = true;
    this.submittedRequired = true;
     this.generateRapport = false;
    var obs = this.prepareSauvegarder();
    if (obs != null) {
      obs.subscribe(data => {
        if (this.form.invalid || this.documentGarde.lienImage == null) {
          this.messages =[ {severity:'error', summary : this.translate.getElement('RAPPORT_EXPERTISE.MESSAGE.CHAMPS_OBLIGATOIRE')}];
          this.spinnerService.hide();
          return;
        }
        this.rapportExpertiseService.genererRapport(this.rapport.idDossier).subscribe(data => {
          window.scrollTo(0,0);
          this.spinnerService.hide();
          if (data) {
            this.messageStorage.saveMessage('RAPPORT_EXPERTISE.MESSAGE.GENERER_RAPPORT_SUCCES', this.rapport.numeroDossier.toString(), ConfigConstants.RAPPORT_EXPERTISE);
            window.location.reload();
          } else {
            this.messages =[ {severity:'error', summary : this.translate.getElement('RAPPORT_EXPERTISE.MESSAGE.GENERER_RAPPORT_ERROR', this.rapport.numeroDossier.toString())}];
          }
        }, error => {
          window.scrollTo(0,0);
          this.messages =[ {severity:'error', summary : this.translate.getElement('RAPPORT_EXPERTISE.MESSAGE.GENERER_RAPPORT_ERROR', this.rapport.numeroDossier.toString())}];
          this.spinnerService.hide();
        })
      }, error => {
        this.messages =[ {severity:'error', summary : this.translate.getElement('RAPPORT_EXPERTISE.MESSAGE.SAVE_ERROR')}];
        this.spinnerService.hide();
      })
    } else {
      this.spinnerService.hide();
    }
   }

   rapportIsVenale() {
     return (this.rapport?.paramTypeValeur?.idTypeValeur == 1 || this.rapport?.paramTypeValeur?.idTypeValeur == 3 || this.rapport?.paramTypeValeur?.idTypeValeur == 5);
   }

   rapportIsLocative() {
     return (this.rapport?.paramTypeValeur?.idTypeValeur == 2 || this.rapport?.paramTypeValeur?.idTypeValeur == 5);
   }

   rapportIsApresTravaux() {
    return (this.rapport?.paramTypeValeur?.idTypeValeur == 3);
   }

   rapportIsFuturAchevement() {
     return (this.rapport?.paramTypeValeur?.idTypeValeur == 4);
   }

  async docsContainsFilename(idBien: number) {
    const docs = await firstValueFrom(
      this.documentService.getDocumentsDossier(idBien, false).pipe(take(1))
    )
    .then(data => {
      if (data) {
         this.existDosSynthese = data.some(doc => doc.nom.startsWith('Synthese') && doc.nom.includes(this.rapport.numeroDossier));
         this.existDocRapport = data.some(doc => doc.nom.startsWith('Rapport') && doc.nom.includes(this.rapport.numeroDossier));
         let doc : Document =  data.find(doc => doc.nom.startsWith('Synthese') && doc.nom.includes(this.rapport.numeroDossier));
         if(doc) {
           this.guidDocSynthese = doc.nom;
         }
      }
    });
  }

  private isSyntheseSendingForValidation() : boolean {
    return this.rapport?.etatEnvoi !== null && this.rapport?.etatEnvoi !== ConfigConstants.ETAT_ENVOI_INIT;
  }

  downloadRappotDocument() {
    this.messageService.clear();
    this.documentService.downloadDocumentByType(this.rapport.idBien, ConfigConstants.RAPPORT_EXPERTISE).subscribe( {
      next : (response : HttpResponse<ArrayBuffer>) => {
        if (response != null && response?.body != null) {
          const fileName = UtilsFonction.getFilenameFromResponse(response, "RAPPORT" , this.rapport?.numeroDossier);
          const blob = new Blob([response.body], { type: 'application/pdf' });
          saveAs(blob, fileName)
        }else {
          this.messages = [ {severity:'error', summary :  this.translate.getElement("DOCUMENT.TELECHARGEMENT.ERROR")}];
        }
      } ,
      error : () => {
        this.messages = [ {severity:'error', summary :  this.translate.getElement("DOCUMENT.TELECHARGEMENT.ERROR")}];
      }
     });

  }


  closableRapportSucessMessage() {
    this.messageSuccessGenerationRapport = null;
  }
}
