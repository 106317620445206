import {Injectable} from '@angular/core';

export class Settings {

    constructor(
      public pagination: {
        first : number,
        rows : number,
        rowsPerPageOptions : number [],
        pageLinkSize : number,
        rowsPerPageOptionsDocument : number [],
        rowsDocument : number
      }
    ) {}

}

@Injectable()
export class AppSettings {
    public settings = new Settings(
        {
          first: 0,
          rows : 50,
          rowsDocument : 10,
          rowsPerPageOptionsDocument : [10, 25, 50],
          rowsPerPageOptions: [50, 100, 150],
          pageLinkSize : 5
        }
    )
}
