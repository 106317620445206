import {ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {Bien} from "../../../models/dossiers/creation-demande";
import {AbstractControl, FormBuilder, FormGroup, FormGroupDirective} from "@angular/forms";
import {DialogService, DynamicDialogRef} from "primeng/dynamicdialog";
import {ParamTypeBien} from "../../../models/bien/param-type-bien";
import {BienService} from "../../../service/bien.service";
import {ReferentielValeur} from "../../../models/referentiel-valeur";
import {FicheDossierObsevableService} from "../../../../shared/observable/fiche-dossier-obsevable.service";
import {Adresse} from "../../../models/adresse";
import {AdresseComponent} from "../../../../shared/adresse/adresse.component";
import {User} from "../../../models/user/user.model";
import {ProfilesUser} from "../../../models/user/profiles-user";
import {DocumentService} from "../../../service/document.service";
import {Document} from "../../../models/document/document.model";
import {UtilsFonction} from "../../../../shared/providers/utils-fonction";
import {DomSanitizer} from "@angular/platform-browser";
import {ConfirmationService, MessageService} from "primeng/api";
import {TranslateService} from "../../../../shared/translate/translate.service";
import {NgxSpinnerService} from "ngx-spinner";
import {Dossier} from "../../../models/dossiers/dossier";

@Component({
  selector: 'app-bien-dossier',
  templateUrl: './bien-dossier.component.html',
  styleUrls: ['./bien-dossier.component.scss']
})
export class BienDossierComponent implements OnInit {


  @Input()
  formGroupName: string;

  @Input()
  bien : Bien;

  @Input()
  submitted : boolean;

  bienForm: FormGroup;

  @Input()
  typeBiens :  ParamTypeBien[];

  @Input()
  etages : ReferentielValeur[];

  @Input()
  user : User;

  addr : Adresse;

  adresseComplet : string;

  disabledAdresseButton : boolean = false;

  displayMapGoogle : boolean = true;
  documentCarte : Document;

  @Input()
  idDossier : number;


  constructor(
    private fb : FormBuilder,
    private bienService : BienService,
    public dialogService: DialogService,
    public ref: DynamicDialogRef,
    private rootFormGroup: FormGroupDirective,
    private cdref: ChangeDetectorRef,
    private fichierDossierObserv : FicheDossierObsevableService,
    private documentService : DocumentService,
    private sanitizer: DomSanitizer,
    private messageService: MessageService,
    private translate : TranslateService,
    private spinnerService: NgxSpinnerService,
    private confirmation: ConfirmationService
  ) {
      if(typeof google === 'undefined') {
        this.displayMapGoogle = false;
      }
    }

  ngOnInit(): void {

    this.bienForm = this.rootFormGroup.control.get(this.formGroupName) as FormGroup;

    this.addr = this.bien?.adresse;

    this.adressCompletBien(this.addr);

    if(this.bienForm.status === "DISABLED" ) {
      this.disabledAdresseButton = true;
    }

    this.fichierDossierObserv.onClientInfo().subscribe( adresse => {
      if(adresse) {
       if(this.bien.identiqueClient && this.bienForm.value.customerAdress === 'idClient') {
         this.bienForm.get('adresse').setValue(adresse);
         this.adressCompletBien(adresse);
         this.bienForm.get('adresse.adresseFormate').setValue(adresse?.adresseFormate);
       }
      }
    });

    this.getPhotoCarte();
  }


  /**
   *
   */
  choiceCustomerAdress() {

    if(!this.bien.identiqueClient && this.bienForm.value.customerAdress === 'idClient') {
      this.fichierDossierObserv.onClientInfo().subscribe( adresse => {
        if(adresse) {
          this.bienForm.get('adresse').setValue(adresse);
          this.adressCompletBien(adresse);
          this.bienForm.get('adresse.adresseFormate').setValue(adresse?.adresseFormate);
          this.bienForm.get('adresse.adresseChange').setValue(true);
        }
      });
    }else if(this.bien.identiqueClient && this.bienForm.value.customerAdress === 'autre'){
      this.bienForm.get('adresse.adresseFormate').setValue(null);
      this.adresseComplet = null;
    }else if(this.bien.identiqueClient && this.bienForm.value.customerAdress === 'idClient') {
       this.getClientAdresse();
    }else {
      this.adressCompletBien(this.bien.adresse);
      this.bienForm.get('adresse.adresseFormate').setValue(this.bien.adresse.adresseFormate);
    }

  }

  /**
   * Ajouter l'adresse
   * @param adresse
   */
  addAdress(adresse? : Adresse) {

    this.ref = this.dialogService.open(AdresseComponent, {
      header: "Adresse",
      width: '50vw',
      baseZIndex: 10000,
      data : {adresse : adresse},
      dismissableMask: true,
      styleClass : 'p-dynamic-dialog'
    });
    this.ref.onClose.subscribe((adress : any) =>{
      if (adress) {

        this.bienForm.get('adresse.adresseFormate').setValue(adress.adresseFormate);
        this.bienForm.get('adresse.numero').setValue(adress.numero);
        this.bienForm.get('adresse.lieuDit').setValue(adress.lieuDit);
        this.bienForm.get('adresse.nomVoie').setValue(adress.nomVoie);
        this.bienForm.get('adresse.codePostal').setValue(adress.codePostal);
        this.bienForm.get('adresse.ville').setValue(adress.ville);
        this.bienForm.get('adresse.pays').setValue(adress.pays);
        this.bienForm.get('adresse.boitePostale').setValue(adress.boitePostale);
        this.bienForm.get('adresse.complement').setValue(adress.complement);
        this.bienForm.get('adresse.lng').setValue(adress.lng);
        this.bienForm.get('adresse.lat').setValue(adress.lat);
        this.bienForm.get('adresse.adresseChange').setValue(adress.adresseChange);
        this.addr =  adress;

        this.adressCompletBien(this.addr);
      }
    });
  }

  adressCompletBien(addresse : Adresse) {

    this.adresseComplet =  '';

    if(addresse?.numero) {
      this.adresseComplet = addresse?.numero + ' '
    }
    if(addresse?.nomVoie) {
      this.adresseComplet =  this.adresseComplet + addresse?.nomVoie + ', ';
    }
    if(addresse?.codePostal) {
      this.adresseComplet =  this.adresseComplet + ' ' + addresse?.codePostal + ' ';
    }
    if(addresse?.ville) {
      this.adresseComplet =  this.adresseComplet + ' ' + addresse?.ville + ', ';
    }
    if(addresse?.pays) {
      this.adresseComplet =  this.adresseComplet + addresse?.pays?.libelle;
    }

    if(addresse.complement) {
      this.adresseComplet = this.adresseComplet + addresse.complement + ', ';
    }

  }

  get f(): { [key: string]: AbstractControl } {
    return this.bienForm.controls;
  }

  getClientAdresse() {
    this.fichierDossierObserv.onClientInfo().subscribe( adresse => {
      if(adresse) {
        this.bienForm.get('adresse').setValue(adresse);
        this.adressCompletBien(adresse);
        this.bienForm.get('adresse.adresseFormate').setValue(adresse?.adresseFormate);
      }
    });
  }

  isProfilAuthorize() : boolean {
    return this.user?.profiles && (this.user?.profiles?.includes(ProfilesUser.ADMINISTRATEUR) || this.user?.profiles?.includes(ProfilesUser.ADMINISTRATEUR_TECHNIQUE)
      || this.user?.profiles?.includes(ProfilesUser.ASSISTANTE)
      || this.user?.profiles?.includes(ProfilesUser.ANIMATEUR_RESEAU));
  }

  getPhotoCarte() {
    this.documentService.findDocumentByIdbienAndCategorie(this.bien.idBien, 12).subscribe( doc => {
      if (doc.length > 0) {
        this.documentCarte =  doc.shift();
        this.creerLienImage(this.documentCarte);
      }
    });
  }

  /**
   * Permet de lire le byte en image
   * @param doc
   */
  creerLienImage(doc: Document) {
    if (doc && doc.contenu) {
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        doc.lienImage = this.sanitizer.bypassSecurityTrustUrl(reader.result as string);
      }, false);
      reader.readAsDataURL(UtilsFonction.b64toBlob(doc.contenu, doc.type) );
    }
  }

  addDocument(documents: Document[]): void {
    this.getPhotoCarte();
  }



  deleteImage(doc: Document) {
    this.confirmation.confirm({
      message: this.translate.getElement("DOCUMENT.SUPPRESSION.CONFIRMATION"),
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.spinnerService.show();
        if(doc?.guid) {
          this.documentService.deleteDossierRapportDocument(this.bien?.idBien, doc?.guid, this.idDossier).subscribe(data => {
            if (data) {
              this.getPhotoCarte();
              this.messageService.add({key: 'uploadPhotoCarte', severity: 'success', summary: this.translate.getElement("DOCUMENT.SUPPRESSION.SUCCESS")});
            } else {
              this.messageService.add({key: 'uploadPhotoCarte', severity: 'error', summary: this.translate.getElement("DOCUMENT.SUPPRESSION.ERROR")});
            }
            this.spinnerService.hide();
          }, error => {
            this.messageService.add({key: 'uploadPhotoCarte', severity: 'error', summary: this.translate.getElement("DOCUMENT.SUPPRESSION.ERROR")});
            this.spinnerService.hide();
          })
        }

      }
    })
  }

}
