<h2  id="expert">{{'DOSSIER.EXPERT' | translateI18n}}</h2>
<div class="card">
  <div class="grid">
    <div class="col">
      <p><b class="locked">{{expert?.prenomExpert}} {{expert?.nomExpert}}</b></p>

      <p class="locked">
        {{expert?.adressExpert?.numero}} {{expert?.adressExpert?.nomVoie}}<br>
        {{expert?.adressExpert?.codePostal}} {{expert?.adressExpert?.ville}}</p>

    </div>

    <div class="col">
      <p class="locked"><a href="mailto:{{expert?.emailExpert}}">{{expert?.emailExpert}}</a></p>
      <p class="locked"><span class="label">{{'DOSSIER.DEMANDE.CREATION.TELEPHONE' | translateI18n}} :
              </span><a >{{expert?.telephoneExpert ? expert?.telephoneExpert : ' -- ' }}</a></p>

      <p ><span class="label">Profil  :
              </span><a >{{expert?.profilsExpert | userProfil}}</a></p>
    </div>

  </div>
</div>
