<form [formGroup]="form" >

  <div class="card-large" id="section1">
    <h1 id="etudeMarche">VII - {{'RAPPORT_EXPERTISE.ETUDE_MARCHE' | translateI18n}}</h1>

    <div class="grid">

      <div class="col-12">
        <h2>{{'RAPPORT_EXPERTISE.ANALYSE_MARCHE' | translateI18n}}</h2>

        <div class="col-12">
          <section>
            <div class="field field-line">

              <div class="mb-2">
                <small class="obligatoire">{{'RAPPORT_EXPERTISE.MESSAGE.MAX_CARACTERE' | translateI18n : '4000'}}
                  <span [ngClass]="{'is-alert':  f.analysemarche.hasError('hasError')}">({{nbAnalyseMarche}} / 4000)</span>
                </small>
              </div>

              <div *ngIf="f.analysemarche.hasError('hasError')">
                <p-messages  [(value)]="msgErrorMaxcaract" key="analysemarche"></p-messages>
              </div>

              <p-editor formControlName="analysemarche" [style]="{'height':'320px'}" name="analysemarche"
                        id="analysemarche" class="w-full"
                        [ngClass]="{'editor-error': f.analysemarche.hasError('hasError')
                         || (submittedRequired && ( f.analysemarche?.errors?.required || f.analysemarche.hasError('hasEmptyCaracterError')) )}"
                        (onTextChange)="numanalysemarcheTextChange($event)">
                <p-header>
                  <app-p-editor-header></app-p-editor-header>
                </p-header>
              </p-editor>

              <small *ngIf="submittedRequired && ( f.analysemarche?.errors?.required || f.analysemarche.hasError('hasEmptyCaracterError'))" class="p-error block">{{'MESSAGE.ERROR.CHAMPS_OBLIGATOIRE' | translateI18n}}</small>
            </div>

            <div class="field field-line mt-4">
              <app-table-reference-venales [idDossier]="rapport?.idDossier"></app-table-reference-venales>
            </div>


          </section>
        </div>

      </div>

      <div class="col-12" *ngIf="this.rapport?.paramTypeValeur?.idTypeValeur === 2 || this.rapport?.paramTypeValeur?.idTypeValeur === 5 || this.rapport?.methodeValorisationRapport?.idMethodecalcul === 2 || this.rapport?.methodeValorisationRapport?.idMethodecalcul === 3">
        <h2>{{'RAPPORT_EXPERTISE.ANALYSE_MARCHE_LOCATIF' | translateI18n}}</h2>

        <div class="col-12">
          <section>
            <div class="field field-line">

              <div class="mb-2">
                <small>{{'RAPPORT_EXPERTISE.MESSAGE.MAX_CARACTERE' | translateI18n : '4000'}}
                  <span [ngClass]="{'is-alert':  f.analysemarcheLocatif.hasError('hasError')}">({{nbAnalysemarcheLocatif}} / 4000)</span>
                </small>
              </div>

              <div *ngIf="f.analysemarcheLocatif.hasError('hasError')">
                <p-messages  [(value)]="msgErrorMaxcaract" key="analysemarcheLocatif"></p-messages>
              </div>

              <p-editor formControlName="analysemarcheLocatif" [style]="{'height':'320px'}" name="analysemarcheLocatif"
                        id="analysemarcheLocatif" class="w-full"
                        [ngClass]="{'editor-error': f.analysemarcheLocatif.hasError('hasError')}"
                        (onTextChange)="numanalysemarcheLocatifTextChange($event)">
                <p-header>
                  <app-p-editor-header></app-p-editor-header>
                </p-header>
              </p-editor>
            </div>
          </section>
        </div>

        <div class="field field-line mt-4">
          <app-table-reference-locative [idDossier]="rapport?.idDossier"></app-table-reference-locative>
        </div>

      </div>

      <div class="col-12">
        <h2>{{'RAPPORT_EXPERTISE.POSITIONNEMENT_BIEN_MARCHE' | translateI18n}}</h2>

        <div class="col-12">
          <section>
            <div class="field field-line">

              <div class="mb-2">
                <small class="obligatoire">{{'RAPPORT_EXPERTISE.MESSAGE.MAX_CARACTERE' | translateI18n : '2000'}}
                  <span [ngClass]="{'is-alert':  f.positionnement.hasError('hasError')}">
                    ({{nbPositionnement}} / 2000)</span></small>
              </div>

              <div *ngIf="f.positionnement.hasError('hasError')">
                <p-messages  [(value)]="msgErrorMaxcaract" key="positionnement"></p-messages>
              </div>

              <p-editor formControlName="positionnement" [style]="{'height':'235px'}" name="positionnement"
                        id="positionnement" class="w-full"
                        [ngClass]="{'editor-error': f.positionnement.hasError('hasError')
                        || (submittedRequired && (f.positionnement?.errors?.required || f.positionnement.hasError('hasEmptyCaracterError') ) ) }"
                        (onTextChange)="numPositionnementTextChange($event)">
                <p-header>
                  <app-p-editor-header></app-p-editor-header>
                </p-header>
              </p-editor>

              <small *ngIf="submittedRequired && (f.positionnement?.errors?.required || f.positionnement.hasError('hasEmptyCaracterError') )" class="p-error block">{{'MESSAGE.ERROR.CHAMPS_OBLIGATOIRE' | translateI18n}}</small>
            </div>
          </section>
        </div>

      </div>

    </div>

  </div>

</form>
