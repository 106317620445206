<form [formGroup]="form" >

  <div class="card-large" id="section1">
    <h1 id="envUrbanisme">V - {{'RAPPORT_EXPERTISE.ENVIRONNEMENT_URBANISME' | translateI18n}}</h1>
    <div class="grid">

      <div class="col-12">
        <h2>{{'RAPPORT_EXPERTISE.DIAGNOSTIQUE_ENV' | translateI18n}}</h2>
        <section>
          <div class="field input-ghost">
            <label for="dpe" class="label">{{ 'RAPPORT_EXPERTISE.LABEL.DPE' | translateI18n}} :</label>
            <p-dropdown [options]="dpes" formControlName="dpe"
                        optionLabel="libelle" name="dpe"  id="dpe"
                        [ngClass]="{'ng-dirty ng-invalid': submittedRequired && f.dpe.errors?.required}"
                        emptyMessage="{{'DOSSIER.MESSAGE.AUCUN_RESULTAT' | translateI18n}}"
                        placeholder="{{'PLACEHOLDER.CHOOSE' | translateI18n}}" class="obligatoire">
            </p-dropdown>
            <small *ngIf="submittedRequired && f.dpe?.errors?.required" class="p-error block mr-4">
              {{'MESSAGE.ERROR.CHAMPS_OBLIGATOIRE' | translateI18n}}
            </small>
          </div>

          <div class="field field-line mt-3">
            <label  class="label">{{'RAPPORT_EXPERTISE.LABEL.AUTRES_DIAGNOSTIQUES' | translateI18n}}</label>
            <div class="">
              <small>{{'RAPPORT_EXPERTISE.MESSAGE.MAX_CARACTERE' | translateI18n : '1000'}}
                <span [ngClass]="{'is-alert':  f.dpeCommentaire.hasError('hasError')}">({{nbDpeCommentaire}} / 1000)</span>
              </small>
            </div>

            <div *ngIf="(submitted || submittedRequired) && f.dpeCommentaire.hasError('hasError')">
              <p-messages  [(value)]="message" key="dpeCommentaire"></p-messages>
            </div>

            <p-editor formControlName="dpeCommentaire" [style]="{'height':'170px'}" name="dpeCommentaire"
                      id="dpeCommentaire" class="w-full"
                      [ngClass]="{'editor-error': f.dpeCommentaire.hasError('hasError')}"
                      (onTextChange)="numDpeCommentaireTextChange($event)">
              <p-header>
                <app-p-editor-header></app-p-editor-header>
              </p-header>
            </p-editor>
          </div>
        </section>
      </div>


      <div class="col-12">
        <h2 class="obligatoire">{{'RAPPORT_EXPERTISE.URBANISME' | translateI18n}}</h2>
        <section>
          <div class="field field-line">

            <div class="mb-2">
              <small>{{'RAPPORT_EXPERTISE.MESSAGE.MAX_CARACTERE' | translateI18n : '3500'}}
                <span [ngClass]="{'is-alert':  f.urbanisme.hasError('hasError')}">({{nbUrbanisme}} / 3500)</span>
              </small>
            </div>

            <div *ngIf="(submitted || submittedRequired) && f.urbanisme.hasError('hasError')">
              <p-messages  [(value)]="message" key="urbanisme"></p-messages>
            </div>

            <p-editor formControlName="urbanisme" [style]="{'height':'320px'}" name="urbanisme"
                      id="urbanisme" class="w-full"
                      [ngClass]="{'editor-error': f.urbanisme.hasError('hasError')
                       ||  (submittedRequired && (f.urbanisme?.errors?.required || f.urbanisme.hasError('hasEmptyCaracterError')))}"
                      (onTextChange)="numUrbanismeTextChange($event)">
              <p-header>
                <app-p-editor-header></app-p-editor-header>
              </p-header>
            </p-editor>

            <small *ngIf="submittedRequired && (f.urbanisme?.errors?.required || f.urbanisme.hasError('hasEmptyCaracterError'))" class="p-error block">{{'MESSAGE.ERROR.CHAMPS_OBLIGATOIRE' | translateI18n}}</small>
          </div>
        </section>
      </div>


      <div class="col-12">
        <h2>{{'RAPPORT_EXPERTISE.PPR' | translateI18n}}</h2>
        <section>
          <div class="field field-line">

            <div class="mb-2">
              <small>{{'RAPPORT_EXPERTISE.MESSAGE.MAX_CARACTERE' | translateI18n : '1000'}}
                <span [ngClass]="{'is-alert':  f.risquesEnvironnement.hasError('hasError')}">({{nbRisquesEnvironnement}} / 1000)</span>
              </small>
            </div>

            <div *ngIf="(submitted || submittedRequired) && f.risquesEnvironnement.hasError('hasError')">
              <p-messages  [(value)]="message" key="risquesEnvironnement"></p-messages>
            </div>

            <p-editor formControlName="risquesEnvironnement" [style]="{'height':'170px'}" name="urbanisme"
                      id="risquesEnvironnement" class="w-full"
                      [ngClass]="{'editor-error': f.risquesEnvironnement.hasError('hasError')}"
                      (onTextChange)="numRisquesEnvironnementTextChange($event)">
              <p-header>
                <app-p-editor-header></app-p-editor-header>
              </p-header>
            </p-editor>
          </div>
        </section>
      </div>


    </div>

  </div>

</form>
