import { Injectable } from '@angular/core';
import {RestDatasource} from "../../core/service/rest.datasource";
import {Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class MonitoringService {

  constructor(private datasource : RestDatasource) { }

  listFiles(path: string){
    return this.datasource.listFiles(path);
  }
  downloadFile(path: string){
    return this.datasource.downloadFile(path);
  }
}
