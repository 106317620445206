export class FileValidation {


  static isExtension(fileName : string)  {

    const extensions  = [
      '.docm','.docx','.dot','.dotm','.dotx','.odp','.odp','.ods','.odt','.one','.pot','.potm','.potx','.pps','.ppsm','.ppsx','.ppt','.pptx','.pptm',
      '.xlsb','.xlsm','.xlsx','.pdf','.jpg','.jpeg','.png','.bmp','.gif'
    ];

    for(let i = 0; i <= extensions.length; i++) {
      if(fileName.toLowerCase().endsWith(extensions[i])) {
        return true;
      }
    }

    return false;
  }


  static isExtensionImage(fileName : string)  {

    const extensions  = ['.jpg','.jpeg','.png','.bmp','.gif'];

    for(let i = 0; i <= extensions.length; i++) {
      if(fileName.toLowerCase().endsWith(extensions[i])) {
        return true;
      }
    }

    return false;
  }

}
