import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-steps-nav',
  templateUrl: './steps-nav.component.html',
  styleUrls: ['./steps-nav.component.scss']
})
export class StepsNavComponent implements OnInit {

  @Input()
  stepsList : any[];

  constructor() { }

  ngOnInit(): void {
  }




}
