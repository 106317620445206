
<h2 >{{'DOSSIER.ADVANCED_SEARCH.LABEL.PERIODE' | translateI18n}}</h2>

<div class="grid formgrid" [formGroup]="form">
  <div class="field col-12 md:col-6">
    <label for="periodeDateDebut">{{'DOSSIER.ADVANCED_SEARCH.LABEL.DU' | translateI18n}}</label>
    <div class=" w-full">
      <p-calendar formControlName="periodeDateDebut" id="periodeDateDebut" [showIcon]="true" dateFormat="dd/mm/yy" inputId="icon" class="inputfield w-full"  [firstDayOfWeek]="1"></p-calendar>
    </div>
  </div>
  <div class="field col-12 md:col-6">
    <label for="periodeDateFin">{{'DOSSIER.ADVANCED_SEARCH.LABEL.AU' | translateI18n}}</label>
    <div class=" w-full">
      <p-calendar formControlName="periodeDateFin" id="periodeDateFin" [showIcon]="true" dateFormat="dd/mm/yy" inputId="icon" class="inputfield w-full"  [firstDayOfWeek]="1"></p-calendar>
    </div>

  </div>
</div>


