import {APP_INITIALIZER, LOCALE_ID, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {ConfirmationService, MessageService} from "primeng/api";
import {RouterModule} from '@angular/router';
import {CurrencyPipe, DatePipe, registerLocaleData, UpperCasePipe} from "@angular/common";
import {UserModule} from "./features/user/user.module";
import {AuthService} from "./core/service/auth/auth.service";
import {RestDatasource} from "./core/service/rest.datasource";
import {AuthGuard} from "./core/service/auth/auth.guard";
import {ProfileService} from "./core/service/user/profile.service";
import {DocumentService} from "./core/service/document.service";
import {HTTP_INTERCEPTORS, HttpClientModule} from "@angular/common/http";
import {DashbordModule} from "./features/dashbord/dashbord.module";
import {DossierModule} from "./features/dossier/dossier.module";
import {TranslateService} from "./shared/translate/translate.service";
import {DialogService, DynamicDialogConfig, DynamicDialogRef} from "primeng/dynamicdialog";
import {FormGroupDirective} from "@angular/forms";
import {IconStatutDossierPipe} from "./shared/pipes/icon-statut-dossier.pipe";
import localeFr from '@angular/common/locales/fr';
import {OAuthModule, OAuthStorage} from "angular-oauth2-oidc";
import {SsoAuthInterceptor} from "./core/service/auth/sso-auth.interceptor";
import {AppSettings} from "./app.settings";
import {AuthInterceptor} from "./core/service/auth/auth.interceptor";
import {SharedModule} from "./shared/shared.module";
import {TooltipModule} from "primeng/tooltip";
import {DropdownModule} from "primeng/dropdown";
import {AvisValeurModule} from "./features/avis-valeur/avis-valeur.module";
import {CoreModule} from "./core/core.module";
import {DateFormatPipePipe} from "./shared/pipes/date-format-pipe.pipe";
import {RapportExpertiseModule} from "./features/rapport-expertise/rapport-expertise.module";
import {CaseWordPipe} from "./shared/pipes/case-word.pipe";
import {StatutDossierPipe} from "./shared/pipes/statut-dossier.pipe";
import { MonitoringModule } from './features/monitoring/monitoring.module';


registerLocaleData(localeFr);

export function storageFactory(): OAuthStorage {
  return localStorage
}

@NgModule({
  declarations: [
    AppComponent
  ],
    imports: [
        RouterModule,
        BrowserModule,
        AppRoutingModule,
        HttpClientModule,
        //CoreModule,
        UserModule,
        DashbordModule,
        DossierModule,
        OAuthModule.forRoot(),
        SharedModule,
        TooltipModule,
        DropdownModule,
        AvisValeurModule,
        CoreModule,
      RapportExpertiseModule,
      MonitoringModule    ],
  providers: [
    RestDatasource,
    AuthService,
    AuthGuard,
    MessageService,
    ConfirmationService,
    ProfileService,
    DatePipe,
    DocumentService,
    DialogService,
    UpperCasePipe,
    TranslateService,
    CaseWordPipe,
    StatutDossierPipe,
    {
      provide: APP_INITIALIZER,
      useFactory: setupTranslateFactory,
      deps: [TranslateService],
      multi: true
    },
    { provide: LOCALE_ID, useValue: "fr-FR" },
    DynamicDialogRef,
    DynamicDialogConfig,
    FormGroupDirective,
    IconStatutDossierPipe,
    DateFormatPipePipe,
    CurrencyPipe,
    {provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: SsoAuthInterceptor, multi: true },
    { provide: OAuthStorage, useFactory: storageFactory},
    AppSettings
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }


export function setupTranslateFactory(service: TranslateService): Function {
  let lang = service.getLanguage() ? service.getLanguage() : 'fr';
  service.setLanguage(lang);
  return () => service.use();
}

