import {ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {RapportExpertise} from "../../../models/rapport-expertise/rapport-expertise";
import {AbstractControl, FormBuilder, FormControl, FormGroup, FormGroupDirective, Validators} from "@angular/forms";
import {ConfirmationService, Message, MessageService} from "primeng/api";
import {ConfigSizeInput} from "../../../../shared/providers/config-size-input";
import {DialogService, DynamicDialogRef} from "primeng/dynamicdialog";
import {TranslateService} from "../../../../shared/translate/translate.service";
import {DatePipe} from "@angular/common";
import {RapportExpertiseObservableService} from "../../../../shared/observable/rapport-expertise-observable.service";
import {RapportExpertiseService} from "../../../service/rapport-expertise.service";
import {ReferentielValeur} from "../../../models/referentiel-valeur";
import {AppSettings, Settings} from "../../../../app.settings";
import {BienDesserte} from "../../../models/bien/bien-desserte";
import {UtilsFonction} from "../../../../shared/providers/utils-fonction";
import {Document} from "../../../models/document/document.model";
import {DocumentService} from "../../../service/document.service";
import {DomSanitizer} from "@angular/platform-browser";
import {NgxSpinnerService} from "ngx-spinner";

@Component({
  selector: 'app-situation-geographique-rapport',
  templateUrl: './situation-geographique-rapport.component.html',
  styleUrls: ['./situation-geographique-rapport.component.scss']
})
export class SituationGeographiqueRapportComponent implements OnInit {

  @Input()
  rapport : RapportExpertise;
  @Input()
  formGroupName: string;
  @Input()
  submitted : boolean;
  @Input()
  submittedRequired : boolean;

  form: FormGroup;

  msgError : Message[];
  secteurs : ReferentielValeur[];

  configSizeInput : ConfigSizeInput;
  nbLocalisation : number = 0;

  dessertes : ReferentielValeur[];

  nbDesserte : number = 0;

  bienDesserteForm : FormGroup;

  bienDesserteDialog: boolean;
  submittedBienDesserte : boolean;

  bienDessertes : BienDesserte[];

  bienDesserte : BienDesserte;

  public settings: Settings;

  messageBienDesserte : Message[];
  messageSuppressionBienDesserte : Message[];

  documentEnv : Document[];
  message : Message[];

  documentCarte : Document;

  showCommentaire : boolean = false;


  commentaire : string = "Votre commentaire ici"

  nbCaractDescriptionDesserte : number = 0;

  progressSpinner : boolean = true;

  displayMapGoogle : boolean = true;

  constructor(
    private fb : FormBuilder,
    public dialogService: DialogService,
    public ref: DynamicDialogRef,
    private rootFormGroup: FormGroupDirective,
    private cdref: ChangeDetectorRef,
    private messageService: MessageService,
    private translate : TranslateService,
    private datePipe: DatePipe,
    private rapportExpertiseObservableService : RapportExpertiseObservableService,
    private rapportExpertiseService : RapportExpertiseService,
    private confirmation: ConfirmationService,
    public appSettings : AppSettings,
    private documentService : DocumentService,
    private sanitizer: DomSanitizer,
    private spinnerService: NgxSpinnerService
    ) {
    this.configSizeInput = new ConfigSizeInput();
    this.settings = this.appSettings.settings;
    if(typeof google === 'undefined') {
      this.displayMapGoogle = false;
    }
  }

  ngOnInit(): void {
    this.form = this.rootFormGroup.control.get(this.formGroupName) as FormGroup;

    if(this.form.value?.localisation?.length > 0) {
      this.nbLocalisation = this.form?.value?.localisation ? this.form?.value?.localisation?.replace(/<[^>]*>/g, '')?.length : 0;
    }

    if(this.form.value?.desserte?.length > 0) {
      this.nbDesserte = this.form?.value?.desserte ? this.form?.value?.desserte?.replace(/<[^>]*>/g, '')?.length : 0;
    }

    this.msgError = [ {key : 'localisation', severity:'error', summary:  this.translate.getElement("RAPPORT_EXPERTISE.MESSAGE.ERROR_CARACTERE_COMMENTAIRE", '4000')}];

    this.msgError = [ {key : 'desserte', severity:'error', summary:  this.translate.getElement("RAPPORT_EXPERTISE.MESSAGE.ERROR_CARACTERE_COMMENTAIRE", '1000')}];

    this.getAllSecteurs();
    this.getAllDessertes();
    this.getAllBienDesserteByBienId();
    this.initBienDesserteForm(null);
    this.getPhotosEnvironnement();
    this.getPhotoCarte();
  }

  get f(): { [key: string]: AbstractControl } {
    return this.form.controls;
  }

  get fBieDess(): { [key: string]: AbstractControl } {
    return this.bienDesserteForm.controls;
  }

  getAllSecteurs() {
    this.rapportExpertiseService.getAllSecteurs().subscribe( data => {
      this.secteurs = data;
    })
  }

  getAllDessertes() {
    this.rapportExpertiseService.getAllDessertes().subscribe( data => {
      this.dessertes = data;
    })
  }

  numLocalisationTextChange(event: any) {
    this.nbLocalisation = event?.htmlValue ? event?.htmlValue?.replace(/<[^>]*>/g, '')?.trim()?.length : 0;
  }

  editDesserte(desserte: any) {
    this.nbCaractDescriptionDesserte = 0;
    this.messageBienDesserte = null;
    this.bienDesserte = {...desserte};
    this.initBienDesserteForm(this.bienDesserte);
    this.nbCaractDescriptionDesserte = desserte?.description?.length;
    this.bienDesserteDialog = true;
  }

  deleteDesserte(desserte: BienDesserte) {
    if(desserte) {
      this.confirmation.confirm({
        header: this.translate.getElement("RAPPORT_EXPERTISE.MESSAGE.ETES_VOUS_SUR"),
        message : this.translate.getElement("RAPPORT_EXPERTISE.MESSAGE.CONFIRMER"),
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          this.rapportExpertiseService.deleteBienDesserte(desserte.id, this.rapport?.idDossier).then( data => {
            if(data) {
              this.getAllBienDesserteByBienId();
              this.messageSuppressionBienDesserte =[ {severity:'success', summary:  this.translate.getElement("RAPPORT_EXPERTISE.MESSAGE.SUCESS_SUPPRESSION")}];
              return;
            }
            this.messageSuppressionBienDesserte =[ {severity:'error', summary:  this.translate.getElement("RAPPORT_EXPERTISE.MESSAGE.ERROR_SUPPRESSION")}];
          }).catch( error => {
            this.messageSuppressionBienDesserte =[ {severity:'error', summary:  this.translate.getElement("RAPPORT_EXPERTISE.MESSAGE.ERROR_SUPPRESSION")}];
          })
        }
      })

    }
  }

  openNew() {
    this.nbCaractDescriptionDesserte = 0;
    this.initBienDesserteForm(null);
    this.bienDesserte = null;
    this.submittedBienDesserte = false;
    this.bienDesserteDialog = true;
  }

  numDesserteTextChange(event: any) {
    this.nbDesserte = event?.htmlValue ? event?.htmlValue?.replace(/<[^>]*>/g, '')?.trim()?.length : 0;
  }


  initBienDesserteForm(bienDesserte : BienDesserte) {
    this.bienDesserteForm = this.fb.group({
      desserteTransport : new FormControl(bienDesserte?.desserte ? bienDesserte?.desserte : null, Validators.required),
      description : new FormControl(bienDesserte?.description ? bienDesserte?.description :null, Validators.maxLength(130)),
    });
  }

  hideDialog() {
    this.bienDesserteDialog = false;
    this.submittedBienDesserte = false;
  }


  async getAllBienDesserteByBienId() {
    this.bienDessertes = await this.rapportExpertiseService.getAllBienDesserteByBienId(this.rapport.idBien);
  }

  saveBienDesserte() {

    this.submittedBienDesserte = true;

    if(this.bienDesserteForm.invalid) {
     this.messageBienDesserte =[ {severity:'error', summary:  this.translate.getElement("RAPPORT_EXPERTISE.MESSAGE.ERROR_FORM")}];
      return;
    }

    const bieD : BienDesserte = new BienDesserte();

    if(this.bienDesserte) {
      bieD.id = this.bienDesserte?.id;
    }

    const value = this.bienDesserteForm.value;
    bieD.description = value.description;
    bieD.desserte = value.desserteTransport;
    bieD.idBien = this.rapport?.idBien;
    bieD.idDossier = this.rapport?.idDossier;

    this.rapportExpertiseService.saveBieDesserte(bieD).subscribe( data => {
      if(data) {
        this.hideDialog();
        this.getAllBienDesserteByBienId();
      }else {
        this.messageBienDesserte =[ {severity:'error', summary:  this.translate.getElement("RAPPORT_EXPERTISE.MESSAGE.ERROR_MESSAGE_MODAL")}];
        this.submittedBienDesserte = false;
      }
    }, error => {
      this.messageBienDesserte =[ {severity:'error', summary:  this.translate.getElement("RAPPORT_EXPERTISE.MESSAGE.ERROR_MESSAGE_MODAL")}];
      this.submittedBienDesserte = false;
    })
  }

  detectChangeComment(event: any) {
    this.nbCaractDescriptionDesserte = String(event).length;
  }

  addDocument(documents: Document[]): void {
    this.reloadPictures(documents?.shift()?.categorie?.id);
  }

  /**
   * Recupère les images de catégorie envrionnement
   */
  getPhotosEnvironnement() {
     this.documentService.findDocumentByIdbienAndCategorie(this.rapport.idBien, 2).subscribe( doc => {
        if (doc.length > 0) {
          this.documentEnv = doc;
          this.documentEnv.forEach(d => this.creerLienImage(d));
          this.progressSpinner = false;
        }else {
          this.documentEnv = null;
          this.progressSpinner = false;
        }
     });
  }

  getPhotoCarte() {
    this.documentService.findDocumentByIdbienAndCategorie(this.rapport.idBien, 12).subscribe( doc => {
      if (doc.length > 0) {
        this.documentCarte =  doc.shift();
        this.creerLienImage(this.documentCarte);
        this.progressSpinner = false;
      }else {
        this.documentCarte = null;
        this.progressSpinner = false;
      }
    });
  }

  /**
   * Permet de lire le byte en image
   * @param doc
   */
  creerLienImage(doc: Document) {
    if (doc && doc.contenu) {
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        doc.lienImage = this.sanitizer.bypassSecurityTrustUrl(reader.result as string);
      }, false);
      reader.readAsDataURL(UtilsFonction.b64toBlob(doc.contenu, doc.type) );
    }
  }


  deleteImage(doc: Document) {
    this.confirmation.confirm({
      message: this.translate.getElement("DOCUMENT.SUPPRESSION.CONFIRMATION"),
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.spinnerService.show();
        if(doc?.guid) {
          this.documentService.deleteDossierRapportDocument(this.rapport?.idBien, doc?.guid, this.rapport?.idDossier).subscribe(data => {
            if (data) {

              if(doc?.categorie?.id === 2) {
                this.getPhotosEnvironnement();
                this.messageService.add({key: 'uploadPhotoEnv', severity: 'success', summary: this.translate.getElement("DOCUMENT.SUPPRESSION.SUCCESS")});
              }
              else if(doc?.categorie?.id === 12) {
                this.getPhotoCarte();
                this.messageService.add({key: 'uploadPhotoCarte', severity: 'success', summary: this.translate.getElement("DOCUMENT.SUPPRESSION.SUCCESS")});
              }

            } else {
              this.messageDeletedPicture(doc?.categorie?.id);
            }
            this.spinnerService.hide();
          }, error => {
            this.messageDeletedPicture(doc?.categorie?.id);
            this.spinnerService.hide();
          })
        }

      }
    })
  }

  reloadPictures(idCategorie : number) {
    if(idCategorie === 2) {
      this.getPhotosEnvironnement();
    }

    if(idCategorie === 12) {
      this.getPhotoCarte();
    }
  }


  messageDeletedPicture(categorie : number) {

    if(categorie === 2) {
      this.messageService.add({key: 'uploadPhotoEnv', severity: 'error', summary: this.translate.getElement("DOCUMENT.SUPPRESSION.ERROR")});
    }
    else if(categorie === 12) {
      this.messageService.add({key: 'uploadPhotoCarte', severity: 'error', summary: this.translate.getElement("DOCUMENT.SUPPRESSION.ERROR")});
    }

  }

}
