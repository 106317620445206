<p-fileUpload *ngIf="document?.lienImage && !addNewPhoto" #fileUpload mode="basic"
              [auto]="true" [customUpload]="true" [multiple]="multiple" name="file"
              (uploadHandler)="onUpload($event,fileUpload)"
              accept="image/*"
              pTooltip="Modifier" tooltipPosition="top"
              chooseLabel="{{choseLabel}}"
              chooseIcon="{{chooseIcon}}"
              styleClass="{{styleClassModifyPicture}}"></p-fileUpload>


<div class="upload-cta" *ngIf="!document?.lienImage && addNewPhoto" >
  <p-fileUpload #fileUpload mode="basic" [auto]="true" [customUpload]="true" name="file"
                [multiple]="multiple"
                (uploadHandler)="onUpload($event,fileUpload)"
                accept="image/*"
                [chooseLabel]="label"
                styleClass="p-button-sm p-button-secondary"
                chooseIcon="inc-icon inc-icon-add is-small"></p-fileUpload>
  <p>{{categorie}}</p>
 <!-- <p>{{format}}</p>-->
</div>

