<form [formGroup]="form" >
  <div class="card-large" id="section1">
    <h1 id="evaluation">VII - {{'AVIS_VALEUR.EVALUATION' | translateI18n}}</h1>

    <div class="grid">

      <div class="col-12">
        <section>

          <div class="field field-line input-inline">
            <h3 class="inline">{{'AVIS_VALEUR.LABEL.METHODOLOGIE' | translateI18n}} : </h3>
            <p-dropdown [options]="methodeValorisation" formControlName="methodeCalcul" optionLabel="label" id="natureBiens" name="natureBiens"
                        [ngClass]="{'ng-dirty ng-invalid': submittedRequired && f.methodeCalcul.errors}"
                        class="obligatoire"
                        emptyMessage="{{'AVIS_VALEUR.MESSAGE.AUCUN_RESULTAT' | translateI18n}}"
                        placeholder="{{'PLACEHOLDER.CHOOSE' | translateI18n}}"></p-dropdown>
          </div>

          <div class="field input-ghost">
            <div class="mb-2">
              <small class="obligatoire">{{'AVIS_VALEUR.MESSAGE.MAX_CARACTERE' | translateI18n : '1000'}}
                <span [ngClass]="{'is-alert':  f.descmethode.hasError('hasError')}">({{nbDescmethode}} / 1000)</span>
              </small>
            </div>
            <div *ngIf="submittedRequired && f.descmethode.hasError('hasError')">
              <p-messages  [(value)]="msgError"></p-messages>
            </div>
            <p-editor formControlName="descmethode" [style]="{'height':'220px'}" name="descmethode"  id="descmethode" class="w-full"
                      (onTextChange)="caractMethodeCalculTextChange($event)"
                      [ngClass]="{'editor-error': (submittedRequired && f.descmethode.errors) || f.descmethode.hasError('hasError')}">
              <p-header>
                <app-p-editor-header></app-p-editor-header>
              </p-header>
            </p-editor>
          </div>
        </section>

        <section>
          <h3>{{'AVIS_VALEUR.LABEL.CONCLUSION' | translateI18n}}</h3>


          <div *ngIf="typeValeur">
            <p>{{'AVIS_VALEUR.TEXTE.AVIS' | translateI18n}}</p>

            <ul>
              <!-- Valeur vénale-->
              <li *ngIf="typeValeur?.idTypeValeur === 1 || typeValeur?.idTypeValeur === 3 || typeValeur?.idTypeValeur === 5">
                <span class="field input-inline">{{typeValeur?.label1}}</span>
                <span class="field input-inline">
                    <p-inputNumber formControlName="valeurGlobaleEstime"
                                   inputId="valeurGlobaleEstime"  mode="currency"
                                   currency="EUR" locale="fr-FR"
                                   placeholder="{{'AVIS_VALEUR.LABEL.RENSEIGNER' | translateI18n}}"
                                   [size]=20 [min]="0" [maxlength]="18"
                                   [ngClass]="{'ng-dirty ng-invalid': submittedRequired && f.valeurGlobaleEstime.errors}"
                                   (onInput)="calculPrixM2IfValueChanged($event, 1)"
                                   class="obligatoire">
                    </p-inputNumber>
                </span>
                <span class="field input-inline">
                    <p-dropdown
                      [options]="regimeFiscalVenal"  formControlName="regimeFiscalVenal"
                      optionLabel="libelleCourt" name="regimeFiscalVenal"  id="regimeFiscalVenal"
                      [ngClass]="{'ng-dirty ng-invalid': submittedRequired && f.regimeFiscalVenal.errors}"
                      emptyMessage="{{'DOSSIER.MESSAGE.AUCUN_RESULTAT' | translateI18n}}"
                      placeholder="{{'PLACEHOLDER.CHOOSE' | translateI18n}}" class="obligatoire">
                    </p-dropdown>
                </span>

                <span class="field input-ghost">
                    {{'AVIS_VALEUR.LABEL.SOIT' | translateI18n}}
                    <p-inputNumber *ngIf="form?.value?.prixM2ValeurGlobaleEstime"
                                   formControlName="prixM2ValeurGlobaleEstime"
                                   inputId="prixM2ValeurGlobaleEstime"  mode="currency"
                                   currency="EUR" locale="fr-FR"
                                   [size]=13
                                   [readonly]="true"
                                   suffix="/m²"
                                    [disabled]="true">
                    </p-inputNumber>

                    <span *ngIf="!form?.value?.prixM2ValeurGlobaleEstime" class="ml-2 w-1 text-center">{{'  -- €'}}</span>
                </span>
              </li>


              <!-- Valeur locative-->
              <li *ngIf="typeValeur?.idTypeValeur === 2 || typeValeur?.idTypeValeur === 5">
                <span  *ngIf="typeValeur?.idTypeValeur === 2" class="field input-inline">{{typeValeur?.label1}}</span>
                <span  *ngIf="typeValeur?.idTypeValeur === 5" class="field input-inline">{{typeValeur?.label2}}</span>

                <span class="field input-inline">
                    <p-inputNumber formControlName="valeurLocative"
                                   inputId="valeurLocative"  mode="currency"
                                   currency="EUR" locale="fr-FR"
                                   [size]=20 [min]="0" [maxlength]="18"
                                   [ngClass]="{'ng-dirty ng-invalid': submittedRequired && f.valeurLocative.errors}"
                                   placeholder="{{'AVIS_VALEUR.LABEL.RENSEIGNER' | translateI18n}}"
                                   (onInput)="calculPrixM2IfValueChanged($event, 2)"
                                   class="obligatoire">
                    </p-inputNumber>
                </span>
                <span class="field input-inline">
                  <p-dropdown  [options]="regimeFiscalLocatif"
                               formControlName="regimeFiscalLocatif"
                               optionLabel="libelleCourt" name="regimeFiscalLocatif"
                               id="regimeFiscalLocatif"
                               [ngClass]="{'ng-dirty ng-invalid': submittedRequired && f.regimeFiscalLocatif.errors}"
                               emptyMessage="{{'DOSSIER.MESSAGE.AUCUN_RESULTAT' | translateI18n}}"
                               placeholder="{{'PLACEHOLDER.CHOOSE' | translateI18n}}" class="obligatoire"></p-dropdown>
                </span>

                <span class="field input-ghost">
                  {{'AVIS_VALEUR.LABEL.SOIT' | translateI18n}}
                    <p-inputNumber *ngIf="form?.value?.prixM2ValeurLocative" formControlName="prixM2ValeurLocative"
                                   inputId="prixM2ValeurLocative"  mode="currency"
                                   currency="EUR" locale="fr-FR"
                                   [size]=13
                                   [readonly]="true"
                                   suffix="/m²"
                                   class="lockedPrixM2" [disabled]="true">
                    </p-inputNumber>

                    <span *ngIf="!form?.value?.prixM2ValeurLocative" class="ml-2 w-1 text-center">{{'  -- €/m²'}}</span>

                </span>
              </li>



              <!-- Valeur apres travaux-->
              <li *ngIf="typeValeur?.idTypeValeur === 3">
                <span class="field input-inline">{{typeValeur?.label2}}</span>
                <span class="field input-inline">
                  <p-inputNumber formControlName="valeurApresTravaux"
                                 inputId="valeurApresTravaux"
                                 mode="currency" currency="EUR" locale="fr-FR"
                                 [size]=20 [min]="0" [maxlength]="18"
                                 [ngClass]="{'ng-dirty ng-invalid': submittedRequired && f.valeurApresTravaux.errors}"
                                 placeholder="{{'AVIS_VALEUR.LABEL.RENSEIGNER' | translateI18n}}"
                                 (onInput)="calculPrixM2IfValueChanged($event, 3)"
                                 class="obligatoire">
                  </p-inputNumber>
                </span>
                <span class="field input-inline">
                  <p-dropdown  [options]="regimeFiscalVenal"
                               formControlName="regimefiscalApresTraveaux"
                               optionLabel="libelleCourt" name="regimefiscalApresTraveaux"
                               id="regimefiscalApresTraveaux"
                               [ngClass]="{'ng-dirty ng-invalid': submittedRequired && f.regimefiscalApresTraveaux.errors}"
                               emptyMessage="{{'DOSSIER.MESSAGE.AUCUN_RESULTAT' | translateI18n}}"
                               placeholder="{{'PLACEHOLDER.CHOOSE' | translateI18n}}" class="obligatoire">
                  </p-dropdown>
                </span>
                <span class="field input-ghost">
                  {{'AVIS_VALEUR.LABEL.SOIT' | translateI18n}}
                   <p-inputNumber *ngIf="form?.value?.prixM2ValeurApresTravaux" formControlName="prixM2ValeurApresTravaux"
                                  inputId="prixM2ValeurApresTravaux"  mode="currency"
                                  currency="EUR" locale="fr-FR"
                                  [size]=13
                                  [readonly]="true"
                                  suffix="/m²"
                                  [disabled]="true">

                   </p-inputNumber>
                  <span *ngIf="!form?.value?.prixM2ValeurApresTravaux" class="ml-2 w-1 text-center">{{'-- €/m²'}}</span>
               </span>
             </li>

              <!-- Valeur etat futur achevement-->
              <li *ngIf="typeValeur?.idTypeValeur === 4">
                <span class="field input-inline">{{typeValeur?.label1}}</span>
                <span class="field input-inline">
                  <p-inputNumber formControlName="valeurFuturAchevement"
                                 inputId="valeurFuturAchevement"
                                 mode="currency" currency="EUR" locale="fr-FR"
                                 placeholder="{{'AVIS_VALEUR.LABEL.RENSEIGNER' | translateI18n}}"
                                 [size]=20 [min]="0" [maxlength]="18"
                                 [ngClass]="{'ng-dirty ng-invalid': submittedRequired && f.valeurFuturAchevement.errors}"
                                 (onInput)="calculPrixM2IfValueChanged($event,4)"
                                 class="obligatoire">
                  </p-inputNumber>
                </span>
                <span class="field input-inline">
                  <p-dropdown  [options]="regimeFiscalVenal"
                               formControlName="regimefiscalFuturAchevement"
                               optionLabel="libelleCourt" name="regimefiscalFuturAchevement"
                               id="regimefiscalFuturAchevement"
                               [ngClass]="{'ng-dirty ng-invalid': submittedRequired && f.regimefiscalFuturAchevement.errors}"
                               emptyMessage="{{'DOSSIER.MESSAGE.AUCUN_RESULTAT' | translateI18n}}"
                               placeholder="{{'PLACEHOLDER.CHOOSE' | translateI18n}}" class="obligatoire">
                  </p-dropdown>
                </span>
                <span class="field input-ghost disabled">
                  {{'AVIS_VALEUR.LABEL.SOIT' | translateI18n}}
                   <p-inputNumber *ngIf="form?.value?.prixM2ValeurFuturAchevement" formControlName="prixM2ValeurFuturAchevement"
                                  inputId="prixM2ValeurFuturAchevement"  mode="currency"
                                  currency="EUR" locale="fr-FR"
                                  [size]=13
                                  [readonly]="true"
                                  suffix="/m²"
                                   [disabled]="true">
                   </p-inputNumber>

                    <span *ngIf="!form?.value?.prixM2ValeurFuturAchevement" class="ml-2 w-1 text-center">{{'  -- €/m²'}}</span>
               </span>
              </li>

           </ul>

         </div>
       </section>

        <section *ngIf="typeValeur?.idTypeValeur === 1 || typeValeur?.idTypeValeur === 3 || typeValeur?.idTypeValeur === 5">
          <div class="field input-inline">
            <label for="liquidite" class="label">{{'AVIS_VALEUR.LABEL.LIQUIDITE_BIEN' | translateI18n}} :</label>
            <p-dropdown  [options]="liquidites"  formControlName="liquidite" optionLabel="libelle" name="liquidite"
                         id="liquidite"
                         [ngClass]="{'ng-dirty ng-invalid': submittedRequired && f.liquidite.errors}"
                         emptyMessage="{{'AVIS_VALEUR.AUCUN_RESULTAT' | translateI18n}}"
                         placeholder="{{'PLACEHOLDER.CHOOSE' | translateI18n}}" class="obligatoire"></p-dropdown>
          </div>

          <div class="field input-inline">
            <label for="delaiCommercialisation" class="label">{{'AVIS_VALEUR.LABEL.DELAI_COMMERCIALISATION' | translateI18n}} :</label>
            <p-dropdown  [options]="delaiCom"  formControlName="delaiCommercialisation" optionLabel="libelle" name="delaiCommercialisation"
                         id="delaiCommercialisation"
                         [ngClass]="{'ng-dirty ng-invalid': submittedRequired && f.delaiCommercialisation.errors}"
                         emptyMessage="{{'AVIS_VALEUR.AUCUN_RESULTAT' | translateI18n}}"
                         placeholder="{{'PLACEHOLDER.CHOOSE' | translateI18n}}" class="obligatoire"></p-dropdown>
          </div>

          <div class="field input-inline" *ngIf="avisValeur?.typeTiers === 'TTBANQUE' ">
            <label for="decote" class="label">{{'AVIS_VALEUR.LABEL.AB_PREV_VENTE_JUDICIAIRE' | translateI18n}} :</label>
            <p-inputNumber formControlName="decote"
                           id="decote"
                           class="oblig"
                           placeholder="{{'AVIS_VALEUR.LABEL.RENSEIGNER' | translateI18n}}"
                           [ngClass]="{'ng-dirty ng-invalid': submittedRequired && (abbatValueError || f.decote.errors)}"
                           [useGrouping]="false"
                           [size]="10"
                           suffix="%" [min]="0" [maxlength]="4" (onInput)="onChangeAbbatValue($event)"></p-inputNumber>
          </div>
          <small *ngIf="abbatValueError" class="p-error block">{{'AVIS_VALEUR.MESSAGE.ERROR_ABAT' | translateI18n}}</small>
        </section>


       <section>
         <p class="font-italic font-medium text-justify">
          {{'AVIS_VALEUR.TEXTE.UTILISATION' | translateI18n}}
         </p>
       </section>


       <section>
         <div class="field input-ghost">
           <p>
             {{'AVIS_VALEUR.FAIT_A' | translateI18n}}
             <span class="field input-inline">
                     <p-dropdown  [options]="etablissements"  formControlName="etablissement" optionLabel="libelle" name="etablissement"
                                  id="etablissement" [ngClass]="{'ng-dirty ng-invalid': submittedRequired && f.etablissement.errors}"
                                  emptyMessage="{{'AVIS_VALEUR.AUCUN_RESULTAT' | translateI18n}}"
                                  placeholder="{{'PLACEHOLDER.CHOOSE' | translateI18n}}" class="obligatoire"></p-dropdown>
             </span>
             {{'AVIS_VALEUR.LE' | translateI18n}} <span class="field input-inline"> {{currentDate | dateFormatPipe}}</span>
           </p>
         </div>

         <div class="grid mt-5 ">
           <div class="col-6 lg:col-3">
             <div class="field input-ghost">
               <p class="font-semibold mb-0"> {{avisValeur?.nomCompletExpert}}</p>
               <p>{{'AVIS_VALEUR.EXPERT' | translateI18n}}</p>
             </div>
           </div>
          </div>

        </section>

      </div>

    </div>


  </div>
</form>
