import {Component, OnInit} from '@angular/core';
import {Dossier} from "../../models/dossiers/dossier";
import {DossierService} from "../../service/dossier.service";
import {TranslateService} from "../../../shared/translate/translate.service";
import {DynamicDialogConfig, DynamicDialogRef} from "primeng/dynamicdialog";
import {User} from "../../models/user/user.model";
import {ProfilesUser} from "../../models/user/profiles-user";
import {Message} from "primeng/api";

@Component({
  selector: 'app-duplication-dossier',
  templateUrl: './duplication-dossier.component.html',
  styleUrls: ['./duplication-dossier.component.scss']
})
export class DuplicationDossierComponent implements OnInit {


  dossier : Dossier;
  user : User;
  valueTypeLivrable : string;
  codeLivrables : any[] = [];
  agents : User[] = [];

  agentActif : boolean = true;

  messageReject: Message[];

  messageTypeLivrable : Message[];

  messageAucunAgentActif : Message[];

  selectBoxAgentActifVisible = false

  agent : User;

  filteredAgent : any[];
  isGestionnaireORAdmin : boolean;

  display : boolean =  false;


  constructor(
    private dossierService : DossierService, private translate : TranslateService,
    public ref: DynamicDialogRef,public config: DynamicDialogConfig
  ) { }

  ngOnInit(): void {

      this.dossier = this.config.data?.dossier;
      this.user = this.config.data?.user;
      this.getTypeLivrableUser();
      this.isGestionnaireORAdmin = this.checkIfUserConnectedISGestionnaireOrAdmin();
      if(this.user != null && this.isGestionnaireORAdmin) {
           this.checkIfAgentCreateDossierIsInactif(this.dossier?.idDossier);
      }

  }


  checkIfUserConnectedISGestionnaireOrAdmin() {
    return this.user.profiles.includes(ProfilesUser.ASSISTANTE) || this.user.profiles.includes(ProfilesUser.ANIMATEUR_RESEAU) ||
            this.user.profiles.includes(ProfilesUser.ADMINISTRATEUR) || this.user.profiles.includes(ProfilesUser.ADMINISTRATEUR_TECHNIQUE)
  }

  hideDialog() {
    this.close();
  }

  /**
   * Recupère le type de livrable
   */
  async getTypeLivrableUser() {
    console.log(this.dossier.idDossier);
    await this.dossierService.getTypeLivrableUser(this.dossier.idDossier).then(tl => {

      if(tl && tl.length > 0) {
        this.codeLivrables = tl.map(item => ({
          code: item.code,
          name: this.translate.getElement('DOSSIER.' + item.code)
        }))
      }else {
        this.messageTypeLivrable = [ {severity:'warn', summary:  this.translate.getElement("DOSSIER.GESTIONNAIRE.MESSAGE.TYPE_LIVRABLE_ERROR", this.dossier?.numeroDossier)}];
      }

    });
    this.codeLivrables.sort((a, b) => a.code.localeCompare(b.code));
    this.display = true;
    if (this.codeLivrables && this.codeLivrables.length === 1) {
      this.valueTypeLivrable = this.codeLivrables[0].code;
    }
  }


  close(data? : any) {
    this.display = false;
    this.ref.close(data);
  }

  dupliquer() {
    let oo = this.isGestionnaireORAdmin && !this.agentActif &&  !this.agent;
    if(!this.valueTypeLivrable) {
        this.messageReject = [ {severity:'error', summary: this.translate.getElement("DOSSIER.GESTIONNAIRE.MESSAGE.DUPPLIQUER_ERROR")}];
        return;
    }

    if(oo) {
      this.messageReject = [ {severity:'error', summary: this.translate.getElement("DOSSIER.GESTIONNAIRE.MESSAGE.CHOIX_AGENT")}];
      return;
    }

    this.close({valueTypeLivrable : this.valueTypeLivrable, agent : this.agent});
  }

  getListAgentByAgence(idDossier: number)  {
    this.dossierService.getListAgentByAgence(idDossier).subscribe(
      users => {
        this.agents = users;
      }, error =>  {

      }
    )
  }

  async checkIfAgentCreateDossierIsInactif(idDossier: number)  {
    await this.dossierService.checkIfAgentCreateDossierIsInactif(idDossier).then(
     actif => {
       this.agentActif = actif;
       if(!this.agentActif) {
         this.getListAgentByAgence(this.dossier?.idDossier);
       }
       console.log(this.agentActif);
     }
   );
  }

  searchAgentUser(event : any) {

    let filtered : any[] = [];
    let query = event.query;
    if(this.agents.length > 0) {
      this.agents.forEach(agent => {
        if (agent.nom.toLowerCase().indexOf(query.toLowerCase()) === 0 ||
          agent.prenom.toLowerCase().indexOf(query.toLowerCase()) === 0) {
          agent['nomComplet'] = agent.prenom + ' ' + agent.nom;
          filtered.push(agent);
        }
      })
      this.filteredAgent = filtered;
      this.selectBoxAgentActifVisible = false
    }else{
      this.messageAucunAgentActif = [ {severity:'warn', summary:  this.translate.getElement("DOSSIER.GESTIONNAIRE.MESSAGE.AUCUN_AGENT_ACTIF")}];
      this.selectBoxAgentActifVisible = true

    }
  }

}
