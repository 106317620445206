<main class="layout-wrapper">
  <app-navigation></app-navigation>
  <app-header *ngIf="dossier" titre="{{dossier.numeroDossier}} {{dossier.client.nom ? dossier.client.nom  : dossier.client.nomMoral}}"
              sstitre="{{dossier.statut | statutDossier}}" [state]="dossier.statut" sstitre2="{{dossier.infoExpertise?.typeLivrable?.code | typeLivrable}}"></app-header>
  <app-navigation-contextuelle  [AnchorItem]="anchorItem" [ActionsItem]="actionsItem"></app-navigation-contextuelle>
  <div class="layout-main have-secondary-nav" id="main-layout" *ngIf="dossier">
    <div class="page is-centered">

      <p-messages *ngIf="message" [(value)]="message" [enableService]="false" [escape]="false"></p-messages>

      <!-- Steps -->
      <div class="steps">
        <app-steps-nav [stepsList]="this.stepsList"></app-steps-nav>
      </div>
      <!-- End steps -->

      <!-- Resume -->
      <h2  id="demandeur">{{'DOSSIER.DEMANDE.CREATION.DEMANDEUR' | translateI18n}}</h2>
      <div class="card">
        <div class="grid">
          <div class="col">
            <p><b class="locked">{{dossier.demande?.prenomDemandeur}} {{dossier.demande?.nomDemandeur}}</b></p>
            <p class="locked" *ngIf="dossier.tiers?.adresse?.numero">{{dossier.tiers?.adresse?.numero}} {{dossier.tiers?.adresse?.nomVoie}}<br>
              {{dossier.tiers?.adresse?.codePostal}} {{dossier.tiers?.adresse?.ville}}</p>
            <p class="locked" *ngIf="dossier.tiers?.entiteClient">
              <span class="label">{{'DOSSIER.DEMANDE.CREATION.ENTITE' | translateI18n}} : </span>
              {{dossier.tiers?.entiteClient}}<br>
            </p>
            <p *ngIf="dossier.tiers?.entiteClient === 'MRC'" class="locked">
              <span class="label">{{'DOSSIER.DEMANDE.CREATION.PRETEUR' | translateI18n}} : </span>
              {{dossier.demande?.paramPreteur?.libelle}}</p>
            <p *ngIf="dossier.tiers?.raisonSociale" class="locked"><span class="label">{{'DOSSIER.DEMANDE.CREATION.RATTACHEMENT' | translateI18n}} : </span>
              {{dossier.tiers?.raisonSociale}}</p>

          </div>

          <div class="col">
            <p *ngIf="dossier.demande?.mailDemandeur" class="locked"><a href="mailto:{{dossier.demande?.mailDemandeur}}">{{dossier.demande?.mailDemandeur}}</a></p>
            <p class="locked" *ngIf="dossier.tiers?.mail"><a href="mailto:{{dossier.tiers?.mail}}">{{dossier.tiers?.mail}}</a></p>
            <p *ngIf="dossier.demande?.telephone" class="locked"><span class="label">{{'DOSSIER.DEMANDE.CREATION.TELEPHONE' | translateI18n}} :
            </span><a href="{{dossier.demande?.telephone}}">{{dossier.demande?.telephone}}</a></p>
          </div>

        </div>


      </div>

      <h2  id="client">{{'DOSSIER.DEMANDE.CREATION.AFFAIRE' | translateI18n}}</h2>
      <div class="card">
        <div class="grid">
          <div class="col">

            <p *ngIf="dossier.client?.isClientPhysique"><b class="locked">
              <span>{{dossier.client?.abreviationCivilite}}</span> {{dossier.client?.prenom}} {{dossier.client?.nom}}</b>
            </p>

            <p *ngIf="!dossier.client?.isClientPhysique"><b class="locked">{{dossier.client?.nomMoral}}</b></p>
            <p *ngIf="!dossier.client?.isClientPhysique"><b class="locked">{{dossier.client?.nomRepresentant}}</b></p>

            <p class="locked">{{dossier.client?.adresse?.numero}} {{dossier.client?.adresse?.nomVoie}}<br>
              {{dossier.client?.adresse?.codePostal}} {{dossier.client?.adresse?.ville}}</p>

            <p class="locked"><span class="label">{{'DOSSIER.DEMANDE.CREATION.CONTRAT' | translateI18n}} :
            </span> {{dossier.client?.numeroContrat}}</p>
            <p class="locked"><span class="label">{{'DOSSIER.DEMANDE.CREATION.FRAIS_EXPERTISE' | translateI18n}} :
            </span> {{dossier.client?.ir | fraisExpertise}}</p>
          </div>

          <div class="col">
            <p class="locked"><a href="mailto:{{dossier.client?.mail1}}">{{dossier.client?.mail1}}</a></p>

            <p class="locked"><a href="mailto:{{dossier.client?.mail2}}">{{dossier.client?.mail2}}</a></p>

            <p class="locked"><span class="label">{{'DOSSIER.DEMANDE.CREATION.PHONE' | translateI18n : '1'}} :
            </span><a href="tel:{{dossier.client?.telephone1}}">{{dossier.client?.telephone1}}</a></p>

            <p *ngIf="dossier.client.telephone2" class="locked"><span class="label">{{'DOSSIER.DEMANDE.CREATION.PHONE' | translateI18n : '2'}} :
            </span><a href="tel:{{dossier.client?.telephone2}}">{{dossier.client?.telephone2}}</a></p>

          </div>
        </div>
      </div>

      <h2  id="contact">{{'DOSSIER.DEMANDE.CREATION.CONTACT' | translateI18n}}</h2>
      <div class="card">
        <div class="grid">
          <div class="col">

            <p class="locked"><span class="label">{{'DOSSIER.DEMANDE.CREATION.QUALITE_CONTACT' | translateI18n }}
            </span>{{dossier.demande.qualiteContact.libelle}}</p>

            <p *ngIf="dossier.demande.contactPhysique"><b class="locked">
              <span>{{dossier.demande?.abreviationCiviliteContact}}</span> {{dossier.demande?.prenomContact}} {{dossier.demande?.nomContact}}</b></p>

            <p *ngIf="!dossier.demande.contactPhysique"><b class="locked">
              {{dossier.demande?.raisonSocialeContact}}</b></p>


            <p class="locked">{{dossier.adresseContact?.numero}} {{dossier.adresseContact?.nomVoie}}<br>
              {{dossier.adresseContact?.codePostal}} {{dossier.adresseContact?.ville}}</p>

          </div>

          <div class="col">

            <p class="locked"><a href="mailto:{{dossier.demande?.mail1Contact}}">
              {{dossier.demande?.mail1Contact}}</a></p>

            <p class="locked">
              <a href="mailto:{{dossier.demande?.mail2Contact}}">{{dossier.demande?.mail2Contact}}</a></p>

            <p class="locked"><span class="label">{{'DOSSIER.DEMANDE.CREATION.PHONE' | translateI18n : '1'}} :
            </span><a href="tel:{{dossier.demande?.telephoneContact}}">{{dossier.demande?.telephoneContact}}</a></p>

            <p class="locked"><span class="label">{{'DOSSIER.DEMANDE.CREATION.PHONE' | translateI18n : '2'}}  :
            </span><a href="tel:{{dossier.demande?.portableContact}}">{{dossier.demande?.portableContact ? dossier.demande?.portableContact : '--'}}</a></p>

          </div>

        </div>
      </div>

      <h2  id="bien">{{'DOSSIER.DEMANDE.CREATION.BIEN_EXPERTISER' | translateI18n}}</h2>

      <div class="grid grid-card">
        <div class="col-12 " style="height: 319.5px;" [ngClass]="{ 'lg:col-6': displayMapGoogle}">

          <div class="card">
            <p class="locked"><span class="label">{{'DOSSIER.DEMANDE.CREATION.TYPE_BIEN' | translateI18n }}  :
            </span>{{dossier.bien?.typeBien}}</p>

            <p  class="locked"><span class="label">{{'DOSSIER.DEMANDE.CREATION.ETAGE' | translateI18n }} :</span>
              {{dossier.bien?.etage ? dossier.bien?.etage : '--'}}</p>

            <p class="locked">
              {{dossier.bien?.adresse?.numero}} {{dossier.bien?.adresse?.nomVoie}}<br>
              {{dossier.bien?.adresse?.codePostal}} {{dossier.bien?.adresse?.ville}}</p>
          </div>


        </div>

        <div class="col-12 lg:col-6" *ngIf="displayMapGoogle">
          <div class="card-map" style="height:100%;">
            <app-carte-google-maps [adresse]="dossier.bien?.adresse"></app-carte-google-maps>
          </div>

        </div>
      </div>

      <app-liste-document id="document" [idBien]=dossier.bien?.idBien [statutDossier]="dossier.statut"
                          [idDossier]="dossier.idDossier" [tierID]="dossier?.tiers?.id" [verouillerExpert]="true"></app-liste-document>

      <h2  id="commentaire">{{'DOSSIER.DEMANDE.CREATION.COMMENTAIRE' | translateI18n}}</h2>
      <div class="card">
        <div class="grid">
          <div class="col">
            <p>{{dossier.demande?.observation}}</p>
          </div>
        </div>
      </div>

    </div>

    <!-- End resume -->


  </div>

</main>

