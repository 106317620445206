import {AfterViewInit, Component, Input, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {Subject} from "rxjs";
import {GoogleMapServiceService} from "../../core/service/googleMaps/google-map-service.service";
import {Adresse} from "../../core/models/adresse";

@Component({
  selector: 'app-google-place-autocomplete',
  templateUrl: './google-place-autocomplete.component.html',
  styleUrls: ['./google-place-autocomplete.component.scss']
})
export class GooglePlaceAutocompleteComponent implements OnInit,  AfterViewInit {

  @Input() adressType: string = 'geocode';
  @ViewChild('addresstext', { static: true }) addresstext: any;
  @Input() adresseInput: Adresse;
  @Input() displayMapGoogle : boolean = true;
  adresseFormate : string


  adresse : Adresse;

  public placeSubject: Subject<google.maps.places.PlaceResult> = new Subject<google.maps.places.PlaceResult>();

  public placeObservable = this.placeSubject.asObservable();

  constructor(private serviceGoogleMaps : GoogleMapServiceService) {
  }

  ngOnInit() {

    if(this.adresseInput) {
      this.adresse = this.adresseInput;
     // if(this.adresseInput?.lng && this.adresseInput?.lat) {
        this.setFormatAdresse(this.adresseInput);
      //}
    }
  }

  ngAfterViewInit() {
    this.serviceGoogleMaps.getPlaceAutocomplete(this.addresstext);
  }

  /**
   * MAJ l'adresse formattée
   * @param adresseInput
   */
  setFormatAdresse(adresseInput : Adresse) {

    this.adresseFormate = '';

    if(adresseInput.numero) {
      this.adresseFormate = adresseInput.numero + ' ';
    }

    if(adresseInput.nomVoie) {
      this.adresseFormate = this.adresseFormate + adresseInput.nomVoie + ' ';
    }

    if(adresseInput.codePostal) {
      this.adresseFormate = this.adresseFormate + adresseInput.codePostal + ' ';
    }

    if(adresseInput.ville) {
      this.adresseFormate = this.adresseFormate + adresseInput.ville + ' ';
    }

    if(adresseInput.pays) {
      this.adresseFormate = this.adresseFormate + adresseInput.pays?.libelle;
    }

  }

  ngOnChanges(changes: SimpleChanges) {

    if(!changes.adresseInput.firstChange) {
      let adresse  = changes.adresseInput?.currentValue;
      if(adresse && (adresse?.numero !== this.adresse?.numero || adresse?.nomVoie !== this.adresse?.nomVoie
        || adresse?.codePostal !== this.adresse?.codePostal || adresse?.ville !== this.adresse?.ville
        || adresse?.pays?.id !== this.adresse?.pays?.id)
      ) {
        this.adresseFormate = '';
        this.adresseInput.adresseFormate = '';
      }
    }

  }



}
