import {Component, OnInit} from '@angular/core';
import {MessageService} from 'primeng/api';
import {DynamicDialogConfig, DynamicDialogRef} from 'primeng/dynamicdialog';
import {Dossier} from 'src/app/core/models/dossiers/dossier';
import {TranslateService} from 'src/app/shared/translate/translate.service';
import {ChangementRapide} from '../../models/dossiers/changement-rapide';
import {FraisFournisseur} from '../../models/dossiers/frais-fournisseur';
import {ConfigConstants} from '../../models/enum/config-constants';
import {ProfilesUser} from '../../models/user/profiles-user';
import {DossierService} from '../../service/dossier.service';
import {InfoExpertise} from "../../models/dossiers/info-expertise";
import {UtilsFonction} from "../../../shared/providers/utils-fonction";

@Component({
  selector: 'app-pop-up-honoraires-dossier',
  templateUrl: './pop-up-honoraires.component.html',
  styleUrls: ['./pop-up-honoraires.component.scss']
})
export class PopUpHonorairesComponent implements OnInit {

  dossier: Dossier;
  payload: ChangementRapide;
  total: number;
  expert: InfoExpertise;
  disabled: boolean;

  constructor(
    public service: DossierService,
    private message: MessageService,
    private translate : TranslateService,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig
  ) { }

  ngOnInit(): void {
    this.dossier = this.config.data;
    this.payload = new ChangementRapide();
    this.payload.idDossier = this.dossier.idDossier;
    this.expert = this.dossier?.infoExpertise;
    this.calculHonorairesFrais();
    this.calculTotal();
    console.log(" fraisFournisseurs : ", this.dossier.fraisFournisseurs);
    let dos = this.dossier.fraisFournisseurs.filter( fr => fr.refacturable === false);
    console.log(" frais : ", dos);
  }

  calculHonorairesFrais() {
    if (this.dossier.infoExpertise?.expert === null) {
        this.expert = null;
        this.payload.honoraires = 0;
        this.payload.fraisExpert = 0;
        this.disabled = true;
    } else {
        this.payload.honoraires = this.calculHonoExpert();
        this.payload.fraisExpert = this.calculFrais();
        this.disabled = this.expert.profilsExpert.includes(ProfilesUser.EXPERT_INTERNE);
        if (this.payload.honoraires === undefined) {
          this.payload.honoraires = 0;
        }
        if (this.payload.fraisExpert === undefined) {
          this.payload.fraisExpert = 0;
        }
    }

  }

  calculHonoExpert() {
    let honoExpert = 0;
    console.log(this.dossier.infoExpertise.honoraire)
    if(this.expert.profilsExpert.includes(ProfilesUser.EXPERT_INTERNE)) {
      let honoExpertise = this.dossier.infoExpertise?.honoraire;
      let fraisNonRefacturable = 0;
      this.dossier.fraisFournisseurs.filter(fr => !fr.refacturable).map( fr => {
        fraisNonRefacturable += fr.montantHT;
      });
      let montantNego = UtilsFonction.calculMontantNegociation(honoExpertise, fraisNonRefacturable, this.getPourcentageNego());
      let montantPilote =  UtilsFonction.calculMontantPilotage(honoExpertise, fraisNonRefacturable, montantNego,  this.getPourcentagePilote());
      honoExpert = UtilsFonction.calculHonoraireExpertInterne(honoExpertise, fraisNonRefacturable, montantNego, montantPilote);
    }else  if(this.expert && this.expert.profilsExpert.includes(ProfilesUser.EXPERT_EXTERNE)) {
      honoExpert = this.getFrais(false, ConfigConstants.TYPE_FRAIS_EXPERTISE, ConfigConstants.TYPE_FOURNISSEUR_EXPERT_RESEAU)?.montantHT;
    }
    return honoExpert;
  }

  calculFrais() {
    return this.getFrais(false, ConfigConstants.TYPE_FRAIS_DEPLACEMENT, ConfigConstants.TYPE_FOURNISSEUR_EXPERT_RESEAU)?.montantHT;
  }

  modifierHonoraires() {
    this.message.clear();
    this.service.modifierHonoraires(this.payload).subscribe(data => {
      if (data) {
        this.dossier.fraisFournisseurs = data.fraisFournisseurs;
        this.message.add({severity: 'success', summary: this.translate.getElement("DOSSIER.POPUP.HONORAIRES.MESSAGE_SUCCESS")});
      }
    }, error => {
      this.message.add({severity: 'error', summary: this.translate.getElement("DOSSIER.POPUP.HONORAIRES.MESSAGE_ERROR")});
    });
  }

  fermer() {
    this.ref.close();
  }

  calculTotal() {
    this.total = this.payload.honoraires+this.payload.fraisExpert;
  }

  public getFrais(refacturable : boolean, codeTypeFrais : string, codeTypeFournisseur : string) : FraisFournisseur {
    let frais;
    if(this.dossier.fraisFournisseurs.length > 0) {
      this.dossier.fraisFournisseurs.map(fr => {
        if(fr.refacturable === refacturable && fr.typesFrais.code === codeTypeFrais && fr.typeFournisseur.code === codeTypeFournisseur) {
          frais = fr;
        }
      })
    }
    return frais;
  }

  public getPourcentageNego() {
    let pourcentageNego = 0;
    if (this.dossier.mission.paramPourcentageNegociation != null) {
      pourcentageNego = this.dossier.mission.paramPourcentageNegociation.valeur;
    }
    return pourcentageNego * 100;
  }

  public getPourcentagePilote() {
    let p1 = 0;
    let p2 = 0;
    let p3 = 0;
    if (this.dossier.pourcentagePilote1 != null) {
      p1 = this.dossier.pourcentagePilote1;
    }
    if (this.dossier.pourcentagePilote2 != null) {
      p2 = this.dossier.pourcentagePilote2;
    }
    if (this.dossier.pourcentagePilote3 != null) {
      p3 = this.dossier.pourcentagePilote3;
    }
    return (p1+p2+p3) * 100;
  }
}
