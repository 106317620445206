import {Component, OnInit} from '@angular/core';
import {MessageService} from 'primeng/api';
import {DynamicDialogConfig, DynamicDialogRef} from "primeng/dynamicdialog";
import {Document} from "../../../core/models/document/document.model";
import {Observable} from 'rxjs';
import {DocumentService} from 'src/app/core/service/document.service';
import {TranslateService} from "../../../shared/translate/translate.service";
import {AppSettings, Settings} from "../../../app.settings";
import {NgxSpinnerService} from "ngx-spinner";

export interface DocumentsNonLusParent {
  recupererDocumentsNonLus() : Observable<Document[]>;
}

@Component({
  selector: 'app-documents-non-lus',
  templateUrl: './documents-non-lus.component.html',
  styleUrls: ['./documents-non-lus.component.scss']
})
export class DocumentsNonLusComponent implements OnInit {

  listeDocumentNonLus: Document[];
  parent: DocumentsNonLusParent;

  totalRecords : number = 0;
  public settings: Settings;

  constructor(
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private service: DocumentService,
    private message: MessageService,
    private translate: TranslateService,
    public appSettings : AppSettings,
    private spinnerService: NgxSpinnerService
  ) {

    this.settings = this.appSettings.settings;
    this.parent = config.data;
    this.listeDocumentNonLus = config.data.documentsNonLus;
    this.totalRecords = this.listeDocumentNonLus.length;
   }

  ngOnInit(): void {

  }

  close(message? : string) {
    this.ref.close(message);
  }

  ngOnDestroy() {
    if (this.ref) {
      this.ref.close();
    }
  }

  downloadDocument(uidDocument: string, idDocumentLecture: number, filename: string) {
    this.spinnerService.show();
    this.message.clear();
    this.service.downloadDocument(uidDocument, idDocumentLecture).subscribe(data => {
      if (data != null) {
        this.openFile(data, filename);
        this.parent.recupererDocumentsNonLus().subscribe(data => {
          this.listeDocumentNonLus = data;
        });
      }
      this.spinnerService.hide();
    }, error => {
      this.message.add({severity: 'error', summary: this.translate.getElement("DOCUMENT.TELECHARGEMENT.ERROR")});
      this.spinnerService.hide();
    })
  }

  openFile(data: any, filename: string) {
    let dataType = data.type;
    let binaryData = [];
    binaryData.push(data);
    let downloadLink = document.createElement('a');
    downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, {type: dataType}));
    if (filename)
        downloadLink.setAttribute('download', filename);
    document.body.appendChild(downloadLink);
    downloadLink.click();
    downloadLink.parentNode.removeChild(downloadLink);
  }

  nextPage($event: any) {

  }
}
