import {ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {AbstractControl, FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {BienConstruction} from "../../../../models/bien/bien-construction";
import {DialogService, DynamicDialogRef} from "primeng/dynamicdialog";
import {ConfirmationService, Message, MessageService} from "primeng/api";
import {RapportExpertiseService} from "../../../../service/rapport-expertise.service";
import {AppSettings, Settings} from "../../../../../app.settings";
import {RapportExpertise} from "../../../../models/rapport-expertise/rapport-expertise";
import {ReferentielValeur} from "../../../../models/referentiel-valeur";
import {TranslateService} from "../../../../../shared/translate/translate.service";

@Component({
  selector: 'app-table-construction',
  templateUrl: './table-construction.component.html',
  styleUrls: ['./table-construction.component.scss']
})
export class TableConstructionComponent implements OnInit {

  @Input()
  rapport : RapportExpertise;

  constructions : ReferentielValeur[];

  bienConstructions : BienConstruction[];
  public settings: Settings;
  bienConstructionForm : FormGroup;
  messageBienConstruction : Message[];
  message : Message[];
  bienConstructionDialog: boolean;
  submittedBienConstruction : boolean;

  bienConstruction: BienConstruction;

  nbCaractDescriptionConstruction: number = 0;

  constructor(
    private fb : FormBuilder,
    public dialogService: DialogService,
    public ref: DynamicDialogRef,
    private cdref: ChangeDetectorRef,
    private messageService : MessageService,
    private rapportExpertiseService : RapportExpertiseService,
    private confirmation: ConfirmationService,
    public appSettings : AppSettings,
    private translate : TranslateService
  ) {
    this.settings = this.appSettings.settings;
  }

  ngOnInit(): void {
    this.getAllConstructions();
    this.getAllBienConstructionByBienId();
  }

  async getAllBienConstructionByBienId() {
    this.bienConstructions = await this.rapportExpertiseService.getAllBienConstructionByBienId(this.rapport.idBien);
  }

  initBienConstructionForm(biec? : BienConstruction) {
    this.bienConstructionForm = this.fb.group({
      bieConstruction : new FormControl(biec?.construction ? biec?.construction : null, Validators.required),
      descriptionConstruction : new FormControl(biec?.description ? biec?.description :null, Validators.maxLength(75)),
    });
  }

  getAllConstructions() {
    this.rapportExpertiseService.getAllConstructions().subscribe( data => {
      this.constructions = data;
    })
  }

  get formBieConstruction(): { [key: string]: AbstractControl } {
    return this.bienConstructionForm.controls;
  }

  hideDialog() {
    this.bienConstructionDialog = false;
    this.submittedBienConstruction = false;
  }

  openNew() {
    this.messageBienConstruction = null;
    this.initBienConstructionForm(null);
    this.bienConstruction = null;
    this.submittedBienConstruction = false;
    this.bienConstructionDialog = true;
  }

  editBienConstruction(biec: BienConstruction) {
    this.messageBienConstruction = null;
    this.bienConstruction = {...biec};
    this.initBienConstructionForm(this.bienConstruction);
    this.nbCaractDescriptionConstruction = biec?.description?.length;
    this.bienConstructionDialog = true;
  }

  deleteBienConstruction(biec: BienConstruction) {
    if(biec) {
      this.message = null;
      this.confirmation.confirm({
        header: this.translate.getElement("RAPPORT_EXPERTISE.MESSAGE.ETES_VOUS_SUR"),
        message : this.translate.getElement("RAPPORT_EXPERTISE.MESSAGE.CONFIRMER"),
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          this.rapportExpertiseService.deleteBienConstruction(biec.id, this.rapport?.idDossier).then( data => {
            if(data) {
              this.getAllBienConstructionByBienId();
              this.message =[ {key : 'table', severity:'success', summary:  this.translate.getElement("RAPPORT_EXPERTISE.MESSAGE.SUCESS_SUPPRESSION")}];
              return;
            }
            this.message =[ {key : 'table', severity:'error', summary:  this.translate.getElement("RAPPORT_EXPERTISE.MESSAGE.ERROR_SUPPRESSION")}];
          }).catch( error => {
            this.message =[ {key : 'table', severity:'error', summary:  this.translate.getElement("RAPPORT_EXPERTISE.MESSAGE.ERROR_SUPPRESSION")}];
          })
        }
      })

    }
  }

  saveBienConstruction() {
      this.submittedBienConstruction = true;

      this.messageBienConstruction = null;

    if(this.bienConstructionForm.invalid) {
      this.messageBienConstruction =[ {key : 'dialog', severity:'error', summary:  this.translate.getElement("RAPPORT_EXPERTISE.MESSAGE.ERROR_FORM")}];
      return;
    }

    const biec : BienConstruction = new BienConstruction();

    if(this.bienConstruction) {
      biec.id = this.bienConstruction?.id;
    }

    const value = this.bienConstructionForm.value;
    biec.description = value.descriptionConstruction;
    biec.construction = value.bieConstruction;
    biec.idBien = this.rapport?.idBien;
    biec.idDossier = this.rapport?.idDossier;

    this.rapportExpertiseService.saveBieConstruction(biec).subscribe( data => {
      if(data) {
        this.hideDialog()
        this.getAllBienConstructionByBienId();
      }else {
        this.messageBienConstruction =[ {key : 'dialog', severity:'error', summary:  this.translate.getElement("RAPPORT_EXPERTISE.MESSAGE.ERROR_MESSAGE_MODAL")}];
        this.submittedBienConstruction = false;
      }
    }, error => {
      this.messageBienConstruction =[ {key : 'dialog', severity:'error', summary:  this.translate.getElement("RAPPORT_EXPERTISE.MESSAGE.ERROR_MESSAGE_MODAL")}];
      this.submittedBienConstruction = false;
    })
  }

  detectChangeDescripionConstruction(event : any) {
    if(event) {
      this.nbCaractDescriptionConstruction =  String(event).length;
    }
  }

}
