<h2 for="status">{{'DOSSIER.ADVANCED_SEARCH.LABEL.STATUT' | translateI18n}}</h2>

<div class="grid formgrid" [formGroup]="form">
  <div class="field col-12"  [formGroup]="form">
    <div class="p-dropdown-group">
      <p-dropdown [options]="statuts" formControlName="statutDossier" optionLabel="value" id="status"
                  placeholder="{{'PLACEHOLDER.CHOOSE' | translateI18n}}" class="w-full"></p-dropdown>
    </div>
  </div>
</div>
