<div class="mb-3">
  <button pButton pRipple label="{{'RAPPORT_EXPERTISE.ACTIONS.AJOUTER_NOUVELLE_LIGNE' | translateI18n}}"
          icon="pi pi-plus" class="p-button-sm p-button-rounded" (click)="openNew()"></button>
</div>
<p-messages  *ngIf="message" [(value)]="message" key="tableSurface"></p-messages>
<p-table [value]="bienReferences" [rows]="settings.pagination?.rowsDocument"  [showCurrentPageReport]="true"
         [paginator]="bienReferences?.length > settings.pagination?.rowsDocument"
         [rowsPerPageOptions]="settings.pagination?.rowsPerPageOptionsDocument"
         [globalFilterFields]="['adresse','typeBien.libelle','prixvente','surface','prixm2', 'annexe', 'datetransac', 'observations']"
         dataKey="id" paginatorPosition="top"
         scrollDirection="both"
         [scrollable]="true"
         currentPageReportTemplate="Affichage {first} à {last} sur {totalRecords}" >
  <ng-template pTemplate="header">
    <tr>
      <th pFrozenColumn class="width-9"></th>
      <th pFrozenColumn pSortableColumn="adresse" class="width-15 frozen-shadow">{{'RAPPORT_EXPERTISE.LABEL.ADRESSE' | translateI18n}}  <p-sortIcon field="adresse"></p-sortIcon></th>
      <th pSortableColumn="typeBien.libelle" class="is-large">{{'RAPPORT_EXPERTISE.LABEL.TYPE_BIEN' | translateI18n}} <p-sortIcon field="typeBien.libelle"></p-sortIcon></th>
      <th pSortableColumn="prixvente" class="is-large">{{'RAPPORT_EXPERTISE.LABEL.PRIX_VENTE' | translateI18n}}<p-sortIcon field="prixvente"></p-sortIcon></th>
      <th pSortableColumn="surface" class="is-large">{{'RAPPORT_EXPERTISE.LABEL.SURFACE' | translateI18n}} (m²) <p-sortIcon field="surface"></p-sortIcon></th>
      <th pSortableColumn="prixm2" class="is-large">{{'RAPPORT_EXPERTISE.LABEL.RATIO' | translateI18n}}<p-sortIcon field="prixm2"></p-sortIcon></th>
      <th pSortableColumn="annexe" class="is-large">{{'RAPPORT_EXPERTISE.LABEL.ANNEXE' | translateI18n}}<p-sortIcon field="annexe"></p-sortIcon></th>
      <th pSortableColumn="datetransac" class="is-large">{{'RAPPORT_EXPERTISE.LABEL.DATE_TRANSACTION' | translateI18n}}<p-sortIcon field="datetransac"></p-sortIcon></th>
      <th pSortableColumn="observations" class="is-large">{{'RAPPORT_EXPERTISE.LABEL.OBSERVATIONS' | translateI18n}} <p-sortIcon field="observations"></p-sortIcon></th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-bier>
    <tr>
      <td class="width-9 text-left" pFrozenColumn>
        <button type="button" pButton pRipple icon="inc-icon inc-icon-trash is-small"
                pTooltip="{{'BUTTON_LABEL.DELETE' | translateI18n}}" tooltipPosition="top"
                class="p-button-rounded mr-2" (click)="delete(bier)"></button>
        <button type="button" pButton pRipple icon="inc-icon inc-icon-edit is-small"
                pTooltip="{{'BUTTON_LABEL.MODIFY' | translateI18n}}" tooltipPosition="top"
                class="p-button-rounded p-button-success mr-2" (click)="edit(bier)"></button>
      </td>
      <td pFrozenColumn class="width-15 frozen-shadow">{{bier.adresse}}</td>
      <td class="is-large">{{bier.typeBien?.libelle}}</td>
      <td class="is-large">{{bier.prixvente | decimal}}</td>
      <td class="is-large">{{bier.surface | decimal}}</td>
      <td class="is-large">{{bier.prixm2 | decimal}}</td>
      <td class="is-large">{{bier.annexe}}</td>
      <td class="is-large">{{bier.datetransac | date : 'dd/MM/yyyy'}}</td>
      <td class="is-large ">{{bier.observations }}</td>

    </tr>
  </ng-template>

</p-table>




<p-dialog [(visible)]="dialog" [style]="{width: '600px'}" [baseZIndex]="10000" header="{{'RAPPORT_EXPERTISE.LABEL.AJOUTER_ELEMENT' | translateI18n}}" [modal]="true" styleClass="p-fluid p-dynamic-dialog">

  <ng-template pTemplate="content" >
    <p-messages  *ngIf="messageDialog" [(value)]="messageDialog"></p-messages>
    <form [formGroup]="refVenaleForm" class="page-form">

      <div class="field">
        <label for="adresse" class="obligatoire" [ngClass]="{'p-error': submitted && formRefVenal.adresse.errors?.pattern}">
          {{'RAPPORT_EXPERTISE.LABEL.ADRESSE' | translateI18n}}
        </label>
        <input type="text" class="inputfield" pInputText id="adresse" formControlName="adresse"
               [ngClass]="{'ng-dirty': submitted  && (formRefVenal.adresse?.errors?.pattern ||  formRefVenal.adresse?.errors?.required)}"
               [maxLength]="64" required/>

        <small *ngIf="submitted && formRefVenal.adresse?.errors?.pattern" class="p-error block">
          {{'MESSAGE.ERROR.REGEX_PATTERN' | translateI18n}}</small>
        <small *ngIf="submitted && formRefVenal.adresse?.errors?.required" class="p-error block">
          {{'MESSAGE.ERROR.CHAMPS_OBLIGATOIRE' | translateI18n}}</small>

      </div>


      <div class="field">
        <label for="typeBien" [ngClass]="{'p-error': submitted  && formRefVenal.typeBien.errors?.required}" class="obligatoire">
          {{'RAPPORT_EXPERTISE.LABEL.TYPE_BIEN' | translateI18n}}
        </label>
        <p-dropdown [options]="typeBiens" formControlName="typeBien"
                    optionLabel="libelle" name="typeBien"  id="typeBien"
                    [ngClass]="{'ng-dirty ng-invalid':  submitted  && formRefVenal.typeBien.errors?.required}"
                    emptyMessage="{{'DOSSIER.MESSAGE.AUCUN_RESULTAT' | translateI18n}}"
                    placeholder="{{'PLACEHOLDER.CHOOSE' | translateI18n}}" >
        </p-dropdown>
        <small class="p-error" *ngIf="submitted && formRefVenal.typeBien?.errors?.required">{{'MESSAGE.ERROR.CHAMPS_OBLIGATOIRE' | translateI18n}}</small>
      </div>


      <div class="field">

        <label [ngClass]="{'obligatoire': formRefVenal.prixVente?.errors?.required}">
          {{'RAPPORT_EXPERTISE.LABEL.PRIX_VENTE' | translateI18n}}</label>

        <p-inputNumber formControlName="prixVente"
                       inputId="prixVente"
                       mode="currency" currency="EUR" locale="fr-FR"
                       [ngClass]="{'ng-invalid ng-dirty': submitted && formRefVenal.prixVente?.errors}"
                       [size]=20
                       [min]="0"
                       [maxlength]="18"
                       placeholder="{{'RAPPORT_EXPERTISE.LABEL.RENSEIGNER' | translateI18n}}"
                        (onInput)="prixVenteChange($event)">
        </p-inputNumber>
        <small class="p-error" *ngIf="submitted && formRefVenal.prixVente?.errors">{{'MESSAGE.ERROR.CHAMPS_OBLIGATOIRE' | translateI18n}}</small>
      </div>

      <div class="field">
        <label for="surface">
          {{'RAPPORT_EXPERTISE.LABEL.SURFACE' | translateI18n}} (m²)</label>
        <p-inputNumber formControlName="surface"
                       id="surface"
                       suffix=" m²"
                       [size]=20
                       [min]="0"
                       [maxlength]="18"
                       [minFractionDigits]="2"
                       [maxFractionDigits]="5"
                       placeholder="{{'RAPPORT_EXPERTISE.LABEL.RENSEIGNER' | translateI18n}}">
        </p-inputNumber>
      </div>

      <div class="field">
        <label for="ratio" [ngClass]="{'obligatoire': formRefVenal.ratio?.errors?.required}">{{'RAPPORT_EXPERTISE.LABEL.RATIO' | translateI18n}}</label>
        <p-inputNumber formControlName="ratio"
                       id="ratio"
                       suffix=" €/m²"
                       [size]=20
                       [min]="0"
                       [maxlength]="18"
                       [ngClass]="{'ng-dirty ng-invalid': submitted && formRefVenal.ratio?.errors}"
                       [minFractionDigits]="2"
                       [maxFractionDigits]="5"
                       placeholder="{{'RAPPORT_EXPERTISE.LABEL.RENSEIGNER' | translateI18n}}" (onInput)="ratioChange($event)">
        </p-inputNumber>

        <small *ngIf="submitted && formRefVenal.ratio?.errors" class="p-error block">{{'MESSAGE.ERROR.CHAMPS_OBLIGATOIRE' | translateI18n}}</small>
      </div>


      <div class="field">
        <label for="annexe">{{'RAPPORT_EXPERTISE.LABEL.ANNEXE' | translateI18n}}</label>
        <input type="text" class="inputfield" pInputText id="annexe" formControlName="annexe" [maxLength]="64"/>
      </div>

      <div class="field">
        <label for="datetransac" class="obligatoire">{{'RAPPORT_EXPERTISE.LABEL.DATE_TRANSACTION' | translateI18n}}</label>
        <div class="p-input-icon-right w-full">
          <p-calendar  formControlName="datetransac" name="datetransac" id="datetransac"
                       [ngClass]="{'ng-dirty ng-invalid': submitted && formRefVenal.datetransac?.errors}"
                      [showIcon]="true" dateFormat="dd/mm/yy" inputId="icon" class="inputfield w-full"
                      [firstDayOfWeek]="1"></p-calendar>
        </div>

        <small *ngIf="submitted && formRefVenal.datetransac?.errors" class="p-error block">{{'MESSAGE.ERROR.CHAMPS_OBLIGATOIRE' | translateI18n}}</small>
      </div>

      <div class="field">
        <label for="observations">{{'RAPPORT_EXPERTISE.LABEL.OBSERVATIONS' | translateI18n}}</label>
        <input type="text" class="inputfield" pInputText id="observations" formControlName="observations" [maxLength]="64"/>

        <small *ngIf="submitted && formRefVenal.observations?.errors?.pattern" class="p-error block">
          {{'MESSAGE.ERROR.REGEX_PATTERN' | translateI18n}}</small>
      </div>


    </form>
  </ng-template>

  <ng-template pTemplate="footer">
    <button pButton type="button" pRipple label="{{'BUTTON_LABEL.ANNULER' | translateI18n}}" icon="pi pi-times" class="uppercase p-button-secondary p-button-outlined mb-2"
            (click)="hideDialog()"></button>
    <button pButton type="submit" pRipple label="{{'BUTTON_LABEL.ENREGISTRER' | translateI18n}}" icon="pi pi-save" class="ppercase mb-2" (click)="save()"></button>
  </ng-template>

</p-dialog>

