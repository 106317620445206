import {ChangeDetectorRef, Component, Input, OnDestroy, OnInit} from '@angular/core';
import {AvisValeur} from "../../../models/avis-valeurs/avis-valeur";
import {AbstractControl, FormBuilder, FormGroup, FormGroupDirective, Validators} from "@angular/forms";
import {Message, MessageService} from "primeng/api";
import {DialogService, DynamicDialogRef} from "primeng/dynamicdialog";
import {TranslateService} from "../../../../shared/translate/translate.service";
import {AvisValeurService} from "../../../service/avis-valeur.service";
import {AvisValeurObservableService} from "../../../../shared/observable/avis-valeur-observable.service";
import {ConfigSizeInput} from "../../../../shared/providers/config-size-input";
import {Subscription} from "rxjs";

@Component({
  selector: 'app-situation-locative',
  templateUrl: './situation-locative.component.html',
  styleUrls: ['./situation-locative.component.scss']
})
export class SituationLocativeComponent implements OnInit, OnDestroy {

  @Input()
  formGroupName: string;

  @Input()
  submitted : boolean;

  @Input()
  submittedRequired : boolean;

  @Input()
  avisValeur : AvisValeur;

  form: FormGroup;

  msgError : Message[];

  periodicitesLoyer : any[];

  regimeFiscalLocatif : any[];

  surface : number;

  configSizeInput : ConfigSizeInput;
  private subscriptionSurface : Subscription;
  constructor(
    private fb : FormBuilder,
    public dialogService: DialogService,
    public ref: DynamicDialogRef,
    private rootFormGroup: FormGroupDirective,
    private cdref: ChangeDetectorRef,
    private message: MessageService,
    private translate : TranslateService,
    private avisValeurService : AvisValeurService,
    private avisValeurObservableService : AvisValeurObservableService
  ) {
    this.configSizeInput = new ConfigSizeInput();
  }

  ngOnInit(): void {
    this.form = this.rootFormGroup.control.get(this.formGroupName) as FormGroup;
    this.msgError =[ {severity:'error',
      summary:  this.translate.getElement("AVIS_VALEUR.MESSAGE.ERROR_CARACTERE_COMMENTAIRE", '400')}];

    this.getAllPeriodiciteLoyer();
    this.getAllRegimeFiscalLocatif();

    this.calculLoyerM2IfSurfaceChanged(this.form?.value?.loyer)
  }

  ngOnDestroy() {
    this.subscriptionSurface.unsubscribe();
  }

  getAllPeriodiciteLoyer()  {
    this.avisValeurService.getPeriodiciteLoyer().subscribe(
      data => {
        if(data) {
          this.periodicitesLoyer = data;
          let periodiciteloyer = this.periodicitesLoyer.find(pl => pl.id === this.avisValeur?.periodiciteloyer);
          this.form.get('periodiciteloyer').setValue(periodiciteloyer);
        }
      },error => {

      }
    )
  }

  getAllRegimeFiscalLocatif()  {
    this.avisValeurService.getAllRegimeFiscalLocatif().subscribe(
      data => {
        if(data) {
          this.regimeFiscalLocatif = data;
          let regimechargeloyer = this.regimeFiscalLocatif.find(rfl => rfl.idRegimeFiscalL === this.avisValeur?.regimechargeloyer);
          this.form.get('regimechargeloyer').setValue(regimechargeloyer);
        }
      },error => {

      }
    )
  }

  calculLoyerM2IfLoyerValueChanged(event : any) {
    if(event.value < 0) {
      this.msgError =[ {severity:'error', summary:  this.translate.getElement("AVIS_VALEUR.MESSAGE.SURFACE_POSITIVE")}];
      return;
    }
    this.calculLoyerM2(this.surface, event.value)
  }

  calculLoyerM2IfSurfaceChanged(loyer : number) {
    this.subscriptionSurface = this.avisValeurObservableService.getSurface().subscribe(surface => {
      this.calculLoyerM2(surface, loyer)
    })
  }

  calculLoyerM2(surf : number, loyer : number) {
    if(surf && surf > 0 && loyer >= 0) {
        this.surface = surf;
        let loyerm2 = loyer / this.surface;
       // loyerm2 = Math.round(loyerm2 * 100) / 100;
        this.form.get('loyerm2').setValue(loyerm2);
    }else {
      this.form.get('loyerm2').setValue(null);
    }
  }


  requiredValue() : boolean {
    if(this.form?.value?.loyer > 0) {
      this.form.controls['regimechargeloyer'].setValidators(Validators.required);
      this.form.controls['periodiciteloyer'].setValidators(Validators.required);
      return true;
    }
    this.form.controls['regimechargeloyer'].setErrors(null);
    this.form.controls['periodiciteloyer'].setErrors(null);
    return false;
  }


  get f(): { [key: string]: AbstractControl } {
    return this.form.controls;
  }

  ngAfterContentChecked() {
    this.cdref.detectChanges();
  }

  displayModifyLoyer() {
    let div = document.getElementById('spanLoyer');
    div.style.display = 'none';
    let divInput = document.getElementById('inputLoyer');
    divInput.style.display = 'inline';
    if (divInput instanceof HTMLElement) {
      divInput.focus();
    }
  }

  onBlur() {
    let div = document.getElementById('spanLoyer');
    div.style.display = 'inline';
    let divInput = document.getElementById('inputLoyer');
    divInput.style.display = 'none';
  }

}
