import {ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {AbstractControl, FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {ConfirmationService, Message, MessageService} from "primeng/api";
import {AppSettings, Settings} from "../../../../../app.settings";
import {ConfigSizeInput} from "../../../../../shared/providers/config-size-input";
import {DialogService, DynamicDialogRef} from "primeng/dynamicdialog";
import {RapportExpertiseService} from "../../../../service/rapport-expertise.service";
import {RapportExpertiseObservableService} from "../../../../../shared/observable/rapport-expertise-observable.service";
import {TranslateService} from "../../../../../shared/translate/translate.service";
import {BienReferenceLocative} from "../../../../models/bien/bien-reference-locative";
import {ReferentielValeur} from "../../../../models/referentiel-valeur";

@Component({
  selector: 'app-table-reference-locative',
  templateUrl: './table-reference-locative.component.html',
  styleUrls: ['./table-reference-locative.component.scss']
})
export class TableReferenceLocativeComponent implements OnInit {


  @Input()
  idDossier : number

  message : Message[];
  messageDialog : Message[];
  submitted  : boolean;
  dialog : boolean;

  public settings: Settings;

  bienReferencesLoc : BienReferenceLocative[];
  bienReferenceLoc : BienReferenceLocative;
  refLocativeForm : FormGroup;

  configSizeInput : ConfigSizeInput;

  typeBiens : ReferentielValeur[];
  periodicites : any[];

  periodiciteLoyer : any;

  constructor(
    private fb : FormBuilder,
    public dialogService: DialogService,
    public ref: DynamicDialogRef,
    private cdref: ChangeDetectorRef,
    private messageService : MessageService,
    private rapportExpertiseService : RapportExpertiseService,
    private confirmation: ConfirmationService,
    public appSettings : AppSettings,
    private rapportExpertiseObservableService : RapportExpertiseObservableService,
    private translate : TranslateService,
  ) {
    this.settings = this.appSettings.settings;
    this.configSizeInput = new ConfigSizeInput();

  }

  ngOnInit(): void {
    this.getAllTypeBiens();
    this.getAllPeriodicites();
    this.getAllBienReferenceLocByDossierId();
  }

  async getAllBienReferenceLocByDossierId() {
   this.bienReferencesLoc =  await this.rapportExpertiseService.getAllBienReferenceLocByDossierId(this.idDossier);
   this.bienReferencesLoc.forEach( d=> {
       if (d.datetransac != null) {
         d.datetransac = new Date(d.datetransac);
       }
   })
  }

  initForm(bierefloc? : BienReferenceLocative) {

    this.refLocativeForm = this.fb.group({
      adresse : new FormControl(bierefloc?.adresse ? bierefloc?.adresse : null,
        [Validators.required, Validators.maxLength(64), Validators.pattern(this.configSizeInput.regexPattern2)]),
      typeBien : new FormControl(bierefloc?.typeBien ? bierefloc?.typeBien :null, Validators.required),
      loyer : new FormControl(bierefloc?.loyer ? bierefloc?.loyer :null, Validators.required),
      periodiciteLoyer : new FormControl(bierefloc?.periodiciteLoyer ? bierefloc?.periodiciteLoyer :null, Validators.required),
      surface : new FormControl(bierefloc?.surface ? bierefloc?.surface :null),
      ratio : new FormControl(bierefloc?.ratio ? bierefloc?.ratio :null),
      annexe  : new FormControl(bierefloc?.annexe ? bierefloc?.annexe :null, Validators.pattern(this.configSizeInput.regexPattern2)),
      datetransac : new FormControl(bierefloc?.datetransac ? bierefloc?.datetransac :null, Validators.required),
      observations : new FormControl(bierefloc?.observations ? bierefloc?.observations :null, [
        Validators.maxLength(64), Validators.pattern(this.configSizeInput.regexPattern2)]),
      periodiciteLoyerRatio : new FormControl(bierefloc?.periodiciteLoyer ? bierefloc?.periodiciteLoyer :null),
    });
    this.refLocativeForm.controls['periodiciteLoyerRatio'].disable();

  }

  edit(bierefloc : BienReferenceLocative) {
    this.messageDialog = null;
    this.bienReferenceLoc = {...bierefloc};
    this.initForm(this.bienReferenceLoc);
    this.dialog = true;
    const body = document.getElementsByTagName('body')[0];
    body.classList.add('p-dynamic-dialog-scrollable');
  }

  delete(bierefloc: BienReferenceLocative) {
    if(bierefloc) {
      this.confirmation.confirm({
        header: this.translate.getElement("RAPPORT_EXPERTISE.MESSAGE.ETES_VOUS_SUR"),
        message : this.translate.getElement("RAPPORT_EXPERTISE.MESSAGE.CONFIRMER"),
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          this.rapportExpertiseService.deleteBienReferenceLoc(bierefloc.id, bierefloc.idDossier).then( data => {
            if(data) {
              this.getAllBienReferenceLocByDossierId();
              this.message =[ {severity:'success', summary:  this.translate.getElement("RAPPORT_EXPERTISE.MESSAGE.SUCESS_SUPPRESSION")}];
              return;
            }
            this.message =[ {severity:'error', summary:  this.translate.getElement("RAPPORT_EXPERTISE.MESSAGE.ERROR_SUPPRESSION")}];
          }).catch( error => {
            this.message =[ {severity:'error', summary:  this.translate.getElement("RAPPORT_EXPERTISE.MESSAGE.ERROR_SUPPRESSION")}];
          })
        }
      })

    }
  }

  openNew() {
    this.messageDialog = null;
    this.initForm(null);
    this.bienReferenceLoc = null;
    this.submitted = false;
    this.dialog = true;
    const body = document.getElementsByTagName('body')[0];
    body.classList.add('p-dynamic-dialog-scrollable');
  }


  hideDialog() {
    this.dialog = false;
    this.submitted = false;
    const body = document.getElementsByTagName('body')[0];
    body.classList.remove('p-dynamic-dialog-scrollable');
  }

  get formRefLocative(): { [key: string]: AbstractControl } {
    return this.refLocativeForm.controls;
  }

  save() {
    this.submitted = true;

    if(this.refLocativeForm.invalid) {
      this.messageDialog = [{severity:'error', summary: this.translate.getElement('MESSAGE.ERROR.MS_CHAMPS_OBLIGATOIRE')}];
      return;
    }

    const bierefloc : BienReferenceLocative = new BienReferenceLocative();

    if(this.bienReferenceLoc) {
      bierefloc.id = this.bienReferenceLoc?.id;
    }

    const value = this.refLocativeForm.value;

    bierefloc.adresse = value.adresse;
    bierefloc.typeBien = value.typeBien;
    bierefloc.loyer = value.loyer;
    bierefloc.periodiciteLoyer = value.periodiciteLoyer;
    bierefloc.surface = value.surface;
    bierefloc.ratio = value.ratio;
    bierefloc.annexe = value.annexe;
    bierefloc.datetransac = value.datetransac;
    bierefloc.observations = value.observations;
    bierefloc.idDossier = this.idDossier;

    this.rapportExpertiseService.saveBieReferenceLoc(bierefloc).subscribe( data => {
      if(data) {
        this.hideDialog()
        this.getAllBienReferenceLocByDossierId();
      }  this.messageDialog =[ {severity:'error', summary:  this.translate.getElement("RAPPORT_EXPERTISE.MESSAGE.ERROR_MESSAGE_MODAL")}];
      this.submitted = false;
    }, error => {
      this.messageDialog =[ {severity:'error', summary:  this.translate.getElement("RAPPORT_EXPERTISE.MESSAGE.ERROR_MESSAGE_MODAL")}];
      this.submitted = false;
    })
  }

  getAllTypeBiens()  {
    this.rapportExpertiseService.getAllTypeBien().subscribe(
      data => {
        this.typeBiens = data;
      },error => {

      }
    )
  }

  getAllPeriodicites()  {
    this.rapportExpertiseService.getPeriodiciteLoyer().subscribe(
      data => {
        this.periodicites = data;
      },error => {

      }
    )
  }

  periodiciteLoyerChange(event: any) {
    this.refLocativeForm.get('periodiciteLoyerRatio').setValue(event.value)
  }

  typeBienChange(event: any) {

    if(event?.value) {
      if(event?.value?.codeCategorieBien === 'CBHABITATI' && this.periodicites) {
        this.refLocativeForm.get('periodiciteLoyer').setValue(this.periodicites.find(p => p.id === 1));
        this.refLocativeForm.get('periodiciteLoyerRatio').setValue(this.refLocativeForm.value?.periodiciteLoyer);
      }else {
        this.refLocativeForm.get('periodiciteLoyer').setValue(null);
        this.refLocativeForm.get('periodiciteLoyerRatio').setValue(null);
      }
    }

  }

  updatePeriodicite(code : String) {
    if(code === 'CBHABITATI' && this.periodicites) {
      this.refLocativeForm.get('periodiciteLoyer').setValue(this.periodicites.find(p => p.id === 1));
      this.refLocativeForm.get('periodiciteLoyerRatio').setValue(this.refLocativeForm.value?.periodiciteLoyer);
    }
  }
}
