import {DossierFilter} from "./dossier-filter";

export class Pagination {
  pageNo : number;
  pageSize : number;
  fieldSort : string;
  direction : string
  filter : DossierFilter[];

  constructor (pageNo : number, pageSize : number, fieldSort : string, orderSort? : string) {
    this.pageNo = pageNo;
    this.pageSize = pageSize;
    this.fieldSort = fieldSort;
    this.direction = orderSort;
  }

  static getDirection(sortOrder : number) : string {
    if(sortOrder === -1 ) {
      return 'desc';
    }
    return 'asc';
  }
}
