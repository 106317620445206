
<form #form="ngForm" (ngSubmit)="affectation()">

  <div class="modale-content grid p-fluid formgrid">

    <div  class="field col-12">
      <p-messages *ngIf="message" [(value)]="message" [enableService]="false" [escape]="false"></p-messages>
    </div>

    <div  class="field col-12"  *ngIf=" ecran === 'RECHERCHE_AVANCEE_STATUT_ACTION'">
      <p-autoComplete [(ngModel)]="selectedExpert" [showEmptyMessage]="true" name="selectedExpert"
                      [suggestions]="filteredExpert"
                      (completeMethod)="searchExpertUser($event)"
                      emptyMessage="{{'DOSSIER.MESSAGE.AUCUN_RESULTAT' | translateI18n}}"
                      [minLength]="1"
                      field="nomComplet"
                      placeholder="Expert"
                      [dropdown]="true"
                      class="inputfield w-full" [size]="55">
        <ng-template let-expert pTemplate="item">
          <span>{{ expert.nomComplet}}</span>
        </ng-template>
      </p-autoComplete>
      <div>
        <a [hidden]="rechAdvLink" (click)="showDeptIntervention()" style="cursor : pointer;">Recherche avancée</a>
      </div>
    </div>



    <div  class="field col-12  md:col-12" [hidden]="displayDeptInter">
      <div class="p-dropdown-group">
      <label>{{'DOSSIER.DEPARTEMENT_INTERVENTION' | translateI18n}}</label>
      <p-autoComplete [(ngModel)]="departement" name="selectedDepartement"
                      [suggestions]="filteredDepartement"
                      (completeMethod)="searchDepartement($event)"
                      field="numeroLibelle"
                      [dropdown]="true"
                      class="inputfield w-full"
                      [size]="50"
                      placeholder="Selectionnez"
                      (onSelect)="selectedDepartementIntervention($event)">
        <ng-template let-dept pTemplate="item">
          <div class="country-item">
            <div>{{dept.numeroLibelle}}</div>
          </div>
        </ng-template>
      </p-autoComplete>
      </div>

      <div *ngIf="departement">
        <div *ngIf="users?.length > 0">
          <ul *ngFor="let user of users" style="list-style-type:none;">
            <li>
              {{user.nom}} {{user.prenom}}
              <a (click)="choiceExpert(user)" style="cursor : pointer;">choisir</a>
            </li>
          </ul>
        </div>

        <ul *ngIf="users?.length === 0" style="list-style-type:none;">
          <li>{{'DOSSIER.MESSAGE.AUCUN_RESULTAT' | translateI18n}}</li>
        </ul>
      </div>

    </div>

    <div  class="field col-12" *ngIf=" ecran === 'RECHERCHE_AVANCEE_STATUT_ACTION'">


      <p-dropdown [(ngModel)]="selectedAssistantes" name="selectedAssistantes"
                  [options]="assistantUser"
                  placeholder="{{'PLACEHOLDER.ASSISTANTE' | translateI18n}}"
                  emptyMessage="{{'DOSSIER.MESSAGE.AUCUN_RESULTAT' | translateI18n}}"
                  optionLabel="nomComplet"
                  appendTo="body"
                  class="inputfield w-full">

      </p-dropdown>

    </div>


  </div>

  <div class="p-dialog-footer" *ngIf=" ecran !== 'EN_ATTENTE_AFFECTATION'">

    <button pButton type="button" label="{{'BUTTON_LABEL.CLOSE' | translateI18n}}"
            (click)="close()" class="uppercase p-button-secondary p-button-outlined mb-2" icon="pi pi-times" iconPos="right"></button>

    <button pButton type="submit" label="AFFECTER"
            [disabled]="!selectedExpert || !selectedAssistantes"
            class="uppercase mb-2" icon="pi pi-search" iconPos="right"></button>

  </div>

</form>



