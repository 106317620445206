import {Adresse} from "../adresse";

export class Tiers {
  id : number;
  commentaire : string;
  dateCreationTiers : Date;
  mail : string;
  nom : string;
  prenom : string;
  civilite : string;
  raisonSociale : string;
  adresse : Adresse;
  entiteClient : string;
  physique : boolean;
  nomCompletTiers : string;
  abreviationCivilite : string;
  typeTiers : string;
  projetLivrable : Boolean;

  constructor(fields?: Partial<Tiers>) {
    if (fields) {
      Object.assign(this, fields);
    }
  }

}
