import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {ConfirmationService, MenuItem, Message, MessageService} from "primeng/api";
import {TranslateService} from "../../shared/translate/translate.service";
import {AvisValeurService} from "../../core/service/avis-valeur.service";
import {ActivatedRoute, Router, RoutesRecognized} from "@angular/router";
import {DatePipe} from "@angular/common";
import {UserService} from "../../core/service/user/user.service";
import {AbstractControl, FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {Validation} from "../../shared/providers/validation";
import {NgxSpinnerService} from "ngx-spinner";
import {MessageStorageService} from "../../core/service/local-storage/message-storage.service";
import {AvisValeur} from "../../core/models/avis-valeurs/avis-valeur";
import {StatutDossier} from "../../core/models/enum/statut-dossier";
import {AvisValeurObservableService} from "../../shared/observable/avis-valeur-observable.service";
import {UtilsObservableService} from "../../shared/observable/utils-observable.service";
import {ConfigSizeInput} from "../../shared/providers/config-size-input";
import {UtilsFonction} from "../../shared/providers/utils-fonction";
import {filter, firstValueFrom, Observable, pairwise, take} from "rxjs";
import {DocumentService} from "../../core/service/document.service";
import {ConfigConstants} from "../../core/models/enum/config-constants";
import {HttpResponse} from "@angular/common/http";
import {saveAs} from "file-saver";
import {ProfilesUser} from "../../core/models/user/profiles-user";

@Component({
  selector: 'app-avis-valeur',
  templateUrl: './avis-valeur.component.html',
  styleUrls: ['./avis-valeur.component.scss']
})
export class AvisValeurComponent implements OnInit {

  anchorItem: MenuItem[] = [];
  actionsItem: MenuItem[] = [];

  avisValeur : AvisValeur;

  stock : any;

  currentDateTime : string;

  form : FormGroup;

  paramTypeValeur : any[];
  paramOccupation : any[];

  message: Message[];

  submitted : boolean;

  /**
   * Pour validation des champs obligatoire pendant la génération par exemple
   */
  submittedRequired : boolean;

  changeDetectedMission : boolean;
  changeDetectedDesignationSituationJ : boolean;
  changeDetectedSituationGeo : boolean;
  changeDetectedDescription : boolean;
  changeDetectedElementsAppreciation : boolean;
  changeDetectedSituationLocative : boolean;
  changeDetectedEvaluation : boolean;

  typeSelected: string;

  factureeStatut = StatutDossier.FACTUREE;

  existeDocAvisValeur : boolean = false;

  configSizeInput : ConfigSizeInput;

  previousUrl : string;

  static PREVIOUS_PAGE_URL_AVIS = 'previousPageUrlAvisValeur';
  messageSuccessGenerationAvis : Message[];

  static GENERER_AVIS_VALEUR = "GENERER_AVIS_VALEUR";

  generateAvisValeur : boolean;

  profiles : ProfilesUser[];

  constructor(
    private translate : TranslateService, private avisValeurService : AvisValeurService, private fb : FormBuilder,
    private route: ActivatedRoute, public datepipe: DatePipe, private userService : UserService,  private cdref: ChangeDetectorRef,
    private spinnerService: NgxSpinnerService, private confirmation: ConfirmationService,
    private messageStorage : MessageStorageService,  private messageService: MessageService,
    private router : Router, private avisValeurObservableService : AvisValeurObservableService,
    private utilsObservableService : UtilsObservableService, private documentService : DocumentService,
  ) {
    this.configSizeInput = new ConfigSizeInput();
    this.getUserConnected();
    this.router.events
      .pipe(filter((e: any) => e instanceof RoutesRecognized),
        pairwise()
      ).subscribe((e: any) => {
        this.previousUrl = e[0].urlAfterRedirects;
        localStorage.setItem(AvisValeurComponent.PREVIOUS_PAGE_URL_AVIS, this.previousUrl);
    });
  }

  ngOnInit(): void {
    this.currentDateTime =this.datepipe.transform((new Date), 'dd/MM/yyyy');
    this.route.params.subscribe(params => {
      if(params) {
        this.getDossierById(params['id']);
      }
    });
    this.typeSelected = 'ball-fussion';

    this.getAllParamTypeValeurRapport();
    this.getAllParamOccupation();

    if(this.messageStorage.getMessage()) {

      this.messageSuccessGenerationAvis = [
        {severity:'success', summary:this.translate.getElement(this.messageStorage.getMessage(), this.messageStorage.getKey1()) }
      ];
      if(this.messageStorage.getKey2() === AvisValeurComponent.GENERER_AVIS_VALEUR) {
        this.generateAvisValeur = true;
      }
      this.messageStorage.removeMessage();
    }
  }

  getDossierById(idDossier : number) {
    this.avisValeurService.getDosierById(idDossier).subscribe(
      data => {
        if(data) {
          this.avisValeur = data;
          this.navigationContextuelle();
          this.docsContainsAvisDeValeur(this.avisValeur);
          setTimeout(() => {
            this.navActionsItem();
          }, 3000);
          this.initForm();
        }else {
          this.router.navigateByUrl("/role-denied");
        }
      }, error => {
        if(error.status === 401) {
          this.router.navigateByUrl("/role-denied");
        }
      }
    )
  }

  private updateAvisValeur() {
    const avisValeur : AvisValeur = new AvisValeur(this.avisValeur);

    this.validationMissionForm(avisValeur);
    this.validationDesignationSituationJuridiqueForm(avisValeur);
    this.validationSituationGeoForm(avisValeur);
    this.validationDescriptionForm(avisValeur);
    this.validationSituationLocativeForm(avisValeur);
    this.validationElementsAppreciationForm(avisValeur);
    this.validationEvaluationForm(avisValeur);
    return avisValeur;
  }

  private prepareSauvegarder(): Observable<any>{

    this.submitted = true;

    this.message = null;

    if(!this.hasErroTextFields() && !this.verifyValue()) {

      const avisValeur = this.updateAvisValeur();

      if(!this.submittedRequired && this.detectionModification()) {
        this.message = [
          {severity:'warn', summary: this.translate.getElement("AVIS_VALEUR.MESSAGE.NO_MESSAGE_DETECTED") }
        ];
        this.spinnerService.hide();
        return null;
      }

      return this.avisValeurService.updateAvisValeur(avisValeur);
    }
    return null;

  }

  /**
   * Enregistre les modifications (edition de l'avis de valeur)
   */
  sauvegarder() {
    this.spinnerService.show();
    let obs = this.prepareSauvegarder();
    if (obs != null) {
      obs.subscribe({
        next: (data) => {
          if(data) {
            this.messageStorage.saveMessage('AVIS_VALEUR.MESSAGE.SAVE_SUCCES', data.numeroDossier);
            window.location.reload();
          }else {
            this.message =[ {severity:'error', summary : this.translate.getElement('AVIS_VALEUR.MESSAGE.SAVE_ERROR')}];
          }
          this.spinnerService.hide();
          window.scrollTo(0,0);
        },
        error: () => {
          this.message =[ {severity:'error', summary : this.translate.getElement('AVIS_VALEUR.MESSAGE.SAVE_ERROR')}];
          this.spinnerService.hide();
          window.scrollTo(0,0);
        }
      });
    }
  }

  genererAvisDeValeur(idDossier : number)  {

    if(!this.verifyValue()) {
      if (this.form.invalid) {
        this.message =[ {severity:'error', summary : this.translate.getElement('AVIS_VALEUR.MESSAGE.CHAMPS_OBLIGATOIRE')}];
        this.spinnerService.hide();
        return;
      }
      this.avisValeurService.generationAvisDeValeur(idDossier).subscribe({
        next: (data) => {
          if (data) {
            this.messageStorage.saveMessage('AVIS_VALEUR.MESSAGE.GENERER', this.avisValeur?.numeroDossier, AvisValeurComponent.GENERER_AVIS_VALEUR);
            window.location.reload();
            window.scrollTo(0, 0);
          } else {
            this.message = [{
              severity: 'error',
              summary: this.translate.getElement('AVIS_VALEUR.MESSAGE.GENERER_ERROR')
            }];
            this.spinnerService.hide();
            window.scrollTo(0, 0);
          }
        }, error: () => {
          this.message = [{
            severity: 'error',
            summary: this.translate.getElement('AVIS_VALEUR.MESSAGE.GENERER_ERROR')
          }];
          this.spinnerService.hide();
          window.scrollTo(0, 0);
        }
      });
    }
  }

  /**
   * Générer un avis de valeur
   * @param idDossier
   */
  submitGenererAvisDeValeur(idDossier : number) {
    this.submitted = true;
    this.submittedRequired = true;
    this.spinnerService.show();
    this.updateAvisValeur();
    if(this.detectionModification()) {
      this.genererAvisDeValeur(idDossier);
    }else {
      let obs = this.prepareSauvegarder();
      if (obs != null) {
        obs.subscribe({
          next : (data) => {
            if(data) {
              this.genererAvisDeValeur(idDossier);
            }else {
              this.message = [{
                severity: 'error',
                summary: this.translate.getElement('AVIS_VALEUR.MESSAGE.SAVE_ERROR')
              }];
              this.spinnerService.hide();
            }
          },
          error : () => {
            this.message = [{
              severity: 'error',
              summary: this.translate.getElement('AVIS_VALEUR.MESSAGE.SAVE_ERROR')
            }];
            this.spinnerService.hide();
          }

        })
      } else {
        this.spinnerService.hide();
      }
    }

  }

  sendForValidation(idDossier : number) {
    this.avisValeurService.sendForValidation(idDossier).subscribe(
      data => {
        if(data) {
          let url = localStorage.getItem(AvisValeurComponent.PREVIOUS_PAGE_URL_AVIS);
          if(url) {
            localStorage.removeItem(AvisValeurComponent.PREVIOUS_PAGE_URL_AVIS);
            this.messageStorage.removeMessage();
            this.router.navigateByUrl(url);
          }else {
            this.router.navigateByUrl("/dashboard");
          }
          this.spinnerService.hide();
        }else {
          this.message =[ {severity:'error', summary : this.translate.getElement('AVIS_VALEUR.MESSAGE.ERROR_CONDITION_SEND_FOR_VALIDATION', this.avisValeur.numeroDossier)}];
        }
        this.spinnerService.hide();
        window.scrollTo(0,0);
      },error => {
        if(error.status === 412) {
          this.message =[ {severity:'error', summary : this.translate.getElement('AVIS_VALEUR.MESSAGE.ERROR_SEND_FOR_VALIDATION', this.avisValeur.numeroDossier)}];
        }else {
          this.message =[ {severity:'error', summary : this.translate.getElement('AVIS_VALEUR.MESSAGE.ERROR_SEND_FOR_VALIDATION', this.avisValeur.numeroDossier)}];
        }
        this.spinnerService.hide();
        window.scrollTo(0,0);
      }
    )
  }


  /**
   * Envoi de l'avis de valeur pour validation
   */
  submitSendForValidation(idDossier : number) {
    this.submittedRequired = true;
    this.spinnerService.show();
    this.updateAvisValeur();
    if(this.detectionModification()) {
      this.sendForValidation(idDossier);
    }else {
      let obs = this.prepareSauvegarder();
      if(obs != null) {
        obs.subscribe({
          next: (data) => {
            if(data) {
              let obsGenererAV = this.avisValeurService.generationAvisDeValeur(idDossier);
              if(obsGenererAV) {
                obsGenererAV.subscribe({
                  next : (data) => {
                    if(data) {
                      this.sendForValidation(idDossier);
                    }
                  },
                  error : () => {
                    window.scrollTo(0,0);
                    this.message =[ {severity:'error', summary : this.translate.getElement('AVIS_VALEUR.MESSAGE.GENERER_ERROR', this.avisValeur.idDossier.toString())}];
                    this.spinnerService.hide();
                  }
                })
              }else {
                window.scrollTo(0,0);
                this.message =[ {severity:'error', summary : this.translate.getElement('AVIS_VALEUR.MESSAGE.GENERER_ERROR', this.avisValeur.idDossier.toString())}];
                this.spinnerService.hide();
              }
            }
            else {
              this.message =[ {severity:'error', summary : this.translate.getElement('AVIS_VALEUR.MESSAGE.GENERER_ERROR')}];
              this.spinnerService.hide();
              window.scrollTo(0,0);
            }
          },
          error: () => {
            this.message =[ {severity:'error', summary : this.translate.getElement('RAPPORT_EXPERTISE.MESSAGE.SAVE_ERROR')}];
            this.spinnerService.hide();
            window.scrollTo(0,0);
          }
        });
      }
    }

  }

  /**
   * detection de modification
   */
  detectionModification() {
    return !this.changeDetectedMission && !this.changeDetectedDesignationSituationJ
      && !this.changeDetectedSituationGeo && !this.changeDetectedDescription
      && !this.changeDetectedElementsAppreciation && !this.changeDetectedSituationGeo
      && !this.changeDetectedSituationLocative && !this.changeDetectedEvaluation ;
  }

  /**
   * Vérifie que les champs avec mise en forme ne sont pas en erreur
   */
  hasErroTextFields() {
    if( this.f?.mission['controls']?.piecesCommuniques?.errors?.hasError
        || this.f?.designation['controls']?.numeroLots?.errors?.hasError
        || this.f?.designation['controls']?.refCadastrales?.errors?.hasError
        || this.f?.designation['controls']?.commentTypeRegimej?.errors?.hasError
        || this.f?.situationGeo['controls']?.situation?.errors?.hasError
        || this.f?.description['controls']?.terrain?.errors?.hasError
        || this.f?.description['controls']?.construction?.errors?.hasError
        || this.f?.description['controls']?.distribution?.errors?.hasError
        || this.f?.description['controls']?.amenagement?.errors?.hasError
        || this.f?.description['controls']?.etatgeneral?.errors?.hasError
        || this.f?.description['controls']?.observation?.errors?.hasError
        || this.f?.elementApreciation['controls']?.favorable?.errors?.hasError
        || this.f?.elementApreciation['controls']?.defavorable?.errors?.hasError
        || this.f?.evaluation['controls']?.descmethode?.errors?.hasError
    ) {
      this.message = [
        {severity:'error', summary: this.translate.getElement("AVIS_VALEUR.MESSAGE.ERROR_FORM") }
      ];
      window.scrollTo(0,0);
      this.spinnerService.hide();
      return true;
    }
    return false;
  }

  verifyValue() {
    if((this.form.value?.evaluation?.decote  && (this.form.value?.evaluation?.decote < 0 || this.form.value?.evaluation?.decote > 100))
      ||  (this.form.value?.description?.surface && this.form.value?.description?.surface < 1)) {
      this.message = [
        {severity:'error', summary: this.translate.getElement("AVIS_VALEUR.MESSAGE.ERROR_FORM") }
      ];
      window.scrollTo(0,0);
      this.spinnerService.hide();
      return true;
    }
    return false;
  }

  get f(): { [key: string]: AbstractControl } {
    return this.form.controls;
  }

  getAllParamTypeValeurRapport()  {
    this.avisValeurService.getAllParamTypeValeur().subscribe(
      data => {
        this.paramTypeValeur = data;
      },error => {

      }
    )
  }

  getAllParamOccupation()  {
    this.avisValeurService.getAllParamOccupation().subscribe(
      data => {
        this.paramOccupation = data;
      },error => {

      }
    )
  }

  /**
   * initialisation du formulaire
   */
  initForm() {
    const avisValeur : AvisValeur = this.avisValeur;

    this.form = this.fb.group({
      mission : this.initFormMission(avisValeur),
      designation : this.initDesignationForm(avisValeur),
      situationGeo : this.initsituationGeo(avisValeur),
      description : this.initDescriptionForm(avisValeur),
      elementApreciation : this.initElementApreciationForm(avisValeur),
      situationLocative : this.initSituationLocative(avisValeur),
      evaluation : this.initEvaluationForm(avisValeur)
    });
  }

  /**
   * Initialisation formulaire section evaluation
   * @param avisValeur
   */
  initEvaluationForm(avisValeur : AvisValeur) : FormGroup {
    return  new FormGroup({
      methodeCalcul : new FormControl(avisValeur?.methodeValorisation, Validators.required),
      descmethode : new FormControl(avisValeur?.descmethode,
        [Validators.required,
          Validation.patternValidatorMaxLengthCaracter(1000, {hasError: true}),
          Validation.patternValidatorEmptyCaracter({hasEmptyCaracterError: true})
        ]),
      valeurGlobaleEstime : new FormControl(avisValeur?.valeurGlobaleEstimee),
      regimeFiscalVenal : new FormControl(avisValeur?.paramRegimeFiscalVenal),
      valeurLocative : new FormControl(avisValeur?.valeurLocative),
      regimeFiscalLocatif : new FormControl(avisValeur?.paramRegimeFiscalLocatif),
      valeurApresTravaux : new FormControl(avisValeur?.valeurApresTravaux),
      regimefiscalApresTraveaux : new FormControl(avisValeur?.paramRegimeFiscalApresTravaux),
      valeurFuturAchevement : new FormControl(avisValeur?.valeurFuturAchevement),
      regimefiscalFuturAchevement : new FormControl(avisValeur?.paramRegimeFiscalEtatFuturAchevement),

      liquidite : new FormControl(avisValeur?.paramLiquidite),
      delaiCommercialisation : new FormControl(avisValeur?.delaiCommercialisation),
      decote : new FormControl(avisValeur?.decote, [Validators.min(0), Validators.max(100)]),
      prixM2ValeurGlobaleEstime : new FormControl(null),
      prixM2ValeurLocative : new FormControl(null),
      prixM2ValeurApresTravaux : new FormControl(null),
      prixM2ValeurFuturAchevement : new FormControl(null),
      etablissement : new FormControl(avisValeur?.paramVille, Validators.required),
    });
  }

  /**
   * initialisation formulaire section situation locative
   * @param avisValeur
   */
  initSituationLocative(avisValeur : AvisValeur) {
    return  new FormGroup({
      cdtOccup : new FormControl(avisValeur?.occupation,
        [Validators.required,  Validation.patternValidatorMaxLengthCaracter(80, {hasError: true}),
          Validators.pattern(this.configSizeInput.regexPattern)]),
      dureeBail : new FormControl(avisValeur?.dureebail,
        [Validation.patternValidatorMaxLengthCaracter(16, {hasError: true}),  Validators.pattern(this.configSizeInput.regexPattern)]),
      priseEffetBail: new FormControl(avisValeur?.priseeffetbail,
        [Validation.patternValidatorMaxLengthCaracter(16, {hasError: true}), Validators.pattern(this.configSizeInput.regexPattern)]),
      loyer : new FormControl(avisValeur?.loyer, Validators.maxLength(12)),
      regimechargeloyer : new FormControl(null),
      periodiciteloyer : new FormControl(null),
      loyerm2 : new FormControl(avisValeur?.loyerm2),
    });
  }

  /**
   * initialisation formulaire section situation géographique
   * @param avisValeur
   */
  initsituationGeo(avisValeur : AvisValeur) : FormGroup {
    return  new FormGroup({
      situation : new FormControl(avisValeur?.situationGeo,
        [Validators.required,
          Validation.patternValidatorMaxLengthCaracter(2000, {hasError: true}),
          Validation.patternValidatorEmptyCaracter({hasEmptyCaracterError: true})
        ]),
    });
  }

  /**
   * initialisation formulaire section Mission
   * @param avisValeur
   */
  initFormMission(avisValeur : AvisValeur) : FormGroup {
    return  new FormGroup({
      typeValeur : new FormControl(avisValeur?.paramTypeValeur, Validators.required),
      typeOccupation : new FormControl( avisValeur?.paramOccupation, Validators.required),
      piecesCommuniques : new FormControl(avisValeur?.piecesTransmises,
        [Validators.required,
          Validation.patternValidatorMaxLengthCaracter(400, {hasError: true}),
          Validation.patternValidatorEmptyCaracter({hasEmptyCaracterError: true})
        ]),
    });
  }

  /**
   * initilisation section désignation
   * @param avisValeur
   */
  initDesignationForm(avisValeur : AvisValeur) : FormGroup {
    return  new FormGroup({
      regimeJuridique : new FormControl(avisValeur?.paramTypeRegimej, Validators.required),
      commentTypeRegimej : avisValeur?.paramTypeRegimej?.idTypeRegimej === 4 ?
        new FormControl(avisValeur?.commentTypeRegimej,
          [Validators.required ,
            Validation.patternValidatorMaxLengthCaracter(96, {hasError: true}),
            Validation.patternValidatorEmptyCaracter({hasEmptyCaracterError: true})
          ]) : new FormControl(avisValeur?.commentTypeRegimej,
        [Validation.patternValidatorMaxLengthCaracter(96, {hasError: true})]),
      natureBiens : new FormControl(avisValeur?.typeBiens, Validators.required),
      parkingAnnexes : new FormControl( avisValeur?.parking,
        [Validation.patternValidatorMaxLengthCaracter(80, {hasError: true}), Validators.pattern(this.configSizeInput.regexPattern)]),
      numeroLots : new FormControl( avisValeur?.numlot, Validation.patternValidatorMaxLengthCaracter(100, {hasError: true})),
      refCadastrales : new FormControl(avisValeur?.designationcadastre, Validation.patternValidatorMaxLengthCaracter(100, {hasError: true})),
      superfTerrain :new FormControl(avisValeur?.supTerrain),
    })
  }

  checkIfObservationIsRequired()  {
    return (!this.form?.value?.description?.terrain || this.form?.value?.description?.terrain?.replace(/<[^>]*>/g, '')?.trim()?.length === 0)
      && (!this.form?.value?.description?.construction || this.form?.value?.description?.construction?.replace(/<[^>]*>/g, '')?.trim()?.length === 0)
      && (!this.form?.value?.description?.distribution || this.form?.value?.description?.distribution?.replace(/<[^>]*>/g, '')?.trim()?.length === 0)
      && (!this.form?.value?.description?.amenagement || this.form?.value?.description?.amenagement?.replace(/<[^>]*>/g, '')?.trim()?.length === 0)
      && (!this.form?.value?.description?.etatgeneral || this.form?.value?.description?.etatgeneral?.replace(/<[^>]*>/g, '')?.trim()?.length === 0);

  }

  /**
   * initialisation section descritption
   * @param avisValeur
   */
  initDescriptionForm(avisValeur : AvisValeur) : FormGroup {
    let surface =  this.typeSurface(avisValeur?.paramSurface, avisValeur);
    this.avisValeurObservableService.sendSurface(surface);
    return  new FormGroup({
      terrain : new FormControl(avisValeur?.terrain, Validation.patternValidatorMaxLengthCaracter(200, {hasError: true})),
      dateConstruction : new FormControl(avisValeur?.dateConstruction, Validators.pattern(this.configSizeInput.regexPattern)),
      nbNiveau : new FormControl(avisValeur?.niveau,
        [Validation.patternValidatorMaxLengthCaracter(20, {hasError: true}), Validators.pattern(this.configSizeInput.regexPattern)]),
      typeSurfaces : new FormControl(avisValeur?.paramSurface, Validators.required),
      surface : new FormControl(surface , [Validators.required, Validators.min(1)]),
      construction : new FormControl(avisValeur?.construction,  Validation.patternValidatorMaxLengthCaracter(200, {hasError: true})),
      distribution : new FormControl(avisValeur?.distribution,  Validation.patternValidatorMaxLengthCaracter(300, {hasError: true})),
      amenagement : new FormControl(avisValeur?.amenagement,  Validation.patternValidatorMaxLengthCaracter(400, {hasError: true})),
      etatgeneral : new FormControl(avisValeur?.etatGeneral,  Validation.patternValidatorMaxLengthCaracter(200, {hasError: true})),
      observation : this.checkIfObservationIsRequired() ?
        new FormControl(avisValeur?.observation,  [
          Validators.required, Validation.patternValidatorMaxLengthCaracter(200, {hasError: true}),
          Validation.patternValidatorEmptyCaracter({hasEmptyCaracterError: true})]) :
        new FormControl(avisValeur?.observation,  Validation.patternValidatorMaxLengthCaracter(200, {hasError: true}))
    })
  }

  /***
   * initilaistion formulaire section éléments d'appreciation
   * @param avisValeur
   */
  initElementApreciationForm(avisValeur : AvisValeur) : FormGroup {
    return  new FormGroup({
      favorable : new FormControl(avisValeur?.favorable,
        [Validators.required,
          Validation.patternValidatorMaxLengthCaracter(150, {hasError: true}),
          Validation.patternValidatorEmptyCaracter({hasEmptyCaracterError: true})
        ]),
      defavorable : new FormControl(avisValeur?.defavorable,
        [Validators.required,
          Validation.patternValidatorMaxLengthCaracter(150, {hasError: true}),
          Validation.patternValidatorEmptyCaracter({hasEmptyCaracterError: true})]),
    })
  }

  /**
   * validation des éléments du section description selon la RG
   */
  validationDescriptionForm(avisValeur : AvisValeur) {
    let description = this.form.value.description;
    this.changeDetectedDescription = false;
    if(description.typeSurfaces !== avisValeur?.paramSurface
      || description.terrain !== avisValeur?.terrain
      || description.construction !== avisValeur?.construction
      || description.distribution !== avisValeur?.distribution
      || description.amenagement !== avisValeur?.amenagement
      || description.etatgeneral !== avisValeur?.etatGeneral
      || description.observation !== avisValeur?.observation
      || (description.surface && description.surface !== avisValeur?.surface)
      || description.dateConstruction !==  avisValeur?.dateConstruction
      || description.nbNiveau !== avisValeur?.niveau
    ){

      avisValeur.paramSurface = description.typeSurfaces;
      avisValeur.surface = description.surface;
      avisValeur.niveau = description.nbNiveau;
      avisValeur.dateConstruction = description.dateConstruction;
      this.setTypeSurface(description?.typeSurfaces, description.surface, avisValeur);

      if(Validation.validatoionEmptyCaracterFielTextWithShaping(description.terrain)) {
        avisValeur.terrain = null;
      }else {
        avisValeur.terrain =  description.terrain;
      }

      if(Validation.validatoionEmptyCaracterFielTextWithShaping(description.distribution)) {
        avisValeur.distribution = null;
      }else {
        avisValeur.distribution =  description.distribution;
      }

      if(Validation.validatoionEmptyCaracterFielTextWithShaping(description.construction)) {
        avisValeur.construction = null;
      }else {
        avisValeur.construction =  description.construction;
      }

      if(Validation.validatoionEmptyCaracterFielTextWithShaping(description.amenagement)) {
        avisValeur.amenagement = null;
      }else {
        avisValeur.amenagement =  description.amenagement;
      }

      if(Validation.validatoionEmptyCaracterFielTextWithShaping(description.etatgeneral)) {
        avisValeur.etatGeneral = null;
      }else {
        avisValeur.etatGeneral =  description.etatgeneral;
      }

      if(Validation.validatoionEmptyCaracterFielTextWithShaping(description.observation)) {
        avisValeur.observation = null;
      }else {
        avisValeur.observation =  description.observation;
      }

      this.changeDetectedDescription = true;
    }
  }

  setTypeSurface(typeSurfaces : any, surface : number, avisValeur : AvisValeur) {
    let idSurface =typeSurfaces?.idSurface;
    switch (idSurface) {
      case 0 :
        avisValeur.sup = null;
        avisValeur.su = null;
        avisValeur.sdhop = null;
        avisValeur.sh = surface;
        return avisValeur;
      case 1 :
        avisValeur.sup = null;
        avisValeur.sh = null;
        avisValeur.sdhop = null;
        avisValeur.su = surface;
        return avisValeur;
      case 2 :
        avisValeur.su = null;
        avisValeur.sh = null;
        avisValeur.sdhop = null;
        avisValeur.sup = surface;
        return avisValeur;
      case 3 :
        avisValeur.sup = null;
        avisValeur.su = null;
        avisValeur.sh = null;
        avisValeur.sdhop = surface;
        return avisValeur;
      default :
        return null;
    }
  }

  /**
   * validation des éléments du section mission selon la RG
   */
  validationMissionForm(avisValeur : AvisValeur) {
    let missionForm = this.form.value.mission;
    this.changeDetectedMission = false;
    if(
       missionForm.typeOccupation !== avisValeur?.paramOccupation
      || missionForm.piecesCommuniques !== avisValeur?.piecesTransmises
      || missionForm.typeValeur !==  avisValeur?.paramTypeValeur
    ) {
      avisValeur.paramOccupation = missionForm.typeOccupation;

      if(!Validation.validatoionEmptyCaracterFielTextWithShaping(missionForm.piecesCommuniques)) {
        avisValeur.piecesTransmises = missionForm.piecesCommuniques;
      }else {
        avisValeur.piecesTransmises = null;
      }

      avisValeur.paramTypeValeur = missionForm.typeValeur;

      this.changeDetectedMission = true;
    }
  }

  /**
   * validation des éléments du section Designation / Situation Juridique selon la RG
   */
  validationDesignationSituationJuridiqueForm(avisValeur : AvisValeur) {
    let situationJuridique = this.form.value.designation;
    this.changeDetectedDesignationSituationJ = false;
    if(situationJuridique.parkingAnnexes !== avisValeur?.parking
      || situationJuridique.numeroLots !== avisValeur?.numlot
      || situationJuridique.refCadastrales !== avisValeur?.designationcadastre
      || situationJuridique.superfTerrain !== avisValeur?.supTerrain
      || (avisValeur?.typeBiens && situationJuridique.natureBiens?.code !== avisValeur?.typeBiens?.code)
      || situationJuridique.regimeJuridique?.idTypeRegimej !==  avisValeur?.paramTypeRegimej?.idTypeRegimej
      || situationJuridique.commentTypeRegimej !== avisValeur?.commentTypeRegimej
    ) {

      avisValeur.parking = situationJuridique.parkingAnnexes;
      avisValeur.supTerrain = situationJuridique.superfTerrain;

      avisValeur.typeBiens = situationJuridique.natureBiens;

      avisValeur.paramTypeRegimej = situationJuridique.regimeJuridique;

      if(Validation.validatoionEmptyCaracterFielTextWithShaping(situationJuridique.numeroLots)) {
        avisValeur.numlot = null;
      }else {
        avisValeur.numlot = situationJuridique.numeroLots;
      }

      if(Validation.validatoionEmptyCaracterFielTextWithShaping(situationJuridique.descmethode)) {
        avisValeur.descmethode = null;
      }else {
        avisValeur.descmethode = situationJuridique.descmethode;
      }

      if(Validation.validatoionEmptyCaracterFielTextWithShaping(situationJuridique.refCadastrales)) {
        avisValeur.designationcadastre = null;
      }else {
        avisValeur.designationcadastre = situationJuridique.refCadastrales;
      }

      if(Validation.validatoionEmptyCaracterFielTextWithShaping(situationJuridique.commentTypeRegimej)) {
        avisValeur.commentTypeRegimej = null;
      }else {
        avisValeur.commentTypeRegimej = situationJuridique.commentTypeRegimej;
      }

      this.changeDetectedDesignationSituationJ = true;
    }

  }

  /**
   * validation des éléments du section Situation géographique selon la RG
   */
  validationSituationGeoForm(avisValeur : AvisValeur) {
    let situationGeo = this.form.value.situationGeo;
    this.changeDetectedSituationGeo = false;
    if(situationGeo.situation !==  avisValeur?.situationGeo) {

      if(Validation.validatoionEmptyCaracterFielTextWithShaping(situationGeo.situation)) {
        avisValeur.situationGeo = null;
      }else {
        avisValeur.situationGeo = situationGeo.situation;
      }

      this.changeDetectedSituationGeo = true;
    }
  }

  /**
   * validation des éléments du section eléments d'appréciation selon la RG
   */
  validationElementsAppreciationForm(avisValeur : AvisValeur) {
    let elementApreciation = this.form.value.elementApreciation;
    this.changeDetectedElementsAppreciation = false;
    if(elementApreciation.favorable !==  avisValeur?.favorable
      || elementApreciation.defavorable !==  avisValeur?.defavorable
    ) {
      if(Validation.validatoionEmptyCaracterFielTextWithShaping(elementApreciation.favorable)) {
        avisValeur.favorable = null;
      }else {
        avisValeur.favorable = elementApreciation.favorable;
      }

      if(Validation.validatoionEmptyCaracterFielTextWithShaping(elementApreciation.defavorable)) {
        avisValeur.defavorable = null;
      }else {
        avisValeur.defavorable = elementApreciation.defavorable;
      }

      this.changeDetectedElementsAppreciation = true;
    }
  }

  /**
   * Validation formulaire et detection changement situation locative
   * @param avisValeur : avis de valeur
   * @param dosAvisSansVisite : dossier avis sans visite
   * @param biec : bien caracteristique
   */
  validationSituationLocativeForm(avisValeur : AvisValeur) {
    let situationLocative = this.form.value.situationLocative;
    this.changeDetectedSituationLocative = false;
    if(situationLocative.dureeBail !==  avisValeur?.dureebail
      || situationLocative.priseEffetBail !==  avisValeur?.priseeffetbail
      || situationLocative.loyer !==  avisValeur?.loyer
      || (situationLocative?.regimechargeloyer?.idRegimeFiscalL &&
        situationLocative?.regimechargeloyer?.idRegimeFiscalL !==  avisValeur?.regimechargeloyer)
      || (situationLocative?.periodiciteloyer?.id &&
        situationLocative?.periodiciteloyer?.id !==  avisValeur?.periodiciteloyer )
      || situationLocative.loyerm2 !==  avisValeur?.loyerm2
      || situationLocative.cdtOccup !==  avisValeur?.occupation
    ) {
      avisValeur.dureebail = situationLocative.dureeBail;
      avisValeur.priseeffetbail = situationLocative.priseEffetBail;
      avisValeur.loyer = situationLocative.loyer;
      avisValeur.regimechargeloyer = situationLocative?.regimechargeloyer?.idRegimeFiscalL;
      avisValeur.periodiciteloyer = situationLocative?.periodiciteloyer?.id;
      avisValeur.loyerm2 = situationLocative.loyerm2;
      avisValeur.occupation = situationLocative.cdtOccup;
      this.changeDetectedSituationLocative = true;
    }
  }

  /**
   *
   * @param avisValeur
   * @param dossierAvisSansVisite
   */
  validationEvaluationForm(avisValeur : AvisValeur) {
    let evaluation = this.form.value.evaluation;
    this.changeDetectedEvaluation = false;

    if(evaluation.valeurGlobaleEstime !== avisValeur.valeurGlobaleEstimee
      || evaluation.valeurLocative !== avisValeur?.valeurLocative
      || evaluation.valeurApresTravaux !== avisValeur?.valeurApresTravaux
      || evaluation.valeurFuturAchevement !== avisValeur?.valeurFuturAchevement
      || evaluation.liquidite !== avisValeur?.paramLiquidite
      || (evaluation?.delaiCommercialisation?.idDelaiCommercialisation &&
        evaluation?.delaiCommercialisation?.idDelaiCommercialisation !== avisValeur?.delaiCommercialisation?.idDelaiCommercialisation)
      || evaluation.regimeFiscalVenal !== avisValeur?.paramRegimeFiscalVenal
      || evaluation.regimeFiscalLocatif !== avisValeur?.paramRegimeFiscalLocatif
      || evaluation.regimefiscalApresTraveaux !== avisValeur?.paramRegimeFiscalApresTravaux
      || evaluation.regimefiscalFuturAchevement !== avisValeur?.paramRegimeFiscalEtatFuturAchevement
      || evaluation.decote !== avisValeur?.decote
      || evaluation.methodeCalcul !==  avisValeur?.methodeValorisation
      || evaluation.descmethode !== avisValeur?.descmethode
      || evaluation.etablissement !== avisValeur?.paramVille
      || this.form.value.mission.typeValeur !== this.avisValeur.paramTypeValeur
    ) {

      avisValeur.methodeValorisation = evaluation.methodeCalcul;
      avisValeur.paramVille = evaluation.etablissement;

      this.setValeurTypeVlauer(this.form?.value?.mission?.typeValeur, avisValeur, evaluation);

      if(Validation.validatoionEmptyCaracterFielTextWithShaping(evaluation.descmethode)) {
        avisValeur.descmethode = null;
      }else {
        avisValeur.descmethode = evaluation.descmethode;
      }
      this.changeDetectedEvaluation = true;
    }

  }


  setValeurTypeVlauer(typeValeur : any, avisValeur : AvisValeur, evaluation : any) {

    switch (typeValeur?.idTypeValeur) {
      case 1 :

        avisValeur.valeurLocative = null;
        avisValeur.valeurApresTravaux = null;
        avisValeur.valeurFuturAchevement = null;
        avisValeur.valeurGlobaleEstimee = evaluation.valeurGlobaleEstime;

        avisValeur.paramRegimeFiscalLocatif = null;
        avisValeur.paramRegimeFiscalApresTravaux = null;
        avisValeur.paramRegimeFiscalEtatFuturAchevement = null;
        avisValeur.paramRegimeFiscalVenal = evaluation.regimeFiscalVenal;

        avisValeur.paramLiquidite = evaluation.liquidite
        avisValeur.delaiCommercialisation = evaluation?.delaiCommercialisation;
        avisValeur.decote = evaluation.decote;

        return avisValeur;
      case 2 :

        avisValeur.valeurApresTravaux = null;
        avisValeur.valeurFuturAchevement = null;
        avisValeur.valeurGlobaleEstimee =null;
        avisValeur.valeurLocative = evaluation.valeurLocative;

        avisValeur.paramRegimeFiscalApresTravaux = null;
        avisValeur.paramRegimeFiscalEtatFuturAchevement = null;
        avisValeur.paramRegimeFiscalVenal = null;
        avisValeur.paramRegimeFiscalLocatif = evaluation.regimeFiscalLocatif;

        avisValeur.paramLiquidite = null;
        avisValeur.delaiCommercialisation = null;
        avisValeur.decote = null;

        return avisValeur;
      case 3 :

        avisValeur.valeurLocative = null;
        avisValeur.valeurFuturAchevement = null;
        avisValeur.valeurApresTravaux = evaluation.valeurApresTravaux;
        avisValeur.valeurGlobaleEstimee = evaluation.valeurGlobaleEstime;

        avisValeur.paramRegimeFiscalEtatFuturAchevement = null;
        avisValeur.paramRegimeFiscalLocatif = null;
        avisValeur.paramRegimeFiscalApresTravaux = evaluation.regimefiscalApresTraveaux;
        avisValeur.paramRegimeFiscalVenal = evaluation.regimeFiscalVenal;

        avisValeur.paramLiquidite = evaluation.liquidite;
        avisValeur.delaiCommercialisation = evaluation?.delaiCommercialisation;
        avisValeur.decote = evaluation.decote;

        return avisValeur;
      case 4 :

        avisValeur.valeurLocative = null;
        avisValeur.valeurApresTravaux = null;
        avisValeur.valeurGlobaleEstimee = null;
        avisValeur.valeurFuturAchevement = evaluation.valeurFuturAchevement;

        avisValeur.paramRegimeFiscalVenal = null;
        avisValeur.paramRegimeFiscalLocatif = null;
        avisValeur.paramRegimeFiscalApresTravaux = null;
        avisValeur.paramRegimeFiscalEtatFuturAchevement = evaluation.regimefiscalFuturAchevement;

        avisValeur.paramLiquidite = null;
        avisValeur.delaiCommercialisation = null;
        avisValeur.decote = null;

        return avisValeur;
      case 5 :

        avisValeur.valeurApresTravaux = null;
        avisValeur.valeurFuturAchevement = null;
        avisValeur.valeurLocative = evaluation.valeurLocative;
        avisValeur.valeurGlobaleEstimee = evaluation.valeurGlobaleEstime;

        avisValeur.paramRegimeFiscalApresTravaux = null;
        avisValeur.paramRegimeFiscalEtatFuturAchevement = null;
        avisValeur.paramRegimeFiscalVenal = evaluation.regimeFiscalVenal;
        avisValeur.paramRegimeFiscalLocatif = evaluation.regimeFiscalLocatif;

        avisValeur.paramLiquidite = evaluation.liquidite;
        avisValeur.delaiCommercialisation = evaluation?.delaiCommercialisation;
        avisValeur.decote = evaluation.decote;

        return avisValeur;
      default :
        return null;

    }
  }



  /**
   * Les elements de la navigation pour la redirection à chaque section
   */
  navigationContextuelle() {
    this.anchorItem.push({
      label: this.translate.getElement('AVIS_VALEUR.SECTIONS'),
      items: [
        {
          label: this.translate.getElement('AVIS_VALEUR.GENERALITE'),
          command : event => {
            this.scroolElement('generalites', event);
            this.addIsactiveNavigationContextuelleMenuItem(event);
          }
        },
        {
          label: this.translate.getElement('AVIS_VALEUR.MISSION'),
          command : event => {
            this.scroolElement('mission', event);
            this.addIsactiveNavigationContextuelleMenuItem(event);
          }
        },
        {
          label: this.translate.getElement('AVIS_VALEUR.DESIGNATION_SITUATION_JURIDIQUE'),
          command :(event) => {
            this.scroolElement('situationJuridique', event);
            this.addIsactiveNavigationContextuelleMenuItem(event);
          }
        },
        {
          label: this.translate.getElement('AVIS_VALEUR.SITUATION_GEO'),
          command : event => {
            this.scroolElement('situationGeo', event);
            this.addIsactiveNavigationContextuelleMenuItem(event);
          }
        },
        {label: this.translate.getElement('AVIS_VALEUR.DESCRIPTION'),
          command : event => {
            this.scroolElement('description', event);
            this.addIsactiveNavigationContextuelleMenuItem(event)
          }
        },
        {
          label: this.translate.getElement('AVIS_VALEUR.SITUATION_LOCATIVE'),
          command : event => {
            this.scroolElement('situationLocative', event);
            this.addIsactiveNavigationContextuelleMenuItem(event);
          }
        },
        {
          label: this.translate.getElement('AVIS_VALEUR.ELEMENTS_APPRECIATION'),
          command : event => {
            this.scroolElement('elementAppreciation', event);
            this.addIsactiveNavigationContextuelleMenuItem(event);
          }
        },
        {
          label: this.translate.getElement('AVIS_VALEUR.EVALUATION'),
          command : event => {
            this.scroolElement('evaluation', event);
            this.addIsactiveNavigationContextuelleMenuItem(event);
          }
        },
        {
          label: this.translate.getElement('AVIS_VALEUR.ANNEXES'),
          command : event => {
            this.scroolElement('annexe', event);
            this.addIsactiveNavigationContextuelleMenuItem(event);
          }
        }
      ]
    });



  }

  async getUserConnected() {
    this.profiles = await this.userService.getUserConnectedProfilesById();
  }

  navActionsItem() {

    let itemSave : MenuItem =  {
      label: this.translate.getElement('AVIS_VALEUR.ACTIONS.SAUVEGARDER'),
      icon : 'inc-icon inc-icon-save is-small',
      command : event => {
        this.confirmation.confirm({
          message:this.translate.getElement('AVIS_VALEUR.MESSAGE.SAUVEGARDE'),
          header :  this.translate.getElement('AVIS_VALEUR.ACTIONS.SAUVEGARDER')?.toUpperCase(),
          icon: 'pi pi-exclamation-triangle',
          accept: () => {
            this.sauvegarder();
            window.scrollTo(0,0);
          }
        })

      }
    };

    let itemGenerate : MenuItem =  {
      label: this.translate.getElement('AVIS_VALEUR.ACTIONS.GENERER'),
      icon: 'inc-icon inc-icon-load is-small',
      command : event => {
        this.confirmation.confirm({
          message: this.translate.getElement('AVIS_VALEUR.POPUP_CONFIRMATION_GENERATION'),
          header : this.translate.getElement('AVIS_VALEUR.GENERATION'),
          icon: 'pi pi-exclamation-triangle',
          accept: () => {
            this.submitGenererAvisDeValeur(this.avisValeur?.idDossier);
            window.scrollTo(0,0);
          }
        })
      }
    };

    const isAuthorize : boolean = (this.profiles.includes(ProfilesUser.EXPERT_EXTERNE) || this.profiles.includes(ProfilesUser.EXPERT_INTERNE)) && this.avisValeur.statutDossier === StatutDossier.ENCOURS;

    console.log(isAuthorize);
    console.log(this.profiles);
    console.log(this.existeDocAvisValeur);

    let itemSendForValidation : MenuItem =  {
      label: this.translate.getElement('AVIS_VALEUR.ACTIONS.ENVOYER'),
      icon: 'inc-icon inc-icon-arrow is-small',
      visible : this.existeDocAvisValeur ===  true && isAuthorize,
      command : event => {
        this.confirmation.confirm({
          message: this.translate.getElement('AVIS_VALEUR.POPUP_CONFIRMATION_ENVOYER'),
          header : this.translate.getElement('AVIS_VALEUR.POPUP_CONFIRMATION_ENVOYER_HEADER'),
          icon: 'pi pi-exclamation-triangle',
          accept: () => {
            this.submitSendForValidation(this.avisValeur.idDossier);
            window.scrollTo(0,0);
          }
        })
      }
    };

    let itemOuvrirFicheDossier : MenuItem = {
      label: this.translate.getElement('AVIS_VALEUR.ACTIONS.OUVRIR_FICHE_DOSSIER'),
      icon: 'pi pi-eye',
      routerLink : ['/dossier/fiche', this.avisValeur?.idDossier]
    };

    this.actionsItem = [{
      label: this.translate.getElement('AVIS_VALEUR.ACTIONS.TITLE'),
      items : [itemSave, itemGenerate, itemSendForValidation, itemOuvrirFicheDossier]
    }];


  }

  scroolElement(idElement : string, event : any) {
    const yOffset = -15;
    const y =  document.getElementById(idElement).getBoundingClientRect().top + window.pageYOffset + yOffset;
    window.scrollTo({top: y, behavior: 'smooth'});
    event.originalEvent.target.parentNode.classList.add("is-active");
  }


  addIsactiveNavigationContextuelleMenuItem(event : any) {
    if(this.stock && this.stock.item.label !== event.item.label) {
      this.stock.originalEvent.target.parentNode.classList.remove("is-active")
      event.originalEvent.target.parentNode.classList.add("is-active");
      this.stock = event;
    }else {
      event.originalEvent.target.parentNode.classList.add("is-active");
      this.stock = event;
    }
  }

  typeSurface(paramSurf : any, avisValeur : AvisValeur) : number{

    let idSurface = paramSurf?.idSurface;

    if(paramSurf && avisValeur) {
      switch (idSurface) {
        case 0 :
          return avisValeur.sh;
        case 1 :
          return avisValeur.su;
        case 2 :
          return avisValeur.sup;
        case 3 :
          return avisValeur.sdhop;
        default :
          return null;
      }
    }

    return null;

  }


  async docsContainsAvisDeValeur(avisValeur: AvisValeur) {
    const docs = await firstValueFrom(
      this.documentService.getDocumentsDossier(avisValeur.idBien, false).pipe(take(1))
    )
    .then(data => {
      if (data) {
        this.existeDocAvisValeur = data.some(doc => doc.nom.startsWith('AvisDeValeur') && doc.nom.includes(avisValeur?.numeroDossier));
      }
    });
  }

  ngAfterContentChecked() {
    this.cdref.detectChanges();
  }


  downloadRappotDocument() {
    this.messageService.clear();
    this.documentService.downloadDocumentByType(this.avisValeur.idBien, ConfigConstants.AVIS_VALEUR).subscribe( {
      next : (response : HttpResponse<ArrayBuffer>) => {
        if (response != null && response?.body != null) {
          const fileName = UtilsFonction.getFilenameFromResponse(response, "AVISDEVALEUR" , this.avisValeur?.numeroDossier);
          const blob = new Blob([response.body], { type: 'application/pdf' });
          saveAs(blob, fileName)
        }else {
          this.message = [ {severity:'error', summary :  this.translate.getElement("DOCUMENT.TELECHARGEMENT.ERROR")}];
        }
      } ,
      error : () => {
        this.message = [ {severity:'error', summary :  this.translate.getElement("DOCUMENT.TELECHARGEMENT.ERROR")}];
      }
    });

  }


  closableAvisSucessMessage() {
    this.messageSuccessGenerationAvis = null;
  }

}
