import {LoginRequest} from "./login-request";

export class ChangePasswordRequest {
  login : LoginRequest;
  newPassword : string;

  constructor(login : LoginRequest, newPassword : string) {
    this.login = login;
    this.newPassword = newPassword;
  }
}
