import {Pipe, PipeTransform} from '@angular/core';
import {ConfigConstants} from "../../core/models/enum/config-constants";

@Pipe({
  name: 'iconStatutDossier'
})
export class IconStatutDossierPipe implements PipeTransform {

  transform(value: string, etatEnvoi? : string): unknown {
    switch (value) {
      case 'ACCUEIL' :
        return 'inc-icon-home';
      case 'A_AFFECTER' :
        return 'inc-icon-bell';
      case 'ENCOURS' :
        return 'inc-icon-flag';
      case 'D_ATTENTE_V1' :
        if(etatEnvoi === ConfigConstants.ETAT_ENVOI_PROJET) {
            return 'inc-icon-edit';
        }
        return 'inc-icon-flash';
      case 'A_FACTURER' :
        return 'inc-icon-euro';
      case 'F_ENCOURS' :
        return 'inc-icon-euro';
      case 'FACTUREE' :
        return 'inc-icon-file';
      case 'ANNULE' :
        return 'inc-icon-close';
      case 'RESEARCH' :
        return 'inc-icon-search';
      case 'DEMANDEENCOURS' :
        return 'inc-icon-eye';
      case 'ADD' :
        return 'inc-icon-add';
      case 'PROFIL' :
        return 'inc-icon-settings';
      default :
        return;
    }
  }

}
