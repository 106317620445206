import { environment } from "./environment";

declare global {
    interface Window { config: any; }
}

const runtimeConfig = window.config || {};

// merge environment (build-time environment) and window.config (runtime config).
// window.config is set through /assets/config.js which is loaded by the browser
// at runtime and can be replaced with a deployment-specific file when deploying
// on a Linux server, on Kubernetes (ConfigMap and mount as file)
// or with Docker (bind mount). See the config directory for more information.
const ssoConfig = Object.assign({}, environment.ssoConfig, runtimeConfig.ssoConfig);
export const runtimeEnvironment = Object.assign(
    {}, 
    environment, 
    runtimeConfig, 
    {ssoConfig: ssoConfig});