import {ReferentielValeur} from "../referentiel-valeur";
import {Pays} from "../adresse";

export class FraisFournisseur {
  id : number;
  typesFrais : ReferentielValeur;
  refacturable : boolean;
  pourcentageApportAffaire : number;
  montantHT : number;
  tvaFactureFournisseur : number;
  typeFournisseur : ReferentielValeur;
  paysFournisseurs : Pays;
  raisonSocialeEntitePrincipale : string;
  sousEntity : string;
  serviceDepartement : string;
  nom : string;
  prenom : string;
  devise : string;
  numeroFactureFournisseur : string;
  dateFactureFournisseur : Date;
  dateReceptionFacture : Date
  dateTransmissionFactureCompta : Date;
}
