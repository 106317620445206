import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'fraisExpertise'
})
export class FraisExpertisePipe implements PipeTransform {

  transform(value: string): string {
    if(value) {
      switch (value) {
        case "0" :
          return "Récuperable";
        case "1" :
          return"Irrécupérable";
        default :
          return "";
      }
    }
   return "";
  }

}
