<form [formGroup]="form" >
  <div class="card-large" id="section1">
    <h1 id="situationJuridique">II - {{'AVIS_VALEUR.DESIGNATION_SITUATION_JURIDIQUE' | translateI18n}}</h1>


    <div class="grid">

      <div class="col">
        <section>

          <div class="field input-ghost">
            <label for="natureBiens" class="label">{{'AVIS_VALEUR.LABEL.NATURE_BIEN' | translateI18n}} :</label>
            <p-dropdown [options]="paramTypeBiens" formControlName="natureBiens" optionLabel="libelle" id="natureBiens" name="natureBiens"
                        [ngClass]="{'ng-dirty ng-invalid': submittedRequired && f.natureBiens.errors}"
                        emptyMessage="{{'AVIS_VALEUR.MESSAGE.AUCUN_RESULTAT' | translateI18n}}"
                        placeholder="{{'PLACEHOLDER.CHOOSE' | translateI18n}}" class="obligatoire"></p-dropdown>
          </div>


          <div class="field input-ghost">
            <label for="regimeJuridique" class="label">{{'AVIS_VALEUR.LABEL.REGIME_JURIDIQUE' | translateI18n}} :</label>
            <p-dropdown [options]="paramTypeRegimes" formControlName="regimeJuridique" optionLabel="label" id="regimeJuridique" name="regimeJuridique"
                        [ngClass]="{'ng-dirty ng-invalid': submittedRequired && f.regimeJuridique.errors}"
                        emptyMessage="{{'AVIS_VALEUR.MESSAGE.AUCUN_RESULTAT' | translateI18n}}"
                        placeholder="{{'PLACEHOLDER.CHOOSE' | translateI18n}}"
                        (onChange)="changeSelectionRegimej($event)" class="obligatoire"></p-dropdown>
          </div>

        </section>
      </div>

      <div class="col">
        <section>

          <div class="field input-ghost">
            <label for="parkingAnnexes" class="label">{{'AVIS_VALEUR.LABEL.PARKING_ANNEXE' | translateI18n}} :</label>
            <input type="text" pInputText id="parkingAnnexes" class="inputfield" formControlName="parkingAnnexes"
                   placeholder="{{'PLACEHOLDER.RENSEIGNEZ' | translateI18n}}"
                   [ngClass]="{'ng-dirty ng-invalid': f.parkingAnnexes.hasError('hasError')}" [maxLength]="configSizeInput.PARKING"/>

            <small *ngIf="f.parkingAnnexes?.errors?.pattern" class="p-error block">
              {{'MESSAGE.ERROR.REGEX_PATTERN' | translateI18n}}</small>

            <small *ngIf="f.parkingAnnexes.hasError('hasError')" class="p-error block">
                {{'AVIS_VALEUR.MESSAGE.ERROR_CARACTERE_INPUT' | translateI18n : '80'}}</small>

          </div>



          <div class="field field-line input-ghost">
            <label for="superfTerrain" class="label">{{'AVIS_VALEUR.LABEL.SUPERFICIE_TERRAIN' | translateI18n}} :</label>

            <p-inputNumber formControlName="superfTerrain"
                           id="superfTerrain"
                           mode="decimal"
                           placeholder="{{'PLACEHOLDER.RENSEIGNEZ' | translateI18n}}"
                           suffix=" m²"
                           [size]=20
                           [min]="0"
                           [maxlength]="18"
                           [minFractionDigits]="2"
                           [maxFractionDigits]="5"></p-inputNumber>

          </div>



        </section>
      </div>

      <div class="field col-12 field-line" *ngIf="commentaireRegimej">

        <div class="mb-2">
          <h3 class="m-0" [ngClass]="{'obligatoire' : form.value?.regimeJuridique?.idTypeRegimej == 4}">
            {{'AVIS_VALEUR.LABEL.COMMENT_TYPE_REGIME' | translateI18n}} :</h3>
          <small>{{'AVIS_VALEUR.MESSAGE.MAX_CARACTERE' | translateI18n : '96'}}
            <span [ngClass]="{'is-alert': f.commentTypeRegimej.hasError('hasError')}">({{nbCommentTypeRegimej}} / 96)</span>
          </small>
        </div>

        <div *ngIf="f.commentTypeRegimej.hasError('hasError')">
          <p-messages  [(value)]="msgError96"></p-messages>
        </div>

        <p-editor formControlName="commentTypeRegimej" [style]="{'height':'100px'}" name="commentTypeRegimej"  id="commentTypeRegimej" class="w-full"
                  (onTextChange)="caractCommentTypeRegimejTextChange($event)"
                  [ngClass]="{'editor-error': (submittedRequired && ( f.commentTypeRegimej?.errors?.required || f.commentTypeRegimej?.hasError('hasEmptyCaracterError')) )
                  || f.commentTypeRegimej.hasError('hasError')}">
          <p-header>
            <app-p-editor-header></app-p-editor-header>
          </p-header>
        </p-editor>
      </div>


      <div class="field col-12 field-line">

        <div class="mb-2">
          <h3 class="m-0">{{'AVIS_VALEUR.LABEL.NUMERO_LOT' | translateI18n}} :</h3>
          <small>{{'AVIS_VALEUR.MESSAGE.MAX_CARACTERE' | translateI18n : '100'}}
            <span [ngClass]="{'is-alert':  f.numeroLots.hasError('hasError')}">({{nbCaractNumeroLots}} / 100)</span>
          </small>
        </div>
        <div *ngIf="f.numeroLots.hasError('hasError')">
          <p-messages  [(value)]="msgError"></p-messages>
        </div>
        <p-editor formControlName="numeroLots" [style]="{'height':'100px'}" name="numeroLots"  id="numeroLots" class="w-full"
                  (onTextChange)="caractNumeroLotsTextChange($event)"
                  [ngClass]="{'editor-error': f.numeroLots.hasError('hasError')}">
          <p-header>
            <app-p-editor-header></app-p-editor-header>
          </p-header>
        </p-editor>
      </div>

      <div class="field col-12 field-line">


        <div class="mb-2">
          <h3 class="m-0">{{'AVIS_VALEUR.LABEL.REFERENCE_CADASTRALE' | translateI18n}} :</h3>
          <small>{{'AVIS_VALEUR.MESSAGE.MAX_CARACTERE' | translateI18n : '100'}}
            <span [ngClass]="{'is-alert':  f.refCadastrales.hasError('hasError')}">({{nbRefCadastrales}} / 100)</span>
          </small>
        </div>

        <div *ngIf="f.refCadastrales.hasError('hasError')">
          <p-messages  [(value)]="msgError"></p-messages>
        </div>
        <p-editor formControlName="refCadastrales" [style]="{'height':'100px'}" name="refCadastrales"  id="refCadastrales" class="w-full"
                  [ngClass]="{'editor-error': f.refCadastrales.hasError('hasError')}"
                  (onTextChange)="refCadastralesTextChange($event)">
          <p-header>
            <app-p-editor-header></app-p-editor-header>
          </p-header>
        </p-editor>
      </div>

    </div>

  </div>
</form>

