import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {LoginRequest} from "../../models/user/login-request";
import {LoginResponse} from "../../models/user/login-response";
import {RestDatasource} from "../rest.datasource";
import {OAuthService} from "angular-oauth2-oidc";
import {LocalStorageService} from "../local-storage/local-storage.service";
import {Router} from "@angular/router";
import {MessageStorageService} from "../local-storage/message-storage.service";
import {MessageObservableService} from "../../../shared/observable/message-observable.service";
import { runtimeEnvironment } from "src/environments/runtime-environment";

@Injectable()
export class AuthService {

    constructor(
      private datasource : RestDatasource, private  oauthService : OAuthService,
      private router : Router, private tokenStorageService : LocalStorageService,
      private messageStorage : MessageStorageService,
      private messageObservableService : MessageObservableService) {
    }

    logout() {
      return this.datasource.logout();
    }

    ask2fa(login:string, password:string): Observable<any> {
        return this.datasource.ask2fa(login, password) ;
    }

    authenticate(login:string, password:string, code2fa:string): Observable<LoginResponse> {
        return this.datasource.authenticate(login, password, code2fa) ;
    }

    reinitPassword(login:string, newPassword:string, code2fa:string): Observable<any> {
        return this.datasource.reinitPassword(login, newPassword, code2fa);
    }

    forgotPassWord(email : string) : Observable<any> {
      const loginRequest : LoginRequest = new LoginRequest();
      loginRequest.login = email;
      return this.datasource.forgotPassWord(loginRequest);
    }

    getParamMessageInformationExpertiseFr() {
      return this.datasource.getParamMessageInformationExpertiseFr();
    }

    /**
     * Connexion adfs websso
     */
    login() {
        this.oauthService.initLoginFlow();
   }

    /**
     * deconnexion adfs
     */
    logoutAdfs() {
        this.oauthService.logOut();
    }

    /**
     * connexion par webSSO/Valea et configuration
     */
    configureSSOConnexion() {

      if(runtimeEnvironment.ssoConfig.issuer) {
        this.oauthService.configure(runtimeEnvironment.ssoConfig);
        this.oauthService.setupAutomaticSilentRefresh();
        this.oauthService.loadDiscoveryDocumentAndLogin().then((_ : any) => {
          const claims : any = this.oauthService.getIdentityClaims();
          if(claims) {
            this.datasource.authenticateWebsso().subscribe(response => {
              if(response && response.token) {
                this.tokenStorageService.setSession(response, new Date().getTime());
                this.redirectionAfterLogIn();
              }else {
                this.oauthService.logOut();
                this.router.navigateByUrl('/auth')
              }
              this.tokenStorageService.removeApplyButtonWebSSO();
            }, error => {
              this.messageObservableService.sendMessageErrorWebsso(error.status);
              this.tokenStorageService.removeApplyButtonWebSSO();
            })
          }
        });
      }

    }

  public getMessage() :string {
    return localStorage.getItem(MessageStorageService.MESSAGE);
  }

  redirectionAfterLogIn() {
    if(this.tokenStorageService.getCurrentURL()) {
      this.router.navigateByUrl(this.tokenStorageService.getCurrentURL());
    }else {
      this.router.navigateByUrl("/dashboard");
    }
  }

  public isSignIn(): boolean {

    const expirationTokenValea : number = JSON.parse(localStorage.getItem(LocalStorageService.TOKEN_EXPIRATION_DATE));
    const currentTimeMillis = new Date().getTime();

    return expirationTokenValea > currentTimeMillis;
  }

  isSignOut(): boolean {
    return !this.isSignIn();
  }
}
