import {Component, OnInit} from '@angular/core';
import {TranslateService} from "../../shared/translate/translate.service";

@Component({
  selector: 'app-erro-link-changement-password-expired',
  templateUrl: './erro-link-changement-password-expired.component.html',
  styleUrls: ['./erro-link-changement-password-expired.component.scss']
})
export class ErroLinkChangementPasswordExpiredComponent implements OnInit {



  message : string;


  constructor(private translate : TranslateService) { }

  ngOnInit(): void {
    this.message = this.translate.getElement("AUTH.MESSAGE.INFO.LIEN_EXPIRE");
  }

}
