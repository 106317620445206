<div class="mb-3">
  <button pButton pRipple label="{{'RAPPORT_EXPERTISE.ACTIONS.AJOUTER_NOUVELLE_LIGNE' | translateI18n}}"
          icon="pi pi-plus" class="p-button-sm p-button-rounded" (click)="openNew()"></button>
</div>
<p-messages  *ngIf="message" [(value)]="message" key="table"></p-messages>
<p-table [value]="bienConstructions" [rows]="settings.pagination?.rowsDocument"  [showCurrentPageReport]="true"
         [paginator]="bienConstructions?.length > settings.pagination?.rowsDocument" [rowsPerPageOptions]="settings.pagination?.rowsPerPageOptionsDocument"
         [globalFilterFields]="['construction?.libelle','description']"
         dataKey="id" paginatorPosition="top"
         currentPageReportTemplate="Affichage {first} à {last} sur {totalRecords}" >
  <ng-template pTemplate="header">
    <tr>
      <th></th>
      <th pSortableColumn="commune" style="min-width:15rem">{{'RAPPORT_EXPERTISE.LABEL.ELEMENTS' | translateI18n}}  <p-sortIcon field="construction?.libelle"></p-sortIcon></th>
      <th pSortableColumn="section" style="min-width:10rem">{{'RAPPORT_EXPERTISE.LABEL.DESCRIPTION' | translateI18n}}  <p-sortIcon field="description"></p-sortIcon></th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-biec>
    <tr>
      <td class="text-left">
        <button type="button" pButton pRipple icon="inc-icon inc-icon-trash is-small"
                pTooltip="{{'BUTTON_LABEL.DELETE' | translateI18n}}" tooltipPosition="top"
                class="p-button-rounded mr-2" (click)="deleteBienConstruction(biec)"></button>
        <button type="button" pButton pRipple icon="inc-icon inc-icon-edit is-small"
                pTooltip="{{'BUTTON_LABEL.MODIFY' | translateI18n}}" tooltipPosition="top"
                class="p-button-rounded p-button-success mr-2" (click)="editBienConstruction(biec)"></button>
      </td>
      <td>{{biec.construction?.libelle}}</td>
      <td>{{biec.description}}</td>
    </tr>
  </ng-template>

</p-table>



<p-dialog [(visible)]="bienConstructionDialog" [style]="{width: '500px'}" header="{{'RAPPORT_EXPERTISE.LABEL.AJOUTER_ELEMENT' | translateI18n}}" [modal]="true" styleClass="p-fluid">

  <ng-template pTemplate="content">
    <p-messages  *ngIf="messageBienConstruction" [(value)]="messageBienConstruction" key="dialog"></p-messages>
    <form [formGroup]="bienConstructionForm" class="page-form">
      <div class="field">
        <label for="bieConstruction" [ngClass]="{'p-error': submittedBienConstruction  && formBieConstruction.bieConstruction.invalid}" class="obligatoire">
          {{'RAPPORT_EXPERTISE.LABEL.CONSTRUCTION' | translateI18n}}
        </label>
        <p-dropdown [options]="constructions" formControlName="bieConstruction"
                    optionLabel="libelle" name="bieConstruction"  id="bieConstruction"
                    [ngClass]="{'ng-dirty ng-invalid':  submittedBienConstruction  && formBieConstruction.bieConstruction.invalid}"
                    emptyMessage="{{'DOSSIER.MESSAGE.AUCUN_RESULTAT' | translateI18n}}"
                    placeholder="{{'PLACEHOLDER.CHOOSE' | translateI18n}}" >
        </p-dropdown>
      </div>

      <div class="field">

        <label for="descriptionConstruction" [ngClass]="{'p-error': submittedBienConstruction  && formBieConstruction.descriptionConstruction?.errors}">
          {{'RAPPORT_EXPERTISE.LABEL.DESCRIPTION' | translateI18n}}</label>

        <textarea [rows]="5" id="descriptionConstruction" [cols]="2" style="width: 100%;" pInputTextarea
                  formControlName="descriptionConstruction" [autoResize]="true"
                  (ngModelChange)="detectChangeDescripionConstruction($event)" [maxLength]="75"></textarea>

        <small>{{'RAPPORT_EXPERTISE.MESSAGE.MAX_CARACTERE' | translateI18n : '75'}}
          <span>({{nbCaractDescriptionConstruction}} / 75)</span>
        </small>
      </div>

    </form>
  </ng-template>

  <ng-template pTemplate="footer">
    <button pButton type="button" pRipple label="{{'BUTTON_LABEL.ANNULER' | translateI18n}}" icon="pi pi-times" class="uppercase p-button-secondary p-button-outlined mb-2"
            (click)="hideDialog()"></button>
    <button pButton type="submit" pRipple label="{{'BUTTON_LABEL.ENREGISTRER' | translateI18n}}" icon="pi pi-save" class="ppercase mb-2" (click)="saveBienConstruction()"></button>
  </ng-template>

</p-dialog>
