export class BienCadastre {
  idBienCadastre : number;
  commune : string;
  lieudit : string;
  section : string;
  numeroParcelle : string;
  contenance : number;
  idBien : number;
  idDossier : number;
}
