import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {FilterMatchMode, FilterService, PrimeNGConfig, SelectItem} from "primeng/api";
import {TranslateService} from "../../../translate/translate.service";
import {StatutDossier} from "../../../../core/models/enum/statut-dossier";
import {LivrableDossier} from "../../../../core/models/enum/livrable-dossier";

@Component({
  selector: 'app-filter-text',
  templateUrl: './filter-text.component.html',
  styleUrls: ['./filter-text.component.scss']
})
export class FilterTextComponent implements OnInit {

  @Input()
  field : string;

  @Input()
  typeField : string;

  @ViewChild('pc', { static: true }) pc: ElementRef


  matchModeOptions : string;

  matchDateModeOptions : SelectItem[];

  statutsMatchMode : string = FilterMatchMode.IN;

  livrableMatchMode : string = FilterMatchMode.IN ;

  statuts : any[];

  livrables : any[];


  constructor(private filterService: FilterService,private primengConfig: PrimeNGConfig, private translate : TranslateService) { }

  ngOnInit(): void {

    this.statuts =   Object.keys(StatutDossier).map(key => ({ name: this.translate.getElement("DOSSIER.STATUTS." + key), value: key }));

    this.livrables =   Object.keys(LivrableDossier).map(
      key => ({ name: this.translate.getElement("DOSSIER." + key), value: key })
    );

    this.primengConfig.setTranslation({apply : this.translate.getElement("BUTTON_LABEL.APPLIQUER")});
    this.primengConfig.setTranslation({clear : this.translate.getElement("BUTTON_LABEL.CANCEL")});
    this.primengConfig.setTranslation({dateFormat : this.translate.getElement("DATE_FILTER.FORMAT")});

    this.matchModeOptions = FilterMatchMode.CONTAINS

    this.matchDateModeOptions = [
      { label: this.translate.getElement("DATE_FILTER.AFTER"), value: FilterMatchMode.DATE_AFTER },
      { label: this.translate.getElement("DATE_FILTER.BEFORE"), value: FilterMatchMode.DATE_BEFORE },
      { label: this.translate.getElement("DATE_FILTER.EQUALS"), value: FilterMatchMode.DATE_IS },
      { label: this.translate.getElement("DATE_FILTER.DIFFERENT"), value: FilterMatchMode.DATE_IS_NOT }
    ];

  }

}
