import {Component, OnInit} from '@angular/core';
import {User} from "../../../core/models/user/user.model";
import {ProfilesUser} from "../../../core/models/user/profiles-user";
import {Document} from "../../../core/models/document/document.model";
import {UsefulFunctionService} from "../../../core/service/usefulFunction.service";
import {AuthService} from "../../../core/service/auth/auth.service";
import {LocalStorageService} from "../../../core/service/local-storage/local-storage.service";
import {DossierService} from "../../../core/service/dossier.service";
import {TranslateService} from "../../../shared/translate/translate.service";
import {DialogService, DynamicDialogRef} from 'primeng/dynamicdialog';
import {DocumentService} from 'src/app/core/service/document.service';
import {DocumentsNonLusComponent} from '../documents-non-lus/documents-non-lus.component';
import {CreationDemandeObservableService} from "../../../shared/observable/creation-demande-observable.service";
import {Message} from "primeng/api";
import {Router} from "@angular/router";
import {Observable} from 'rxjs';
import {UtilsFonction} from "../../../shared/providers/utils-fonction";
import {UserService} from "../../../core/service/user/user.service";
import {ReferentielValeur} from "../../../core/models/referentiel-valeur";
import {TypeLivrable} from "../../../core/models/dossiers/type-livrable";
import {runtimeEnvironment} from "../../../../environments/runtime-environment";

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  titre : string;
  user : User;
  documentsNonLus : Document[];

  //TODO : le nombre de document à telecharger
  nbDoc = 0;

  messageParamInfoExpFR : string;
  profile : string ='Demandeur';
  successCreationDemande : Message[];

  lienCreerDemande : any[] =[];
  role : any[];
  statsDossier : any;

  lienRechercheRapide: string;
  ref: DynamicDialogRef;

  creerDemande : boolean = false;
  profiles : ProfilesUser[];
  typesLivrables : ReferentielValeur[];
  isInterne:boolean=false;
  enableAdminTech:boolean=false;

  constructor(
    public usefulFunctionService : UsefulFunctionService,
    private authService : AuthService,
    private localStorageService : LocalStorageService,
    private dossierServcie : DossierService,
    private documentService : DocumentService,
    public dialogService: DialogService,
    private translate : TranslateService,
    private creationDemandeObservable : CreationDemandeObservableService,
    private router:Router, private userService : UserService,
  ) {
    this.isInterne=runtimeEnvironment.isInterne;
    this.enableAdminTech=runtimeEnvironment.enableAdminTech;

  }

  async ngOnInit() {

    this.getParamMessageInformationExpertiseFr();

    this.getSatatsDossier();
    this.getUserConnected();

    await this.userService.getUserConnectedProfilesById()
      .then( (data) =>  this.profiles = data)
      .catch((error) => this.router.navigateByUrl('/role-denied'));

    this.role = Object.values(this.profiles).map(item => String(item));

    this.creationDemandeObservable.onSucessSubjectMessage().subscribe(message => {
      if (message) {
        this.successCreationDemande = [{severity: 'success', summary: message}];
      }
    });

    if (this.profiles.includes(ProfilesUser.ADMINISTRATEUR) || this.profiles.includes(ProfilesUser.ADMINISTRATEUR_TECHNIQUE) || this.profiles.includes(ProfilesUser.ASSISTANTE) || this.profiles.includes(ProfilesUser.ANIMATEUR_RESEAU)) {
      this.lienRechercheRapide = "dossier/recherche/gestionnaire";
    } else if (this.profiles.includes(ProfilesUser.EXPERT_EXTERNE) || this.profiles.includes(ProfilesUser.EXPERT_INTERNE)) {
      this.lienRechercheRapide = "dossier/recherche/expert";
    } else if (this.profiles.includes(ProfilesUser.AGENT)) {
      this.lienRechercheRapide = "dossier/demande/suivi";
    }
    this.getTypeLivrable();

    this.recupererDocumentsNonLus();

  }

  getUserConnected() {
    this.userService.getUserConnectedByToken().then(userConnected => {
      this.user = userConnected;
    });
  }

  recupererDocumentsNonLus(): Observable<Document[]> {
    let observable = this.documentService.getDocumentsNonLus();
    observable.subscribe(data => {
      this.documentsNonLus = data;
      this.nbDoc = data.length;
    });
    return observable;
  }

  showDocumentsNonLus() {
    this.ref = this.dialogService.open(DocumentsNonLusComponent, {
      header: this.nbDoc == 1 ? this.nbDoc + " " + this.translate.getElement('DOCUMENT.NONLUS.HEADER1'): this.nbDoc + " " + this.translate.getElement('DOCUMENT.NONLUS.HEADER2'),
      width: '50vw',
      baseZIndex: 10000,
      dismissableMask: true,
      data: this,
      styleClass : 'p-dynamic-dialog'
    });
  }

  getSatatsDossier() {
    this.dossierServcie.getStatsDossier().subscribe(data => {
      this.statsDossier = data;
    });
  }

  getParamMessageInformationExpertiseFr() {
    this.authService.getParamMessageInformationExpertiseFr().subscribe(data => {
      this.messageParamInfoExpFR = data['message'];
      this.messageParamInfoExpFR =  this.messageParamInfoExpFR.replace(/<[^>]*>/g, '');
    })
  }

  async getTypeLivrable() {
    if (this.profiles.includes(ProfilesUser.AGENT)) {
      this.typesLivrables = await this.dossierServcie.getTypeLivrableUser(0);

      if(this.findTypeLivrable(TypeLivrable.TVRAPPORTXX)) {
        this.creerDemande = true;
        this.lienCreerDemande.push(
          {titre : this.translate.getElement('DASHBORD.LIEN_CREER_DEMANDE.RAPPORT'), lien : "/dossier/demande/creation", type : TypeLivrable.TVRAPPORTXX}
        )
      }

      if(this.findTypeLivrable(TypeLivrable.TVAVISDOSSI)) {
        this.creerDemande = true;
        this.lienCreerDemande.push(
          {titre :this.translate.getElement('DASHBORD.LIEN_CREER_DEMANDE.AVIS'), lien : "/dossier/demande/creation", type : TypeLivrable.TVAVISDOSSI}
        )
      }
    }
  }


  findTypeLivrable(key : string) {
    return this.typesLivrables?.find(type => type.code === key);
  }


  showAdvanceSearch() {

    this.ref = UtilsFonction.rechercheAvancee(this.dialogService, this.translate);

    this.ref.onClose.subscribe((data : any) =>{

      //Une fois la modal fermée, supprimer la classe qui gère le scroll
      const body = document.getElementsByTagName('body')[0];
      body.classList.remove('p-dynamic-dialog-scrollable');

      if(data) {
        if(data) {
          let params = data.params;

          if(params?.adresseBien) {
            const adress = params.adresseBien;
            params['codePostal'] = adress?.codePostal;
            params['ville'] = adress?.ville;
            params['nomVoie'] = adress?.nomVoie;
            params['numeroVoie'] = adress?.numero;
            params['complement'] = adress?.complement;
          }
          delete params['adresseBien'];

          if(params.typeVue && params.typeVue.length > 1) {
            params['typeVues'] = params.typeVue.join();
          }
          delete params['typeVue'];
          this.router.navigate([data.url], {queryParams : params});
        }

      }
    });

  }


}
