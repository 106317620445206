import { Component, OnInit } from '@angular/core';
import {LivrableDossier} from "../../models/enum/livrable-dossier";
import {TranslateService} from "../../../shared/translate/translate.service";
import {DynamicDialogConfig, DynamicDialogRef} from "primeng/dynamicdialog";
import {Dossier} from "../../models/dossiers/dossier";
import {Message} from "primeng/api";
import {ConfigConstants} from "../../models/enum/config-constants";

@Component({
  selector: 'app-rejet-livrable',
  templateUrl: './rejet-livrable.component.html',
  styleUrls: ['./rejet-livrable.component.scss']
})
export class RejetLivrableComponent implements OnInit {

  rejeterLivrableRapport : boolean;
  valueRejectLivrable : string;
  verouillerUploadDocument : boolean;
  codeLivrable : string;
  dossier : Dossier;

  messageReject : Message[];

  constructor(
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private translate : TranslateService
  ) { }

  ngOnInit(): void {
    this.dossier = this.config.data;
    this.codeLivrable = this.dossier.infoExpertise?.typeLivrable?.code;

    if(this.codeLivrable === LivrableDossier.TVAVISDOSSI) {
      this.valueRejectLivrable = ConfigConstants.ETAT_ENVOI_SYNTHESE;
    }
  }

  rejectLivrableRapport() {

    if(!this.valueRejectLivrable) {
      this.messageReject =  [
        {severity:'error', summary:this.translate.getElement('DOSSIER.GESTIONNAIRE.MESSAGE.REJETER.RAPPORT_ERROR')}
      ];
      return;
    }
    this.close(this.valueRejectLivrable);
  }

  hideDialog() {
    this.close();
  }

  close(data? : any) {
    this.ref.close(data);
  }


  returnTexteByTypeLivrable() {
    switch (this.codeLivrable) {
      case LivrableDossier.TVRAPPORTXX :
        return this.translate.getElement("DOSSIER.GESTIONNAIRE.MESSAGE.REJETER.RAPPORT_SYNTHESE");
      case LivrableDossier.TVAVISDOSSI :
        return this.translate.getElement("DOSSIER.GESTIONNAIRE.MESSAGE.REJETER.AVIS_VALEUR_SYNTHESE");
      default :
        return 'N/A';
    }

  }

}
