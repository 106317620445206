import {formatDate} from "@angular/common";
import {LazyLoadEvent} from "primeng/api/lazyloadevent";

export class DossierFilter {
  field : string;
  value : string;
  mode : string;
  listeValeurs : string[];

  constructor (field : string, value : string, mode : string, listeValeurs? : string[]) {
    this.field = field;
    this.value = value;
    this.mode = mode;
    this.listeValeurs = listeValeurs;
  }

  static isDate(dateStr : string) {
    return !isNaN(new Date(dateStr).getDate());
  }

  static isNumber(numStr : any) {
    return !isNaN(numStr);
  }

  static formatFilters(event: LazyLoadEvent): DossierFilter[] {
    let filterStrings : any[] = [];
    for ( const prop in event.filters ) {
      if( prop !== '' ) {
        const filterField: string = prop;
        const filterMeta = event.filters[filterField];
        if (Array.isArray( filterMeta ) ) {
          for ( const meta of filterMeta ) {
            if( meta.value !== null ) {
              let filer : DossierFilter;
              if(Array.isArray( meta.value)) {
                filer = new DossierFilter(filterField,null, meta.matchMode, meta.value)
              }
              else if (this.isNumber(meta.value)) {
                filer = new DossierFilter(filterField, meta.value, meta.matchMode)
              }
              else if(this.isDate(meta.value)) {
                let date=  formatDate(meta.value, 'yyyy-MM-dd HH:mm', 'fr')
                filer = new DossierFilter(filterField, date, meta.matchMode)
              }
              else {
                 filer = new DossierFilter(filterField, meta.value, meta.matchMode)
              }
              filterStrings.push(filer);
            }
          }
        }
      }
    }
    return filterStrings;
  }
}
