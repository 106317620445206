<div class="mb-3">
  <button pButton pRipple label="{{'RAPPORT_EXPERTISE.ACTIONS.AJOUTER_NOUVELLE_LIGNE' | translateI18n}}"
          icon="pi pi-plus" class="p-button-sm p-button-rounded" (click)="openNew()"></button>
</div>
<p-messages  *ngIf="messageBieSurface" [(value)]="messageBieSurface" key="tableSurface"></p-messages>
<p-table [value]="bieSurfaces" [rows]="settings.pagination?.rowsDocument"  [showCurrentPageReport]="true"
         [paginator]="bieSurfaces?.length > settings.pagination?.rowsDocument" [rowsPerPageOptions]="settings.pagination?.rowsPerPageOptionsDocument"
         [globalFilterFields]="['batEtage','utilisation','surface','coefPonderation','surfacePondere']"
         dataKey="id" paginatorPosition="top"
         currentPageReportTemplate="Affichage {first} à {last} sur {totalRecords}" >
  <ng-template pTemplate="header">
    <tr>
      <th></th>
      <th pSortableColumn="batEtage">{{'RAPPORT_EXPERTISE.LABEL.BAT_ETAGE' | translateI18n}} <p-sortIcon field="batEtage"></p-sortIcon></th>
      <th pSortableColumn="utilisation"> {{'RAPPORT_EXPERTISE.LABEL.UTILISATION' | translateI18n}}   <p-sortIcon field="utilisation"></p-sortIcon></th>
      <th pSortableColumn="surface">{{'RAPPORT_EXPERTISE.LABEL.SURFACE' | translateI18n}} (m²) <p-sortIcon field="surface"></p-sortIcon></th>
      <th pSortableColumn="coefPonderation">{{'RAPPORT_EXPERTISE.LABEL.COEF_PONDERATION' | translateI18n}}   <p-sortIcon field="coefPonderation"></p-sortIcon></th>
      <th pSortableColumn="surfacePondere" > {{typeSurface?.libelleCourt}} considérée (m²)<p-sortIcon field="surfacePondere"></p-sortIcon></th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-bies>
    <tr>
      <td class="text-left">
        <button type="button" pButton pRipple icon="inc-icon inc-icon-trash is-small"
                pTooltip="{{'BUTTON_LABEL.DELETE' | translateI18n}}" tooltipPosition="top"
                class="p-button-rounded mr-2" (click)="deleteBieSurface(bies)"></button>
        <button type="button" pButton pRipple icon="inc-icon inc-icon-edit is-small"
                pTooltip="{{'BUTTON_LABEL.MODIFY' | translateI18n}}" tooltipPosition="top"
                class="p-button-rounded p-button-success mr-2" (click)="editBieSurface(bies)"></button>
      </td>
      <td>{{bies?.batEtage}}</td>
      <td>{{bies?.utilisation}}</td>
      <td>{{bies?.surface | decimal}}</td>
      <td>{{bies?.coefPonderation | decimal}}</td>
      <td>{{bies?.surfacePondere | decimal}}</td>
    </tr>
  </ng-template>
  <ng-template pTemplate="footer">
    <tr>
      <td>Total</td>
      <td></td>
      <td></td>
      <td>{{total | decimal}}</td>
      <td></td>
      <td>{{totalSurfaceConsideree | decimal}}</td>
    </tr>
  </ng-template>


</p-table>






<p-dialog [(visible)]="dialog" [style]="{width: '500px'}" header="{{'RAPPORT_EXPERTISE.LABEL.AJOUTER_ELEMENT' | translateI18n}}" [modal]="true" styleClass="p-fluid">

  <ng-template pTemplate="content">
    <p-messages  *ngIf="messageBieSurfaceDialog" [(value)]="messageBieSurfaceDialog" key="dialogSurface"></p-messages>
    <form [formGroup]="surfaceForm" class="page-form">

      <div class="field">
        <label for="batEtage" [ngClass]="{'p-error': submitted && formBieSurface.batEtage.errors?.pattern}">
          {{'RAPPORT_EXPERTISE.LABEL.BAT_ETAGE' | translateI18n}}
        </label>
        <input type="text" class="inputfield" pInputText id="batEtage" formControlName="batEtage"
               [ngClass]="{'ng-dirty': submitted  && formBieSurface.batEtage?.errors?.pattern}" [maxLength]="18"/>


        <small *ngIf="submitted && formBieSurface.batEtage?.errors?.pattern" class="p-error block">
            {{'MESSAGE.ERROR.REGEX_PATTERN' | translateI18n}}</small>

      </div>


      <div class="field" [ngClass]="{'p-error': submitted && formBieSurface.utilisation.errors?.pattern}">
        <label for="utilisation">
          {{'RAPPORT_EXPERTISE.LABEL.UTILISATION' | translateI18n}}
        </label>
        <input type="text" class="inputfield" pInputText id="utilisation" formControlName="utilisation"
               [ngClass]="{'ng-dirty': submitted  && formBieSurface.utilisation?.errors?.pattern}" [maxLength]="18"/>

        <small *ngIf="submitted && formBieSurface.utilisation?.errors?.pattern" class="p-error block">
            {{'MESSAGE.ERROR.REGEX_PATTERN' | translateI18n}}</small>

      </div>


      <div class="field">
        <label for="surface" class="obligatoire"  [ngClass]="{'p-error': submitted && formBieSurface.surface.errors}">
          {{'RAPPORT_EXPERTISE.LABEL.SURFACE' | translateI18n}}</label>
        <p-inputNumber formControlName="surface"
                       id="surface"
                       suffix=" m²"
                       [size]=20
                       [ngClass]="{'ng-invalid ng-dirty': submitted && formBieSurface.surface.errors}"
                       [min]="0"
                       [maxlength]="18"
                       [minFractionDigits]="2"
                       [maxFractionDigits]="5"
                    (onInput)="surfaceChange($event)">
        </p-inputNumber>
        <small class="p-error" *ngIf="submitted && formBieSurface.surface?.errors?.required">{{'MESSAGE.ERROR.CHAMPS_OBLIGATOIRE' | translateI18n}}</small>
      </div>


      <div class="field">
        <label for="coefPondereation" class="obligatoire" [ngClass]="{'p-error': submitted && formBieSurface.coefPondereation.errors}">
          {{'RAPPORT_EXPERTISE.LABEL.COEF_PONDERATION' | translateI18n}}</label>
        <p-inputNumber formControlName="coefPondereation"
                       id="coefPondereation"
                       [ngClass]="{'ng-invalid ng-dirty': submitted && formBieSurface.coefPondereation.errors}"
                       [size]=20
                       [min]="0"
                       [maxlength]="18"
                       [minFractionDigits]="2"
                       [maxFractionDigits]="5"
                       (onInput)="coefPonderationChange($event)" >
        </p-inputNumber>
        <small class="p-error" *ngIf="submitted && formBieSurface.coefPondereation?.errors?.required">{{'MESSAGE.ERROR.CHAMPS_OBLIGATOIRE' | translateI18n}}</small>
      </div>

      <div class="field">
        <label >{{'RAPPORT_EXPERTISE.LABEL.SURFACE_CONSIDEREE' | translateI18n}} :</label>
        <b> {{surfaceForm?.value?.surfacePondere ? (surfaceForm?.value?.surfacePondere | decimal) + ' m²' : 'N/A'}}</b>
      </div>


    </form>
  </ng-template>

  <ng-template pTemplate="footer">
    <button pButton type="button" pRipple label="{{'BUTTON_LABEL.ANNULER' | translateI18n}}" icon="pi pi-times" class="uppercase p-button-secondary p-button-outlined mb-2"
            (click)="hideDialog()"></button>
    <button pButton type="submit" pRipple label="{{'BUTTON_LABEL.ENREGISTRER' | translateI18n}}" icon="pi pi-save" class="ppercase mb-2" (click)="saveBienSurface()"></button>
  </ng-template>

</p-dialog>

