import {Component, OnInit} from '@angular/core';
import {Message, MessageService} from "primeng/api";
import {Router} from "@angular/router";
import {AuthService} from "../../../core/service/auth/auth.service";
import {LocalStorageService} from "../../../core/service/local-storage/local-storage.service";
import {TranslateService} from "../../../shared/translate/translate.service";
import {MessageStorageService} from "../../../core/service/local-storage/message-storage.service";
import {MessageObservableService} from "../../../shared/observable/message-observable.service";
import {NgForm} from "@angular/forms";
import {LoginResponse} from "../../../core/models/user/login-response";
import { runtimeEnvironment } from 'src/environments/runtime-environment';

@Component({
  selector: 'app-code-authentification',
  templateUrl: './code-authentification.component.html',
  styleUrls: ['./code-authentification.component.scss']
})
export class CodeAuthentificationComponent implements OnInit {

  public login: string ;
  public password: string ;
  public chiffre1: string;
  public chiffre2: string;
  public chiffre3: string;
  public chiffre4: string;
  public chiffre5: string;
  public chiffre6: string;
  public chiffre7: string;
  public chiffre8: string;
  public infoMessage: Message[];
  public errorMessage: Message[];
  errorMessageWebsso : Message[];
  public errorMessageCode: Message[];
  public showCodeInput: boolean;

  env =  runtimeEnvironment;
  messages : string[];

  messagesTentitivesCode : string;

  constructor(
    private router:Router,
    private auth:AuthService,
    private tokenStorageService : LocalStorageService,
    private translate : TranslateService, private messageService: MessageService,
    private messageStorage : MessageStorageService,
    private messageObservableService : MessageObservableService
  ) {
    this.tokenStorageService.clearAllStorage();
    if (this.router.getCurrentNavigation()?.extras?.state) {
      this.showCodeInput = true;
      let message = this.router.getCurrentNavigation()?.extras?.state?.message;
      this.messages = message.split('<br/>');
      this.login = this.router.getCurrentNavigation()?.extras?.state?.login;
      this.password = this.router.getCurrentNavigation()?.extras?.state?.password;
      if(!this.login && !this.password ) {
        this.router.navigateByUrl('/auth');
      }
    } else {
      this.router.navigateByUrl('/auth');
    }
  }

  ngOnInit(): void {
  }

  authenticate(form: NgForm) {
    this.messages = null;
    this.errorMessageCode = null;
    this.infoMessage = null;
    this.messageService.clear();
    if (form.valid) {
      this.auth.authenticate(this.login, this.password , this.getCode2fa())
        .subscribe((response : LoginResponse) => {
            if(response && response.token) {
              this.tokenStorageService.setSession(response, new Date().getTime());
              this.auth.redirectionAfterLogIn();
            }else {
              this.errorMessageCode =[ {severity:'error', summary : this.translate.getElement("AUTH.MESSAGE.ERROR.CODE_INCORRECT")}];
            }

          },
          error => {
            if (error.status == 403) {
              // Forbidden
              this.errorMessageCode = [ {severity:'error', summary :this.translate.getElement("AUTH.MESSAGE.ERROR.CODE_INCORRECT")}];
              return;
            }
            if (error.status == 429) {
              // Too Many Requests : Mauvais code entré trop de fois à la suite
              this.messagesTentitivesCode = this.translate.getElement("AUTH.MESSAGE.ERROR.CODE_WRONG");
              //this.errorMessageCode = [ {severity:'error', summary :this.translate.getElement("AUTH.MESSAGE.ERROR.CODE_WRONG")}];
              this.login = null;
              this.password = null;
              this.showCodeInput = false;
              return;
            }
            if (error.status == 410) {
              // Gone : Le code a expiré
              this.resetCode();
              this.errorMessageCode =  [ {severity:'error', summary :this.translate.getElement("AUTH.MESSAGE.ERROR.CODE_EXPIRED")}];
            }

            if (error.status == 303) {
              //Le mot de passe a expiré
              this.router.navigate(["/auth/reinit-password/" + this.getCode2fa()],
                {queryParams :  {type: "passwordExpired"}});
              return;
            }

            // PRECONDITION_FAILED
            this.errorMessage = [ {severity:'error', summary : this.translate.getElement("AUTH.MESSAGE.ERROR.PRECONDITION_FAILED")}];
          });
    } else {
      return;
    }
  }

  onDigitInput(event: any){

    let element;
    if (String(event.code).startsWith("Digit") || String(event.code).startsWith("Numpad") || event.code === "ArrowRight")
      element = event.srcElement.nextElementSibling;

    if (event.code === 'Backspace' || event.code === "ArrowLeft") {
      element = event.srcElement.previousElementSibling;
    }

    if(element == null)
      return;
    else
      element.focus();
  }

  onPaste(event: any) {
    const clip = event.clipboardData.getData('text').trim();
    this.chiffre1 = clip[0];
    this.chiffre2 = clip[1];
    this.chiffre3 = clip[2];
    this.chiffre4 = clip[3];
    this.chiffre5 = clip[4];
    this.chiffre6 = clip[5];
    this.chiffre7 = clip[6];
    this.chiffre8 = clip[7];
  }

  private resetCode() {
    this.chiffre1 = null;
    this.chiffre2 = null;
    this.chiffre3 = null;
    this.chiffre4 = null;
    this.chiffre5 = null;
    this.chiffre6 = null;
    this.chiffre7 = null;
    this.chiffre8 = null;
  }

  private getCode2fa(): string {
    return this.chiffre1+this.chiffre2+this.chiffre3+this.chiffre4+this.chiffre5+this.chiffre6+this.chiffre7+this.chiffre8;
  }

  clearCodeInput() {
    this.resetCode();
  }

}
