import {AfterViewChecked, ChangeDetectorRef, Component, HostListener, OnInit, ViewChild} from '@angular/core';
import {LazyLoadEvent, Message, MessageService, SortEvent} from "primeng/api";
import {ProfilesUser} from "../../../../core/models/user/profiles-user";
import {Dossier} from "../../../../core/models/dossiers/dossier";
import {DossierService} from "../../../../core/service/dossier.service";
import {UserService} from "../../../../core/service/user/user.service";
import {LocalStorageService} from "../../../../core/service/local-storage/local-storage.service";
import {TranslateService} from "../../../../shared/translate/translate.service";
import {DialogService, DynamicDialogRef} from "primeng/dynamicdialog";
import {EditDossierAffectationComponent} from "../../../../core/dialog/edit-dossier-affectation/edit-dossier-affectation.component";
import {ResponsePagination} from "../../../../core/models/dossiers/response-pagination";
import {AffectationRechercheAvanceeExpertComponent} from "../../../../core/dialog/affectation-recherche-avancee-expert/affectation-recherche-avancee-expert.component";
import {DatePipe} from "@angular/common";
import {Validation} from "../../../../shared/providers/validation";
import {TypeLivrablePipe} from "../../../../shared/pipes/type-livrable.pipe";
import {AppSettings, Settings} from "../../../../app.settings";
import {Pagination} from "../../../../core/models/rechercher-tableau/pagination";
import {DossierFilter} from "../../../../core/models/rechercher-tableau/dossier-filter";
import {PaginationComponent} from 'src/app/shared/pagination/pagination.component';
import {NgxSpinnerService} from "ngx-spinner";


@Component({
  selector: 'app-folder-list-to-assign',
  templateUrl: './folder-list-to-assign.component.html',
  styleUrls: ['./folder-list-to-assign.component.scss']
})
export class FolderListToAssignComponent implements OnInit, AfterViewChecked {

  @ViewChild(PaginationComponent) paginationComponent : PaginationComponent;

  dossiers: Dossier[] = [];
  detailToast: string;
  experUser : any[];
  filteredExpert: any[];
  filteredItems: any[];
  selectedExpert: any;
  filteredAssistante: any[];
  filteredItemsAssistante: any[];
  selectedAssistantes: any;
  assistantUser : any[];
  assistanteConnected : any;
  userProfile : any[] = [ProfilesUser.EXPERT_EXTERNE, ProfilesUser.EXPERT_INTERNE];
  assistantProfile : any[] = [ProfilesUser.ASSISTANTE];
  isAffecter : boolean = true;
  selectAll: boolean = false;
  selectedDossier : Dossier[] = [];
  totalRecords : number = 0;
  messageAffect: Message[];
  loading: boolean;
  header : any;
  infoBulleConflit: any;
  listFolderEmpty : boolean = false;
  display: boolean = false;
  ref: DynamicDialogRef;
  response : ResponsePagination;
  ecran = 'EN_ATTENTE_AFFECTATION';
  errorMessage: Message[];
  tableHeight : number;
  totalDossierConflit : number = 0;
  public settings: Settings;
  lotSize:number = 30;
  totalProcessed:number = 0;
  totalForProcessed:number=0;
   pagination : Pagination;

   isAllDossierEnConflit : boolean = false;

  @HostListener('window:resize', ['$event'])
  onWindowResize() {
    let elem = document?.getElementById('tableaux');
    let rect = elem?.getBoundingClientRect();
    this.tableHeight = window.innerHeight - rect?.y - 80;
  }

  constructor(
    private dossierService: DossierService,
    private messageService: MessageService,
    private userService : UserService,
    private tokenStorgeService : LocalStorageService,
    public translate: TranslateService,
    public dialogService: DialogService,
    private datePipe : DatePipe,
    public appSettings : AppSettings,
    private cdRef: ChangeDetectorRef,
    private spinnerService: NgxSpinnerService
  ) {
    this.settings = this.appSettings.settings;
    this.isRowSelectable = this.isRowSelectable.bind(this);
  }

  ngOnInit(): void {

    this.header = this.translate.data.DOSSIER.AFFECTATION.HEADER.TITLE;
    this.infoBulleConflit = this.translate.data.DOSSIER.AFFECTATION.INFO.CONFLIT;

    this.loading = true;
    let page : Pagination = new Pagination(this.settings.pagination?.first, this.settings.pagination?.rows,  'numero', 'asc')
    this.getListDossierAaffecter(page, true);
    this.userService.getAllUserByProfiles(this.userProfile).subscribe(expertUser => {
      this.experUser = expertUser;
    });

    this.userService.getAllUserByProfiles(this.assistantProfile).subscribe(assistant => {
      this.assistantUser = assistant;
      this.checkAssistante();

    });

    //this.overridePrimeNGTableColumnFilter();
  }

  async checkAssistante() {
    var user = await this.userService.getUserConnectedByToken();
    this.assistantUser.forEach((assistante => {
      assistante['nomComplet'] = assistante.nom + ' ' + assistante.prenom;
      if (assistante.id === user.id) {
        this.assistanteConnected = assistante;
      }
    }));

  }

  loadDossier(event: LazyLoadEvent) {
    this.loading = true;

    let fieldSort = 'numero'
    setTimeout(() => {
      if(event.sortField) {
        fieldSort = event?.sortField;
      }

      this.pagination = new Pagination(this.settings.pagination?.first, this.settings.pagination?.rows,  fieldSort, Pagination.getDirection(event.sortOrder));
      this.pagination .filter = DossierFilter.formatFilters(event);
      this.pagination.pageNo = this.settings.pagination?.first;
      this.paginationComponent?.paginator?.changePage(this.settings.pagination.first);
      this.getListDossierAaffecter(this.pagination, false);
    }, 1000);
  }

  /**
   * retourne tous les dossiers en etat d'affectation
   * @param page
   */
  getListDossierAaffecter(page : Pagination, checkEmpty : boolean) {
    this.loading = true;
    this.dossierService.getListDossierAaffecterBis(page).subscribe( data => {


        this.tableHeight = 0;
        this.totalDossierConflit = 0;
        this.response = data;
        this.dossiers = data.content;
        this.totalRecords = data.totalElements;
      if(data.content?.length > 0) {
        // Transforme les champs date en véritable objet date
        this.dossiers.map(dos => {
          if(dos.mission?.dateMissionPrevisionnelle) {
            dos.mission.dateMissionPrevisionnelle = new Date(dos.mission.dateMissionPrevisionnelle);
          }
          if(dos.bien != null && dos.bien.enConflit) {
            this.totalDossierConflit = this.totalDossierConflit + 1;
          }
        })
        this.isAllDossierEnConflit = this.dossiers.some(ds => !ds?.bien?.enConflit);
      }
      if(checkEmpty && this.totalRecords === 0) {
        this.listFolderEmpty = true;
      }
      this.onWindowResize();
      this.loading = false;
    }, error => {
      this.loading = false;
    })
  }

  onRowSelect(event : any) {
    this.selectedDossier.push(event.data);
    this.onSelectionChange(this.selectedDossier);
    this.selectedDossier = this.selectedDossier.filter((obj, index, array) => {
      return index === array.findIndex(o => o.idDossier === obj.idDossier);
    });
    this.selectedExpert = null;
    this.detailToast = event.data.numeroDossier + ' / ' + event.data?.tiers?.nomCompletTiers
    this.messageService.add({severity:'success', summary: this.translate.getElement('DOSSIER.AFFECTATION.MESSAGE.SELECTDOSSIER'), detail: this.detailToast});
  }

  onRowUnselect(event : any) {
    const index: number =this.selectedDossier.findIndex(doss=>doss.idDossier===event.data.idDossier);
    if (index !== -1) {
      this.selectedDossier.splice(index, 1);
    }
    this.onSelectionChange(this.selectedDossier);
    this.messageService.add({severity:'warn', summary:this.translate.getElement('DOSSIER.AFFECTATION.MESSAGE.DESELECTDOSSIER'), detail: this.detailToast});
  }

  /**
   * auto completion utilisateur expert
   * @param event
   */
  searchExpertUser(event : any) {

    let filtered : any[] = [];
    let query = event.query;
    if(this.experUser.length > 0) {
      this.experUser.forEach(expert => {
        if (expert.nom.toLowerCase().indexOf(query.toLowerCase()) === 0 ||
          expert.prenom.toLowerCase().indexOf(query.toLowerCase()) === 0) {
          expert['nomComplet'] = expert.prenom + ' ' + expert.nom;
          filtered.push(expert);
        }
      })
      this.filteredExpert = filtered;
    }
  }

  valider() {
    if(this.selectedDossier.length > 0 ) {
      if(this.selectedExpert && this.selectedAssistantes) {
        let idDossiers : number[] = [];

        this.selectedDossier.forEach((dossier : Dossier) => {
          idDossiers.push(dossier.idDossier);
        })
        if(this.selectedExpert.id === undefined) {
          this.messageService.add({severity:'error', summary:'Erreur ', detail: this.translate.getElement('DOSSIER.AFFECTATION.MESSAGE.ERROR.SELECT_EXPERT')});
        }else if(this.selectedAssistantes.id === undefined) {
          this.messageService.add({severity:'error', summary:'Erreur ', detail: this.translate.getElement('DOSSIER.AFFECTATION.MESSAGE.ERROR.SELECT_ASSISTANTE')});
        }else {
          this.affectationDossierLot(idDossiers);
        }
      }
      else {
        this.messageService.add({severity:'error', summary:'Erreur ', detail: this.translate.getElement('DOSSIER.AFFECTATION.MESSAGE.ERROR.SELECT_EXPERT')});
      }
    }
  }


  async affectationDossierLot(idDossiers: number[]) {
    this.totalProcessed = 0;
    this.totalForProcessed=0;
    this.totalForProcessed = idDossiers.length;
    //construire les lot par lot size
    const lots = this.createLot(idDossiers, this.lotSize);
  await  this.processAffectationNextLot(lots, 0);
  }

  createLot(array: any[], lotSize: any) {
    let lot = [];
    for (let i = 0; i < array.length; i += lotSize) {
      lot.push(array.slice(i, i + lotSize))
    }
    return lot;
  }
  /**
   * Affecter un dossier ou plusieurs dossiers
   * PAR LOT
   * @param lots : [][]
   * @param index :number
   */
  async processAffectationNextLot(lots: any[][], index: number) {
    this.spinnerService.show();
    let expert = this.selectedExpert;
    let assistant = this.selectedAssistantes;
    if (index < lots.length) {
     await this.dossierService.affectationDossier(lots[index], this.selectedExpert.id, this.selectedAssistantes.id)
        .subscribe( (response) => {
          if ( response) {
             this.processAffectationNextLot(lots, index + 1);
            this.totalProcessed = this.totalProcessed + parseInt(response);
          }
        }, error => {
          this.processAffectationNextLot(lots, index + 1);

        });
    } else {
      let page: Pagination = this.pagination;
      if (page == null) {
        page = new Pagination(this.settings.pagination?.first, this.settings.pagination?.rows, 'numero', 'asc');
      }
      await this.getListDossierAaffecter(page, false);
      //CAS DE SUCCES
      if (this.totalForProcessed === this.totalProcessed) {
        this.selectedExpert = null;
        this.selectedAssistantes = null;
        this.selectedDossier = [];
        this.isAffecter = true;
        this.messageAffect = [
          {
            severity: 'success',
            summary: this.translate.getElement('DOSSIER.AFFECTATION.MESSAGE.AFFECTS', expert.nomComplet, assistant.nomComplet)
          }
        ];
        this.selectAll = false
        window.scrollTo(0, 0);
        this.spinnerService.hide();
      } else {
        let totalErr =  this.totalForProcessed - this.totalProcessed;
         this.messageAffect =  [
          {
            severity: 'error',
            summary: this.translate.getElement('DOSSIER.AFFECTATION.MESSAGE.NOTALLAFFECTS', String(totalErr), String(this.totalForProcessed))
          }
        ];
        window.scrollTo(0, 0);
        this.spinnerService.hide();
      }
    }
  }

  onSelectionChange(value: any = []) {
    this.isAffecter = value.length <= 0;
    this.selectAll = value.length === this.totalRecords - this.totalDossierConflit;
    if(!this.isAffecter) {
      this.selectedExpert = null;
      this.selectedAssistantes = this.assistanteConnected;

      if(this.selectAll) {
        this.selectedDossier =  [];
        value.map((item:any) => {
          this.selectedDossier.push(item);
        });
      }
    }
  }
  selectAllChange(event:any){
    this.selectedDossier =  [];
    const checked = event.checked;
    if (checked) {
      this.loading = true;
      this.selectAll = true;
      let page = {pageNo : this.settings.pagination?.first, pageSize : this.totalRecords, fieldSort : 'numero',orderSort:'asc' }
      this.dossierService.getListDossierAaffecterBis(page).subscribe(res => {
        if(res.content?.length > 0) {
          this.totalDossierConflit = 0;
          res.content.map((dos: Dossier) => {
            if (!(dos.bien != null && dos.bien.enConflit)) {
              this.selectedDossier.push(dos);
            } else {
              this.totalDossierConflit = this.totalDossierConflit + 1;
            }
          });
        }else{
          this.selectAll = false;
          this.selectedDossier = [];
        }
        this.onSelectionChange(this.selectedDossier)
        this.loading = false;
      });
    }
    else{
      this.selectAll = false;
      this.selectedDossier = [];
      this.onSelectionChange(this.selectedDossier)
    }
  }

  isRowSelectable(event : any) {
    return !this.isOutOfStock(event.data);
  }

  isOutOfStock(data : Dossier) {
    return data?.bien?.enConflit;
  }

  /**
   * Edition dossier
   * @param dossier
   */
  showEdit(dossier : Dossier) {

    this.ref = this.dialogService.open(EditDossierAffectationComponent, {
      header: dossier.numeroDossier.toString(),
      width: '50vw',
      baseZIndex: 10000,
      data : dossier,
      styleClass : 'p-dynamic-dialog'
    });

    this.ref.onClose.subscribe((message : string) =>{
      //Une fois la modal fermée, supprimer la classe qui gère le scroll
      const body = document.getElementsByTagName('body')[0];
      body.classList.remove('p-dynamic-dialog-scrollable');
      if (message) {
        let page : Pagination = this.pagination;
        if (page == null) {
          page = new Pagination(this.settings.pagination?.first, this.settings.pagination?.rows,  'numero', 'asc');
        }
        this.messageAffect = [ {severity:'success', summary: message }];
        this.getListDossierAaffecter(page, false);
      }
    });
  }

  ngOnDestroy() {
    if (this.ref) {
      this.ref.close();
    }
  }


  nextPage(event : any) {
    let page : Pagination = this.pagination;
    if(page) {
      page.pageNo = event.page;
      page.pageSize = event.rows;
    }else {
      page.fieldSort = 'numero';
      page.direction = 'asc'
    }
    this.getListDossierAaffecter(page, false);
  }

  /**
   * Edition dossier
   * @param dossier
   */
  showAffectationRechercheAvanceeExpert() {

    this.ref = this.dialogService.open(AffectationRechercheAvanceeExpertComponent, {
      header: this.translate.getElement('DOSSIER.AFFECTATION.HEADER.AFFECTER'),
      width: '35vw',
      baseZIndex: 10000,
      data : 'EN_ATTENTE_AFFECTATION',
      styleClass : this.ecran === 'EN_ATTENTE_AFFECTATION' ? 'p-dynamic-dialog enAttenteAffection' : 'p-dynamic-dialog rechercheStatutAffecttion',
      dismissableMask: true,
    });

    this.ref.onClose.subscribe((message : string) =>{
      if (message) {
       this.selectedExpert = message;
      }
    });
  }

  exportExcel() {

    let page = {pageNo : this.settings.pagination?.first, pageSize : this.totalRecords, fieldSort : 'id' }
    this.loading = true;
    this.dossierService.getListDossierAaffecterBis(page).subscribe( data => {

      if(data) {

        import("xlsx").then(xlsx => {
          let dossier = data.content.map(
            dossier => ({
              [this.translate.getElement("DOSSIER.NUM_DOSSIER")] : dossier?.numeroDossier,
              [this.translate.getElement("DOSSIER.TIERS")] : dossier?.tiers?.nomCompletTiers,
              [this.translate.getElement("DOSSIER.DEMANDEUR")] : dossier?.demande?.nomCompletDemandeur,
              [this.translate.getElement("DOSSIER.OBSERVATION_DEMANDEUR")] : dossier?.demande?.observation,
              [this.translate.getElement("DOSSIER.AFFAIRE")] : dossier?.client?.nomComplet,
              [this.translate.getElement("DOSSIER.ADRESSE_FORM.POSTAL_CODE")] : dossier?.adresse?.codePostal,
              [this.translate.getElement("DOSSIER.ADRESSE_FORM.CITY")] : dossier?.adresse?.ville,
              [this.translate.getElement("DOSSIER.ECHEANCE")] :  this.datePipe.transform(dossier?.mission?.dateMissionPrevisionnelle, 'dd/MM/yyyy'),
              [this.translate.getElement("DOSSIER.TYPE_LIVRABLE")] : new TypeLivrablePipe().transform(dossier?.mission?.typeLivrable?.code)
            })
          );
          const worksheet = xlsx.utils.json_to_sheet(dossier);
          const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
          const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
          Validation.saveAsExcelFile(excelBuffer, "tableau_dossier_en_attente_affectation");
          this.loading = false;
        });

      }

    }, error => {
      this.errorMessage = [ {severity:'error', summary: this.translate.getElement("DOSSIER.MESSAGE.ERROR.EXPORT_EXCEL") }];
    })


  }


  customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
      let value1 = data1[event.field];
      let value2 = data2[event.field];
      let result = null;

      if (value1 == null && value2 != null)
        result = -1;
      else if (value1 != null && value2 == null)
        result = 1;
      else if (value1 == null && value2 == null)
        result = 0;
      else if (typeof value1 === 'string' && typeof value2 === 'string')
        result = value1.localeCompare(value2);
      else
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;

      return (event.order * result);
    });
  }

  ngAfterViewChecked() {
    this.cdRef.detectChanges();
  }
}
