<main class="layout-wrapper">
  <app-navigation></app-navigation>
  <app-header titre="{{user?.prenom}} {{user?.nom}}" sstitre="{{'HEADER.SSTITRE_PROFIL_OR_DASHBORD' | translateI18n}} : {{user?.dateDernierConnexion | date:'d MMMM y HH:mm'}}"></app-header>
  <div class="layout-main">

    <div class="page is-centered is-narrow">

      <div class="card">
        <p><b>{{user?.nom}} {{user?.prenom}}</b></p>
        <p>{{user?.adresse?.numero}} {{user?.adresse?.nomVoie}}<br>
          {{user?.adresse?.codePostal}} {{user?.adresse?.ville}}</p>
        <p><span class="grey003">Tel: </span> {{usefulFunctionService.format_tel_portable(user)}}
        </p>
        <p><a href="mailto:{{user?.email}}">{{user?.email}}</a></p>
        <p><span>Profil : </span> {{profils.toString()}}</p>
      </div>

      <app-change-password [askCurrentPassword]=true></app-change-password>

      <div class="card">
        <h5>{{'LANGUE_APP.LABEL' | translateI18n}}</h5>

        <div class="grid">
          <div class="col field">
              <p-dropdown [options]="langues" [(ngModel)]="langue" [editable]="true" optionLabel="name" ></p-dropdown>
              <button class="ml-4" pButton type="button" label="Valider" style="height: 2.8rem;" (click)="getLanguage()"></button>
          </div>

        </div>

      </div>

    </div>
  </div>

</main>
