<div class="login-page">
</div>

<div class="layout-main is-full" id="main-login">

  <div class="flex justify-content-center m-4" id="logo-bnp-login">
    <img src="../../../assets/images/logo-bnp-valuation.svg" width="200px" />
  </div>

  <div class="grid justify-content-center align-content-center w-full">
    <div class="col-12 is-login-card">

      <div class="flex justify-content-center pb-6">
        <img src="../../../assets/images/logo-valea.svg" width="140px" [routerLink]="['/auth']"/>
      </div>

      <div  *ngIf="errorMessage" >
        <p-messages [(value)]="errorMessage" [enableService]="false" [escape]="false"></p-messages>
      </div>

      <div *ngIf="submitted">
        <p-messages severity="info" [escape]="false" [enableService]="false">
          <ng-template pTemplate>

            <div class="ml-4 mr-4">
              <h3><i class="pi pi-info-circle" style="font-size: 1.5rem;"></i>
                <span class="ml-3">{{'FORGGOTTEN_PASWORD.FORM.MESSAGE.SUCCESS.TITLE' | translateI18n}}</span></h3>
              <p>{{message[0]}}</p>
              <p class="pb-3">{{message[1]}}</p>
            </div>
          </ng-template>
        </p-messages>
      </div>

      <div class="form-container card" *ngIf="!submitted">

        <form [formGroup]="forgottenPasswordForm" (ngSubmit)="submit()">
          <div class="form-title"> {{'FORGGOTTEN_PASWORD.FORM.TITLe' | translateI18n}} </div>

          <div class="field">
            <label for="email">{{'FORGGOTTEN_PASWORD.FORM.LABEL.EMAIL' | translateI18n}}</label>
            <div class="p-input-icon-right w-full">
              <i class="pi pi-envelope"></i>
              <input type="email" pInputText formControlName="email" id="email" class="inputfield w-full"
                     [ngClass]="{ 'is-invalid': (submitted && forgottenPasswordForm.controls.email.errors) || forgottenPasswordForm.controls.email.invalid }"
                     class="inputfield w-full" required/>
            </div>
          </div>

          <button pButton type="submit"  label="{{'FORGGOTTEN_PASWORD.FORM.LABEL.BLUTTON_SUBMIT' | translateI18n}}" class="w-full uppercase"  icon="pi pi-arrow-right" iconPos="right"></button>

        </form>
      </div>
    </div>
  </div>
</div>
