import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'caseWord'
})
export class CaseWordPipe implements PipeTransform {

  transform(value: string): string {
    if (!value) return value;
    return value[0].toUpperCase() + value.slice(1);
  }

}
