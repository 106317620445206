import {Component, OnInit} from '@angular/core';
import {MenuItem} from 'primeng/api';
import {environment} from "../../../environments/environment.dev";
import {LocalStorageService} from "../../core/service/local-storage/local-storage.service";
import {ProfilesUser} from "../../core/models/user/profiles-user";
import {TranslateService} from "../translate/translate.service";
import {DialogService, DynamicDialogRef} from "primeng/dynamicdialog";
import {Router} from "@angular/router";
import {DossierService} from 'src/app/core/service/dossier.service';
import {ReferentielValeur} from 'src/app/core/models/referentiel-valeur';
import {IconStatutDossierPipe} from "../pipes/icon-statut-dossier.pipe";
import {UtilsFonction} from "../providers/utils-fonction";
import {UserService} from "../../core/service/user/user.service";
import {UtilsObservableService} from "../observable/utils-observable.service";
import {ConfigConstants} from "../../core/models/enum/config-constants";
import {TypeLivrable} from "../../core/models/dossiers/type-livrable";
import {runtimeEnvironment} from "../../../environments/runtime-environment";

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent implements OnInit {

  ref: DynamicDialogRef;
  items: MenuItem[];

  versionApp = environment.version;
  classNav: string = 'have-2';

  stock : any;

  menuAdvanceSearch : boolean = false;

  profiles : ProfilesUser[];
  typesLivrables : ReferentielValeur[];
  activeItem: any;

  constructor(
    private userTokenStorageService : LocalStorageService, private translateService : TranslateService,
    private dialogService: DialogService, private dossierService: DossierService,
    private router:Router, private iconStatutPipe : IconStatutDossierPipe,
    private userService : UserService, private utilsObservableService : UtilsObservableService,
    private dossierServcie : DossierService
  ) {}


  ngOnInit(): void {
    this.initMenu();
  }

   async initMenu() {

     const profiles = await  this.userService.getUserConnectedProfilesById();

     let menus = [];
     menus.push({
       label: this.translateService.getElement('NAVIGATION.ACCUEIL'),
       icon: 'inc-icon ' + this.iconStatutPipe.transform('ACCUEIL'),
       routerLink: '/dashboard',
       styleClass: 'is-green-light',
       command : (event : any) => {
         this.refreshActiveMenuItemOnClick(event);
       }
     })

     if (profiles) {
       if (profiles.includes(ProfilesUser.ASSISTANTE) || profiles.includes(ProfilesUser.ANIMATEUR_RESEAU)
         || profiles.includes(ProfilesUser.ADMINISTRATEUR) || profiles.includes(ProfilesUser.ADMINISTRATEUR_TECHNIQUE)) {
         menus.push(
           {
             label: this.translateService.getElement('NAVIGATION.AFFECTATION'),
             icon: 'inc-icon ' + this.iconStatutPipe.transform('A_AFFECTER'),
             routerLink: '/dossier/list-folder-to-assign',
             styleClass: 'is-orange',
             command : (event : any) => {
               this.refreshActiveMenuItemOnClick(event);
             }
           },
           {
             label: this.translateService.getElement('NAVIGATION.PROJET_ENVOYE'),
             icon: 'inc-icon ' + this.iconStatutPipe.transform('D_ATTENTE_V1', ConfigConstants.ETAT_ENVOI_PROJET),
             routerLink: '/dossier/recherche/gestionnaire',
             routerLinkActiveOptions : {exact : true},
             queryParams: {typeRecherche: 'dashboardCard', etatEnvoi : 'ETATLIVRABLEPRJ'},
             styleClass: 'is-orange projets_envoyes',
             command : (event : any) => {
               this.refreshActiveMenuItemWithParamOnClick(event);
             }
           },
           {
             label: this.translateService.getElement('NAVIGATION.VALIDATION'),
             icon: 'inc-icon ' + this.iconStatutPipe.transform('D_ATTENTE_V1'),
             routerLink: '/dossier/recherche/gestionnaire',
             routerLinkActiveOptions : {exact : true},
             queryParams: {'typeRecherche': 'dashboardCard'},
             styleClass: 'is-orange',
             command : (event : any) => {
               this.refreshActiveMenuItemWithParamOnClick(event);
             }
           }
         );
       }

       if (profiles.includes(ProfilesUser.EXPERT_EXTERNE) || profiles.includes(ProfilesUser.EXPERT_INTERNE)
         || profiles.includes(ProfilesUser.ADMINISTRATEUR) || profiles.includes(ProfilesUser.ADMINISTRATEUR_TECHNIQUE)) {
         menus.push(
           {
             label: this.translateService.getElement('NAVIGATION.DOSSIER'),
             icon: 'inc-icon ' + this.iconStatutPipe.transform('ENCOURS'),
             routerLink: '/dossier/recherche/expert',
             queryParams: {'typeRecherche': 'dashboardCard'},
             command : (event : any) => {
               this.refreshActiveMenuItemWithParamOnClick(event);
             }
           }
         );
       }

       if (profiles.includes(ProfilesUser.AGENT) || profiles.includes(ProfilesUser.ADMINISTRATEUR) || profiles.includes(ProfilesUser.ADMINISTRATEUR_TECHNIQUE)) {

         menus.push({
           label: this.translateService.getElement('NAVIGATION.DEMANDE.SUIVI'),
           icon: 'inc-icon ' + this.iconStatutPipe.transform('DEMANDEENCOURS'),
           routerLink: '/dossier/demande/suivi',
           queryParams: {'typeRecherche': 'dashboardCard'},
           command : (event : any) => {
              this.refreshActiveMenuItemWithParamOnClick(event);
           }
         });
       }

       if (profiles.includes(ProfilesUser.AGENT)) {

         this.typesLivrables = await  this.dossierServcie.getTypeLivrableUser(0);

         if(this.typesLivrables) {
           let items = [];
           if (this.findTypeLivrable(this.typesLivrables, TypeLivrable.TVRAPPORTXX)) {
             items.push({
               label: this.translateService.getElement('NAVIGATION.DEMANDE.ITEMDEMANDE.RAPPORT'),
               icon: 'pi pi-fw pi-list',
               routerLink: '/dossier/demande/creation',
               queryParams: {'type': TypeLivrable.TVRAPPORTXX},
               command : (event : any) => {
                 this.refreshActiveMenuItemWithParamOnClick(event);
               }
             });
           }
           if (this.findTypeLivrable(this.typesLivrables, TypeLivrable.TVAVISDOSSI)) {
             items.push({
               label: this.translateService.getElement('NAVIGATION.DEMANDE.ITEMDEMANDE.AVIS'),
               icon: 'pi pi-fw pi-euro',
               routerLink: '/dossier/demande/creation',
               queryParams: {'type': TypeLivrable.TVAVISDOSSI},
               command : (event : any) => {
                 this.refreshActiveMenuItemWithParamOnClick(event);
               }
             });
           }

           if (items.length > 0) {
             menus.push({
                 label: this.translateService.getElement('NAVIGATION.DEMANDE.CREATION'),
                 icon: 'inc-icon ' + this.iconStatutPipe.transform('ADD'),
                 items: items,
               }
             );
           }
         }
       }
     }

     menus.push(
       {
         label: this.translateService.getElement('NAVIGATION.RESEARCH'),
         icon: 'inc-icon ' + this.iconStatutPipe.transform('RESEARCH'),
         //routerLink: '',
         command: (event: any) => {
           this.showAdvanceSearch();
         },
         styleClass: 'is-green-light'
       },
       {
         label: this.translateService.getElement('NAVIGATION.PROFIL'),
         icon: 'inc-icon inc-icon-settings',
         routerLink: '/profil',
         styleClass: 'is-yellow',
         command : (event : any) => {
           this.refreshActiveMenuItemOnClick(event);
         }
       });
     if (profiles && profiles.includes(ProfilesUser.ADMINISTRATEUR_TECHNIQUE) && runtimeEnvironment.isInterne && runtimeEnvironment.enableAdminTech) {
       menus.push(
         {
           label: this.translateService.getElement('NAVIGATION.MONITORING'),
           icon: 'inc-icon inc-icon-settings',
           routerLink: '/monitoring',
           styleClass: 'is-yellow',
           command : (event : any) => {
             this.refreshActiveMenuItemOnClick(event);
           }
         });
     }
     menus.push(
       {
         styleClass: 'is-spacer'
       },
       {
         label: this.translateService.getElement('NAVIGATION.AIDE'),
         icon: 'inc-icon inc-icon-help',
         styleClass: 'help',
         command : () => {
           window.open('/help-navigation', '_blank')
         }
       },
       {
         label: this.translateService.getElement('NAVIGATION.DECONNEXION'),
         icon: 'inc-icon inc-icon-night',
         styleClass: 'logout',
         routerLink: '/deconnexion'
       }
     );

     this.items = menus;
     this.classNav = 'have-' + this.items.length;
   }

  findTypeLivrable(typesLivrables: ReferentielValeur[], key : string) {
    return typesLivrables?.find(type => type.code === key);
  }

  showAdvanceSearch() {

    this.ref = UtilsFonction.rechercheAvancee(this.dialogService, this.translateService);

    this.ref.onClose.subscribe((data : any) =>{
      if(data) {
        if(data) {
          let params = data.params;

          if(params?.adresseBien) {
            const adress = params.adresseBien;
            params['codePostal'] = adress?.codePostal;
            params['ville'] = adress?.ville;
            params['nomVoie'] = adress?.nomVoie;
            params['numeroVoie'] = adress?.numero;
            params['complement'] = adress?.complement;
          }
          delete params['adresseBien'];

          if(params.typeVue && params.typeVue.length > 1) {
            params['typeVues'] = params.typeVue.join();
          }
          delete params['typeVue'];
          this.router.navigate([data.url], {queryParams : params});
        }

        this.menuAdvanceSearch = false;
      }
    });

  }

  removeIsActiveOnMenuRecherche() {
    if(this.stock.item.label !== this.translateService.getElement('NAVIGATION.RESEARCH')) {
      this.stock.originalEvent.target.parentNode.classList.remove("p-menuitem-link-active");
    }
  }

  refreshActiveMenuItemOnClick(event : any) {
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
      this.router.navigateByUrl(event.item.routerLink);
    })
  }

  refreshActiveMenuItemWithParamOnClick(event : any) {
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
      this.router.navigate([event.item.routerLink],{ queryParams: event.item.queryParams });
    })
  }

}
