import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {DossierService} from "../../../core/service/dossier.service";
import {Dossier} from "../../../core/models/dossiers/dossier";
import {ConfirmationService, MenuItem, Message} from "primeng/api";
import {Civilite} from "../../../core/models/param/civilite";
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {DialogService, DynamicDialogRef} from "primeng/dynamicdialog";
import {Adresse} from "../../../core/models/adresse";
import {UserService} from "../../../core/service/user/user.service";
import {User} from "../../../core/models/user/user.model";
import {Contact} from "../../../core/models/dossiers/creation-demande";
import {FicheDossierObsevableService} from "../../../shared/observable/fiche-dossier-obsevable.service";
import {ParamTypeBien} from "../../../core/models/bien/param-type-bien";
import {ReferentielValeur} from "../../../core/models/referentiel-valeur";
import {BienService} from "../../../core/service/bien.service";
import {TranslateService} from "../../../shared/translate/translate.service";
import {LocalStorageService} from "../../../core/service/local-storage/local-storage.service";
import {ProfilesUser} from "../../../core/models/user/profiles-user";
import {DatePipe} from "@angular/common";
import {StatutDossier} from "../../../core/models/enum/statut-dossier";
import {ConfigConstants} from "../../../core/models/enum/config-constants";
import {InfoExpertise} from "../../../core/models/dossiers/info-expertise";
import {PourcentageNegociation} from "../../../core/models/param/pourcentage-negociation";
import {Mission} from "../../../core/models/dossiers/mission";
import {AffectationRechercheAvanceeExpertComponent} from "../../../core/dialog/affectation-recherche-avancee-expert/affectation-recherche-avancee-expert.component";
import {MessageStorageService} from "../../../core/service/local-storage/message-storage.service";
import {NgxSpinnerService} from "ngx-spinner";
import {FraisFournisseur} from "../../../core/models/dossiers/frais-fournisseur";
import {UtilsFonction} from "../../../shared/providers/utils-fonction";
import {ConfigSizeInput} from "../../../shared/providers/config-size-input";
import {ValidationLivrableComponent} from "../../../core/dialog/validation-livrable/validation-livrable.component";
import {DocumentService} from "../../../core/service/document.service";
import {saveAs} from "file-saver";
import {HttpResponse} from "@angular/common/http";
import {Signataire} from "../../../core/models/user/signataire";
import {Document} from "../../../core/models/document/document.model";
import {firstValueFrom, take} from 'rxjs';
import {LivrableDossier} from "../../../core/models/enum/livrable-dossier";
import {EditDossierAffectationComponent} from "../../../core/dialog/edit-dossier-affectation/edit-dossier-affectation.component";
import {DuplicationDossierComponent} from "../../../core/dialog/duplication-dossier/duplication-dossier.component";
import {RejetLivrableComponent} from "../../../core/dialog/rejet-livrable/rejet-livrable.component";


@Component({
  selector: 'app-fiche-dossier',
  templateUrl: './fiche-dossier.component.html',
  styleUrls: ['./fiche-dossier.component.scss']
})
export class FicheDossierComponent implements OnInit {

  ConfigConstants = ConfigConstants;

  stock : any;

  infoMessage : string;
  idDossier :any;

  dossier : Dossier;

  anchorItem: MenuItem[] = [];
  actionsItem: MenuItem[] = [];
  livrableItem: MenuItem[] = [];
  items : MenuItem[] = [];

  civilites : Civilite[];

  form : FormGroup;

  fraisExp : any[];

  expert : InfoExpertise;
  qualites : any[] = [];

  typeBiens :  ParamTypeBien[];
  etages : ReferentielValeur[] = [];

  submitted = false;
  errorMessageFormInvalid: Message[];

  userConnecte : User;

  stepsList : any [] = [];

  purcentageNeg : PourcentageNegociation[];

  qualitesContact : any[];

  noChangeDetected : boolean = true;

  changeDetectedClient : boolean;
  changeDetectedContact : boolean;
  changeDetectedBien : boolean;

  mtHTfraisRefacturable : number;
  pourcentagePilotage : number;

  fraisExpert : number;

  message : Message[];
  messageReject : Message[];

  messageVerroullage : Message[];
  controlModeUserIsVerouilleur : boolean;

  detectedModificationHt : boolean;

  modificationClientAndContact : boolean = true;

  profiles : ProfilesUser[];

  configSizeInput : ConfigSizeInput;

  verouillerUploadDocument : boolean;

  expertInterneUser : User[];

  userSignataires : Signataire[];

  guidLivrable : string;

  valueTypeLivrable : string;

  static AVIS_VALEUR = 'AVIS_VALEUR';
  static RAPPORT_EXPERTISE = 'RAPPORT_EXPERTISE';
  static SYNTHESE = 'SYNTHESE';

  existeDocAvisValeur : boolean = false;
  existDocRapport : boolean = false;

  constructor(
      private route: ActivatedRoute, private dossierService : DossierService, private fb : FormBuilder,
      public ref: DynamicDialogRef, public dialogService: DialogService,
      private userService : UserService, private cdref: ChangeDetectorRef, private bienService : BienService,
      private ficheDossierObservable : FicheDossierObsevableService, private translate : TranslateService,
      private userStorage : LocalStorageService, private datePipe : DatePipe, private router : Router,
      private messageStorage : MessageStorageService, private spinnerService: NgxSpinnerService,
      private confirmation: ConfirmationService, private documentService : DocumentService
  ) {
    this.route.params.subscribe(param => {
      this.idDossier = param.id;
    });
    this.configSizeInput = new ConfigSizeInput();
  }

  ngOnInit(): void {

    if(this.messageStorage.getMessage()) {
      this.message = [
        {severity:'success', summary:this.translate.getElement(this.messageStorage.getMessage(), this.messageStorage.getKey1(), this.messageStorage.getKey2()) }
      ];

      this.messageStorage.removeMessage();
      this.scrollToTop();
    }
     this.getAllUtilisateurSignataires();
     this.getUserConnected();
     this.getListTypeBien();
     this.getAllUserByProfiles();
     this.getListEtage();
     this.getListQualiteContact();
     this.getcivilites();
     setTimeout(() => {
     this.getDossierById(this.idDossier);
     }, 1000);

    this.fraisExp = [
      {name : 'Irrécupérable', value : "1" },
      {name :'Récuperable' , value : "0"}
    ];

    this.dossierService.getAllParamPourcentageNegociation().subscribe(data => {
      this.purcentageNeg = data;
    });
  }

  ngAfterContentChecked() {
    this.cdref.detectChanges();
  }

  async getUserConnected() {
    this.profiles = await this.userService.getUserConnectedProfilesById();
    this.userConnecte = await this.userService.getUserConnectedByToken();
    if(this.userConnecte) {
      this.userConnecte.profiles = this.profiles;
    }
  }

  async getProfilUserConnected() {
    this.profiles = await this.userService.getUserConnectedProfilesById();
  }

  public hasAccess(role: string) {
    return Object.values(this.profiles).map(item => String(item)).includes(role);
  }

  private detetectionControleMode() {
    this.messageVerroullage = null;
    if (this.dossier.validationVerrouilleur && this.dossier.dateValidationVerrouillage && this.dossier.statut === StatutDossier.D_ATTENTE_V1) {

      let dateVer = this.datePipe.transform(this.dossier.dateValidationVerrouillage, "dd/MM/yyyy HH:mm:ss");
      if(this.dossier.etatEnvoi === ConfigConstants.ETAT_ENVOI_A_VALIDER  && this.dossier.validationVerrouilleur === this.userConnecte?.id
        && (this.userConnecte?.profiles?.includes(ProfilesUser.ASSISTANTE) || this.userConnecte?.profiles?.includes(ProfilesUser.ANIMATEUR_RESEAU)
        || this.userConnecte?.profiles?.includes(ProfilesUser.ADMINISTRATEUR)|| this.userConnecte?.profiles?.includes(ProfilesUser.ADMINISTRATEUR_TECHNIQUE))
        ) {
        this.messageVerroullage =[ {severity:'info', summary : this.translate.getElement('DOSSIER.MESSAGE.VEROUILLAGE_1', dateVer)}];
        this.controlModeUserIsVerouilleur = true;
        return;
      }
      else if(this.dossier.validationVerrouilleur !== this.userConnecte?.id && this.dossier.dateValidationVerrouillage){
        this.messageVerroullage =[ {severity:'info', summary : this.translate.getElement('DOSSIER.MESSAGE.VEROUILLAGE_2', this.dossier.nomCompletVerouilleur, dateVer)}];
        this.form.disable();
        this.controlModeUserIsVerouilleur = false;
        this.verouillerUploadDocument = true;
        return;
      }
    }

    this.controlModeUserIsVerouilleur = true;


  }

  getDossierById(idDossier : number) {
    this.dossierService.getDosierById(idDossier).subscribe(
      async(data) => {
        if(data) {

          this.dossier = data;
          this.expert = this.dossier.infoExpertise;
          this.initForm();
          this.navigationContextuelle();
          this.stepsList = UtilsFonction.navSteps(this.dossier, this.translate, this.datePipe);
          await this.docsContainsFilename(this.dossier.bien.idBien);
          if(this.userConnecte) {
            this.detetectionControleMode();
            if(this.isProfilAuthorize()
              && this.controlModeUserIsVerouilleur) {
              // setTimeout(() => {
                this.navActionsItem(this.dossier);
                this.navLivrablesItem(this.dossier);
              // }, 3000);

            }
          }
        }
      }, error => {
        if(error.status === 401) {
          this.router.navigateByUrl("/role-denied");
        }
      }
    )
  }

  getListQualiteContact() {
    this.dossierService.getQualiteList().subscribe(data => {
      this.qualitesContact = data;
    })
  }

  async docsContainsFilename(idBien: number) {
    const docs = await firstValueFrom(
      this.documentService.getDocumentsDossier(idBien, false).pipe(take(1))
    )
    .then(data => {
      if (data) {
        this.existeDocAvisValeur = data.some(doc => doc.nom.startsWith('AvisDeValeur') && doc.nom.includes(this.dossier?.numeroDossier));
        this.existDocRapport = data.some(doc => doc.nom.startsWith('Rapport') && doc.nom.includes(this.dossier.numeroDossier));
         let doc : Document =  data.find(doc => (doc.nom.startsWith('Rapport') || doc.nom.startsWith('Avis')) && doc.nom.includes(this.dossier.numeroDossier));
         if(doc) {
           this.guidLivrable = doc.guid;
         }
      }
    });
  }

  /**
   * Recupère les informations de l'expert
   */
  disabledInfoExpertise() {
    if(this.dossier?.infoExpertise && this.dossier?.infoExpertise?.expert) {
        if(this.expert?.profilsExpert?.includes(ProfilesUser.EXPERT_EXTERNE)) {
          this.form.controls['honoraireHT']['controls']['pilotage'].disable();
          if(this.dossier.statut === StatutDossier.F_ENCOURS || this.dossier.statut === StatutDossier.FACTUREE){
            this.form.controls['honoraireHT']['controls']['honoExpertMontantHT'].disable();
            this.form.controls['honoraireHT']['controls']['fraisExpertMontantHt'].disable();
          }
        }else if(this.expert?.profilsExpert?.includes(ProfilesUser.EXPERT_INTERNE)) {
          this.form.controls['honoraireHT']['controls']['honoExpertMontantHT'].disable();
        }
    }

  }

  /**
   * Desactiver la section bien pour les dossier de statut (A_AFFECTER, ENCOURS, D_ATTENTE_V1)
   */
  notModifyBien() {
    let statut = ['A_AFFECTER', 'ENCOURS', 'D_ATTENTE_V1'];
    const existe = statut.some(st => st === this.dossier.statut);
    if(!existe) {
      this.form.controls['bien'].disable();
    }
  }


  /**
   * initialisation du formulaire
   */
  initForm() {

    const dossier : Dossier = new Dossier(this.dossier);

    this.form = this.fb.group({
      client : this.initFormClient(dossier),
      contact : this.initFormContact(dossier),
      bien : this.initBienForm(dossier),
      honoraireHT : this.initHTForm(dossier),
      commentaire : [this.dossier.mission?.commentaire]
    });

    this.notModifyBien();

    if(!this.isProfilAuthorize()) {
      this.form.disable();
    }

    if(this.dossier.statut === StatutDossier.F_ENCOURS || this.dossier.statut === StatutDossier.FACTUREE) {
      this.form.controls['honoraireHT']['controls']['honoraireClient'].disable();
      this.form.controls['honoraireHT']['controls']['fraisRefacturable'].disable();
    }

    if(this.dossier?.statut === StatutDossier.ANNULE) {
      this.form.controls['honoraireHT'].disable();
    }

    if(this.dossier?.statut === StatutDossier.F_ENCOURS || this.dossier?.statut === StatutDossier.FACTUREE
      || this.dossier?.statut === StatutDossier.A_FACTURER || this.dossier?.statut === StatutDossier.ANNULE){
      this.modificationClientAndContact = false;
      this.form.controls['client'].disable();
      this.form.controls['contact'].disable();
    }

    this.disabledInfoExpertise();

  }


  /**
   * MAJ des modifications sur le dossier
   */
  sauvegarder() {

    this.spinnerService.show();

    this.message = null;
    this.messageStorage.removeMessage();
    this.submitted = true;
    let dossier = new Dossier(this.dossier);


    if(this.form.value.honoraireHT.pilotage < 0 || this.form.value.honoraireHT.pilotage > 100) {
      this.errorMessageFormInvalid = [ {severity:'error',
        summary: this.translate.getElement('DOSSIER.MESSAGE.ERROR.POURCENTAGE_PILOTAGE') }];
      this.scrollToTop();
      this.spinnerService.hide();
      return;
    }


    this.identiqueClientAndContact()

    if(this.form.invalid || this.checkIfFormClientISValid() || this.checkifFormContactIsValid() || this.checkIfBienFormIsNotValid()) {
      this.errorMessageFormInvalid = [ {severity:'error',
        summary: this.translate.getElement('MESSAGE.ERROR.MS_CHAMPS_OBLIGATOIRE') }];
      this.scrollToTop();
      this.spinnerService.hide();
      return;
    }


    this.updateClient(dossier);
    this.updateContact(dossier)
    this.updateBien(dossier);
    this.updateHonoraire(dossier);

    if(!this.detectionModification() && this.dossier?.mission?.commentaire == this.form.value.commentaire) {
      this.message = [
        {severity:'warn', summary: this.translate.getElement('DOSSIER.NO_MESSAGE_DETECTED') }
      ];
      this.scrollToTop();
      this.spinnerService.hide();
      return;
    }

    dossier.mission.commentaire =  this.form.get('commentaire').value;


    this.dossierService.updateFicheDossier(dossier).subscribe(data => {
      if(data) {
        this.messageStorage.saveMessage('DOSSIER.MESSAGE.UPDATE_DOSSIER', dossier.numeroDossier.toString());
        this.spinnerService.hide();
        this.reloadCurrentRoute();
      }else {
        this.message =[ {severity:'error', summary : this.translate.getElement('DOSSIER.MESSAGE.ERROR.UPDATE_DOSSIER')}];
        this.scrollToTop();
        this.spinnerService.hide();
      }
    }, error => {
      if(error.status === 401 ) {
        this.reloadCurrentRoute();
      }
      this.message =[ {severity:'error', summary : this.translate.getElement('DOSSIER.MESSAGE.ERROR.UPDATE_DOSSIER')}];
      this.scrollToTop();
      this.spinnerService.hide();
    })

  }

  detectionModification() : boolean {
    return this.changeDetectedClient || this.changeDetectedContact || this.changeDetectedBien || this.detectedModificationHt;
  }

  /**
   * MAJ des informations du clients modifiées
   * @param cli
   * @param form
   */
  updateClient(dossier : Dossier) {
    let client = this.form.value.client;/*on la récupére d'apres formulaire */
    const typeCli = dossier?.client?.isClientPhysique ? 'human' : 'company';/*on la récupére du bd lors du get */
    this.changeDetectedClient = false;

    if(this.form.controls.client.status !== 'DISABLED') {

      if (
        typeCli !== client.customerType
        || dossier?.client?.telephone1 !== client.phone1
        || dossier?.client?.telephone2 !== client.phone2
        || dossier?.client?.mail1 !== client.email1
        || dossier?.client?.mail2 !== client.email2
        || dossier?.client?.numeroContrat !== client.numContrat
        || dossier?.client?.adresse?.adresseFormate !== client.adresse?.adresseFormate
        || dossier?.client?.adresse?.boitePostale !== client.adresse?.boitePostale
        || dossier?.client?.adresse?.lieuDit !== client.adresse?.lieuDit
        || dossier?.client?.adresse?.complement !== client.adresse?.complement
        || dossier?.client?.ir != client.fraisExp?.value
      ) {
        dossier.client.telephone1 = client.phone1;
        dossier.client.telephone2 = client.phone2;
        dossier.client.mail1 = client.email1;
        dossier.client.mail2 = client.email2;
        dossier.client.numeroContrat = client.numContrat;
        dossier.numeroContrat = client.numContrat;
        dossier.client.adresse = client.adresse;
        dossier.client.ir = client.fraisExp?.value;
        this.changeDetectedClient = true;
      }

      if (client.customerType === 'human' && (dossier?.client?.nom !== client.nomClient
        || dossier?.client?.prenom !== client.prenomClient
        || dossier?.client?.codeCivilite !== client.gender?.codeCivilite)) {
        dossier.client.nomMoral = null;
        dossier.client.nomRepresentant = null;
        dossier.client.cpRepresentant = null;
        dossier.client.nom = client.nomClient;
        dossier.client.prenom = client.prenomClient;
        dossier.client.codeCivilite = client.gender?.codeCivilite;
        this.changeDetectedClient = true;
      }

      if (client.customerType === 'company' && (dossier?.client?.nomMoral !== client.nomMoral
        || dossier?.client?.nomRepresentant !== client.nomRepresentant
        || dossier?.client?.cpRepresentant !== client?.cpRepresentant)) {
        dossier.client.nomMoral = client.nomMoral;
        dossier.client.nomRepresentant = client.nomRepresentant;
        dossier.client.cpRepresentant = client.cpRepresentant;
        dossier.client.nom = null;
        dossier.client.prenom = null;
        dossier.client.codeCivilite = null;
        this.changeDetectedClient = true;
      }


    }
  }

  scrollToTop(){
    window.scrollTo(0,0);
  }

  /**
   *
   * @param client
   */
  initFormClient(dossier : Dossier) : FormGroup {
    return  new FormGroup({
      customerType : new FormControl(dossier?.client?.isClientPhysique ? 'human' : 'company', [Validators.required]),
      gender : new FormControl(dossier?.client?.civilite? dossier?.client?.civilite : null),
      nomClient:new FormControl(dossier?.client.nom ? dossier?.client.nom : null,
        [Validators.maxLength(this.configSizeInput.NOM_CLIENT), Validators.pattern(this.configSizeInput.regexPattern)]),
      prenomClient: new FormControl(dossier?.client?.prenom ? dossier?.client?.prenom : null,
        [Validators.maxLength(this.configSizeInput.PRENOM_CLIENT), Validators.pattern(this.configSizeInput.regexPattern)]),
      nomMoral : new FormControl(dossier?.client?.nomMoral ? dossier?.client?.nomMoral : null,
        [Validators.maxLength(this.configSizeInput.NOM_MOARAL_CLIENT), Validators.pattern(this.configSizeInput.regexPattern)]),
      nomRepresentant : new FormControl(dossier?.client?.nomRepresentant ? dossier?.client?.nomRepresentant : null,
        [Validators.maxLength(this.configSizeInput.REPRESENTANT_CLIENT), Validators.pattern(this.configSizeInput.regexPattern)]),
      phone1 : new FormControl(dossier?.client?.telephone1 ? dossier?.client?.telephone1 : null,
        [Validators.maxLength(this.configSizeInput.TELEPHONE_CLIENT), Validators.pattern(this.configSizeInput.regexPattern)]),
      phone2 : new FormControl(dossier?.client?.telephone2 ? dossier?.client?.telephone2 : null, [Validators.maxLength(this.configSizeInput.TELEPHONE_CLIENT),
        Validators.pattern(this.configSizeInput.regexPattern)]),
      email1 : new FormControl(dossier?.client?.mail1 ? dossier?.client?.mail1 : null,
        [Validators.email, Validators.maxLength(this.configSizeInput.MAIL_CLIENT), Validators.pattern(this.configSizeInput.regexPattern)]),
      email2 : new FormControl(dossier?.client?.mail2 ? dossier?.client?.mail2 : null,
        [Validators.email, Validators.maxLength(this.configSizeInput.MAIL_CLIENT), Validators.pattern(this.configSizeInput.regexPattern)]),
      adresse : this.initAdresse(dossier?.client?.adresse, true),
      numContrat :new FormControl(
        dossier?.client?.numeroContrat ? dossier?.client?.numeroContrat : null,
        [this.dossier?.tiers?.entiteClient !== 'BNPPARIBAS' ? Validators.required : Validators.nullValidator,
            Validators.minLength(7), Validators.maxLength(this.configSizeInput.NUMERO_CONTRAT), Validators.pattern(this.configSizeInput.regexPattern)]
      ),
      fraisExp : new FormControl(
        this.dossier.ir ? this.fraisExp.find(fe => fe.value ===this.dossier.ir) : null,
        [this.dossier?.tiers?.entiteClient  === 'UCB' || this.dossier?.tiers?.entiteClient  === 'UCBE'
          || this.dossier?.tiers?.entiteClient  === 'Service Expertise UCB Suisse' ? Validators.required : Validators.nullValidator]
      ),
    })
  }

  /**
   * Initialisation form adresse
   * @param adresse
   */
  initAdresse(adresse : Adresse, required : boolean) : FormGroup  {

    let adressFormat = adresse.adresseFormate;

    if(!adressFormat) {
      adressFormat = UtilsFonction.addresseFormat(adresse);
    }

    return new FormGroup({
      adresseFormate: new FormControl(adressFormat, Validators.maxLength(this.configSizeInput.ADRESSE_FORMATE)),
      numero: new FormControl(adresse.numero,
        [Validators.maxLength(this.configSizeInput.NUMERO_ADRESSE), Validators.pattern(this.configSizeInput.regexPattern)]),
      lieuDit: new FormControl(adresse.lieuDit,
        [Validators.maxLength(this.configSizeInput.LIEU_DIT), Validators.pattern(this.configSizeInput.regexPattern)]),
      nomVoie: new FormControl(adresse.nomVoie, [required ? Validators.required : Validators.nullValidator,
        Validators.maxLength(this.configSizeInput.NOM_VOIE), Validators.pattern(this.configSizeInput.regexPattern)]),
      codePostal: new FormControl(adresse.codePostal, [required ? Validators.required : Validators.nullValidator,
        Validators.maxLength(this.configSizeInput.CODE_POSTALE), Validators.pattern(this.configSizeInput.regexPattern)]),
      ville: new FormControl(adresse.ville, [required ? Validators.required : Validators.nullValidator,
        Validators.maxLength(this.configSizeInput.VILLE), Validators.pattern(this.configSizeInput.regexPattern)]),
      pays: new FormControl(adresse.pays, required ? Validators.required : Validators.nullValidator),
      boitePostale: new FormControl(adresse.boitePostale,
        [Validators.maxLength(this.configSizeInput.BOITE_POSTALE)]),
      complement: new FormControl(adresse.complement,
        [Validators.maxLength(this.configSizeInput.COMPLEMENT_ADRESSE), Validators.pattern(this.configSizeInput.regexPattern)]),
      lat : new FormControl(adresse.lat),
      lng : new FormControl(adresse.lng),
      adresseChange : new FormControl(adresse.adresseChange)
    })
  }

  /**
   * Initialisation des données pour la section contact
   * @param demande
   */
  initFormContact(dossier : Dossier) : FormGroup {
    return new FormGroup({
      contactType : new FormControl(dossier?.demande?.contactIdentiqueClient  ? 'idClient' : 'autre'),
      customerContactType : new FormControl(dossier?.demande?.contactPhysique ? 'human' : 'company'),
      qualite : new FormControl(this.qualitesContact ? this.qualitesContact.find(q => q.id === dossier?.demande?.qualiteContact?.id) : null),
      gender : new FormControl(dossier?.demande?.civiliteContact),
      nomContact : new FormControl(dossier?.demande?.nomContact,
        [Validators.maxLength(this.configSizeInput.NOM_CONTACT), Validators.pattern(this.configSizeInput.regexPattern)]),
      prenomContact: new FormControl(dossier?.demande?.prenomContact,
        [Validators.maxLength(this.configSizeInput.PRENOM_CONTACT), Validators.pattern(this.configSizeInput.regexPattern)]),
      nomMoral : new FormControl(dossier?.demande?.raisonSocialeContact,
        [Validators.maxLength(this.configSizeInput.RAISON_SOCIALE_CONTACT), Validators.pattern(this.configSizeInput.regexPattern)]),
      phone1 : new FormControl(dossier?.demande?.telephoneContact,
        [Validators.maxLength(this.configSizeInput.TELEPHONE_CONTACT), Validators.pattern(this.configSizeInput.regexPattern)]),
      phone2 : new FormControl(dossier?.demande?.portableContact,
        [Validators.maxLength(this.configSizeInput.TELEPHONE_CONTACT), Validators.pattern(this.configSizeInput.regexPattern)]),
      email1 : new FormControl(dossier?.demande?.mail1Contact, [Validators.email, Validators.maxLength(this.configSizeInput.MAIL_CONTACT),
        Validators.pattern(this.configSizeInput.regexPattern)]),
      email2 : new FormControl(dossier?.demande?.mail2Contact, [Validators.email, Validators.maxLength(this.configSizeInput.MAIL_CONTACT),
        Validators.pattern(this.configSizeInput.regexPattern)]),
      adresse : this.initAdresse(dossier?.demande?.adresseContact, false)
    });
  }

  /**
   *  Initialisation des données pour la section bien
   * @param dossier
   */
  initBienForm(dossier : Dossier) : FormGroup {

    return new FormGroup({
      typeBien :  new FormControl(this.typeBiens ? this.typeBiens.find(tb => tb.paramReferentielValeurByCode?.libelle === dossier?.bien?.typeBien) : null,
         Validators.required),
      customerAdress :  new FormControl(dossier?.bien?.identiqueClient ? 'idClient' : 'autre'),
      adresse : this.initAdresse(dossier?.bien?.adresse, true),
      etage :  new FormControl(this.etages ? this.etages.find(e => e.libelle === dossier?.bien?.etage) : null)
    });

  }

  /**
   *  Initialisation des données pour la section honoraire H.T
   * @param dos
   */
  initHTForm(dos : Dossier) {

    this.mtHTfraisRefacturable = this.getMtHTFraisRefacturableExpert(true, ConfigConstants.TYPE_FRAIS_DEPLACEMENT, ConfigConstants.TYPE_FOURNISSEUR_EXPERT_RESEAU);

    this.fraisExpert = this.getMtHTFraisRefacturableExpert(false, ConfigConstants.TYPE_FRAIS_DEPLACEMENT, ConfigConstants.TYPE_FOURNISSEUR_EXPERT_RESEAU);
    let pourcentagePilotage = 0;
    if(dos.pourcentagePilote1 || dos.pourcentagePilote1 === 0) {
      pourcentagePilotage = dos.pourcentagePilote1 * 100;
    }else if(this.expert) {
      if(this.expert?.profilsExpert?.includes(ProfilesUser.EXPERT_EXTERNE)) {
        pourcentagePilotage = 100;
      }else if(this.expert?.profilsExpert?.includes(ProfilesUser.EXPERT_INTERNE)) {
        pourcentagePilotage = 0;
      }
    }

    let user = this.expertInterneUser.find(exp => exp.id === dos?.mission?.pilote1);

    if(user) {
      user['nomComplet'] = user.prenom + ' ' + user.nom;
    }

    return new FormGroup({
      honoraireClient : new FormControl(dos?.infoExpertise?.honoraire ? dos.infoExpertise.honoraire : 0),
      fraisRefacturable : new FormControl(this.mtHTfraisRefacturable),
      negociation : new FormControl(dos.mission?.paramPourcentageNegociation ? dos.mission?.paramPourcentageNegociation
        : this.purcentageNeg.find(prc => prc.libelle === "0")),
      pilotage : new FormControl(pourcentagePilotage),
      honoExpertMontantHT : new FormControl(),
      fraisExpertMontantHt : new FormControl(this.fraisExpert),
      expertInterne :   new FormControl(user),
    });

  }

  getAllUserByProfiles() {
    let userProfile : any[] = [ProfilesUser.EXPERT_INTERNE];
    this.userService.getAllUserByProfiles(userProfile).subscribe(expertUser => {
      this.expertInterneUser = expertUser;
    });
  }



  /**
   * retourne le montant des frais pour l'expert
   * @param refacturable
   * @param codeTypeFrais
   * @param codeTypeFournisseur
   */
  getMtHTFraisRefacturableExpert(refacturable : boolean, codeTypeFrais : string, codeTypeFournisseur : string) {
    let mtHTfraisRefacturable = 0;
    if(this.dossier.fraisFournisseurs.length > 0) {
      this.dossier.fraisFournisseurs.map(fr => {
        if(fr.refacturable === refacturable && fr.typesFrais.code === codeTypeFrais && fr.typeFournisseur.code === codeTypeFournisseur) {
          mtHTfraisRefacturable = fr.montantHT;
        }
      })
    }
    return mtHTfraisRefacturable;
  }

  isProfilAuthorize() : boolean {

    return this.userConnecte && (this.userConnecte?.profiles?.includes(ProfilesUser.ADMINISTRATEUR) ||this.userConnecte?.profiles?.includes(ProfilesUser.ADMINISTRATEUR_TECHNIQUE)
      ||this.userConnecte?.profiles?.includes(ProfilesUser.ASSISTANTE)
      || this.userConnecte?.profiles?.includes(ProfilesUser.ANIMATEUR_RESEAU));
  }

  isProfilExterne() : boolean {
    return this.userConnecte && this.userConnecte?.profiles?.includes(ProfilesUser.EXPERT_EXTERNE);
  }

  /**
   * verifie la validité des informations du clients modifiées
   * @param client
   */
  checkIfFormClientISValid() : boolean {
    let client = this.form.value.client;
    return (this.form.controls.client.status !== 'DISABLED' && (client.customerType === null || client.adresse.adresseFormate === null ||  client.adresse.adresseFormate.length === 0
      || (client.customerType === 'human'
        && (client.gender === null || client.nomClient === null || client.nomClient.length === 0
          || client.prenomClient === null || client.prenomClient.length === 0))
      || (client.customerType === 'company'
        && (client.nomMoral === null || client.nomMoral.length === 0 || client.nomRepresentant === null || client.nomRepresentant.length === 0 ))
    ));
  }

  /**
   * verifie la validité des information du contact modifiées
   * @param contact
   */
  checkifFormContactIsValid() : boolean {
    let contact = this.form.value.contact;
    return (this.form.controls.contact.status !== 'DISABLED' && (contact.contactType === 'autre' &&
      (contact.adresse.adresseFormate === null || contact.adresse.adresseFormate.length === 0
        || (contact.customerContactType === 'human' && (!contact.gender
              || contact.nomContact === null || contact.nomContact.length === 0 || contact.prenomContact === null || contact.prenomContact.length === 0))
        || (contact.customerContactType === 'company' && (contact.nomMoral === null || contact.nomMoral?.length === 0))
      )
    ));
  }

  /**
   * verifie la validité des informations du bien modifiées
   * @param bien
   */
  checkIfBienFormIsNotValid() {
    let bieForm = this.form.value.bien;
    return (
      this.form.controls.bien.status !== 'DISABLED' &&
      (
        bieForm.typeBien === null || (
          bieForm.customerAdress === 'autre' && (
            bieForm.adresse.codePostal === null ||
            bieForm.adresse.nomVoie === null || bieForm.adresse.nomVoie.length === 0 || bieForm.adresse.pays === null
          )
        )
      )
    );
  }


  /**
   * MAJ des informations du contact modifiées
   * @param dossier
   */
  updateContact(dossier : Dossier){


    let formContact = this.form.value.contact;
    let formClient = this.form.value.client;
    this.changeDetectedContact = false;
    if(this.form.controls.contact.status !== 'DISABLED') {
      if(!dossier?.demande?.contactIdentiqueClient && formContact.contactType === 'idClient') {
        const ctact = new Contact(
          formContact.contactType, 0, formClient.gender?.codeCivilite,
          formClient.nomClient, formClient.prenomClient, formClient.nomMoral, formClient.phone1,
          formClient.phone2, formClient.email1, formClient.email2, formClient.adresse
        );
        this.updateDemande(dossier, ctact);
        this.changeDetectedContact = true;
      }
      else if(formContact.contactType === 'autre'
        && (dossier?.demande?.nomContact !== formContact.nomContact || dossier?.demande?.prenomContact !== formContact.prenomContact
          || dossier?.demande?.qualiteContact?.id !== formContact.qualite?.id
          || dossier?.demande?.telephoneContact !== formContact.phone1 || dossier?.demande?.portableContact !== formContact.phone2
          || dossier?.demande?.mail1Contact !== formContact.email1
          || dossier?.demande?.mail2Contact !== formContact.email2 || dossier?.demande?.codeCiviliteContact !== formContact.gender?.codeCivilite
          || dossier?.demande?.adresseContact?.adresseFormate !== formContact.adresse?.adresseFormate
          || dossier?.demande?.adresseContact?.boitePostale !== formContact.adresse?.boitePostale
          || dossier?.demande?.adresseContact?.lieuDit !== formContact.adresse?.lieuDit
          || dossier?.demande?.adresseContact?.complement !== formContact.adresse?.complement
        )
      ) {
        const ctact = new Contact(formContact.contactType, formContact.qualite?.id, formContact.gender?.codeCivilite,
          formContact.nomContact, formContact.prenomContact, formContact.nomMoral,
          formContact.phone1, formContact.phone2, formContact.email1, formContact.email2, formContact.adresse);
        this.updateDemande(dossier, ctact);
        this.changeDetectedContact = true;
      }
    }

  }

  updateDemande(dossier : Dossier, ctact : any) {
    dossier.demande.nomContact = ctact.nom;
    dossier.demande.prenomContact = ctact.prenom
    dossier.demande.adresseContact = ctact.adresse;
    dossier.demande.raisonSocialeContact = ctact.raisonSociale;
    dossier.demande.telephoneContact = ctact.telephone1;
    dossier.demande.portableContact = ctact.telephone2;
    dossier.demande.mail1Contact = ctact.mail1;
    dossier.demande.mail2Contact = ctact.mail2;
    dossier.demande.codeCiviliteContact = ctact.codeCivilite;

    const qContact = this.qualitesContact.filter(qc => qc.id === ctact.idQualiteContact);
    if(qContact) {
      dossier.demande.qualiteContact = qContact[0];
    }
    dossier.demande.contactIdentiqueClient = ctact.identiqueClient;
  }

  /**
   * MAJ des informations du bien modifiées
   * @param bien
   * @param form
   */
  updateBien(dossier : Dossier) {

    let bieform =  this.form.value.bien;
    this.changeDetectedBien = false;
    if(this.form.controls.bien.status !== 'DISABLED'
      && (dossier?.bien?.refTypeBien?.code !== bieform.typeBien?.paramReferentielValeurByCode.code
        || (dossier?.bien?.etage !== bieform.etage && dossier?.bien?.etage !== bieform.etage?.libelle)
        || (dossier?.bien?.adresse.numero !== bieform.adresse.numero  || dossier?.bien?.adresse.nomVoie !== bieform.adresse.nomVoie
          || dossier?.bien?.adresse.ville !== bieform.adresse.ville || dossier?.bien?.adresse.codePostal !== bieform.adresse.codePostal
          || dossier?.bien?.adresse?.pays?.id !== bieform.adresse.pays.id || dossier?.bien?.adresse.complement !== bieform.adresse.complement
          || dossier?.bien?.adresse.boitePostale !== bieform.adresse.boitePostale || dossier?.bien?.adresse.lieuDit !== bieform.adresse.lieuDit)
      )) {

      dossier.bien.typeBien = bieform.typeBien?.paramReferentielValeurByCode.code;
      dossier.bien.adresse = bieform.adresse;
      dossier.bien.codeEtage = bieform.etage?.code;

      if(bieform.customerAdress === 'idClient') {
        dossier.bien.identiqueClient = true;
      } else  if(bieform.customerAdress === 'autre') {
        dossier.bien.identiqueClient = false;
      }

      this.changeDetectedBien = true;

    }

  }

  identiqueClientAndContact() {
    let formContact = this.form.value.contact;
    let formClient = this.form.value.client;
    if(this.form.controls.contact.status !== 'DISABLED' && !this.dossier.demande.contactIdentiqueClient && formContact.contactType === 'idClient') {
      this.form.get('contact.gender').setValue(formClient.gender);
      this.form.get('contact.nomContact').setValue(formClient.nomClient);
      this.form.get('contact.prenomContact').setValue(formClient.prenomClient);
      this.form.get('contact.adresse').setValue(formClient.adresse);
      this.form.get('contact.phone1').setValue(formClient.phone1);
      this.form.get('contact.phone2').setValue(formClient.phone2);
      this.form.get('contact.email1').setValue(formClient.email1);
      this.form.get('contact.email2').setValue(formClient.email2);
      this.form.get('contact.qualite').setValue(this.qualitesContact.find(q => q.id === 0));
    }
  }

  /**
   * MAJ des informations H.T modifiées
   * @param honoHT
   * @param dossier
   */
  updateHonoraire(dossier : Dossier) {

    let honoHT =  this.form.value.honoraireHT;

    this.detectedModificationHt = false;

    if(this.form.controls['honoraireHT']['controls']['honoraireClient'].status !== 'DISABLED' && dossier?.infoExpertise?.honoraire !== honoHT.honoraireClient) {
       let infoExpertise = new InfoExpertise(dossier.infoExpertise);
      infoExpertise.honoraire = honoHT.honoraireClient;
      dossier.infoExpertise.id = this.dossier.infoExpertise.id;
      dossier.infoExpertise = infoExpertise;
      this.detectedModificationHt = true;
    }

    if(honoHT?.expertInterne?.id && honoHT?.expertInterne?.id !== this.dossier?.mission?.pilote1) {
      dossier.mission.pilote1 = honoHT?.expertInterne?.id;
      this.detectedModificationHt = true;
    }


    if(this.form.controls['honoraireHT']['controls']['fraisRefacturable'].status !== 'DISABLED' && this.mtHTfraisRefacturable !== honoHT.fraisRefacturable){
      let fraisRefacturable : FraisFournisseur = null;
      dossier.fraisFournisseurs.map(fr => {
        if(fr.refacturable === true && fr.typesFrais.code === ConfigConstants.TYPE_FRAIS_DEPLACEMENT
          && fr.typeFournisseur.code ===  ConfigConstants.TYPE_FOURNISSEUR_EXPERT_RESEAU) {
          fraisRefacturable = fr;
          fraisRefacturable.montantHT = honoHT.fraisRefacturable;
        }
      });

      fraisRefacturable = this.createNewFournisseur(fraisRefacturable, true,  honoHT.fraisRefacturable,'DEPLCMNT',  'TFEXPEXT');
      dossier.fraisFournisseurs.push(fraisRefacturable);
      this.detectedModificationHt = true;
    }

    if(this.form.controls['honoraireHT']['controls']['negociation'].status !== 'DISABLED'
      && this.dossier?.mission?.paramPourcentageNegociation?.libelle !== honoHT.negociation.libelle){
      let mis = new Mission(dossier.mission);
      mis.paramPourcentageNegociation = honoHT.negociation;
      dossier.mission = mis;
      this.detectedModificationHt = true;
    }

    if(this.form.controls['honoraireHT']['controls']['pilotage'].status !== 'DISABLED' && honoHT.pilotage && dossier.pourcentagePilote1 * 100 !== honoHT.pilotage) {
      dossier.pourcentagePilote1 = honoHT.pilotage / 100;
      this.detectedModificationHt = true;
    }

    const honoExpert =  this.getMtHTFraisRefacturableExpert(false, ConfigConstants.TYPE_FRAIS_EXPERTISE, ConfigConstants.TYPE_FOURNISSEUR_EXPERT_RESEAU);

    if(this.form.controls['honoraireHT']['controls']['honoExpertMontantHT'].status !== 'DISABLED' && honoExpert !==   Math.round(honoHT.honoExpertMontantHT * 100) / 100) {
      let honoraireExpert : FraisFournisseur = null;
      this.dossier.fraisFournisseurs.map(fr => {
        if(fr.refacturable === false && fr.typesFrais.code === ConfigConstants.TYPE_FRAIS_EXPERTISE
          && fr.typeFournisseur.code ===  ConfigConstants.TYPE_FOURNISSEUR_EXPERT_RESEAU) {
          honoraireExpert = fr;
          honoraireExpert.montantHT = honoHT.honoExpertMontantHT;
        }
      });

      honoraireExpert = this.createNewFournisseur(honoraireExpert, false,  honoHT.fraisRefacturable,'EXPERTIS',  'TFEXPEXT');
      dossier.fraisFournisseurs.push(honoraireExpert);
      this.detectedModificationHt = true;
    }


    if(this.form.controls['honoraireHT']['controls']['fraisExpertMontantHt'].status !== 'DISABLED' && this.fraisExpert !== honoHT.fraisExpertMontantHt) {
      let fraisExpert : FraisFournisseur = null;
      dossier.fraisFournisseurs.map(fr => {
        if(fr.refacturable === false && fr.typesFrais.code === ConfigConstants.TYPE_FRAIS_DEPLACEMENT
          && fr.typeFournisseur.code ===  ConfigConstants.TYPE_FOURNISSEUR_EXPERT_RESEAU) {
          fraisExpert = fr;
          fraisExpert.montantHT = honoHT.fraisExpertMontantHt;
        }
      });
      fraisExpert = this.createNewFournisseur(fraisExpert, false,  honoHT.fraisExpertMontantHt,'DEPLCMNT',  'TFEXPEXT');
      dossier.fraisFournisseurs.push(fraisExpert);
      this.detectedModificationHt = true;
    }


  }

  /**
   * Créer nouveau frais fournisseur
   * @param fournisseur
   * @param refacturable
   * @param montantHT
   * @param codeTypeFrais
   * @param codeTypeFournisseur
   */
  createNewFournisseur(fournisseur : FraisFournisseur, refacturable : boolean, montantHT : number, codeTypeFrais : string, codeTypeFournisseur : string) {

    if(fournisseur === null) {
      fournisseur = new FraisFournisseur();
      fournisseur.refacturable = refacturable;
      fournisseur.montantHT = montantHT;
      let typeFrais = new ReferentielValeur();
      typeFrais.code = codeTypeFrais;
      let typeFournisseur = new ReferentielValeur();
      typeFournisseur.code = codeTypeFournisseur;
      fournisseur.typeFournisseur = typeFournisseur;
      fournisseur.typesFrais = typeFrais;
    }

    return fournisseur;
  }

  /**
   * Valiation du livrable rapport/avis
   * @param dossier
   * @param signataire
   */
  validerLivrable(dossier : Dossier, signataire : Signataire) {

    if(signataire) {
      this.spinnerService.show();
      this.message = null;
      this.messageStorage.removeMessage();
      dossier.signataire = signataire;
      this.dossierService.validerLivrable(dossier).subscribe( data => {
        if(data) {
          this.spinnerService.hide();
          this.messageStorage.saveMessage('DOSSIER.GESTIONNAIRE.MESSAGE.VALIDER_LIVRABLE.SUCCESS', dossier.numeroDossier.toString() );
          this.reloadCurrentRoute();
          return;
        }
        this.message = [
          {severity:'error', summary:this.translate.getElement('DOSSIER.GESTIONNAIRE.MESSAGE.VALIDER_LIVRABLE.ERROR_SERVER',  dossier.numeroDossier.toString())}
        ];
        this.spinnerService.hide();
        this.scrollToTop();
      }, error => {
        this.message = [
          {severity:'error', summary:this.translate.getElement('DOSSIER.GESTIONNAIRE.MESSAGE.VALIDER_LIVRABLE.ERROR_SERVER',  dossier.numeroDossier.toString())}
        ];
        this.spinnerService.hide();
        this.scrollToTop();
      })
    }
  }


  /**
   * Rejeter le livrable
   * @param dossier
   */
  rejeterLivrable(dossier: Dossier) {

    this.spinnerService.show();

    this.dossierService.rejectLivrable(dossier).subscribe(data => {
      if(data) {
        this.spinnerService.hide();
        this.messageStorage.saveMessage('DOSSIER.GESTIONNAIRE.MESSAGE.REJETER.SUCCESS', dossier.numeroDossier.toString() );
        this.reloadCurrentRoute();
        return;
      }
      this.message = [
        {severity:'error', summary:this.translate.getElement('DOSSIER.GESTIONNAIRE.MESSAGE.REJETER.ERROR_SERVER',  dossier.numeroDossier.toString())}
      ];
      this.spinnerService.hide();
      this.scrollToTop();
    }, error => {
      this.message = [
        {severity:'error', summary:this.translate.getElement('DOSSIER.GESTIONNAIRE.MESSAGE.REJETER.ERROR_SERVER',  dossier.numeroDossier.toString())}
      ];
      this.spinnerService.hide();
      this.scrollToTop();
    })
  }

  getAllUtilisateurSignataires() {
    this.dossierService.getAllUtilisateurSignataires().subscribe( data => {
      this.userSignataires = data;
      //this.defaultSignataire = this.userSignataires.find(us => us.id === this.userConnected.id && us.actif);
    })
  }




  /**
   *
   * @param event
   * @param id
   */
  addIsactiveNavigationContextuelleMenuItem(event : any, id : string) {
    const yOffset = -15;
    const y =  document.getElementById(id).getBoundingClientRect().top + window.pageYOffset + yOffset;
    window.scrollTo({top: y, behavior: 'smooth'});
    if(this.stock && this.stock.item.label !== event.item.label) {
      this.stock.originalEvent.target.parentNode.classList.remove("is-active")
      event.originalEvent.target.parentNode.classList.add("is-active");
      this.stock = event;
    }else {
      event.originalEvent.target.parentNode.classList.add("is-active");
      this.stock = event;
    }
  }


  /**
   * Les elements de la navigation pour la redirection à chaque section
   */
  navigationContextuelle() {

      let items : MenuItem[] = [];

      items.push({
          label: this.translate.getElement('DOSSIER.DEMANDE.CREATION.DEMANDEUR'),
          command : event => {
            this.addIsactiveNavigationContextuelleMenuItem(event, 'demandeur');
          }
        },
        {
          label: this.translate.getElement('DOSSIER.DEMANDE.CREATION.AFFAIRE'),
          command :(event) => {
            this.addIsactiveNavigationContextuelleMenuItem(event, 'client');
          }
        },
        {
          label: this.translate.getElement('DOSSIER.DEMANDE.CREATION.CONTACT'),
          command : event => {
            this.addIsactiveNavigationContextuelleMenuItem(event, 'contact');
          }
        },
        {label: this.translate.getElement('DOSSIER.DEMANDE.CREATION.BIEN'),
          command : event => {
            this.addIsactiveNavigationContextuelleMenuItem(event, 'bien')
          }
        }
      );

      if(this.dossier.infoExpertise?.expert) {
        items.push({label: this.translate.getElement('DOSSIER.EXPERT'),
          command : event => {
            this.addIsactiveNavigationContextuelleMenuItem(event, 'expert')
          }
        });
      }

      items.push(
        {label: this.translate.getElement('DOSSIER.HT'),
          command : event => {
            this.addIsactiveNavigationContextuelleMenuItem(event, 'honoraire');
          }
        },
        {label: this.translate.getElement('DOSSIER.RESULTAT_EXP'),
          command : event => {

            this.addIsactiveNavigationContextuelleMenuItem(event, 'resultatExp');
          }
        },
        {
          label: this.translate.getElement('DOSSIER.DEMANDE.CREATION.DOCUMENT'),
          command : event => {
            this.addIsactiveNavigationContextuelleMenuItem(event, 'document');
          }
        },
        {
          label: this.translate.getElement('DOSSIER.OBSERVATION'),
          visible : !this.isProfilExterne(),
          command : event => {
            this.addIsactiveNavigationContextuelleMenuItem(event, 'commentaire');
          }
        }
      );

      this.anchorItem.push({
          label: this.translate.getElement('DOSSIER.DEMANDE.CREATION.SECTION'),
          items: items
      });
  }

  /**
   * construction des actions utilisateurs sur le dossier
   * @param dossier
   */
  async navActionsItem(dossier: Dossier) {

    let conflit: boolean = dossier.bien?.enConflit;
    let itemRepasserAAffecter: MenuItem = {
      label: this.translate.getElement('DOSSIER.GESTIONNAIRE.ACTION.REPASSER_AFFECTER'),
      icon: 'inc-icon inc-icon-flag is-small',
      visible: !conflit,
      command: event => {
        this.confirmation.confirm({
          message: this.translate.getElement("DOSSIER.GESTIONNAIRE.MESSAGE.ACTION_CONFIRMATION"),
          header: this.translate.getElement('DOSSIER.GESTIONNAIRE.ACTION.REPASSER_AFFECTER'),
          icon: 'pi pi-exclamation-triangle',
          accept: () => {
            this.repasserAAffecter(dossier);
          }
        })
      }
    };
    let itemAffecter: MenuItem = {
      label: this.translate.getElement('DOSSIER.GESTIONNAIRE.ACTION.AFFECTER_EXpert'),
      icon: 'inc-icon inc-icon-bell is-small',
      visible: !conflit,
      command: event => {
        this.affecter(dossier);
      }
    };
    let itemRelancer: MenuItem = {
      label: this.translate.getElement('DOSSIER.GESTIONNAIRE.ACTION.RELANCER'),
      icon: 'inc-icon inc-icon-arrow is-small',
      visible: !conflit,
      command: event => {
        this.confirmation.confirm({
          message: this.translate.getElement("DOSSIER.GESTIONNAIRE.MESSAGE.ACTION_CONFIRMATION"),
          header: this.translate.getElement('DOSSIER.GESTIONNAIRE.ACTION.RELANCER'),
          icon: 'pi pi-exclamation-triangle',
          accept: () => {
            this.relancer(dossier);
          }
        })
      }
    };
    let itemDupliquer: MenuItem = {
      label: this.translate.getElement('DOSSIER.GESTIONNAIRE.ACTION.DUPLIQUER'),
      icon: 'pi pi-copy',
      visible: !conflit,
      command: event => {
        this.ref = this.dialogService.open(DuplicationDossierComponent, {
          header: this.translate.getElement('DOSSIER.DUPLIQUER_DOSSIER',  dossier.numeroDossier),
          width: '30vw',
          height: '84%',
          baseZIndex: 10000,
          data : { dossier : dossier, user : this.userConnecte},
          styleClass : 'p-dynamic-dialog'
        });

        this.ref.onClose.subscribe((data : any) =>{
          if (data) {
              this.valueTypeLivrable = data.valueTypeLivrable;
              let idAgence : number = 0;
              if(data.agent != null) {
                idAgence = data.agent?.id;
              }
              this.dupliquer(data.valueTypeLivrable, idAgence);
          }
        });
      }
    };

    let itemSauvegrader: MenuItem = {
      label: 'Sauvegarder',
      icon: 'pi pi-save',
      styleClass: 'primary-link',
      command: event => {
        this.sauvegarder();
      }
    };

    let itemModifierLivrable: MenuItem = {
      label: this.translate.getElement('DOSSIER.GESTIONNAIRE.ACTION.MODIFIER_LIVRABLE'),
      icon: 'inc-icon inc-icon-edit is-small',
      visible: !conflit,
      command: event => {
        this.confirmation.confirm({
          message: this.translate.getElement("DOSSIER.GESTIONNAIRE.MESSAGE.ACTION_CONFIRMATION"),
          header: this.translate.getElement('DOSSIER.GESTIONNAIRE.ACTION.MODIFIER_LIVRABLE'),
          icon: 'pi pi-exclamation-triangle',
          accept: () => {
            //Action à effectuer
            if (dossier.infoExpertise?.typeLivrable?.code === 'TVAVISDOSSI') {
              this.router.navigateByUrl('/avis-valeur/edition/' + dossier.idDossier);
            } else if (dossier.infoExpertise?.typeLivrable?.code === 'TVRAPPORTXX') {
              this.router.navigateByUrl('/rapport-expertise/edition/' + dossier.idDossier);
            }
          }
        })
      }
    };
    let itemAnnuler: MenuItem = {
      label: this.translate.getElement('DOSSIER.GESTIONNAIRE.ACTION.ANNULER'),
      icon: 'inc-icon inc-icon-close is-small',
      command: event => {
        this.confirmation.confirm({
          message: this.translate.getElement("DOSSIER.GESTIONNAIRE.MESSAGE.ACTION_CONFIRMATION"),
          header: this.translate.getElement('DOSSIER.GESTIONNAIRE.ACTION.ANNULER'),
          icon: 'pi pi-exclamation-triangle',
          accept: () => {
            this.annuler(dossier);
          }
        })
      }
    };
    let itemReactiver: MenuItem =  {
        label: this.translate.getElement('DOSSIER.GESTIONNAIRE.ACTION.REACTIVER'),
        icon: 'inc-icon inc-icon-load is-small',
        command : event => {
          this.confirmation.confirm({
            message: this.translate.getElement("DOSSIER.GESTIONNAIRE.MESSAGE.ACTION_CONFIRMATION"),
            header : this.translate.getElement('DOSSIER.GESTIONNAIRE.ACTION.REACTIVER'),
            icon: 'pi pi-exclamation-triangle',
            accept: () => {
              this.reactiver(dossier);
            }
          })
        }
      };
    let itemRejeterLivrable: MenuItem =  {
        label: this.translate.getElement('DOSSIER.GESTIONNAIRE.ACTION.REJETER'),
        icon: 'inc-icon inc-icon-close is-small',
        visible : !conflit,
        styleClass : conflit ? 'en_conflit' : '',
        command : event => {
          if(!dossier.synthese) {
            this.confirmation.confirm({
              message: this.translate.getElement("DOSSIER.GESTIONNAIRE.MESSAGE.ACTION_CONFIRMATION"),
              header : this.translate.getElement('DOSSIER.GESTIONNAIRE.ACTION.REJETER'),
              icon: 'pi pi-exclamation-triangle',
              accept: () => {
                dossier.etatEnvoi = ConfigConstants.ETAT_ENVOI_INIT;
                this.rejeterLivrable(dossier);
              }
            });
          }else {
            this.ref = this.dialogService.open(RejetLivrableComponent, {
              header: this.translate.getElement('DOSSIER.GESTIONNAIRE.ACTION.REJETER'),
              width: '30vw',
              baseZIndex: 10000,
              data : dossier,
              styleClass : 'p-dynamic-dialog'
            });


            this.ref.onClose.subscribe((data : any) =>{
              if (data && (data === ConfigConstants.ETAT_ENVOI_INIT || data === ConfigConstants.ETAT_ENVOI_SYNTHESE)) {
                  let dossier = this.dossier;
                  dossier.etatEnvoi = data;
                  this.rejeterLivrable(dossier);
              }
            });
          }
        }
      };
      let itemValiderLivrable: MenuItem = {
        label: this.translate.getElement('DOSSIER.GESTIONNAIRE.ACTION.VALIDER'),
        icon: 'inc-icon inc-icon-check is-small',
        visible : !conflit,
        styleClass : conflit ? 'en_conflit' : '',
        command : event => {
          this.ref = this.dialogService.open(ValidationLivrableComponent, {
            header: this.translate.getElement('DOSSIER.GESTIONNAIRE.ACTION.VALIDER'),
            width: '40vw',
            baseZIndex: 10000,
            data : {userConnected : this.userConnecte},
            styleClass : 'p-dynamic-dialog'
          });

        this.ref.onClose.subscribe((signataire: any) => {
          this.validerLivrable(dossier, signataire);
        });

      }
    };

    let itemEnvoyerProjet: MenuItem = {
      label: this.translate.getElement('DOSSIER.GESTIONNAIRE.ACTION.ENVOYER_PROJET'),
      icon: 'inc-icon inc-icon-file is-small',
      visible: !conflit,
      command: event => {

        this.confirmation.confirm({
          message: this.translate.getElement("DOSSIER.GESTIONNAIRE.MESSAGE.ACTION_CONFIRMATION"),
          header: this.translate.getElement('DOSSIER.GESTIONNAIRE.ACTION.ENVOYER_PROJET'),
          icon: 'pi pi-exclamation-triangle',
          accept: () => {
            this.envoyerProjet(dossier, this.guidLivrable);
          }
        })
      }
    };


    if (dossier.statut == null) {
      return [];
    }

    if (dossier.statut === StatutDossier.A_AFFECTER) {
      this.actionsItem = [{
        label: this.translate.getElement('DOSSIER.GESTIONNAIRE.ACTION.ACTION_TITLE'),
        items: [itemAffecter, itemDupliquer, itemAnnuler, itemSauvegrader]
      }];
      return this.actionsItem;
    }

    if (dossier.statut === StatutDossier.ENCOURS) {
      this.actionsItem = [{
        label: this.translate.getElement('DOSSIER.GESTIONNAIRE.ACTION.ACTION_TITLE'),
        items: [itemRepasserAAffecter, itemRelancer, itemDupliquer, itemAnnuler, itemSauvegrader]
      }];
      return this.actionsItem;
    }


    //l'utilisateur connecté doit être un signataire actif dans le paramétrage des signataires
    let controlAutorize: boolean | Signataire = (this.userSignataires && this.userSignataires.find(us => us.id === this.userConnecte.id && us.actif))
      || (this.userConnecte.profiles.includes(ProfilesUser.ADMINISTRATEUR) || this.userConnecte.profiles.includes(ProfilesUser.ADMINISTRATEUR_TECHNIQUE));

    if (dossier.statut === StatutDossier.D_ATTENTE_V1) {

      let items: any[] = [];

      if(this.userConnecte.id === dossier.validationVerrouilleur && dossier.dateValidationVerrouillage && controlAutorize) {
        if(dossier.etatEnvoi === ConfigConstants.ETAT_ENVOI_A_VALIDER || dossier.etatEnvoi === ConfigConstants.ETAT_ENVOI_PROJET){
          items = [itemDupliquer, itemModifierLivrable, itemRejeterLivrable, itemAnnuler, itemSauvegrader]
          if(this.existeDocAvisValeur || this.existDocRapport) {
            items.splice(3, 0, itemValiderLivrable);
          }
        }

        if(dossier?.tiers?.projetLivrable && dossier.etatEnvoi !== ConfigConstants.ETAT_ENVOI_PROJET && (this.existeDocAvisValeur || this.existDocRapport)) {
          items.splice(3, 0, itemEnvoyerProjet);
        }
      } else {
        items = [itemDupliquer,  itemModifierLivrable, itemRejeterLivrable, itemAnnuler, itemSauvegrader]
      }
      this.actionsItem = [{
        label: this.translate.getElement('DOSSIER.GESTIONNAIRE.ACTION.ACTION_TITLE'),
        items: items
      }];
      return this.actionsItem;
    }

    if (dossier.statut === StatutDossier.A_FACTURER) {
      this.actionsItem = [{
        label: this.translate.getElement('DOSSIER.GESTIONNAIRE.ACTION.ACTION_TITLE'),
        items: [itemDupliquer, itemModifierLivrable, itemRejeterLivrable, itemAnnuler, itemSauvegrader]
      }];
      return this.actionsItem;
    }

    if (dossier.statut === StatutDossier.FACTUREE) {
      this.actionsItem = [{
        label: this.translate.getElement('DOSSIER.GESTIONNAIRE.ACTION.ACTION_TITLE'),
        items: [itemDupliquer,  itemModifierLivrable, itemSauvegrader]
      }];
      return this.actionsItem;
    }

    if (dossier.statut === StatutDossier.F_ENCOURS) {
      this.actionsItem = [{
        label: this.translate.getElement('DOSSIER.GESTIONNAIRE.ACTION.ACTION_TITLE'),
        items: [itemDupliquer,  itemModifierLivrable, itemSauvegrader]
      }];
      return this.actionsItem;
    }

    if (dossier.statut === StatutDossier.ANNULE) {
      this.actionsItem = [{
        label: this.translate.getElement('DOSSIER.GESTIONNAIRE.ACTION.ACTION_TITLE'),
        items: [itemReactiver]
      }];
      return this.actionsItem;
    }

    return [];
  }


  /**
   * construction des Livrables utilisateurs sur le dossier
   * @param dossier
   */
  async navLivrablesItem(dossier: Dossier) {

    let conflit: boolean = dossier.bien?.enConflit;

    let itemtelecharger: MenuItem;
    //item Synthèse
    let itemSynthese: MenuItem = {
      label: this.translate.getElement('DOSSIER.GESTIONNAIRE.LIVRABLE.SYNTHESE'),
      icon: 'inc-icon inc-icon-eye is-small',
      visible: !conflit,
      command: event => {
        this.confirmation.confirm({
          message: this.translate.getElement("DOSSIER.GESTIONNAIRE.MESSAGE.ACTION_CONFIRMATION"),
          header: this.translate.getElement('DOSSIER.GESTIONNAIRE.LIVRABLE.SYNTHESE'),
          icon: 'pi pi-exclamation-triangle',
          accept: () => {
            this.telechargerLivrable(dossier, ConfigConstants.SYNTHESE);
          }
        })
      }
    };
    //items Rapport ou Avis de valeur
    if (this.dossier.infoExpertise?.typeLivrable?.code === LivrableDossier.TVRAPPORTXX) {
      itemtelecharger = {
        label: this.translate.getElement('DOSSIER.GESTIONNAIRE.LIVRABLE.RAPPORT'),
        icon: 'inc-icon inc-icon-eye is-small',
        visible: !conflit,
        command: event => {
          this.confirmation.confirm({
            message: this.translate.getElement("DOSSIER.GESTIONNAIRE.MESSAGE.ACTION_CONFIRMATION"),
            header: this.translate.getElement('DOSSIER.GESTIONNAIRE.LIVRABLE.RAPPORT'),
            icon: 'pi pi-exclamation-triangle',
            accept: () => {
              this.telechargerLivrable(dossier, ConfigConstants.RAPPORT_EXPERTISE);
            }
          })
        }
      };
    } else if (this.dossier.infoExpertise?.typeLivrable?.code === LivrableDossier.TVAVISDOSSI) {
      itemtelecharger = {
        label: this.translate.getElement('DOSSIER.GESTIONNAIRE.LIVRABLE.AVIS'),
        icon: 'inc-icon inc-icon-eye is-small',
        visible: !conflit,
        command: event => {
          this.confirmation.confirm({
            message: this.translate.getElement("DOSSIER.GESTIONNAIRE.MESSAGE.ACTION_CONFIRMATION"),
            header: this.translate.getElement('DOSSIER.GESTIONNAIRE.LIVRABLE.AVIS'),
            icon: 'pi pi-exclamation-triangle',
            accept: () => {
              this.telechargerLivrable(dossier, ConfigConstants.AVIS_VALEUR);
            }
          })
        }
      }
    }

    if (dossier.statut == null) {
      return [];
    }

    if (dossier.statut === StatutDossier.A_AFFECTER) {
      return [];
    }

    if (dossier.statut === StatutDossier.ENCOURS) {
      return [];
    }


    //l'utilisateur connecté doit être un signataire actif dans le paramétrage des signataires
    let controlAutorize: boolean | Signataire = (this.userSignataires && this.userSignataires.find(us => us.id === this.userConnecte.id && us.actif))
      || (this.userConnecte.profiles.includes(ProfilesUser.ADMINISTRATEUR) || this.userConnecte.profiles.includes(ProfilesUser.ADMINISTRATEUR_TECHNIQUE));

    if (dossier.statut === StatutDossier.D_ATTENTE_V1) {

      let itemsLivrable: any[] = [];

      if(this.userConnecte.id === dossier.validationVerrouilleur && dossier.dateValidationVerrouillage && controlAutorize) {
        if(dossier.etatEnvoi === ConfigConstants.ETAT_ENVOI_A_VALIDER || dossier.etatEnvoi === ConfigConstants.ETAT_ENVOI_PROJET){
          itemsLivrable.push(itemtelecharger);
        }
      } else {
        itemsLivrable.push(itemtelecharger);
      }
      if (this.dossier.infoExpertise?.typeLivrable?.code === LivrableDossier.TVRAPPORTXX) {
        itemsLivrable.push(itemSynthese);
      }
      this.livrableItem = [{
        label: this.translate.getElement('DOSSIER.GESTIONNAIRE.LIVRABLE.LIVRABLE_TITLE'),
        items: itemsLivrable
      }];
      return this.livrableItem;
    }

    if (dossier.statut === StatutDossier.A_FACTURER) {
      let itemsLivrable: any[] = [itemtelecharger];
      if (this.dossier.infoExpertise?.typeLivrable?.code === LivrableDossier.TVRAPPORTXX) {
        itemsLivrable.push(itemSynthese);
      }
      this.livrableItem = [{
        label: this.translate.getElement('DOSSIER.GESTIONNAIRE.LIVRABLE.LIVRABLE_TITLE'),
        items: itemsLivrable
      }];
      return this.livrableItem;
    }

    if (dossier.statut === StatutDossier.FACTUREE) {
      let itemsLivrable: any[] = [itemtelecharger];
      if (this.dossier.infoExpertise?.typeLivrable?.code === LivrableDossier.TVRAPPORTXX) {
        itemsLivrable.push(itemSynthese);
      }
      this.livrableItem = [{
        label: this.translate.getElement('DOSSIER.GESTIONNAIRE.LIVRABLE.LIVRABLE_TITLE'),
        items: itemsLivrable
      }];
      return this.livrableItem;
    }

    if (dossier.statut === StatutDossier.F_ENCOURS) {
      let itemsLivrable: any[] = [itemtelecharger];
      if (this.dossier.infoExpertise?.typeLivrable?.code === LivrableDossier.TVRAPPORTXX) {
        itemsLivrable.push(itemSynthese);
      }
      this.livrableItem = [{
        label: this.translate.getElement('DOSSIER.GESTIONNAIRE.LIVRABLE.LIVRABLE_TITLE'),
        items: itemsLivrable
      }];
      return this.livrableItem;
    }

    if (dossier.statut === StatutDossier.ANNULE) {
      return [];
    }

    return [];
  }
  reloadCurrentRoute() {
    let currentUrl = this.router.url;
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
      this.router.navigate([currentUrl]);
    });
  }

  repasserAAffecter(dossier: Dossier) {
    this.spinnerService.show();
    this.dossierService.repasserAAffecter(dossier).subscribe( response => {
      this.spinnerService.hide();
      this.messageStorage.saveMessage('DOSSIER.AFFECTATION.MESSAGE.SUCCES_REPASSER_AFFECTER', dossier.numeroDossier.toString() );
      this.reloadCurrentRoute();
    }, error => {
      this.message = [
        {severity:'error', summary:this.translate.getElement('DOSSIER.AFFECTATION.MESSAGE.ERROR_REPASSER_AFFECTER',  dossier.numeroDossier.toString())}
      ];
      this.spinnerService.hide();
      this.scrollToTop();
    })
  }


  telechargerLivrable(dossier : Dossier, type : string) {

    this.documentService.downloadDocumentByType(dossier.bien.idBien, type).subscribe( {
      next : (response: HttpResponse<ArrayBuffer>) => {
        if (response != null && response?.body != null) {
          const fileName = FicheDossierComponent.getFilenameFromResponse(response, type, dossier?.numeroDossier);
          const blob = new Blob([response.body], { type: 'application/pdf' });
          saveAs(blob, fileName)
        }else {
          this.message = [ {severity:'error', summary :  this.translate.getElement("DOCUMENT.TELECHARGEMENT.ERROR")}];
          this.scrollToTop();
        }
      },
      error : () => {
        this.message = [ {severity:'error', summary :  this.translate.getElement("DOCUMENT.TELECHARGEMENT.ERROR")}];
        this.scrollToTop();
      }
    });

  }

  dupliquer(codeLivrable : string, idAgence : number) {
    this.spinnerService.show();
    this.dossierService.duplicationDossier(this.dossier.idDossier, codeLivrable, idAgence).subscribe(response => {
      if(response) {
        const message = this.translate.getElement('DOSSIER.GESTIONNAIRE.MESSAGE.CLICK_OUVRIR');
        this.messageStorage.saveMessage('DOSSIER.GESTIONNAIRE.MESSAGE.SUCESS_DUPLICATION_DOSSIER',
          response.numeroDossier.toString(),
          "<a class='messageLink' href='/dossier/fiche/" + response.idDossier +  " '>" + message + "</a>"
        )
        this.reloadCurrentRoute();
        this.spinnerService.hide();
      }else {
        this.message = [
          {severity:'error', summary:this.translate.getElement('DOSSIER.GESTIONNAIRE.MESSAGE.ERROR_DUPLICATION_DOSSIER', this.dossier.numeroDossier)}
        ];
        this.spinnerService.hide();
      }

    }, error => {
      this.message = [
        {severity:'error', summary:this.translate.getElement('DOSSIER.GESTIONNAIRE.MESSAGE.ERROR_DUPLICATION_DOSSIER', this.dossier.numeroDossier)}
      ];
      this.spinnerService.hide();
      this.scrollToTop();
    })
  }

  /**
   * recuperer le nom du fichier à partir de l'entete du retour serveur back
   * @param response
   * @private
   */
  private static getFilenameFromResponse(response: HttpResponse<ArrayBuffer>, typeDoc? : string, numberDossier ?: string): string {
    const contentDispositionHeader = response.headers.get('Content-Disposition');
    if (contentDispositionHeader) {
      const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
      const matches = filenameRegex.exec(contentDispositionHeader);
      if (matches != null && matches[1]) {
        return decodeURIComponent(matches[1].trim().replace(/['"]/g, ''));
      }
    }
    return typeDoc + numberDossier + ".pdf";
  }


  affecter(dossier: Dossier) {

    this.ref = this.dialogService.open(AffectationRechercheAvanceeExpertComponent, {
      header: this.translate.getElement('DOSSIER.AFFECTATION.HEADER.AFFECTER'),
      width: '400px',
      baseZIndex: 10000,
      data : 'RECHERCHE_AVANCEE_STATUT_ACTION',
      styleClass : 'p-dynamic-dialog'
    });

    this.ref.onClose.subscribe((data : any) =>{
      this.spinnerService.show();
      if (data) {
        const expert = data.expert;
        const assistante = data.assistante;
        if(dossier && expert.id && assistante.id) {
          let idDossiers : number[] = [dossier.idDossier];
          this.dossierService.affectationDossier(idDossiers,  expert.id, assistante.id).subscribe(data => {
            if(data) {
              this.spinnerService.hide();
              this.messageStorage.saveMessage('DOSSIER.AFFECTATION.MESSAGE.AFFECT', expert.nomComplet, assistante.nomComplet);
              this.reloadCurrentRoute();
            }
          }, error => {
            this.message = [
              {severity:'error', summary:this.translate.getElement('DOSSIER.AFFECTATION.MESSAGE.NOTAFFECT')}
            ];
            this.spinnerService.hide();
            this.scrollToTop();
          });
        }
      }else {
        this.spinnerService.hide();
      }
    });
  }

  relancer(dossier: Dossier) {
    this.spinnerService.show();
    this.dossierService.relancerDossier(dossier.idDossier).subscribe(data => {
      if (data) {
        this.spinnerService.hide();
        this.messageStorage.saveMessage('DOSSIER.AFFECTATION.MESSAGE.RELANCE.SUCCES', dossier.numeroDossier);
        this.reloadCurrentRoute();
      } else {
        this.message = [
          {severity:'error', summary:this.translate.getElement('DOSSIER.AFFECTATION.MESSAGE.RELANCE.ERREUR', dossier.numeroDossier ) }
        ];
        this.spinnerService.hide();
        this.scrollToTop();
      }
    }, error => {
      this.message = [
        {severity:'error', summary:this.translate.getElement('DOSSIER.AFFECTATION.MESSAGE.RELANCE.ERREUR', dossier.numeroDossier ) }
      ];
      this.spinnerService.hide();
      this.scrollToTop();
    })

  }

  redirectionFicheDossier(idDossier : number) {
    this.router.navigateByUrl('/dossier/fiche/' + idDossier)
  }

  annuler(dossier: Dossier) {
    this.spinnerService.show();
    this.dossierService.annulationDossier(dossier).subscribe(response => {
      this.spinnerService.hide();
      this.messageStorage.saveMessage('DOSSIER.GESTIONNAIRE.MESSAGE.SUCESS_ANNULATION_DOSSIER', dossier.numeroDossier.toString())
      this.reloadCurrentRoute();
    }, error => {
      this.message = [
        {severity:'error', summary:this.translate.getElement('DOSSIER.GESTIONNAIRE.MESSAGE.ERROR_ANNULATION_DOSSIER', dossier.numeroDossier.toString())}
      ];
      this.spinnerService.hide();
      this.scrollToTop();
    })
  }

  reactiver(dossier: Dossier) {
    this.spinnerService.show();
    this.dossierService.reactivationDossier(dossier).subscribe(response => {
      this.spinnerService.hide();
      this.messageStorage.saveMessage('DOSSIER.GESTIONNAIRE.MESSAGE.SUCESS_REACTIVATION_DOSSIER', dossier.numeroDossier.toString())
      this.reloadCurrentRoute();
    }, error => {
      this.message = [
        {severity:'error', summary:this.translate.getElement('DOSSIER.GESTIONNAIRE.MESSAGE.ERROR_REACTIVATION_DOSSIER', dossier.numeroDossier.toString())}
      ];
      this.spinnerService.hide();
      this.scrollToTop();
    })
  }


  /**
   * retourne la liste des étages pour les adresses du bien
   */
  getListEtage() {
    this.bienService.getAllEtageBienImmeubleByAdressPrincipal().subscribe(data => {
      const def : ReferentielValeur = new ReferentielValeur();
      def.libelle = 'choisir'
      this.etages.push(def);
      if(data) {
        data.forEach(dt => this.etages.push(dt));
      }
    });
  }

  getListTypeBien() {
    this.bienService.getAllParamTypeBien().subscribe(data => {
      this.typeBiens = data;
    });
  }

  /**
   * La liste des civilité
   */
  getcivilites() {
    this.dossierService.findAllCivilite().subscribe( data => {
      this.civilites = data;
    })
  }

  /**
   * Envoyer un projet
   * @param dossier
   */
  envoyerProjet(dossier: Dossier, guidDocument : string) {
    this.spinnerService.show();
    this.dossierService.envoyerProjet(dossier, guidDocument).subscribe(data => {
      if (data) {
        this.spinnerService.hide();
        this.messageStorage.saveMessage("DOSSIER.GESTIONNAIRE.MESSAGE.SUCCCESS_ENVOYER_PROJET", dossier.numeroDossier.toString());
        this.reloadCurrentRoute();
      } else {
        this.spinnerService.hide();
        this.message = [
          {severity:'error', summary:this.translate.getElement("DOSSIER.GESTIONNAIRE.MESSAGE.ERROR_ENVOYER_PROJET", dossier.numeroDossier.toString()) }
        ];
        this.scrollToTop();
      }
    }, error => {
      this.spinnerService.hide();
      this.message = [
        {severity:'error', summary:this.translate.getElement("DOSSIER.GESTIONNAIRE.MESSAGE.ERROR_ENVOYER_PROJET", dossier.numeroDossier.toString()) }
      ];
      this.scrollToTop();
    });
  }

}
