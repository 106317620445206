import {ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {AbstractControl, FormBuilder, FormGroup, FormGroupDirective} from "@angular/forms";
import {AdresseComponent} from "../../../../shared/adresse/adresse.component";
import {DialogService, DynamicDialogRef} from "primeng/dynamicdialog";
import {User} from "../../../models/user/user.model";
import {Civilite} from "../../../models/param/civilite";
import {CreationDemandeObservableService} from "../../../../shared/observable/creation-demande-observable.service";
import {ConfigSizeInput} from "../../../../shared/providers/config-size-input";

@Component({
  selector: 'app-client',
  templateUrl: './client.component.html',
  styleUrls: ['./client.component.scss']
})
export class ClientComponent implements OnInit {

  @Input()
  formGroupName: string;

  @Input()
  user : User;

  form: FormGroup;

  preteur : any[];

  fraisExp : any[]

  @Input()
  submitted : boolean;
  @Input()
  civilites: Civilite[];

  clientSubmit : boolean = false;

  defaultTypeClient : string;

  @Input()
  titre : string;

  configSizeInput : ConfigSizeInput;

  constructor(
    private fb : FormBuilder,
    public dialogService: DialogService,
    public ref: DynamicDialogRef,
    private rootFormGroup: FormGroupDirective,
    private creationDemandeObservable : CreationDemandeObservableService,
    private cdref: ChangeDetectorRef,
  ) {
    this.configSizeInput = new ConfigSizeInput();
  }

  ngOnInit(): void {

    this.fraisExp = [
      {name : 'Irrécupérable', value : 1},
      {name :'Récuperable' , value : 0}
    ];

    this.form = this.rootFormGroup.control.get(this.formGroupName) as FormGroup;

    this.initFrais();

    this.defaultTypeClient = this.form.value.customerType;

    this.choiceTypeClient();

  }

  ngAfterContentChecked() {
    this.cdref.detectChanges();
  }

  get f(): { [key: string]: AbstractControl } {
    return this.form.controls;
  }

  addAdress() {

    this.ref = this.dialogService.open(AdresseComponent, {
      header: "Adresse",
      width: '50vw',
      baseZIndex: 10000,
      data : {contry : 'allContry', adresse : this.form.value.adresse},
      dismissableMask: true,
      styleClass : 'p-dynamic-dialog'
    });

    this.ref.onClose.subscribe((adress : any) =>{
      if (adress) {

        this.form.get('adresse.adresseFormate').setValue(adress.adresseFormate);

        if (adress?.numero === undefined) {
          adress.numero = null;
        }
        this.form.get('adresse.numero').setValue(adress?.numero);
        this.form.get('adresse.lieuDit').setValue(adress.lieuDit);
        this.form.get('adresse.nomVoie').setValue(adress.nomVoie);
        this.form.get('adresse.codePostal').setValue(adress.codePostal);
        this.form.get('adresse.ville').setValue(adress.ville);
        this.form.get('adresse.pays').setValue(adress.pays);
        this.form.get('adresse.boitePostale').setValue(adress.boitePostale);
        this.form.get('adresse.complement').setValue(adress.complement);
        this.form.get('adresse.lng').setValue(adress.lng);
        this.form.get('adresse.lat').setValue(adress.lat);
        this.form.get('adresse.adresseChange').setValue(adress.adresseChange);

        this.creationDemandeObservable.sendClientAdressFormat(adress);
      }
    });
  }

  choiceTypeClient() {
    this.form.valueChanges.subscribe((formValue) => {
      if(this.form.value?.customerType && this.defaultTypeClient !== this.form.value.customerType) {
        this.defaultTypeClient = this.form.value.customerType;
        const type = this.form.value.customerType;
        this.form.reset();
        this.form.get('customerType').setValue(type);
        this.initFrais();
      }
    });
  }

  initFrais() {
    if (this.user?.agence?.entiteClient === 'DRE IMMO AFFAIRES SPECIALES') {
      this.form.get('fraisExp').setValue({name : 'Irrécupérable', value : 1});
    }
  }
}
