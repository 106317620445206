import {ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {LocalStorageService} from "../../../service/local-storage/local-storage.service";
import {User} from "../../../models/user/user.model";
import {ProfilesUser} from "../../../models/user/profiles-user";
import {FormBuilder, FormGroup, FormGroupDirective} from "@angular/forms";
import {DialogService, DynamicDialogRef} from "primeng/dynamicdialog";
import {Dossier} from "../../../models/dossiers/dossier";
import {ConfigConstants} from "../../../models/enum/config-constants";
import {PourcentageNegociation} from "../../../models/param/pourcentage-negociation";
import {InfoExpertise} from "../../../models/dossiers/info-expertise";
import {UtilsFonction} from "../../../../shared/providers/utils-fonction";

@Component({
  selector: 'app-honoraires',
  templateUrl: './honoraires.component.html',
  styleUrls: ['./honoraires.component.scss']
})
export class HonorairesComponent implements OnInit {

  @Input()
  formGroupName: string;

  @Input()
  submitted : boolean;

  @Input()
  dossier : Dossier;

  @Input()
  pourcentageNeg : PourcentageNegociation[];

  @Input()
  userConnecte : User;

  @Input()
  expertInterneUser : User[];

  form: FormGroup;

  infoExpertise : InfoExpertise;

  totalRefacturables : boolean;
  pourcentNegociationMouse : boolean;
  negociation : boolean;
  pilotage : boolean;
  fraisExpert : boolean;
  calculKilometrage : boolean;

  kiloMettrage : string;
  noValue : string = 'N/A';

  montantNegociation : number = 0;

  totalRefacturable : number = 0;

  mtPilotage : number = 0;
  totalExpert : number = 0;

  pilotageError : boolean;

  filteredExpert: any[];
  filteredItems: any[];
  expertInterneModif : boolean;

  constructor(
    private userStorage : LocalStorageService,
    private fb : FormBuilder,
    public dialogService: DialogService,
    public ref: DynamicDialogRef,
    private rootFormGroup: FormGroupDirective,
    private cdref: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.form = this.rootFormGroup.control.get(this.formGroupName) as FormGroup;

    this.calculTotalRefacturable();

    this.infoExpertise = this.dossier?.infoExpertise;

    if(this.infoExpertise?.expert) {
        this.calculHTExpert();
        this.calculkilometrage();
    }else {
      const fraisFournisseur =  this.getFraisFournisseur(false, ConfigConstants.TYPE_FRAIS_EXPERTISE, ConfigConstants.TYPE_FOURNISSEUR_EXPERT_RESEAU);
      if(fraisFournisseur != null) {
        this.form.get('honoExpertMontantHT').setValue(fraisFournisseur);
      }else {
        this.form.get('honoExpertMontantHT').setValue(0);
      }
    }

    this.expertInterneModif = this.userConnecte && (this.userConnecte.profiles.includes(ProfilesUser.ASSISTANTE) || this.userConnecte.profiles.includes(ProfilesUser.ANIMATEUR_RESEAU)
    || this.userConnecte.profiles.includes(ProfilesUser.ADMINISTRATEUR) || this.userConnecte.profiles.includes(ProfilesUser.ADMINISTRATEUR_TECHNIQUE));

  }


  calculTotalRefacturable() {
    const honorairesClient = this.form.get('honoraireClient').value;
    const fraisRefact = this.form.get('fraisRefacturable').value;
    this.totalRefacturable = honorairesClient + fraisRefact;
  }


  calculMtNeg() {

    const honoraireClient = this.form.get('honoraireClient').value;
    const negPourcentage = this.form.get('negociation').value.libelle;

    if(this.infoExpertise) {

      if(this.infoExpertise?.profilsExpert?.includes(ProfilesUser.EXPERT_EXTERNE)) {
        let totalExpert = this.form.get('honoExpertMontantHT').value + this.form.get('fraisExpertMontantHt').value;
        this.montantNegociation = ((honoraireClient - totalExpert) * negPourcentage ) / 100;
      }else if(this.infoExpertise?.profilsExpert?.includes(ProfilesUser.EXPERT_INTERNE)) {
        let fraisNonRefacturable = 0;
        this.dossier.fraisFournisseurs.filter(fr => !fr.refacturable).map( fr => {
          fraisNonRefacturable += fr.montantHT;
        });
        this.montantNegociation = UtilsFonction.calculMontantNegociation(honoraireClient, fraisNonRefacturable,negPourcentage );
      }
    }

  }

  calculMtPilotage() {

    const honoraireClient = this.form.get('honoraireClient').value;
    const pilotage = this.form.get('pilotage').value;

    let mtNeg =  this.montantNegociation;

    if(this.infoExpertise) {
      if(this.infoExpertise?.profilsExpert?.includes(ProfilesUser.EXPERT_EXTERNE)) {
        this.mtPilotage = ((honoraireClient - this.totalExpert - mtNeg) * pilotage) / 100;
      }if(this.infoExpertise?.profilsExpert?.includes(ProfilesUser.EXPERT_INTERNE)) {
       // this.mtPilotage = ((honoraireClient  - mtNeg) * pilotage ) / 100;
        let fraisNonRefacturable = 0;
        this.dossier.fraisFournisseurs.filter(fr => !fr.refacturable).map( fr => {
          fraisNonRefacturable += fr.montantHT;
        });
        this.mtPilotage =UtilsFonction.calculMontantPilotage(honoraireClient, fraisNonRefacturable, this.montantNegociation,  pilotage);
      }
    }

  }

  calculTotalExpert() {
    const honoExpert = this.form.get('honoExpertMontantHT').value;
    const fraisExpert = this.form.get('fraisExpertMontantHt').value;
    this.totalExpert = honoExpert + fraisExpert;
  }

  calculkilometrage() {

      this.kiloMettrage = "https://maps.google.fr/maps?f=d&hl=fr&saddr=" +
        this.dossier?.bien?.adresse?.ville.replace(' ', '+') + "+"
        + this.dossier?.bien?.adresse?.codePostal.replace(' ', '+') +
        "&daddr=" +  this.infoExpertise?.adressExpert?.ville.replace(' ', '+') +  "+"
        +this.infoExpertise?.adressExpert?.codePostal.replace(' ', '+') + "&ie=UTF8&z=8&om=1";
  }

  ngAfterContentChecked() {
    this.cdref.detectChanges();
  }


  onChangeHonoClient(event : any, fieldChange? : string) {

    if(fieldChange === 'honoraireClient'){
      this.form.get('honoraireClient').setValue(event.value)
    }
    if(fieldChange === 'fraisRefacturable') {
      this.form.get('fraisRefacturable').setValue(event.value)
    }

    this.calculTotalRefacturable();

    this.calculMtNeg();

    this.calculMtPilotage();

    this.calculHonoExpert();

    this.calculTotalExpert();
  }

  onChangePilotage(event : any) {
    if (event.value >= 0 && event.value <= 100) {
      this.pilotageError = false;
      this.form.get('pilotage').setValue(event.value);
      this.calculMtPilotage();

      if(this.infoExpertise && this.infoExpertise?.profilsExpert?.includes(ProfilesUser.EXPERT_INTERNE)) {
        this.calculHonoExpert();
        this.calculTotalExpert();
      }
    }else {
      this.pilotageError = true;
    }

  }

  onChangeNegociation(event : any) {

    this.form.get('negociation').setValue(event.value);



    this.form.controls['pilotage'].enable();

    this.calculMtNeg();
    this.calculMtPilotage();
    this.calculHonoExpert();
    this.calculTotalExpert();

    if(this.infoExpertise && this.infoExpertise?.profilsExpert?.includes(ProfilesUser.EXPERT_EXTERNE)) {
      this.form.controls['pilotage'].disable();
    }



  }

  /**
   * honoraires expert
   */
  calculHonoExpert() {
    let honoExpert;
    if(this.infoExpertise?.profilsExpert?.includes(ProfilesUser.EXPERT_INTERNE)) {
      let fraisNonRefacturable = 0;
      this.dossier.fraisFournisseurs.filter(fr => !fr.refacturable).map( fr => {
        fraisNonRefacturable += fr.montantHT;
      });
      honoExpert = UtilsFonction.calculHonoraireExpertInterne(this.form.get('honoraireClient').value, fraisNonRefacturable, this.montantNegociation,this.mtPilotage);
    }else  if(this.infoExpertise?.profilsExpert?.includes(ProfilesUser.EXPERT_EXTERNE)) {
      honoExpert = this.getFraisFournisseur(false, ConfigConstants.TYPE_FRAIS_EXPERTISE, ConfigConstants.TYPE_FOURNISSEUR_EXPERT_RESEAU);
    }

    if(!honoExpert) {
      honoExpert = 0;
    }
    this.form.get('honoExpertMontantHT').setValue(honoExpert);
  }

  /**
   * retourne les frais fournissuers
   * @param refacturable
   * @param codeTypeFrais
   * @param codeTypeFournisseur
   */
  getFraisFournisseur(refacturable : boolean, codeTypeFrais : string, codeTypeFournisseur : string) {
    let montantHT = 0;
    if(this.dossier.fraisFournisseurs.length > 0) {
      this.dossier.fraisFournisseurs.map(fr => {
        if(fr.refacturable === refacturable && fr.typesFrais.code === codeTypeFrais && fr.typeFournisseur.code === codeTypeFournisseur) {
          montantHT = fr.montantHT;
        }
      })
    }

    return montantHT;
  }


  changeFraixExpert(event : any) {
    this.form.get('fraisExpertMontantHt').setValue(event.value);
    this.calculTotalExpert();
   /* this.calculMtNeg();
    this.calculMtPilotage();
    this.calculHonoExpert()*/
  }

  changeHonoraireExpert(event : any) {
    this.form.get('honoExpertMontantHT').setValue(event.value);
    this.calculTotalExpert();
    this.calculMtNeg();
    this.calculMtPilotage();
  }

  calculHTExpert() {

    if(this.infoExpertise?.profilsExpert.includes(ProfilesUser.EXPERT_EXTERNE)) {
      this.calculHonoExpert();
      this.calculTotalExpert();
      this.calculMtNeg();
      this.calculMtPilotage();
    }

    if(this.infoExpertise?.profilsExpert.includes(ProfilesUser.EXPERT_INTERNE)) {
      this.calculMtNeg();
      this.calculMtPilotage();
      this.calculHonoExpert();
      this.calculTotalExpert();
    }
  }

  /**
   * auto completion utilisateur expert
   * @param event
   */
  searchExpertUser(event : any) {

    let filtered : any[] = [];
    let query = event.query;
    if(this.expertInterneUser.length > 0) {
      this.expertInterneUser.forEach(expert => {
        if (expert.nom.toLowerCase().indexOf(query.toLowerCase()) === 0 ||
          expert.prenom.toLowerCase().indexOf(query.toLowerCase()) === 0) {
          expert['nomComplet'] = expert.prenom + ' ' + expert.nom;
          filtered.push(expert);
        }
      })
      this.filteredExpert = filtered;
    }
  }

}
