import {Component, Input, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {DialogService, DynamicDialogRef} from "primeng/dynamicdialog";
import {TranslateService} from "../translate/translate.service";
import {Message} from "primeng/api";
import {UtilsFonction} from "../providers/utils-fonction";

@Component({
  selector: 'app-quick-serch',
  templateUrl: './quick-serch.component.html',
  styleUrls: ['./quick-serch.component.scss']
})
export class QuickSerchComponent implements OnInit {

  @Input() showContrat: boolean;
  @Input() showRechercheAvancee: boolean;
  @Input() lienRechercheRapide: String;
  @Input() rechercheAvanceeData: String;

  numeroDossier : String;
  numeroContrat : String;
  nomClient : String;

  ref: DynamicDialogRef;
  messageErrorCaractereNumDossier : Message[];

  constructor(private router:Router,   public dialogService: DialogService, private translate : TranslateService) { }

  ngOnInit(): void {  }

  rechercheRapide() {
    this.messageErrorCaractereNumDossier = [];
    if(this.numeroDossier != null && this.numeroDossier.trim() && this.numeroDossier.trim().length < 5) {
      this.messageErrorCaractereNumDossier = [ {severity:'error', summary: this.translate.getElement('DOSSIER.MESSAGE.ERROR.CARACTERE_NUMERO_DOSSIER', '5')}];
      return;
    }
    this.router.navigate([this.lienRechercheRapide],
      {queryParams: {numeroDossier: this.numeroDossier, numeroContrat: this.numeroContrat, nomClient: this.nomClient, typeRecherche : "rechercheRapide"}}
    );
  }


  showAdvanceSearch() {

    this.ref = UtilsFonction.rechercheAvancee(this.dialogService, this.translate);

    this.ref.onClose.subscribe((data : any) =>{

      //Une fois la modal fermée, supprimer la classe qui gère le scroll
      const body = document.getElementsByTagName('body')[0];
      body.classList.remove('p-dynamic-dialog-scrollable');

      if(data) {
        let params = data.params;

        if(params?.adresseBien) {
          const adress = params.adresseBien;
          params['codePostal'] = adress?.codePostal;
          params['ville'] = adress?.ville;
          params['nomVoie'] = adress?.nomVoie;
          params['numeroVoie'] = adress?.numero;
          params['complement'] = adress?.complement;
        }
        delete params['adresseBien'];
        this.router.navigate([data.url], {queryParams : params});
      }
    });
  }
}
