import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-dashboard-card',
  templateUrl: './dashboard-card.component.html',
  styleUrls: ['./dashboard-card.component.scss']
})
export class DashboardCardComponent implements OnInit {


  @Input() type: string;
  @Input() titre : string;
  @Input() sstitre : string;
  @Input() compteur : number;
  @Input() liens : any[] =[]
  @Input() icon : string;
  @Input() etatEnvoi : string;


  constructor() {}


  ngOnInit(): void {
  }

}
