import {ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {AbstractControl, FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {ConfirmationService, Message, MessageService} from "primeng/api";
import {AppSettings, Settings} from "../../../../../app.settings";
import {ConfigSizeInput} from "../../../../../shared/providers/config-size-input";
import {DialogService, DynamicDialogRef} from "primeng/dynamicdialog";
import {RapportExpertiseService} from "../../../../service/rapport-expertise.service";
import {TranslateService} from "../../../../../shared/translate/translate.service";
import {BienReference} from "../../../../models/bien/bien-reference";
import {ReferentielValeur} from "../../../../models/referentiel-valeur";

@Component({
  selector: 'app-table-reference-venales',
  templateUrl: './table-reference-venales.component.html',
  styleUrls: ['./table-reference-venales.component.scss']
})
export class TableReferenceVenalesComponent implements OnInit {


  @Input()
  idDossier : number

  message : Message[];
  messageDialog : Message[];
  submitted  : boolean;
  dialog : boolean;

  public settings: Settings;

  bienReferences : BienReference[];
  bienReference : BienReference;
  refVenaleForm : FormGroup;

  configSizeInput : ConfigSizeInput;

  typeBiens : ReferentielValeur[];

  constructor(
    private fb : FormBuilder,
    public dialogService: DialogService,
    public ref: DynamicDialogRef,
    private cdref: ChangeDetectorRef,
    private messageService : MessageService,
    private rapportExpertiseService : RapportExpertiseService,
    private confirmation: ConfirmationService,
    public appSettings : AppSettings,
    private translate : TranslateService,
  ) {
    this.settings = this.appSettings.settings;
    this.configSizeInput = new ConfigSizeInput();

  }

  ngOnInit(): void {
    this.getAllTypeBiens();
    this.getAllBienReferenceByDossierId();
  }

  async getAllBienReferenceByDossierId() {
   this.bienReferences =  await this.rapportExpertiseService.getAllBienReferenceByDossierId(this.idDossier);
   this.bienReferences.forEach( d=> {
       if (d.datetransac != null) {
         d.datetransac = new Date(d.datetransac);
       }
   })
  }

  initForm(bier? : BienReference) {

    this.refVenaleForm = this.fb.group({
      adresse : new FormControl(bier?.adresse ? bier?.adresse : null, [Validators.required, Validators.pattern(this.configSizeInput.regexPattern2)]),
      typeBien : new FormControl(bier?.typeBien ? bier?.typeBien : null, Validators.required),
      prixVente : new FormControl(bier?.prixvente ? bier?.prixvente :null, !bier?.prixm2 ? Validators.required: Validators.nullValidator),
      surface : new FormControl(bier?.surface ? bier?.surface :null),
      ratio : new FormControl(bier?.prixm2 ? bier?.prixm2 :null, !bier?.prixvente ? Validators.required: Validators.nullValidator),
      annexe  : new FormControl(bier?.annexe ? bier?.annexe :null,  Validators.pattern(this.configSizeInput.regexPattern2)),
      datetransac : new FormControl(bier?.datetransac ? bier?.datetransac :null, Validators.required),
      observations : new FormControl(bier?.observations ? bier?.observations :null, [
        Validators.max(64), Validators.pattern(this.configSizeInput.regexPattern2)
      ]),
    });

    this.requiredPrixVenteRatio();
  }

  edit(bier: BienReference) {
    this.messageDialog = null;
    this.bienReference = {...bier};
    this.initForm(this.bienReference);
    this.dialog = true;
  }

  delete(bier: BienReference) {
    if(bier) {
      this.confirmation.confirm({
        header: this.translate.getElement("RAPPORT_EXPERTISE.MESSAGE.ETES_VOUS_SUR"),
        message : this.translate.getElement("RAPPORT_EXPERTISE.MESSAGE.CONFIRMER"),
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          this.rapportExpertiseService.deleteBienReference(bier.id, bier.idDossier).then( data => {
            if(data) {
              this.getAllBienReferenceByDossierId();
              this.message =[ {severity:'success', summary:  this.translate.getElement("RAPPORT_EXPERTISE.MESSAGE.SUCESS_SUPPRESSION")}];
              return;
            }
            this.message =[ {severity:'error', summary:  this.translate.getElement("RAPPORT_EXPERTISE.MESSAGE.ERROR_SUPPRESSION")}];
          }).catch( error => {
            this.message =[ {severity:'error', summary:  this.translate.getElement("RAPPORT_EXPERTISE.MESSAGE.ERROR_SUPPRESSION")}];
          })
        }
      })

    }
  }

  openNew() {
    this.messageDialog = null;
    this.initForm(null);
    this.bienReference = null;
    this.submitted = false;
    this.dialog = true;
    const body = document.getElementsByTagName('body')[0];
    body.classList.add('p-dynamic-dialog-scrollable');
  }


  hideDialog() {
    this.dialog = false;
    this.submitted = false;
    //Une fois la modal fermée, supprimer la classe qui gère le scroll
    const body = document.getElementsByTagName('body')[0];
    body.classList.remove('p-dynamic-dialog-scrollable');
  }

  get formRefVenal(): { [key: string]: AbstractControl } {
    return this.refVenaleForm.controls;
  }

  save() {
    this.submitted = true;

    if(this.refVenaleForm.invalid) {
      this.messageDialog = [{severity:'error', summary: this.translate.getElement("RAPPORT_EXPERTISE.MESSAGE.ERROR_FORM")}];
      return;
    }

    const bier : BienReference = new BienReference();

    if(this.bienReference) {
      bier.id = this.bienReference?.id;
    }

    const value = this.refVenaleForm.value;

    bier.adresse = value.adresse;
    bier.typeBien = value.typeBien;
    bier.prixvente = value.prixVente;
    bier.surface = value.surface;
    bier.prixm2 = value.ratio;
    bier.annexe = value.annexe;
    bier.datetransac = value.datetransac;
    bier.observations = value.observations;
    bier.idDossier = this.idDossier;

    this.rapportExpertiseService.saveBieReference(bier).subscribe( data => {
      if(data) {
        this.hideDialog();
        this.getAllBienReferenceByDossierId();
        return;
      }
      this.messageDialog =[ {severity:'error', summary:  this.translate.getElement("RAPPORT_EXPERTISE.MESSAGE.ERROR_MESSAGE_MODAL")}];
      this.submitted = false;
    }, error => {
      this.messageDialog =[ {severity:'error', summary:  this.translate.getElement("RAPPORT_EXPERTISE.MESSAGE.ERROR_MESSAGE_MODAL")}];
      this.submitted = false;
    })
  }

  getAllTypeBiens()  {
    this.rapportExpertiseService.getAllTypeBien().subscribe(
      data => {
        this.typeBiens = data;
      },error => {

      }
    )
  }

  requiredPrixVenteRatio() {
     if(!this.refVenaleForm?.value?.ratio) {
      this.refVenaleForm?.controls['prixVente'].setValidators(Validators.required);
      this.refVenaleForm?.controls['ratio'].setErrors(null);
      return;
    }else if(!this.refVenaleForm?.value?.prixVente) {
      this.refVenaleForm.controls['ratio'].setErrors(Validators.required);
      this.refVenaleForm.controls['prixVente'].setValidators(null);
      return;
    }
  }

  prixVenteChange(event : any)  {
    if(event?.value) {
      this.refVenaleForm.get('prixVente').setValue(event?.value)
      this.requiredPrixVenteRatio();
    }
  }

  ratioChange(event : any)  {
    if(event?.value) {
      this.refVenaleForm.get('ratio').setValue(event?.value)
      this.requiredPrixVenteRatio();
    }
  }
}
