import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from "@angular/common/http";
import {Observable} from "rxjs";
import {RapportExpertise} from "../models/rapport-expertise/rapport-expertise";
import {BienCadastre} from "../models/bien/bien-cadastre";
import {ReferentielValeur} from "../models/referentiel-valeur";
import {BienDesserte} from "../models/bien/bien-desserte";
import {BienConstruction} from "../models/bien/bien-construction";
import {BienAmenagementInterieur} from "../models/bien/bien-amenagement-interieur";
import {BienSurface} from "../models/bien/bien-surface";
import {BienAnnexe} from "../models/bien/bien-annexe";
import {BienReference} from "../models/bien/bien-reference";
import {BienReferenceLocative} from "../models/bien/bien-reference-locative";
import { runtimeEnvironment } from 'src/environments/runtime-environment';

@Injectable({
  providedIn: 'root'
})
export class RapportExpertiseService {

  baseUrl = runtimeEnvironment.backEndPoint + "api/dossier/rapport-expertise/";
  urlParam = runtimeEnvironment.backEndPoint + "api/rapport-expertise/param/"

  constructor(private http: HttpClient) { }

  /**
   * Retourne les dossiers du type rapport d'expertise
   * @param idDossier
   */
  getRapportByIdDossier(idDossier : number) : Promise<RapportExpertise> {
      return this.http.get<RapportExpertise>(this.baseUrl + idDossier).toPromise()
       .then((response) => response as RapportExpertise)
       .catch();
  }

  /**
   * Sauvegarder le rapport d'expertise
   * @param avisValeur
   */
  updateAndSaveRapport(rapport : RapportExpertise) : Observable<RapportExpertise> {
    return this.http.post<RapportExpertise>(this.baseUrl +"edition/update", rapport);
  }

  /**
   * Génère le document de synthèse du rapport
   * @param idDossier
   */
  genererSynthese(idDossier: number) : Observable<Boolean> {
    return this.http.get<Boolean>(this.baseUrl + "generer/synthese/" + idDossier);
  }

  /**
   * Génère le rapport
   * @param idDossier
   */
   genererRapport(idDossier: number) : Observable<Boolean> {
    return this.http.get<Boolean>(this.baseUrl + "generer/rapport/" + idDossier);
  }

  getAllNatureVisite() : Promise<any[]> {
    return this.http.get<any[]>(this.urlParam +"all-natures-visite").toPromise();
  }

  getAllSituationBiens() : Promise<any[]> {
    return this.http.get<any[]>(this.urlParam +"all-situations-bien").toPromise();
  }

  getAllEtatEntretiens() : Promise<any[]> {
    return this.http.get<any[]>(this.urlParam +"all-etats-entretien").toPromise();
  }

  /**
   * Récupère tous les type de valeur
   */
  getAllParamTypeValeur() : Observable<any[]> {
    return this.http.get<any[]>(this.urlParam +"all-param-type-valeur");
  }

  /**
   *
   */
  getAllParamOccupation() : Observable<any[]> {
    return this.http.get<any[]>(this.urlParam +"all-param-occupation");
  }

  /**
   *
   */
  getAllParamTypeRegimejRapport() : Observable<any[]> {
    return this.http.get<any[]>(this.urlParam +"all-param-type-regime-rapport");
  }

  /**
   *
   */
  getAllTypeBiens() : Observable<any[]> {
    return this.http.get<any[]>(this.urlParam +"all-ref-type-biens");
  }

  getAllTypeBien() : Observable<ReferentielValeur[]> {
    return this.http.get<any[]>(this.urlParam +"all-type-bien");
  }

  /**
   *
   */
  getAllParamSurface() : Observable<any[]> {
    return this.http.get<any[]>(this.urlParam + "all-param-surface");
  }

  /**
   * Récupère la liste des regimes fiscaux locatif
   */
  getAllRegimeFiscalLocatif() : Observable<any[]> {
    return this.http.get<any[]>(this.urlParam + "all-regime-fiscal-locatif");
  }

  //

  getAllRegimeFiscalVenal() : Observable<any[]> {
    return this.http.get<any[]>(this.urlParam + "all-regime-fiscal-venal");
  }

  /**
   *  Récupère la liste des periodes loyers
   */
  getPeriodiciteLoyer() : Observable<any[]> {
    return this.http.get<any[]>(this.urlParam + "all-periodicite-loyer");
  }

  getAllMethodeValorisation() : Observable<any[]> {
    return this.http.get<any[]>(this.urlParam + "all-methode-valorisation");
  }

  /**
   *
   */
  getAllDelaiCommercialisation() : Observable<any[]> {
    return this.http.get<any[]>(this.urlParam + "all-delai-commercialisation");
  }

  /**
   *
   */
  getAllLiquidite() : Observable<any[]> {
    return this.http.get<any[]>(this.urlParam +"all-liquidite");
  }

  /**
   *
   */
  getAllVilleImpl() : Observable<any[]> {
    return this.http.get<any[]>(this.urlParam +"all-villes-impl");
  }

  /**
   *
   */
  getAllSecteurs() : Observable<ReferentielValeur[]> {
    return this.http.get<ReferentielValeur[]>(this.urlParam + "all-secteurs");
  }

  /**
   *
   */
  getAllDessertes() : Observable<ReferentielValeur[]> {
    return this.http.get<ReferentielValeur[]>(this.urlParam + "all-dessertes");
  }

  /**
   * Sauvegarder le bein cadastre
   * @param avisValeur
   */
  saveBieCadastre(biec : BienCadastre) : Observable<BienCadastre> {
    return this.http.post<BienCadastre>(this.baseUrl +"bien-cadastre/save", biec);
  }

  getAllBienCadastreByBienId(idBien : number) : Promise<BienCadastre[]>{
    return this.http.get<BienCadastre[]>(this.baseUrl + "all-bien-cadastre/" + idBien).toPromise()
  }

  deleteBienCadastre(idBienCadastre : number, idDossier : number) : Promise<any>{
    let param = new HttpParams().append('idDossier', idDossier);
    return this.http.delete<any>(this.baseUrl + "delete-bien-cadastre/" + idBienCadastre, {params : param}).toPromise()
  }

  /**
   *
   */
  getAllBienDesserteByBienId(idBien : number) : Promise<BienDesserte[]> {
    return this.http.get<BienDesserte[]>(this.baseUrl + "all-dessertes-bien/" + idBien).toPromise()
  }

  /**
   * Sauvegarder le bien desserte
   * @param avisValeur
   */
  saveBieDesserte(bieD : BienDesserte) : Observable<BienDesserte> {
    return this.http.post<BienDesserte>(this.baseUrl +"bien-desserte/save", bieD);
  }

  deleteBienDesserte(idBienDesserte : number, idDossier : number) : Promise<any>{
    let param = new HttpParams().append('idDossier', idDossier);
    return this.http.delete<any>(this.baseUrl + "delete-bien-desserte/" + idBienDesserte, {params : param}).toPromise()
  }

  getAllTopographie() : Observable<any[]> {
    return this.http.get<any[]>(this.urlParam +"all-topographie");
  }

  getAllConfiguration() : Observable<any[]> {
    return this.http.get<any[]>(this.urlParam +"all-configuration");
  }

  getAllEntretiens() : Observable<ReferentielValeur[]> {
    return this.http.get<ReferentielValeur[]>(this.urlParam +"all-entretien");
  }


  getAllConstructions() : Observable<ReferentielValeur[]>{
    return this.http.get<ReferentielValeur[]>(this.urlParam +"all-construction");
  }


  getAllAmenagementInterieur(): Observable<ReferentielValeur[]> {
    return this.http.get<ReferentielValeur[]>(this.urlParam +"all-amenagement-interieur");
  }

  saveBieConstruction(biec : BienConstruction) : Observable<BienConstruction> {
    return this.http.post<BienConstruction>(this.baseUrl +"bien-construction/save", biec);
  }

  deleteBienConstruction(idBienDesserte : number, idDossier : number) : Promise<any>{
    let param = new HttpParams().append('idDossier', idDossier);
    return this.http.delete<any>(this.baseUrl + "delete-bien-construction/" + idBienDesserte, {params : param}).toPromise()
  }

  saveBieAmenagementInterieur(bieAI : BienAmenagementInterieur) : Observable<BienAmenagementInterieur> {
    return this.http.post<BienAmenagementInterieur>(this.baseUrl +"bien-amenagement-interieur/save", bieAI);
  }

  deleteBienAmenagementInterieur(id : number, idDossier : number) : Promise<any>{
    let param = new HttpParams().append('idDossier', idDossier);
    return this.http.delete<any>(this.baseUrl + "delete-bien-amenagement-interieur/" + id, {params : param}).toPromise()
  }

  getAllBienConstructionByBienId(idBien : number) : Promise<BienConstruction[]>{
    return this.http.get<BienConstruction[]>(this.baseUrl + "all-construction-bien/" + idBien).toPromise()
  }

  getAllAmenagementInterieurByBienId(idBien : number) : Promise<BienAmenagementInterieur[]>{
    return this.http.get<BienAmenagementInterieur[]>(this.baseUrl + "all-amenagement-bien/" + idBien).toPromise()
  }

  getListReserves() : Observable<any[]>{
    return this.http.get<any[]>(this.urlParam + "list-reserve");
  }

  getAllTypeSurfaceRapport() : Observable<any[]>{
    return this.http.get<any[]>(this.urlParam + "all-type-surface-rapport");
  }


  saveBieSurface(bies : BienSurface) : Observable<BienAmenagementInterieur> {
    return this.http.post<BienAmenagementInterieur>(this.baseUrl +"bien-surface/save", bies);
  }

  deleteBienSurface(id : number, idDossier : number) : Promise<any>{
    let param = new HttpParams().append('idDossier', idDossier);
    return this.http.delete<any>(this.baseUrl + "delete-bien-surface/" + id, {params : param}).toPromise()
  }

  getAllBienSurfaceByBienId(idBien : number) : Promise<BienSurface[]>{
    return this.http.get<BienSurface[]>(this.baseUrl + "all-surface-bien/" + idBien).toPromise()
  }


  getAllAnnexes(): Observable<ReferentielValeur[]> {
    return this.http.get<ReferentielValeur[]>(this.urlParam +"all-annexes");
  }

  saveBieAnnexe(bies : BienAnnexe) : Observable<BienAnnexe> {
    return this.http.post<BienAnnexe>(this.baseUrl +"bien-annexe/save", bies);
  }

  deleteBienAnnexe(id : number, idDossier : number) : Promise<any>{
    let param = new HttpParams().append('idDossier', idDossier);
    return this.http.delete<any>(this.baseUrl + "delete-bien-annexe/" + id, {params : param}).toPromise()
  }

  getAllBienAnnexesByBienId(idBien : number) : Promise<BienAnnexe[]>{
    return this.http.get<BienAnnexe[]>(this.baseUrl + "all-bien-annexe/" + idBien).toPromise()
  }

  getAllDPE(): Observable<ReferentielValeur[]> {
    return this.http.get<ReferentielValeur[]>(this.urlParam +"all-dpe");
  }

  saveBieReference(bier : BienReference) : Observable<BienReference> {
    return this.http.post<BienReference>(this.baseUrl +"bien-reference/save", bier);
  }

  deleteBienReference(id : number, idDossier : number) : Promise<any>{
    let param = new HttpParams().append('idDossier', idDossier);
    return this.http.delete<any>(this.baseUrl + "delete-bien-reference/" + id, {params : param}).toPromise()
  }

  getAllBienReferenceByDossierId(idDossier : number) : Promise<BienReference[]>{
    return this.http.get<BienReference[]>(this.baseUrl + "all-bien-reference/" + idDossier).toPromise()
  }


  saveBieReferenceLoc(bierefloc : BienReferenceLocative) : Observable<BienReferenceLocative> {
    return this.http.post<BienReferenceLocative>(this.baseUrl +"bien-reference-locative/save", bierefloc);
  }

  deleteBienReferenceLoc(id : number, idDossier : number) : Promise<any>{
    let param = new HttpParams().append('idDossier', idDossier);
    return this.http.delete<any>(this.baseUrl + "delete-bien-reference-locative/" + id, {params : param}).toPromise()
  }

  getAllBienReferenceLocByDossierId(idDossier : number) : Promise<BienReferenceLocative[]>{
    return this.http.get<BienReferenceLocative[]>(this.baseUrl + "all-bien-reference-locative/" + idDossier).toPromise()
  }

  getAllMethodeValorsationRapport(): Observable<any[]> {
    return this.http.get<any[]>(this.urlParam +"list-methode-valorisation-rapport");
  }

  getTauxFiscalite(): Observable<any[]> {
    return this.http.get<any[]>(this.urlParam +"list-taux-fiscalite");
  }

  sendSyntheseForValidation(idDossier : number, uidDocument : string): Observable<Boolean> {
    let param = new HttpParams().append('idDossier', idDossier).append("guidDocument", uidDocument);
    return this.http.get<Boolean>(this.baseUrl + "send-synthese-for-validation", {params : param});
  }

  sendRapportForValidation(idDossier : number): Observable<Boolean> {
    return this.http.get<Boolean>(this.baseUrl + "send-rapport-for-validation/" + idDossier);
  }


}
