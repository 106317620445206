import {Component} from '@angular/core';
import {NgForm} from '@angular/forms';
import {Router} from '@angular/router';
import {AuthService} from "../../../core/service/auth/auth.service";
import {LocalStorageService} from "../../../core/service/local-storage/local-storage.service";
import {TranslateService} from "../../../shared/translate/translate.service";
import {Message, MessageService} from "primeng/api";
import {MessageStorageService} from "../../../core/service/local-storage/message-storage.service";
import {MessageObservableService} from "../../../shared/observable/message-observable.service";
import {Location} from "@angular/common";
import { runtimeEnvironment } from 'src/environments/runtime-environment';

@Component({
  selector: 'login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent {

  public login: string ;
  public password: string ;
  public chiffre1: string;
  public chiffre2: string;
  public chiffre3: string;
  public chiffre4: string;
  public chiffre5: string;
  public chiffre6: string;
  public chiffre7: string;
  public chiffre8: string;
  public infoMessage: Message[];
  public errorMessage: Message[];
  errorMessageWebsso : Message[];
  public errorMessageCode: Message[];
  public showCodeInput: boolean;

  env =  runtimeEnvironment;

 constructor(
    private router:Router,
    private auth:AuthService,
    private tokenStorageService : LocalStorageService,
    private translate : TranslateService, private messageService: MessageService,
    private messageStorage : MessageStorageService,
    private messageObservableService : MessageObservableService,
    private location : Location
 ) {
   let url = window.location.href
   if(auth.isSignIn()) {
     this.router.navigateByUrl("/dashboard");
   }

   this.messageErrorWebsso();

 }

  ngOnInit(): void {

  }


  ask2fa(form: NgForm) {

    if (form.invalid ) {
      return;
    }

    this.auth.ask2fa(this.login, this.password).subscribe(response => {
      if(response) {
        this.showCodeInput = true;
        this.router.navigate(['/auth/code'], {
          state: {
            message: this.translate.getElement("AUTH.MESSAGE.INFO.CODE_SEND"),
            login : this.login,
            password : this.password
          }
        } );
      } else {
        this.errorMessage = [ {severity:'error', summary : this.translate.getElement("AUTH.MESSAGE.ERROR.ID_OR_PASSWORD_INCOORECT")}];
      }
   },
   error => {
     if (error.status == 400) {
       // Unauthorized
       this.errorMessage = [ {severity:'error', summary : this.translate.getElement("AUTH.MESSAGE.ERROR.ID_OR_PASSWORD_INCOORECT")}];
     }
     else {
       // PRECONDITION_FAILED
       this.errorMessage = [ {severity:'error', summary : this.translate.getElement("AUTH.MESSAGE.ERROR.PRECONDITION_FAILLED_LOGIN")}];
     }
   });

  }

  connectionBySSO() {

    if(this.env.isInterne) {
      this.tokenStorageService.setApplyButtonWeSSO('WEBSSO_BUTTON');
      this.auth.configureSSOConnexion();
      this.messageErrorWebsso();
    }
  }

  messageErrorWebsso() {
    this.messageObservableService.onMessageErrorWebsso().subscribe(
      message => {
        if(message) {
          this.errorMessageWebsso = [
            {severity :'error', summary : this.translate.getElement("AUTH.MESSAGE.ERROR.PRECONDITION_FAILED") }
          ];
          this.messageObservableService.sendMessageErrorWebsso(null)
        }
      }
    )
  }

}
