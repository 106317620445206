export enum ProfilesUser {
  ADMINISTRATEUR="ADMINISTRATEUR",
  DIRECTION_GENERALE="DIRECTION_GENERALE",
  DIRECTION="DIRECTION",
  MANAGEMENT_INTERMEDIAIRE="MANAGEMENT_INTERMEDIAIRE",
  EXPERT_INTERNE="EXPERT_INTERNE",
  EXPERT_EXTERNE="EXPERT_EXTERNE",
  ASSISTANTE="ASSISTANTE",
  ANIMATEUR_RESEAU="ANIMATEUR_RESEAU",
  COMPTABILITE="COMPTABILITE",
  AGENT="AGENT",
  ADMINISTRATEUR_TECHNIQUE="ADMINISTRATEUR_TECHNIQUE"
}
