import {Component, HostListener, Input, OnInit} from '@angular/core';
import {MenuItem, MessageService, PrimeNGConfig} from 'primeng/api';

@Component({
  selector: 'app-navigation-contextuelle',
  templateUrl: './navigation-contextuelle.component.html',
  styleUrls: ['./navigation-contextuelle.component.scss'],
  providers: [MessageService]
})
export class NavigationContextuelleComponent implements OnInit {

  @Input()
  AnchorItem: MenuItem[];

  @Input()
  ActionsItem: MenuItem[];
//pour afficher un autre menu au dessous du premier menu  (exemple menu livrable au dessous du menu actions)
  @Input()
  SecondActionsItem: MenuItem[];

  isCollapse: boolean = false;

  constructor(private messageService: MessageService, private primengConfig: PrimeNGConfig) {
  }

  ngOnInit(): void {
    this.primengConfig.ripple = true;
    this.closableMenu();
  }

  collapseMenu() {

    if (document.getElementById('menuContextuel').classList.contains('is-close')) {
      document.getElementById('menuContextuel').classList.remove('is-close');
      document.getElementById('main-layout').classList.add('have-secondary-nav');
      this.isCollapse = false;

    } else {
      document.getElementById('menuContextuel').classList.add('is-close');
      document.getElementById('main-layout').classList.remove('have-secondary-nav');
      this.isCollapse = true;
    }

  }

  @HostListener('window:resize', ['$event'])
  onResize(event : any) {
    this.closableMenu();
  }

  closableMenu() {
    if(window.innerWidth < 1300 ) {
      this.isCollapse = true;
      document.getElementById('menuContextuel')?.classList.add('is-close');
      document.getElementById('main-layout')?.classList.remove('have-secondary-nav');
    }else {
      document.getElementById('menuContextuel')?.classList.remove('is-close');
      document.getElementById('main-layout')?.classList.add('have-secondary-nav');
      this.isCollapse = false;
    }
  }

}
