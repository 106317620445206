<h2>{{titre}} ({{documents?.length ? documents?.length : 0}})</h2>
<p-messages [(value)]="message" [escape]="false" [key]="keyMessage"></p-messages>

<div>
  <p-progressSpinner *ngIf="progressSpinner"  [style]="{width: '100px', height: '100px'}"
                     styleClass="custom-spinner justify-content-center flex mt-6"
                     strokeWidth="3" fill="var(--surface-ground)"
                     animationDuration=".5s"></p-progressSpinner>
</div>

<div class="grid gallery" *ngIf="!progressSpinner">


  <div class="col-12  md:col-6 lg:col-4" *ngFor="let doc of documents">
    <figure >
      <img [src]="doc.lienImage" alt="">
      <figcaption class="flex">

        <div class="field input-ghost commentairePhoto">
          <app-commentaire-photo [doc]="doc" [keyMessage]="keyMessage" [idDossier]="idDossier"></app-commentaire-photo>
        </div>

        <div class="cta flex">
          <button type="button" pButton pRipple icon="pi pi-trash"
                  pTooltip="Supprimer" tooltipPosition="top" class="test p-button-rounded p-button-sm w-full m-0" (click)="deletePicture(doc)"></button>
        </div>
      </figcaption>
    </figure>
  </div>

  <div class="col-12  md:col-6 lg:col-4" *ngIf="!progressSpinner">
    <app-upload-photo
      [displayLegende]="false"
      [categorie]="categorie"
      [idObjetPrincipal]="idBien"
      [idCategorie]="idCategorie"
      [addNewPhoto]="true" [parent]="this"
      [keyMessage]="keyMessage"
      [idDossier]="idDossier"
      [multiple]="true">
    </app-upload-photo>
  </div>
</div>
