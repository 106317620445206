import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {ConfirmationService, MenuItem, Message} from "primeng/api";
import {TranslateService} from "../../../shared/translate/translate.service";
import {LocalStorageService} from "../../../core/service/local-storage/local-storage.service";
import {DossierService} from "../../../core/service/dossier.service";
import {Dossier} from "../../../core/models/dossiers/dossier";
import {ActivatedRoute, Router} from "@angular/router";
import {DatePipe} from "@angular/common";
import {UtilsFonction} from "../../../shared/providers/utils-fonction";
import {DuplicationDossierComponent} from "../../../core/dialog/duplication-dossier/duplication-dossier.component";
import {FormBuilder} from "@angular/forms";
import {DialogService, DynamicDialogRef} from "primeng/dynamicdialog";
import {UserService} from "../../../core/service/user/user.service";
import {BienService} from "../../../core/service/bien.service";
import {FicheDossierObsevableService} from "../../../shared/observable/fiche-dossier-obsevable.service";
import {MessageStorageService} from "../../../core/service/local-storage/message-storage.service";
import {NgxSpinnerService} from "ngx-spinner";
import {DocumentService} from "../../../core/service/document.service";
import {User} from "../../../core/models/user/user.model";
import {ProfilesUser} from "../../../core/models/user/profiles-user";

@Component({
  selector: 'app-fiche-demande',
  templateUrl: './fiche-demande.component.html',
  styleUrls: ['./fiche-demande.component.scss']
})
export class FicheDemandeComponent implements OnInit {

  stock : any;
  anchorItem: MenuItem[] = [];

  dossier : Dossier;

  stepsList : any [] = [];

  displayMapGoogle : boolean = true;
  message : Message[];
  actionsItem: MenuItem[] = [];

  userConnecte : User;
  profiles : ProfilesUser[];

  constructor(
    private translate : TranslateService,
    private storage : LocalStorageService,
    private dossierService : DossierService,
    private route: ActivatedRoute,
    private router : Router,
    private datePipe : DatePipe,
    public ref: DynamicDialogRef,
    public dialogService: DialogService,
    private spinnerService: NgxSpinnerService,
    private messageStorage : MessageStorageService,
    private userService : UserService

  ) {
    if(typeof google === 'undefined') {
      this.displayMapGoogle = false;
    }
  }

  ngOnInit(): void {

    if(this.messageStorage.getMessage()) {
      this.message = [
        {severity:'success', summary:this.translate.getElement(this.messageStorage.getMessage(), this.messageStorage.getKey1(), this.messageStorage.getKey2()) }
      ];

      this.messageStorage.removeMessage();
      this.scrollToTop();
    }

    this.route.params.subscribe(params => {
      if(params) {
        this.getDossierById(params['id']);
        this.getUserConnected();
      }
    });

    this.navigationContextuelle();

  }

  async getUserConnected() {
    this.profiles = await this.userService.getUserConnectedProfilesById();
    this.userConnecte = await this.userService.getUserConnectedByToken();
    if(this.userConnecte) {
      this.userConnecte.profiles = this.profiles;
    }
  }


  getDossierById(idDossier : number) {
    this.dossierService.getDosierById(idDossier).subscribe(
      data => {
        if(data) {
          this.dossier = data;
          this.stepsList = UtilsFonction.navSteps(this.dossier, this.translate, this.datePipe);
          this.navActionsItem();
        }
      }, error => {
        if(error.status === 401) {
          this.router.navigateByUrl("/role-denied");
        }
      }
    )
  }

  /**
   * Les elements de la navigation pour la redirection à chaque section
   */
  navigationContextuelle() {
    this.anchorItem.push({
      label: this.translate.getElement('DOSSIER.DEMANDE.CREATION.SECTION'),
      items: [
        {
          label: this.translate.getElement('DOSSIER.DEMANDE.CREATION.DEMANDEUR'),
          command : event => {
            const yOffset = -15;
            const y = document.getElementById('demandeur').getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({top: y, behavior: 'smooth'});
            this.addIsactiveNavigationContextuelleMenuItem(event);
          }
        },
        {
          label: this.translate.getElement('DOSSIER.DEMANDE.CREATION.AFFAIRE'),
          command :(event) => {
            const yOffset = -15;
            const y = document.getElementById('client').getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({top: y, behavior: 'smooth'});
            this.addIsactiveNavigationContextuelleMenuItem(event);
          }
        },
        {
          label: this.translate.getElement('DOSSIER.DEMANDE.CREATION.CONTACT'),
          command : event => {
            const yOffset = -15;
            const y = document.getElementById('contact').getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({top: y, behavior: 'smooth'});
            this.addIsactiveNavigationContextuelleMenuItem(event);

          }
        },
        {
          label: this.translate.getElement('DOSSIER.DEMANDE.CREATION.BIEN'),
          command : event => {
            const yOffset = -15;
            const y = document.getElementById('bien').getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({top: y, behavior: 'smooth'});
            this.addIsactiveNavigationContextuelleMenuItem(event)
          }
        },
        {
          label: this.translate.getElement('DOSSIER.DEMANDE.CREATION.DOCUMENT'),
          command : event => {
            const yOffset = -15;
            const y = document.getElementById('document').getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({top: y, behavior: 'smooth'});
            this.addIsactiveNavigationContextuelleMenuItem(event);
          }
        },
        {
          label: this.translate.getElement('DOSSIER.DEMANDE.CREATION.COMMENTAIRE'),
          command : event => {
            const yOffset = -15;
            const y = document.getElementById('commentaire').getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({top: y, behavior: 'smooth'});
            this.addIsactiveNavigationContextuelleMenuItem(event);
          }
        }
      ]
    })
  }

  addIsactiveNavigationContextuelleMenuItem(event : any) {
    if(this.stock && this.stock.item.label !== event.item.label) {
      this.stock.originalEvent.target.parentNode.classList.remove("is-active")
      event.originalEvent.target.parentNode.classList.add("is-active");
      this.stock = event;
    }else {
      event.originalEvent.target.parentNode.classList.add("is-active");
      this.stock = event;
    }
  }

  /**
   * construction des actions utilisateurs sur le dossier
   * @param dossier
   */
  async navActionsItem() {

    let conflit: boolean = this.dossier.bien?.enConflit;

    let itemDupliquer: MenuItem = {
      label: this.translate.getElement('DOSSIER.GESTIONNAIRE.ACTION.DUPLIQUER'),
      icon: 'pi pi-copy',
      visible: !conflit,
      styleClass: conflit ? 'en_conflit' : '',
      command: event => {
        this.ref = this.dialogService.open(DuplicationDossierComponent, {
          header: this.translate.getElement('DOSSIER.DUPLIQUER_DOSSIER',   this.dossier.numeroDossier),
          width: '30vw',
          height: '84%',
          baseZIndex: 10000,
          data :{ dossier :  this.dossier , user : this.userConnecte},
          styleClass : 'p-dynamic-dialog'
        });

        this.ref.onClose.subscribe((data : any) =>{
          if (data && data.valueTypeLivrable) {
            let idAgence : number = 0;
            if(data.agent != null) {
              idAgence = data.agent?.id;
            }
            this.dupliquer(data.valueTypeLivrable, idAgence);
          }
        });
      }
    };

    if (this.dossier.statut == null) {
      return [];
    }

    if (!conflit) {
      this.actionsItem = [{
        label: this.translate.getElement('DOSSIER.GESTIONNAIRE.ACTION.ACTION_TITLE'),
        items: [itemDupliquer]
      }];
    }
    return this.actionsItem;

  }


  dupliquer(codeLivrable : string, idAgence : number) {
    this.spinnerService.show();
    this.dossierService.duplicationDossier(this.dossier.idDossier, codeLivrable, idAgence).subscribe(response => {
      if(response ) {
        const message = this.translate.getElement('DOSSIER.GESTIONNAIRE.MESSAGE.CLICK_OUVRIR');
        this.messageStorage.saveMessage('DOSSIER.GESTIONNAIRE.MESSAGE.SUCESS_DUPLICATION_DOSSIER',
          response.numeroDossier.toString(),
          "<a class='messageLink' href='/dossier/demande/fiche/" + response.idDossier +  " '>" + message + "</a>"
        )
        this.reloadCurrentRoute();
        this.spinnerService.hide();
      }else {
        this.message = [
          {severity:'error', summary:this.translate.getElement('DOSSIER.GESTIONNAIRE.MESSAGE.ERROR_DUPLICATION_DOSSIER', this.dossier.numeroDossier)}
        ];
        this.spinnerService.hide();
      }

    }, error => {

      //Si aucune négociation chapeau n'est associée à ce tiers
      if(error.status === 404) {
        this.message = [
          {severity:'error', summary:this.translate.getElement('DOSSIER.GESTIONNAIRE.MESSAGE.ERROR_DUPLICATION_DOSSIER_NEGO_NON_TROUVE', this.dossier.numeroDossier)}
        ];
      }else {
        this.message = [
          {severity:'error', summary:this.translate.getElement('DOSSIER.GESTIONNAIRE.MESSAGE.ERROR_DUPLICATION_DOSSIER', this.dossier.numeroDossier)}
        ];
      }
      this.spinnerService.hide();
      this.scrollToTop();
    })
  }

  reloadCurrentRoute() {
    let currentUrl = this.router.url;
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
      this.router.navigate([currentUrl]);
    });
  }

  scrollToTop(){
    window.scrollTo(0,0);
  }

}
