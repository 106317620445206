export class ConfigSizeInput {

  NOM_CLIENT = 128;
  PRENOM_CLIENT = 32;
  TELEPHONE_CLIENT = 32;
  MAIL_CLIENT = 64;
  NOM_MOARAL_CLIENT = 64;
  REPRESENTANT_CLIENT = 64;

  NUMERO_CONTRAT = 16;

  //DOS DEMANDE
  NOM_CONTACT = 128;
  PRENOM_CONTACT = 32;
  TELEPHONE_CONTACT = 32;
  MAIL_CONTACT = 64;
  OBSERVATION = 4000;
  NOM_TIERS = 128;
  PRENOM_TIERS = 32;
  MAIL_TIERS = 64;
  RAISON_SOCIALE_CONTACT = 256;

  //ADRESSE
  ADRESSE_FORMATE = 255;
  BOITE_POSTALE = 255;
  CODE_POSTALE = 255;
  NOM_VOIE = 255;
  NUMERO_ADRESSE = 255;
  COMPLEMENT_ADRESSE = 255;
  LIEU_DIT = 255;
  VILLE = 255;

  //Bien caracteris
  PARKING = 80;
  DATE_CONSTRUCTION = 32;
  NIVEAU = 20;
  DURRE_BAIL = 16;
  PRISE_EFFET_BAIL = 16;

  //Dos avis sans visite
  OCCUPATION = 80;


  //Pour la verification des injections excel et certains caractères illégaux comme '= ; % et unicode' par exemple
  regexPattern = "^[^;%\"\u0000-\u001f=]*$";

  //Pour la verification des injections excel et certains caractères illégaux comme '= et unicode' par exemple
  regexPattern2 = "^[^\"\u0000-\u001f=]*$";

  //DOS RAPPORT
  NOM_EXPERT  = 255;
  PRENOM_EXPERT = 255;
  ACCOMPAGNATEUR  = 70;

}
