<form [formGroup]="form" >

  <div class="card-large" id="section1">
    <h1 id="cdtOccup">VI - {{'RAPPORT_EXPERTISE.CODITION_OCCUPATION' | translateI18n}}</h1>
    <div class="grid">

      <div class="col-12 field field-line">

        <div class="mb-2">
          <small class="obligatoire">{{'RAPPORT_EXPERTISE.MESSAGE.MAX_CARACTERE' | translateI18n : '3500'}}
            <span [ngClass]="{'is-alert':  f.conditionOccupation.hasError('hasError')}">({{nbConditionOccupation}} / 3500)</span>
          </small>
        </div>

        <div *ngIf="f.conditionOccupation.hasError('hasError')">
          <p-messages  [(value)]="message" key="conditionOccupation"></p-messages>
        </div>

        <p-editor formControlName="conditionOccupation" [style]="{'height':'320px'}" name="conditionOccupation"
                  id="conditionOccupation" class="w-full"
                  [ngClass]="{'editor-error': f.conditionOccupation.hasError('hasError')
                  || (submittedRequired && (f.conditionOccupation?.errors?.required || f.conditionOccupation.hasError('hasEmptyCaracterError') ))}"
                  (onTextChange)="numConditionOccupationextChange($event)">
          <p-header>
            <app-p-editor-header></app-p-editor-header>
          </p-header>
        </p-editor>

        <small *ngIf="submittedRequired && (f.conditionOccupation?.errors?.required || f.conditionOccupation.hasError('hasEmptyCaracterError') )" class="p-error block">{{'MESSAGE.ERROR.CHAMPS_OBLIGATOIRE' | translateI18n}}</small>
      </div>

    </div>
  </div>

</form>
