<form [formGroup]="clientForm" >

  <h2  id="client">{{'DOSSIER.DEMANDE.CREATION.AFFAIRE' | translateI18n}}</h2>
  <div class="card">

      <fieldset class="grid formgrid">

        <!-- .p-radiobutton-group regroupement .inline-group pour afficher les radiobutton en ligne -->
        <div class="field col-12 field-line " [ngClass]="{'read-only-form' : f.customerType.status === 'DISABLED' }">
          <div class="p-radiobutton-group inline-group">
            <p-radioButton  value="human" formControlName="customerType" label="{{'DOSSIER.DEMANDE.CREATION.PHYSICAL_PERSON' | translateI18n}}" (onClick)="choiceTypeClient()">
            </p-radioButton>
            <p-radioButton value="company" formControlName="customerType" label="{{'DOSSIER.DEMANDE.CREATION.CORPORATION' | translateI18n}}" (onClick)="choiceTypeClient()"></p-radioButton>
          </div>
        </div>


      </fieldset>



        <fieldset class="grid formgrid" *ngIf="clientForm.value?.customerType === 'human'">

          <div class="field col-12 field-line" >
            <div class="p-dropdown-group" *ngIf="f.gender.status !== 'DISABLED'">
              <label for="genders" class="obligatoire">
                {{'DOSSIER.DEMANDE.CREATION.CIVILITY' | translateI18n}}</label>
              <p-dropdown  [options]="civilites"  formControlName="gender" optionLabel="abreviation" id="genders"
                          placeholder="{{'PLACEHOLDER.CHOOSE' | translateI18n}}"
                          [ngClass]="{'ng-dirty ng-invalid': submitted  && clientForm.value.gender === null}">

              </p-dropdown>
            </div>

            <div class="read-only-form" *ngIf="f.gender.status === 'DISABLED'" >
              <div class="label"> {{'DOSSIER.DEMANDE.CREATION.CIVILITY' | translateI18n}} : </div>
              {{clientForm.value?.gender?.abreviation}}

            </div>


            <small  *ngIf="submitted && clientForm.value.gender === null && f.gender.status !== 'DISABLED'"
                    class="p-error block">{{'MESSAGE.ERROR.CHAMPS_OBLIGATOIRE' | translateI18n}}</small>
          </div>

          <div class="col-12 grid field-line">

            <div class="field col-12 lg:col-6" [ngClass]="{'read-only-form' : f.nomClient.status === 'DISABLED' }">
              <label for="name" [ngClass]="{'obligatoire' : f.nomClient.status !== 'DISABLED' }">
                {{'DOSSIER.DEMANDE.CREATION.NOM' | translateI18n}}</label>
              <div class="p-input-icon-right w-full">
                <i class="pi pi-user"></i>
                <input type="text" pInputText formControlName="nomClient" id="name" class="inputfield w-full"
                       [ngClass]="{'ng-dirty ng-invalid': submitted
                      && (clientForm.value.nomClient === null || clientForm.value.nomClient.length === 0)}" [maxLength]="configSizeInput.NOM_CLIENT"/>
              </div>

              <small *ngIf="submitted && f.nomClient?.errors?.pattern" class="p-error block">
                {{'MESSAGE.ERROR.REGEX_PATTERN' | translateI18n}}
              </small>

              <small  *ngIf="(f.nomClient.dirty || submitted)  && (clientForm.value.nomClient === null || clientForm.value.nomClient.length === 0)"
                      class="p-error block">{{'MESSAGE.ERROR.CHAMPS_OBLIGATOIRE' | translateI18n}}</small>

            </div>

            <div class="field col-12 lg:col-6"  [ngClass]="{'read-only-form' : f.prenomClient.status === 'DISABLED' }">
              <label for="firstname" [ngClass]="{'obligatoire' : f.prenomClient.status !== 'DISABLED' }">
                {{'DOSSIER.DEMANDE.CREATION.PRENOM' | translateI18n}}</label>
              <div class="p-input-icon-right w-full">
                <i class="pi pi-user"></i>
                <input type="text" pInputText formControlName="prenomClient" id="firstname" class="inputfield w-full"
                       [ngClass]="{'ng-dirty ng-invalid': submitted
                       && (clientForm.value.prenomClient === null || clientForm.value.prenomClient.length === 0)}" [maxLength]="configSizeInput.PRENOM_CLIENT"/>
              </div>

              <small *ngIf="submitted && f.prenomClient?.errors?.pattern" class="p-error block">
                {{'MESSAGE.ERROR.REGEX_PATTERN' | translateI18n}}
              </small>

              <small *ngIf="submitted  && (clientForm.value.prenomClient === null || clientForm.value.prenomClient.length === 0)"
                     class="p-error block">{{'MESSAGE.ERROR.CHAMPS_OBLIGATOIRE' | translateI18n}}</small>

            </div>

          </div>

        </fieldset>

    <fieldset class="grid formgrid"  *ngIf="clientForm.value?.customerType === 'company'">

        <div class="col-12 grid field-line mt-2">

          <div class="field col-12 lg:col-6"  [ngClass]="{'read-only-form' : f.nomMoral.status === 'DISABLED' }">
            <label [ngClass]="{'obligatoire' : f.nomMoral.status !== 'DISABLED' }">{{'DOSSIER.DEMANDE.CREATION.NOM_MORAL' | translateI18n}}</label>
            <div class="p-input-icon-right w-full">
              <i class="pi pi-user"></i>
              <input type="text" pInputText formControlName="nomMoral" id="nommoral" class="inputfield w-full"
                     [ngClass]="{'ng-dirty ng-invalid': submitted && (clientForm.value.nomMoral === null || clientForm.value.nomMoral.length === 0)}"
                     [maxLength]="configSizeInput.NOM_MOARAL_CLIENT"/>
            </div>

            <small *ngIf="submitted && f.nomMoral?.errors?.pattern" class="p-error block">
              {{'MESSAGE.ERROR.REGEX_PATTERN' | translateI18n}}
            </small>

            <small  *ngIf="submitted && (clientForm.value.nomMoral === null || clientForm.value.nomMoral.length === 0)"
                    class="p-error block">{{'MESSAGE.ERROR.CHAMPS_OBLIGATOIRE' | translateI18n}}</small>

          </div>

          <div class="field col-12 lg:col-6" [ngClass]="{'read-only-form' : f.nomRepresentant.status === 'DISABLED' }">
            <label for="nomrepresentant">{{'DOSSIER.DEMANDE.CREATION.REPRESENTANT_LEGALE' | translateI18n}}</label>
            <div class="p-input-icon-right w-full">
              <i class="pi pi-user"></i>
              <input type="text" pInputText formControlName="nomRepresentant" id="nomrepresentant" class="inputfield w-full"
                     [ngClass]="{'ng-dirty ng-invalid': submitted
                     && (clientForm.value.nomRepresentant === null || clientForm.value.nomRepresentant.length === 0)}"
                     [maxLength]="configSizeInput.REPRESENTANT_CLIENT"/>
            </div>

            <small *ngIf="submitted && f.nomRepresentant?.errors?.pattern" class="p-error block">
              {{'MESSAGE.ERROR.REGEX_PATTERN' | translateI18n}}
            </small>

            <small *ngIf="submitted && (clientForm.value.nomRepresentant === null || clientForm.value.nomRepresentant.length === 0)"
                   class="p-error block">{{'MESSAGE.ERROR.CHAMPS_OBLIGATOIRE' | translateI18n}}</small>

          </div>
        </div>

    </fieldset>

    <fieldset class="grid formgrid">

        <div class="col-12 grid field-line">

          <div class="field col-12 lg:col-6" [ngClass]="{'read-only-form' : f.phone1.status === 'DISABLED' }">
            <label for="phone1">
              {{'DOSSIER.DEMANDE.CREATION.PHONE' | translateI18n : '1'}}
              <span> {{'DOSSIER.DEMANDE.CREATION.OPTIONAL' | translateI18n}}</span></label>
            <div class="p-input-icon-right w-full">
              <i class="pi pi-phone"></i>
              <input type="text" pInputText formControlName="phone1" id="phone1" class="inputfield w-full"
                     [ngClass]="{'ng-dirty': submitted  &&  f.phone1.invalid}" [maxLength]="configSizeInput.TELEPHONE_CLIENT"/>
            </div>

            <small *ngIf="submitted && f.phone1?.errors?.pattern" class="p-error block">
              {{'MESSAGE.ERROR.REGEX_PATTERN' | translateI18n}}
            </small>

            <small *ngIf="submitted  &&  f.phone1.invalid" class="p-error block">{{'MESSAGE.ERROR.CHAMPS_OBLIGATOIRE' | translateI18n}}</small>

          </div>


          <div class="field col-12 lg:col-6" [ngClass]="{'read-only-form' : f.phone2.status === 'DISABLED' }">
            <label for="phone2">{{'DOSSIER.DEMANDE.CREATION.PHONE' | translateI18n : '2'}} <span> {{'DOSSIER.DEMANDE.CREATION.OPTIONAL' | translateI18n}}</span></label>
            <div class="p-input-icon-right w-full">
              <i class="pi pi-phone"></i>
              <input type="text" pInputText formControlName="phone2" id="phone2" class="inputfield w-full" [maxLength]="configSizeInput.TELEPHONE_CLIENT"/>
            </div>

            <small *ngIf="submitted && f.phone2?.errors?.pattern" class="p-error block">
              {{'MESSAGE.ERROR.REGEX_PATTERN' | translateI18n}}
            </small>
          </div>

          <div class="field col-12 lg:col-6" [ngClass]="{'read-only-form' : f.email1.status === 'DISABLED' }">
            <label for="email1">
              {{'DOSSIER.DEMANDE.CREATION.EMAIL' | translateI18n : '1'}}
              <span> {{'DOSSIER.DEMANDE.CREATION.OPTIONAL' | translateI18n}}</span></label>
            <div class="p-input-icon-right w-full">
              <i class="pi pi-envelope"></i>
              <input type="email" pInputText formControlName="email1" id="email1" class="inputfield w-full"
                     [ngClass]="{'ng-dirty': submitted  &&  f.email1.invalid}"  [maxLength]="configSizeInput.MAIL_CLIENT" />
            </div>

            <div *ngIf="submitted  && f.email1.invalid" class="pt-2">
              <small *ngIf="f.email1?.errors?.pattern" class="p-error block">
                {{'MESSAGE.ERROR.REGEX_PATTERN' | translateI18n}}
              </small>
              <small *ngIf="f.email1.errors?.email"  class="p-error block">{{'MESSAGE.ERROR.EMAIL_INVALID' | translateI18n : '1'}}</small>
              <small *ngIf="f.email1.errors.required" class="p-error block">{{'MESSAGE.ERROR.CHAMPS_OBLIGATOIRE' | translateI18n}}</small>
            </div>
          </div>

          <div class="field col-12 lg:col-6" [ngClass]="{'read-only-form' : f.email2.status === 'DISABLED' }">
            <label for="email2">{{'DOSSIER.DEMANDE.CREATION.EMAIL' | translateI18n : '2'}}
              <span> {{'DOSSIER.DEMANDE.CREATION.OPTIONAL' | translateI18n}}</span></label>
            <div class="p-input-icon-right w-full">
              <i class="pi pi-envelope"></i>
              <input type="text" pInputText formControlName="email2" id="email2" class="inputfield w-full" [maxLength]="configSizeInput.MAIL_CLIENT"/>
            </div>

            <small *ngIf="submitted && f.email2?.errors?.pattern" class="p-error block">
              {{'MESSAGE.ERROR.REGEX_PATTERN' | translateI18n}}
            </small>

          </div>

          <div class="field col-12 lg:col-6" [ngClass]="{'read-only-form' : f.numContrat.status === 'DISABLED' }">
            <label for="noc" [ngClass]="{'obligatoire' : entiteClient !== 'BNPPARIBAS' && f.numContrat.status !== 'DISABLED'}">
              {{'DOSSIER.DEMANDE.CREATION.CONTRAT' | translateI18n}}</label>
            <div class="p-input-icon-right w-full">
              <input type="text" pInputText formControlName="numContrat" id="noc" class="inputfield w-full"
                     [ngClass]="{'ng-dirty': submitted  &&  f.numContrat.invalid}" [maxLength]="configSizeInput.NUMERO_CONTRAT"/>
            </div>
            <div *ngIf=" submitted &&  f.numContrat.invalid" class="pt-2">
              <small *ngIf="f.numContrat?.errors?.pattern" class="p-error block">
                {{'MESSAGE.ERROR.REGEX_PATTERN' | translateI18n}}
              </small>
              <small *ngIf="f.numContrat.errors.minlength" class="p-error block">{{'DOSSIER.DEMANDE.CREATION.MESSAGE.ERROR_CONTRAT_INVALID' | translateI18n}}</small>
              <small *ngIf="f.numContrat.errors.required" class="p-error block">{{'MESSAGE.ERROR.CHAMPS_OBLIGATOIRE' | translateI18n}}</small>
            </div>
          </div>

          <div class="field col-12 lg:col-6" *ngIf="f.fraisExp.status !== 'DISABLED'
              && (entiteClient === 'UCB'|| entiteClient === 'UCBE' || entiteClient === 'Service Expertise UCB Suisse' || entiteClient === 'DRE IMMO AFFAIRES SPECIALES')">
            <label for="fraisExp" class="obligatoire">{{'DOSSIER.DEMANDE.CREATION.FRAIS_EXPERTISE' | translateI18n}}</label>
            <div class="p-input-icon-right w-full" >
              <p-dropdown [options]="fraisExp" placeholder="{{'PLACEHOLDER.CHOOSE' | translateI18n}}" id="fraisExp" formControlName="fraisExp" name="fraisExp"
                          optionLabel="name"
                          [ngClass]="{'ng-dirty': submitted  &&  f.fraisExp.invalid}"
              ></p-dropdown>
            </div>

            <div *ngIf="submitted  &&  f.fraisExp.invalid" class="pt-2">
              <small *ngIf="f.fraisExp.errors.required" class="p-error block">{{'MESSAGE.ERROR.CHAMPS_OBLIGATOIRE' | translateI18n}}</small>
            </div>

          </div>

          <div class="field col-12 lg:col-6" *ngIf="f.fraisExp.status === 'DISABLED'">
            <p>
              <span class="label"> {{'DOSSIER.DEMANDE.CREATION.FRAIS_EXPERTISE' | translateI18n}} : </span>
              {{clientForm.value?.fraisExp?.name}}</p>
          </div>

          <div class="field col-12 lg:col-12" formGroupName="adresse" >
            <label  [ngClass]="{ 'p-error': submitted && clientForm.get('adresse.adresseFormate').value === null, 'obligatoire' : clientForm.controls.adresse.status !== 'DISABLED'}">
              {{'DOSSIER.DEMANDE.CREATION.ADRESSE' | translateI18n}}</label>
            <div class="flex flex-row">
              <div *ngIf="clientForm.value?.adresse.adresseFormate !== null" style="background-color: #ffffff;display: flex;align-items: center;" >
                <span class="p-3">{{clientForm.value?.adresse.adresseFormate}}</span>
              </div>
              <button type="button"  pButton pRipple
                      *ngIf="clientForm.value?.adresse.adresseFormate === null && clientForm.controls.adresse.status !== 'DISABLED'"
                      label="{{'BUTTON_LABEL.ADD' | translateI18n}}" (click)="addAdress()"></button>
              <button type="button" [disabled] ="!isProfilAuthorize()" pButton pRipple
                      *ngIf="clientForm.value?.adresse.adresseFormate !== null && clientForm.controls.adresse.status !== 'DISABLED'"
                      label="{{'BUTTON_LABEL.MODIFY' | translateI18n}}" (click)="addAdress(clientForm?.value?.adresse)"></button>
            </div>

            <div *ngIf="submitted" class="pt-2">
              <small *ngIf="clientForm.get('adresse.adresseFormate').value === null" class="p-error block">{{'MESSAGE.ERROR.CHAMPS_OBLIGATOIRE' | translateI18n}}</small>
            </div>
          </div>

        </div>
    </fieldset>

  </div>

</form>
