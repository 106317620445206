import {Pipe, PipeTransform} from '@angular/core';
import {formatDate} from "@angular/common";
import {TranslateService} from "../translate/translate.service";

@Pipe({
  name: 'dateFormatPipe'
})
export class DateFormatPipePipe implements PipeTransform {

  constructor(private translate: TranslateService) {}

  transform(date: Date) : any {

    if(this.translate.getLanguage()) {
      switch (this.translate.getLanguage().toLowerCase()) {
        case 'fr'.toLowerCase() :
          return formatDate(date,'dd MMMM yyyy', 'fr')
        case 'en'.toLowerCase() :
          return formatDate(date,'dd MMMM yyyy', 'en-US')
        default :
          return;
      }
    }
  }

}
