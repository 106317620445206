<form [formGroup]="contactForm">

  <h2  id="contact">{{'DOSSIER.DEMANDE.CREATION.CONTACT' | translateI18n}}</h2>

  <div class="card">

      <fieldset class="grid formgrid">

        <div class="field col-12 field-line" >
          <div class="p-radiobutton-group inline-group">
            <p-radioButton  value="idClient" formControlName="contactType"
                            label="{{'DOSSIER.DEMANDE.CREATION.ID_CLIENT' | translateI18n}}" (onClick)="choiceContactMode()">
            </p-radioButton>
            <p-radioButton value="autre" formControlName="contactType"
                           label="{{'DOSSIER.DEMANDE.CREATION.OTHER' | translateI18n}}" (onClick)="choiceContactMode()"></p-radioButton>
          </div>
        </div>
      </fieldset>


    <fieldset class="grid formgrid" *ngIf="contactForm.value?.contactType === 'autre'">


      <div class="field col-12 field-line mt-3" >
        <div class="p-radiobutton-group inline-group">
          <p-radioButton  value="human" formControlName="customerContactType"
                          label="{{'DOSSIER.DEMANDE.CREATION.PHYSICAL_PERSON' | translateI18n}}"
                          (onClick)="choiceContactType()"></p-radioButton>
          <p-radioButton value="company" formControlName="customerContactType"
                         label="{{'DOSSIER.DEMANDE.CREATION.CORPORATION' | translateI18n}}"
                         (onClick)="choiceContactType()"></p-radioButton>
        </div>
      </div>


      <div class="col-12 grid field-line mt-3">

        <div class="field col-12 lg:col-6" *ngIf="f.qualite.status !== 'DISABLED'">
          <label for="qualite" class="obligatoire">{{'DOSSIER.DEMANDE.CREATION.QUALITE' | translateI18n}}</label>
          <div class="p-input-icon-right w-full">
            <p-dropdown [options]="qualites" placeholder="{{'PLACEHOLDER.CHOOSE' | translateI18n}}" id="qualite" formControlName="qualite" name="qualite"
                        [ngClass]="{'ng-dirty ng-invalid': (submitted || f.qualite.dirty) && contactForm.value.qualite === null}"
                        optionLabel="libelle"></p-dropdown>
          </div>

          <small *ngIf=" (submitted || f.qualite.dirty) && contactForm.value.qualite === null"
                 class="p-error block">{{'MESSAGE.ERROR.CHAMPS_OBLIGATOIRE' | translateI18n}}</small>
        </div>

        <div class="field col-12 lg:col-6" *ngIf="f.qualite.status === 'DISABLED'">
          <p>
            <span class="label"> {{'DOSSIER.DEMANDE.CREATION.QUALITE' | translateI18n}} : </span>
            {{contactForm.value?.qualite?.libelle}}</p>
        </div>


        <div class="field col-12 lg:col-6" *ngIf="contactForm.value?.customerContactType === 'human'">
          <div class="p-dropdown-group" *ngIf="f.gender.status !== 'DISABLED'">
            <label for="genders" class="obligatoire" >{{'DOSSIER.DEMANDE.CREATION.CIVILITY' | translateI18n}}</label>
            <p-dropdown [options]="civilites" formControlName="gender" optionLabel="abreviation" id="genders"
                        class="w-full"
                        [ngClass]="{'ng-dirty ng-invalid': (submitted || f.gender.dirty)
                                && (contactForm.value.gender === null || contactForm.value.gender.length === 0)}"
                        placeholder="{{'PLACEHOLDER.CHOOSE' | translateI18n}}"></p-dropdown>
          </div>


          <p  *ngIf="f.gender.status === 'DISABLED'">
            <span class="label"> {{'DOSSIER.DEMANDE.CREATION.CIVILITY' | translateI18n}} : </span>
            {{contactForm.value?.gender?.abreviation}}</p>


          <small *ngIf=" (submitted || f.gender.dirty) && (contactForm.value.gender === null || contactForm.value.gender.length === 0) && f.gender.status !== 'DISABLED'"
                 class="p-error block">{{'MESSAGE.ERROR.CHAMPS_OBLIGATOIRE' | translateI18n}}</small>
        </div>

        <div class="field col-12 lg:col-6" *ngIf="contactForm.value?.customerContactType === 'human'" [ngClass]="{'read-only-form' : f.nomContact.status === 'DISABLED' }">
          <label for="name" [ngClass]="{'obligatoire' : f.nomContact.status !== 'DISABLED' }">{{'DOSSIER.DEMANDE.CREATION.NOM' | translateI18n}}</label>
          <div class="p-input-icon-right w-full">
            <i class="pi pi-user"></i>
            <input type="text" pInputText formControlName="nomContact" id="name" class="inputfield w-full"
                   [ngClass]="{'ng-dirty ng-invalid': (submitted || f.nomContact.dirty )
                   && (contactForm.value.nomContact === null || contactForm.value.nomContact.length === 0)}" [maxLength]="configSizeInput.NOM_CONTACT"/>
          </div>

          <small *ngIf="submitted && f.nomContact?.errors?.pattern" class="p-error block">{{'MESSAGE.ERROR.REGEX_PATTERN' | translateI18n}}</small>

          <small *ngIf=" (submitted || f.nomContact.dirty) && (contactForm.value.nomContact === null || contactForm.value.nomContact.length === 0)"
                 class="p-error block">{{'MESSAGE.ERROR.CHAMPS_OBLIGATOIRE' | translateI18n}}</small>

        </div>

        <div class="field col-12 lg:col-6" *ngIf="contactForm.value?.customerContactType === 'human'" [ngClass]="{'read-only-form' : f.prenomContact.status === 'DISABLED' }">
          <label for="firstname" [ngClass]="{'obligatoire' : f.prenomContact.status !== 'DISABLED' }">{{'DOSSIER.DEMANDE.CREATION.PRENOM' | translateI18n}}</label>
          <div class="p-input-icon-right w-full">
            <i class="pi pi-user"></i>
            <input type="text" pInputText formControlName="prenomContact" id="firstname" class="inputfield w-full"
                   [ngClass]="{'ng-dirty ng-invalid': (submitted || f.prenomContact.dirty)
                   && (contactForm.value.prenomContact === null || contactForm.value.prenomContact.length === 0)}" [maxLength]="configSizeInput.PRENOM_CONTACT"/>
          </div>

          <small *ngIf="submitted && f.prenomContact?.errors?.pattern" class="p-error block">{{'MESSAGE.ERROR.REGEX_PATTERN' | translateI18n}}</small>

          <small  *ngIf="(submitted || f.prenomContact.dirty)
          && (contactForm.value.prenomContact === null || contactForm.value.prenomContact.length === 0)" class="p-error block">
            {{'MESSAGE.ERROR.CHAMPS_OBLIGATOIRE' | translateI18n}}</small>

        </div>

        <div class="field col-12 lg:col-6" *ngIf="contactForm.value?.customerContactType === 'company'" [ngClass]="{'read-only-form' : f.nomMoral.status === 'DISABLED' }">
          <label [ngClass]="{'obligatoire' : f.nomMoral.status !== 'DISABLED' }">{{'DOSSIER.DEMANDE.CREATION.NOM_MORAL' | translateI18n}}</label>
          <div class="p-input-icon-right w-full">
            <i class="pi pi-user"></i>
            <input type="text" pInputText formControlName="nomMoral" id="nommoral" class="inputfield w-full"
                   [ngClass]="{'ng-dirty ng-invalid': (submitted || f.nomMoral.dirty)
                   && (contactForm.value.nomMoral === null || contactForm.value.nomMoral.length === 0)}" [maxLength]="configSizeInput.RAISON_SOCIALE_CONTACT"/>
          </div>

          <small *ngIf="submitted && f.nomMoral?.errors?.pattern" class="p-error block">{{'MESSAGE.ERROR.REGEX_PATTERN' | translateI18n}}</small>

          <small *ngIf="(submitted || f.nomMoral.dirty)
                   && (contactForm.value.nomMoral === null || contactForm.value.nomMoral.length === 0)" class="p-error block">{{'MESSAGE.ERROR.CHAMPS_OBLIGATOIRE' | translateI18n}}</small>

        </div>

        <div class="field col-12 lg:col-6" [ngClass]="{'read-only-form' : f.phone1.status === 'DISABLED' }">
          <label for="phone1">
            {{'DOSSIER.DEMANDE.CREATION.PHONE' | translateI18n : '1'}}
            <span> {{'DOSSIER.DEMANDE.CREATION.OPTIONAL' | translateI18n}}</span></label>
          <div class="p-input-icon-right w-full">
            <i class="pi pi-phone"></i>
            <input type="text" pInputText formControlName="phone1" id="phone1" class="inputfield w-full"
                   [ngClass]="{'ng-dirty ng-invalid': submitted && f.phone1?.errors?.pattern}" [maxLength]="configSizeInput.TELEPHONE_CONTACT"/>
          </div>

          <small *ngIf="submitted && f.phone1?.errors?.pattern" class="p-error block">{{'MESSAGE.ERROR.REGEX_PATTERN' | translateI18n}}</small>

        </div>

        <div class="field col-12 lg:col-6" [ngClass]="{'read-only-form' : f.phone2.status === 'DISABLED' }">
          <label for="phone2">{{'DOSSIER.DEMANDE.CREATION.PHONE' | translateI18n : '2'}} <span> {{'DOSSIER.DEMANDE.CREATION.OPTIONAL' | translateI18n}}</span></label>
          <div class="p-input-icon-right w-full">
            <i class="pi pi-phone"></i>
            <input type="text" pInputText formControlName="phone2" id="phone2" class="inputfield w-full"
                   [ngClass]="{'ng-dirty ng-invalid': submitted && f.phone2?.errors?.pattern}" [maxLength]="configSizeInput.TELEPHONE_CONTACT"/>
          </div>

          <small *ngIf="submitted && f.phone2?.errors?.pattern" class="p-error block">{{'MESSAGE.ERROR.REGEX_PATTERN' | translateI18n}}</small>

        </div>

        <div class="field col-12 lg:col-6" [ngClass]="{'read-only-form' : f.email1.status === 'DISABLED' }">
          <label for="email1">
            {{'DOSSIER.DEMANDE.CREATION.EMAIL' | translateI18n : '1'}}
            <span> {{'DOSSIER.DEMANDE.CREATION.OPTIONAL' | translateI18n}}</span></label>
          <div class="p-input-icon-right w-full">
            <i class="pi pi-envelope"></i>
            <input type="email" pInputText formControlName="email1" id="email1" class="inputfield w-full"
                   [ngClass]="{'ng-dirty ng-invalid': (submitted || f.email1.dirty) && f.email1?.errors}"
                   [maxLength]="configSizeInput.MAIL_CONTACT"/>
          </div>
          <div *ngIf="(submitted || f.email1.dirty  || f.email1.touched)" class="pt-2">
            <small *ngIf="f.email1.errors?.email"  class="p-error block">{{'MESSAGE.ERROR.EMAIL_INVALID' | translateI18n : '1'}}</small>
            <small *ngIf="f.email1?.errors?.pattern" class="p-error block">{{'MESSAGE.ERROR.REGEX_PATTERN' | translateI18n}}</small>
          </div>
        </div>

        <div class="field col-12 lg:col-6" [ngClass]="{'read-only-form' : f.email2.status === 'DISABLED' }">
          <label for="email2">{{'DOSSIER.DEMANDE.CREATION.EMAIL' | translateI18n : '2'}}<span>
            {{'DOSSIER.DEMANDE.CREATION.OPTIONAL' | translateI18n}}</span></label>
          <div class="p-input-icon-right w-full">
            <i class="pi pi-envelope"></i>
            <input type="text" pInputText formControlName="email2" id="email2" class="inputfield w-full"
                   [ngClass]="{'ng-dirty ng-invalid': (submitted || f.email2.dirty ) && f.email2.errors?.email}" [maxLength]="configSizeInput.MAIL_CONTACT"/>
          </div>

          <small *ngIf="submitted && f.email2?.errors?.pattern" class="p-error block">{{'MESSAGE.ERROR.REGEX_PATTERN' | translateI18n}}</small>
          <small *ngIf="(f.email2.dirty  || submitted) && f.email2.errors?.email" class="p-error block">{{'MESSAGE.ERROR.EMAIL_INVALID' | translateI18n : '2'}}</small>

        </div>

        <div class="field col-12  lg:col-12" formGroupName="adresse">
          <label [ngClass]="{'obligatoire' : f.adresse.status !== 'DISABLED' }">{{'DOSSIER.DEMANDE.CREATION.ADRESSE' | translateI18n}}</label>
          <div class="flex flex-row">
            <div *ngIf="contactForm.value?.adresse.adresseFormate !== null" style="background-color: #ffffff;display: flex;align-items: center;" >
              <span class="p-3">{{contactForm.value?.adresse.adresseFormate}}</span>
            </div>
            <button type="button"  pButton pRipple *ngIf="contactForm.value?.adresse.adresseFormate === null
                                        && contactForm.value.contactType === 'autre' && contactForm.controls.adresse.status !== 'DISABLED'"
                    label="{{'BUTTON_LABEL.ADD' | translateI18n}}" (click)="addAdress()"
                    [disabled] ="!isProfilAuthorize()"></button>
            <button type="button"  pButton pRipple  *ngIf="contactForm.value?.adresse.adresseFormate !== null && contactForm.controls.adresse.status !== 'DISABLED'
                    && (this.demande?.qualiteContact === 'autre' || contactForm.value.contactType === 'autre')"
                    label="{{'BUTTON_LABEL.MODIFY' | translateI18n}}" (click)="addAdress(contactForm?.value?.adresse)"
                    [disabled] ="!isProfilAuthorize()"></button>
          </div>

          <div *ngIf="submitted" class="pt-2">
            <small *ngIf="contactForm.get('adresse.adresseFormate').value === null" class="p-error block">{{'MESSAGE.ERROR.CHAMPS_OBLIGATOIRE' | translateI18n}}</small>
          </div>


        </div>

      </div>

    </fieldset>

  </div>

</form>
