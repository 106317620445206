export enum StatutDossier {
   /* A_AFFECTER= "A affecter",
    ENCOURS = "En cours",
    D_ATTENTE_V1 = "En attente validation V1",
    A_FACTURER = "A facturer",
    F_ENCOURS = "Facturation en cours",
    FACTUREE = "Facturé",
    ANNULE = "Annulé",*/

    A_AFFECTER= "A_AFFECTER",
    ENCOURS = "ENCOURS",
    D_ATTENTE_V1 = "D_ATTENTE_V1",
    A_FACTURER = "A_FACTURER",
    F_ENCOURS = "F_ENCOURS",
    FACTUREE = "FACTUREE",
    ANNULE = "ANNULE",
}
