import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {Paginator} from 'primeng/paginator';
import {AppSettings, Settings} from "../../app.settings";

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss']
})
export class PaginationComponent implements OnInit {


  @Input()
  totalRecords : number = 0;

  @ViewChild('paginator') paginator: Paginator;

  @Output() eventNextPage: EventEmitter<any> = new EventEmitter();

  public settings: Settings;

  @Input()
  label : string;


  constructor(public appSettings : AppSettings) {
    this.settings = this.appSettings.settings;
  }

  ngOnInit(): void {
  }

  nextPage(event : any) {
    this.eventNextPage.emit(event);
  }

}
