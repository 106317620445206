import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ChangePasswordPageComponent} from "../core/change-password-page/change-password-page.component";
import {ChangePasswordComponent} from "./change-password/change-password.component";
import {MessageModule} from "primeng/message";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {HeaderComponent} from "./header/header.component";
import {QuickSerchComponent} from "./quick-serch/quick-serch.component";
import {FileUploadModule} from "primeng/fileupload";
import {ConfirmDialogModule} from "primeng/confirmdialog";
import {TableModule} from "primeng/table";
import {InputTextModule} from "primeng/inputtext";
import {TypeLivrablePipe} from "./pipes/type-livrable.pipe";
import {NavigationComponent} from "./navigation/navigation.component";
import {NavigationContextuelleComponent} from "./navigation-contextuelle/navigation-contextuelle.component";
import {RadioButtonModule} from "primeng/radiobutton";
import {DropdownModule} from "primeng/dropdown";
import {MenuModule} from "primeng/menu";
import {TieredMenuModule} from "primeng/tieredmenu";
import {AutoCompleteModule} from "primeng/autocomplete";
import {ToastModule} from "primeng/toast";
import {TranslatePipe} from './pipes/translate.pipe';
import {GooglePlaceAutocompleteComponent} from './google-place-autocomplete/google-place-autocomplete.component';
import {AdresseComponent} from './adresse/adresse.component';
import {StatutDossierPipe} from './pipes/statut-dossier.pipe';
import {InputClientComponent} from './forms/advanced-search/input-client/input-client.component';
import {TypeDocumentComponent} from './forms/advanced-search/type-document/type-document.component';
import {StatutsDropdownComponent} from './forms/advanced-search/statuts-dropdown/statuts-dropdown.component';
import {PeriodeCalenderComponent} from './forms/advanced-search/periode-calender/periode-calender.component';
import {CalendarModule} from "primeng/calendar";
import {AdresseBienComponent} from './forms/advanced-search/adresse-bien/adresse-bien.component';
import {IconStatutDossierPipe} from './pipes/icon-statut-dossier.pipe';
import {OtherFolderImageComponent} from './other-folder-image/other-folder-image.component';
import {FilterTextComponent} from './table/filter/filter-text/filter-text.component';
import {CdkTableModule} from "@angular/cdk/table";
import {MultiSelectModule} from "primeng/multiselect";
import {ScrollTopModule} from "primeng/scrolltop";
import {UserProfilPipe} from './pipes/user-profil.pipe';
import {FraisExpertisePipe} from './pipes/frais-expertise.pipe';
import {PaginationComponent} from './pagination/pagination.component';
import {PaginatorModule} from "primeng/paginator";
import {PEditorHeaderComponent} from './forms/p-editor-header/p-editor-header.component';
import {DateFormatPipePipe} from './pipes/date-format-pipe.pipe';
import {CaseWordPipe} from './pipes/case-word.pipe';
import {IconDessertePipe} from './pipes/icon-desserte.pipe';
import {DecimalPipe} from "./pipes/decimal.pipe";

@NgModule({
  declarations: [
    ChangePasswordComponent,
    ChangePasswordPageComponent,
    HeaderComponent,
    QuickSerchComponent,
    TypeLivrablePipe,
    NavigationComponent,
    NavigationContextuelleComponent,
    TranslatePipe,
    GooglePlaceAutocompleteComponent,
    AdresseComponent,
    StatutDossierPipe,
    InputClientComponent,
    TypeDocumentComponent,
    StatutsDropdownComponent,
    PeriodeCalenderComponent,
    AdresseBienComponent,
    IconStatutDossierPipe,
    OtherFolderImageComponent,
    FilterTextComponent,
    UserProfilPipe,
    FraisExpertisePipe,
    PaginationComponent,
    PEditorHeaderComponent,
    DateFormatPipePipe,
    CaseWordPipe,
    IconDessertePipe,
    DecimalPipe,
  ],
  exports: [
    ChangePasswordComponent,
    HeaderComponent,
    QuickSerchComponent,
    TypeLivrablePipe,
    NavigationComponent,
    NavigationContextuelleComponent,
    TranslatePipe,
    GooglePlaceAutocompleteComponent,
    AdresseComponent,
    StatutDossierPipe,
    InputClientComponent,
    TypeDocumentComponent,
    StatutsDropdownComponent,
    AdresseBienComponent,
    PeriodeCalenderComponent,
    IconStatutDossierPipe,
    OtherFolderImageComponent,
    FilterTextComponent,
    DecimalPipe,
    UserProfilPipe,
    DateFormatPipePipe,
    FraisExpertisePipe,
    PaginationComponent,
    PEditorHeaderComponent,
    CaseWordPipe,
    IconDessertePipe,
    DecimalPipe,
  ],
    imports: [
        CommonModule,
        MessageModule,
        InputTextModule,
        FormsModule,
        TableModule,
        ReactiveFormsModule,
        FileUploadModule,
        ConfirmDialogModule,
        RadioButtonModule,
        DropdownModule,
        MenuModule,
        TieredMenuModule,
        AutoCompleteModule,
        ToastModule,
        CalendarModule,
        CdkTableModule,
        MultiSelectModule,
        ScrollTopModule,
        PaginatorModule
    ]
})
export class SharedModule { }
