import {Component, Input, OnInit} from '@angular/core';
import {Document} from "../../../../models/document/document.model";
import {DocumentService} from "../../../../service/document.service";
import {UtilsFonction} from "../../../../../shared/providers/utils-fonction";
import {DomSanitizer} from "@angular/platform-browser";
import {ConfirmationService, Message, MessageService} from "primeng/api";
import {TranslateService} from "../../../../../shared/translate/translate.service";
import {NgxSpinnerService} from "ngx-spinner";

@Component({
  selector: 'app-photos-description',
  templateUrl: './photos-description.component.html',
  styleUrls: ['./photos-description.component.scss']
})
export class PhotosDescriptionComponent implements OnInit {

  @Input()
  idBien : number;

  @Input()
  keyMessage : string;

  @Input()
  categorie : string;

  @Input()
  idCategorie : number;

  @Input()
  titre : string;

  @Input()
  idDossier : number;

  documents : Document[];

  message : Message[];

  progressSpinner : boolean = true;


  constructor(
    private documentService : DocumentService,
    private sanitizer: DomSanitizer,
    private confirmation: ConfirmationService,
    private messageService : MessageService,
    private translate : TranslateService,
    private spinnerService: NgxSpinnerService
  ) { }

  ngOnInit(): void {
    this.reloadPictures();
  }

  /**
   * Recupère les images de catégorie envrionnement
   */
  getPhotosExterieur() {
    this.progressSpinner = true;
    this.documentService.findDocumentByIdbienAndCategorie(this.idBien, 3).subscribe( doc => {
      if (doc != null) {
        this.documents = doc;
        this.documents.forEach(d => this.creerLienImage(d));
      }

      this.progressSpinner = false;

    });
  }

  getPhotosInterieur() {
    this.progressSpinner = true;
    this.documentService.findDocumentByIdbienAndCategorie(this.idBien, 4).subscribe( doc => {
      if (doc != null) {
        this.documents = doc;
        this.documents.forEach(d => this.creerLienImage(d));
      }
      this.progressSpinner = false;
    });
  }

  /**
   * Permet de lire le byte en image
   * @param doc
   */
  creerLienImage(doc: Document) {
    if (doc.contenu) {
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        doc.lienImage = this.sanitizer.bypassSecurityTrustUrl(reader.result as string);
      }, false);
      reader.readAsDataURL(UtilsFonction.b64toBlob(doc.contenu, doc.type) );
    }
  }

  addDocument(documents: Document[]): void {
    this.reloadPictures();
  }

  deletePicture(doc : Document) {
    this.confirmation.confirm({
      message: this.translate.getElement("DOCUMENT.SUPPRESSION.CONFIRMATION"),
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.spinnerService.show();
        if(doc?.guid) {
          this.documentService.deleteDossierRapportDocument(this.idBien, doc?.guid, this.idDossier).subscribe(data => {
            if (data) {
              this.messageService.add({key: this.keyMessage, severity: 'success', summary: this.translate.getElement("DOCUMENT.SUPPRESSION.SUCCESS")});
              this.reloadPictures();
            } else {
              this.messageService.add({key: this.keyMessage, severity: 'error', summary: this.translate.getElement("DOCUMENT.SUPPRESSION.ERROR")});
            }
            this.spinnerService.hide();
          }, error => {
            this.messageService.add({key: this.keyMessage, severity: 'error', summary: this.translate.getElement("DOCUMENT.SUPPRESSION.ERROR")});
            this.spinnerService.hide();
          })
        }

      }
    })
  }

  reloadPictures() {
    if(this.idCategorie === 4) {
      this.getPhotosInterieur();
    }

    if(this.idCategorie === 3) {
      this.getPhotosExterieur();
    }
  }
}
