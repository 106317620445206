import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'statutDossier'
})
export class StatutDossierPipe implements PipeTransform {

  transform(value: string, ...args: unknown[]): unknown {

    switch (value) {
      case 'A_AFFECTER' :
        return 'A affecter';
      case 'ENCOURS' :
        return 'En cours';
      case 'D_ATTENTE_V1' :
        return 'En attente validation v1';
      case 'A_FACTURER' :
        return 'A facturer';
      case 'F_ENCOURS' :
        return 'Facturation en cours';
      case 'FACTUREE' :
        return 'Facturé';
      case 'ANNULE' :
        return 'Annulé';
      default :
        return;
    }
  }

}
