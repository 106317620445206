<div *ngIf="userSignataires">


  <h2>{{'DOSSIER.GESTIONNAIRE.MESSAGE.VALIDER_LIVRABLE.TITLE_CHOOSE_SIGNATAIRE' | translateI18n }} :</h2>
  <p-table [value]="userSignataires" [(selection)]="userSignataire" dataKey="id" responsiveLayout="scroll">
    <ng-template pTemplate="header">
      <tr>
        <th style="width: 3rem"></th>
        <th>{{'DOSSIER.GESTIONNAIRE.MESSAGE.VALIDER_LIVRABLE.PRENOM' | translateI18n }}</th>
        <th>{{'DOSSIER.GESTIONNAIRE.MESSAGE.VALIDER_LIVRABLE.NOM' | translateI18n }}</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-signataire>
      <tr>
        <td>
          <p-tableRadioButton [value]="signataire"></p-tableRadioButton>
        </td>
        <td>{{signataire.prenom}}</td>
        <td>{{signataire.nom}}</td>
      </tr>
    </ng-template>
  </p-table>

  <div class="mt-3">
    <h3>{{'RAPPORT_EXPERTISE.POPUP_CONFIRMATION_ENVOYER_HEADER' | translateI18n}}</h3>
    <p>
      <span><i class="pi pi-exclamation-triangle" style="font-size: 1.5rem"></i></span>
      {{'DOSSIER.GESTIONNAIRE.MESSAGE.VALIDER_LIVRABLE.DIALOG' | translateI18n}}
    </p>
  </div>

  <div class="p-dialog-footer">

    <button pButton type="button" pRipple label="{{'DOSSIER.CONFIRMATION_DIALOG.REJET_BUTTON' | translateI18n}}" icon="pi pi-times"
            class="uppercase p-button-secondary p-button-outlined mb-2" (click)="close()"></button>
    <button pButton type="submit" pRipple label="{{'DOSSIER.CONFIRMATION_DIALOG.ACCEPT_BUTTON' | translateI18n}}"
            icon="pi pi-check" class="ppercase mb-2" (click)="validerLivrable()"></button>
  </div>
</div>



