import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ErroLinkChangementPasswordExpiredComponent} from "./erro-link-changement-password-expired/erro-link-changement-password-expired.component";
import {DeconnexionComponent} from "./deconnexion/deconnexion.component";
import {MessagesModule} from "primeng/messages";
import {MessageModule} from "primeng/message";
import {RouterModule} from "@angular/router";
import {MenuModule} from "primeng/menu";
import {TieredMenuModule} from "primeng/tieredmenu";
import {StepsNavComponent} from "./steps-nav/steps-nav.component";
import {ListeDocumentComponent} from "./liste-document/liste-document.component";
import {UploadDocumentComponent} from "./upload-document/upload-document.component";
import {TableModule} from "primeng/table";
import {ConfirmDialogModule} from "primeng/confirmdialog";
import {FileUploadModule} from "primeng/fileupload";
import {EditDossierAffectationComponent} from './dialog/edit-dossier-affectation/edit-dossier-affectation.component';
import {DialogModule} from "primeng/dialog";
import {InputTextModule} from "primeng/inputtext";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {SharedModule} from "../shared/shared.module";
import {DropdownModule} from "primeng/dropdown";
import {AutoCompleteModule} from "primeng/autocomplete";
import {RechercheAvanceeDossierComponent} from './dialog/recherche-avancee-dossier/recherche-avancee-dossier.component';
import {RadioButtonModule} from "primeng/radiobutton";
import {AffectationRechercheAvanceeExpertComponent} from './dialog/affectation-recherche-avancee-expert/affectation-recherche-avancee-expert.component';
import {CalendarModule} from "primeng/calendar";
import {ClientComponent} from './forms/creation/client/client.component';
import {ContactComponent} from './forms/creation/contact/contact.component';
import {BienComponent} from './forms/creation/bien/bien.component';
import {RippleModule} from "primeng/ripple";
import {CarteGoogleMapsComponent} from './carte-google-maps/carte-google-maps.component';
import {GMapModule} from "primeng/gmap";
import {DemandeurComponent} from './dialog/recherche-avancee-dossier/demandeur/demandeur.component';
import {GestionnaireComponent} from './dialog/recherche-avancee-dossier/gestionnaire/gestionnaire.component';
import {ExpertComponent} from './dialog/recherche-avancee-dossier/expert/expert.component';
import {ClientDossierComponent} from "./forms/fiche-dossier/client-dossier/client-dossier.component";
import {ExpertDossierComponent} from "./forms/fiche-dossier/expert-dossier/expert-dossier.component";
import {ContactDossierComponent} from './forms/fiche-dossier/contact-dossier/contact-dossier.component';
import {BienDossierComponent} from './forms/fiche-dossier/bien-dossier/bien-dossier.component';
import {ResultatExpertiseComponent} from './forms/fiche-dossier/resultat-expertise/resultat-expertise.component';
import {HonorairesComponent} from './forms/fiche-dossier/honoraires/honoraires.component';
import {ProgressSpinnerModule} from "primeng/progressspinner";
import {DividerModule} from "primeng/divider";
import {InputNumberModule} from "primeng/inputnumber";
import {PopUpHonorairesComponent} from './dialog/pop-up-honoraires/pop-up-honoraires.component';
import {PopUpDateComponent} from './dialog/pop-up-date/pop-up-date.component';
import {EditorModule} from "primeng/editor";
import {AideComponent} from './aide/aide.component';
import {MissionComponent} from './forms/avis-valeur/mission/mission.component';
import {DescriptionComponent} from './forms/avis-valeur/description/description.component';
import {ElementsAppreciationComponent} from './forms/avis-valeur/elements-appreciation/elements-appreciation.component';
import {SituationLocativeComponent} from './forms/avis-valeur/situation-locative/situation-locative.component';
import {EvaluationComponent} from './forms/avis-valeur/evaluation/evaluation.component';
import {RoleDeniedComponent} from './not-found/role-denied/role-denied.component';
import {SituationGeographiqueComponent} from './forms/avis-valeur/situation-geographique/situation-geographique.component';
import {HelpNavigationComponent} from './help-navigation/help-navigation.component';
import {DesignationComponent} from "./forms/avis-valeur/designation/designation.component";
import {SyntheseMissionComponent} from './forms/rapport-expertise/synthese-mission/synthese-mission.component';
import {MissionRapportComponent} from './forms/rapport-expertise/mission-rapport/mission-rapport.component';
import {UploadPhotoComponent} from './upload-photo/upload-photo.component';
import {DesignationCadastreComponent} from './forms/rapport-expertise/designation-cadastre/designation-cadastre.component';
import {InputTextareaModule} from "primeng/inputtextarea";
import {ToolbarModule} from "primeng/toolbar";
import {SituationGeographiqueRapportComponent} from "./forms/rapport-expertise/situation-geographique/situation-geographique-rapport.component";
import {InplaceModule} from "primeng/inplace";
import {DescriptionRapportComponent} from './forms/rapport-expertise/description-rapport/description-rapport.component';
import {EnvironnementUrbanismeComponent} from './forms/rapport-expertise/environnement-urbanisme/environnement-urbanisme.component';
import {ConditonOccupationComponent} from './forms/rapport-expertise/conditon-occupation/conditon-occupation.component';
import {ConclusionRapportComponent} from './forms/rapport-expertise/conclusion-rapport/conclusion-rapport.component';
import {EstrimationRapportComponent} from './forms/rapport-expertise/estrimation-rapport/estrimation-rapport.component';
import {EtudeMarcheComponent} from './forms/rapport-expertise/etude-marche/etude-marche.component';
import {TableAmenagementsInterieursComponent} from './forms/rapport-expertise/description-rapport/table-amenagements-interieurs/table-amenagements-interieurs.component';
import {TableConstructionComponent} from './forms/rapport-expertise/description-rapport/table-construction/table-construction.component';
import {PhotosDescriptionComponent} from "./forms/rapport-expertise/description-rapport/photos-description/photos-description.component";
import {TableSurfacesComponent} from './forms/rapport-expertise/description-rapport/table-surfaces/table-surfaces.component';
import {TableAnnexesBienComponent} from './forms/rapport-expertise/description-rapport/table-annexes-bien/table-annexes-bien.component';
import {TableReferenceLocativeComponent} from './forms/rapport-expertise/etude-marche/table-reference-locative/table-reference-locative.component';
import {TableReferenceVenalesComponent} from "./forms/rapport-expertise/etude-marche/table-reference-venales/table-reference-venales.component";
import {CommentairePhotoComponent} from './forms/rapport-expertise/commentaire-photo/commentaire-photo.component';
import {ValidationLivrableComponent} from './dialog/validation-livrable/validation-livrable.component';
import {NgxSpinnerModule} from "ngx-spinner";
import { RejetLivrableComponent } from './dialog/rejet-livrable/rejet-livrable.component';
import { DuplicationDossierComponent } from './dialog/duplication-dossier/duplication-dossier.component';


@NgModule({
  declarations: [
    ErroLinkChangementPasswordExpiredComponent,
    DeconnexionComponent,
    StepsNavComponent,
    ListeDocumentComponent,
    UploadDocumentComponent,
    EditDossierAffectationComponent,
    RechercheAvanceeDossierComponent,
    AffectationRechercheAvanceeExpertComponent,
    ClientComponent,
    ContactComponent,
    BienComponent,
    CarteGoogleMapsComponent,
    DemandeurComponent,
    GestionnaireComponent,
    ExpertComponent,
    ClientDossierComponent,
    ExpertDossierComponent,
    ContactDossierComponent,
    BienDossierComponent,
    ResultatExpertiseComponent,
    HonorairesComponent,
    PopUpHonorairesComponent,
    PopUpDateComponent,
    AideComponent,
    MissionComponent,
    DesignationComponent,
    DescriptionComponent,
    ElementsAppreciationComponent,
    SituationLocativeComponent,
    EvaluationComponent,
    RoleDeniedComponent,
    SituationGeographiqueComponent,
    HelpNavigationComponent,
    SyntheseMissionComponent,
    MissionRapportComponent,
    UploadPhotoComponent,
    DesignationCadastreComponent,
    SituationGeographiqueRapportComponent,
    DescriptionRapportComponent,
    EnvironnementUrbanismeComponent,
    ConditonOccupationComponent,
    ConclusionRapportComponent,
    EstrimationRapportComponent,
    EtudeMarcheComponent,
    PhotosDescriptionComponent,
    TableAmenagementsInterieursComponent,
    TableConstructionComponent,
    TableSurfacesComponent,
    TableAnnexesBienComponent,
    TableReferenceLocativeComponent,
    TableReferenceVenalesComponent,
    CommentairePhotoComponent,
    ValidationLivrableComponent,
    RejetLivrableComponent,
    DuplicationDossierComponent
  ],
    exports: [
        StepsNavComponent,
        ListeDocumentComponent,
        UploadDocumentComponent,
        ClientComponent,
        ContactComponent,
        BienComponent,
        CarteGoogleMapsComponent,
        ClientDossierComponent,
        ExpertDossierComponent,
        ContactDossierComponent,
        BienDossierComponent,
        HonorairesComponent,
        ResultatExpertiseComponent,
        MissionComponent,
        DesignationComponent,
        SituationLocativeComponent,
        ElementsAppreciationComponent,
        DescriptionComponent,
        EvaluationComponent,
        SituationGeographiqueComponent,
        SyntheseMissionComponent,
        MissionRapportComponent,
        UploadPhotoComponent,
        DesignationCadastreComponent,
        SituationGeographiqueRapportComponent,
        DescriptionRapportComponent,
        EnvironnementUrbanismeComponent,
        ConditonOccupationComponent,
        EtudeMarcheComponent,
        EstrimationRapportComponent,
        ConclusionRapportComponent,
        ValidationLivrableComponent,
        DuplicationDossierComponent
    ],
    imports: [
        CommonModule,
        RouterModule,
        MessagesModule,
        MessageModule,
        MenuModule,
        TieredMenuModule,
        TableModule,
        ConfirmDialogModule,
        FileUploadModule,
        DialogModule,
        InputTextModule,
        InputTextModule,
        FormsModule,
        TableModule,
        ReactiveFormsModule,
        SharedModule,
        DropdownModule,
        AutoCompleteModule,
        RadioButtonModule,
        CalendarModule,
        RippleModule,
        GMapModule,
        ProgressSpinnerModule,
        DividerModule,
        InputNumberModule,
        EditorModule,
        InputTextareaModule,
        ToolbarModule,
        InplaceModule,
        NgxSpinnerModule,
    ]
})

export class CoreModule { }
