<h2 >{{'DOSSIER.ADVANCED_SEARCH.LABEL.TYPE_DOC' | translateI18n}}</h2>
<div class="grid formgrid" [formGroup]="form">
  <div class="field col-12" [formGroup]="form">
    <div class="p-radiobutton-group inline-group">
      <p-radioButton name="codeTypeLivrable" value="TVRAPPORTXX" formControlName="codeTypeLivrable"
                     label="{{'DOSSIER.ADVANCED_SEARCH.LABEL.RAPPORT' | translateI18n}}">
      </p-radioButton>
      <p-radioButton name="codeTypeLivrable" value="TVAVISDOSSI" formControlName="codeTypeLivrable"
                     label="{{'DOSSIER.ADVANCED_SEARCH.LABEL.AVIS' | translateI18n}}">
      </p-radioButton>
    </div>
  </div>
</div>
