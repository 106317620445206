import {ReferentielValeur} from "../referentiel-valeur";

export class BienReferenceLocative {
  id : number;
  typeBien : ReferentielValeur;
  adresse : string;
  loyer : number;
  periodiciteLoyer : any;
  surface : number;
  ratio : number;
  annexe : string;
  datetransac : Date;
  observations : string;
  idDossier : number;
}
