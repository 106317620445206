import {ReferentielValeur} from "./referentiel-valeur";

export class Adresse {
  id : number;
  nomVoie : string;
  codePostal : string;
  ville : string;
  numero : number;
  adresseFormate : string;
  lieuDit: string;
  complement : string;
  pays : Pays;
  region : ReferentielValeur;
  departement : Departement;
  lat : number;
  lng : number;
  boitePostale : string;
  adresseChange : boolean;

  constructor(fields?: Partial<Adresse>) {
    if (fields) {
      Object.assign(this, fields);
    }
  }

}

export class Pays {
  id : number;
  statut : string;
  libelle : string;
  devise : string;
  libelleAnglais : string;
  codeIso2 : string;
  codeIso3 : string;
  tdr : string;
  libelleTdr : string;
  isBureau : boolean;

}

export class Departement {
  id : number;
  numero : string;
  code : ReferentielValeur;
  region : ReferentielValeur;
  secteurIntervention : ReferentielValeur;
  auditCreateur : number;
  auditDateCreation : Date;
  auditModifieur : number;
  auditDateModification : Date;
}

