<form  [formGroup]="form">

  <app-type-document formGroupName="typeDoc"></app-type-document>

  <app-statuts-dropdown formGroupName="statut" [statuts]="statuts"></app-statuts-dropdown>


  <fieldset class="grid formgrid">

    <div class="field col field-line">
      <h2>Expert</h2>
      <p-autoComplete formControlName="expert" [showEmptyMessage]="true" name="expert"
                      [suggestions]="filteredExpert"
                      (completeMethod)="searchExpertUser($event)"
                      emptyMessage="Aucun résultat"
                      [minLength]="1"
                      field="nomComplet"
                      class="inputfield w-full" [size]="30">
        <ng-template let-expert pTemplate="item">
          <span>{{ expert.nomComplet}}</span>
        </ng-template>
      </p-autoComplete>

    </div>

    <div class="field col field-line">
      <h2>Tiers</h2>
      <p-autoComplete formControlName="tiers" [showEmptyMessage]="true" name="tiers"
                      [suggestions]="filteredtiers"
                      (completeMethod)="searchTiers($event)"
                      emptyMessage="Aucun résultat"
                      [minLength]="1"
                      field="nomComplet"
                      class="inputfield w-full" [size]="30">
        <ng-template let-expert pTemplate="item">
          <span>{{ expert.nomComplet}}</span>
        </ng-template>
      </p-autoComplete>

    </div>
  </fieldset>
  <app-periode-calender formGroupName="periode"></app-periode-calender>

  <app-adresse-bien formGroupName="adresseBien"></app-adresse-bien>

  <app-input-client formGroupName="client"></app-input-client>


</form>

