import {Injectable} from '@angular/core';
import {DatePipe} from "@angular/common";
import {User} from "../models/user/user.model";

@Injectable({
  providedIn: 'root'
})
export class UsefulFunctionService {

  constructor(private datePipe : DatePipe) { }

  formatterDateTime(date : any)  {
      if(date !== undefined && date !== null) {
      const dateStr = this.datePipe.transform(date, 'd MMMM y HH:mm');

      let month = [
        {en : 'January', fr :'Janvier' },
        {en : 'February', fr :'Fevrier' },
        {en : 'March', fr :'Mars' },
        {en : 'April', fr :'Avril' },
        {en : 'May', fr :'Mai' },
        {en : 'June', fr :'Juin' },
        {en : 'July', fr :'Juillet' },
        {en : 'August', fr :'Août' },
        {en : 'September', fr :'Septembre' },
        {en : 'October', fr :'Octobre' },
        {en : 'November', fr :'Novembre' },
        {en : 'December', fr :'Decembre' }
      ]

      let tab = dateStr.split(' ');

      const result = month.filter(value =>  value.en.toLocaleLowerCase() === tab[1].toLocaleLowerCase())

      return tab[0] + ' ' + result[0]['fr'].toLocaleString().toLocaleLowerCase() + ' ' +tab[2] + ' ' + tab[3];
    }
    return '';
  }

  format_tel_portable(user : User) {
    if(user) {
      return user.portable && user.telephone
          ?  user.portable.concat(" / ").concat(user.telephone) :user.portable &&  !user.telephone
            ? user.portable : user.telephone && !user.portable ? user.telephone : '--';
    }
    return '';
  }
}
