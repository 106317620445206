<form  [formGroup]="form">

  <h2>{{titre}}</h2>
  <!-- Fieldsets pour structurer des groupes de champs -->
  <fieldset id="client" class="grid formgrid">

    <!-- col-12 pour une colonne sur toute la largeur -->
    <div class="field col-12 field-line">

      <!-- .p-radiobutton-group regroupement .inline-group pour afficher les radiobutton en ligne -->
      <div class="p-radiobutton-group inline-group">
        <p-radioButton  value="human" formControlName="customerType"
                        [ngClass]="submitted && form.get('customerType').value === null ? 'radioErrorColor':null"
                        label="{{'DOSSIER.DEMANDE.CREATION.PHYSICAL_PERSON' | translateI18n}}" (onClick)="choiceTypeClient()">
        </p-radioButton>
        <p-radioButton value="company" formControlName="customerType"
                       [ngClass]="submitted && form.get('customerType').value === null ? 'radioErrorColor':null"
                       label="{{'DOSSIER.DEMANDE.CREATION.CORPORATION' | translateI18n}}" (onClick)="choiceTypeClient()"></p-radioButton>
      </div>

      <div *ngIf="submitted && form.get('customerType').value === null" class="pt-2">
        <small  class="p-error block">{{'MESSAGE.ERROR.CHAMPS_OBLIGATOIRE' | translateI18n}}</small>
      </div>
    </div>



    <div class="field col-12 field-line" *ngIf="form.value.customerType === 'human'">
      <div class="p-dropdown-group">
        <label for="genders" class="obligatoire" >
          {{'DOSSIER.DEMANDE.CREATION.CIVILITY' | translateI18n}}</label>
        <p-dropdown [options]="civilites"  formControlName="gender" optionLabel="abreviation" id="genders"
                    [ngClass]="{'ng-dirty ng-invalid': submitted && form.get('gender').value === null}"
                    placeholder="{{'PLACEHOLDER.CHOOSE' | translateI18n}}"></p-dropdown>

      </div>
      <div *ngIf="submitted && form.get('gender').value === null" class="pt-2">
        <small class="p-error block">{{'MESSAGE.ERROR.CHAMPS_OBLIGATOIRE' | translateI18n}}</small>
      </div>

    </div>

    <!-- Structurer le formulaire ligne par ligne pour préserver l'accessiblité et la tabulation champs à champs -->
    <!-- Ligne formulaire -->
    <div class="col-12 grid field-line  mt-2" *ngIf="form.value.customerType === 'human'">
      <div class="field col">
        <label for="name" class="obligatoire">{{'DOSSIER.DEMANDE.CREATION.NOM' | translateI18n}}</label>
        <div class="p-input-icon-right w-full">
          <i class="pi pi-user"></i>
          <input type="text" pInputText formControlName="nomClient" id="name"
                 class="inputfield w-full"
                 [ngClass]="{'ng-dirty ng-invalid': submitted && (form.value.nomClient === null || form.value.nomClient.length === 0)}"
                 [maxLength]="configSizeInput.NOM_CLIENT"/>
        </div>

        <div *ngIf="submitted" class="pt-2">
          <small *ngIf="f.nomClient?.errors?.pattern" class="p-error block">{{'MESSAGE.ERROR.REGEX_PATTERN' | translateI18n}}</small>
          <small  *ngIf="form.value.nomClient === null || form.value.nomClient.length === 0" id="form-title" class="p-error block">
            {{'MESSAGE.ERROR.CHAMPS_OBLIGATOIRE' | translateI18n}}</small>
        </div>
      </div>

      <div class="field col">
        <label for="prenom" class="obligatoire">{{'DOSSIER.DEMANDE.CREATION.PRENOM' | translateI18n}}</label>
        <div class="p-input-icon-right w-full">
          <i class="pi pi-user"></i>
          <input type="text" pInputText formControlName="prenomClient" id="prenom" class="inputfield w-full"
                 [ngClass]="{'ng-dirty ng-invalid': submitted && (form.value.prenomClient === null || form.value.prenomClient.length === 0)}"
                 [maxLength]="configSizeInput.PRENOM_CLIENT"/>
        </div>

        <div *ngIf="submitted" class="pt-2">
          <small *ngIf="f.prenomClient?.errors?.pattern" class="p-error block">{{'MESSAGE.ERROR.REGEX_PATTERN' | translateI18n}}</small>
          <small *ngIf="form.value.prenomClient === null || form.value.prenomClient.length === 0" class="p-error block">
            {{'MESSAGE.ERROR.CHAMPS_OBLIGATOIRE' | translateI18n}}</small>
        </div>

      </div>
    </div>



    <div class="col-12 grid field-line mt-2" *ngIf="form.value.customerType === 'company'">
      <div class="field col">
        <label for="nommoral" class="obligatoire">{{'DOSSIER.DEMANDE.CREATION.NOM_MORAL' | translateI18n}}</label>
        <div class="p-input-icon-right w-full">
          <i class="pi pi-user"></i>
          <input type="text" pInputText formControlName="nomMoral" id="nommoral" class="inputfield w-full"
                 [ngClass]="{'ng-dirty ng-invalid': submitted && (form.value.nomMoral === null || form.value.nomMoral.length === 0)}"
                 [maxLength]="configSizeInput.NOM_MOARAL_CLIENT"/>
        </div>

        <div *ngIf="submitted" class="pt-2">
          <small *ngIf="f.nomMoral?.errors?.pattern" class="p-error block">{{'MESSAGE.ERROR.REGEX_PATTERN' | translateI18n}}</small>
          <small *ngIf="form.value.nomMoral === null || form.value.nomMoral.length === 0" class="p-error block">
            {{'MESSAGE.ERROR.CHAMPS_OBLIGATOIRE' | translateI18n}}</small>
        </div>
      </div>

      <div class="field col" >
        <label for="nomrepresentant" class="obligatoire">
          {{'DOSSIER.DEMANDE.CREATION.REPRESENTANT_LEGALE' | translateI18n}}</label>
        <div class="p-input-icon-right w-full">
          <i class="pi pi-user"></i>
          <input type="text" pInputText formControlName="nomRepresentant" id="nomrepresentant" class="inputfield w-full"
                 [ngClass]="{'ng-dirty ng-invalid': submitted && (form.value.nomRepresentant === null || form.value.nomRepresentant.length === 0)}"
                 [maxLength]="configSizeInput.REPRESENTANT_CLIENT"/>
        </div>

        <div *ngIf="submitted" class="pt-2">
          <small *ngIf="f.nomRepresentant?.errors?.pattern" class="p-error block">{{'MESSAGE.ERROR.REGEX_PATTERN' | translateI18n}}</small>
          <small *ngIf="form.value.nomRepresentant === null || form.value.nomRepresentant.length === 0" class="p-error block">
            {{'MESSAGE.ERROR.CHAMPS_OBLIGATOIRE' | translateI18n}}</small>
        </div>

      </div>
    </div>


    <!-- Ligne formulaire -->
    <div class="col-12 grid field-line">

      <div class="field col">
        <label for="phone1" >
          {{'DOSSIER.DEMANDE.CREATION.PHONE' | translateI18n : '1'}} <span> {{'DOSSIER.DEMANDE.CREATION.OPTIONAL' | translateI18n}}</span></label>
        <div class="p-input-icon-right w-full">
          <i class="pi pi-phone"></i>
          <input type="text" pInputText formControlName="phone1" id="phone1" class="inputfield w-full"
                 [ngClass]="{'ng-dirty ng-invalid': submitted && f.phone1?.errors?.pattern}"
                 [maxLength]="configSizeInput.TELEPHONE_CLIENT" />
        </div>


        <small *ngIf="submitted && f.phone1?.errors?.pattern" class="p-error block">{{'MESSAGE.ERROR.REGEX_PATTERN' | translateI18n}}</small>


      </div>

      <div class="field col">
        <label for="phone2">{{'DOSSIER.DEMANDE.CREATION.PHONE' | translateI18n : '2'}} <span> {{'DOSSIER.DEMANDE.CREATION.OPTIONAL' | translateI18n}}</span></label>
        <div class="p-input-icon-right w-full">
          <i class="pi pi-phone"></i>
          <input type="text" pInputText formControlName="phone2" id="phone2" class="inputfield w-full"
                 [maxLength]="configSizeInput.TELEPHONE_CLIENT"/>
        </div>

        <small *ngIf="f.phone2?.errors?.pattern" class="p-error block">{{'MESSAGE.ERROR.REGEX_PATTERN' | translateI18n}}</small>
      </div>
    </div>

    <!-- Ligne formulaire -->
    <div class="col-12 grid field-line">
      <div class="field col">
        <label for="email1" >{{'DOSSIER.DEMANDE.CREATION.EMAIL' | translateI18n : '1'}} <span> {{'DOSSIER.DEMANDE.CREATION.OPTIONAL' | translateI18n}}</span></label>
        <div class="p-input-icon-right w-full">
          <i class="pi pi-envelope"></i>
          <input type="email" pInputText formControlName="email1" id="email1" class="inputfield w-full"
                 [ngClass]="{'ng-dirty ng-invalid': submitted && ( f.email1.errors?.email || f.email1?.errors?.pattern)}"
                 [maxLength]="configSizeInput.MAIL_CLIENT"/>
        </div>

        <div *ngIf="f.email1.errors" class="pt-2">
          <small *ngIf="(f.email1.touched || submitted) && f.email1.errors?.email"  class="p-error block">{{'MESSAGE.ERROR.EMAIL_INVALID' | translateI18n : '1'}}</small>
          <small *ngIf="f.email1?.errors?.pattern" class="p-error block">{{'MESSAGE.ERROR.REGEX_PATTERN' | translateI18n}}</small>
        </div>

      </div>

      <div class="field col">
        <label for="email2">{{'DOSSIER.DEMANDE.CREATION.EMAIL' | translateI18n : '2'}}<span> {{'DOSSIER.DEMANDE.CREATION.OPTIONAL' | translateI18n}}</span></label>
        <div class="p-input-icon-right w-full">
          <i class="pi pi-envelope"></i>
          <input type="text" pInputText formControlName="email2" id="email2" class="inputfield w-full"
                 [maxLength]="configSizeInput.MAIL_CLIENT"/>
        </div>
        <small *ngIf="f.email2?.errors?.pattern" class="p-error block">{{'MESSAGE.ERROR.REGEX_PATTERN' | translateI18n}}</small>
      </div>
    </div>

    <div class="col field field-line" formGroupName="adresse">
      <label class="obligatoire">{{'DOSSIER.DEMANDE.CREATION.ADRESSE' | translateI18n}}</label>
      <div class="flex flex-row">
        <div style="background-color: #efefef;display: flex;align-items: center;" *ngIf="form.get('adresse.adresseFormate').value !== null">
          <span class="p-3" >{{form.get('adresse.adresseFormate').value}}</span>
        </div>

        <button type="button"  pButton pRipple  *ngIf="form.get('adresse.adresseFormate').value === null"
                label="{{'BUTTON_LABEL.ADD' | translateI18n}}"
                (click)="addAdress()"></button>

        <button type="button"  pButton pRipple  *ngIf="form.get('adresse.adresseFormate').value !== null"
                label="{{'BUTTON_LABEL.MODIFY' | translateI18n}}"
                (click)="addAdress()"></button>
      </div>

      <div *ngIf="submitted" class="pt-2">
        <small *ngIf="form.get('adresse.adresseFormate').value === null" class="p-error block">{{'MESSAGE.ERROR.CHAMPS_OBLIGATOIRE' | translateI18n}}</small>
      </div>

    </div>



    <div class="col-12 grid field field-line" >
      <div class="field col-6 mt-3">
        <label for="noc" [ngClass]="{'obligatoire' : user?.agence?.entiteClient !== 'BNPPARIBAS'}">{{'DOSSIER.DEMANDE.CREATION.CONTRAT' | translateI18n}}</label>
        <div class="p-input-icon-right w-full">
          <input type="text" pInputText formControlName="numContrat" id="noc" class="inputfield w-full"
                 [ngClass]="{'ng-dirty ng-invalid': submitted && f.numContrat.errors}" [maxLength]="configSizeInput.NUMERO_CONTRAT"/>
        </div>

        <div *ngIf="f.numContrat.errors" class="pt-2">
          <small *ngIf="(f.numContrat.touched || submitted) && f.numContrat.errors.minlength" class="p-error block">{{'DOSSIER.DEMANDE.CREATION.MESSAGE.ERROR_CONTRAT_INVALID' | translateI18n}}</small>
          <small *ngIf="submitted && f.numContrat.errors.required" class="p-error block">{{'MESSAGE.ERROR.CHAMPS_OBLIGATOIRE' | translateI18n}}</small>
          <small *ngIf="f.numContrat?.errors?.pattern" class="p-error block">{{'MESSAGE.ERROR.REGEX_PATTERN' | translateI18n}}</small>
        </div>

      </div>

      <div class="field col-6 mt-3" *ngIf="user?.agence?.entiteClient === 'UCB'
        || user?.agence?.entiteClient === 'UCBE' || user?.agence?.entiteClient === 'Service Expertise UCB Suisse'
        || user?.agence?.entiteClient === 'DRE IMMO AFFAIRES SPECIALES'">
        <label for="fraisExp" class="obligatoire">{{'DOSSIER.DEMANDE.CREATION.FRAIS_EXPERTISE' | translateI18n}}</label>
        <div class="p-input-icon-right w-full">
          <p-dropdown [options]="fraisExp" placeholder="{{'PLACEHOLDER.CHOOSE' | translateI18n}}" id="fraisExp" formControlName="fraisExp" name="fraisExp"
                      optionLabel="name" [ngClass]="{'ng-dirty ng-invalid': submitted && f.fraisExp.errors}"></p-dropdown>
        </div>

        <div *ngIf="submitted && f.fraisExp.invalid" class="pt-2">
          <small *ngIf="f.fraisExp.errors.required" class="p-error block">{{'MESSAGE.ERROR.CHAMPS_OBLIGATOIRE' | translateI18n}}</small>
        </div>
      </div>
    </div>


  </fieldset>


</form>
