import {Component, OnInit} from '@angular/core';
import {MessageService} from 'primeng/api';
import {DynamicDialogConfig, DynamicDialogRef} from 'primeng/dynamicdialog';
import {Dossier} from 'src/app/core/models/dossiers/dossier';
import {TranslateService} from 'src/app/shared/translate/translate.service';
import {ChangementRapide} from '../../models/dossiers/changement-rapide';
import {ConfigConstants} from '../../models/enum/config-constants';
import {DossierService} from '../../service/dossier.service';
import {NgxSpinnerService} from "ngx-spinner";
import {Validation} from "../../../shared/providers/validation";

@Component({
  selector: 'app-pop-up-date-dossier',
  templateUrl: './pop-up-date.component.html',
  styleUrls: ['./pop-up-date.component.scss']
})
export class PopUpDateComponent implements OnInit {

  dossier: Dossier;
  payload: ChangementRapide;
  typeDate: string;

  submitted = false;

  commentaireCounter : number = 0;

  constructor(
    public service: DossierService,
    private message: MessageService,
    private translate : TranslateService,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private spinnerService: NgxSpinnerService
  ) { }

  ngOnInit(): void {
    this.dossier = this.config.data.dossier;
    this.typeDate = this.config.data.type;
    this.payload = new ChangementRapide();
    this.payload.idDossier = this.dossier.idDossier;


    if(ConfigConstants.DATE_CONTACT === this.typeDate) {
      if (this.dossier.dateContactDossier != null) {
        this.payload.date = new Date(this.dossier.dateContactDossier);
      }

      if (this.dossier?.commentcontactRapportDossier != null) {
        this.payload.commentaire = this.dossier.commentcontactRapportDossier;
        this.commentaireCounter = this.dossier?.commentcontactRapportDossier?.replace(/<[^>]*>/g, '')?.length;
      }

    } else if(ConfigConstants.DATE_VISITE === this.typeDate) {

      if (this.dossier.dateVisite != null) {
        this.payload.date = new Date(this.dossier.dateVisite);
      }

      if (this.dossier?.commentviRapportDossier != null) {
        this.payload.commentaire = this.dossier.commentviRapportDossier;
        this.commentaireCounter = this.dossier?.commentviRapportDossier?.replace(/<[^>]*>/g, '')?.length;
      }
    }


  }

  /**
   * Modification de la date de visite/contact
   */
  modifierDate() {

    this.spinnerService.show();

    //Remplace les codes html trouvés dans le commentaire par une chaine vide
    let plainText = this.payload?.commentaire?.replace(/<[^>]*>/g, '');

    if(plainText && plainText?.trim() === "") {
      this.payload.commentaire = null;
    }

    if(this.payload?.commentaire && plainText.length > 400) {
      this.message.add({severity: 'error',
        summary: this.translate.getElement("DOSSIER.POPUP.DATE.MESSAGE_ERROR_CARACTERE_COMMENTAIRE")
      });
      this.spinnerService.hide();
      return;
    }

    this.message.clear();
    this.submitted = true;

    // Recrée la date pour éviter les problèmes de fuseau horaires
    if(this.payload.date) {
      this.payload.date = new Date(Date.UTC(this.payload.date.getFullYear(), this.payload.date.getMonth(), this.payload.date.getDate()));
    }

    if(Validation.validatoionEmptyCaracterFielTextWithShaping(this.payload.commentaire)) {
      this.payload.commentaire = null;
    }

    if (this.typeDate === ConfigConstants.DATE_CONTACT) {
      this.service.modifierDateContact(this.payload).subscribe(data => {
        if (data) {
          this.dossier.dateContactDossier = data.dateContactDossier;
          this.dossier.commentcontactRapportDossier = data.commentcontactRapportDossier;
          this.message.add({severity: 'success', summary: this.translate.getElement("DOSSIER.POPUP.DATE.CONTACT.MESSAGE_SUCCESS")});
        }
        this.spinnerService.hide();

      }, error => {
        this.message.add({severity: 'error', summary: this.translate.getElement("DOSSIER.POPUP.DATE.CONTACT.MESSAGE_ERROR")});
        this.spinnerService.hide();
      })
    } else if (this.typeDate === ConfigConstants.DATE_VISITE) {
        this.service.modifierDateVisite(this.payload).subscribe(data => {
          if (data) {
            this.dossier.dateVisite = data.dateVisite;
            this.dossier.commentviRapportDossier = data.commentviRapportDossier;
            this.message.add({severity: 'success', summary: this.translate.getElement("DOSSIER.POPUP.DATE.VISITE.MESSAGE_SUCCESS")});
          }
          this.spinnerService.hide();

        }, error => {
          this.message.add({severity: 'error', summary: this.translate.getElement("DOSSIER.POPUP.DATE.VISITE.MESSAGE_ERROR")});
          this.spinnerService.hide();
        })
    }

  }

  fermer() {
    this.ref.close();
  }

  commentaireTextChange(event: any) {
    this.commentaireCounter = event.htmlValue?.replace(/<[^>]*>/g, '')?.length
  }


}
