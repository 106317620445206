import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {RapportExpertise} from "../../../models/rapport-expertise/rapport-expertise";
import {AbstractControl, FormBuilder, FormGroup, FormGroupDirective} from "@angular/forms";
import {Message} from "primeng/api";
import {TranslateService} from "../../../../shared/translate/translate.service";
import {RapportExpertiseObservableService} from "../../../../shared/observable/rapport-expertise-observable.service";
import {Subscription} from "rxjs";

@Component({
  selector: 'app-conditon-occupation',
  templateUrl: './conditon-occupation.component.html',
  styleUrls: ['./conditon-occupation.component.scss']
})
export class ConditonOccupationComponent implements OnInit, OnDestroy {

  @Input()
  formGroupName: string;

  @Input()
  submitted : boolean;

  @Input()
  submittedRequired : boolean = false;

  @Input()
  rapport : RapportExpertise;

  nbConditionOccupation : number = 0;

  form: FormGroup;

  message : Message[];

  private subscriptionTypeOccupation : Subscription;

  constructor(
    private fb : FormBuilder,
    private rootFormGroup: FormGroupDirective,
    private translate : TranslateService,
    private rapportExpertiseObservableService : RapportExpertiseObservableService,
  ) { }

  ngOnInit(): void {
    this.form = this.rootFormGroup.control.get(this.formGroupName) as FormGroup;

    this.nbConditionOccupation = this.form?.value?.conditionOccupation ? this.form?.value?.conditionOccupation?.replace(/<[^>]*>/g, '')?.length : 0;

    this.message =[ {key : 'conditionOccupation', severity:'error', summary:  this.translate.getElement("RAPPORT_EXPERTISE.MESSAGE.ERROR_CARACTERE_COMMENTAIRE", '3500')}];

    this.subscriptionTypeOccupation = this.rapportExpertiseObservableService.getTypeOccupation().subscribe( data => {
      if(data && data?.idOccupation === 0) {
        this.form.get('conditionOccupation').setValue("Bien immobilier libre de toute location ou occupation.")
      }
    });

  }

  ngOnDestroy() {
    this.subscriptionTypeOccupation.unsubscribe();
  }

  get f(): { [key: string]: AbstractControl } {
    return this.form.controls;
  }

  numConditionOccupationextChange(event: any) {
    this.nbConditionOccupation = event?.htmlValue ? event?.htmlValue?.replace(/<[^>]*>/g, '')?.trim()?.length : 0;
  }

}
