<main class="layout-wrapper">
  <app-navigation></app-navigation>
  <app-header titre="Créer une demande" sstitre="{{ssTitre}}"></app-header>
  <app-navigation-contextuelle  *ngIf="typesLivrables && form" [AnchorItem]="anchorItem" [ActionsItem]="actions"></app-navigation-contextuelle>
  <div class="layout-main have-secondary-nav" id="main-layout" *ngIf="typesLivrables && form" >
    <div class="page is-centered">


      <div  *ngIf="errorMessageFormInvalid" class="mr-4">
        <p-messages [(value)]="errorMessageFormInvalid" [escape]="false" [enableService]="false"></p-messages>
      </div>

      <form [formGroup]="form" (ngSubmit)="submitDemande()">
        <!-- Resume -->
        <h2  id="demandeur">{{'DOSSIER.DEMANDE.CREATION.DEMANDEUR' | translateI18n}}</h2>
        <div class="card">
          <div class="grid">
            <div class="col">
              <p><b class="locked">{{user?.prenom}} {{user?.nom}}</b></p>
              <p class="locked">{{user?.agence?.adresse?.numero}} {{user?.agence?.adresse?.nomVoie}}<br>
                {{user?.agence?.adresse?.codePostal}} {{user?.agence?.adresse?.ville}}</p>
              <p class="locked" *ngIf="user?.agence?.entiteClient"><span class="label">{{'DOSSIER.DEMANDE.CREATION.ENTITE' | translateI18n}} : </span>
                {{user?.agence?.entiteClient ? user?.agence?.entiteClient : ' --'}}<br>
              </p>
              <p class="locked"><span class="label">{{'DOSSIER.DEMANDE.CREATION.RATTACHEMENT' | translateI18n}} : </span>
                {{user?.agence?.raisonSociale ? user?.agence?.raisonSociale : ' --'}}</p>

              <div class=" mt-1" *ngIf="user?.agence?.entiteClient === 'MRC'">

                <div class="field" >
                  <label for="preteur" class="obligatoire" [ngClass]="submitted && f.preteur?.errors?.required ? 'errorColor':null">
                    {{'DOSSIER.DEMANDE.CREATION.PRETEUR' | translateI18n}}</label>
                  <div class="p-input-icon-right w-full">
                    <p-dropdown [options]="preteur" placeholder="{{'PLACEHOLDER.CHOOSE' | translateI18n}}" id="preteur"
                                formControlName="preteur" name="preteur"
                                optionLabel="libelle"
                                emptyMessage="{{'DOSSIER.MESSAGE.AUCUN_RESULTAT' | translateI18n}}"
                                [ngClass]="submitted && f.preteur?.errors?.required ? 'dropdownErrorColor':null"
                    ></p-dropdown>
                  </div>



                  <small *ngIf="submitted && f.preteur?.errors?.required" class="p-error block">{{'MESSAGE.ERROR.CHAMPS_OBLIGATOIRE' | translateI18n}}</small>

                </div>

              </div>

            </div>

            <div class="col">
              <p class="locked"><a href="mailto:{{user?.email}}">{{user?.email}}</a></p>
              <p class="locked"><a href="mailto:{{user?.agence?.mail}}">{{user?.agence?.mail}}</a></p>
              <p class="locked"><span class="label">{{'DOSSIER.DEMANDE.CREATION.TELEPHONE' | translateI18n}} :
              </span><a href="{{user?.telephone}}">{{user?.telephone ? user?.telephone : '--'}}</a></p>
              <p class="locked"> <span class="label">{{'DOSSIER.DEMANDE.CREATION.PORTABLE' | translateI18n}} :
              </span><a href="{{user?.portable}}">{{user?.portable ? user?.portable :'--'}}</a></p>
            </div>

          </div>


        </div>
        <!-- End resume -->

        <!--Formulaire renseignement client-->
        <app-client  [user]="user" [civilites]="civilites" titre="{{'DOSSIER.DEMANDE.CREATION.AFFAIRE' | translateI18n}}"
                     formGroupName="client" [submitted]="submitted"></app-client>

        <!--Formulaire renseignement contact-->
        <app-contact  [civilites]="civilites" [user]="user" titre="{{'DOSSIER.DEMANDE.CREATION.CONTACT' | translateI18n}}"
                      formGroupName="contact" [submitted]="submitted"></app-contact>

        <!--Formulaire renseignement bien-->
        <app-bien  [user]="user" titre="{{'DOSSIER.DEMANDE.CREATION.BIEN_EXPERTISER' | translateI18n}}" formGroupName="bien" [submitted]="submitted"></app-bien>


       <!-- La liste des documment et ajout des documents-->
        <fieldset id="documents" class="grid formgrid">

          <div class="field col-12 field-line">
            <app-liste-document [verouillerUpload]="false" [verouillerExpert]="true"></app-liste-document>
          </div>

        </fieldset>


        <!--Ajout d'un commentaire-->
        <!--<h2>{{'DOSSIER.DEMANDE.CREATION.COMMENTAIRE' | translateI18n}}</h2>-->
        <div class="mb-2">
          <h2>{{'DOSSIER.DEMANDE.CREATION.COMMENTAIRE' | translateI18n}}</h2>
          <small>{{'DOSSIER.DEMANDE.CREATION.MAX_CARACTERE' | translateI18n : '4 000'}}
            <span>({{nbCaractCommentaire}} / 4000)</span>
          </small>
        </div>
        <fieldset id="commentaire" class="grid formgrid">

          <div class="field col-12 field-line">
            <textarea [rows]="15" [cols]="2" pInputTextarea formControlName="commentaire" [autoResize]="false"
                      (ngModelChange)="detectChangeComment($event)" maxlength="4000"></textarea>
          </div>

        </fieldset>

      </form>
    </div>
  </div>

</main>


<ngx-spinner  type="ball-scale-multiple"><p-progressSpinner></p-progressSpinner></ngx-spinner>
