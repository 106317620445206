<form [formGroup]="form" >
  <!-- ligne -->
  <div class="synthese">
    <!-- Colonne -->
    <div class="col-12">
      <h1>{{ 'RAPPORT_EXPERTISE.SYNTHESE_MISSION' | translateI18n}}</h1>

        <section>
          <div class="field input-ghost locked">
            <span class="label"> {{ 'RAPPORT_EXPERTISE.LABEL.ADRESSE_BIEN' | translateI18n}} : </span>
            <b>
              {{rapport?.bienAdresse?.numero}} {{rapport?.bienAdresse?.nomVoie}},
              <span *ngIf="rapport?.bienAdresse?.complement">{{rapport?.bienAdresse?.complement}},</span>
              {{rapport?.bienAdresse?.codePostal}} {{rapport?.bienAdresse?.ville}},
              {{rapport?.bienAdresse?.pays?.libelle}}
            </b>
          </div>

          <div class="field input-ghost locked">
            <span class="label">{{ 'RAPPORT_EXPERTISE.LABEL.TYPE_BIEN' | translateI18n}}  : </span> <b>{{rapport?.typlogieBien | caseWord}}</b>
          </div>
        </section>

      <section>
        <div class="grid">
          <div class="col-12 xl:col-8 flex-grow-1">
            <div class="field input-ghost locked">
              <span class="label">{{ 'RAPPORT_EXPERTISE.LABEL.DATE_VISITE' | translateI18n}}  : </span>
              <b>
                {{rapport?.dateVisite ? (rapport?.dateVisite | date : 'dd/MM/yyyy') : ' -- '}}
              </b>
            </div>


            <div class="field input-ghost obligatoire" *ngIf="!rapport?.etatEnvoi ||  rapport?.etatEnvoi === ConfigConstants.ETAT_ENVOI_INIT">
              <label class="label">{{ 'RAPPORT_EXPERTISE.LABEL.EFFECTUEE_PAR' | translateI18n}} :</label>

              <p-inplace [closable]="true" class="inline-block">
                <ng-template pTemplate="display">
                  {{form?.value?.prenomExpert ? form?.value?.prenomExpert  : 'Prenom expert'}}
                </ng-template>
                <ng-template pTemplate="content">
                  <input type="text" pInputText id="prenomExpert" class="inputfield" formControlName="prenomExpert"
                         placeholder="Prenom expert" [maxLength]="configSizeInput.PRENOM_EXPERT" (input)="nomExpertChange()"/>
                </ng-template>
              </p-inplace>


              <p-inplace [closable]="true" class="inline-block"  [ngClass]="{'nomExpert': submittedRequiredSynthese && f.nomExpert?.errors}">
                <ng-template pTemplate="display">
                  <span>
                    {{form?.value?.nomExpert ?form?.value?.nomExpert : 'Nom expert'}}</span>
                </ng-template>
                <ng-template pTemplate="content">
                  <input type="text" pInputText id="nomExpert" class="inputfield"  formControlName="nomExpert"
                         placeholder="Nom expert" [maxLength]="configSizeInput.NOM_EXPERT"
                         (input)="nomExpertChange()" required/>
                </ng-template>
              </p-inplace>

            </div>

            <small *ngIf="f.prenomExpert?.errors?.pattern || f.nomExpert?.errors?.pattern" class="p-error block mr-4">
              {{'MESSAGE.ERROR.REGEX_PATTERN' | translateI18n}}
            </small>

            <small *ngIf="submittedRequiredSynthese && f.nomExpert?.errors?.required" class="p-error block mr-4">
              {{'MESSAGE.ERROR.CHAMPS_OBLIGATOIRE' | translateI18n}}
            </small>

            <div class="field input-ghost locked" *ngIf="rapport?.etatEnvoi &&  rapport?.etatEnvoi !== ConfigConstants.ETAT_ENVOI_INIT" >
              <span class="label">{{'RAPPORT_EXPERTISE.LABEL.EFFECTUEE_PAR' | translateI18n}} : </span>
              <b>{{rapport?.nomExpert}} {{rapport?.prenomExpert}} </b>
            </div>
          </div>

          <div class="col-12 xl:col-4">
            <div class="field input-ghost" *ngIf="!rapport?.etatEnvoi ||  rapport?.etatEnvoi === ConfigConstants.ETAT_ENVOI_INIT">
              <label for="typeVisite" class="label">{{ 'RAPPORT_EXPERTISE.LABEL.TYPE_VISITE' | translateI18n}} :</label>
              <p-dropdown *ngIf="!rapport?.etatEnvoi ||  rapport?.etatEnvoi === ConfigConstants.ETAT_ENVOI_INIT" [options]="naturesVisites" formControlName="typeVisite"
                          optionLabel="libelle" name="typeVisite"  id="typeVisite"
                          emptyMessage="{{'DOSSIER.MESSAGE.AUCUN_RESULTAT' | translateI18n}}"
                          [ngClass]="{'ng-dirty ng-invalid': submittedRequiredSynthese && f.typeVisite?.errors}"
                          (onChange)="changeTypeVisite($event.value)"
                          placeholder="{{'PLACEHOLDER.CHOOSE' | translateI18n}}" class="obligatoire">
              </p-dropdown>
              <small *ngIf="submittedRequiredSynthese && f.typeVisite?.errors?.required" class="p-error block mr-4">
                {{'MESSAGE.ERROR.CHAMPS_OBLIGATOIRE' | translateI18n}}
              </small>
            </div>

            <div class="field input-ghost locked" *ngIf="rapport?.etatEnvoi &&  rapport?.etatEnvoi !== ConfigConstants.ETAT_ENVOI_INIT" >
              <span class="label">{{'RAPPORT_EXPERTISE.LABEL.TYPE_VISITE' | translateI18n}} : </span>
              <b>{{rapport?.natureVisite ? rapport?.natureVisite?.libelle : ' -- '}}</b>
            </div>


            <div class="field input-ghost" *ngIf="!rapport?.etatEnvoi ||  rapport?.etatEnvoi === ConfigConstants.ETAT_ENVOI_INIT">
              <label for="accompagnateur" class="label">{{'RAPPORT_EXPERTISE.LABEL.SOUS_CONDUITE' | translateI18n}} :</label>
              <input  type="text" pInputText id="accompagnateur" class="inputfield" formControlName="accompagnateur"
                      placeholder="Renseignez"
                      value="Valeur modifiable" [maxLength]="configSizeInput.ACCOMPAGNATEUR" />
            </div>

            <div class="field input-ghost locked" *ngIf="rapport?.etatEnvoi &&  rapport?.etatEnvoi !== ConfigConstants.ETAT_ENVOI_INIT" >
              <span class="label">{{'RAPPORT_EXPERTISE.LABEL.SOUS_CONDUITE' | translateI18n}} : </span>
              <b>{{rapport?.accompagnateur ? rapport?.accompagnateur : ' -- '}}</b>
            </div>
          </div>
        </div>
      </section>

      <section *ngIf="typeSurface">
        <div class="field input-ghost locked">
          <span class="label">{{typeSurface?.libelle}} : </span>
          <b>{{surface | decimal}} m²</b>
        </div>
      </section>


      <section>

          <div class="formgroup-inline">
            <div class="field input-ghost" *ngIf="!rapport?.etatEnvoi ||  rapport?.etatEnvoi === ConfigConstants.ETAT_ENVOI_INIT">
              <label for="situationBien" class="label">{{'RAPPORT_EXPERTISE.LABEL.SITUATION_BIEN' | translateI18n}} : </label>
              <p-dropdown
                [options]="situationsBien"  formControlName="situationBien"
                optionLabel="libelle" name="situationBien"  id="situationBien"
                emptyMessage="{{'DOSSIER.MESSAGE.AUCUN_RESULTAT' | translateI18n}}"
                [ngClass]="{'ng-dirty ng-invalid': submittedRequiredSynthese && f.situationBien?.errors}"
                placeholder="{{'PLACEHOLDER.CHOOSE' | translateI18n}}" class="obligatoire"></p-dropdown>
              <small *ngIf="submittedRequiredSynthese && f.situationBien?.errors?.required" class="p-error block mr-4">
                {{'MESSAGE.ERROR.CHAMPS_OBLIGATOIRE' | translateI18n}}
              </small>
            </div>

            <div class="field input-ghost locked" *ngIf="rapport?.etatEnvoi &&  rapport?.etatEnvoi !== ConfigConstants.ETAT_ENVOI_INIT" >
              <span class="label">{{'RAPPORT_EXPERTISE.LABEL.SITUATION_BIEN' | translateI18n}} : </span>
              <b>{{rapport?.situation ? rapport?.situation?.libelle : ' -- '}}</b>
            </div>

          </div>

        <div class="grid" *ngIf="form?.value?.typeVisite">

          <div class="col-12 md:col-6">
            <div class="field input-ghost" *ngIf="!rapport?.etatEnvoi ||  rapport?.etatEnvoi === ConfigConstants.ETAT_ENVOI_INIT">
              <label for="entretienExterne" class="label">{{'RAPPORT_EXPERTISE.LABEL.ETAT_ENTRETIEN_EXTERNE' | translateI18n}} :</label>
              <p-dropdown
                [options]="etatsEntretien"  formControlName="entretienExterne"
                optionLabel="libelle" name="entretienExterne"  id="entretienExterne"
                emptyMessage="{{'DOSSIER.MESSAGE.AUCUN_RESULTAT' | translateI18n}}"
                [ngClass]="{'ng-dirty ng-invalid': submittedRequiredSynthese && f.entretienExterne?.errors}"
                placeholder="{{'PLACEHOLDER.CHOOSE' | translateI18n}}" class="obligatoire"></p-dropdown>
              <small *ngIf="submittedRequiredSynthese && f.entretienExterne?.errors?.required" class="p-error block mr-4">
                {{'MESSAGE.ERROR.CHAMPS_OBLIGATOIRE' | translateI18n}}
              </small>
            </div>

            <div class="field input-ghost locked" *ngIf="rapport?.etatEnvoi &&  rapport?.etatEnvoi !== ConfigConstants.ETAT_ENVOI_INIT" >
              <span class="label">{{'RAPPORT_EXPERTISE.LABEL.ETAT_ENTRETIEN_EXTERNE' | translateI18n}} : </span>
              <b>{{rapport?.entretienExterne ? rapport?.entretienExterne?.libelle : ' -- '}}</b>
            </div>

          </div>

          <div class="col-12 md:col-6">
            <div class="field input-ghost" *ngIf="(!rapport?.etatEnvoi ||  rapport?.etatEnvoi === ConfigConstants.ETAT_ENVOI_INIT) && displayEtatEntretienInterne">
              <label for="entretienInterne" class="label">{{'RAPPORT_EXPERTISE.LABEL.ETAT_ENTRETIEN_INTERNE' | translateI18n}} :</label>
              <p-dropdown *ngIf="!rapport?.etatEnvoi ||  rapport?.etatEnvoi === ConfigConstants.ETAT_ENVOI_INIT"
                          [options]="etatsEntretien"  formControlName="entretienInterne"
                          optionLabel="libelle" name="entretienInterne"  id="entretienInterne"
                          emptyMessage="{{'DOSSIER.MESSAGE.AUCUN_RESULTAT' | translateI18n}}"
                          [ngClass]="{'ng-dirty ng-invalid': submittedRequiredSynthese && f.entretienInterne?.errors}"
                          placeholder="{{'PLACEHOLDER.CHOOSE' | translateI18n}}" class="obligatoire"></p-dropdown>
              <small *ngIf="submittedRequiredSynthese && f.entretienInterne?.errors?.required" class="p-error block mr-4">
                {{'MESSAGE.ERROR.CHAMPS_OBLIGATOIRE' | translateI18n}}
              </small>
            </div>

            <div class="field input-ghost locked" *ngIf="(rapport?.etatEnvoi &&  rapport?.etatEnvoi !== ConfigConstants.ETAT_ENVOI_INIT) && displayEtatEntretienInterne" >
              <span class="label">{{'RAPPORT_EXPERTISE.LABEL.ETAT_ENTRETIEN_INTERNE' | translateI18n}} : </span>
              <b>{{rapport?.entretienInterne ? rapport?.entretienInterne?.libelle : ' -- '}}</b>
            </div>
          </div>

        </div>
      </section>


      <section *ngIf="typeValeur">

        <div class="field input-ghost locked" *ngIf="typeValeur?.idTypeValeur === 1 || typeValeur?.idTypeValeur === 3 || typeValeur?.idTypeValeur === 5">

          <span class="label">{{typeValeur?.label1}} : </span>
          <b>{{this.valeurVenal?.value  ? (this.valeurVenal?.value |  currency : 'EUR' : 'symbol' : '1.2-2') : '---'}} {{this.valeurVenal?.regime}}</b>
          ({{'RAPPORT_EXPERTISE.LABEL.SOIT' | translateI18n}} {{this.valeurVenal?.ratio ? (this.valeurVenal?.ratio|  currency : 'EUR' : 'symbol' : '1.2-2') : ' -- €'}} {{'RAPPORT_EXPERTISE.LABEL.AU_M2' | translateI18n}})
        </div>

        <div class="field input-ghost locked" *ngIf="typeValeur?.idTypeValeur === 2 || typeValeur?.idTypeValeur === 5">

          <span class="label" *ngIf="typeValeur?.idTypeValeur === 2" >{{typeValeur?.label1}} : </span>
          <span class="label"  *ngIf="typeValeur?.idTypeValeur === 5" >{{typeValeur?.label2}} : </span>
          <b>
            {{this.valeurLocative?.value  ? (this.valeurLocative?.value |  currency : 'EUR' : 'symbol' : '1.2-2') : '---'}}
            {{this.valeurLocative?.regime}}
          </b>
          ({{'RAPPORT_EXPERTISE.LABEL.SOIT' | translateI18n}} {{this.valeurLocative?.ratio ? (this.valeurLocative?.ratio|  currency : 'EUR' : 'symbol' : '1.2-2') : ' -- €'}} {{'RAPPORT_EXPERTISE.LABEL.AU_M2' | translateI18n}})
        </div>

        <div class="field input-ghost locked" *ngIf="typeValeur?.idTypeValeur === 3">

          <span class="label">{{typeValeur?.label2}} : </span>
          <b>
            {{this.valeurApresTravaux?.value  ? (this.valeurApresTravaux?.value |  currency : 'EUR' : 'symbol' : '1.2-2') : '---'}}
            {{this.valeurApresTravaux?.regime}}
          </b>
          ({{'RAPPORT_EXPERTISE.LABEL.SOIT' | translateI18n}} {{this.valeurApresTravaux?.ratio ? (this.valeurApresTravaux?.ratio|  currency : 'EUR' : 'symbol' : '1.2-2') : ' -- €'}} {{'RAPPORT_EXPERTISE.LABEL.AU_M2' | translateI18n}})
        </div>

        <div class="field input-ghost locked" *ngIf="typeValeur?.idTypeValeur === 4">

          <span class="label">{{typeValeur?.libelle}} : </span>
          <b>
            {{this.valeurFuturAchevement?.value  ? (this.valeurFuturAchevement?.value |  currency : 'EUR' : 'symbol' : '1.2-2') : '---'}}
            {{this.valeurFuturAchevement?.regime}}
          </b>
          ({{'RAPPORT_EXPERTISE.LABEL.SOIT' | translateI18n}} {{this.valeurFuturAchevement?.ratio ? (this.valeurFuturAchevement?.ratio|  currency : 'EUR' : 'symbol' : '1.2-2') : ' -- €'}} {{'RAPPORT_EXPERTISE.LABEL.AU_M2' | translateI18n}})
        </div>


      </section>

      <section>

        <div class="grid" *ngIf="typeValeur?.idTypeValeur === 1 || typeValeur?.idTypeValeur === 3 || typeValeur?.idTypeValeur === 5">

          <div class="col-12" *ngIf="rapport?.typeTiers === 'TTBANQUE' && rapport?.displayVenteForcee" >
            <div class="field input-ghost locked">
              <span class="label">{{'RAPPORT_EXPERTISE.LABEL.VALEUR_VENTE_FORCEE' | translateI18n}} : </span>
              <b> {{valeurVenteForcee ? (valeurVenteForcee | currency: 'EUR' : 'symbol' : '1.2-2' ): ' -- '}} </b>
            </div>
          </div>

          <div class="col-12 md:col-6">
            <div class="field input-ghost" *ngIf="!rapport?.etatEnvoi ||  rapport?.etatEnvoi === ConfigConstants.ETAT_ENVOI_INIT">
              <label for="liquidite" class="label">{{'RAPPORT_EXPERTISE.LABEL.LIQUIDITE_BIEN' | translateI18n}} :</label>
              <p-dropdown  [options]="liquidites"  formControlName="liquidite" optionLabel="libelle" name="liquidite"
                           id="liquidite"
                           emptyMessage="{{'RAPPORT_EXPERTISE.AUCUN_RESULTAT' | translateI18n}}"
                           [ngClass]="{'ng-dirty ng-invalid': submittedRequiredSynthese && f.liquidite?.errors}"
                           placeholder="{{'PLACEHOLDER.CHOOSE' | translateI18n}}" class="obligatoire" (onChange)="changeLiquidite($event)"></p-dropdown>
              <small *ngIf="submittedRequiredSynthese && f.liquidite?.errors?.required" class="p-error block mr-4">
                {{'MESSAGE.ERROR.CHAMPS_OBLIGATOIRE' | translateI18n}}
              </small>
            </div>

            <div class="field input-ghost locked" *ngIf="rapport?.etatEnvoi &&  rapport?.etatEnvoi !== ConfigConstants.ETAT_ENVOI_INIT" >
              <span class="label">{{'RAPPORT_EXPERTISE.LABEL.LIQUIDITE_BIEN' | translateI18n}} : </span>
              <b>{{rapport?.paramLiquidite?.libelle ? rapport?.paramLiquidite?.libelle : '--'}}</b>
            </div>

          </div>

          <div class="col-12 md:col-6">
            <div class="field input-ghost" *ngIf="!rapport?.etatEnvoi ||  rapport?.etatEnvoi === ConfigConstants.ETAT_ENVOI_INIT">
              <label for="delaiCommercialisation" class="label">{{'AVIS_VALEUR.LABEL.DELAI_COMMERCIALISATION' | translateI18n}} :</label>
              <p-dropdown  [options]="delaiCom"  formControlName="delaiCommercialisation" optionLabel="libelle" name="delaiCommercialisation"
                           id="delaiCommercialisation"
                           emptyMessage="{{'RAPPORT_EXPERTISE.AUCUN_RESULTAT' | translateI18n}}"
                           [ngClass]="{'ng-dirty ng-invalid': submittedRequiredSynthese && f.delaiCommercialisation?.errors}"
                           placeholder="{{'PLACEHOLDER.CHOOSE' | translateI18n}}" class="obligatoire"
                           (onChange)="changeDelaiCommercialisation($event)"></p-dropdown>
              <small *ngIf="submittedRequiredSynthese && f.delaiCommercialisation?.errors?.required" class="p-error block mr-4">
                {{'MESSAGE.ERROR.CHAMPS_OBLIGATOIRE' | translateI18n}}
              </small>
            </div>

            <div class="field input-ghost locked" *ngIf="rapport?.etatEnvoi &&  rapport?.etatEnvoi !== ConfigConstants.ETAT_ENVOI_INIT" >
              <span class="label">{{'RAPPORT_EXPERTISE.LABEL.DELAI_COMMERCIALISATION' | translateI18n}} : </span>
              <b>{{rapport?.delaiCommercialisation?.libelle ? rapport?.delaiCommercialisation?.libelle : '--'}}</b>
            </div>
          </div>

        </div>

      </section>


    </div>
    <!-- Fin Colonne -->

  </div>
  <!-- Fin ligne -->
</form>
